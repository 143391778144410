import { useContext } from 'react';
import { createStore, useStore, StoreApi, StateCreator } from 'zustand';
import type { StateGetter, StateSetter } from './provider';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Constructor } from 'type-fest';

export interface PersistanceAdapter<T> {
  getItem<Item>(key: string): Item | Promise<Item>;
  setItem<Item>(key: string, newValue: Item): PromiseLike<void>;
  removeItem(key: string): void | Promise<void>;
  partialize(state: T): Partial<T>;
  id(): string;
}

export type Middleware<T, K> = (
  t: StateCreator<T & { actions: K }>
) => StateCreator<T & { actions: K }, any, any, any>;

type PersistanceAdapterCtor<T> = Constructor<PersistanceAdapter<T>, [T]>;

export function createDataStore<
  T,
  K extends Record<string, (...args: any[]) => void>,
  A extends (set: StateSetter<T>, get: StateGetter<T, K>) => K
>(
  state: T,
  actions?: A,
  opts: { persistance?: PersistanceAdapterCtor<T> } = {}
) {
  if (opts.persistance) {
    const persistance = new opts.persistance(state);
    const middleware = persist<T & { actions: K }>(
      (set, get) => ({
        ...state,
        actions: actions ? actions(set as any, get as any) : ({} as K),
      }),
      {
        name: persistance.id(), // unique name
        storage: createJSONStorage(() => persistance),
        partialize: (state) =>
          persistance.partialize(state as T) as T & { actions: K },
      }
    );
    return createStore<T & { actions: K }>()(middleware);
  }

  return createStore<T & { actions: K }>()((set, get) => ({
    ...state,
    actions: actions ? actions(set as any, get) : ({} as K),
  }));
}

export const makeStoreContextHook = <K, A = ReturnType<() => {}>>(
  ctx: React.Context<any>
) => {
  return function <T = any>(
    selector: (state: K & { actions: A }) => T,
    equalityFn?: (left: T, right: T) => boolean
  ) {
    const ctxStore = useContext(ctx) as StoreApi<K & { actions: A }>;
    return useStore(ctxStore, selector, equalityFn);
  };
};
