import { ok } from 'neverthrow';
import { AST } from '../ast';
import { cacheStrategy } from './cache-strategy';
import type { ExistingCache, ServeFromCacheResult, CachePolicy } from './types';
import { Assert } from '@cotera/utilities';

export const serve = async <Meta, Error>(params: {
  ir: AST.RelIR;
  existingCache: ExistingCache<Meta, Error>;
  create: (ir: AST.RelIR) => Promise<ServeFromCacheResult<Meta, Error>>;
  policy: CachePolicy;
}): Promise<ServeFromCacheResult<Meta, Error>> => {
  const { existingCache, create, ir, policy } = params;
  const strategy = cacheStrategy({ ir, existingCache, policy });
  const { type } = strategy;

  switch (type) {
    case 'functional':
      return ok({ ir: strategy.relation, meta: null });
    case 'existing': {
      const cached = existingCache[strategy.sqlHash];
      Assert.assert(cached !== undefined);

      const cacheResult = await cached.fetch();
      return cacheResult.map(({ ir }) => ({
        meta: cached.meta,
        ir: strategy.serve(ir),
      }));
    }
    case 'create': {
      const cacheResult = await create(strategy.relation);
      return cacheResult.map(({ ir, meta }) => ({
        ir: strategy.serve(ir),
        meta,
      }));
    }
    default:
      return Assert.unreachable(type);
  }
};
