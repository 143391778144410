import { useState } from 'react';
import { Inputs } from '../../forms';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import { useSubscribe, useViewModelContext } from '@cotera/client/app/etc';
import { DataGridViewModel } from '../types';

export const PlainTextSearch: React.FC<{
  column: string;
}> = ({ column }) => {
  const vm = useViewModelContext<DataGridViewModel>();
  const filters = useSubscribe(vm, (s) => s.filters);
  const [value, setValue] = useState(
    filters[column]?.t === 'search' ? filters[column].value : ''
  );
  const [hasFocus, setHasFocus] = useState(false);

  useKeyPress('Enter', () => {
    if (hasFocus) {
      vm.filter({
        ...filters,
        [column]: {
          t: 'search',
          value,
        },
      });
    }
  });

  return (
    <Inputs.Text
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      icon="search"
      className="w-full"
      value={value}
      onChange={setValue}
    />
  );
};
