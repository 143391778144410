import { ArtifactRequest } from '@cotera/api';
import { ARTIFACT_SIZE, EraCache } from '@cotera/era';
import { assertOk } from '@cotera/utilities';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useTenantedClient, useWhoami } from '../stores/org';
import { queryKeys } from './query-cache-keys';
import { DateTime } from 'luxon';

export const useInvalidateArtifactById = ({ id }: { id: string | null }) => {
  const orgId = useWhoami((state) => state.org.id);
  const { artifacts } = useTenantedClient();

  const queries = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      if (id !== null) {
        await artifacts.invalidateArtifact({ id });
      }
    },
    onSuccess: async () => {
      if (id !== null) {
        await queries.invalidateQueries({
          queryKey: queryKeys.artifacts.usableCache({ orgId }),
        });
        await queries.invalidateQueries({
          queryKey: queryKeys.artifacts.requests({ orgId, page: 1 }),
        });
        await queries.invalidateQueries({
          queryKey: queryKeys.artifacts.requestById({ orgId, reqId: id }),
        });

        await queries.invalidateQueries({
          predicate: (query) => {
            if (
              typeof query.state.data === 'object' &&
              query.state.data !== null &&
              'fromArtifactId' in query.state.data
            ) {
              return query.state.data.fromArtifactId === id;
            }
            return false;
          },
        });
      }
    },
  });
};

export const useArtifactRequests = ({
  page,
  sqlHash,
}: {
  page: number;
  sqlHash?: string;
}): UseQueryResult<ArtifactRequest[]> => {
  const org = useWhoami((s) => s.org);
  const client = useTenantedClient();

  return useQuery({
    queryKey: queryKeys.artifacts.requests({ orgId: org.id, page, sqlHash }),
    queryFn: async (): Promise<ArtifactRequest[]> =>
      assertOk(
        await client.artifacts.listRequests({
          pagination: { page, perPage: 100 },
          sqlHash,
        })
      ),
  });
};
const MAX_CACHE_TIME_IN_HOURS = 24;

export const ERA_CACHE_POLICY: EraCache.CachePolicy = {
  artifactSize: ARTIFACT_SIZE,
  isFresh: ({ artifactCreatedAt }): boolean => {
    return (
      Math.abs(
        DateTime.now().diff(DateTime.fromJSDate(artifactCreatedAt)).as('hours')
      ) < MAX_CACHE_TIME_IN_HOURS
    );
  },
};
