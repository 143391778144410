import { Feature, useDrafts } from './context';
import { AssignedFilter, FormState, ItemTypes } from './types';
import { filter, pluralize } from '@cotera/client/app/etc';
import { Card, List } from '@cotera/client/app/components/headless';
import {
  Title,
  Text,
  ProgressBar,
  Badge,
  ActionMenu,
  Icon,
  toast,
  Button,
} from '@cotera/client/app/components/ui';
import { Copyable, Empty } from '@cotera/client/app/components/app';
import { classNames } from '@cotera/client/app/components/utils';
import { FocusableComponentProps } from '@cotera/client/app/components/types/form-component';
import { useDrag } from 'react-dnd';
import { Assert } from '@cotera/utilities';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useEffect, useState } from 'react';
import { TenantedClient } from '@cotera/api';
import { sortBy } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

const doSearch = async (
  client: TenantedClient,
  uddId: string,
  versionId: string,
  search: string
) => {
  return Assert.assertOk(
    await client.topics.detectedFeatures({
      uddId: uddId,
      versionId: versionId,
      search: {
        query: search,
      },
    })
  );
};

export const useSearch = (props: {
  uddId: string;
  versionId: string;
  search?: string;
}) => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const client = useTenantedClient();
  const addNewFeatures = useDrafts((s) => s.actions.addNewFeatures);
  const setSearching = useDrafts((s) => s.actions.setSearching);

  useEffect(() => {
    const action = async () => {
      try {
        if ((props.search?.length ?? 0) > 0) {
          setSearching(true);
          const data = await doSearch(
            client,
            props.uddId,
            props.versionId,
            props.search ?? ''
          );
          setFeatures(data.features);
          addNewFeatures(data.features);
        } else {
          setFeatures([]);
        }
      } finally {
        setSearching(false);
      }
    };

    void action();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search, props.uddId, props.versionId]);

  return features;
};

export const DetectedFeatures: React.FC<{
  uddId: string;
  versionId: string;
  query?: FormState;
  assigned?: AssignedFilter;
}> = ({ query, uddId, versionId }) => {
  const features = useDrafts((s) => s.features);
  const search = useSearch({
    uddId,
    versionId,
    search: query?.search,
  });
  const searchIds = search.map((x) => x.featureId);
  const searchedData =
    searchIds.length > 0
      ? searchIds.map((id) => features.find((x) => x.featureId === id)!)
      : sortBy(features, 'documentCount').reverse().slice(0, 300);
  const setFocusedFeature = useDrafts((s) => s.actions.setFocusedFeature);

  const filteredData = filter(searchedData, [
    query?.filter === 'assigned' &&
      ((items) =>
        items.filter((item) => item.topics.length > 0 && !item.ignored)),
    query?.filter === 'unassigned' &&
      ((items) =>
        items.filter((item) => item.topics.length === 0 && !item.ignored)),
    query?.filter === 'ignored' &&
      ((items) => items.filter((item) => item.ignored)),
  ]);

  const numAssigned = features.filter((x) => x.topics.length > 0).length;

  return (
    <>
      <Card.Container className="h-[calc(100%-110px)]">
        <div className="flex items-center w-full justify-between px-4 pt-4 pb-3">
          <Title type="section" className="w-fit">
            Detected Features
          </Title>
          <Text.Caption>{filteredData.length} Results</Text.Caption>
        </div>
        {filteredData.length > 0 && (
          <List.Ul
            className="pl-2 pr-4 py-2 overflow-scroll h-[calc(100%-45px)]"
            hasFocus={true}
            childType={Item}
          >
            {filteredData.map((item) => {
              return (
                <Item
                  uddId={uddId}
                  onClick={() => {
                    setFocusedFeature(item);
                  }}
                  key={item.featureId}
                  item={item}
                />
              );
            })}
          </List.Ul>
        )}
        {filteredData.length === 0 && (
          <Card.Content className="h-full flex items-center justify-center">
            <Empty
              type="list"
              title="No detected features"
              caption="Try adjusting your search query or filters"
            />
          </Card.Content>
        )}
      </Card.Container>
      <ProgressBar
        className="h-10 w-full"
        text="Assigned"
        percent={(numAssigned / features.length) * 100}
      />
    </>
  );
};

const Item: React.FC<
  {
    uddId: string;
    onClick: () => void;
    item: {
      featureId: string;
      content: string;
      documentCount: number;
      topics: {
        name: string;
      }[];
    };
  } & FocusableComponentProps
> = ({ item, uddId, onClick, ...focusProps }) => {
  const remove = useDrafts((s) => s.actions.ignoreFeature);
  const focusedFeature = useDrafts((s) => s.focusedFeature);
  const navigate = useNavigate();
  const { entityName } = useParams() as {
    entityName: string;
  };
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.ITEM,
    item: {
      featureId: item.featureId,
      topicId: null,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <List.Li
      {...focusProps}
      as="li"
      onClick={onClick}
      className={classNames(
        focusedFeature?.featureId === item.featureId
          ? '!border-indigo-300'
          : '',
        'data-[focus]:border-indigo-200 w-full justify-between transition-colors rounded px-2 py-2 flex border boder-divder mb-2 items-center',
        isDragging ? 'opacity-50' : ''
      )}
    >
      <div
        className="flex flex-grow flex-col items-start relative ml-2"
        ref={preview}
      >
        <Text.Caption className="relative">
          <Copyable offset={{ right: '-right-10', top: '-top-3' }}>
            {item.featureId}
          </Copyable>
        </Text.Caption>
        <p className="mt-1 line-clamp-2 text-sm leading-6 text-standard-text overflow-ellipsis">
          {item.content}
        </p>
      </div>
      <div className="flex items-center mb-1 justify-between">
        <div className="flex">
          {item.topics.length === 0 && (
            <Badge theme={'warning'} className="mr-2">
              Unassigned
            </Badge>
          )}
          {item.topics.map((topic) => (
            <Badge theme={'secondary'} className="mr-2">
              {topic.name}
            </Badge>
          ))}
          <Badge theme="regular" className="mr-2">
            {item.documentCount} {pluralize(item.documentCount, 'message')}
          </Badge>
        </div>
      </div>
      <ActionMenu
        actions={[
          {
            label: 'View Details',
            icon: <Icon icon="chevron-right" />,
            onClick: () => {
              navigate(
                `/entities/${entityName}/columns/${uddId}/nlp-features/${item.content}`
              );
            },
          },
          {
            label: 'Ignore',
            icon: <Icon icon="trash" />,
            onClick: () => {
              remove(item.featureId);
              toast.warning('Feature ignored');
            },
          },
        ]}
      />
      <Button ref={drag} icon="drag-handle" inline />
    </List.Li>
  );
};
