import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';
import Fuse, { IFuseOptions } from 'fuse.js';

export const useFuzzySearch = function <T>(
  items: T[],
  keys: string[],
  options?: IFuseOptions<T>
): (query: string | null) => T[] {
  const fuse = useDeepMemo(() => {
    const f = new Fuse<T>(items, {
      keys: keys.map(String),
      ...(options || {}),
    });
    return f;
  }, [items, keys]);

  return (query: string | null) =>
    !query || query?.length === 0
      ? items
      : fuse.search(query).map((x) => x.item);
};
