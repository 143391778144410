import { TELEGRAPH_COMMENTS } from '../../assumptions';

export const NotShadowBannedComments = TELEGRAPH_COMMENTS.where((t) =>
  t.attr('IS_ACTOR_GHOSTBANNED').eq(false)
);

export const ShadowBannedComments = TELEGRAPH_COMMENTS.where((t) =>
  t.attr('IS_ACTOR_GHOSTBANNED').eq(true)
);

export const UsersFlagged = TELEGRAPH_COMMENTS.where((t) =>
  t.attr('TOTAL_FLAGS').gt(0)
);
