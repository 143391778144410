import { Contract, GET, PaginationParamsSchema, POST } from '@cotera/contracts';
import { z } from 'zod';
import { FeatureFlagSchema, FeatureFlagsSchema } from '../feature-flags';
import { OrgSchema } from '../user';

export const SuperAdminOrgsContract = Contract.new({
  all: GET({
    params: z.object({ pagination: PaginationParamsSchema }),
    output: z.array(OrgSchema),
    errors: z.never(),
  }),
  setFeatures: POST({
    params: z.object({
      orgId: z.string(),
      features: FeatureFlagsSchema,
    }),
    errors: z.never(),
    output: FeatureFlagsSchema,
  }),
  getByName: GET({
    params: z.object({ name: z.string() }),
    output: OrgSchema.nullable(),
    errors: z.never(),
  }),
  create: POST({
    params: z.object({
      name: z.string(),
      featureFlags: z.array(FeatureFlagSchema).optional(),
    }),
    output: OrgSchema,
    errors: z.object({ errorType: z.literal('NameTaken') }),
  }),
  setupHostedSnowflakeOrg: POST({
    params: z.object({
      name: z.string(),
      reportViewerDomains: z.array(z.string()).optional(),
      users: z
        .object({
          name: z.string(),
          email: z.string().email(),
        })
        .array(),
    }),
    errors: z.never(),
    output: z.object({ warehouseConnectionId: z.string() }),
  }),
  setupHostedShopifyOnSnowflakeOrg: POST({
    params: z.object({
      name: z.string(),
      reportViewerDomains: z.array(z.string()).optional(),
      users: z
        .object({
          name: z.string(),
          email: z.string().email(),
        })
        .array(),
      shopName: z.string(),
    }),
    errors: z.never(),
    output: z.object({ connectorId: z.string() }),
  }),
});
