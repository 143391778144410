import { Badge, Button, Loading, toast } from '@cotera/client/app/components';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import { AST, Constant, Relation } from '@cotera/era';
import { useState, useContext, Suspense } from 'react';
import { UiStateContext } from './ui-state';
import {
  useEraRuntimeCurrentRelValue,
  useEraScopesAwareRelIR,
} from './macro-expansion/scopes';
import { Assert } from '@cotera/utilities';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useFirstValue } from '@cotera/client/app/hooks/use-first-value';

const cutoffOptions = [
  {
    value: '0.8',
    display: 'Very Strong',
  },
  {
    value: '0.65',
    display: 'Strong',
  },
  {
    value: '0.5',
    display: 'Moderate',
  },
  {
    value: '0.3',
    display: 'Weak',
  },
];

export const RenderSemanticSearch: React.FC<{
  section: AST._RelVarControlsV2;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  const currentValue = useEraRuntimeCurrentRelValue(section.var);
  const chartRelIR = useEraScopesAwareRelIR(currentValue);
  const rel = useFirstValue(Relation.wrap(chartRelIR));

  Assert.assert(section.config.t === 'semantic-search');

  return (
    <Suspense fallback={<Loading.Shimmer className="h-10 w-[150px]" />}>
      <RenderSemanticSearchInner
        section={section}
        rel={rel}
        inBlock={inBlock}
      />
    </Suspense>
  );
};

const RenderSemanticSearchInner: React.FC<{
  rel: Relation;
  section: AST._RelVarControlsV2;
  inBlock: boolean;
}> = ({ rel, section }) => {
  const [search, setSearch] = useState<string>('');
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [searches, setSearches] = useState<
    {
      search: string;
      embedding: number[];
    }[]
  >([]);
  const sendUpdate = useContext(UiStateContext);
  const client = useTenantedClient();
  const [cutoff, setCutoff] = useState<number>(0.65);
  const [loading, setLoading] = useState<boolean>(false);

  const handleEnter = (
    newSearches: {
      search: string;
      embedding: number[];
    }[]
  ) => {
    setSearches(newSearches);
    const seachesCleared = newSearches.length === 0 && searches.length > 0;
    if (seachesCleared) {
      sendUpdate.rel({
        scope: section.var.scope,
        name: section.var.name,
        cb: () => rel,
      });
      return;
    } else if (newSearches.length === 0) {
      return;
    }

    setLoading(true);
    setSearch('');

    const averages = getAverages(newSearches.map((s) => s.embedding));

    sendUpdate.rel({
      scope: section.var.scope,
      name: section.var.name,
      cb: () =>
        rel
          .select(
            (t) => ({
              ...t.star(),
              similarity: Constant(1).sub(
                t.attr('embedding').cosineDistance(averages)
              ),
            }),
            {
              orderBy: (t) => t.attr('similarity').desc(),
            }
          )
          .where((t) => t.attr('similarity').gte(cutoff)),
    });
    setLoading(false);
  };

  useKeyPress('Enter', async () => {
    if (hasFocus) {
      try {
        setLoading(true);
        const embedings = await client.llm.embedding({
          messages: [{ content: search, id: '1' }],
        });
        if (embedings.isErr()) {
          toast.error('Failed to query embeddings', String(embedings.error));
          return;
        }
        const embedding = embedings.value.embeddings[0];

        Assert.assert(embedding !== undefined, 'embedding is undefined');

        handleEnter([
          ...searches,
          {
            search,
            embedding: embedding.embedding,
          },
        ]);
      } catch (e) {
        setLoading(false);
        toast.error('Failed to filter by similarity', String(e));
      }
    }
  });

  return (
    <div className="flex w-full flex-wrap items-center">
      <div className="w-1/3 flex mb-2">
        <Inputs.Text
          onBlur={() => {
            setHasFocus(false);
          }}
          loading={loading}
          onFocus={() => setHasFocus(true)}
          icon="search"
          placeholder="Search"
          value={search}
          onChange={setSearch}
          className="flex-grow mr-2"
        />
        <ComboBox.Select
          label="Similarity"
          compact
          onChange={(e) => {
            e && setCutoff(parseFloat(e.value));
            handleEnter(searches);
          }}
          value={{
            value: cutoff.toString(),
            display:
              cutoffOptions.find((o) => o.value === cutoff.toString())
                ?.display ?? String(cutoff),
          }}
          options={cutoffOptions}
          className="mr-2"
        />
      </div>
      <div className="flex flex-row items-center mb-2">
        {searches.map((s, i) => (
          <Badge size="large" theme="regular" key={i} className="mr-2">
            <span>{s.search}</span>
            <Button
              onClick={() => {
                const newSearches = searches.filter((_, j) => i !== j);
                handleEnter(newSearches);
              }}
              icon="close"
              compact
              small
              theme="regular"
              inline
            />
          </Badge>
        ))}
      </div>
    </div>
  );
};

export function getAverages(arrays: number[][]): number[] {
  const result = [];
  for (let i = 0; i < arrays[0]!.length; i++) {
    let sum = 0;
    for (let j = 0; j < arrays.length; j++) {
      sum += arrays[j]?.[i] ?? 0;
    }
    result.push(sum / arrays.length);
  }
  return result;
}
