export function generateCalendarArray(inputDate: Date): string[] {
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth() + 1;
  // Create a new Date object for the first day of the given month and year
  const firstDay = new Date(year, month - 1, 1);

  // Get the day of the week for the first day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const startDay = firstDay.getDay();

  // Adjust startDay to make Monday the first day of the week
  const adjustedStartDay = startDay === 0 ? 6 : startDay - 1;

  // Get the number of days in the month
  const daysInMonth = new Date(year, month, 0).getDate();

  // Calculate the total number of cells in the calendar (5 rows * 7 columns)
  const totalCells = 5 * 7;

  // Initialize the calendar array with blanks
  const calendarArray: (number | string)[] = Array(totalCells).fill('');

  // Fill in the days of the month
  for (let day = 1; day <= daysInMonth; day++) {
    calendarArray[adjustedStartDay + day - 1] = day;
  }

  return calendarArray.map(String);
}
