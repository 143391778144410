import { Button } from '@cotera/client/app/components/ui';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { useEffect, useState } from 'react';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import { createPortal } from 'react-dom';
import { DragHandle } from '../../headless';

export const SideModal: React.FC<
  {
    open: boolean;
    onClose: () => void;
  } & ChildrenProps
> = ({ children, open, onClose }) => {
  const [width, setWidth] = useState(570);
  const [isResizing, setIsResizing] = useState(false);
  const [snapPosition, setSnapPosition] = useState<'left' | 'right'>('right');
  const [isVisible, setIsVisible] = useState(false);

  const handleResize = (delta: number) => {
    if (snapPosition === 'right') {
      delta = -delta;
    }
    if (!isResizing) {
      setIsResizing(true);
    }
    setWidth((prevWidth) => Math.max(prevWidth + delta, 570)); // Ensure minimum width
  };

  useKeyPress('Escape', () => {
    if (open) {
      onClose();
    }
  });

  useEffect(() => {
    if (!isVisible) {
      setIsVisible(true); // Trigger the transition when the component mounts
    }
  }, [isVisible]);

  return createPortal(
    <div
      className={classNames(
        'fixed top-0 h-full z-30 overflow-hidden py-4 ',
        isVisible ? 'mx-4 px-4 opacity-100' : 'px-0 opacity-0',
        snapPosition === 'left' ? 'left-0' : 'right-0',
        !isResizing ? 'transition-all ease-in-out duration-150' : ''
      )}
      style={{
        width: isVisible ? `${width}px` : '0px',
      }}
    >
      <div
        className="relative bg-white h-full rounded overflow-y-auto border border-divider flex-col"
        style={{
          width: `${width - 20}px`,
          boxShadow:
            snapPosition === 'left'
              ? '3px 3px 12px rgba(0, 0, 0, 0.15)'
              : '-3px 3px 12px rgba(0, 0, 0, 0.15)',
        }}
      >
        <div
          className={classNames(
            'absolute h-full z-[40] top-0',
            snapPosition === 'left' ? 'right-0' : 'left-0'
          )}
        >
          <DragHandle
            onResize={handleResize}
            onResizeFinished={() => {
              setIsResizing(false);
            }}
          />
        </div>
        <div className="pl-2 pr-2 pt-2 flex justify-between w-full items-center">
          <Button
            icon={snapPosition === 'left' ? 'arrow-right' : 'arrow-left'}
            onClick={() => {
              setSnapPosition(snapPosition === 'left' ? 'right' : 'left');
            }}
            text={snapPosition === 'left' ? 'Snap Right' : 'Snap Left'}
            inline
            small
          />
          <Button icon="close" onClick={onClose} text="Esc" inline small />
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
};
