import { StateSetter } from '../provider';

export function withHistory<
  State extends { history: State[]; pop: () => void }
>(set: StateSetter<State>, get: () => State): StateSetter<State> {
  const pop = () => {
    const last = [...get().history].pop();
    if (last) {
      const nextState: State = {
        ...last,
        history: get().history.slice(0, -1),
      };
      set(() => nextState);
    }
  };

  return (data: (s: State) => Partial<State>) => {
    set(() => ({
      ...data(get()),
      history: [...get().history, get()],
      pop,
    }));
  };
}
