import { Contract, POST } from '@cotera/contracts';
import { z } from 'zod';
import { Parser } from '@cotera/era';

export const WarehouseConnectionSchema = z.object({
  id: z.string().uuid(),
  orgId: z.string().uuid(),
  type: Parser.Credentials.WarehouseTypeSchema,
  displayName: z.string(),
  writeSchema: z.string(),
});

export const WarehouseConnectionContract = Contract.new(
  {
    create: POST({
      params: z.object({
        displayName: z.string(),
        credentials: Parser.Credentials.WarehouseCredentialsSchema,
        skipSetup: z.boolean().optional(),
      }),
      output: WarehouseConnectionSchema,
      errors: z.never(),
    }),
  },
  {
    mustBeAbleTo: [['manage', 'warehouse']],
  }
);
