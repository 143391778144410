import React from 'react';
import { Button, Divider, Title, Text } from '@cotera/client/app/components/ui';
import { classNames } from '../../utils';
import { Inputs } from '../../forms';
import { Ty } from '@cotera/era';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { downloadFile, jsonToCsv } from '@cotera/client/app/components/utils';
import { useSubscribe, WatchableViewModel } from '@cotera/client/app/etc';
import { AttrIcon } from '../../app/era';
import { DataGridViewModel } from '../types';
import { isEqual } from 'lodash';

export const SlideOver: React.FC<{
  vm: DataGridViewModel;
}> = ({ vm }) => {
  const show = useSubscribe(vm, (s) => s.showActionsColumn);
  const allColumns = useSubscribe(vm, (s) => s.columns);
  const hiddenColumns = useSubscribe(vm, (s) => s.hiddenItems);
  const attributes = useSubscribe(
    vm,
    (s) => s.attributes,
    (a, b) => isEqual(Object.keys(a), Object.keys(b))
  );
  const data = useSubscribe(vm, (s) => s.data);
  const [searchText, setSearch] = React.useState('');
  const search = useFuzzySearch(
    allColumns.map((x) => ({
      key: x,
    })),
    ['key']
  );
  const columns = search(searchText)
    .map((x) => x.key)
    .map((x) => ({
      key: x,
      ty: attributes[x] as Ty.ExtendedAttributeType,
    }));
  const allColumnsHidden = columns.length === hiddenColumns.length;

  const [downloading, setDownloading] = React.useState(false);

  return (
    <>
      <div
        onClick={() => {
          vm.toggleActionsColumn();
        }}
        className={classNames(
          show ? 'opacity-60 blur-md' : 'z-[-1] opacity-0',
          'blur-md w-full h-full absolute top-0 right-0 h-full border-l border-divider z-10 transition-all duration-300 ease-in-out bg-white'
        )}
      />
      <div
        className={classNames(
          show ? 'w-[250px] opacity-100' : 'w-0 opacity-0',
          'overflow-x-hidden overflow-y-scroll absolute top-0 right-0 h-full bg-white border-l border-divider z-50 transition-all duration-300 ease-in-out'
        )}
      >
        <div className="flex flex-col justify-center px-4 w-[250px]">
          <div className="flex justify-between items-center mb-2">
            <Title type="section">Manage Data Grid</Title>
            <Button
              inline
              icon="close"
              onClick={() => {
                vm.toggleActionsColumn();
              }}
            />
          </div>
          <Inputs.Text
            placeholder="Search for a property"
            icon="search"
            value={searchText}
            onChange={setSearch}
          />
          <Divider className="mb-4 mt-4" />
          <div className="flex justify-between items-center">
            <Title type="label">Columns</Title>
            <Button
              small
              inline
              icon={allColumnsHidden ? 'eye-slash' : 'eye'}
              onClick={() => {
                if (allColumnsHidden) {
                  vm.showColumn(...columns.map((attr) => attr.key));
                } else {
                  vm.hideColumn(...columns.map((attr) => attr.key));
                }
              }}
            />
          </div>
          {columns.length === 0 && (
            <Text.Caption className="text-left mt-2">
              No attributes
            </Text.Caption>
          )}
          <ul className="mb-4">
            {columns.map((attr) => (
              <li key={attr.key} className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <AttrIcon ty={attr.ty} />
                  <Text.Span
                    className={classNames(
                      'overflow-ellipsis',
                      hiddenColumns.includes(attr.key) ? 'line-through' : ''
                    )}
                  >
                    {attr.key}
                  </Text.Span>
                </div>
                <Button
                  small
                  inline
                  icon={hiddenColumns.includes(attr.key) ? 'eye-slash' : 'eye'}
                  onClick={() => {
                    if (hiddenColumns.includes(attr.key)) {
                      vm.showColumn(attr.key);
                    } else {
                      vm.hideColumn(attr.key);
                    }
                  }}
                />
              </li>
            ))}
          </ul>
          <Title type="label" className="mb-2">
            Actions
          </Title>
          <ul className="mb-2">
            <li className="flex justify-between items-center">
              <Button
                loading={downloading}
                icon="arrow-down"
                text="Download as CSV"
                small
                onClick={async () => {
                  setDownloading(true);
                  downloadFile([await jsonToCsv(data?.toArray() ?? [])], 'csv');
                  setDownloading(false);
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const DataGridActions: React.FC<{
  vm: WatchableViewModel<{
    toggleActionsColumn: () => void;
  }>;
}> = ({ vm }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <Button
        overrides={{ height: 'h-fit', py: '' }}
        small
        compact
        inline
        icon="cog-6-tooth"
        onClick={() => {
          vm.toggleActionsColumn();
        }}
      />
    </div>
  );
};
