import { Loading, ResultBoundary, Title } from '@cotera/client/app/components';
import { jsonToCsv } from '@cotera/client/app/components/utils';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { Relation } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import Markdown from 'markdown-to-jsx';
import { err, ok, Result } from 'neverthrow';
import { Suspense } from 'react';

type LLMSummaryProps = {
  rel: Relation;
  prompt: string;
  title?: string;
  model?: string;
};

function useLlmSummary({ prompt, rel, model }: LLMSummaryProps) {
  const orgId = useWhoami((s) => s.org.id);

  const queryKey = useTenantedQueryKey([
    orgId,
    'llm-summary',
    prompt,
    rel.sqlHash(),
  ]);

  const client = useTenantedClient();
  const results = useDuckDBQuery({ rel: rel.limit(1000) });

  return useSuspenseQuery({
    queryFn: async () => {
      const result = Assert.assertOk(
        await client.llm.asyncCompletion({
          orgId,
          model: model ?? 'o1-mini',
          maxTokens: 5000,
          messages: [
            {
              role: 'user',
              content: `
              ${prompt}
              You will be provided data that is string of csv data. The first row is the headers.

              You MUST return your analysis markdown format.
            `,
            },
            {
              role: 'user',
              content:
                'here is the data in csv format. The first row is the headers.',
            },
            {
              role: 'user',
              content: await trimCsvToMaxLength(
                await jsonToCsv(results.data.data.toArray())
              ),
            },
          ],
        })
      );

      const checkCompletion = async (
        attempt: number
      ): Promise<Result<string, unknown>> => {
        const asyncResult = await client.llm.getAsyncResponse({
          completionId: result.completionId,
          orgId,
        });

        if (asyncResult.isErr()) {
          return err(asyncResult.error);
        }

        if (asyncResult.value.status === 'success') {
          return ok(
            asyncResult.value.completion
              .replace('```markdown\n', '')
              .replace('```', '')
          );
        }

        const ONE_SECOND = 500;
        const FIVE_SECONDS = 5000;
        await new Promise((resolve) =>
          setTimeout(resolve, Math.min(ONE_SECOND * attempt, FIVE_SECONDS))
        );
        return checkCompletion(attempt + 1);
      };

      return await checkCompletion(1);
    },
    queryKey,
  });
}

async function trimCsvToMaxLength(csvString: string, maxLength = 80000): Promise<string> {
  // Step 1: Trim the CSV to the max length provided
  const trimmedCsv = csvString.substring(0, maxLength);

  // Step 2: Check if the last line is incomplete by checking if it ends with a newline
  const lines = trimmedCsv.split('\n');

  // If the last line doesn't end with a newline, it's an incomplete line, so remove it
  if (!csvString[maxLength] || csvString[maxLength] !== '\n') {
    lines.pop(); // Remove the last incomplete line
  }

  // Step 3: Return the valid CSV string
  return lines.join('\n');
}

export const LLMSummary: React.FC<LLMSummaryProps> = ({
  prompt,
  rel,
  title,
}) => {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col">
          {title && <Title type="section" title={title} className="mb-4" />}
          <Loading.Shimmer className="h-[10px] w-[20%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[70%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[60%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[65%] mb-4" />
          <Loading.Shimmer className="h-[10px] w-[20%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[50%] mb-2" />
        </div>
      }
    >
      <LLMSummaryData prompt={prompt} rel={rel} />
    </Suspense>
  );
};

export const LLMSummaryData: React.FC<LLMSummaryProps> = ({ prompt, rel }) => {
  const { data: summary } = useLlmSummary({ prompt, rel });

  return (
    <ResultBoundary result={summary}>
      {(res) => (
        <Markdown
          options={{
            createElement(tag, props, children) {
              const Tag = tag as any;
              return (
                <Tag {...props} style={{ marginBottom: '1rem' }}>
                  {children}
                </Tag>
              );
            },
          }}
        >
          {res}
        </Markdown>
      )}
    </ResultBoundary>
  );
};
