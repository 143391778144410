import { Relation } from '@cotera/era';
import { useState } from 'react';
import { Badge, Button } from '@cotera/client/app/components';
import { ComboBox } from '@cotera/client/app/components/forms';

export type GroupByState = {
  groupBy: string[];
  select: Record<string, 'count' | 'sum' | 'avg' | 'min' | 'max'>;
};

type GroupByOperator = 'count' | 'sum' | 'avg' | 'min' | 'max';
type GroupByActions = {
  addColumn: (col: string) => void;
  removeColumn: (col: string) => void;
};

export const useGroupBy = (
  onChange: (state: GroupByState) => void
): [GroupByState, GroupByActions] => {
  const [state, setState] = useState<{
    groupBy: string[];
    select: Record<string, GroupByOperator>;
  }>({
    groupBy: [],
    select: {},
  });

  const addColumn = (col: string) => {
    setState((state) => {
      const newState = {
        ...state,
        groupBy: [...state.groupBy, col],
      };
      onChange(newState);
      return newState;
    });
  };

  const removeColumn = (col: string) => {
    setState((state) => {
      const newState = {
        ...state,
        groupBy: state.groupBy.filter((c) => c !== col),
      };
      onChange(newState);
      return newState;
    });
  };

  return [
    {
      groupBy: state.groupBy,
      select: state.select,
    },
    {
      addColumn,
      removeColumn,
    },
  ];
};

export const GroupByBuilder: React.FC<{
  rel: Relation;
  groupBy: GroupByState;
  actions: GroupByActions;
}> = ({ rel, groupBy: state, actions }) => {
  return (
    <div className="flex w-full justify-between">
      <div className="flex flex-row">
        {state.groupBy.map((col) => (
          <Badge size="large" theme="regular" key={col} className="mr-2">
            <span className="border-r border-divider pr-2">{col}</span>
            <Button
              text="Remove"
              inline
              iconOnly
              theme="error"
              icon="trash"
              onClick={() => actions.removeColumn(col)}
            />
          </Badge>
        ))}
        <AddGroupByButton rel={rel} state={state} actions={actions} />
      </div>
    </div>
  );
};

const AddGroupByButton: React.FC<{
  rel: Relation;
  state: GroupByState;
  actions: GroupByActions;
}> = ({ rel, state, actions }) => {
  const [showSelect, setShowSelect] = useState(false);
  const attrs = Object.keys(rel.attributes);
  const groupByAttrs = attrs.filter((attr) => !state.groupBy.includes(attr));
  const defaultValue = groupByAttrs.at(0)!;

  return (
    <>
      {!showSelect && (
        <Button
          text="Add Column"
          inline
          iconOnly
          icon="plus"
          onClick={() => {
            setShowSelect(true);
          }}
        />
      )}
      {showSelect && (
        <div className="flex flex-row items-center">
          <ComboBox.Single
            label="Column"
            compact
            value={{
              value: defaultValue,
              display: defaultValue,
            }}
            onChange={(value) => {
              if (value) {
                actions.addColumn(value.value);
                setShowSelect(false);
              }
            }}
            options={({ query }) => (
              <ComboBox.StaticOptions
                options={groupByAttrs.map((attr) => ({
                  value: attr,
                  display: attr,
                }))}
                query={query}
              />
            )}
          />
          <Button
            text="Cancel"
            small
            inline
            iconOnly
            icon="close"
            onClick={() => setShowSelect(false)}
          />
        </div>
      )}
    </>
  );
};
