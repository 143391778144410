import {
  Alert,
  Text,
  Center,
  Loading,
  Button,
  Divider,
} from '@cotera/client/app/components/ui';
import { Inputs } from '@cotera/client/app/components/forms';
import { Layout } from '@cotera/client/app/layout';
import { useAppData, useTenantedClient } from '@cotera/client/app/stores/org';
import { EventStreamDefinition } from '@cotera/sdk/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '@cotera/client/app/components/app';
import { Card } from '@cotera/client/app/components/headless';

type UrlParams = { sinkId: string; eventStreamId: string };

export const DryRunPage: React.FC = () => {
  const { sinkId, eventStreamId } = useParams() as UrlParams;
  const [state, setState] = useState<{
    detectedAt: Date | null;
    limit: number;
  }>({
    detectedAt: new Date(0),
    limit: 100,
  });

  const eventStream = useAppData((s) =>
    s.skeleton.eventStreams.find((x) => x.name === eventStreamId)
  );

  if (!eventStream) {
    return (
      <Layout>
        <NotFound resource="Event Stream" />
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col">
        <Form
          detectedAt={state.detectedAt}
          limit={state.limit}
          onChange={(detectedAt, limit) =>
            setState({
              detectedAt,
              limit,
            })
          }
        />
        <Suspense fallback={<Loading.Dots />}>
          <WithEventStream
            limit={state.limit}
            sinkId={sinkId}
            eventStream={eventStream}
            detectedAt={state.detectedAt}
          />
        </Suspense>
      </div>
    </Layout>
  );
};

const Form: React.FC<{
  detectedAt?: Date | null;
  limit?: number;
  onChange: (detectedAt: Date | null, limit: number) => void;
}> = ({
  detectedAt: inputDetectedAt = new Date(0),
  limit: inputLimit = 100,
  onChange,
}) => {
  const [detectedAt, setDetectedAt] = useState<Date | null>(inputDetectedAt);
  const [limit, setLimit] = useState<number>(inputLimit);

  return (
    <div className="flex space-x-2 items-end">
      <Inputs.Date
        label="Set cursor"
        value={[detectedAt, detectedAt]}
        onChange={([x]) => setDetectedAt(x)}
      />
      <Inputs.Number label="Limit" value={limit} onChange={setLimit} />
      <Button
        text="Run"
        theme="secondary"
        icon="play"
        onClick={() => {
          onChange(detectedAt, limit);
        }}
      />
    </div>
  );
};

const WithEventStream = ({
  eventStream,
  sinkId,
  limit,
  detectedAt,
}: {
  limit: number;
  sinkId: string;
  detectedAt: Date | null;
  eventStream: Pick<
    EventStreamDefinition,
    'rel' | 'entityId' | 'identifier' | 'name' | 'sinks'
  >;
}) => {
  const client = useTenantedClient();
  const result = useSuspenseQuery({
    queryKey: [
      'warehouse.dryRunSink',
      { sinkId, limit, detectedAt, eventStream },
    ],
    queryFn: () =>
      client.warehouse.dryRunSink({
        sinkId,
        limit: Number(limit),
        detectedAt,
        eventStream: {
          name: eventStream.name,
          entityId: eventStream.entityId,
          identifier: eventStream.identifier,
          rel: eventStream.rel,
          sinks: eventStream.sinks,
        },
      }),
  });

  if (result.data.isErr()) {
    return (
      <Center>
        <Alert
          variant="error"
          message="Error"
          detail={JSON.stringify(result.data.error)}
        />
      </Center>
    );
  }

  if (result.data.value.jobs.length === 0) {
    return (
      <Center>
        <Text.P>No jobs found</Text.P>
      </Center>
    );
  }

  return (
    <div className="mt-4 overflow-scroll">
      <Divider className="mb-4" />
      <div className="grid grid-cols-3 space-x-2 overflow-scroll">
        {result.data.value.jobs.map((job, i) => (
          <Card.Container key={i}>
            <Card.Header>
              <Card.Title>{job.name}</Card.Title>
            </Card.Header>
            <Card.Content className="overflow-scroll">
              <pre className="text-sm">
                {JSON.stringify(job.params, null, 2)}
              </pre>
            </Card.Content>
          </Card.Container>
        ))}
      </div>
    </div>
  );
};
