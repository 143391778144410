import EventEmitter from 'events';

export class CancelablePromise<T> extends Promise<T> {
  private _promise: Promise<T>;
  private emitter: EventEmitter = new EventEmitter();

  constructor(promise: Promise<T>, props?: { controller?: AbortController }) {
    super(() => {});
    props?.controller?.signal?.addEventListener('abort', () => {
      this.emitter.emit('abort');
    });
    const cancellable = new Promise<T>((_resolve, reject) => {
      this.emitter.on('abort', () => {
        reject('Canceled');
      });
    });

    this._promise = Promise.race([promise, cancellable]);
  }

  override then<TResult1 = T, TResult2 = never>(
    onfulfilled?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | undefined
      | null,
    onrejected?:
      | ((reason: any) => TResult2 | PromiseLike<TResult2>)
      | undefined
      | null
  ): Promise<TResult1 | TResult2> {
    return this._promise.then(onfulfilled, onrejected) as Promise<
      TResult1 | TResult2
    >;
  }

  override catch(...args: Parameters<Promise<T>['catch']>): Promise<T> {
    return this._promise.catch(...args) as Promise<T>;
  }

  override finally(onfinally?: (() => void) | undefined | null): Promise<T> {
    return this._promise.finally(onfinally) as Promise<T>;
  }

  cancel(): void {
    this.emitter.emit('abort');
  }
}
