export const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full animate-pulse"
      viewBox="0 0 1024 1024"
      version="1.1"
    >
      <path d="M179.2 421.376h128v327.68H179.2z" fill="#D1D1D1" />
      <path d="M747.52 354.816h128v394.24h-128z" fill="#9C9C9C" />
      <path d="M558.08 267.776h128v481.28h-128z" fill="#D1D1D1" />
      <path d="M368.64 103.936h128v645.12H368.64z" fill="#9C9C9C" />
      <path d="M73.216 828.416h870.4v58.88h-870.4z" fill="#D1D1D1" />
    </svg>
  );
};
