import { makePopover } from '../../headless/popover';
import { Button } from '../../ui/button';
import { FormComponentProps } from '../../types/form-component';
import { TextInput } from '../input';

const Popover = makePopover(Button);

type Props = {
  value: [number, number];
  onChange: (min: number | null, max: number | null) => void;
  className?: string;
} & FormComponentProps;

export const MinMaxInput: React.FC<Props> = ({
  icon,
  disabled,
  compact,
  label,
  value: [min, max],
  onChange,
  theme = 'regular',
  className,
}) => {
  return (
    <Popover
      as={Button}
      className={className}
      theme={theme}
      disabled={disabled}
      label={label}
      compact={compact}
      icon={icon}
      text={min !== null && max !== null ? `${min} - ${max}` : ''}
      anchor="bottom start"
      panel={{
        view: (
          <>
            <div className="flex flex-col space-y-2 px-3 py-4">
              <TextInput
                autoFocus
                label="Min"
                type="number"
                compact
                className="border border-divider rounded px-2"
                value={String(min) ?? ''}
                onChange={(e) => onChange(Number(e), max)}
              />
              <TextInput
                label="Max"
                type="number"
                compact
                className="border border-divider rounded px-2"
                value={String(max) ?? ''}
                onChange={(e) => onChange(min, Number(e))}
              />
            </div>
          </>
        ),
      }}
    />
  );
};
