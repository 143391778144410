import { useUpdateCursorStatus } from '@cotera/client/app/pages/ide/events/hooks';
import { Button } from '@cotera/client/app/components';

export const PauseSinkButton: React.FC<{
  entityId: string;
  streamId: string;
  sinkId: string;
}> = ({ entityId, streamId, sinkId }) => {
  const updateStatus = useUpdateCursorStatus({
    id: {
      entityId,
      streamId,
      sinkId,
    },
  });

  return (
    <Button
      icon="pause"
      text={`Pause sink`}
      theme="warning"
      onClick={() => updateStatus.mutateAsync('paused')}
    />
  );
};
