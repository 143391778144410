import React from 'react';
import { LogoComponent } from './logo-svg';
import { classNames } from '@cotera/client/app/components/utils';

export interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className = '' }) => {
  return (
    <div className={classNames('flex flex-row', className)}>
      <div className="w-4 items-center my-auto ml-0.5">
        <LogoComponent />
      </div>
      <span className="ml-2.5 font-semibold text-2xl text-indigo-800 tracking-tight mb-0.5">
        cotera
      </span>
    </div>
  );
};
