import { TC, Ty } from '@cotera/era';

export const generateExamplesForCategories = (
  columns: Record<string, Ty.ExtendedAttributeType>,
  data: Record<string, any>[]
) => {
  const sampleData = data[0] || {}; // Use first row as a sample
  const examplesByCategory: Record<
    string,
    { example: string; output: string; column: string }[]
  > = {};

  for (const [columnName, type] of Object.entries(columns)) {
    const sampleValue =
      sampleData[columnName] !== undefined
        ? sampleData[columnName]
        : 'sampleValue';

    if (TC.isBoolean(type)) {
      examplesByCategory['Logical Operations'] = [
        ...(examplesByCategory['Logical Operations'] || []),
        {
          example: `"${columnName}" && true`,
          output: `${sampleValue && true}`,
          column: columnName,
        },
        {
          example: `not("${columnName}")`,
          output: `${!sampleValue}`,
          column: columnName,
        },
      ];
    }
    if (TC.isStringLike(type)) {
      examplesByCategory['String Concatenation'] = [
        ...(examplesByCategory['String Concatenation'] || []),
        {
          example: `"${columnName}" |> concat(' foo')`,
          output: `"${sampleValue} foo"`,
          column: columnName,
        },
      ];
      examplesByCategory['Pattern Matching'] = [
        ...(examplesByCategory['Pattern Matching'] || []),
        {
          example: `“${columnName}" |> like('prefix%')`,
          output: `${String(sampleValue)?.startsWith('prefix')}`,
          column: columnName,
        },
      ];
    }
    if (TC.isNumeric(type)) {
      examplesByCategory['Arithmetic Operations'] = [
        ...(examplesByCategory['Arithmetic Operations'] || []),
        { example: `1 + 2 * 3`, output: `7`, column: columnName },
        {
          example: `"${columnName}" + 5`,
          output: `${sampleValue + 5}`,
          column: columnName,
        },
      ];
      examplesByCategory['Comparison'] = [
        ...(examplesByCategory['Comparison'] || []),
        {
          example: `"${columnName}" > 5`,
          output: `${sampleValue > 5}`,
          column: columnName,
        },
      ];
    }
    if (TC.isArray(type)) {
      examplesByCategory['Array Declaration'] = [
        ...(examplesByCategory['Array Declaration'] || []),
        { example: `[1, 2, 3]`, output: `[1, 2, 3]`, column: columnName },
        {
          example: `["${columnName}"[0] + 1, 2]`,
          output: `[${sampleValue[0] + 1}, 2]`,
          column: columnName,
        },
      ];
    }
    if (TC.isStruct(type)) {
      examplesByCategory['Struct Declaration'] = [
        ...(examplesByCategory['Struct Declaration'] || []),
        {
          example: `{ foo: "${columnName}" }`,
          output: `{ foo: ${JSON.stringify(sampleValue)} }`,
          column: columnName,
        },
      ];
      examplesByCategory['Access Struct'] = [
        ...(examplesByCategory['Access Struct'] || []),
        {
          example: `"${columnName}" |> get_from_record("foo")`,
          output: `value from ${columnName}`,
          column: columnName,
        },
      ];
    }
  }

  return examplesByCategory;
};
