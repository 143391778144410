import {
  EraHash,
  Asc,
  From,
  InformationSchema,
  Relation,
  AST,
  Driver,
  Count,
} from '@cotera/era';
import { z } from 'zod';
import type { Schedule } from '@cotera/api';
import { Assert } from '@cotera/utilities';

const MAT_PREFIX = '_mat';

export type Materialization = {
  readonly schema: string;
  readonly viewName: string;
  readonly schedule: Schedule;
  readonly source: AST.RelFR;
};

export class MaterializationConfig {
  readonly underlyingRel: Relation;
  readonly view: Relation;
  readonly config: Omit<Materialization, 'schedule'>;

  static fromConf(conf: {
    viewName: string;
    schema: string;
    source: Relation;
  }): MaterializationConfig {
    return new MaterializationConfig(conf);
  }

  get source(): Relation {
    return Relation.wrap(this.config.source);
  }

  constructor(conf: { viewName: string; schema: string; source: Relation }) {
    const rel = Relation.wrap(conf.source);

    this.underlyingRel = rel;
    this.config = { ...conf, source: rel.ast };
    this.view = From({
      schema: conf.schema,
      name: conf.viewName,
      attributes: rel.attributes,
    });
  }

  async update(
    driver: Driver.EraDriver,
    schema: string,
    opts: {
      now?: Date;
      cleanUnused: boolean;
      statementTimeoutMs: number;
      requireAtLeastOneRowToSwapNewTableIn: boolean;
    }
  ): Promise<{ underlying: AST.TableDescription }> {
    const underlying = await driver.createTableFrom(
      {
        name: this.#sourceTableNameForTimestamp(opts?.now ?? new Date()),
        schema,
      },
      Relation.wrap(this.config.source)
    );

    if (opts.requireAtLeastOneRowToSwapNewTableIn) {
      const [row, ...rest] = await From(underlying)
        .summary((_) => ({ count: Count() }))
        .execute(driver);

      Assert.assert(row !== undefined && rest.length === 0);
      const count = row['count'];
      Assert.assert(count !== undefined && typeof count === 'number');
      if (count < 1) {
        throw new Error(
          `Table ${driver.dialect.relation(underlying)} has no rows`
        );
      }
    }

    const replaceRes = await driver.createOrReplaceView(
      { name: this.config.viewName, schema },
      From(underlying)
    );

    Assert.assert(replaceRes.isOk());

    if (opts?.cleanUnused) {
      const existing = await this.existingMaterializations({ driver, schema });
      const unused = existing.filter((t) => t.table_name !== underlying.name);

      for (const { table_name } of unused) {
        await driver.dropTable({ name: table_name, schema });
      }
    }

    return { underlying };
  }

  async existingMaterializations(params: {
    schema: string;
    driver: Driver.EraDriver;
  }): Promise<{ table_name: string }[]> {
    const { schema, driver } = params;
    const existing = await InformationSchema({
      schemas: [schema],
      type: 'tables',
    })
      .where((t) => t.attr('table_name').like(`${this.#sourcePrefix()}_%`))
      .orderBy((t) => Asc(t.attr(`table_name`)))
      .execute(driver);

    return z.object({ table_name: z.string() }).array().parse(existing);
  }

  #sourceTableNameForTimestamp(ts: Date): string {
    return `${this.#sourcePrefix()}_${toUnixTimestampSecs(ts)}`;
  }

  #sourcePrefix(): string {
    return `${MAT_PREFIX}_${this.config.viewName}_${this.#sourceHash()}`;
  }

  #sourceHash(): string {
    return EraHash.hashRel(this.config.source).slice(0, 12);
  }
}

const toUnixTimestampSecs = (t: Date) => Math.floor(t.getTime() / 1000);
