import { Contract, GET } from '@cotera/contracts';
import z from 'zod';

export const RuntimeDataContract = Contract.new({
  decisionTreeOutputs: GET({
    params: z.object({}),
    output: z.object({
      data: z.record(
        z
          .object({
            variant: z.string(),
            column: z.string(),
          })
          .array()
      ),
    }),
    errors: z.never(),
  }),
});
