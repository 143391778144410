import React from 'react';
import { InputProps, ObjectErrors } from './input.props';
import { z } from 'zod';
import { ZodInput } from './input';

export const ObjectInput: React.FC<
  InputProps<z.ZodObject<any>> & { errors?: ObjectErrors }
> = ({
  zodSchema,
  name,
  wrapper = ({ children }) => children,
  className,
  errors,
  hideOptionals,
}) => {
  return (
    <>
      {Object.entries(zodSchema.shape).map(([nestedParamName, schema]) => (
        <ZodInput
          errors={errors}
          wrapper={({ schema, children }) =>
            wrapper({ schema: schema as any, children, name: nestedParamName })
          }
          className={className}
          key={nestedParamName}
          name={`${name}.${nestedParamName}`}
          optional={schema instanceof z.ZodOptional}
          zodSchema={schema as z.ZodTypeAny}
          hideOptionals={hideOptionals}
        />
      ))}
    </>
  );
};
