import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateCursorStatus = (props: {
  id: {
    entityId: string;
    streamId: string;
    sinkId: string;
  };
}) => {
  const org = useWhoami((s) => s.org);
  const client = useTenantedClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (status: 'unpaused' | 'paused') =>
      client.warehouse.setSinkPauseStatus({ ...props, status }),
    onSuccess: async (result) => {
      if (result.isOk()) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.warehouse.sinkCursors({ orgId: org.id }),
        });
      }
    },
  });
};

export const useSinkCursors = () => {
  const org = useWhoami((s) => s.org);
  const client = useTenantedClient();

  return useSuspenseQuery({
    queryKey: queryKeys.warehouse.sinkCursors({ orgId: org.id }),
    queryFn: async () => {
      const response = await client.warehouse.sinkCursors({});
      return Assert.assertOk(response);
    },
  });
};
