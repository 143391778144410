import React from 'react';
import { CompileMarkup } from '../compiler';
import { AST, Expression, MakeArray } from '@cotera/era';
import { useTypedExpressionValue } from './scopes';
import { z } from 'zod';
import _ from 'lodash';

const NOOP = Object.freeze({ t: 'noop' });

export const RenderMacroMarkupCase: React.FC<{
  section: AST._MacroMarkupCase;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  const evaluated = useTypedExpressionValue(
    MakeArray(
      section.cases.map((x) => Expression.fromAst(x.when).coalesce(false))
    ),
    z.boolean().array()
  );

  const enumerated = evaluated.map((res, i) => [res, i] as const);

  for (const [res, i] of enumerated) {
    if (res) {
      const mu: AST.Mu = section.cases[i]?.then ?? NOOP;
      return <CompileMarkup key={i} section={mu} inBlock={inBlock} />;
    }
  }

  return <CompileMarkup key="else" inBlock={inBlock} section={section.else} />;
};
