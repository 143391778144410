import React, { useCallback, useEffect, useRef } from 'react';

interface DragHandleProps {
  onResize: (delta: number) => void;
  onResizeFinished?: () => void;
}

export const DragHandle: React.FC<DragHandleProps> = ({
  onResize,
  onResizeFinished,
}) => {
  const isDragging = useRef(false);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    isDragging.current = true;
    document.body.style.userSelect = 'none'; // Disable text selection globally
  };

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (isDragging.current) {
        onResize(event.movementX); // Pass the horizontal movement delta
      }
    },
    [onResize]
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging.current) {
      isDragging.current = false;
      document.body.style.userSelect = ''; // Re-enable text selection
      onResizeFinished?.();
    }
  }, [onResizeFinished]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <div
      style={{
        cursor: 'ew-resize',
      }}
      className="hover:bg-indigo-300 h-full w-[4px] transition-colors duration-150"
      onMouseDown={handleMouseDown}
    />
  );
};
