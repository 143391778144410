import { classNames, ChildrenProps } from '@cotera/client/app/components/utils';
import { ElementType } from 'react';

export const Form: React.FC<
  { className?: string; as?: ElementType } & Partial<ChildrenProps>
> = ({ className, children, as: As = 'span' }) => (
  <As
    className={classNames(
      'inline-block bg-gray-200 rounded animate-pulse',
      className
    )}
  >
    {children}
  </As>
);
