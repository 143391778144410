import { Text, H4 } from '@cotera/client/app/components/ui/typography';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { useEffect, useRef, useState } from 'react';
import { useChartContext } from './context/context';
import { ChartAction } from './types';
import { Button, Legend, Tooltip } from '@cotera/client/app/components/ui';

const TITLE_POSITION_CLASS = {
  left: 'text-left',
  center: 'text-center',
};

type ChartLayoutProps = {
  name?: string;
  chartHeight?: number;
  className?: string;
  titlePosition?: 'left' | 'center';
  menuItems?: ChartAction[];
  menuItemHandlers?: Record<string, (data: Record<string, unknown>[]) => void>;
  labels?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  hideLabels?: boolean;
  titleBorder?: boolean;
  onLegendClick?: (labels: { id: string; hidden: boolean }[]) => void;
};

const ClearIcon = ({
  className,
  active,
  onClick,
}: {
  className?: string;
  active?: boolean;
  onClick: () => void;
}) => {
  const hiddenItems = useChartContext((s) => s.hiddenItems);

  return (
    <Button
      icon="x-mark"
      inline
      active={active}
      onClick={onClick}
      className={classNames(
        className,
        'hover:text-primary-text',
        hiddenItems.length > 0 ? 'text-muted-text' : 'text-zinc-300'
      )}
    />
  );
};

export const DEFAULT_MENU_ITEMS: ChartAction[] = [
  {
    id: 'fullscreen',
    label: 'Fullscreen',
    icon: ({ className, active, onClick }) => (
      <Button
        inline
        icon="arrows-pointing-out"
        className={className}
        active={active}
        onClick={onClick}
      />
    ),
  },
  {
    id: 'clear-selection',
    label: 'Clear Selection',
    icon: ClearIcon,
  },
];

export function ChartLayout({
  name,
  className,
  chartHeight,
  children,
  titlePosition = 'left',
  menuItems,
  menuItemHandlers,
  labels: axisLabels,
  hideLabels,
  titleBorder = true,
  onLegendClick: onLegendToggle,
}: ChartLayoutProps & ChildrenProps) {
  const labels = useChartContext((s) => s.labels);
  const data = useChartContext((s) => s.data);
  const toggleItem = useChartContext((s) => s.actions.toggle);
  const focusItem = useChartContext((s) => s.actions.focus);
  const clearFocus = useChartContext((s) => s.actions.clearFocus);
  const hiddenItems = useChartContext((s) => s.hiddenItems);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuItemClickHandlers: Record<
    string,
    (data: Record<string, unknown>[]) => PromiseLike<void>
  > = {
    ...menuItemHandlers,
    fullscreen: async () => {
      if (!isFullScreen) {
        if (containerRef.current?.requestFullscreen) {
          await containerRef.current?.requestFullscreen();
          setIsFullScreen(true);
        }
      } else {
        if (document.exitFullscreen !== undefined) {
          await document.exitFullscreen();
          setIsFullScreen(false);
        }
      }
    },
    'clear-selection': async () => {
      clearFocus();
    },
  };

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullScreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, [setIsFullScreen]);

  return (
    <div
      ref={containerRef}
      className={classNames(
        className,
        'flex flex-col justify-center items-center w-full bg-white',
        isFullScreen ? 'p-16 p-[90px]' : 'h-full'
      )}
    >
      {name && (
        <H4
          className={classNames(
            'pb-4 w-full relative',
            TITLE_POSITION_CLASS[titlePosition],
            titleBorder ? 'border-b border-divider ' : ''
          )}
        >
          {name}
        </H4>
      )}

      <div className="h-full w-full flex flex-col relative items-center">
        <div
          className="w-full h-full flex"
          style={{ height: !isFullScreen ? chartHeight : undefined }}
        >
          <div
            className={classNames(
              'flex flex-col h-full',
              (menuItems?.length ?? 0) > 0 ? 'w-[calc(100%-15px)]' : 'w-full'
            )}
          >
            {axisLabels?.top && (
              <Text.Caption className="text-sm text-center">
                {axisLabels.top}
              </Text.Caption>
            )}
            <div className="h-full w-full flex flex-row justify-between">
              {axisLabels?.left && (
                <Text.Caption
                  className="text-sm text-center rotate-180"
                  style={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'mixed',
                  }}
                >
                  {axisLabels.left}
                </Text.Caption>
              )}
              {children}
              {axisLabels?.right && (
                <Text.Caption
                  className="text-sm text-center"
                  style={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'mixed',
                  }}
                >
                  {axisLabels.right}
                </Text.Caption>
              )}
            </div>
            {axisLabels?.bottom && (
              <Text.Caption className="text-sm text-center">
                {axisLabels.bottom}
              </Text.Caption>
            )}
          </div>
          <div
            className={classNames(
              'flex flex-col h-full',
              (menuItems?.length ?? 0) > 0 ? 'w-[15px]' : ''
            )}
          >
            <ul className="mt-4 mr-2">
              {menuItems?.map(({ icon: Icon, label, id, active }, i) => (
                <li key={i}>
                  <Tooltip text={label} side="left">
                    <Icon
                      active={active}
                      onClick={async () => {
                        await (menuItemClickHandlers[id] &&
                          menuItemClickHandlers[id]!(data));
                      }}
                    />
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {!hideLabels && (
          <Legend
            labels={labels.map((d) => ({
              ...d,
              hidden: hiddenItems.includes(d.label),
            }))}
            onClick={(id) => {
              if (labels.every((l) => l.label === id)) {
                clearFocus();
                onLegendToggle?.(
                  labels.map((d) => ({
                    id: d.label,
                    hidden: false,
                  }))
                );
              } else {
                const hiddenItems = toggleItem(id);
                onLegendToggle?.(
                  labels.map((d) => ({
                    id: d.label,
                    hidden: hiddenItems.includes(d.label),
                  }))
                );
              }
            }}
            onDoubleClick={(id) => {
              focusItem(id);
              onLegendToggle?.(
                labels.map((d) => ({
                  id: d.label,
                  hidden: d.label !== id,
                }))
              );
            }}
          />
        )}
      </div>
    </div>
  );
}
