import { FunctionIdentifier } from '../../ast/func-identifier';

export const FUNCTION_ARITY: Record<
  FunctionIdentifier,
  number | { gte: number }
> = {
  format: { gte: 0 },
  and: { gte: 1 },
  or: { gte: 1 },
  concat: { gte: 1 },
  mul: 2,
  div: 2,
  add: 2,
  sub: 2,
  not: 1,
  to_the_power_of: 2,
  eq: 2,
  neq: 2,
  gt: 2,
  gte: 2,
  lt: 2,
  lte: 2,
  coalesce: 2,
  abs: 1,
  length: 1,
  split_part: 3,
  replace: 3,
  floor: 1,
  ceil: 1,
  lower: 1,
  upper: 1,
  now: 0,
  like: 2,
  ln: 1,
  log_2: 1,
  log_10: 1,
  cosine_distance: 2,
  null_if: 2,
  is_nan: 1,
  is_numeric_string: 1,
  tag: 2,
  implements: 2,
  null_of: 1,
  type_of: 1,
  random: 0,
  gen_random_uuid: 0,
  percentile_cont: 2,
  percentile_disc: 2,
  round: 2,
  nan: 0,
  is_null: 1,
  impure: 1,
  sum: 1,
  count: 1,
  count_distinct: 1,
  avg: 1,
  min: 1,
  max: 1,
  corr: 2,
  stddev_samp: 1,
  stddev_pop: 1,
  string_agg: 2,
  array_agg: 1,
  date_diff: 3,
  date_add: 3,
  date_trunc: 2,
  date_part: 2,
  get_from_record: 2,
};
