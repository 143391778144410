import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  Column,
  getPaginationRowModel,
  Table as ITable,
  getFilteredRowModel,
  FilterFn,
  SortingState,
  SortDirection,
  getSortedRowModel,
  createColumnHelper,
  VisibilityState,
  OnChangeFn,
  ColumnFiltersState,
} from '@tanstack/react-table';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ClipboardIcon,
  ClipboardDocumentListIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Text } from '@cotera/client/app/components/ui';
import {
  ChildrenProps,
  classNames,
  downloadFile,
  jsonToCsv,
} from '@cotera/client/app/components/utils';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@cotera/client/app/components/headless/dropdown';
import { RowAction, RowActions } from './row-actions';
import {
  JsonViewer,
  usePagination,
  Pagination as ControlledPagination,
} from '@cotera/client/app/components/app';
import { SlideOver, toast, Button } from '@cotera/client/app/components/ui';
import { useDataGridStore } from './store';
import { DISPLAY_COLUMN_SIZE } from './constants';
import { sortBy } from 'lodash';
import { useDeepCompareEffect, useMeasure } from 'react-use';
import { Inputs } from '@cotera/client/app/components/forms';
import { Ty } from '@cotera/era';
import { ColumnAction } from './special-columns';
import { RunTopicMatchingView } from './special-columns/run-topic-matching';
import { getActionName, isAction } from './utils';
import { FFI } from '@cotera/sdk/core';

export type DatagridMenuItem = {
  name: string;
  icon: JSX.Element;
  onClick: () => void | Promise<void>;
};
export type Props = {
  className?: string;
  defaultRowCount?: number;
  data: Record<string, unknown>[];
  columns: Column<unknown>[];
  columnVisibility?: VisibilityState;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  menuItems?: DatagridMenuItem[];
  name?: string;
  rowActions?: RowAction[];
  minHeight?: string;
  totalRows: number;
  columnTypes: Record<string, Ty.ExtendedAttributeType>;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(String(row.getValue(columnId)), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const columnHelper = createColumnHelper();

const DISPLAY_COLUMN_ID = 'actions';
const displayColumn = (actions: RowAction[]) =>
  columnHelper.display({
    id: DISPLAY_COLUMN_ID,
    maxSize: DISPLAY_COLUMN_SIZE,
    minSize: DISPLAY_COLUMN_SIZE,
    header: () => <div className="bg-white h-full w-full"></div>,
    cell: (props) => (
      <RowActions actions={actions} row={props.row} cell={props.cell} />
    ),
  });

const DEFAULT_COLUMN_SIZE = 150;

export const DataGrid: React.FC<Props> = (props) => {
  return (
    <ControlledPagination.Container
      rowsPerPage={props.defaultRowCount}
      totalRows={props.totalRows}
    >
      <InnerDataGrid {...props} />
    </ControlledPagination.Container>
  );
};
const InnerDataGrid: React.FC<Props> = ({
  data,
  columns,
  className,
  columnVisibility,
  onColumnVisibilityChange,
  menuItems = [],
  rowActions = [],
  minHeight,
  columnTypes,
}) => {
  const page = usePagination((s) => s.page);
  const rowsPerPage = usePagination((s) => s.rowsPerPage);
  const setPage = usePagination((s) => s.actions.setPage);
  const setPageSize = usePagination((s) => s.actions.setPageSize);

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [statefulColumnVisibility, setColumnVisibility] = React.useState({});
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const table = useReactTable({
    initialState: {
      pagination: {
        pageSize: rowsPerPage,
        pageIndex: page,
      },
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    data,
    columns: [displayColumn(rowActions), ...columns],
    defaultColumn: {
      minSize: Math.max(
        DEFAULT_COLUMN_SIZE - (DISPLAY_COLUMN_SIZE / columns.length - 1),
        width / columns.length -
          ((DISPLAY_COLUMN_SIZE + 5) / columns.length - 1)
      ),
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      sorting,
      columnVisibility: columnVisibility ?? statefulColumnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: onColumnVisibilityChange ?? setColumnVisibility,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  const {
    detail: { open, name, value, links = {} },
    closeDetail,
  } = useDataGridStore();

  useDeepCompareEffect(() => {
    const pagination = table.getState().pagination;
    setPage(pagination.pageIndex);
    setPageSize(pagination.pageSize);
  }, [table.getState().pagination]);

  return (
    <div
      className="h-full w-full flex flex-col"
      style={{
        minHeight,
      }}
      ref={ref}
    >
      <SlideOver title={name ?? ''} open={open} setOpen={() => closeDetail()}>
        <JsonViewer json={value} />
        <div>
          <ClipboardIcon
            className="ml-auto h-6 w-6 text-gray-500 cursor-pointer mt-2"
            onClick={async () => {
              if (data !== undefined) {
                const text =
                  value instanceof Date ? value.toISOString() : String(value);
                await navigator.clipboard.writeText(text);
                toast.info('Copied query to clipboard');
              }
            }}
          />
        </div>
        <div className="flex flex-col space-y-4 py-4">
          {sortBy(Object.entries(links), ([name]) => name).map(([name, go]) => (
            <Button
              text={name}
              key={name}
              onClick={() => {
                closeDetail();
                go(value);
              }}
              inline
            />
          ))}
        </div>
      </SlideOver>
      <div className="h-full w-full overflow-x-auto">
        <table
          className={classNames(
            'flex flex-col text-gray-800 text-sm overflow-y-auto h-full overflow-x-hidden',
            className
          )}
          style={{
            width: table.getCenterTotalSize(),
          }}
        >
          <thead className="sticky top-0 bg-white z-[1]">
            <tr className="flex flex-row">
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      style={{
                        width: header.getSize(),
                      }}
                      className="relative flex flex-row border-r border-divider border-b shadow-sm font-normal text-left"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.id !== DISPLAY_COLUMN_ID && (
                        <>
                          <ColumnMenu
                            column={header.column}
                            table={table}
                            type={columnTypes[header.id]}
                          />
                          <div
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className="w-[4px] h-full hover:bg-secondary-background rounded -mr-[2px] cursor-ew-resize"
                          />
                        </>
                      )}
                    </th>
                  );
                })
              )}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={classNames(
                  'flex flex-row',
                  row.getIsSelected()
                    ? 'bg-indigo-50'
                    : 'bg-white hover:bg-muted-background'
                )}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <React.Fragment key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer>
        <Total table={table} />
        <div className="flex items-center">
          <Pagination table={table} />
          <FooterMenu table={table} menuItems={menuItems} />
        </div>
      </Footer>
    </div>
  );
};

const FooterMenu: React.FC<{
  table: ITable<any>;
  menuItems: DatagridMenuItem[];
}> = ({ table, menuItems }) => {
  return (
    <Dropdown>
      <DropdownTrigger>
        <EllipsisVerticalIcon className="w-5 h-5 text-standard-text cursor-pointer" />
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          icon={<XCircleIcon className="h-4 w-4" />}
          active={false}
          onClick={async () => table.setColumnFilters([])}
        >
          Clear Filters
        </DropdownItem>
        <DropdownItem
          icon={<ClipboardDocumentIcon className="h-4 w-4" />}
          active={false}
          onClick={async () => {
            downloadFile(
              [
                JSON.stringify(
                  table.getFilteredRowModel().rows.map((x) => x.original)
                ),
              ],
              'json'
            );
          }}
        >
          Download as JSON
        </DropdownItem>
        <DropdownItem
          icon={<ClipboardDocumentListIcon className="h-4 w-4" />}
          active={false}
          onClick={async () => {
            downloadFile(
              [
                await jsonToCsv(
                  table.getFilteredRowModel().rows.map((x) => x.original)
                ),
              ],
              'csv'
            );
          }}
        >
          Download as CSV
        </DropdownItem>
        {menuItems.map(({ name: title, icon, onClick }) => (
          <DropdownItem
            key={title}
            icon={icon}
            active={false}
            onClick={onClick}
          >
            {title}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

const ROW_SIZES = [10, 20, 30, 40, 50] as const;

const Pagination = ({ table }: { table: ITable<any> }) => {
  const startRowDisplay =
    table.getState().pagination.pageIndex *
      table.getState().pagination.pageSize +
    1;
  return (
    <div className="flex items-center gap-2">
      <Text.Caption className="text-sm text-black">Rows per page:</Text.Caption>
      <select
        className="mr-4 border-0 focus:ring-0 focus:outline-none active:bg-gray-200 bg-gray-100 rounded px-4 pr-8 text-sm text-gray-500"
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {ROW_SIZES.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <Text.Caption className="text-sm text-black mr-4">
        {startRowDisplay} -&nbsp;
        {startRowDisplay + table.getRowModel().rows.length - 1} of{' '}
        {table.getFilteredRowModel().rows.length}
      </Text.Caption>
      <button
        className="text-semibold"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeftIcon
          height={15}
          className={classNames(
            'text-semibold',
            !table.getCanPreviousPage() ? 'text-muted-text' : ''
          )}
        />
      </button>
      <button
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRightIcon
          height={15}
          className={classNames(
            'text-semibold',
            !table.getCanNextPage() ? 'text-muted-text' : ''
          )}
        />
      </button>
    </div>
  );
};

const Footer = ({
  children,
  className,
}: ChildrenProps & { className?: string }) => {
  return (
    <div
      className={classNames(
        'border-t border-divider flex items-center flex-row pt-4 w-full justify-between',
        className
      )}
    >
      {children}
    </div>
  );
};

const Total = ({ table }: { table: ITable<any> }) => {
  return (
    <Text.Caption className="text-sm text-black">
      Total: {table.getFilteredRowModel().rows.length}
    </Text.Caption>
  );
};

const ColumnMenu = ({
  table,
  column,
  type,
}: {
  table: ITable<any>;
  column: Column<any, any>;
  type?: Ty.ExtendedAttributeType;
}) => {
  const isSorted = column.getIsSorted();

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const sortState: 'none' | SortDirection = !isSorted ? 'none' : isSorted;
  const [currentAction, setCurrentAction] =
    React.useState<FFI.FiiFuncName | null>(null);

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <EllipsisVerticalIcon className="w-5 h-5 absolute right-0 top-0 cursor-pointer" />
        </DropdownTrigger>
        <DropdownContent>
          <Filter column={column} table={table} />
          <DropdownItem
            icon={<ArrowUpIcon className="h-4 w-4" />}
            active={sortState === 'asc'}
            onClick={() => column.toggleSorting(false)}
          >
            Sort Asc
          </DropdownItem>
          <DropdownItem
            icon={<ArrowDownIcon className="h-4 w-4" />}
            active={sortState === 'desc'}
            onClick={() => column.toggleSorting(true)}
          >
            Sort Desc
          </DropdownItem>
          <DropdownItem
            icon={<ArrowsUpDownIcon className="h-5 w-5" />}
            active={sortState === 'none'}
            onClick={() => column.clearSorting()}
          >
            No Sort
          </DropdownItem>
          {type && isAction(type) && (
            <ColumnAction
              t={type}
              table={table}
              column={column}
              func={getActionName(type)}
              onClick={setCurrentAction}
            />
          )}
          <DropdownItem
            icon={<XMarkIcon className="h-5 w-5" />}
            active={column.getIsFiltered()}
            onClick={() => column.toggleVisibility(false)}
          >
            Hide
          </DropdownItem>
        </DropdownContent>
      </Dropdown>
      {type && isAction(type) && (
        <RunTopicMatchingView
          show={currentAction === '@@cotera-topic-matching'}
          column={column}
          table={table}
          onClose={() => {
            setCurrentAction(null);
          }}
        />
      )}
    </>
  );
};

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ITable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return (
    <div className="flex items-center px-2">
      <MagnifyingGlassIcon className="h-4 w-4 mr-3" />
      <Inputs.Text
        autoFocus
        placeholder={column.id}
        type={firstValue === 'number' ? 'number' : 'text'}
        value={(columnFilterValue ?? '') as string}
        onChange={(e) => {
          column.setFilterValue(e);
        }}
        className="font-normal border p-0 text-sm mr-2"
      />
    </div>
  );
}
