import { NotFound } from '@cotera/client/app/components/app/not-found';
import { Layout } from '@cotera/client/app/layout';

export const NotFoundPage = () => {
  return (
    <Layout>
      <NotFound />
    </Layout>
  );
};
