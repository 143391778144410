import { Link, LinkProps } from 'react-router-dom';
import { classNames } from '@cotera/client/app/components/utils';

export const CoteraLink: React.FC<
  LinkProps & React.RefAttributes<HTMLAnchorElement>
> = ({ className = '', ...props }) => {
  return (
    <Link
      className={classNames(
        className,
        'text-primary-text hover:underline font-medium'
      )}
      {...props}
    />
  );
};
