import { AST } from '@cotera/era';
import { Row } from '../components/row';
import { classNames } from '@cotera/client/app/components/utils';

const SIZE_MAP = {
  lg: 'h-[450px]',
  md: 'h-[300px]',
  sm: 'h-[150px]',
} as const;

const ALIGNMENT = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

export const Image: React.FC<{
  section: AST._Image;
}> = ({ section }) => {
  return (
    <Row className={ALIGNMENT[section.align]}>
      <img
        src={section.uri}
        alt={section.description}
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
        className={classNames('mb-2', SIZE_MAP[section.size])}
      />
    </Row>
  );
};
