import { Caption } from './caption';
import { Span } from './span';
import { P } from './p';
import { H1, H2, H3, H4 } from '../typography';

export const Text = {
  Caption,
  Span,
  P,
  H1,
  H2,
  H3,
  H4,
};
