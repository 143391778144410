import { AST } from '../ast';
import { TypeCheckError } from './type-check-error';
import _ from 'lodash';

export const NO_URL: AST._UiState['url'] = Object.freeze({
  params: Object.freeze([]),
  path: null,
});

export const mergeUrlConfigs = (
  registers: AST._UiState['url'][],
  opts?: { requireUniqueParams?: boolean }
): AST._UiState['url'] | TypeCheckError => {
  const { requireUniqueParams = true } = opts ?? {};
  const [path, ...otherPaths] = _.compact(registers.map((x) => x.path));

  if (otherPaths.length > 0) {
    return new MultipleComponentsTryingToRegisterUrlPath();
  }

  const params: Set<string> = new Set();

  for (const paramSet of registers.map((reg) => reg?.params ?? [])) {
    for (const name of paramSet) {
      if (requireUniqueParams && params.has(name)) {
        return new UrlParameterRegisteredMultipleTimes({ name });
      }
      params.add(name);
    }
  }

  return Object.freeze({
    path: Object.freeze(path ?? null),
    params: Object.freeze([...params].sort()),
  });
};

class UrlParameterRegisteredMultipleTimes extends TypeCheckError {
  constructor(private readonly params: { readonly name: string }) {
    super();
  }
  protected override message(): string {
    return TypeCheckError.msg`Unable to register "${this.params.name}" multiple times`;
  }
}

class MultipleComponentsTryingToRegisterUrlPath extends TypeCheckError {
  protected override message(): string {
    throw new Error('Only one markup component can implement url');
  }
}
