import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { NamedAttr, DetailPageLinks } from './types';
import { useDataGridStore } from './store';
import { Link } from 'react-router-dom';
import { isAction, isLink } from './utils';
import { Action } from './ffi-actions';
import {
  ChildrenProps,
  classNames,
  Formatters,
} from '@cotera/client/app/components/utils';
import { Button, Text, toast } from '@cotera/client/app/components/ui';
import { DateDisplay } from '../../ui/typography';

export const BaseCell: React.FC<
  ChildrenProps & {
    className?: string;
    cell: CellContext<any, unknown>;
    noDetail?: boolean;
    links: DetailPageLinks;
  }
> = ({ children, className, cell, noDetail, links }) => {
  const openDetail = useDataGridStore((s) => s.openDetail);

  return (
    <td
      onClick={(e) => {
        if (!noDetail) {
          if (e.detail === 2) {
            openDetail({
              name: cell.column.id,
              value: cell.getValue(),
              links,
            });
          }
        }
      }}
      style={{
        width: cell.column.getSize(),
      }}
      className={classNames(
        'z-[0] flex flex-col justify-center px-3 py-2 border-r border-divider pb-2 border-b hover:bg-indigo-50',
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        cell.column.getIsSorted() ? 'bg-yellow-50' : '',
        className
      )}
    >
      <div className="inline-block relative overflow-hidden whitespace-nowrap text-ellipsis">
        {children}
      </div>
    </td>
  );
};

export const Cell: React.FC<{
  cell: CellContext<any, unknown>;
  attr: NamedAttr;
}> = ({ cell, attr }) => {
  let wrapper: (node: React.ReactNode) => React.ReactNode;

  if (isAction(attr.ty)) {
    const { func, params } = cell.getValue() as {
      func: string;
      params: Record<string, unknown>;
    };

    return (
      <BaseCell cell={cell} links={{}}>
        <Action func={func} params={params} />
      </BaseCell>
    );
  }

  if (isLink(attr.ty)) {
    wrapper = (node) => (
      <LinkCell cell={cell} attr={attr}>
        {node}
      </LinkCell>
    );
  } else if (Object.entries(attr.detailPages).length > 0) {
    wrapper = (node) => (
      <span className="justify-between flex hover:text-primary-text">
        {node}{' '}
      </span>
    );
  } else {
    wrapper = (x) => x;
  }

  const attrType =
    attr.ty.ty.k === 'struct' && isLink(attr.ty)
      ? attr.ty.ty.fields['text']!
      : attr.ty;

  const value = isLink(attr.ty)
    ? (cell.getValue() as { text: string })?.text
    : cell.getValue();

  if (value === null || value === undefined) {
    return (
      <BaseCell
        links={{}}
        cell={cell}
        className={classNames(
          attrType.ty.k === 'primitive' &&
            (attrType.ty.t === 'int' || attrType.ty.t === 'float')
            ? 'text-right'
            : ''
        )}
      >
        <Text.Caption>NULL</Text.Caption>
      </BaseCell>
    );
  }

  if (attrType.ty.k !== 'primitive' && attrType.ty.k !== 'enum') {
    return (
      <BaseCell
        links={attr.detailPages}
        cell={cell}
        className="text-left cursor-pointer"
      >
        <pre>{JSON.stringify(value)}</pre>
        <CopyButton cell={cell} />
      </BaseCell>
    );
  }

  switch (attrType.ty.t) {
    case 'int':
    case 'float':
      return (
        <BaseCell cell={cell} className="text-right" links={attr.detailPages}>
          {wrapper(Formatters.number(value as number))}
          <CopyButton cell={cell} position="left" />
        </BaseCell>
      );
    case 'timestamp':
      return (
        <BaseCell
          cell={cell}
          className="cursor-pointer"
          links={attr.detailPages}
        >
          {wrapper(<DateDisplay date={value as Date} time={true} />)}
          <CopyButton cell={cell} />
        </BaseCell>
      );
    default:
      return (
        <BaseCell cell={cell} links={attr.detailPages}>
          {wrapper(String(value))}
          <CopyButton cell={cell} />
        </BaseCell>
      );
  }
};

const LinkCell = ({
  cell,
  children,
}: {
  children: ChildrenProps['children'];
  cell: CellContext<any, unknown>;
  attr: NamedAttr;
}) => {
  const value = cell.getValue() as {
    __link: string;
    text: string;
    action: 'tab' | 'navigate';
  };
  return (
    <Link
      className="justify-between flex hover:text-primary-text"
      to={value.__link}
      target={value.action === 'tab' ? '_blank' : undefined}
    >
      {children}{' '}
    </Link>
  );
};

const CopyButton: React.FC<{
  cell: CellContext<any, unknown>;
  position?: 'left' | 'right';
}> = ({ cell, position = 'right' }) => {
  const [hovering, setHovering] = React.useState(false);

  return (
    <div
      className={classNames(
        'absolute -top-2.5 h-10 w-10',
        position === 'right' ? '-right-4' : '-left-2'
      )}
      onMouseOver={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <Button
        inline
        icon="clipboard"
        onClick={async (e) => {
          e.stopPropagation();
          await navigator.clipboard.writeText(String(cell.getValue()));
          toast.success('Copied to clipboard');
        }}
        className={classNames(hovering ? 'block' : 'hidden')}
      />
    </div>
  );
};
