import { z } from 'zod';
import { Contract, GET, POST } from '@cotera/contracts';
import { EtlContract } from './etl';
import { Parser } from '@cotera/era';
import { SinkSchema } from '../dev-server';
import { EntityColumnsContract } from './entity-columns';

export { EtlContract } from './etl';
export { FIVETRAN_CONNECTORS, FIVETRAN_CONNECTOR_SCHEMAS } from './fivetran';
export {
  EntityColumnsContract,
  EntityColumnAssumptionTableRefSchema,
  EntityColumnMetaSchema,
  EntityColumnInfoSchema,
} from './entity-columns';
export type {
  EntityColumnAssumptionTableRef,
  EntityColumnAssumptionMeta,
  EntityColumnMeta,
  EntityColumnInfo,
  EntityColumnCreateError,
  Entity,
  ExpressionColumnSchema,
} from './entity-columns';

const SinkIdSchema = z.object({
  entityId: z.string(),
  streamId: z.string(),
  sinkId: z.string(),
});

export const WarehouseContract = Contract.new({
  entityColumns: EntityColumnsContract,
  getPrimary: GET({
    params: z.object({}),
    output: z
      .object({
        displayName: z.string(),
        type: Parser.Credentials.WarehouseTypeSchema,
        readSchema: z.string(),
        writeSchema: z.string(),
      })
      .nullable(),
    errors: z.never(),
    mustBeAbleTo: [['read', 'warehouse']],
  }),
  setSinkCursorPosition: POST({
    params: z.object({
      id: SinkIdSchema,
      position: z.object({
        coteraStableId: z.string().optional(),
        detectedAt: z.coerce.date(),
      }),
    }),
    errors: z.never(),
    output: z.object({}),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  setSinkPauseStatus: POST({
    params: z.object({
      id: SinkIdSchema,
      status: z.enum(['paused', 'unpaused']),
    }),
    errors: z.never(),
    output: z.object({}),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  sinkCursors: GET({
    params: z.object({}),
    errors: z.never(),
    output: z.array(
      z.object({
        coteraStableId: z.string(),
        entityId: z.string(),
        id: z.string(),
        sinkId: z.string(),
        streamId: z.string(),
        detectedAt: z.coerce.date(),
        pausedAt: z.coerce.date().nullable(),
      })
    ),
  }),
  dryRunSink: POST({
    params: z.object({
      eventStream: z.object({
        rel: Parser.RelSchema,
        entityId: Parser.IdTypeSchema,
        name: z.string(),
        sinks: z.record(SinkSchema),
        identifier: Parser.ExprSchema,
      }),
      detectedAt: z.coerce.date().nullable(),
      limit: z.number().optional(),
      sinkId: z.string(),
    }),
    errors: z.never(),
    output: z.object({
      jobs: z.array(
        z.object({
          name: z.string(),
          params: z.record(z.unknown()),
        })
      ),
      eventsProcessed: z.number(),
      cursor: z
        .object({
          coteraStableId: z.string(),
          detectedAt: z.coerce.date(),
        })
        .nullable(),
    }),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  snapshotEventStream: POST({
    params: z.object({ eventStreamId: z.string() }),
    errors: z.object({ t: z.literal('NotFound') }),
    output: z.object({}),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  irForHash: GET({
    params: z.object({ sqlHash: z.string() }),
    output: Parser.RelSchema.nullable(),
    errors: z.never(),
    mustBeAbleTo: [['read', 'warehouse']],
  }),
  runMaterialization: POST({
    params: z.object({ viewName: z.string(), schema: z.string() }),
    output: z.object({}),
    errors: z.never(),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  createDevView: POST({
    params: z.object({
      ast: Parser.RelSchema,
      suffix: z.string(),
    }),
    output: z.object({
      name: z.string(),
    }),
    errors: z.object({
      errorType: z.enum([
        'WarehouseConnectionNotFound',
        'CantParameterize',
        'DriverError',
      ]),
      message: z.string(),
    }),
    mustBeAbleTo: [['write', 'warehouse']],
  }),
  provisionSnowflake: POST({
    params: z.object({}),
    output: z.object({ warehouseConnectionId: z.string() }),
    errors: z.object({
      t: z.enum(['WarehouseError']),
    }),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  etl: EtlContract,
});
