import { Ty } from '@cotera/era';

export type ResponseShape =
  | {
      t: 'object';
      items: {
        key: string;
        type: ResponseShape;
      }[];
    }
  | {
      t: 'array';
      items: ResponseShape;
    }
  | {
      t: 'string';
    }
  | {
      t: 'int';
    }
  | {
      t: 'float';
    }
  | {
      t: 'boolean';
    }
  | {
      t: 'enum';
      values: string[];
    };

export function responseShapeToShorthand(
  responseShape: ResponseShape
): Ty.ExtendedAttributeType {
  switch (responseShape.t) {
    case 'object':
      return Ty.ty(
        Ty.structOf(
          Object.fromEntries(
            responseShape.items.map((item) => [
              item.key,
              responseShapeToShorthand(item.type),
            ])
          )
        )
      );
    case 'array':
      return Ty.ty(Ty.arrayOf(responseShapeToShorthand(responseShape.items)));
    case 'string':
      return Ty.ty('string');
    case 'float':
      return Ty.ty('float');
    case 'int':
      return Ty.ty('int');
    case 'boolean':
      return Ty.ty('boolean');
    case 'enum':
      return Ty.ty(Ty.enumOf(responseShape.values));
    default:
      throw new Error(`Unknown type: ${(responseShape as any).t}`);
  }
}

export function shorthandToResponseShape(
  ty: Ty.ExtendedAttributeType
): ResponseShape {
  if (ty.ty.k === 'primitive') {
    switch (ty.ty.t) {
      case 'int':
        return { t: 'int' };
      case 'float':
        return { t: 'float' };
      case 'boolean':
        return { t: 'boolean' };
      case 'string':
        return { t: 'string' };
      case 'day':
      case 'month':
      case 'year':
      case 'timestamp':
        return { t: 'string' }; // or a custom type if you handle dates differently
      case 'super':
        throw new Error("Cannot map 'super' to ResponseShape.");
      default:
        throw new Error(`Unknown primitive type: ${ty}`);
    }
  }

  // Handle array types
  if (ty.ty.k === 'array') {
    return { t: 'array', items: shorthandToResponseShape(ty.ty.t) };
  }

  // Handle struct types
  if (ty.ty.k === 'struct') {
    return {
      t: 'object',
      items: Object.entries(ty.ty.fields).map(([key, value]) => ({
        key,
        type: shorthandToResponseShape(value),
      })),
    };
  }

  // Handle enum types
  if (ty.ty.k === 'enum') {
    return { t: 'enum', values: [...ty.ty.t] };
  }

  throw new Error(`Unsupported ExtendedAttributeType: ${ty.ty.k}`);
}

export type ParsedSegment = { t: 'expression' | 'string'; value: string };

export function extractExpressions(input: string): ParsedSegment[] {
  const regex = /\{\{(.*?)\}\}/g;
  let match;
  const segments: ParsedSegment[] = [];
  let lastIndex = 0;

  while ((match = regex.exec(input)) !== null) {
    const beforeExpression = input.substring(lastIndex, match.index);
    if (beforeExpression) {
      segments.push({ t: 'string', value: beforeExpression });
    }

    const expressionValue = match[1]?.trim() ?? '';
    if (expressionValue) {
      segments.push({ t: 'expression', value: expressionValue });
    }

    lastIndex = regex.lastIndex;
  }

  // Add any remaining string after the last expression
  const remainingString = input.substring(lastIndex);
  if (remainingString) {
    segments.push({ t: 'string', value: remainingString });
  }

  return segments;
}

export function isCursorInsideExpression(
  textarea: HTMLTextAreaElement
): boolean {
  const cursorPosition = textarea.selectionStart;
  const textBeforeCursor = textarea.value.substring(0, cursorPosition);
  const textAfterCursor = textarea.value.substring(cursorPosition);

  // Find the last opening and closing braces before the cursor
  const lastOpening = textBeforeCursor.lastIndexOf('{{');
  const lastClosing = textBeforeCursor.lastIndexOf('}}');

  // Check if the cursor is after the last opening and before any closing
  if (lastOpening > lastClosing) {
    // Find the first closing brace after the cursor
    const nextClosing = textAfterCursor.indexOf('}}');

    // If there's a closing brace after the cursor, it's within a block
    return nextClosing !== -1;
  }

  return false;
}

export function hasMatchingBraces(input: string): boolean {
  let openBraces = 0;

  for (let i = 0; i < input.length; i++) {
    if (input[i] === '{' && input[i + 1] === '{') {
      openBraces++;
      i++; // Skip the next character as it is part of the `{{`
    } else if (input[i] === '}' && input[i + 1] === '}') {
      if (openBraces === 0) {
        return false; // Found a closing `}}` without a matching opening `{{`
      }
      openBraces--;
      i++; // Skip the next character as it is part of the `}}`
    }
  }

  return openBraces === 0;
}

export function buildEraQLFromExpressionString(
  segments: ParsedSegment[]
): string {
  return segments
    .map((segment) => {
      if (segment.t === 'string') {
        return `'${segment.value.replace(/'/g, "\\'")}'`;
      }
      return segment.value;
    })
    .reduce((acc, segment) => {
      if (acc === '') {
        return segment;
      }
      return `${acc} |> concat(${segment})`;
    }, '');
}
