import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Card } from '@cotera/client/app/components/headless';

export const Block: React.FC<
  ChildrenProps & { title?: string; border?: boolean }
> = ({ title, border = true, children }) => {
  return (
    <Card.Container
      className="mt-4"
      bottomPadding={false}
      noBackground={!border}
    >
      {title && (
        <Card.Header>
          <Card.Title
            size="lg"
            className="font-semibold text-gray-800 w-full relative"
          >
            {title}
          </Card.Title>
        </Card.Header>
      )}
      <Card.Content padding={border}>{children}</Card.Content>
    </Card.Container>
  );
};
