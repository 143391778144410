import { ExprAttributeRequirements } from './check-expr';
import { TypeCheckError } from '../type-check-error';
import { Ty } from '../../ty';
import _ from 'lodash';

export const mergeAttrReqs = (
  ...reqs: ExprAttributeRequirements[]
): ExprAttributeRequirements | TypeCheckError => {
  const merged: Record<string, Record<string, Ty.ExtendedAttributeType>> = {};

  for (const req of reqs) {
    for (const [source, reqsForSource] of Object.entries(req)) {
      for (const [attrName, ty] of Object.entries(reqsForSource)) {
        const s = merged[source as 'left' | 'right' | 'from'] ?? {};

        const existing = s[attrName];

        if (!existing) {
          s[attrName] = ty;
        } else {
          if (!_.isEqual(ty, existing)) {
            throw new Error('TDOO');
          }
        }

        merged[source] = s;
      }
    }
  }

  return merged;
};
