import {
  CoteraClient,
  DevServerClient,
  mkCoteraClient,
  mkDevServer,
} from '@cotera/api';
import { COTERA_DEMO_ORG_ID, COTERA_DEMO_USER_ID } from '@cotera/utilities';
import axios, { AxiosError } from 'axios';
import { err, ok } from 'neverthrow';
import { TC } from '@cotera/era';
import { DemoManifest } from './demo-manifest';

export const mkLocalDevServer = (
  headers: Record<string, string>
): DevServerClient =>
  mkDevServer(async (route, method, input) => {
    try {
      const response = await axios({
        url: `http://localhost:3000/${route.join('/')}`,
        method: 'POST',
        data: input,
        headers: {
          ...headers,
          'X-HTTP-Method-Override': method,
        },
        validateStatus: () => true,
      });

      return ok(response);
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 404) {
        return err({ t: 'client', err: { errorType: 'OrgNotFound' } });
      }

      if (error.code === 'ECONNREFUSED' || error.code === 'ERR_NETWORK') {
        return err({ t: 'client', err: { errorType: 'NoDevServerFound' } });
      }
      throw e;
    }
  });

const makeBaseClient = (
  config: {
    apiUrl: string;
    demo: boolean;
  },
  headers: Record<string, string> = {}
) => {
  return mkCoteraClient(
    async (route, method, input, opts) => {
      const response = await axios({
        url: `${config.apiUrl}/api/v1/${route.slice(1).join('/')}`,
        method: 'POST',
        data: input,
        withCredentials: true,
        headers: {
          'x-cotera-demo': config.demo,
          'X-HTTP-Method-Override': method,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        },
        // By default axios throws when a request does not have a 2xx status
        // code. That's not how we do things here though. This makes sure
        // that axios never throws.
        validateStatus: () => true,
        signal: opts?.abort?.signal,
      });

      return response;
    },
    { skipParse: true }
  );
};

const makeDemoClient = (
  config: {
    apiUrl: string;
    demo: boolean;
  },
  headers: Record<string, string> = {}
): CoteraClient => {
  const client = makeBaseClient(config, headers);

  return {
    u: {
      ...client.u,
      whoami: async () =>
        ok({
          id: COTERA_DEMO_USER_ID,
          name: 'DEMO',
          email: 'demo-cotera.invalid',
          impersonator: null,
          untenantedRoles: [],
          tenantedRoles: [{ orgId: COTERA_DEMO_ORG_ID, role: 'DEMO' }],
          orgs: [{ id: COTERA_DEMO_ORG_ID, name: 'DEMO', featureFlags: {} }],
        }),
    },
    t: {
      ...client.t,
      devServer: {
        ...client.t.devServer,
        manifests: {
          ...client.t.devServer.manifests,
          skeleton: async () =>
            ok(DemoManifest.skeleton({ version: 'server' })),
          app: async ({ id }) => {
            const app = DemoManifest.apps[id]!;
            const checked = TC.MU.checkApp(app);

            if (checked instanceof TC.TyStackTrace) {
              throw checked.toError({});
            }

            return ok(checked);
          },
        },
      },
    },
  };
};

export const makeClient = (
  config: { apiUrl: string; demo: boolean },
  headers: Record<string, string> = {}
): CoteraClient => {
  if (config.demo) {
    return makeDemoClient(config, headers);
  }

  return makeBaseClient(config, headers);
};
