import {
  ArrowPathIcon,
  BellAlertIcon,
  ChartBarIcon,
  ChartPieIcon,
  ChatBubbleLeftIcon,
  GiftIcon,
  ShoppingBagIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';

type App = {
  key: string;
  label: string;
  excerpt: string;
  icon: React.ElementType;
  tags: string[];
};

export const APPS: App[] = [
  {
    key: 'lifecycle',
    label: 'Lifecycle Monitoring',
    excerpt: 'Understand your customer lifecycle and how to improve it.',
    icon: UserGroupIcon,
    tags: ['E-commerce', 'B2B', 'B2C'],
  },
  {
    key: 'feedback',
    label: 'Customer Feedback Monitoring',
    excerpt:
      'Understand exactly what your customers think about your product and distill it into actionable insights.',
    icon: ChatBubbleLeftIcon,
    tags: ['E-commerce', 'B2B', 'B2C', 'SaaS', 'Startup'],
  },
  {
    key: 'recommendations',
    label: 'Product Recommendations',
    excerpt: 'Drive more sales using the data you have already',
    icon: ShoppingBagIcon,
    tags: ['E-commerce'],
  },
  {
    key: 'Replenishment',
    label: 'Item Replenishment Engine',
    excerpt: 'Never Miss an Opportunity to Restock',
    icon: ArrowPathIcon,
    tags: ['E-commerce', 'B2B', 'B2C'],
  },
  {
    key: 'discounts',
    label: 'Discount Model',
    excerpt: 'Maximize Profits with Intelligent Discounting',
    icon: GiftIcon,
    tags: ['E-commerce', 'B2C'],
  },
  {
    key: 'category-recommendations',
    label: 'Next Best Category Recommendations',
    excerpt: 'Discover the Right Categories for Each Customer',
    icon: ChartPieIcon,
    tags: ['E-commerce', 'B2B', 'B2C'],
  },
  {
    key: 'churn',
    label: 'Churn Prediction Model',
    excerpt: 'Keeping Customers Happy',
    icon: BellAlertIcon,
    tags: ['E-commerce', 'B2C'],
  },
  {
    key: 'metrics',
    label: 'Metrics Layer',
    excerpt: 'Simplified Tracking for Your Online Store',
    icon: ChartBarIcon,
    tags: [`E-commerce`],
  },
];
