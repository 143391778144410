import { ChildrenProps } from '@cotera/client/app/components/utils';
import * as BaseTooltip from '@radix-ui/react-tooltip';
import React from 'react';
import { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';

const Container: React.FC<ChildrenProps> = ({ children }) => (
  <div className="bg-white p-2 shadow-lg border-divider border rounded">
    {children}
  </div>
);

const Item = ({ children }: ChildrenProps) => (
  <div className="flex items-center text-sm [&:not(:last-child)]:mb-2">
    {children}
  </div>
);

const Dot = ({ color }: { color: string }) => (
  <div
    className="rounded-full w-[10px] h-[10px] mr-3"
    style={{ background: color }}
  ></div>
);

const Renderer: React.FC<
  {
    content: ChildrenProps['children'];
  } & ChildrenProps
> = ({ children, content }) => {
  const { ref, x, y } = useMouse();

  return (
    <BaseTooltip.Provider delayDuration={0}>
      <BaseTooltip.Root>
        <BaseTooltip.Trigger ref={ref} asChild>
          {children}
        </BaseTooltip.Trigger>
        <BaseTooltip.Portal>
          <BaseTooltip.Content
            align="start"
            alignOffset={x}
            sideOffset={-y + 10}
            hideWhenDetached
            className="z-50"
          >
            {content}
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
};

export const Tooltip = {
  Container,
  Item,
  Dot,
  Renderer,
};

function useMouse<T extends HTMLElement = any>(
  options: { resetOnExit?: boolean } = { resetOnExit: false }
) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const ref = React.useRef<T>();

  const setMousePosition = (event: MouseEvent<HTMLElement>) => {
    if (ref.current) {
      const rect = event.currentTarget.getBoundingClientRect();

      const x = Math.max(
        0,
        Math.round(
          event.pageX - rect.left - (window.pageXOffset || window.scrollX)
        )
      );

      const y = Math.max(
        0,
        Math.round(
          event.pageY - rect.top - (window.pageYOffset || window.scrollY)
        )
      );

      setPosition({ x, y });
    } else {
      setPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const resetMousePosition = () => setPosition({ x: 0, y: 0 });

  useEffect(() => {
    const element = ref?.current ? ref.current : document;
    element.addEventListener('mousemove', setMousePosition as any);
    if (options.resetOnExit)
      element.addEventListener('mouseleave', resetMousePosition as any);

    return () => {
      element.removeEventListener('mousemove', setMousePosition as any);
      if (options.resetOnExit) {
        element.removeEventListener('mouseleave', resetMousePosition as any);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, options.resetOnExit]);

  return { ref, ...position };
}
