import { AST, Relation } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { CalloutForRelations, CalloutSkeleton } from '../components/callout';
import { App } from './app';
import { RenderBlock } from './block';
import { RenderChart } from './charts';
import { ForEach } from './for-each';
import { Image } from './image';
import { Page, PageHeader } from './page';
import { RenderSections } from './sections';
import { RenderTabs } from './tabs';
import { Text } from './text';
import { RenderStateSkeleton, RenderStats } from '../components/stats';
import { RenderWidth } from './width';
import { RenderDivider } from './divider';
import { RenderInsights, RenderInsightsSkeleton } from './insights';
import { Block, DisplayError, Loading } from '@cotera/client/app/components';
import { RenderUiState } from './ui-state';
import { RenderExprControlsV2 } from './expr-controls/expr-controls-v2';
import { RenderRelControls } from './rel-controls';
import { RenderMacroMarkupCase } from './macro-expansion/macro-markup-case';
import { RenderMarkupVar } from './macro-expansion/markup-var';
import { RenderMacroApplyVarsToMarkup } from './macro-expansion/macro-apply-vars-to-markup';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const CompileMarkup: React.FC<{
  section: AST.Mu | AST._App;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <DisplayError error={error} />}
    >
      <Suspense fallback={<Loading.Dots />}>
        <CompileMarkupView section={section} inBlock={inBlock} />
      </Suspense>
    </ErrorBoundary>
  );
};

const CompileMarkupView: React.FC<{
  section: AST.Mu | AST._App;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  const { t } = section;

  switch (t) {
    case 'noop':
      return null;
    case 'text':
      return <Text section={section} />;
    case 'image':
      return <Image section={section} />;
    case 'chart': {
      const inner = <RenderChart section={section} />;
      return !inBlock ? <Block>{inner}</Block> : inner;
    }
    case 'tabs':
      return <RenderTabs section={section} inBlock={inBlock} />;
    case 'block':
      return <RenderBlock section={section} />;
    case 'sections':
      return <RenderSections section={section} inBlock={inBlock} />;
    case 'page':
      return <Page section={section} />;
    case 'stats':
      return (
        <Suspense fallback={<RenderStateSkeleton section={section} />}>
          {!inBlock ? (
            <Block>
              <RenderStats section={section} />
            </Block>
          ) : (
            <RenderStats section={section} />
          )}
        </Suspense>
      );
    case 'callout':
      return (
        <Suspense fallback={<CalloutSkeleton />}>
          <CalloutForRelations
            rels={section.rels.map((x) => Relation.wrap(x))}
          />
        </Suspense>
      );
    case 'app':
      return <App section={section} />;
    case 'for-each':
      return (
        <Suspense fallback={<Loading.Dots />}>
          <ForEach section={section} inBlock={inBlock} />
        </Suspense>
      );
    case 'header':
      return <PageHeader section={section} />;
    case 'width':
      return <RenderWidth section={section} inBlock={inBlock} />;
    case 'divider':
      return <RenderDivider section={section} />;
    case 'insights':
      return (
        <Suspense fallback={<RenderInsightsSkeleton />}>
          <RenderInsights section={section} />;
        </Suspense>
      );
    case 'ui-state':
      return (
        <RenderUiState
          key={section.body.scope}
          section={section}
          inBlock={inBlock}
        />
      );
    case 'expr-controls-v2':
      return <RenderExprControlsV2 section={section} inBlock={inBlock} />;
    case 'rel-controls-v2':
      return <RenderRelControls section={section} inBlock={inBlock} />;
    case 'macro-section-case':
      return <RenderMacroMarkupCase section={section} inBlock={inBlock} />;
    case 'markup-var':
      return <RenderMarkupVar section={section} inBlock={inBlock} />;
    case 'macro-apply-vars-to-markup':
      return (
        <RenderMacroApplyVarsToMarkup section={section} inBlock={inBlock} />
      );
    default:
      return Assert.unreachable(t);
  }
};
