import { Layout } from '@cotera/client/app/layout';
import { EventsObservability } from '@cotera/sdk/core';
import { TopLevelMarkupWrapper } from '../../top-level-markup-wrapper';
import { CompileMarkup } from '../../apps/compiler';

export const EventsObserver: React.FC = () => {
  return (
    <Layout>
      <div className="flex flex-col w-full p-8">
        <div>
          <TopLevelMarkupWrapper>
            <CompileMarkup section={EventsObservability.ast} inBlock={false} />
          </TopLevelMarkupWrapper>
        </div>
      </div>
    </Layout>
  );
};
