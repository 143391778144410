import React, { useCallback } from 'react';

const KEYS = ['Enter', 'Escape', 'ArrowUp', 'ArrowDown'];

export const useKeyPress = (
  targetKey:
    | (typeof KEYS)[number]
    | ((e: KeyboardEvent) => boolean)
    | undefined,
  callback: (e: KeyboardEvent) => void
) => {
  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      const condition =
        typeof targetKey === 'function'
          ? targetKey
          : (e: KeyboardEvent) => e.key === targetKey;
      if (condition(e)) {
        callback(e);
      }
    },
    [targetKey, callback]
  );

  React.useEffect(() => {
    if (targetKey === undefined) return;
    window.addEventListener('keydown', keyHandler);
    return () => window.removeEventListener('keydown', keyHandler, false);
  }, [keyHandler, targetKey]);
};
