import { Relation } from '@cotera/era';
import { FilterBuilder } from './filter-builder';
import { FilterGroup, Option } from './types';
import { Assert } from '@cotera/utilities';
import React from 'react';
import _ from 'lodash';
import { filterGroupToExpression } from './utils';
import { useFirstValue } from '@cotera/client/app/hooks/use-first-value';

export const useOptions = (attributes: Relation['attributes']): Option[] => {
  const options = Object.entries(attributes)
    .filter(([_, value]) => value.ty.k === 'primitive' || value.ty.k === 'enum')
    .filter(([_, value]) => {
      Assert.assert(value.ty.k === 'primitive' || value.ty.k === 'enum');
      return value.ty.t !== 'super';
    })
    .map(([key, value]) => {
      return {
        attr: key,
        t: value,
        values: value.ty.k === 'enum' ? [...value.ty.t] : [],
      };
    });

  return options;
};

type Props = {
  rel: Relation;
  filterGroup?: FilterGroup;
  onRun: (rel: Relation) => void;
  onRunTransform?: (fn: (rel: Relation) => Relation) => void;
  onChange?: () => void;
  runEnabled?: boolean;
  autoRun?: boolean;
  onAutoRunChange?: (autoRun: boolean) => void;
};

export const FilterBuilderForRelation: React.FC<Props> = (props) => {
  const [hasRun, setHasRun] = React.useState(false);

  return (
    <WithBasicOptions
      {...props}
      onRun={(rel) => {
        setHasRun(true);
        props.onRun(rel);
      }}
      onRunTransform={props.onRunTransform}
      onChange={() => {
        setHasRun(false);
      }}
      runEnabled={!hasRun}
    />
  );
};

const WithBasicOptions: React.FC<Props> = ({
  rel,
  filterGroup,
  onRun,
  onRunTransform,
  ...rest
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue = useFirstValue(rel);
  const options = useOptions(initialValue.attributes);
  const [autoRun, setAutoRun] = React.useState(false);

  return (
    <FilterBuilder
      rel={initialValue}
      filterGroup={filterGroup}
      options={options}
      onRun={({ filterGroup }) => {
        onRun(
          initialValue.where((t) => filterGroupToExpression(t)(filterGroup))
        );
        onRunTransform?.((rel) =>
          rel.where((t) => filterGroupToExpression(t)(filterGroup))
        );
      }}
      autoRun={autoRun}
      onAutoRunChange={setAutoRun}
      {...rest}
    />
  );
};
