import { AST } from '../ast';
import { Expression } from '../builder';
import { exprPurityAnalysis } from '../type-checker/expr/purity-analysis';

export const splitFunctionalTransform = (
  ir: AST.RelIR
): { source: AST.RelIR; next: (x: AST.RelIR) => AST.RelIR } => {
  if (ir.t !== 'select' || ir.condition !== null) {
    return { source: ir, next: (x) => x };
  }

  const isPure = [
    ...Object.values(ir.selection),
    ...ir.orderBys.map((x) => x.expr),
  ].every((expr) => {
    const { isWindowed, isAggregated } = Expression.fromAst(expr);
    const { rand, now, other } = exprPurityAnalysis(expr);
    return [rand, now, other, isAggregated, isWindowed].every((x) => !x);
  });

  if (!isPure || ir.distinct) {
    return { source: ir, next: (x) => x };
  }

  const child = splitFunctionalTransform(ir.sources.from);

  const next = (x: AST.RelIR): AST.RelIR => ({
    ...ir,
    sources: { from: child.next(x) },
  });

  return { source: child.source, next };
};
