import { AST, EraQL, TC, Ty } from '@cotera/era';
import { Result, err, ok } from 'neverthrow';
import { capitalize } from 'lodash';

function formatParseErrors(errors: EraQL.WithMeta<EraQL.ParseError>): {
  msg: string;
  range: [number, number];
} {
  const [error, { range }] = errors;
  let message: string;

  switch (error.t) {
    case 'cant-tag':
    case 'cant-pipe-into':
    case 'cant-call':
    case 'invalid-arity':
    case 'invalid-enum-variant':
    case 'unexpected-eof':
    case 'unexpected-token':
      message = `${capitalize(error.t.replace(/-/g, ' '))}${
        error.debug ? ` (Debug: ${error.debug})` : ''
      }.`;
      break;

    case 'expected': {
      const expectedStr = Array.isArray(error.expected)
        ? error.expected.join(', ')
        : error.expected;
      message = `Expected ${expectedStr}.`;
      break;
    }
    case 'attribute-not-found':
      message = `Attribute not found: "${error.name}".`;
      break;

    case 'unclosed-deliminator':
      message = `Unclosed delimiter, missing: "${error.missing}".`;
      break;

    case 'duplicate-key':
    case 'missing-key':
    case 'unexpected-key':
      message = `${error.t.replace(/-/g, ' ')} for key: "${error.key}".`;
      break;

    case 'type-check-error':
      // message = `Type check error: ${error.trace.message}${
      //   error.trace.location ? ` at ${error.trace.location}` : ''
      // }.`;
      message = 'Type check error. TODO';
      break;

    default:
      message = 'Unknown error type.';
      break;
  }

  return { msg: message, range: [range[0], range[1]] };
}

export const parseExprResult = (
  res: EraQL.ParseRes<AST.ExprIR>
): Result<
  {
    tc: TC.ExprTypeCheck;
    ast: AST.ExprIR;
    dTy: string;
  },
  { t: string; msg: string }
> => {
  if (res.isErr()) {
    return err({
      t: 'parse-error',
      msg: formatParseErrors(res.error).msg,
    });
  }
  const ast = res.value;
  const tc = TC.checkExpr(ast);

  if (tc instanceof TC.TyStackTrace) {
    return err({
      t: 'type-check-error',
      msg: tc.headline,
    });
  }

  return ok({ tc, ast, dTy: Ty.displayTy(tc.ty) });
};
