import { Relation } from './relation';
import { GroupedRelationRef } from './relation-ref';
import { Expression } from './expression';
import { Constant } from './utilities';
import { Ty } from '../ty';
import { Chart } from './markup/chart';

export class Grouping {
  constructor(
    private readonly groupedAttributes: readonly string[],
    private readonly relation: Relation
  ) {}

  get chart(): Chart {
    return Chart.fromRel(this);
  }

  get attributes() {
    return this.relation.attributes;
  }

  andThen<X extends Relation | Grouping>(cb: (x: Grouping) => X): X {
    return cb(this);
  }

  ref(): GroupedRelationRef {
    return new GroupedRelationRef(this.groupedAttributes, this.relation);
  }

  select(
    selection: (relation: GroupedRelationRef) => {
      readonly [name: string]: Ty.Scalar | Expression;
    }
  ): Relation {
    const ref = this.ref();
    let selected: Record<string, Ty.Scalar | Expression>;
    try {
      selected = selection(ref);
    } catch (e) {
      if (e instanceof Error) {
        if ((Error as any).captureStackTrace) {
          // eslint-disable-next-line @typescript-eslint/unbound-method
          (Error as any).captureStackTrace(e, this.select);
        }
      }

      throw e;
    }

    const exprs = Object.fromEntries(
      Object.entries(selected).map(([name, foo]) => {
        const { ast } = foo instanceof Expression ? foo : Constant(foo);
        return [name, ast];
      })
    );

    return Relation.fromAst(
      {
        t: 'aggregate',
        groupedAttributes: this.groupedAttributes,
        sources: {
          from: this.relation.ast,
        },
        selection: exprs,
      },
      // eslint-disable-next-line @typescript-eslint/unbound-method
      { jsStackPointer: this.select }
    );
  }
}
