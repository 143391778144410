import _ from 'lodash';
import { AST } from '../../ast';
import { ExprVar, Expression } from '../expression';
import { mkMuMacro } from '../macro/mu-macro';
import type { MarkupShorthand } from './section';
import { TyStackTrace, TC } from '../../type-checker';
import { Constant } from '../utilities';

class FluentPage<Params extends readonly string[]> implements AST._Page {
  readonly t = 'page';
  readonly params: Params;
  readonly config: {
    readonly cache: Record<string, string[]>;
  };
  readonly body: AST._Page['body'];

  constructor(
    params: Params,
    config: { readonly cache?: Record<string, string[]> },
    body: AST._Page['body']
  ) {
    this.params = params;
    this.config = { cache: config.cache ?? {} };
    this.body = body;
  }

  preload(cacheConfig: Record<Params[number], string[]>): FluentPage<Params> {
    return new FluentPage<Params>(
      this.params,
      { ...this.config, cache: cacheConfig },
      this.body
    );
  }
}

export const Page = <Params extends readonly string[]>(
  params: Params,
  body: (args: {
    [key in Params[number]]: Expression;
  }) => MarkupShorthand,
  config: { cache?: Record<Params[number], string[]> } = {}
): FluentPage<Params> => {
  const m = mkMuMacro(
    Object.fromEntries(
      params.map((param) => [param, Constant(null, { ty: 'string' })])
    ),
    (args) => body(args as { [key in Params[number]]: ExprVar }),
    { displayName: 'Page' }
  );

  const data: AST._Page = {
    t: 'page',
    params,
    config: { cache: config.cache ?? {} },
    body: { scope: m.scope, macro: m.body.ast },
  };

  const checked = TC.MU.checkPage(data);

  if (checked instanceof TyStackTrace) {
    throw checked.toError({});
  }

  return new FluentPage<Params>(data.params as Params, data.config, data.body);
};
