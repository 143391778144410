import { create } from 'zustand';
import { DuckDBQueryResult } from '../../etc/duckdb';
import { Relation } from '@cotera/era';

export type SearchTerm = {
  readonly term: string;
  readonly embedding: number[];
};

type State = {
  readonly loading: boolean;
  readonly setLoading: (loading: boolean) => void;
  readonly data: DuckDBQueryResult | null;
  readonly setData: (data: DuckDBQueryResult) => void;
  readonly setRel: (rel: Relation) => void;
  readonly searchTerms: SearchTerm[];
  readonly addSearchTerm: (term: { term: string; embedding: number[] }) => void;
  readonly removeSearchTerm: (term: string) => void;
  readonly cutoff: number;
  readonly cutoffPos: number;
  readonly setCutoff: (props: { cutoff: number; cutoffPos: number }) => void;
  readonly rel: Relation | null;
};

export const useStore = create<State>((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
  data: null,
  setData: (data) => set({ data }),
  searchTerms: [],
  addSearchTerm: async (term) => {
    set((state) => ({
      searchTerms: [...state.searchTerms, term],
    }));
  },
  removeSearchTerm: (term) =>
    set((state) => ({
      searchTerms: state.searchTerms.filter((t) => t.term !== term),
    })),
  cutoff: 0,
  cutoffPos: 0,
  setCutoff: (props) => set(props),
  setRel: (rel) => set({ rel }),
  rel: null,
}));
