export const Styles = {
  input: `
  bg-white  
  hover:bg-zinc-50 
  active:bg-zinc-100
  border-zinc-200
  border
  rounded
  px-2 
  py-1.5 
  h-10`,
  focus: {
    inline:
      'transition-colors !border-none !bg-transparent !active:bg-transparent active:text-indigo-400 hover:text-indigo-400 !focus:ring-0 focus:text-indigo-400 data-[focus]:text-indigo-400',
    normal: `focus:outline-none 
    active:ring-0
    ring-0
    outline-none
    active:outline-none
    focus:ring-0 focus:border-indigo-400 focus:border-opacity-80 focus:border
    data-[focus]:border data-[focus]:border-indigo-400 data-[focus]:border-opacity-80 data-[focus]:ring-0 transition-all ease-in-out duration-200`,
  },
  compactableInput: {
    compact: 'text-xs',
    normal: 'text-sm',
  },
  active: {
    inline: `!text-indigo-400`,
    normal: `!bg-zinc-100`,
  },
  disabled: `opacity-50 cursor-default`,
  priority: {
    low: 'z-10',
    medium: 'z-20',
    high: 'z-30',
    highest: 'z-40',
  },
};
