import { XCircleIcon } from '@heroicons/react/24/solid';
import { DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { classNames } from '@cotera/client/app/components/utils';

export type ErrorProps = { message: string; className?: string };

export const FormError: React.FC<ErrorProps> = ({
  message,
  className = '',
}) => {
  return (
    <div
      className={classNames(
        'w-full flex rounded bg-red-400 text-alt-text p-3 pl-4 pr-4 border-red-500 border',
        className
      )}
    >
      <XCircleIcon className="h-5 w-5 text-alt-text mr-4 inline" />
      {message}
    </div>
  );
};

export const FormErrors: React.FC<{
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
  className?: string;
}> = ({ errors, className = '' }) => {
  return (
    <div className={classNames('mb-4', className)}>
      {Object.keys(errors).map((key) => {
        const data = errors[key]!;
        return (
          <FormError
            message={data['message'] as unknown as string}
            className="mb-2"
            key={key}
          />
        );
      })}
    </div>
  );
};

export const hasErrors = (
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>
) => {
  return Object.keys(errors).length > 0;
};

export * from './display-error';
