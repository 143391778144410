import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

const STYLE_MAP = {
  neutral: 'bg-standard-background text-standard-text',
  positive: 'bg-green-100 text-green-800',
  negative: 'bg-red-100 text-red-800',
  secondary: 'bg-standard-background text-standard-text',
  warning: 'bg-amber-100 text-amber-800',
  primary: 'bg-primary-background text-alt-text',
  accent: 'bg-accent-background text-alt-text',
};

export const Chip: React.FC<
  ChildrenProps & {
    className?: string;
    type?: keyof typeof STYLE_MAP;
    onClick?: () => void;
    tight?: boolean;
  }
> = ({ children, className, type: style = 'neutral', onClick, tight }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'rounded-full text-xs',
        className,
        STYLE_MAP[style],
        tight ? 'px-0.5 py-0.5' : 'px-3 py-2'
      )}
    >
      {children}
    </div>
  );
};
