import { Entity } from '@cotera/api';
import { iconForTy, useTextHinter } from '@cotera/client/app/components/app';
import { TC, Ty } from '@cotera/era';

export const handleOptionClick = (
  option: { value: string; trigger: string },
  eraql: string,
  inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  onValue: (v: string) => void
) => {
  if (!inputRef.current) return;

  const input = inputRef.current;
  const cursorPosition = input.selectionStart ?? 0;
  const beforeCursor = eraql.slice(0, cursorPosition);
  const currentWord = beforeCursor.split(' ').pop();

  const textBeforeCursor = eraql.slice(
    0,
    cursorPosition - (currentWord?.length ?? 0)
  );
  const textAfterCursor = eraql.slice(cursorPosition);

  // Insert option at cursor position
  let insertText = '';
  switch (option.trigger) {
    case '"':
      insertText = `"${option.value}"`;
      break;
    case '.':
      insertText = `${option.value}`;
      break;
    case '|>':
      insertText = `|> ${option.value}()`;
      break;
  }
  const newValue = `${textBeforeCursor}${insertText}${textAfterCursor} `;
  onValue(newValue);

  // Focus input and set the new cursor position after the inserted text
  input.focus();
  const newCursorPosition = cursorPosition + insertText.length;
  input.setSelectionRange(newCursorPosition, newCursorPosition);
};

export const useHints = (
  entity: Entity,
  operatorsForType: string[],
  inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  setValue: (v: string) => void
) => {
  const {
    options: columnOptions,
    showOptions: showColumnOptions,
    handleChange: handleColumnChange,
    close: closeColumnOptions,
  } = useTextHinter(
    Object.entries(entity.columns).map(([attr, ty]) => ({
      value: attr,
      icon: iconForTy(ty.type) ?? undefined,
    })),
    '"'
  );

  const {
    options: operatorOptions,
    showOptions: showOperatorOptions,
    handleChange: handleOperatorChange,
    close: closeOperatorOptions,
  } = useTextHinter(operatorsForType, '.');

  const {
    options: functionOptions,
    showOptions: showFuncOptions,
    handleChange: handleFuncChange,
    close: closeFuncOptions,
  } = useTextHinter(['length', 'lower', 'upper'], '|>');

  const handleChange = (
    currentValue: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const cursorPosition = e.target.selectionStart ?? 0;
    handleColumnChange(currentValue, cursorPosition);
    handleOperatorChange(currentValue, cursorPosition);
    handleFuncChange(currentValue, cursorPosition);
  };

  return {
    columns: {
      options: columnOptions,
      showOptions: showColumnOptions,
      handleChange: handleColumnChange,
      close: closeColumnOptions,
    },
    operators: {
      options: operatorOptions,
      showOptions: showOperatorOptions,
      handleChange: handleOperatorChange,
      close: closeOperatorOptions,
    },
    functions: {
      options: functionOptions,
      showOptions: showFuncOptions,
      handleChange: handleFuncChange,
      close: closeFuncOptions,
    },
    handleChange,
    handleOptionClick: (
      option: { value: string; trigger: string },
      eraql: string
    ) =>
      handleOptionClick(option, eraql, inputRef, (v) => {
        setValue(v);

        closeColumnOptions();
        closeOperatorOptions();
        closeFuncOptions();
      }),
  };
};

export function getValidOperatorsForType(
  ty: Ty.ExtendedAttributeType
): string[] {
  const optionsForType = () => {
    if (TC.isNumeric(ty)) {
      return ['+', '-', '*', '/', '%', '=', '!=', '>', '>=', '<', '<='];
    }
    if (TC.isBoolean(ty)) {
      return ['and', 'or', 'not', '??'];
    }
    if (TC.isStringLike(ty)) {
      return ['=', '!=', '||', 'like'];
    }
    if (TC.isArray(ty)) {
      return ['[]'];
    }
    if (TC.isStruct(ty)) {
      return ['{}', 'get_from_record'];
    }

    return [];
  };

  return [...optionsForType(), '|>'];
}
// return Object.keys(TC.FUNCTION_TYPING_RULES).filter((fnName: any) => {
//   return TC.FUNCTION_TYPING_RULES['!=']!.rule(fnName, [ty]);
// })
