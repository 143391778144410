import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Item as ItemT, readSliceFromWorker } from './queries';
import { useStore } from './store';
import { EmptyState } from './empty-state';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '@cotera/client/app/components/utils';
import { v4 } from 'uuid';
import { toast } from '@cotera/client/app/components/ui/toaster';
import { useTenantedClient } from '../../stores/org';

const PAGE_SIZE = 50;

export const Results: React.FC = () => {
  const data = useStore((s) => s.data);
  const [page, setPage] = useState(0);

  if (data === null) {
    return <EmptyState />;
  }

  const nPages = Math.ceil(data.numRows / PAGE_SIZE) - 1;
  const pageStart = page * PAGE_SIZE;
  const pageEnd = pageStart + PAGE_SIZE;

  const slice = readSliceFromWorker(data, pageStart, pageEnd);

  return (
    <div>
      <ul className="w-full divide-y divide-gray-100 bg-white rounded">
        {slice.map((item, i) => {
          return <Item pos={pageStart + i} key={item.id} item={item} />;
        })}
      </ul>
      <Pagination
        nPages={nPages}
        page={page}
        setPage={(page: number) => {
          document.getElementById('scene')?.scrollTo({ top: 0 });
          setPage(page);
        }}
      />
    </div>
  );
};

export const Item: React.FC<{ pos: number; item: ItemT }> = ({ pos, item }) => {
  const ts = new Date(item.timestamp);
  return (
    <li
      key={item.id}
      className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-indigo-50 sm:px-6 lg:px-8"
    >
      <span className="p-1.5 bg-indigo-50 text-primary-text rounded border border-indigo-200 text-xs font-semibold text-center align-middle m-auto">
        {Math.round((item.cosine_similarity ?? 0) * 100)}
      </span>
      <div className="flex-auto">
        <div className="flex items-baseline justify-between gap-x-4">
          <time
            className="text-gray-800 font-semibold"
            dateTime={ts.toLocaleString()}
          >
            {ts.toLocaleString()}
          </time>
          <div />
          <p className="text-sm leading-6 text-gray-500 italic">{item.id}</p>
        </div>
        <p className="mt-1 line-clamp-2 text-sm leading-6 text-standard-text overflow-ellipsis">
          {item.content}
        </p>
      </div>
      <div className="my-auto">
        <Actions
          content={item.content}
          pos={pos}
          score={item.cosine_similarity ?? 0}
        />
      </div>
    </li>
  );
};

export const Pagination: React.FC<{
  nPages: number;
  page: number;
  setPage: (page: number) => void;
}> = ({ nPages, page, setPage }) => {
  return (
    <nav className="flex items-center justify-around border-t border-divider px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(0)}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
          Start
        </button>
      </div>
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(page - 1)}
          disabled={page === 0}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <span className="pt-4 text-sm font-medium text-gray-500 ">
        Page {page + 1}
      </span>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(page + 1)}
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-divider hover:text-standard-text"
          onClick={() => setPage(nPages)}
        >
          End
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-muted-text"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};

export const Actions: React.FC<{
  content: string;
  pos: number;
  score: number;
}> = ({ content, pos, score }) => {
  const setCutoff = useStore((s) => s.setCutoff);
  const addSearchTerm = useStore((s) => s.addSearchTerm);
  const client = useTenantedClient();

  return (
    <Menu as="div" className="relative flex-none">
      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-standard-text">
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  active ? 'bg-standard-background' : '',
                  'block px-3 py-1 text-sm leading-6 text-standard-text w-full'
                )}
                onClick={() =>
                  setCutoff({
                    cutoff: score,
                    cutoffPos: pos,
                  })
                }
              >
                Confirm Match
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  active ? 'bg-standard-background' : '',
                  'block px-3 py-1 text-sm leading-6 text-standard-text w-full'
                )}
                onClick={async () => {
                  const id = v4();
                  const result = await client.llm.embedding({
                    messages: [{ content, id }],
                  });

                  if (result.isErr()) {
                    toast.error('Failed to query embeddings');
                    return;
                  }

                  const embedding = result.value.embeddings[0]!.embedding;

                  addSearchTerm({
                    term: content,
                    embedding,
                  });
                }}
              >
                Add to Search
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
