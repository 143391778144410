import { Contract } from '@cotera/contracts';
import { SuperAdminDbtContract } from './dbt.contract';
import { SuperAdminEmailsContract } from './emails.contract';
import { SuperAdminOrgsContract } from './orgs.contract';
import { SuperAdminUsersContract } from './users.contract';

export const SuperAdminContract = Contract.new(
  {
    users: SuperAdminUsersContract,
    orgs: SuperAdminOrgsContract,
    emails: SuperAdminEmailsContract,
    dbt: SuperAdminDbtContract,
  },
  {
    mustBeAbleTo: [['use', 'superadmin']],
  }
);
