import {
  ChildrenProps,
  classNames,
  ColorScheme,
} from '@cotera/client/app/components/utils';
import { kebabCase } from 'lodash';

export const Cell: React.FC<
  {
    column: string;
    onClick?: () => void;
    width?: number;
    style?: React.CSSProperties;
    className?: string;
  } & ChildrenProps
> = ({ column, children, onClick, width, style, className }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width:
          width !== undefined
            ? `${width}`
            : `calc(var(--col-${kebabCase(column)}-size) * 1px)`,
        ...style,
      }}
      className={classNames(
        'z-[0] flex flex-col justify-center px-3 py-2 [&:not(:last-child)]:border-r border-divider pb-2 border-b hover:bg-indigo-100 text-sm',
        ColorScheme.text['regular'],
        className
      )}
    >
      <div className="inline-block relative overflow-hidden whitespace-nowrap text-ellipsis">
        {children}
      </div>
    </div>
  );
};
