import { classNames } from '@cotera/client/app/components/utils';

export const Label: React.FC<
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
> = ({ className = '', ...props }) => {
  return (
    <label
      {...props}
      className={classNames(
        className,
        'text-standard-text font-medium text-sm'
      )}
    ></label>
  );
};
