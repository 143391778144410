import {
  UI,
  AST,
  Page,
  If,
  Callout,
  Sum,
  LineChart,
  CountDistinct,
  Today,
  Not,
  And,
  Relation,
  Asc,
  Constant,
  Desc,
  LagOver,
  MaxOver,
  Median,
  RowNumberOver,
  RelationRef,
  FirstValueOver,
  Or,
} from '@cotera/era';
import {
  CustomerSpecifications,
  FavoriteProductsTables,
  userFavoriteProducts,
} from '../favorite-products';
import { NextProductFlow, PreviousProductFlow } from '../product-affinity';

export const Overview = ({
  Orders,
  ActiveItems,
  ItemDetail,
  TodaysCustomerRfm,
}: {
  Orders: Relation;
  ActiveItems: Relation;
  ItemDetail: Relation;
  TodaysCustomerRfm?: Relation;
}): AST._Page => {
  const CompareTotalSpend = Orders.groupBy((t) => t.pick('PRODUCT_ID')).select(
    (t) => ({
      ...t.group(),
      CURRENT_TOTAL_SPEND: Sum(
        If(t.attr('ORDER_DATE').between(Today().dateSub('days', 90), Today()), {
          then: t.attr('PRICE').mul(t.attr('QUANTITY')),
          else: Constant(0),
        })
      ),
      PREVIOUS_TOTAL_SPEND: Sum(
        If(
          t
            .attr('ORDER_DATE')
            .between(Today().dateSub('days', 180), Today().dateSub('days', 90)),
          { then: t.attr('PRICE').mul(t.attr('QUANTITY')), else: Constant(0) }
        )
      ),
    })
  );

  /*compare total quantity purchased between past 180 and 90 days*/
  const CompareQuantity = Orders.groupBy((t) => t.pick('PRODUCT_ID')).select(
    (t) => ({
      ...t.group(),
      CURRENT_QUANTITY: Sum(
        If(t.attr('ORDER_DATE').between(Today().dateSub('days', 90), Today()), {
          then: t.attr('QUANTITY'),
          else: Constant(0),
        })
      ),
      PREVIOUS_QUANTITY: Sum(
        If(
          t
            .attr('ORDER_DATE')
            .between(Today().dateSub('days', 180), Today().dateSub('days', 90)),
          { then: t.attr('QUANTITY'), else: Constant(0) }
        )
      ),
    })
  );

  /*quantity purchased per month line graph*/
  const OrderMonths = Orders.groupBy((t) => ({
    ...t.pick('PRODUCT_ID'),
    MONTH: t.attr('ORDER_DATE').dateTrunc('month'),
  })).select((t) => ({
    ...t.group(),
    QUANTITY_PURCHASED: Sum(t.attr('QUANTITY')),
  }));

  /*count of customers who purchased compared between 180 and 90 days*/
  const CompareCustomers = Orders.groupBy((t) => t.pick('PRODUCT_ID')).select(
    (t) => ({
      ...t.group(),
      CURRENT_CUSTOMERS: CountDistinct(
        If(t.attr('ORDER_DATE').between(Today().dateSub('days', 90), Today()), {
          then: t.attr('__COTERA_CUSTOMER_ID'),
        })
      ),
      PREVIOUS_CUSTOMERS: CountDistinct(
        If(
          t
            .attr('ORDER_DATE')
            .between(Today().dateSub('days', 180), Today().dateSub('days', 90)),
          { then: t.attr('__COTERA_CUSTOMER_ID') }
        )
      ),
    })
  );

  /*RFM categories of recent customers*/
  const CurrentCustomers = Orders.select((t) => ({
    CURRENT_CUSTOMERS: If(
      t.attr('ORDER_DATE').between(Today().dateSub('days', 90), Today()),
      {
        then: t.attr('__COTERA_CUSTOMER_ID'),
      }
    ),
    ...t.pick('PRODUCT_ID'),
  }));

  const RecentCustomersRfm = TodaysCustomerRfm
    ? CurrentCustomers.innerJoin(TodaysCustomerRfm, (customer, rfm) => ({
        on: customer
          .attr('CURRENT_CUSTOMERS')
          .eq(rfm.attr('__COTERA_CUSTOMER_ID')),
        select: {
          ...customer.pick('CURRENT_CUSTOMERS', 'PRODUCT_ID'),
          ...rfm.pick('RFM_CATEGORY'),
        },
      })).select((t) => ({
        ...t.pick('PRODUCT_ID', 'CURRENT_CUSTOMERS', 'RFM_CATEGORY'),
      }))
    : undefined;

  /*repeat purchases*/
  const RepeatPurchasers = Orders.groupBy((t) =>
    t.pick('PRODUCT_ID', '__COTERA_CUSTOMER_ID')
  )
    .select((t) => ({
      ...t.group(),
      COUNT_REPEAT_PURCHASES: CountDistinct(t.attr('ORDER_ID')),
    }))
    .groupBy((t) => t.pick('PRODUCT_ID'))
    .select((t) => ({
      ...t.group(),
      TOTAL_CUSTOMER_COUNT: CountDistinct(t.attr('__COTERA_CUSTOMER_ID')),
      REPEAT_CUSTOMER_COUNT: CountDistinct(
        If(t.attr('COUNT_REPEAT_PURCHASES').gte(2), {
          then: t.attr('__COTERA_CUSTOMER_ID'),
        })
      ),
    }));

  /*days between repeat purchases*/
  const DaysBetween = Orders.where((t) => t.attr('QUANTITY').eq(1))
    .groupBy((t) => t.pick('__COTERA_CUSTOMER_ID', 'PRODUCT_ID', 'ORDER_DATE'))
    .select((t) => ({
      ...t.group(),
      DAYS_BETWEEN_PURCHASES: LagOver(t.attr('ORDER_DATE'), {
        partitionBy: [t.attr('__COTERA_CUSTOMER_ID'), t.attr('PRODUCT_ID')],
        orderBy: Asc(t.attr('ORDER_DATE')),
      }),
    }));

  /*average days between repeat purchases for quantity = 1 orders*/
  const AverageDaysBetweenRepeatPurchases = DaysBetween.groupBy((t) => ({
    ...t.pick('PRODUCT_ID'),
  })).select((t) => ({
    ...t.group(),
    AVG_DAYS_BETWEEN_PURCHASES: Median(
      t.attr('DAYS_BETWEEN_PURCHASES').dateDiff(t.attr('ORDER_DATE'), 'days')
    ),
  }));

  /*top five performing items in last 90 days*/
  const ItemsByMonth = Orders.groupBy((t) => ({
    ...t.pick('PRODUCT_ID'),
    MONTH: t.attr('ORDER_DATE').dateTrunc('month'),
  })).select((t) => ({
    ...t.group(),
    QUANTITY_PURCHASED: Sum(t.attr('QUANTITY')),
  }));

  const TopFiveItems = ItemsByMonth.select((t) => ({
    ...t.star(),
    MAX_ORDER_DATE: MaxOver(t.attr('MONTH'), {}),
  }))
    .where((t) => t.attr('MONTH').eq(t.attr('MAX_ORDER_DATE')))
    .select((t) => ({
      ...t.star(),
      RANK: RowNumberOver({ orderBy: Desc(t.attr('QUANTITY_PURCHASED')) }),
    }))
    .where((t) => t.attr('RANK').lte(5));

  const ActiveItemDetails = ActiveItems.innerJoin(
    ItemDetail,
    (item, detail) => ({
      on: item.attr('PRODUCT_ID').eq(detail.attr('PRODUCT_ID')),
      select: {
        ...detail.star(),
      },
    })
  );

  const favoriteProductsTables: FavoriteProductsTables = {
    Orders,
    ActiveItems,
    ItemDetail,
  };

  const customerSpecifications: CustomerSpecifications = {
    customerIdColumn: (t: RelationRef) => t.attr('__COTERA_CUSTOMER_ID'),
    favoriteProductIdentifierColumn: (t: RelationRef) => t.attr('PRODUCT_ID'),
    favoriteProductNameColumn: (t: RelationRef) => t.attr('ITEM_NAME'),
    quantityMeasureColumn: (t: RelationRef) => t.attr('QUANTITY'),
  };

  const favoriteItemParams = {
    favoriteProductsTables,
    customerSpecifications,
  };

  const FavoriteProducts = userFavoriteProducts(favoriteItemParams).leftJoin(
    ItemDetail,
    (fav, item) => ({
      on: fav.attr('FAVORITE_PRODUCT_ID').eq(item.attr('PRODUCT_ID')),
      select: {
        ...fav.pick('__COTERA_CUSTOMER_ID', 'CONSERVATIVE_SHOPPER'),
        ...item.renameWith(
          (name) => `FAVORITE_${name}`,
          [
            'ITEM_NAME',
            'ITEM_PRODUCT_COST',
            'ITEM_PRODUCT_URL',
            'ITEM_PRODUCT_IMAGE_URL',
          ]
        ),
        FAVORITE_ITEM_ID: item.attr('PRODUCT_ID'),
      },
    })
  );

  const FirstProductPurchase = Orders.where((t) =>
    t.attr('PRODUCT_ID').isNotNull()
  )
    .select(
      (t) => ({
        ...t.pick('__COTERA_CUSTOMER_ID'),
        FIRST_ITEM_ID: FirstValueOver(t.attr('PRODUCT_ID'), {
          partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
          orderBy: [
            Asc(t.attr('ORDER_DATE')),
            Desc(t.attr('QUANTITY')),
            Desc(t.attr('PRICE')),
          ],
        }),
      }),
      { distinct: true }
    )
    .leftJoin(ItemDetail, (t, i) => ({
      on: t.attr('FIRST_ITEM_ID').eq(i.attr('PRODUCT_ID')),
      select: {
        ...t.star(),
        FIRST_ITEM_NAME: i.attr('ITEM_NAME'),
      },
    }));

  return Page(['productId'] as const, ({ productId }) => {
    return [
      UI.Header({
        title: 'Item Performance',
        caption:
          'This report breaks down specific item buying trends and customer behavior.',
      }),
      UI.Block([
        Callout({
          rels: [
            ActiveItemDetails.where((t) =>
              t.attr('PRODUCT_ID').cast('string').eq(productId)
            ).select((t) => ({
              title: 'Item Name',
              value: t.attr('ITEM_NAME'),
            })),
            ActiveItemDetails.where((t) =>
              t.attr('PRODUCT_ID').cast('string').eq(productId)
            ).select((t) => ({
              title: 'Product ID',
              value: t.attr('PRODUCT_ID').cast('string'),
            })),
          ],
        }),
      ]),
      UI.Block([
        UI.Grid({
          columns: 2,
          sections: [
            Callout({
              rels: [
                Orders.where((t) =>
                  t.attr('PRODUCT_ID').cast('string').eq(productId)
                ).summary((t) => ({
                  title: 'Total Dollars Spent',
                  value: Sum(t.attr('QUANTITY').mul(t.attr('PRICE'))).cast(
                    'string'
                  ),
                  unit: 'USD',
                })),
              ],
            }),
            UI.Stats([
              Orders.where((t) =>
                t.attr('PRODUCT_ID').cast('string').eq(productId)
              )
                .summary((t) => ({
                  value: Sum(t.attr('QUANTITY')),
                }))
                .chart.Stat((t) => ({
                  value: t.attr('value'),
                  unit: 'units',
                  title: 'Total Quantity Purchased',
                })),
            ]),
          ],
        }),
      ]),
      UI.Block([
        UI.Stats([
          CompareTotalSpend.where((t) =>
            t.attr('PRODUCT_ID').cast('string').eq(productId)
          )
            .select((t) => ({
              ...t.star(),
              orderDiff: t
                .attr('CURRENT_TOTAL_SPEND')
                .sub(t.attr('PREVIOUS_TOTAL_SPEND')),
            }))
            .chart.Stat(
              (t) => ({
                value: t.attr('CURRENT_TOTAL_SPEND'),
                from: t.attr('PREVIOUS_TOTAL_SPEND'),
                style: If(
                  t
                    .attr('CURRENT_TOTAL_SPEND')
                    .gt(t.attr('PREVIOUS_TOTAL_SPEND')),
                  {
                    then: 'positive',
                    else: 'negative',
                  }
                ),
                unit: 'USD',
              }),
              {
                title: 'Dollars Spent (last 90 days)',
              }
            ),
          CompareQuantity.where((t) =>
            t.attr('PRODUCT_ID').cast('string').eq(productId)
          )
            .select((t) => ({
              ...t.star(),
              orderDiff: t
                .attr('CURRENT_QUANTITY')
                .sub(t.attr('PREVIOUS_QUANTITY')),
            }))
            .chart.Stat(
              (t) => ({
                value: t.attr('CURRENT_QUANTITY'),
                from: t.attr('PREVIOUS_QUANTITY'),
                style: If(
                  t.attr('CURRENT_QUANTITY').gt(t.attr('PREVIOUS_QUANTITY')),
                  {
                    then: 'positive',
                    else: 'negative',
                  }
                ),
              }),
              {
                title: 'Quantity Purchased (last 90 days)',
              }
            ),
        ]),
      ]),
      UI.Block([
        LineChart({
          size: 'lg',
          title: 'Total Quantity Purchased by Month',
          rel: OrderMonths.where((t) =>
            t.attr('PRODUCT_ID').cast('string').eq(productId)
          ).select(
            (t) => ({
              y: t.attr('QUANTITY_PURCHASED'),
              x: t.attr('MONTH'),
              category: 'Quantity Purchased',
            }),
            { orderBy: (t) => Desc(t.attr('x')) }
          ),
          axis: {
            x: {
              label: 'Month',
              scale: 'day',
            },
            y: {
              label: 'Quantity Purchased by Month',
            },
          },
        }),
      ]),
      UI.Block([
        LineChart({
          size: 'lg',
          title: 'Total Quantity Purchased by Month Compared to Top Five Items',
          rel: ItemsByMonth.where((t) =>
            t.attr('MONTH').gte(Today().dateSub('days', 365).dateTrunc('month'))
          )
            .innerJoin(TopFiveItems, (month, top) => ({
              on: Or(
                month.attr('PRODUCT_ID').eq(top.attr('PRODUCT_ID')),
                month.attr('PRODUCT_ID').cast('string').eq(productId)
              ),
              select: { ...month.star(), ...top.pick('RANK') },
            }))
            .where((t) =>
              t
                .attr('RANK')
                .isNotNull()
                .or(t.attr('PRODUCT_ID').cast('string').eq(productId))
            )
            .innerJoin(ActiveItemDetails, (t, item) => ({
              on: t.attr('PRODUCT_ID').eq(item.attr('PRODUCT_ID')),
              select: {
                ...t.star(),
                ...item.pick('ITEM_NAME'),
              },
            }))
            .select(
              (t) => ({
                y: t.attr('QUANTITY_PURCHASED'),
                x: t.attr('MONTH'),
                category: t.attr('ITEM_NAME'),
              }),
              { orderBy: (t) => Desc(t.attr('x')) }
            ),
          axis: {
            x: {
              label: 'Month',
              scale: 'day',
            },
            y: {
              label: 'Top 5 Performing Items (Last 90 Days)',
            },
          },
        }),
      ]),
      UI.Block([
        UI.Stats([
          FirstProductPurchase.summary((t) => ({
            value: CountDistinct(
              If(t.attr('FIRST_ITEM_ID').cast('string').eq(productId), {
                then: t.attr('__COTERA_CUSTOMER_ID'),
              })
            )
              .safeDiv(CountDistinct(t.attr('__COTERA_CUSTOMER_ID')))
              .mul(100)
              .cast('float'),
            unit: '%',
          })).chart.Stat(
            (t) => ({
              ...t.pick('value', 'unit'),
            }),
            { title: 'Proportion of First Purchases with Item' }
          ),
          RepeatPurchasers.where((t) =>
            t.attr('PRODUCT_ID').cast('string').eq(productId)
          ).chart.Stat(
            (t) => ({
              value: t
                .attr('REPEAT_CUSTOMER_COUNT')
                .safeDiv(t.attr('TOTAL_CUSTOMER_COUNT'))
                .mul(100)
                .cast('float'),
              unit: '%',
            }),
            { title: 'Proportion of Purchasers Who Make a Repeat Purchase' }
          ),
          AverageDaysBetweenRepeatPurchases.where((t) =>
            t.attr('PRODUCT_ID').cast('string').eq(productId)
          ).chart.Stat(
            (t) => ({
              value: t.attr('AVG_DAYS_BETWEEN_PURCHASES'),
            }),
            { title: 'Avg Days Between Repeat Purchases' }
          ),
        ]),
      ]),
      UI.Block([
        UI.Stats([
          CompareCustomers.where((t) =>
            t.attr('PRODUCT_ID').cast('string').eq(productId)
          )
            .select((t) => ({
              ...t.star(),
              orderDiff: t
                .attr('CURRENT_CUSTOMERS')
                .sub(t.attr('PREVIOUS_CUSTOMERS')),
            }))
            .chart.Stat(
              (t) => ({
                value: t.attr('CURRENT_CUSTOMERS'),
                from: t.attr('PREVIOUS_CUSTOMERS'),
                style: If(
                  t.attr('CURRENT_CUSTOMERS').gt(t.attr('PREVIOUS_CUSTOMERS')),
                  {
                    then: 'positive',
                    else: 'negative',
                  }
                ),
              }),
              { title: 'Number of Customers Purchased (last 90 days)' }
            ),
          FavoriteProducts.where((t) =>
            t.attr('FAVORITE_ITEM_ID').cast('string').eq(productId)
          )
            .summary((t) => ({
              value: CountDistinct(t.attr('__COTERA_CUSTOMER_ID')),
            }))
            .chart.Stat(
              (t) => ({
                ...t.pick('value'),
              }),
              { title: 'Total Favorite Item Customers' }
            ),
        ]),
      ]),
      UI.Row([
        UI.Half(
          UI.Block(
            [
              PreviousProductFlow({
                Orders,
                productId,
                startDate: Today().dateSub('days', 90),
              })
                .where((t) => t.attr('PRODUCT_ID').isNotNull())
                .leftJoin(ItemDetail, (flow, item) => ({
                  on: item.attr('PRODUCT_ID').eq(flow.attr('PRODUCT_ID')),
                  select: {
                    ...flow.star(),
                    ITEM_NAME: item
                      .attr('ITEM_NAME')
                      .coalesce(flow.attr('PRODUCT_ID').cast('string')),
                  },
                }))
                .leftJoin(ItemDetail, (flow, item) => ({
                  on: item
                    .attr('PRODUCT_ID')
                    .cast('string')
                    .eq(flow.attr('KEY_PRODUCT_ID').cast('string')),
                  select: {
                    ...flow.star(),
                    KEY_ITEM_NAME: item
                      .attr('ITEM_NAME')
                      .coalesce(flow.attr('KEY_PRODUCT_ID')),
                    ITEM_RANK: RowNumberOver({
                      orderBy: Desc(flow.attr('CUSTOMER_COUNT')),
                    }),
                  },
                }))
                .select((t) => ({
                  ...t.star(),
                  ITEM_NAME: If(t.attr('ITEM_RANK').gt(9), {
                    then: 'Other products',
                    else: t.attr('ITEM_NAME'),
                  }),
                }))
                .groupBy((t) => t.pick('ITEM_NAME', 'KEY_ITEM_NAME'))
                .select((t) => ({
                  ...t.group(),
                  CUSTOMER_COUNT: Sum(t.attr('CUSTOMER_COUNT')),
                }))
                .chart.SankeyChart((t) => ({
                  to: t.attr('KEY_ITEM_NAME'),
                  from: t.attr('ITEM_NAME'),
                  value: t.attr('CUSTOMER_COUNT'),
                })),
            ],
            { title: 'Products purchased before' }
          )
        ),
        UI.Half(
          UI.Block(
            [
              NextProductFlow({
                Orders,
                productId,
                startDate: Today().dateSub('days', 90),
              })
                .leftJoin(ItemDetail, (flow, item) => ({
                  on: item.attr('PRODUCT_ID').eq(flow.attr('PRODUCT_ID')),
                  select: {
                    ...flow.star(),
                    ITEM_NAME: item
                      .attr('ITEM_NAME')
                      .coalesce(flow.attr('PRODUCT_ID').cast('string')),
                  },
                }))
                .leftJoin(ItemDetail, (flow, item) => ({
                  on: item
                    .attr('PRODUCT_ID')
                    .cast('string')
                    .eq(flow.attr('KEY_PRODUCT_ID').cast('string')),
                  select: {
                    ...flow.star(),
                    KEY_ITEM_NAME: item
                      .attr('ITEM_NAME')
                      .coalesce(flow.attr('KEY_PRODUCT_ID')),
                    ITEM_RANK: RowNumberOver({
                      orderBy: Desc(flow.attr('CUSTOMER_COUNT')),
                    }),
                  },
                }))
                .select((t) => ({
                  ...t.star(),
                  ITEM_NAME: If(t.attr('ITEM_RANK').gt(9), {
                    then: 'Other products',
                    else: t.attr('ITEM_NAME'),
                  }),
                }))
                .groupBy((t) => t.pick('ITEM_NAME', 'KEY_ITEM_NAME'))
                .select((t) => ({
                  ...t.group(),
                  CUSTOMER_COUNT: Sum(t.attr('CUSTOMER_COUNT')),
                }))
                .chart.SankeyChart((t) => ({
                  to: t.attr('ITEM_NAME'),
                  from: t.attr('KEY_ITEM_NAME'),
                  value: t.attr('CUSTOMER_COUNT'),
                })),
            ],
            { title: 'Products purchased after' }
          )
        ),
      ]),
      UI.Block(
        RecentCustomersRfm
          ? TodaysCustomerRfm
            ? [
                UI.Row([
                  UI.Half(
                    FavoriteProducts.innerJoin(
                      TodaysCustomerRfm,
                      (fav, rfm) => ({
                        on: fav
                          .attr('__COTERA_CUSTOMER_ID')
                          .eq(rfm.attr('__COTERA_CUSTOMER_ID')),
                        select: {
                          ...fav.star(),
                          ...rfm.pick('RFM_CATEGORY'),
                        },
                      })
                    )
                      .where((t) =>
                        t.attr('FAVORITE_ITEM_ID').cast('string').eq(productId)
                      )
                      .countBy((t) => ({
                        ...t.pick('RFM_CATEGORY'),
                      }))
                      .where((t) =>
                        And(
                          Not(t.attr('RFM_CATEGORY').eq('Lost Customers')),
                          Not(
                            t.attr('RFM_CATEGORY').eq('Hibernating Customers')
                          )
                        )
                      )
                      .chart.PieChart(
                        (t) => ({
                          value: t.attr('COUNT'),
                          category: t.attr('RFM_CATEGORY'),
                        }),
                        { title: 'RFM Categories of Favorite Item Customers' }
                      )
                  ),
                  UI.Half(
                    RecentCustomersRfm.where((t) =>
                      t.attr('PRODUCT_ID').cast('string').eq(productId)
                    )
                      .countBy((t) => ({
                        ...t.pick('RFM_CATEGORY'),
                      }))
                      .chart.PieChart(
                        (t) => ({
                          value: t.attr('COUNT'),
                          category: t.attr('RFM_CATEGORY'),
                        }),
                        { title: 'RFM Categories of Last 90 Days Customers' }
                      )
                  ),
                ]),
                '* charts exclude customers that have not made a purchase in over 6 months.',
              ]
            : []
          : []
      ),
    ];
  });
};
