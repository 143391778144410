import { classNames } from '@cotera/client/app/components/utils';

const CAPTION_TEXT = {
  normal: 'text-standard-text',
  dark: 'text-alt-text',
  subtle: 'text-muted-text',
} as const;

export const Caption: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > & { theme?: 'normal' | 'dark' | 'subtle' }
> = ({ className = '', theme = 'normal', ...props }) => {
  return (
    <span
      {...props}
      className={classNames(
        'text-sm inline-block text-left',
        className,
        CAPTION_TEXT[theme]
      )}
    />
  );
};
