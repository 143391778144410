import { useKeyDownState } from '@cotera/client/app/hooks';
import { useCallback } from 'react';
import { DataGridViewModel } from '../types';
import { useSubscribe } from '@cotera/client/app/etc';

export const useSelectRow = (vm: DataGridViewModel) => {
  const selectedRows = useSubscribe(vm, (s) => s.selectedRows);

  const isCtrlPressed = useKeyDownState((e) => e.metaKey || e.ctrlKey);
  const isShiftPressed = useKeyDownState((e) => e.shiftKey);

  const selectRow = useCallback(
    (index: number) => {
      const isMultiSelecting = isCtrlPressed || isShiftPressed;

      if (!isMultiSelecting && selectedRows.length === 0) {
        return vm.setSelectedRows([index]);
      }

      if (
        !isMultiSelecting &&
        selectedRows.length === 1 &&
        selectedRows[0] !== index
      ) {
        return vm.setSelectedRows([index]);
      }

      if (!isMultiSelecting && selectedRows.length > 1) {
        return vm.setSelectedRows([index]);
      }

      if (selectedRows.includes(index)) {
        return vm.setSelectedRows(selectedRows.filter((x) => x !== index));
      }

      if (isMultiSelecting) {
        return vm.setSelectedRows([...selectedRows, index]);
      }
    },
    [isCtrlPressed, isShiftPressed, selectedRows, vm]
  );

  return selectRow;
};
