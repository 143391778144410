import { classNames } from '@cotera/client/app/components/utils';
export const Overlay: React.FC<{
  z: string;
  onClick?: () => void;
  className?: string;
}> = ({ z, onClick, className }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'backdrop-blur-sm fixed inset-0 bg-gray-400 bg-opacity-20',
        z,
        className
      )}
    />
  );
};
