import { useEffect, useState } from 'react';

export const useKeyDownState = (
  target: string | ((e: KeyboardEvent) => boolean)
) => {
  const [shiftHeld, setShiftHeld] = useState(false);
  const condition =
    typeof target === 'function'
      ? target
      : (e: KeyboardEvent) => e.key === target;

  function downHandler(e: KeyboardEvent) {
    if (condition(e)) {
      setShiftHeld(true);
    }
  }

  function upHandler(e: KeyboardEvent) {
    if (!condition(e)) {
      setShiftHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shiftHeld;
};
