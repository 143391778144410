import { Contract, GET, POST } from '@cotera/contracts';
import z from 'zod';

export const ReasonsContract = Contract.new({
  examples: GET({
    params: z.object({
      reasonId: z.string(),
    }),
    output: z
      .object({
        topicId: z.string(),
        content: z.string(),
        type: z.string(),
      })
      .array(),
    errors: z.never(),
  }),
  search: GET({
    params: z.object({
      query: z.string(),
    }),
    output: z
      .object({
        topicId: z.string(),
        content: z.string(),
        type: z.string(),
        distance: z.number(),
      })
      .array(),
    errors: z.never(),
  }),
  addExample: POST({
    params: z.object({
      topicId: z.string(),
      reasonId: z.string(),
    }),
    output: z.object({
      exampleId: z.string(),
    }),
    errors: z.never(),
  }),
  dryRun: POST({
    params: z.object({
      entityName: z.string(),
      topicExtractionPromptId: z.string(),
      summaryPromptId: z.string(),
      messages: z
        .object({
          content: z.string(),
          coteraStableId: z.string(),
        })
        .array(),
    }),
    output: z
      .object({
        keywordId: z.string(),
        sentiment: z.string(),
        messageStableId: z.string(),
      })
      .array(),
    errors: z.object({
      errorType: z.enum([
        'LlmError',
        'EmbeddingsError',
        'LlmValidationError',
        'NoMatch',
        'NotFound',
      ]),
    }),
  }),
  add: POST({
    params: z.object({
      entityId: z.string(),
      name: z.string(),
    }),
    output: z.object({
      id: z.string(),
    }),
    errors: z.never(),
  }),
  deleteExample: POST({
    params: z.object({
      topicId: z.string(),
    }),
    output: z.undefined(),
    errors: z.never(),
  }),
  reasons: GET({
    params: z.object({}),
    output: z
      .object({
        name: z.string(),
        id: z.string(),
        entityId: z.string(),
        numExamples: z.number(),
      })
      .array(),
    errors: z.never(),
  }),
});
