import React from 'react';
import { Divider, Title as UiTitle } from '@cotera/client/app/components/ui';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { Theme } from '../../types/utils';

const STYLES = {
  container: {
    tight: '',
    normal: '',
  },
  content: {
    tight: 'py-2 px-4',
    normal: 'p-4',
  },
  divider: {
    tight: 'mt-2',
    normal: 'mt-3',
  },
  header: {
    tight: '',
    normal: '',
  },
  title: {
    tight: 'text-md',
    normal: '',
  },
};

const Container = ({
  children,
  className,
  bottomPadding = true,
  noBackground = false,
  tight = false,
  decoration,
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
  bottomPadding?: boolean;
  noBackground?: boolean;
  tight?: boolean;
  decoration?: Theme;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <DecorationWrapper theme={decoration}>
      <div
        className={classNames(
          !noBackground ? 'border border-divider rounded bg-white' : '',
          className,
          bottomPadding ? 'mb-4' : '',
          STYLES.container[tight ? 'tight' : 'normal']
        )}
        {...rest}
      >
        {children}
      </div>
    </DecorationWrapper>
  );
};

const DECORATION_CSS: Record<Theme, string> = {
  primary: 'bg-indigo-400',
  secondary: 'bg-emerald-400',
  error: 'bg-rose-400',
  muted: 'bg-gray-50',
  regular: 'bg-white',
  warning: 'bg-yellow-400',
};

const DecorationWrapper: React.FC<{ theme?: Theme } & ChildrenProps> = ({
  theme,
  children,
}) => {
  if (theme === undefined) {
    return <>{children}</>;
  }

  return (
    <div className={classNames(DECORATION_CSS[theme], 'rounded-extra pt-1')}>
      {children}
    </div>
  );
};

const Content = ({
  children,
  className,
  padding = true,
  scroll = false,
}: {
  scroll?: boolean;
  children: React.ReactNode;
  className?: string;
  padding?: boolean;
}) => {
  return (
    <div
      className={classNames(
        padding ? STYLES.content['normal'] : '',
        scroll ? 'overflow-auto' : '',
        className
      )}
    >
      {children}
    </div>
  );
};

const Header = ({
  children,
  shaded = false,
  divider = true,
  className,
}: {
  children: React.ReactNode;
  shaded?: boolean;
  divider?: boolean;
  className?: string;
}) => {
  return (
    <Content
      padding={false}
      className={classNames(
        'w-full rounded pb-0 px-4 pt-4',
        shaded ? 'bg-indigo-50' : '',
        STYLES.header['normal'],
        className
      )}
    >
      {children}
      {!shaded && divider && (
        <Divider className={classNames(STYLES.divider['normal'])} />
      )}
    </Content>
  );
};

const TITLE_SIZE = {
  sm: 'text-sm',
  lg: 'text-md',
};

const Title: React.FC<
  ChildrenProps & { className?: string; size?: keyof typeof TITLE_SIZE }
> = ({ children }) => {
  return <UiTitle type="section">{children}</UiTitle>;
};

const Footer: React.FC<
  ChildrenProps & {
    border?: boolean;
  }
> = ({ children, border = true }) => {
  return (
    <div
      className={classNames(
        'flex items-center w-full',
        border ? 'border-t border-divider' : ''
      )}
    >
      {children}
    </div>
  );
};

export const Card = {
  Container,
  Content,
  Header,
  Title,
  Footer,
};
