import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { classNames } from '../../utils';
import { NavLink as BaseNavLink, useLocation } from 'react-router-dom';
import { Accordion, useAccordion } from '../../headless/accordion';
import { create } from 'zustand';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { LogoComponent } from '../logo/logo-svg';
import {
  Button,
  ICON_MAPPING,
  Collapse,
  Rotate,
} from '@cotera/client/app/components/ui';

export const useSidebar = create<{
  collapsed: boolean;
  setCollapsed: (c: boolean) => void;
}>((set) => ({
  collapsed: true,
  setCollapsed: (collapsed: boolean) => set({ collapsed }),
}));

export type SideBarProps = {
  navigation: NavItem[];
};

const COLLAPSE_DURATION = 200;

const SideBarContainer: React.FC<ChildrenProps> = ({ children }) => {
  const collapsed = useSidebar((state) => state.collapsed);
  const setCollapsed = useSidebar((state) => state.setCollapsed);

  return (
    <>
      <div className="ml-2 h-16 fixed -top-1.5 left-0 z-[5] flex items-center justify-between">
        <button onClick={() => setCollapsed(!collapsed)}>
          <Bars3Icon className="h-5 w-5 my-auto ml-3 mr-3 text-standard-text" />
        </button>
      </div>
      <div
        className={classNames(
          'animate-[width] duration-200 flex flex-col float-left pb-4 fixed overflow-x-hidden border-r border-divider h-screen bg-white z-10',
          collapsed ? 'w-[0px] md:w-[52px]' : 'w-[18rem] shadow-sm'
        )}
      >
        <div className="ml-2 -mt-1.5 h-16 flex items-center justify-between">
          <Collapse
            in={!collapsed}
            property="width"
            duration={COLLAPSE_DURATION}
          >
            <div className={classNames('flex flex-row pl-2')}>
              <div className="w-4 items-center my-auto ml-2">
                <LogoComponent />
              </div>
              <span className="ml-2.5 font-semibold text-2xl text-indigo-800 tracking-tight mb-0.5">
                cotera
              </span>
            </div>
          </Collapse>
          <button onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <Bars3Icon className="h-5 w-5 my-auto ml-3 mr-3 text-standard-text" />
            ) : (
              <ChevronLeftIcon className="h-5 w-5 my-auto ml-3 mr-3 text-standard-text" />
            )}
          </button>
        </div>
        <nav className="mt-2 flex-1 px-4 space-y-1 h-full" aria-label="Sidebar">
          <Accordion.Root expanded={[]}>
            <div className="h-full w-full flex flex-col justify-between">
              {children}
            </div>
          </Accordion.Root>
        </nav>
      </div>
    </>
  );
};

interface NavItem {
  name: string;
  to: string;
  icon: keyof typeof ICON_MAPPING;
  className?: string;
  activeOnExact?: boolean;
  activeFn?: (location: string) => boolean;
  expand?: boolean;
}

type ItemProps = {
  isActive?: boolean;
  icon: keyof typeof ICON_MAPPING;
  name: string;
  className?: string;
  onClick?: () => void;
  expand?: boolean;
};

const NavItem: React.FC<
  ItemProps & { children?: ChildrenProps['children'] }
> = ({ name, icon, isActive, onClick, expand }) => {
  const collapsed = useSidebar((state) => state.collapsed);

  return (
    <Button
      onClick={onClick}
      inline
      icon={icon}
      text={name}
      tooltip={'right'}
      iconOnly={!expand && collapsed}
      active={isActive}
    />
  );
};

const NavLink: React.FC<NavItem> = ({
  name,
  to,
  icon,
  className,
  activeOnExact = false,
  activeFn,
  expand,
}) => {
  const location = useLocation();
  return (
    <BaseNavLink
      key={name}
      to={to}
      children={({ isActive: defaultIsActive }) => {
        const isActiveOnExact = activeOnExact && to === location.pathname;
        const isActiveFn = activeFn && activeFn(location.pathname);
        const isActive =
          (defaultIsActive && !activeOnExact && !activeFn) ||
          isActiveOnExact ||
          isActiveFn;

        return (
          <NavItem
            expand={expand}
            name={name}
            icon={icon}
            isActive={isActive}
            className={classNames(className, 'mb-2')}
          />
        );
      }}
    />
  );
};
const Separator = () => <div className="border-t border-divider my-2 w-full" />;

const ExpandableSection: React.FC<
  Omit<NavItem, 'isActive'> & ChildrenProps
> = ({ name, to, icon, children }) => {
  const expanded = useAccordion((x) => x.expanded);
  const l = useLocation();
  const isActive = l.pathname.includes(to);

  return (
    <>
      <Accordion.Trigger
        id={name}
        as={(props) => (
          <NavItem
            {...props}
            name={name}
            isActive={false}
            icon={icon}
            className="mb-2 justify-between cursor-pointer"
          />
        )}
      >
        <Rotate
          degrees={expanded.includes(name) ? 180 : 0}
          in={expanded.includes(name)}
          className="mr-4"
        >
          <ChevronDownIcon height={15} />
        </Rotate>
      </Accordion.Trigger>
      <Accordion.Item
        className="w-full overflow-hidden"
        id={name}
        open={isActive}
      >
        {children}
      </Accordion.Item>
    </>
  );
};

const Main: React.FC<ChildrenProps> = ({ children }) => {
  return <div className="w-full self-start">{children}</div>;
};

const End: React.FC<ChildrenProps> = ({ children }) => {
  return <div className="w-full self-end">{children}</div>;
};

export const SideBar = {
  Link: NavLink,
  Container: SideBarContainer,
  Separator,
  ExpandableSection,
  Main,
  End,
  NavItem,
};
