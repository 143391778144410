import {
  Divider,
  Title,
  Text,
  Badge,
  Button,
} from '@cotera/client/app/components/ui';
import React from 'react';
import {
  DocumentListForRelation,
  FilterBuilder,
  useOptions,
} from '@cotera/client/app/components/app';
import { Card } from '@cotera/client/app/components/headless';
import { uniqBy } from 'lodash';
import { useSubscribe, useViewModelContext } from '@cotera/client/app/etc';
import { DocumentsViewModel } from './viewmodel';

export const Documents: React.FC<{
  entityName: string;
  forceRender: number;
  features: { id: string; content: string }[];
  onRefreshClick: () => void;
}> = React.memo(
  ({ onRefreshClick }) => {
    const vm = useViewModelContext<DocumentsViewModel>();
    const baseRel = useSubscribe(vm, (s) => s.baseRel);
    const options = useOptions(baseRel.attributes);
    const rel = useSubscribe(vm, (s) => s.rel);

    return (
      <>
        <FilterBuilder
          runEnabled
          options={options}
          rel={baseRel}
          onRun={({ filterGroup, limit }) => {
            vm.setFilters(filterGroup, limit);
          }}
        />
        <Card.Container className="h-[calc(100%-60px)] mt-2">
          <div className="flex items-center">
            <Title
              type="section"
              title="Documents"
              subtitle="Sample of documents in this topics training set"
              className="w-fit px-4 pt-4 pb-2"
            />
            <Button
              icon="arrow-path"
              tooltip="left"
              text={'refresh sample'}
              inline
              theme="primary"
              className="ml-auto mr-4"
              onClick={() => {
                onRefreshClick();
              }}
            />
          </div>
          <Divider className="mb-2" />
          <DocumentListForRelation
            className="h-[calc(100%-46px)]"
            rel={rel.limit(301)}
            renderer={(props) => (
              <Document
                {...props}
                features={vm.featuresForDocument(props.item.id)}
              />
            )}
          />
        </Card.Container>
      </>
    );
  },
  (prev, next) => prev.forceRender === next.forceRender
);

const Document: React.FC<{
  pos: number;
  item: {
    value: string;
    id: string;
  };
  features: {
    id: string;
    content: string;
  }[];
}> = ({ item, features: featuresForDocument }) => {
  return (
    <li className="flex flex-col w-full overflow-x-scroll pb-2">
      <Title type="label" className="w-fit mb-2">
        {item.id}
      </Title>
      <Text.P className="max-h-[100px] overflow-y-scroll mb-3 text-wrap break-all">
        {item.value}
      </Text.P>
      <div className="flex items-center mb-2 flex-wrap">
        {uniqBy(featuresForDocument, 'id').map((f, i) => (
          <Badge key={i} className="mr-2 mb-2" theme={'primary'}>
            {f.content}
          </Badge>
        ))}
      </div>
      <Divider />
    </li>
  );
};
