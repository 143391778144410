import { forwardRef } from 'react';
import { ColorScheme } from '../../utils/colors/color-scheme';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { Theme } from '../../types/utils';

export const Caption = forwardRef<
  HTMLParagraphElement,
  ChildrenProps & { className?: string; theme?: Theme }
>(({ children, className, theme = 'muted' }, ref) => {
  return (
    <p
      ref={ref}
      className={classNames(ColorScheme.text[theme], 'text-xs', className)}
    >
      {children}
    </p>
  );
});
