import { useState } from 'react';
import { FormComponentProps } from '../../types/form-component';
import { FormLabel, NameWrapper } from '../utils';
import React from 'react';
import {
  classNames,
  ColorScheme,
  Styles,
} from '@cotera/client/app/components/utils';
import { Button, Icon } from '@cotera/client/app/components/ui';

type Props<Option extends string> = {
  options: readonly Option[];
  value?: Option;
  disabled?: boolean;
  onChange: (option?: Option) => void;
  className?: string;
  nullable?: boolean;
} & FormComponentProps;

export function ToggleSelect<T extends string>({
  label,
  compact = false,
  icon,
  disabled = false,
  theme = 'regular',
  className,
  nullable = true,
  ...props
}: Props<T>) {
  const [hasFocus, setHasFocus] = useState(false);

  const focusProps = {
    'data-focus': hasFocus ? true : undefined,
  };

  return (
    <NameWrapper name={label} compact={compact}>
      <div
        {...focusProps}
        tabIndex={0}
        onBlur={() => setHasFocus(false)}
        role="group"
        className={classNames(
          'w-fit flex items-center',
          Styles.input,
          ColorScheme.text[theme],
          Styles.compactableInput[compact ? 'compact' : 'normal'],
          Styles.focus['normal'],
          disabled ? Styles.disabled : '',
          'hover:!bg-white',
          className
        )}
      >
        {icon && <Icon icon={icon} theme="regular" />}
        <FormLabel label={label} compact={compact} disabled={disabled} />
        {props.options.map((option, index) => {
          return (
            <React.Fragment key={index}>
              <Button
                inline
                compact
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                text={option.toUpperCase()}
                theme={props.value === option ? 'primary' : 'regular'}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onChange(
                    props.value === option && nullable ? undefined : option
                  );
                }}
              />
              {index !== props.options.length - 1 && (
                <div className="rounded-[100%] bg-zinc-200 h-1 w-1" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </NameWrapper>
  );
}
