import { DevServerClient, DevServerError } from '@cotera/api';
import { AST, TC } from '@cotera/era';
import { assertOk } from '@cotera/utilities';
import { ok, Result } from 'neverthrow';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useDevMode } from '../stores/dev-mode';
import { useTenantedClient, useWhoami } from '../stores/org';
import { QueryOpts } from './hook-helpers';
import { queryKeys } from './query-cache-keys';
import { useAppData } from '@cotera/client/app/stores/org';

//TODO move these into a different folder
export const useDevServer = (): {
  client: DevServerClient;
  source: 'dev' | 'commited';
} => {
  const localDevServer = useWhoami((x) => x.localDevServer);
  const devModeEnabled = useDevMode((s) => s.enabled);
  const { devServer } = useTenantedClient();
  const source = devModeEnabled ? 'dev' : 'commited';
  return { source, client: source === 'dev' ? localDevServer : devServer };
};

export const useManifestApp = (
  params: {
    id: string;
    version: string;
  },
  opts?: QueryOpts
): UseQueryResult<Result<AST._App | null, DevServerError>> => {
  const orgId = useWhoami((s) => s.org.id);
  const { client } = useDevServer();

  return useQuery({
    queryKey: queryKeys.manifest.app({ ...params, orgId }),
    queryFn: async (): Promise<Result<AST._App | null, DevServerError>> => {
      const res = assertOk(await client.manifests.app(params));

      if (res === null) {
        return ok(null);
      }
      const checked = TC.MU.checkApp(res);

      if (checked instanceof TC.TyStackTrace) {
        throw checked.toError({});
      }

      return ok(checked);
    },
    enabled: opts?.enabled ?? true,
  });
};

export const useManifestDetailPage = ({
  page,
}: {
  page: string;
}): UseQueryResult<Result<AST.Mu, DevServerError>> => {
  const version = useAppData((x) => x.skeleton.version);
  const orgId = useWhoami((s) => s.org.id);
  const { client } = useDevServer();
  return useQuery({
    queryKey: queryKeys.manifest.detailPage({ page, orgId, version }),
    queryFn: async (): Promise<Result<AST.Mu, never>> => {
      const res = assertOk(
        await client.manifests.detailsPage({ version, page })
      );
      if (res === null) {
        throw new Error(`TODO DETAIL PAGE ${page} NOT FOUND`);
      }
      return ok(res);
    },
  });
};
