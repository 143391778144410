import { z } from 'zod';
import { toolCallSchema, toolsSchema } from './tools';

const systemMessageSchema = z.object({
  role: z.literal('system'),
  content: z.string(),
});

const assistantMessageSchema = z.object({
  role: z.literal('assistant'),
  content: z.string().nullable(),
  tool_calls: toolCallSchema.array().optional(),
});

const userMessageSchema = z.object({
  role: z.literal('user'),
  content: z.string(),
  tools: toolsSchema.optional(),
});

const toolMessageSchema = z.object({
  role: z.literal('tool'),
  tool_call_id: z.string(),
  content: z.string(),
});

export type SystemMessage = z.infer<typeof systemMessageSchema>;
export type AssistantMessage = z.infer<typeof assistantMessageSchema>;
export type UserMessage = z.infer<typeof userMessageSchema>;
export type ToolMessage = z.infer<typeof toolMessageSchema>;

export const llmMessageSchema = z.discriminatedUnion('role', [
  systemMessageSchema,
  assistantMessageSchema,
  userMessageSchema,
  toolMessageSchema,
]);

export type LlmMessage = z.infer<typeof llmMessageSchema>;

export const systemMessage = (content: string): SystemMessage => ({
  role: 'system' as const,
  content,
});

export const assistantMessage = (content: string): AssistantMessage => ({
  role: 'assistant' as const,
  content,
});

export const userMessage = (content: string): UserMessage => ({
  role: 'user' as const,
  content,
});
