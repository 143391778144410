import * as Typography from './typography';
import { Caption } from './caption';
import { Label } from './label';
import { Title } from './title';
import { Text as P } from './p';

export * from './typography';
//for backwards compatibility
export { Title, P };

export const Text = {
  ...Typography,
  Caption,
  Label,
  Title,
  P,
};
