import { forwardRef } from 'react';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { classNames } from '@cotera/client/app/components/utils';
import { ColorScheme } from '../../utils/colors/color-scheme';

export const P = forwardRef<
  HTMLParagraphElement,
  ChildrenProps & { className?: string }
>(({ children, className }, ref) => {
  return (
    <p
      ref={ref}
      className={classNames(ColorScheme.text['regular'], 'text-sm', className)}
    >
      {children}
    </p>
  );
});
