import { FFI } from '@cotera/sdk/core';

export const FFI_ACTIONS: Record<
  FFI.FiiFuncName,
  {
    displayType: string;
  }
> = {
  '@@cotera-open-link': {
    displayType: 'link',
  },
  '@@cotera-udd-write': {
    displayType: 'action',
  },
  '@@cotera-pause-sink': {
    displayType: 'action',
  },
  '@@cotera-snapshot': {
    displayType: 'action',
  },
  '@@cotera-set-sink-cursor': {
    displayType: 'action',
  },
  '@@cotera-topic-matching': {
    displayType: 'string',
  },
};
