import { UI, AST, f, MakeStruct, Page, Relation } from '@cotera/era';

export const ItemOverview = ({
  ActiveItems,
  ItemDetail,
}: {
  ActiveItems: Relation;
  ItemDetail: Relation;
}): AST._App => {
  return UI.App({
    title: 'Item Overview',
    icon: 'bar-chart',
    pages: {
      '/': Page([], () => {
        return [
          UI.Header({
            title: 'Item Overview',
          }),
          UI.Block([
            ItemDetail.select((t) => ({
              ...t.pick('PRODUCT_ID'),
              PRODUCT_NAME: t.attr('ITEM_NAME'),
              PRODUCT_PERFORMANCE_DASHBOARD: MakeStruct({
                __link: f`https://app.cotera.co/apps/item-performance/${t.attr(
                  'PRODUCT_ID'
                )}/`,
                text: t.attr('ITEM_NAME'),
                action: 'tab',
              }),
            })).leftJoin(ActiveItems, (item, active) => ({
              on: item.attr('PRODUCT_ID').eq(active.attr('PRODUCT_ID')),
              select: {
                ...item.star(),
                IS_ACTIVE: active.attr('PRODUCT_ID').isNotNull().cast('string'),
              },
            })),
          ]),
        ];
      }),
    },
    menu: [],
  });
};
