import React, { forwardRef, HTMLAttributeAnchorTarget, useRef } from 'react';
import { ColorScheme } from '../../utils/colors/color-scheme';
import { Theme } from '../../types/utils';
import { IconName, Icon } from '../icon';
import { Link } from 'react-router-dom';
import { useKeyPress } from '../../../hooks/use-key-press';
import { useForwardedRef } from '../../../hooks/use-forwarded-ref';
import { CompactableUiComponent } from '../../types/ui-component';
import { makePopover } from '../../headless/popover';
import { List } from '../../headless/list/list';
import { Styles } from '../../utils/styles';
import { FormLabel, NameWrapper } from '../../forms/utils';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { Tooltip, TooltipSide } from '../tooltip';
import { Loading } from '../loading';

const CSS: Record<Theme, string> = {
  primary:
    'bg-indigo-50 border-indigo-400 hover:bg-indigo-100 active:bg-indigo-200',
  secondary:
    'bg-emerald-50 text-emerald-500 border-emerald-400 hover:bg-emerald-100 active:bg-emerald-200',
  regular: `bg-white border-zinc-200 hover:bg-zinc-50 active:bg-zinc-100`,
  muted: `bg-white border-zinc-200 hover:bg-zinc-50 active:bg-zinc-100`,
  error: 'bg-rose-50 border-rose-400 hover:bg-rose-100 active:bg-rose-200',
  warning:
    'bg-orange-50 border-orange-400 hover:bg-orange-100 active:bg-orange-200',
};

const Wrapper: React.FC<ChildrenProps & { link: Props['link'] }> = ({
  children,
  link,
}) => {
  if (link) {
    return (
      <Link
        to={link.to}
        target={link.target}
        className={classNames('flex items-center')}
      >
        {children}
      </Link>
    );
  }
  return children;
};

type Props = CompactableUiComponent<{
  type?: 'button' | 'submit' | 'reset';
  noPadding?: boolean;
  theme?: Theme;
  text?: string;
  icon?: IconName;
  iconOnly?: boolean;
  tooltip?: TooltipSide;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  link?: {
    to: string;
    target?: HTMLAttributeAnchorTarget;
  };
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  options?: React.ReactNode;
  label?: string;
  inline?: boolean;
  small?: boolean;
  active?: boolean;
  overrides?: {
    height?: string;
    py?: string;
  };
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  hint?: {
    display: string;
    targetKey: string | ((e: KeyboardEvent) => boolean);
  };
  __internal?: {
    rounding?: string;
  };
}>;

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      type = 'button',
      compact = false,
      label,
      inline,
      className,
      options,
      onClick,
      onFocus,
      onBlur,
      disabled = false,
      theme = 'regular',
      icon,
      text,
      link,
      active,
      iconOnly,
      tooltip = 'top',
      noPadding = false,
      small = false,
      __internal,
      loading,
      overrides,
      hint,
      iconPosition = 'left',
      ...props
    },
    forwardRef
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useKeyPress('Enter', () => {
      document.activeElement === buttonRef?.current &&
        !disabled &&
        buttonRef?.current?.click();
    });

    useKeyPress(hint?.targetKey, (e) => {
      if (!disabled) {
        e.preventDefault();
        buttonRef?.current?.click();
      }
    });

    useForwardedRef(forwardRef, buttonRef);

    return (
      <NameWrapper name={label} compact={compact}>
        <OptionsWrapper theme={theme} options={options} disabled={disabled}>
          <TooltipWrapper tooltip={text} side={tooltip} show={iconOnly}>
            <button
              {...props}
              type={type}
              ref={buttonRef}
              onClick={(e) => {
                !disabled && onClick?.(e);
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              className={classNames(
                CSS[theme],
                ColorScheme.text[theme],
                noPadding ? '' : 'px-2',
                overrides?.height ?? (small ? 'h-8' : 'h-10'),
                overrides?.py ?? 'py-1.5',
                `overflow-scroll
                no-scrollbar
            flex items-center 
            border 
            ease-in-out duration-200 
            focus:outline-none 
            focus:ring-opacity-80
            active:ring-0
            ring-0
            outline-none
            group
            active:outline-none`,
                options !== undefined
                  ? 'rounded-l'
                  : __internal?.rounding ?? 'rounded',
                disabled ? 'opacity-50 cursor-default' : 'cursor-pointer',
                inline ? Styles.focus['inline'] : Styles.focus['normal'],
                active ? Styles.active[inline ? 'inline' : 'normal'] : '',
                className
              )}
            >
              <Wrapper link={link}>
                {icon && iconPosition === 'left' && (
                  <Icon
                    className={classNames(
                      'flex-shrink-0',
                      text && !iconOnly ? 'mr-2' : '',
                      active ? Styles.active[inline ? 'inline' : 'normal'] : ''
                    )}
                    icon={icon}
                  />
                )}
                <FormLabel
                  hasText={!!(text?.length ?? 0) && !iconOnly}
                  label={label}
                  compact={compact}
                  disabled={disabled}
                />
                {text && !iconOnly && (
                  <span
                    className={classNames(
                      ' h-full flex items-center text-nowrap',
                      Styles.compactableInput[compact ? 'compact' : 'normal'],
                      label ? 'pl-2' : '',
                      icon ? 'pr-2' : ''
                    )}
                  >
                    {text}
                  </span>
                )}
                {hint && (
                  <code className="border py-0.5 px-0.5 text-xs ml-1 rounded opacity-80">
                    {hint.display}
                  </code>
                )}
                {loading && <Loading.Spinner className="ml-2" variant="sm" />}
                {icon && iconPosition === 'right' && (
                  <Icon
                    className={classNames(
                      'flex-shrink-0',
                      text && !iconOnly ? 'mr-2' : '',
                      active ? Styles.active[inline ? 'inline' : 'normal'] : ''
                    )}
                    icon={icon}
                  />
                )}
              </Wrapper>
            </button>
          </TooltipWrapper>
        </OptionsWrapper>
      </NameWrapper>
    );
  }
);

const Popover = makePopover(Button);

const TooltipWrapper = ({
  children,
  show,
  tooltip,
  side,
}: {
  children: React.ReactNode;
  tooltip?: string;
  show?: boolean;
  side: TooltipSide;
}) => {
  if (tooltip && show) {
    return (
      <Tooltip side={side} text={tooltip}>
        {children}
      </Tooltip>
    );
  }
  return children;
};

const OptionsWrapper: React.FC<
  ChildrenProps & { options: Props['options']; theme: Theme; disabled: boolean }
> = ({ children, theme, options, disabled }) => {
  if (options !== undefined) {
    return (
      <div className="flex items-center">
        {children}
        <Popover
          as={Button}
          disabled={disabled}
          className="!border-l-0"
          theme={theme}
          icon="chevron-down"
          anchor="bottom end"
          __internal={{
            rounding: 'rounded-r',
          }}
          panel={{
            view: options,
          }}
        />
      </div>
    );
  }
  return children;
};

export const ButtonOptions: React.FC<ChildrenProps> = ({ children }) => {
  return <List.Ul childType={ButtonOption}>{children}</List.Ul>;
};

export const ButtonOption = List.Li;
