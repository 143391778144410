import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { FormComponentProps } from '../../types/form-component';
import { FormLabel, NameWrapper } from '../utils';
import { omit } from 'lodash';
import {
  Button,
  Icon,
  IconName,
  Loading,
} from '@cotera/client/app/components/ui';
import { useForwardedRef } from '@cotera/client/app/hooks/use-forwarded-ref';
import {
  classNames,
  ColorScheme,
  Styles,
} from '@cotera/client/app/components/utils';
import { useEffectOnce } from 'react-use';

type InputType = 'text' | 'password' | 'email' | 'number';

export type InputProps<T, Nullable extends boolean> = FormComponentProps &
  Pick<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'onKeyDown' | 'placeholder' | 'autoComplete' | 'onBlur' | 'id' | 'onFocus'
  > & {
    loading?: boolean;
    compact?: boolean;
    className?: string;
    disabled?: boolean;
    icon?: IconName;
    type?: InputType;
    autoFocus?: boolean;
    inline?: boolean;
    focusDelay?: number;
    spellcheck?: boolean;
  } & (Nullable extends true
    ? {
        value: T | null;
        onChange: (
          value: T | null,
          e: React.ChangeEvent<HTMLInputElement>
        ) => void;
      }
    : {
        value: T;
        onChange: (value: T, e: React.ChangeEvent<HTMLInputElement>) => void;
      });

function makeValueFn<T>(type: InputType) {
  return (value: string): T | null => {
    if (value === 'NULL') {
      return null;
    }

    switch (type) {
      case 'number':
        return Number(value) as T;
      default:
        return value as T;
    }
  };
}

function makeStringToValueFn<T>(type: InputType) {
  return (value: T | null): string => {
    if (type === 'number' && value === null) {
      return String(0);
    }
    if (value === null) {
      return 'NULL';
    }

    return String(value);
  };
}

export function makeInput<T, Nullable extends boolean>(
  inputType: InputType = 'text'
) {
  return forwardRef<HTMLInputElement, InputProps<T, Nullable>>(
    (
      {
        type = inputType,
        disabled = false,
        value: inputValue,
        onChange,
        icon,
        label,
        compact = false,
        autoFocus,
        inline,
        theme = 'regular',
        focusDelay = 0,
        loading,
        spellcheck,
        ...props
      },
      forwardRef
    ) => {
      const [focused, setFocused] = useState(false);
      const inputRef = useRef<HTMLInputElement>(null);

      const value = makeStringToValueFn<T>(type)(inputValue);

      useForwardedRef(forwardRef, inputRef);

      const changeHandler = useCallback(
        (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
          const valueFn = makeValueFn<T>(type);

          onChange(valueFn(value) as any, e);
        },
        [onChange, type]
      );

      const proxiedProps = {
        ...omit(
          props,
          'onKeyDown',
          'placeholder',
          'autoComplete',
          'onBlur',
          'id'
        ),
        'data-focus': focused ? true : props['data-focus'],
      };

      useEffectOnce(() => {
        if (autoFocus) {
          //the delay makes the ui a little smoother
          setTimeout(() => {
            inputRef.current?.focus();
          }, focusDelay);
        }
      });

      return (
        <NameWrapper name={label} compact={compact}>
          <div
            {...proxiedProps}
            className={classNames(
              Styles.focus[inline ? 'inline' : 'normal'],
              'focus:!text-zinc-400 data-[focus]:!text-zinc-800',
              Styles.input,
              Styles.compactableInput[compact ? 'compact' : 'normal'],
              'flex items-center',
              ColorScheme.text[theme],
              disabled ? Styles.disabled : 'cursor-pointer',
              props.className
            )}
            onClick={() => {
              inputRef.current?.focus();
            }}
          >
            {loading && <Loading.Spinner className="mr-2" variant="sm" />}
            {icon && !loading && (
              <Icon
                className={classNames(label ? 'mr-2' : '')}
                icon={icon}
                theme={theme}
              />
            )}
            <FormLabel label={label} compact={compact} disabled={disabled} />
            <TypeWrapper type={type} value={value} onChange={changeHandler}>
              {(type) => (
                <input
                  spellCheck={spellcheck}
                  id={props.id}
                  onKeyDown={props.onKeyDown}
                  placeholder={props.placeholder}
                  autoFocus={autoFocus}
                  autoComplete={props.autoComplete}
                  type={type === 'number' ? 'text' : type}
                  onFocus={() => setFocused(true)}
                  onBlur={(e) => {
                    setFocused(false);
                    props.onBlur?.(e);
                  }}
                  className={classNames(
                    'flex-grow text-sm border-none bg-transparent focus:outline-none focus:ring-0',
                    label ? 'py-1' : ''
                  )}
                  ref={inputRef}
                  value={String(value) ?? 'NULL'}
                  onChange={(e) => changeHandler(e.target.value, e)}
                />
              )}
            </TypeWrapper>
          </div>
        </NameWrapper>
      );
    }
  );
}
const TypeWrapper: React.FC<{
  type: InputType;
  children: (type: InputType) => React.ReactNode;
  value: string;
  onChange: (value: string, e: React.ChangeEvent<any>) => void;
}> = ({ type, children, value, onChange }) => {
  const [proxiedType, setProxiedType] = useState(type);

  const Helpers = () => {
    if (type === 'number') {
      return (
        <div className="flex items-center">
          <Button
            icon={'minus'}
            inline
            onClick={(e) => {
              e.stopPropagation();
              onChange(String(Number(value) - 1), e);
            }}
          />
          <Button
            icon={'plus'}
            inline
            onClick={(e) => {
              e.stopPropagation();
              onChange(String(Number(value) + 1), e);
            }}
          />
        </div>
      );
    }

    if (type === 'password') {
      return (
        <Button
          icon={proxiedType === 'password' ? 'eye-slash' : 'eye'}
          inline
          onClick={(e) => {
            e.stopPropagation();
            setProxiedType(proxiedType === 'password' ? 'text' : 'password');
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      {children(proxiedType)}
      <Helpers />
    </>
  );
};

export const TextInput = makeInput<string, false>();

export const NumberInput = makeInput<number, false>('number');
