import { Inputs } from '@cotera/client/app/components/forms';
import { Text } from '@cotera/client/app/components/ui';
import { useSubscribe, WatchableViewModel } from '@cotera/client/app/etc';
import { classNames } from '@cotera/client/app/components/utils';

export const ColumnName: React.FC<{
  className?: string;
  vm: WatchableViewModel<{
    columnName: string;
    errors: {
      columnName: string | undefined;
    };
    setColumnName: (v: string) => void;
  }>;
}> = ({ vm, className }) => {
  const errors = useSubscribe(
    vm,
    (s) => s.errors,
    (a, b) => {
      return JSON.stringify(a) === JSON.stringify(b);
    }
  );
  const columnName = useSubscribe(vm, (s) => s.columnName);

  return (
    <div className={classNames('flex flex-col', className)}>
      <Inputs.Text
        className={'mb-4 '}
        icon={errors.columnName ? 'x-circle' : undefined}
        theme={errors.columnName ? 'error' : 'regular'}
        label="Column Name"
        placeholder={
          errors.columnName ? errors.columnName : 'Enter a name for the column'
        }
        value={columnName}
        onChange={(v) => {
          vm.setColumnName(v);
        }}
      />
      {errors.columnName && (
        <Text.Caption theme="error">{errors.columnName}</Text.Caption>
      )}
    </div>
  );
};
