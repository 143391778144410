import React from 'react';

export type JsonViewerProps = {
  json: any;
};

export const JsonViewer: React.FC<JsonViewerProps> = ({ json }) => {
  return (
    <div className="bg-standard-background p-4 rounded border border-dashed border-divider">
      {typeof json === 'object' ? (
        <pre className="whitespace-pre-wrap">
          {JSON.stringify(json, null, 2)}
        </pre>
      ) : (
        json.toLocaleString()
      )}
    </div>
  );
};
