import { AST } from '@cotera/era';
import { CompileMarkup } from './compiler';
import { Block } from '@cotera/client/app/components';
import { useTypedExpressionValue } from './macro-expansion/scopes';
import { z } from 'zod';

export const RenderBlock: React.FC<{
  section: AST._Block;
}> = ({ section }) => {
  const title = useTypedExpressionValue(section.title, z.string().nullable());

  return (
    <Block title={title ?? undefined} border={section.border}>
      {section.sections.map((section, i) => (
        <CompileMarkup key={i} section={section} inBlock={true} />
      ))}
    </Block>
  );
};
