import { useEffect, useRef } from 'react';

// Generic hook that works with any type of prop
export function useOnPropChange<T>(
  prop: T,
  callback: (newProp: T, oldProp: T | undefined) => void
) {
  const prevPropRef = useRef<T | undefined>(undefined);

  useEffect(() => {
    if (prevPropRef.current !== prop) {
      callback(prop, prevPropRef.current);
      prevPropRef.current = prop;
    }
  }, [prop, callback]);
}
