import { Divider } from '@cotera/client/app/components';
import { AST } from '@cotera/era';
import { useTypedExpressionValue } from './macro-expansion/scopes';
import { z } from 'zod';

const nullableStringSchema = z.string().nullable();

export const RenderDivider: React.FC<{
  section: AST._Divider;
}> = ({ section }) => {
  const caption = useTypedExpressionValue(section.text, nullableStringSchema);
  return <Divider caption={caption ?? undefined} className="my-6" />;
};
