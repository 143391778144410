export const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      version="1.1"
      className="w-full h-full animate-pulse"
    >
      <path
        d="M542.72 98.816V445.44h359.936c-23.552-184.832-173.056-329.728-359.936-346.624z"
        fill="#9C9C9C"
      />
      <path
        d="M512 476.16V97.28h-7.68C285.184 97.28 107.52 274.944 107.52 494.08S285.184 890.88 504.32 890.88 901.12 713.216 901.12 494.08c0-6.144 0-11.776-0.512-17.92H512z"
        fill="#D1D1D1"
      />
    </svg>
  );
};
