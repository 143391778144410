import { Constant, Expression, MakeArray, MakeStruct, Ty } from '@cotera/era';
import { FFI_CALL } from './utils';
export { FII_REQUIREMENTS } from './utils';
export type { FiiFuncName } from './utils';
export { isFiiFuncName, isFFI, FFI_FUNC_NAMES } from './utils';

export const uddWrite = (params: {
  entity_name: string | Expression;
  key: string | Expression;
  identifier: number | string | Expression;
  options: {
    value: Ty.Scalar | Expression;
    label: string | Expression;
    color: string | Expression;
  }[];
}) => {
  return FFI_CALL({
    func: '@@cotera-udd-write',
    params: {
      ...params,
      options: MakeArray(
        params.options.map((opt) =>
          MakeStruct({
            color: Expression.wrap(opt.color, { ty: 'string' }),
            label: Expression.wrap(opt.label, { ty: 'string' }),
            value: Expression.wrap(opt.value),
          })
        )
      ),
    },
    jsStackPointer: uddWrite,
  });
};

export const openLink = (params: {
  to: string | Expression;
  label: string | Expression;
  newTab?: boolean | Expression;
}) =>
  FFI_CALL({
    func: '@@cotera-open-link',
    params,
    jsStackPointer: openLink,
  });

export const setCursor = (params: {
  detectedAt: Date | Expression;
  coteraStableId: string | Expression;
  entityId: string | Expression;
  streamId: string | Expression;
  sinkId: string | Expression;
}) =>
  FFI_CALL({
    func: '@@cotera-set-sink-cursor',
    params,
    jsStackPointer: setCursor,
  });

export const pause = (params: {
  entityId: string | Expression;
  streamId: string | Expression;
  sinkId: string | Expression;
}) =>
  FFI_CALL({
    func: '@@cotera-pause-sink',
    params,
    jsStackPointer: pause,
  });

export const snapshot = (params: {
  entityId: string | Expression;
  streamId: string | Expression;
}) =>
  FFI_CALL({
    func: '@@cotera-snapshot',
    params,
    jsStackPointer: snapshot,
  });

export const TOPICS_TAG = '@@cotera-topic-matching';

export const topics = (params: {
  id: string | Expression;
  coteraStableId: string | Expression;
  content: string | Expression;
  entityName: string | Expression;
  topicUddId: string | Expression;
  summaryUddId: string | Expression;
  topicVersionId?: string | Expression;
  topicExtractionPromptId?: string | Expression;
  summaryPromptId?: string | Expression;
}) =>
  FFI_CALL({
    func: TOPICS_TAG,
    params: {
      ...params,
      topicVersionId:
        params.topicVersionId ?? Constant(null, { ty: Ty.ty('string') }),
      topicExtractionPromptId:
        params.topicExtractionPromptId ??
        Constant(null, { ty: Ty.ty('string') }),
      summaryPromptId:
        params.summaryPromptId ?? Constant(null, { ty: Ty.ty('string') }),
    },
    jsStackPointer: topics,
  });
