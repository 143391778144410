import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { ThemeableProps } from '../../types/themeable';

const HINT_CSS = {
  primary: 'bg-indigo-100 text-indigo-800',
  secondary: 'bg-emerald-100 text-emerald-800',
  muted: 'bg-gray-50 text-gray-800',
  regular: 'bg-gray-100 text-gray-800',
  error: 'bg-red-100 text-red-800',
  warning: 'bg-yellow-100 text-yellow-800',
};
export const Hint: React.FC<
  ChildrenProps & { className?: string } & ThemeableProps
> = ({ children, className, theme = 'regular' }) => {
  return (
    <code
      className={classNames(
        'border border-divider p-1 rounded',
        className,
        HINT_CSS[theme]
      )}
    >
      {children}
    </code>
  );
};
