import React from 'react';
import { DateTime } from 'luxon';
import { classNames } from '@cotera/client/app/components/utils';

export const H1: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => {
  return (
    <h1
      className={classNames(
        'text-3xl font-semibold text-standard-text tracking-tighter',
        className
      )}
    >
      {children}
    </h1>
  );
};

export const H2: React.FC<{
  className?: string;
  children: React.ReactNode;
  textColor?: string;
}> = ({ className = '', children, textColor }) => {
  return (
    <h2
      className={classNames(
        'text-2xl font-semibold tracking-tighter',
        className,
        textColor ? textColor : 'text-standard-text'
      )}
    >
      {children}
    </h2>
  );
};

export const H3: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => {
  return (
    <h3
      className={classNames(
        'text-lg font-semibold text-standard-text tracking-tight',
        className
      )}
    >
      {children}
    </h3>
  );
};

export const H4: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => {
  return (
    <h4
      className={classNames(
        'text-md font-semibold text-standard-text tracking-tight',
        className
      )}
    >
      {children}
    </h4>
  );
};

export const DateDisplay: React.FC<{ date: Date; time?: boolean }> = ({
  date,
  time,
}) => {
  if (!time) {
    return <>{DateTime.fromJSDate(date).toUTC().toFormat('yyyy/MM/dd')}</>;
  }
  return <>{DateTime.fromJSDate(date).toUTC().toISO()?.replace('.000', '')}</>;
};
