import { AST } from '../../ast';
import { Constant, Expression, Relation } from '..';
import { Axis, ChartSize } from '../../ast/chart';
import _ from 'lodash';
import { Histogram } from './histogram';
import { BarChartProps, LineChartProps } from './chart';
import { Markup } from './section';

/**
 * @param rel with attributes { x: ['string', 'timestamp', 'float'], y: ['int', 'float', 'string'], category: ['string'] }
 */
export const BarChart = ({
  rel,
  title,
  size,
  axis,
  trendline,
  type = 'stacked',
  format,
  direction = 'vertical',
}: BarChartProps & {
  rel: Relation;
}): Markup => {
  const bc: AST._Chart = {
    t: 'chart',
    rel: rel.ast,
    title: Expression.wrap(title ?? Constant(null, { ty: 'string' })).ast,
    size: size ?? 'md',
    format: format ?? {},
    config: {
      t: 'bar-chart',
      axis: {
        x: {
          label: axis?.x?.label ?? null,
          scale: axis?.x?.scale ?? null,
        },
        y: {
          label: axis?.y?.label ?? null,
          scale: axis?.y?.scale ?? null,
        },
      },
      trendline: trendline ?? false,
      type,
      direction,
    },
  };

  return Markup.fromAst(bc, { jsStackPointer: BarChart });
};

/**
 *
 * @param rel with attributes { target: ['string', 'timestamp', 'float'], group: ['string'] }
 */
export const HistogramChart = ({
  rel,
  title,
  size,
  axis,
  type = 'stacked',
  numBuckets = 10,
  direction = 'vertical',
}: {
  rel: Relation;
  title?: string | Expression;
  size?: ChartSize;
  axis: {
    x: OptionalNullable<Axis>;
    y: OptionalNullable<Axis>;
  };
  type?: 'stacked' | 'grouped';
  numBuckets?: number;
  direction?: 'vertical' | 'horizontal';
}): Markup => {
  const bc: AST._Chart = {
    t: 'chart',
    format: {},
    rel: Histogram(
      rel,
      (r) => ({
        target: r.attr('target'),
        group: r.attr('group'),
      }),
      { numBuckets }
    ).select((t) => ({
      ...t.star(),
      category: t.attr('group'),
    })).ast,
    title: Expression.wrap(title ?? Constant(null, { ty: 'string' })).ast,
    size: size ?? 'md',
    config: {
      t: 'histogram-chart',
      axis: {
        x: {
          label: axis.x?.label ?? null,
          scale: axis.x?.scale ?? null,
        },
        y: {
          label: axis.y?.label ?? null,
          scale: axis.y?.scale ?? null,
        },
      },
      type,
      direction,
    },
  };

  return Markup.fromAst(bc, { jsStackPointer: HistogramChart });
};

/**
 *
 * @param rel with attributes { x: ['string', 'timestamp'], y: ['int', 'float'], category: 'string' }
 */
export const LineChart = ({
  rel,
  title,
  size = 'md',
  axis,
  format,
  type = 'normal',
}: LineChartProps & {
  rel: Relation;
}): Markup => {
  const bc: AST._Chart = {
    t: 'chart',
    rel: rel.ast,
    title: Expression.wrap(title ?? Constant(null, { ty: 'string' })).ast,
    size: size,
    format: format ?? {},
    config: {
      t: 'line-chart',
      axis: {
        x: {
          label: axis?.x?.label ?? null,
          scale: axis?.x?.scale ?? null,
        },
        y: {
          label: axis?.y?.label ?? null,
          scale: axis?.y?.scale ?? null,
        },
      },
      type,
    },
  };

  return Markup.fromAst(bc, { jsStackPointer: LineChart });
};

export const AreaChart = (
  props: Omit<LineChartProps, 'type'> & { rel: Relation }
): Markup => {
  return LineChart({ ...props, type: 'area' });
};

/**
 *
 * @param rel with attributes { title: [string], value: [float], style: [string] }
 */
export const Callout = ({ rels }: { rels: Relation[] }): Markup => {
  const bc: AST._Callout = {
    t: 'callout',
    rels: rels.map(
      (rel) =>
        rel.select((t) => ({
          style: Constant(null, { ty: 'string' }),
          unit: Constant(null, { ty: 'string' }),
          ...t.star(),
        })).ast
    ),
  };

  return Markup.fromAst(bc, { jsStackPointer: Callout });
};

type PickNullable<T> = {
  [P in keyof T as null extends T[P] ? P : never]: T[P];
};

type PickNotNullable<T> = {
  [P in keyof T as null extends T[P] ? never : P]: T[P];
};

type OptionalNullable<T> = {
  [K in keyof PickNullable<T>]?: Exclude<T[K], null>;
} & {
  [K in keyof PickNotNullable<T>]: T[K];
};
