import {
  BarChartForRelation,
  LineChartForRelation,
  PieChartForRelation,
  RadarChartForRelation,
  SankeyChartForRelation,
  HeatmapChartForRelation,
  HistogramChartForRelation,
  ScatterPlotChartForRelation,
  SummaryChartForRelation,
  DataGridForRelation,
} from '@cotera/client/app/components/data-vis';
import { AST, Relation, Constant } from '@cotera/era';
import React from 'react';
import { Assert } from '@cotera/utilities';
import { RenderRelationInfo } from './relation-info';
import {
  useEraScopesAwareRelIR,
  useTypedExpressionValue,
} from './macro-expansion/scopes';
import { z } from 'zod';
import { COLORS } from '@cotera/client/app/components/utils';
import { RenderSemanticSearchResults } from './semantic-search-results';
import { RenderIndexList } from './index-list';
import { LLMSummary } from '@cotera/client/app/components/app';

const SIZE_MAP = {
  lg: 450,
  md: 350,
  sm: 225,
};

type ChartSection = AST.Chart._Chart;

export const RenderChart: React.FC<{
  section: ChartSection;
}> = ({ section }) => {
  const height = SIZE_MAP[section.size];

  const title = useTypedExpressionValue(
    section.title ?? Constant(null, { ty: 'string' }),
    z.string().nullable()
  );

  const chartRelIR = useEraScopesAwareRelIR(section.rel);

  const name = title ?? undefined;

  switch (section.config.t) {
    case 'sankey-chart':
      return (
        <SankeyChartForRelation
          format={section.format ?? undefined}
          rel={Relation.wrap(chartRelIR)}
          name={name}
          chartHeight={height}
        />
      );
    case 'bar-chart': {
      return (
        <BarChartForRelation
          titlePosition="left"
          rel={Relation.wrap(chartRelIR)}
          format={section.format ?? undefined}
          axis={{
            x: {
              scale: section.config.axis.x.scale ?? undefined,
              label: section.config.axis.x.label ?? undefined,
            },
            y: {
              scale: section.config.axis.y.scale ?? undefined,
              label: section.config.axis.y.label ?? undefined,
            },
          }}
          name={name}
          trendline={section.config.trendline}
          chartHeight={height}
          type={section.config.type}
          direction={section.config.direction}
        />
      );
    }
    case 'histogram-chart':
      return (
        <HistogramChartForRelation
          titlePosition="left"
          rel={Relation.wrap(chartRelIR)}
          axis={{
            x: {
              scale: section.config.axis.x.scale ?? undefined,
              label: section.config.axis.x.label ?? undefined,
            },
            y: {
              scale: section.config.axis.y.scale ?? undefined,
              label: section.config.axis.y.label ?? undefined,
            },
          }}
          name={name}
          chartHeight={height}
          type={section.config.type}
          direction={section.config.direction}
        />
      );
    case 'line-chart':
      return (
        <LineChartForRelation
          rel={Relation.wrap(chartRelIR)}
          format={section.format ?? undefined}
          axis={{
            x: {
              scale: section.config.axis.x.scale ?? undefined,
              label: section.config.axis.x.label ?? undefined,
            },
            y: {
              scale: section.config.axis.y.scale ?? undefined,
              label: section.config.axis.y.label ?? undefined,
            },
          }}
          name={name}
          chartHeight={height}
          type={section.config.type}
        />
      );
    case 'heatmap-chart':
      return (
        <HeatmapChartForRelation
          format={section.format ?? undefined}
          rel={Relation.wrap(chartRelIR)}
          axis={{
            x: {
              scale: section.config.axis.x.scale ?? undefined,
              label: section.config.axis.x.label ?? undefined,
            },
            y: {
              scale: section.config.axis.y.scale ?? undefined,
              label: section.config.axis.y.label ?? undefined,
            },
          }}
          name={name}
          chartHeight={height}
          colors={
            section.config.colors.map((x) => ({
              color: x.color,
              shade: Number(x.shade),
            })) as {
              color: keyof (typeof COLORS)[200];
              shade: keyof typeof COLORS;
            }[]
          }
        />
      );
    case 'scatter-plot':
      return (
        <ScatterPlotChartForRelation
          dynamicSizePoints={section.config.dynamicSizePoints}
          numTicks={section.config.numTicks}
          format={section.format ?? undefined}
          rel={Relation.wrap(chartRelIR)}
          axis={{
            x: {
              scale: section.config.axis.x.scale ?? undefined,
              label: section.config.axis.x.label ?? undefined,
            },
            y: {
              scale: section.config.axis.y.scale ?? undefined,
              label: section.config.axis.y.label ?? undefined,
            },
          }}
          name={name}
          chartHeight={height}
        />
      );
    case 'pie-chart':
      return (
        <PieChartForRelation
          format={section.format ?? undefined}
          titlePosition="left"
          rel={Relation.wrap(chartRelIR)}
          name={name}
          chartHeight={height}
          labels={section.config.labels ?? undefined}
        />
      );
    case 'radar-chart':
      return (
        <RadarChartForRelation
          format={section.format ?? undefined}
          titlePosition="left"
          rel={Relation.wrap(chartRelIR)}
          name={name}
          chartHeight={height}
        />
      );
    case 'summary-chart':
      return (
        <SummaryChartForRelation
          format={section.format ?? undefined}
          rel={Relation.wrap(chartRelIR)}
          name={name}
        />
      );
    case 'index-list':
      return <RenderIndexList section={section} />;
    case 'data-grid':
      return (
        <DataGridForRelation
          className="mt-4"
          fromArtifactId={null}
          rel={Relation.wrap(chartRelIR)}
          defaultRowCount={section.config.defaultRowCount}
        />
      );
    case 'semantic-search-results':
      return <RenderSemanticSearchResults section={section} />;
    case 'relation-info':
      return <RenderRelationInfo rel={section.rel} />;
    case 'llm-summary':
      return (
        <LLMSummary
          rel={Relation.wrap(chartRelIR)}
          prompt={section.config.prompt}
          model={section.config.model ?? undefined}
        />
      );
    default:
      return Assert.unreachable(section.config);
  }
};
