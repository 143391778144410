import { Contract, GET, PaginationParamsSchema, POST } from '@cotera/contracts';
import { z } from 'zod';
import { UserSchema } from '../user';
import { TenantedRoleSchema } from '../../ability';

const SuperAdminUserSchema = UserSchema.merge(
  z.object({
    tenantedRoles: z.array(
      z.object({
        orgId: z.string(),
        org: z.object({
          name: z.string(),
        }),
        role: TenantedRoleSchema,
      })
    ),
  })
);

export type SuperAdminUserDef = z.infer<typeof SuperAdminUserSchema>;

export const SuperAdminUsersContract = Contract.new({
  getByEmail: GET({
    params: z.object({ email: z.string().email() }),
    output: SuperAdminUserSchema.nullable(),
    errors: z.never(),
  }),
  getByEmailDomain: GET({
    params: z.object({
      domain: z.string(),
      pagination: PaginationParamsSchema,
    }),
    output: z.array(SuperAdminUserSchema),
    errors: z.never(),
  }),
  all: GET({
    params: z.object({ pagination: PaginationParamsSchema }),
    errors: z.never(),
    output: z.array(SuperAdminUserSchema),
  }),
  addToOrg: POST({
    params: z.object({
      userId: z.string(),
      orgId: z.string(),
      role: TenantedRoleSchema,
    }),
    output: z.object({}),
    errors: z.object({
      errorType: z.enum(['UserNotFound', 'OrgNotFound']),
      message: z.string().optional(),
    }),
  }),
  create: POST({
    params: z.object({
      name: z.string().optional(),
      email: z.string().email(),
      tenantedRoles: z
        .array(
          z.object({
            orgId: z.string(),
            role: TenantedRoleSchema,
          })
        )
        .optional(),
    }),
    output: SuperAdminUserSchema,
    errors: z.object({
      errorType: z.enum(['InvalidEmail', 'OrgNotFound']),
      message: z.string().optional(),
    }),
  }),
});
