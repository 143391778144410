import _ from 'lodash';
import { Ty } from '../ty';
import { Assert, Freeze } from '../utils';
import { narrowestSuperTypeOf } from '../type-checker/implements';
import { PRIMITIVE_TYPE_INFERENCE_RULES } from './primitive-type-inference-rules';
export { validatorForType } from './primitive-type-inference-rules';

const ENUM_INFERENCE_VARIANT_NUMBER = 16;
const ENUM_INFERENCE_VARIANT_MAX_LENGTH = 32;

export const inferType = (
  values: Ty.Scalar[]
): Ty.ExtendedAttributeType | undefined => {
  if (values.length === 0) {
    return undefined;
  }

  if (values.every((n) => n === null)) {
    return undefined;
  }

  const nullable = values.some((val) => val === null);

  if (values.every((val) => typeof val === 'string' || val === null)) {
    const uniqStrs = Object.freeze(
      _.uniq(values.filter((val) => typeof val === 'string')).sort()
    ) as readonly string[];

    if (
      uniqStrs.length > 0 &&
      uniqStrs.length <= ENUM_INFERENCE_VARIANT_NUMBER &&
      uniqStrs.every(
        (variant) => variant.length <= ENUM_INFERENCE_VARIANT_MAX_LENGTH
      )
    ) {
      return { ty: { k: 'enum', t: uniqStrs }, nullable, tags: [] };
    } else {
      return { ty: { k: 'primitive', t: 'string' }, nullable, tags: [] };
    }
  }

  if (
    values.every(
      (val) =>
        typeof val === 'object' &&
        !(val instanceof Date) &&
        !Freeze.isReadonlyArray(val)
    )
  ) {
    const uniqFields = new Set(values.flatMap((val) => Object.keys(val ?? {})));

    const inference = [...uniqFields].map(
      (field) =>
        [
          field,
          inferType(
            values.map((val) =>
              typeof val === 'object' &&
              !(val instanceof Date) &&
              !Freeze.isReadonlyArray(val)
                ? val?.[field] ?? null
                : null
            )
          ) ?? null,
        ] as const
    );

    if (inference.some(([_field, inference]) => inference === null)) {
      return undefined;
    }

    const fields = Object.fromEntries(
      _.compact(
        inference.map(([name, ty]) => (ty === null ? null : [name, ty]))
      )
    );

    return { ty: { k: 'struct', fields }, nullable: false, tags: [] };
  }

  if (isNumberArray(values)) {
    const nums = values.filter((x) => x !== null);
    if (nums.every((num) => Number.isInteger(num))) {
      const start = _.min(nums)!;
      const end = _.max(nums)!;
      return { ty: { k: 'range', start, end, t: 'int' }, nullable, tags: [] };
    }
  }

  for (const [t, validator] of PRIMITIVE_TYPE_INFERENCE_RULES) {
    if (validator.nullable().array().safeParse(values).success) {
      return { ty: { k: 'primitive', t }, nullable, tags: [] };
    }
  }

  if (values.every((val) => Array.isArray(val) || val === null)) {
    const inferred = values
      .filter((val) => val !== null)
      .map((val) => {
        Assert.assert(Array.isArray(val));
        return inferType(val);
      });

    if (inferred.some((inference) => inference === undefined)) {
      return undefined;
    }

    const common = narrowestSuperTypeOf(_.compact(inferred)).unwrapOr(
      undefined
    );
    return common
      ? { nullable, ty: { k: 'array', t: common }, tags: common.tags }
      : common;
  }

  return undefined;
};

const isNumberArray = (x: any[]): x is (number | null)[] =>
  x.every((y) => typeof y === 'number' || y === null);
