import { classNames } from '@cotera/client/app/components/utils';

export const Text: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >
> = ({ className = '', ...props }) => {
  return (
    <p
      {...props}
      className={classNames('text-standard-text leading-4', className)}
    />
  );
};
