import { Assert } from '@cotera/utilities';
import { useParams } from 'react-router-dom';
import {
  Center,
  DisplayError,
  Loading,
  NotFound,
  BottomNav,
} from '@cotera/client/app/components';
import { Layout } from '../../layout';
import { useAppData } from '../../stores/org';
import { CompileMarkup } from './compiler/compiler';
import React, { createContext } from 'react';
import {
  makeStoreContextHook,
  makeStoreProvider,
} from '@cotera/client/app/etc';
import { useManifestApp } from '../../hooks/use-manifest';
import { AST } from '@cotera/era';
import { Crumb } from '@cotera/client/app/components';
import { isActiveUrlPath } from './utils';
import { InstrumentationProvider } from '../../etc';
import { ManifestSkeleton } from '@cotera/api';
import { ICON_MAPPING } from '@cotera/client/app/components/ui';

type AppState = {
  readonly id: string;
  readonly basePath: string;
};

type TabActions = (set: (state: Partial<AppState>) => void) => {};

const AppContext = createContext<AppState>(undefined as any);

const AppProvider = makeStoreProvider<AppState, ReturnType<TabActions>>(
  AppContext
);

export const useApp = makeStoreContextHook<AppState, ReturnType<TabActions>>(
  AppContext
);

const makeCrumbs =
  (appId: string, skeleton: ManifestSkeleton, menuItems: AST._App['menu']) =>
  (pages: Crumb[]): Crumb[] => {
    const makeAppCrumb = (page: Crumb) => ({
      ...page,
      alternatives: Object.entries(skeleton.apps)
        .map(([id, mu]) => ({
          name: mu.title,
          to: `/apps/${id}`,
          capitalize: true,
          icon: ICON_MAPPING[mu.icon ?? 'variable'],
        }))
        .filter((x) => x.name !== page.name),
    });

    const makePageCrumb = (page: Crumb) => ({
      ...page,
      alternatives: menuItems
        .map((x) => ({
          name: x.title,
          to: `/apps/${appId}/${x.slug}`,
          capitalize: true,
        }))
        .filter((x) => x.to !== page.to),
    });

    return pages
      .map((page, i) => {
        if (
          i === pages.length - 1 &&
          page.name === appId &&
          menuItems.length > 0
        ) {
          return [
            makeAppCrumb(page),
            makePageCrumb({
              ...page,
              name: menuItems.find((x) => x.slug === './')?.title ?? '',
            }),
          ];
        }
        if (page.name === appId) {
          return makeAppCrumb(page);
        }
        if (menuItems.map((x) => x.slug).includes(page.name)) {
          return makePageCrumb(page);
        }
        return page;
      })
      .flat();
  };

export const RenderApp: React.FC<{ routePath: string }> = ({ routePath }) => {
  const { appId } = useParams();
  Assert.assert(appId !== undefined);
  const { version } = useAppData((x) => x.skeleton);
  const app = useManifestApp({ version, id: appId });
  const skeleton = useAppData((x) => x.skeleton);

  if (app.isLoading) {
    return (
      <Layout>
        <Center>
          <Loading.Dots />
        </Center>
      </Layout>
    );
  }

  const data = app.data!;

  if (!data.isOk()) {
    return (
      <Layout>
        <DisplayError error={data.error} />
      </Layout>
    );
  }

  if (data.value === null) {
    return (
      <Layout>
        <NotFound resource={`App: "${appId}"`} />
      </Layout>
    );
  }

  const basePath = `${routePath}/${appId}`;

  return (
    <InstrumentationProvider
      metadata={{
        appId,
        appVersion: version,
      }}
    >
      <Layout
        header={{
          breadcrumbs: makeCrumbs(appId, skeleton, data.value.menu ?? []),
        }}
        bottomNav={
          data.value?.menu?.length > 0
            ? () => (
                <>
                  {data.value!.menu.map((item) => {
                    const isActive = isActiveUrlPath(
                      basePath,
                      item.slug,
                      window.location.pathname
                    );
                    return (
                      <BottomNav.Item
                        isActive={isActive}
                        key={item.slug}
                        text={item.title}
                        type="link"
                        to={item.slug}
                        icon={item.icon && ICON_MAPPING[item.icon]}
                      />
                    );
                  })}
                </>
              )
            : undefined
        }
      >
        <AppProvider
          key={appId}
          state={{ id: appId, basePath }}
          actions={() => ({})}
        >
          <CompileMarkup section={data.value} inBlock={false} />
        </AppProvider>
      </Layout>
    </InstrumentationProvider>
  );
};
