import React from 'react';
import { Whoami } from '@cotera/api';
import { sortBy, uniqBy } from 'lodash';
import { Button, Divider } from '@cotera/client/app/components/ui';
import { useWhoami } from '@cotera/client/app/stores/org';
import { List, makePopover } from '@cotera/client/app/components/headless';

export type OrgMenuProps = {
  whoami: Whoami;
  switchOrg: (id: string) => void;
  signOut: () => void;
};

const Popover = makePopover(Button);

export const OrgMenu: React.FC<OrgMenuProps> = ({
  whoami,
  switchOrg,
  signOut,
}) => {
  const appOrg = useWhoami((s) => s.org);
  const orgs = sortBy(
    uniqBy(whoami.orgs, (org) => org.id),
    (org) => org.name
  );

  return (
    <Popover
      as={Button}
      anchor="bottom end"
      inline
      text={appOrg.name}
      theme="regular"
      icon="user-circle"
      panel={{
        view: (
          <List.Ul
            className="px-2 py-2 max-h-[300px] overflow-y-auto"
            selectedIndex={orgs.findIndex((x) => x.id === appOrg.id)}
          >
            {orgs.map((org) => {
              return (
                <List.Li
                  as={Button}
                  inline
                  text={org.name}
                  icon={appOrg.id === org.id ? 'check' : undefined}
                  key={org.id}
                  onClick={() => switchOrg(org.id)}
                />
              );
            })}
            <Divider theme="regular" className="mb-2 mt-2" />
            <List.Li
              as={Button}
              inline
              onClick={signOut}
              text="Sign Out"
              icon="lock-closed"
            />
          </List.Ul>
        ),
      }}
    ></Popover>
  );
};
