import { useEffect } from 'react';

export const Logout: React.FC = () => {
  useEffect(() => {
    void fetch('/sessions/logout', { method: 'POST' }).then(() => {
      window.location.reload();
    });

    return;
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
