import { ExprVar, Expression } from './expression';
import { FluentOrderBy, Relation } from './relation';
import _ from 'lodash';
import { Ty } from '../ty';
import { AST } from '../ast';
import { Parser } from '../parser';
import { GLOBAL_SCOPE } from './macro/macro-base';
import { ExprMacroChildren } from '../ast/base';

/**
 * `GenerateSeries` is a function that generates a series of numbers from
 * `start` to `stop` inclusive.
 * @param start The first number in the series.
 * @param stop The stop number in the series
 * @returns A relation with a single attribue of numbers called `n`.
 */
export const GenerateSeries = (
  start: number | Expression,
  stop: number | Expression
): Relation =>
  Relation.fromAst(
    {
      t: 'generate-series',
      start: Expression.wrap(start).ast,
      stop: Expression.wrap(stop).ast,
    },
    { jsStackPointer: GenerateSeries }
  );

export type RelationLike =
  | AST.FileDescription
  | AST.TableDescription
  | Relation;

/**
 * `UnionAll` is a function that takes a list of relations and returns a new
 * relation that combines the rows of all the relations on matching columns.
 * @param relations A list of relations with identical columns (in name and type) that will be combined.
 * @returns A new relation that contains all the rows from the input relations, including duplicates.
 */
export const UnionAll = (
  relations: RelationLike[],
  opts?: { jsStackPointer?: Function }
): Relation => {
  return relations
    .map((rel) => From(rel))
    .reduce((l, r) =>
      Relation.fromAst(
        {
          t: 'union',
          all: true,
          sources: { left: l.ast, right: r.ast },
        },
        opts
      )
    );
};

export const MakeStruct = (
  fields: Record<string, Ty.Scalar | Expression>
): Expression => {
  const fluentFields = _.mapValues(fields, (field) => Expression.wrap(field));

  return Expression.fromAst({
    t: 'make-struct',
    fields: _.mapValues(fluentFields, (field) => field.ast),
  });
};

export const MakeArray = (
  items: readonly (Ty.Scalar | Expression)[]
): Expression =>
  Expression.fromAst(
    {
      t: 'make-array',
      elements: items.map((item) => Expression.wrap(item).ast),
    },
    { jsStackPointer: MakeArray }
  );

export const Values = (
  rows: readonly Record<string, Ty.Scalar>[],
  typeInferenceOverrides?: {
    [name: string]: Ty.Shorthand;
  },
  opts?: {
    jsStackPointer?: Function;
  }
): Relation => {
  const overrides = _.mapValues(typeInferenceOverrides ?? {}, (override) =>
    typeof override === 'string' ? { ty: override, nullable: false } : override
  );

  const uniqAttributeNames = new Set(rows.flatMap((row) => Object.keys(row)));
  const tys: Record<string, Ty.Shorthand> = _.mapValues(
    typeInferenceOverrides ?? {},
    (override) => Ty.shorthandToTy(override)
  );

  for (const name of uniqAttributeNames) {
    if (!overrides[name]) {
      const values = rows.map((row) => row[name] ?? null);
      const inferred = Parser.inferType(values);

      if (inferred) {
        tys[name] = inferred;
      } else {
        throw new Error(
          `Unable to infer type of attribute "${name}" with values: [${values
            .map((x) => `${x}`)
            .join(', ')}]`
        );
      }
    }
  }

  return Relation.fromAst(
    {
      t: 'values',
      values: rows,
      attributes: _.mapValues(tys, (ty) => Ty.shorthandToTy(ty)),
    },
    { jsStackPointer: opts?.jsStackPointer ?? Values }
  );
};

export const From = (
  relation: RelationLike,
  opts?: { jsStackPointer: Function }
): Relation => {
  if (relation instanceof Relation) {
    return relation;
  }

  if ('uri' in relation) {
    return Relation.fromAst(
      {
        ...relation,
        t: 'file',
        attributes: _.mapValues(relation.attributes, (ty) =>
          Ty.shorthandToTy(ty)
        ),
      },
      opts
    );
  }

  return Relation.fromAst(
    {
      ...relation,
      t: 'table',
      attributes: _.mapValues(relation.attributes, (ty) =>
        Ty.shorthandToTy(ty)
      ),
    },
    opts
  );
};

export const GenUuid = (): Expression => {
  return Expression.fromAst(
    {
      t: 'function-call',
      op: 'gen_random_uuid',
      args: [],
    },
    { jsStackPointer: GenUuid }
  );
};

export function Constant(
  value: Ty.Scalar,
  opts?: { ty?: Ty.Shorthand; impure?: boolean; nullable?: boolean }
): Expression {
  let { ty } = opts ?? {};
  let inferred: Ty.ExtendedAttributeType | null = null;

  if (ty === undefined) {
    // Special case NaN
    if (typeof value === 'number') {
      if (isNaN(value)) {
        return Expression.fromAst(
          { t: 'function-call', op: 'nan', args: [] },
          {}
        );
      }
    }

    inferred = Parser.inferType([value]) ?? null;
    if (!inferred) {
      throw new Error(`Unable to infer type of value ${JSON.stringify(value)}`);
    }

    ty = inferred;
  }

  let eTy = Ty.shorthandToTy(ty);

  if (inferred || typeof ty === 'string') {
    if (value !== null) {
      eTy =
        opts?.nullable ?? false
          ? Ty.makeNullable(eTy)
          : Ty.makeNotNullable(eTy);
    }
  }

  const res = Expression.fromAst(
    { t: 'scalar', ty: eTy, val: value },
    { jsStackPointer: Constant }
  );
  return opts?.impure ? res.functionCall('impure', []) : res;
}

/**
 * `Case` evaluates a list of conditions and returns the result of the first
 * true condition. If no conditions are true, the default value is returned. If
 * `else` is omitted, null is returned.
 * @param cases A list of conditions and their corresponding results. Format:
 * {when: , then: }
 * @param opts.else The default value to return if no conditions are true.
 * @returns An {@link Expression}
 * @example rel.select((t) => ({...t.star(), new_column: Case([{when:
 * t.attr('value').gte(50), then: '˘>= 50'}, {when: t.attr('age').lt(50), then:
 * '<50'}], {else: 'unknown'})
 */
export const Case = (
  cases: {
    readonly when: boolean | Expression;
    readonly then: Ty.Scalar | Expression;
  }[],
  opts?: {
    else?: Ty.Scalar | Expression;
    jsStackPointer?: Function;
  }
): Expression => {
  const casesArray = Array.isArray(cases) ? cases : [cases];
  const casesWrapped = casesArray.map(({ when, then }) => ({
    when: Expression.wrap(when),
    then: Expression.wrap(then),
  }));
  const wrappedElse =
    opts?.else !== undefined ? Expression.wrap(opts.else) : undefined;
  const caseExpr: AST._Case<ExprMacroChildren> = {
    t: 'case',
    cases: casesWrapped.map(({ when, then }) => ({
      when: when.ast,
      then: then.ast,
    })),
    else: wrappedElse ? wrappedElse.ast : undefined,
  };

  return Expression.fromAst(caseExpr, {
    jsStackPointer: opts?.jsStackPointer ?? Case,
  });
};

/**
 * `Desc` is used to specific the descending order of an expression in an `order
 * by` clause.
 * @param expr The {@link Expression} that will be ordered in descending order.
 * @returns An object that represents an ordered {@link Expression}.
 * @example rel.orderBy((t) => Desc(t.attr('age')))
 */
export const Desc = (expr: Ty.Scalar | Expression): FluentOrderBy => ({
  expr: Expression.wrap(expr),
  direction: 'desc',
});

/**
 * `Asc` is used to specific the ascending order of an expression in an `order
 * by` clause.
 * @param expr The {@link Expression} that will be ordered in ascending order.
 * @returns An object that represents an ordered {@link Expression}.
 * @example rel.orderBy((t) => Asc(t.attr('age')))
 */
export const Asc = (expr: Ty.Scalar | Expression): FluentOrderBy => ({
  expr: Expression.wrap(expr),
  direction: 'asc',
});

// Random other stuff

/**
 * `f` is used to identify an f-string. It is used to create a string using
 * expressions.
 * @returns An {@link Expression} that represents a string containing both
 * string and expression components.
 * @example rel.select((t) => ({greeting: f`Hello ${t.attr('name')}`}))
 */
export const f = (
  strings: readonly string[],
  ...keys: readonly (Ty.Scalar | Expression)[]
): Expression => {
  const exprs = _.zip(strings, keys)
    .flatMap(([str = '', expr = '']) => [str, expr])
    .filter((expr) => expr !== '');

  return Expression.fromAst({
    t: 'function-call',
    op: 'format',
    args: exprs.map((expr) => Expression.wrap(expr).ast),
  });
};

/**
 * `Not` is a logical operator that returns true if the expression is false.
 * @param expr A logical {@link Expression}
 * @returns A boolean {@link Expression}
 * @example rel.where((t) => Not(t.attr('is_active')))
 */
export const Not = (expr: boolean | Expression): Expression => {
  return Expression.wrap(expr).functionCall('not', [], {
    jsStackPointer: Not,
  });
};

/**
 * Evaluate if an expression is null.
 * @param expr The expression you want to evaluate.
 * @returns A boolean {@link Expression}.
 * @example
 * ```ts
 * rel.where((t) => IsNull(t.attr('name')))
 * ```
 * There is also a method implementation using `.isNull()`
 * ```ts
 * rel.where((t) => t.attr('name').isNull())
 * ```
 */
export const IsNull = (expr: Expression): Expression => {
  return expr.functionCall('is_null', [], { jsStackPointer: IsNull });
};

const binOp = (
  op: 'eq' | 'neq' | 'lt' | 'gt' | 'gte' | 'lte',
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  const l = Expression.wrap(left);
  const r = Expression.wrap(right);
  return l.functionCall(op, [r], {
    jsStackPointer: opts?.jsStackPointer ?? binOp,
  });
};

/**
 * `Eq` is a logical operator that returns true if the expressions are equal.
 * @param left The first {@link Expression} to be evaluated for equality.
 * @param right The second {@link Expression} to be evaluated for equality.
 * @returns Returns a boolean {@link Expression}
 * @example
 * ```ts
 * rel.where((t) => Eq(t.attr('name'), 'John'))
 * ```
 * There is also a method implementation using `.eq()`
 * ```ts
 * rel.where((t) => t.attr('name').eq('John'))
 * ```
 */
export const Eq = (
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  return binOp('eq', left, right, { jsStackPointer: Eq, ...opts });
};

/**
 * `Neq` is a logical operator that returns true if the expressions are not
 * equal.
 * @param left The first {@link Expression} to be evaluated for inequality.
 * @param right The second {@link Expression} to be evaluated for inequality.
 * @returns Returns a boolean {@link Expression}
 * @example
 * ```ts
 * rel.where((t) => Neq(t.attr('name'), 'John'))
 * ```
 * There is also a method implementation using `.neq()`
 * ```ts
 * rel.where((t) => t.attr('name').neq('John'))
 * ```
 */
export const Neq = (
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  return binOp('neq', left, right, { ...opts });
};

/**
 * `Lt` is a logical operator that returns true if the left expression is less
 * than the right expression.
 * @param left The first {@link Expression} to be evaluated for less than.
 * @param right The {@link Expression} to be evaluated against.
 * @returns Returns a boolean {@link Expression}
 * @example rel.where((t) => Lt(t.attr('age'), 50))
 */
export const Lt = (
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer: Function }
): Expression => {
  return binOp('lt', left, right, opts);
};

/**
 * `Lte` is a logical operator that returns true if the left expression is less
 * than or equal to the right expression.
 * @param left The first {@link Expression} to be evaluated for less than or
 * equal to.
 * @param right The {@link Expression} to be evaluated against.
 * @returns Returns a boolean {@link Expression}
 * @example rel.where((t) => Lte(t.attr('age'), 50))
 */
export const Lte = (
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  return binOp('lte', left, right, opts);
};

/**
 * `Gt` is a logical operator that returns true if the left expression is
 * greater than the right expression.
 * @param left The first {@link Expression} to be evaluated for greater than.
 * @param right The {@link Expression} to be evaluated against.
 * @returns Returns a boolean {@link Expression}
 * @example rel.where((t) => Gt(t.attr('age'), 50))
 */
export const Gt = (
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  return binOp('gt', left, right, { jsStackPointer: Gt, ...opts });
};

/**
 * `Gte` is a logical operator that returns true if the left expression is
 * greater than or equal to the right expression.
 * @param left The first {@link Expression} to be evaluated for greater than or
 * equal to.
 * @param right The {@link Expression} to be evaluated against.
 * @returns Returns a boolean {@link Expression}
 * @example rel.where((t) => Gte(t.attr('age'), 50))
 */
export const Gte = (
  left: Ty.Scalar | Expression,
  right: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  return binOp('gte', left, right, { jsStackPointer: Gte, ...opts });
};

/**
 * `And` is a logical operator that returns true if all expressions are true.
 * @param exprs Boolean expression that is evaluated for each row of the
 * {@link RelationRef}.
 * @returns A boolean {@link Expression}
 */
export const And = (...exprs: (boolean | Expression)[]): Expression => {
  if (exprs.length === 0) {
    throw new Error('At least one expression required');
  }
  return Expression.fromAst(
    {
      t: 'function-call',
      op: 'and',
      args: exprs.map((x) => Expression.wrap(x, { ty: 'boolean' }).ast),
    },
    { jsStackPointer: And }
  );
};

/**
 * `If` evaluates a condition and returns a specified value based on the boolean
 * output. If the condition is true, the `then` value is returned. Otherwise the
 * `else` value is returned. If no `else` value is provided, null is returned.
 * @param expr A boolean expression.
 * @param opts.then: The value to return if the condition is true.
 * @param opts.else: The value to return if the condition is false.
 * @returns An {@link Expression}.
 * @example rel.select((t) => ({...t.star(), new_column:
 * If(t.attr('value').gte(50), {then: '>= 50', else: '<50'})})
 */
export const If = (
  expr: boolean | Expression,
  opts: {
    then: Ty.Scalar | Expression;
    else?: Ty.Scalar | Expression;
  }
): Expression =>
  Case([{ when: expr, then: opts.then }], {
    else: opts.else,
    jsStackPointer: If,
  });

/**
 * `Greatest` is a function that returns the greatest value from a list of
 * expressions.
 * @param exprs List of {@link Expression}s.
 * @returns The greatest value from the list of {@link Expression}s.
 * @example rel.select((t) => ({...t.star(), greatest_value:
 * Greatest(t.attr('value1'), t.attr('value2'))})
 */
export const Greatest = (...exprs: (Ty.Scalar | Expression)[]): Expression => {
  return exprs
    .map((x) => Expression.wrap(x))
    .reduce((curr, next) =>
      Case(
        [{ when: curr.gte(next, { jsStackPointer: Greatest }), then: curr }],
        { else: next, jsStackPointer: Greatest }
      )
    );
};

/**
 * `Least` is a function that returns the least value from a list of
 * expressions.
 * @param exprs List of {@link Expression}s.
 * @returns The least value from the list of {@link Expression}s.
 * @example rel.select((t) => ({...t.star(), least_value:
 * Least(t.attr('value1'), t.attr('value2'))})
 */
export const Least = (...exprs: (Ty.Scalar | Expression)[]): Expression => {
  return exprs
    .map((x) => Expression.wrap(x))
    .reduce((curr, next) =>
      Case([{ when: curr.lte(next, { jsStackPointer: Least }), then: curr }], {
        else: next,
        jsStackPointer: Least,
      })
    );
};

/**
 * Or is a logical operator that returns true if at least one expression is
 * true.
 * @param exprs Boolean {@link Expression} that is evaluated for each row of the
 * {@link RelationRef}.
 * @returns A boolean {@link Expression}
 * @example rel.where((t) => Or(t.attr('is_active'), t.attr('is_new')))
 */
export const Or = (...exprs: (boolean | Expression)[]): Expression => {
  if (exprs.length === 0) {
    throw new Error('At least one expression is required');
  }

  return Expression.fromAst(
    {
      t: 'function-call',
      op: 'or',
      args: exprs.map((x) => Expression.wrap(x, { ty: 'boolean' }).ast),
    },
    { jsStackPointer: And }
  );
};

/**
 * `Coalesce` is a function that returns the first non-null value from a list of
 * expressions.
 * @param exprs List of {@link Expression}s.
 * @returns The first non-null value from the list of {@link Expression}.
 * @example rel.select((t) => ({...t.star(), first_non_null_value:
 * Coalesce([t.attr('value1'), t.attr('value2'), t.attr('value3')])})
 */
export const Coalesce = (exprs: (Ty.Scalar | Expression)[]): Expression => {
  return exprs
    .map((x) => Expression.wrap(x))
    .reduce((curr, next) => curr.coalesce(next));
};

/**
 * `Now` is a function that returns the current timestamp.
 * @returns An expression
 * @example rel.select((t) => ({ ...t.star(), current_time: Now() }))
 */
export const Now = (): Expression =>
  ExprVar.create({
    name: 'now',
    scope: GLOBAL_SCOPE,
    ty: Ty.nn('timestamp'),
    default: Expression.fromAst({
      t: 'function-call',
      op: 'now',
      args: [],
    }),
  });

export const Today = () => Now().dateTrunc('day');
export const Yesterday = () => Today().dateSub('days', 1);

export const Random = (): Expression => {
  return Expression.fromAst(
    {
      t: 'function-call',
      op: 'random',
      args: [],
    },
    {}
  );
};

/**
 * `NullIf` is a function that returns null if the two expressions are equal.
 * @param expr The first {@link Expression}.
 * @param other The second {@link Expression}.
 * @returns The first `Expression` if it is not equal to the second
 * {@link Expression}, otherwise null.
 * @example rel.select((t) => ({...t.star(), new_column:
 * NullIf(t.attr('value1'), t.attr('value2'))})
 */
export const NullIf = (
  expr: Ty.Scalar | Expression,
  other: Ty.Scalar | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => {
  return Expression.wrap(expr).functionCall('null_if', [other], {
    jsStackPointer: opts?.jsStackPointer ?? NullIf,
  });
};

/**
 * `IsNaN` is a function that returns true if the expression is
 * {@link https://en.wikipedia.org/wiki/NaN | NaN}.
 * @param expr The {@link Expression} to be evaluated.
 * @returns A boolean {@link Expression}.
 * @example rel.where((t) => IsNaN(t.attr('value')))
 */
export const IsNaN = (expr: number | Expression): Expression => {
  return Expression.fromAst(
    {
      t: 'function-call',
      op: 'is_nan',
      args: [Expression.wrap(expr).ast],
    },
    {}
  );
};

export const InformationSchema = (params: {
  schemas: string[];
  type: 'columns' | 'tables';
}): Relation =>
  Relation.fromAst(
    { t: 'information-schema', ...params },
    { jsStackPointer: InformationSchema }
  );

/**
 * `Floor` is a function that returns the largest integer less than or equal to
 * the given value.
 * @param expr The {@link Expression} to be evaluated.
 * @returns An {@link Expression}
 * @example rel.select((t) => ({...t.star(), value_floor:
 * Floor(t.attr('value'))})
 */
export const Floor = (
  expr: number | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => Expression.wrap(expr).floor(opts);

/**
 * `Ceil` is a function that returns the smallest integer greater than or equal
 * to the given value
 * @param expr The {@link Expression} to be evaluated.
 * @returns An {@link Expression}
 * @example rel.select((t) => ({...t.star(), value_ceil: Ceil(t.attr('value'))})
 */
export const Ceil = (
  expr: number | Expression,
  opts?: { jsStackPointer?: Function }
): Expression => Expression.wrap(expr).ceil(opts);

export const Ln = (
  expr: number | Expression,
  opts?: { jsStackPointer: Function }
): Expression =>
  Expression.wrap(expr).functionCall('ln', [], {
    jsStackPointer: opts?.jsStackPointer ?? Ln,
  });

export const LogBase2 = (
  expr: number | Expression,
  opts?: { jsStackPointer: Function }
) =>
  Expression.wrap(expr).functionCall('log_2', [], {
    jsStackPointer: opts?.jsStackPointer,
  });

export const LogBase10 = (
  expr: number | Expression,
  opts?: { jsStackPointer: Function }
) =>
  Expression.wrap(expr).functionCall('log_10', [], {
    jsStackPointer: opts?.jsStackPointer,
  });

export const CosineDistance = (
  left: number[] | Expression,
  right: number[] | Expression,
  opts?: { jsStackPointer: Function }
) =>
  Expression.wrap(left).functionCall(
    'cosine_distance',
    [Expression.wrap(right)],
    {
      jsStackPointer: opts?.jsStackPointer,
    }
  );
