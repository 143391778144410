import { Contract, GET, POST } from '@cotera/contracts';
import { Parser } from '@cotera/era';
import z from 'zod';

export const UddContract = Contract.new({
  write: POST({
    params: z.object({
      data: z.array(
        z.object({
          identifier: z.union([z.string(), z.number()]),
          value: Parser.LiteralSchema,
        })
      ),
      key: z.string(),
      entityId: z.string(),
    }),
    errors: z.never(),
    output: z.object({}),
  }),
  pollByIds: GET({
    params: z.object({ ids: z.string().array() }),
    errors: z.never(),
    output: z.object({}),
  }),
  get: GET({
    params: z.object({ entityId: z.string() }),
    errors: z.never(),
    output: z.object({
      udds: z
        .object({
          id: z.string(),
          key: z.string(),
          ty: Parser.ExtendedAttributeTypeSchema,
        })
        .array(),
    }),
  }),
});
