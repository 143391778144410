import { BaseWorkspaceNodeViewModel, NodeTransform } from './base';
import { v4 } from 'uuid';
import {
  BarChartForRelation,
  LineChartForRelation,
  PieChartForRelation,
} from '@cotera/client/app/components/data-vis';
import { useSubscribe } from '@cotera/client/app/etc';
import { ComboBox } from '@cotera/client/app/components/forms';
import { Empty } from '@cotera/client/app/components/app';
import { Relation } from '@cotera/era';

const CHART_MAPPING = {
  bar: BarChartForRelation,
  line: LineChartForRelation,
  pie: PieChartForRelation,
};

type Axis = { x: string | null; y: string | null };

export class ChartViewModel extends BaseWorkspaceNodeViewModel {
  readonly t = 'chart';
  private _axis: Axis;
  private _type: 'bar' | 'line' | 'pie' = 'bar';
  override transforms: NodeTransform[] = [];

  constructor(
    name: string,
    position: { x: number; y: number },
    public override parent: BaseWorkspaceNodeViewModel
  ) {
    super(name, v4(), position);
    const hasX = parent.rel.attributes['x'] !== undefined;
    const hasY = parent.rel.attributes['y'] !== undefined;
    this._axis = {
      x: hasX ? 'x' : null,
      y: hasY ? 'y' : null,
    };
  }

  get baseRel() {
    return this.parent.rel;
  }

  get rel() {
    return this.parent.rel.apply(this.transforms);
  }

  get type() {
    return this._type;
  }

  setType(type: 'bar' | 'line' | 'pie') {
    this._type = type;
    this.notifySubscribers();
  }

  get axis() {
    return this._axis;
  }

  setAxis(fn: (prev: Axis) => Axis) {
    this._axis = fn(this._axis);

    if (this.hasRequiredAttributes) {
      this.transforms = [
        {
          fn: (rel: Relation) => {
            return rel.select((t) => ({
              x: t.attr(this._axis.x!),
              y: t.attr(this._axis.y!),
              category: 'All',
            }));
          },
        },
      ];
    }

    this.notifySubscribers();
  }

  get hasRequiredAttributes() {
    return this._axis.x !== null && this._axis.y !== null;
  }
}

const View: React.FC<{ node: ChartViewModel }> = ({ node }) => {
  const Chart = CHART_MAPPING[node.type];
  const rel = useSubscribe(node, (s) => s.rel);
  const axis = useSubscribe(node, (s) => s.axis);
  const baseRel = useSubscribe(node, (s) => s.baseRel);
  const hasRequiredAttributes = useSubscribe(
    node,
    (s) => s.hasRequiredAttributes
  );

  return (
    <div className="flex w-full h-[350px]">
      <div className="flex flex-col w-1/4 border-r border-divider pr-4">
        <ComboBox.Select
          className="mb-4"
          label="X Axis"
          value={{
            display: axis.x ?? '',
            value: axis.x ?? '',
          }}
          options={Object.keys(baseRel.attributes).map((key) => ({
            display: key,
            value: key,
          }))}
          onChange={(value) => {
            node.setAxis((prev) => {
              return {
                ...prev,
                x: value?.value ?? null,
              };
            });
          }}
        />
        <ComboBox.Select
          label="Y Axis"
          value={{
            display: axis.y ?? '',
            value: axis.y ?? '',
          }}
          options={Object.keys(baseRel.attributes).map((key) => ({
            display: key,
            value: key,
          }))}
          onChange={(value) => {
            node.setAxis((prev) => {
              return {
                ...prev,
                y: value?.value ?? null,
              };
            });
          }}
        />
      </div>
      <div className="flex flex-col w-3/4 justify-center items-center">
        {hasRequiredAttributes ? (
          <Chart
            rel={rel}
            axis={{
              x: {
                scale: 'point',
              },
              y: {},
            }}
          />
        ) : (
          <Empty type="chart" title="This chart is empty" />
        )}
      </div>
    </div>
  );
};

export const ChartNode = {
  ViewModel: ChartViewModel,
  View,
};
