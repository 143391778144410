import { Cell } from '../components/cell';
import { Button, Icon } from '@cotera/client/app/components/ui';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@cotera/client/app/components/headless';
import React from 'react';
import { Expression, Relation } from '@cotera/era';
import { WatchableViewModel } from '@cotera/client/app/etc';
import { NewColumnAction } from './column-action.type';

export type ApplyFn = (props: {
  t: 'eraql' | 'decision-tree';
  value: Expression;
  column: string;
}) => void;

const Header: React.FC<{
  actions: NewColumnAction[];
  vm: WatchableViewModel<{
    rel: Relation;
  }>;
  additionalProps: Record<string, unknown>;
  onApply: ApplyFn;
}> = ({ actions, additionalProps, onApply, vm }) => {
  const [open, setOpen] = React.useState<string | null>(null);

  const ActiveView = actions.find(({ label }) => label === open)?.view;

  return (
    <>
      <div className="flex relative flex-col px-3 w-full h-full pb-2 bg-white -mt-0.5 items-center">
        <Dropdown>
          <DropdownTrigger>
            <Button inline icon="add" text="Add Column" />
          </DropdownTrigger>
          <DropdownContent>
            {actions.map(({ label, icon }) => (
              <DropdownItem
                icon={<Icon icon={icon} />}
                key={label}
                onClick={() => {
                  setOpen(label);
                }}
              >
                {label}
              </DropdownItem>
            ))}
          </DropdownContent>
        </Dropdown>
      </div>
      {ActiveView && (
        <ActiveView
          vm={vm}
          open={true}
          onClose={() => setOpen(null)}
          onApply={onApply}
          additionalProps={additionalProps}
        />
      )}
    </>
  );
};

const Body = () => {
  return <Cell column="cotera-extra-column">&nbsp;</Cell>;
};

export const NewColumn = {
  Header,
  Body,
};
