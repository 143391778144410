import { err } from 'neverthrow';
import { Assert } from '../../../utils';
import { combineMeta } from '../../lex';
import { Call } from '../ast';
import { compileAst } from '../compile';
import { TY_SPECIAL_FORMS } from './ty-special-form';
import { SpecialForm } from './types';
import { EXPR_SPECIAL_FORMS } from './expr-special-forms';
import { sym } from '../../lex/tokens';

const pipeFirst: SpecialForm = ([lhs, call], ctx) => {
  Assert.assert(
    lhs !== undefined && call !== undefined,
    'Artiy already checked'
  );

  const [_lhsAst, lhsMeta] = lhs;
  const [callAst, callMeta] = call;

  switch (callAst.t) {
    case 'call': {
      const newCall: Call = { ...callAst, args: [lhs, ...callAst.args] };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    case 'symbol': {
      const newCall: Call = { t: 'call', op: [callAst, callMeta], args: [lhs] };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    default:
      return err([{ t: 'cant-pipe-into' }, callMeta]);
  }
};

const pipeLast: SpecialForm = ([lhs, call], ctx) => {
  Assert.assert(
    lhs !== undefined && call !== undefined,
    'Artiy already checked'
  );

  const [_lhsAst, lhsMeta] = lhs;
  const [callAst, callMeta] = call;

  switch (callAst.t) {
    case 'call': {
      const newCall: Call = { ...callAst, args: [...callAst.args, lhs] };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    case 'symbol': {
      const newCall: Call = { t: 'call', op: [callAst, callMeta], args: [lhs] };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    default:
      return err([{ t: 'cant-pipe-into' }, callMeta]);
  }
};

const call =
  (op: string): SpecialForm =>
  ([lhs, rhs], ctx) => {
    Assert.assert(
      lhs !== undefined && rhs !== undefined,
      'Artiy already checked'
    );

    const newMeta = combineMeta(lhs[1], rhs[1]);

    const newCall: Call = {
      t: 'call',
      op: [sym(op), newMeta],
      args: [lhs, rhs],
    };
    return compileAst([newCall, newMeta], ctx);
  };

export const SPECIAL_FORMS: Record<
  string,
  [artiy: number | number[] | 'any', fn: SpecialForm]
> = {
  '+': [2, call('add')],
  '-': [2, call('sub')],
  '*': [2, call('mul')],
  '/': [2, call('div')],
  '^': [2, call('to_the_power_of')],
  '>': [2, call('gt')],
  '>=': [2, call('gte')],
  '<': [2, call('lt')],
  '<=': [2, call('lte')],
  '==': [2, call('eq')],
  '!=': [2, call('neq')],
  '??': [2, call('coalesce')],
  '||': [2, call('or')],
  '&&': [2, call('and')],
  '|>': [2, pipeFirst],
  '|>>': [2, pipeLast],
  ...EXPR_SPECIAL_FORMS,
  ...TY_SPECIAL_FORMS,
};
