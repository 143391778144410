import path from 'path';

export const isActiveUrlPath = (
  basePath: string,
  to: string,
  current: string
) => {
  const clean = (url: string) => (url.at(-1) === '/' ? url.slice(0, -1) : url);
  return encodeURI(clean(path.join(basePath, to))) === clean(current);
};
