import { UI, AST, Relation } from '@cotera/era';
import { Overview } from './company-app';
import { CompanyCompetitors, GlassDoorReviews } from './company-list';

export const CompanyDetail = ({
  CompanyReviews,
}: {
  CompanyReviews: Relation;
}): AST._App => {
  return UI.App({
    title: 'Overview',
    icon: 'bar-chart',
    pages: {
      '/:companyName': Overview({
        CompanyReviews,
        CompanyCompetitors,
        GlassDoorReviews,
      }),
    },
    menu: [],
  });
};
