export function getAverages(arrays: number[][]): number[] {
  const result = [];
  for (let i = 0; i < arrays[0]!.length; i++) {
    let sum = 0;
    for (let j = 0; j < arrays.length; j++) {
      sum += arrays[j]?.[i] ?? 0;
    }
    result.push(sum / arrays.length);
  }
  return result;
}
