import {
  Org,
  Whoami,
  CoteraClient,
  DevServerClient,
  CoteraAbilities,
} from '@cotera/api';
import {
  makeStoreProvider,
  makeStoreContextHook,
} from '@cotera/client/app/etc/store';
import { createContext } from 'react';
import { CoteraDuckDB } from '../etc/duckdb';
import { ManifestSkeleton } from '@cotera/api';

type AppDataState = {
  readonly skeleton: ManifestSkeleton;
  readonly initedDb: Promise<CoteraDuckDB>;
};

type Actions = (set: (state: Partial<AppDataState>) => void) => {};

const AppDataContext = createContext<AppDataState>(undefined as any);

export const AppDataProvider = makeStoreProvider<
  AppDataState,
  ReturnType<Actions>
>(AppDataContext);

export const useAppData = makeStoreContextHook<
  AppDataState,
  ReturnType<Actions>
>(AppDataContext);

export type WhoamiState = {
  readonly whoami: Whoami;
  readonly org: Org;
  readonly abilities: CoteraAbilities;
  readonly api: CoteraClient;
  readonly localDevServer: DevServerClient;
};

export type WhoamiActions = (set: (state: Partial<AppDataState>) => void) => {
  changeOrg: (orgId: string) => void;
};

const WhoamiContext = createContext<WhoamiState>(undefined as any);

export const WhoamiProvider = makeStoreProvider<
  WhoamiState,
  ReturnType<WhoamiActions>
>(WhoamiContext);

export const useWhoami = makeStoreContextHook<
  WhoamiState,
  ReturnType<WhoamiActions>
>(WhoamiContext);

export const useUntenantedClient = () => useWhoami((state) => state.api.u);
export const useTenantedClient = () => useWhoami((state) => state.api.t);
