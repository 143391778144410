import { Link, useParams } from 'react-router-dom';
import { useEntity } from '@cotera/client/app/hooks/entities';
import { useTopicVersions } from '@cotera/client/app/hooks/topics';
import { Layout } from '@cotera/client/app/layout';
import {
  Badge,
  Divider,
  Loading,
  Section,
  Title,
} from '@cotera/client/app/components/ui';
import React, { Suspense, useState } from 'react';
import Sticky from 'react-sticky-el';
import { Card, List } from '@cotera/client/app/components/headless';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { Inputs } from '@cotera/client/app/components/forms';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { AddNewVersion, PromoteButton } from './components';
import { Empty } from '@cotera/client/app/components/app';

export const ListTopicsPage = () => {
  const { entityName, uddId } = useParams() as {
    entityName: string;
    uddId: string;
  };

  return (
    <Layout>
      <Section direction="vertical" className="w-full">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex justify-between w-2/3 mb-4 items-center">
            <Title title="Manage Topics" type="title" />
          </div>
          <Suspense fallback={<Loading.Dots />}>
            <View entityName={entityName} uddId={uddId} />
          </Suspense>
        </div>
      </Section>
    </Layout>
  );
};

const View: React.FC<{
  entityName: string;
  uddId: string;
}> = ({ entityName, uddId }) => {
  const entity = useEntity({ entityName });
  const { data } = useTopicVersions(uddId);

  const [searchValue, setSearchValue] = useState('');
  const search = useFuzzySearch(data ?? [], ['version']);
  const results = searchValue.length > 0 ? search(searchValue) : data;

  return (
    <div className="flex flex-col w-2/3">
      <Sticky
        mode="top"
        wrapperClassName="z-10 relative"
        scrollElement={'.main'}
        stickyClassName="bg-white z-10 border-b-2 border-divider pt-4"
      >
        <Inputs.Text
          autoFocus
          icon="search"
          value={searchValue}
          onChange={setSearchValue}
          className="mb-4"
        />
      </Sticky>
      <Divider className="mb-4" />
      <Card.Container>
        {results.length > 0 ? (
          <List.Ul className="pl-2 pr-4 py-2" hasFocus={true}>
            {results.map((item, i) => {
              return (
                <List.Li
                  as={Link}
                  key={i}
                  to={`${item.version}`}
                  className={classNames(
                    'data-[focus]:text-indigo-300 transition-colors rounded cursor-pointer px-2 py-2 flex [&:not(:last-child)]:border-b boder-divder [&:not(:last-child)]:mb-2',
                    ColorScheme.text.regular
                  )}
                >
                  <li className="flex flex w-full items-center  justify-between">
                    <span className="text-sm">{item.version}</span>
                    <div className="flex items-center">
                      {item.published ? (
                        <Badge className="mr-2" theme="primary">
                          Published
                        </Badge>
                      ) : (
                        <PromoteButton
                          small
                          versionId={item.id}
                          uddId={uddId}
                        />
                      )}
                      <AddNewVersion
                        sourceVersionId={item.id}
                        uddId={uddId}
                        entityName={entity.name}
                        numVersions={results.length}
                      />
                    </div>
                  </li>
                </List.Li>
              );
            })}
          </List.Ul>
        ) : (
          <div className="flex flex-col items-center justify-center h-fit my-8">
            <Empty
              type="list"
              title="No topic versions found"
              cta={
                <AddNewVersion
                  uddId={uddId}
                  entityName={entity.name}
                  type="add"
                  display="full"
                  numVersions={results.length}
                />
              }
            />
          </div>
        )}
      </Card.Container>
    </div>
  );
};
