import { classNames } from '@cotera/client/app/components/utils';

export const TabSelector: React.FC<{
  options: readonly string[];
  active: string;
  set: (x: string) => void;
}> = ({ options, active: selected, set }) => {
  const active = selected || options[0];
  return (
    <div className="w-full border-divider border rounded py-1.5 px-2 bg-white">
      <nav
        className="overflow-x-scroll no-scrollbar flex space-x-2"
        aria-label="tabs"
      >
        {options.map((opt) => (
          <TabButton
            key={opt}
            name={opt}
            active={opt === active}
            onClick={() => set(opt)}
          />
        ))}
      </nav>
    </div>
  );
};

const TabButton = ({
  name,
  active,
  onClick,
}: {
  name: string;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      type="button"
      key={name}
      onClick={onClick}
      className={classNames(
        active
          ? 'bg-accent-background text-alt-text shadow-sm'
          : 'border-transparent text-muted-text hover:text-standard-text hover:bg-standard-background',
        'whitespace-nowrap px-4 text-xs font-medium py-1.5 rounded'
      )}
    >
      {name}
    </button>
  );
};
