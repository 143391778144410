import _ from 'lodash';
import hash from 'object-hash';
import { AST } from '../../ast';
import { Relation } from '../relation';
import { Expression } from '../expression';
import { Assert } from '@cotera/utilities';
import { MacroArgs, MacroBody, mkMacroVars } from './macro-base';

export type RelMacro<Args extends MacroArgs> = {
  readonly call: MacroBody<Args, Relation>;
  readonly body: Relation;
  readonly scope: string;
};

export const mkRelMacro = <Args extends MacroArgs>(
  args: Args,
  body: (args: Args) => Relation,
  opts?: { readonly displayName?: string }
): RelMacro<Args> => {
  const scope = `${opts?.displayName ? `${opts.displayName}-` : ''}${hash({
    args,
    body: body.toString(),
    opts: opts ?? {},
  })}`;

  const mBody = body(mkMacroVars(scope, args));

  const callback: MacroBody<Args, Relation> = (params) => {
    const rels: Record<string, AST.RelFR> = _.chain(params)
      .entries()
      .filter(([_name, def]) => def instanceof Relation)
      .map(([name, _def]) => {
        const val = params[name];
        Assert.assert(val instanceof Relation);
        return [name, val.ast];
      })
      .fromPairs()
      .value();

    const exprs: Record<string, AST.ExprFR> = _.chain(params)
      .entries()
      .filter(([_name, def]) => !(def instanceof Relation))
      .map(([name, _def]) => {
        const val = params[name];
        Assert.assert(val !== undefined && !(val instanceof Relation));
        return [name, Expression.wrap(val).ast];
      })
      .fromPairs()
      .value();

    return Relation.fromAst(
      {
        t: 'macro-apply-vars-to-rel',
        scope,
        vars: { rels, exprs },
        sources: { from: mBody.ast },
        displayName: opts?.displayName ?? null,
      },
      { jsStackPointer: callback }
    );
  };

  return { scope, body: mBody, call: callback };
};

export const relIf = (
  condition: boolean | Expression,
  branches: {
    then: Relation;
    else: Relation;
  }
): Relation =>
  relCase([{ when: Expression.wrap(condition), then: branches.then }], {
    else: branches.else,
  });

export const relCase = (
  cases: readonly {
    readonly when: boolean | Expression;
    readonly then: Relation;
  }[],
  opts: { readonly else: Relation }
): Relation =>
  Relation.fromAst({
    t: 'macro-rel-case',
    cases: cases.map(({ when, then }) => ({
      when: Expression.wrap(when).ast,
      then: then.ast,
    })),
    else: opts.else.ast,
  });
