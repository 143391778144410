import { Dictionary, groupBy, sortBy } from 'lodash';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { ResponsiveLine } from '@nivo/line';
import {
  Axis,
  THEME,
  getTickLegendSize,
  isDateAxis,
  makeTickValues,
  scaleToFormat,
} from '../utils';
import { Loading } from './loading';
import { ChartContext, makeChartContextHook } from '../context';
import { ChartProps } from '../types';
import { EmptyChart } from '../empty';
import { Tooltip } from '../tooltip';
import { If, Text } from '@cotera/client/app/components/ui';
import {
  GRAY_200,
  isTimeSeriesFormat,
  makeValueFormatter,
  UTCDate,
} from '@cotera/client/app/components/utils';

type Datum = {
  x: string | number | Date;
  y: number;
  category: string;
};

export type Props = {
  type?: 'stacked' | 'normal' | 'area';
  loading?: boolean;
  axis: {
    x?: Axis;
    y?: Axis;
  };
} & ChartProps<Datum>;

const makeScale = (scale: Axis['scale']) => {
  if (!scale || scale === 'point') {
    return {
      type: 'point',
      min: 'auto',
    } as const;
  }
  if (scale === 'linear') {
    return {
      type: 'linear',
      max: 'auto',
      min: 'auto',
    } as const;
  }
  if (isTimeSeriesFormat({ unit: scale })) {
    const tzOffset = new Date().getTimezoneOffset();
    return {
      type: 'time',
      format: tzOffset === 0 ? 'native' : '%y-%m-%d-%H-%M-%S',
      precision: scale,
      useUTC: tzOffset === 0,
      min: 'auto',
    } as const;
  }

  return {
    type: 'point',
  } as const;
};

const useTypedChartContext = makeChartContextHook<Datum>();

export const LineChart: React.FC<Props> = ({
  data,
  type,
  axis,
  loading = false,
  format,
  menuItems = [],
  menuItemHandlers = {},
  ...chartLayout
}) => {
  return (
    <ChartContext data={data} labelKeys={['category']}>
      <ChartLayout
        {...chartLayout}
        labels={{
          left: axis.y?.label,
          bottom: axis.x?.label,
        }}
        menuItems={[...DEFAULT_MENU_ITEMS, ...menuItems]}
        menuItemHandlers={{ ...menuItemHandlers }}
      >
        <If condition={loading}>
          <Loading />
        </If>
        <If condition={data.length === 0 && !loading}>
          <EmptyChart />
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart type={type} axis={axis} format={format} />
        </If>
      </ChartLayout>
    </ChartContext>
  );
};

const Chart: React.FC<{
  format?: Props['format'];
  axis: Props['axis'];
  type?: 'stacked' | 'normal' | 'area';
}> = ({ axis, type: inputType = 'normal', format }) => {
  const type = inputType === 'area' ? 'stacked' : inputType;
  const areaProps =
    inputType === 'area'
      ? {
          enableArea: true,
          areaOpacity: 1,
        }
      : {};
  const xFormatter = makeValueFormatter(
    format?.['x'] ?? scaleToFormat(axis.x?.scale)
  );
  const yFormatter = makeValueFormatter(
    format?.['y'] ?? scaleToFormat(axis.y?.scale ?? 'linear')
  );
  const data = useTypedChartContext((s) => s.data);
  const labels = useTypedChartContext((s) => s.labels);

  const chartData = transform(
    groupBy(data, (x) => x.category),
    axis
  );

  const bottomMargin = getTickLegendSize(
    chartData.map((x) => x.data.map((y) => xFormatter(y.x))).flat(),
    20
  );
  const leftMargin = getTickLegendSize(
    chartData.map((x) => x.data.map((y) => yFormatter(y.y))).flat(),
    20
  );

  return (
    <ResponsiveLine
      theme={THEME}
      animate
      curve="monotoneX"
      data={chartData}
      pointBorderColor={{
        from: 'color',
        modifiers: [['darker', 0.3]],
      }}
      pointBorderWidth={1}
      pointSize={inputType === 'area' ? 0 : 5}
      useMesh
      enableArea={inputType === 'area'}
      enableSlices={'x'}
      colors={(x) => labels.find((y) => y.label === x.id)?.color ?? GRAY_200}
      margin={{
        bottom: bottomMargin,
        left: leftMargin,
        right: 30,
        top: 20,
      }}
      sliceTooltip={({ slice }) => {
        const x = slice.points[0]?.data.x;
        return (
          <Tooltip.Container>
            <div className="flex flex-col">
              <Text.Caption className="mb-2">{xFormatter(x)}</Text.Caption>
              {slice.points.map((point) => {
                return (
                  <Tooltip.Item key={point.id}>
                    <Tooltip.Dot color={point.color} />
                    <strong>{point.serieId}</strong>: {yFormatter(point.data.y)}
                  </Tooltip.Item>
                );
              })}
            </div>
          </Tooltip.Container>
        );
      }}
      xScale={makeScale(axis.x?.scale)}
      yScale={{
        stacked: type === 'stacked',
        type: 'linear',
        min: inputType === 'area' ? 0 : Math.min(...data.map((x) => x.y)) - 1,
        max: 'auto',
      }}
      crosshairType="cross"
      axisBottom={{
        format: (v) => xFormatter(v, 20),
        tickValues: makeTickValues(format?.['x'] ?? axis.x?.scale),
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 60,
      }}
      axisLeft={{
        format: yFormatter,
      }}
      {...areaProps}
    />
  );
};

type TransformedDatum = {
  id: string;
  data: {
    x: string | number | Date;
    y: number;
  }[];
};

const sortData = (data: Datum[], axis: Props['axis']) => {
  if (isDateAxis(axis.x)) {
    return sortBy(data, (x) => x.x);
  }

  if (axis.x?.scale === 'linear') {
    return sortBy(data, (x) => Number(x.x));
  }

  return data;
};

const transformX = (x: Datum['x'], axis: Props['axis']): Datum['x'] => {
  if (isDateAxis(axis.x)) {
    return new UTCDate(x);
  }

  if (axis.x?.scale === 'linear') {
    return Number(x);
  }

  return x;
};

const transformDatum = (
  datum: Datum,
  axis: Props['axis']
): { x: Datum['x']; y: Datum['y'] } => {
  return {
    x: transformX(datum.x, axis),
    y: datum.y,
  };
};

const transform = (
  data: Dictionary<Datum[]>,
  axis: Props['axis']
): TransformedDatum[] => {
  return Object.entries(data).map(([key, value]) => ({
    id: key,
    data: sortData(value, axis).map((x) => transformDatum(x, axis)),
  }));
};
