import { Layout } from '../layout';
import { useUntenantedClient } from '../stores/org';
import { useSuspenseQuery } from '@tanstack/react-query';
import { SuperAdmin } from '../components/app';
import { Org } from '@cotera/api';
import { Assert } from '@cotera/utilities';

export const SuperAdminView: React.FC = () => {
  const client = useUntenantedClient();

  const { data: orgs } = useSuspenseQuery({
    queryFn: async (): Promise<Org[]> => {
      const res = await client.superAdmin.orgs.all({
        pagination: { page: 1, perPage: 100 },
      });

      return Assert.assertOk(res);
    },
    queryKey: ['superAdmin', 'orgs'],
  });

  return (
    <Layout>
      <SuperAdmin client={client} orgs={orgs} />
    </Layout>
  );
};
