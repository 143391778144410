import { create } from 'zustand';
import type { DetailPageLinks } from './types';

type DataGridState = {
  detail: {
    open: boolean;
    name?: string;
    value?: unknown;
    links?: DetailPageLinks;
  };
};

type DataGridActions = {
  openDetail: (params: {
    name: string;
    value: unknown;
    links: DetailPageLinks;
  }) => void;
  closeDetail: () => void;
};

export const useDataGridStore = create<DataGridState & DataGridActions>(
  (set) => ({
    detail: { open: false },
    openDetail: ({ name, value, links }) =>
      set(() => ({ detail: { open: true, name, value, links } })),
    closeDetail: () =>
      set((state) => ({ detail: { ...state.detail, open: false } })),
  })
);
