import { Case, Constant, RelationRef, Ty, f } from '@cotera/era';
import {
  UserDefinedDimensions,
  EventStream,
  LEGACY_GLOBAL_ID,
} from '@cotera/sdk/core';
import { AMAZON_REVIEWS } from '../../assumptions';

export const TAXONOMY = [
  'QUALITY',
  'PACKAGING',
  'DELIVERY',
  'PRODUCT_CHANGES',
  'EASE_OF_USE',
  'CUSTOMER_SERVICE',
  'PRICE',
  'VALUE_FOR_MONEY',
  'SUSTAINABILITY',
  'HEALTH_CONCERNS',
  'DURABILITY',
  'UNIQUENESS',
] as const;

const DESCRIPTIONS = {
  QUALITY:
    "The quality of the product, for example whether it's well made, or whether it did the task it was meant to do.",
  PACKAGING:
    'The packaging of the product - did it product the product? Was it designed well and easy to open?',
  DELIVERY:
    'Delivery - did it come on time? Was it cheap? Was the product damaged during delivery?',
  PRODUCT_CHANGES:
    'Recent changes to the product - has it improved or got worse?',
  EASE_OF_USE: 'How easy is the product to use?',
  CUSTOMER_SERVICE:
    'Was customer service helpful, responsive and courteous? Or slow and unhelpful.',
  PRICE: 'Was the product cheap or expensive?',
  VALUE_FOR_MONEY: 'Was the product worth the money paid for it?',
  SUSTAINABILITY:
    'Environmental concerns around the product - is it friendly to the Earth?',
  HEALTH_CONCERNS:
    'Health concerns around the product. Did it irritate skin? Make someone sick?',
  DURABILITY:
    'Whether the product is long lasting or whether it wore out or broke quickly.',
  UNIQUENESS:
    'Is the product unique or are there lots of alternatives? Does it stand out in some way?',
};

const PREDICTED_TAGS = 'PREDICTED_TAGS';

export const TAXONOMY_OUTPUT_TY = Ty.s(
  Object.fromEntries(TAXONOMY.map((category) => [category, 'int'] as const))
);

export const ReviewsUdd = new UserDefinedDimensions({
  id: LEGACY_GLOBAL_ID,
  attributes: {
    [PREDICTED_TAGS]: TAXONOMY_OUTPUT_TY,
  },
});

const reviews = AMAZON_REVIEWS.select((t) => ({
  ...t.except('ID'),
  id: t.sql`"ID"`,
  prompt: f`${t.attr('REVIEW_TITLE')}. ${t.attr('REVIEW').cast('string')}`,
  CREATED_AT: t.attr('REVIEW_DATE'),
}));

const mapTag = (t: RelationRef, attr: string) => {
  return [
    attr,
    Case([
      { when: t.attr(PREDICTED_TAGS).getField(attr).eq(1), then: 'Positive' },
      { when: t.attr(PREDICTED_TAGS).getField(attr).eq(-1), then: 'Negative' },
      { when: t.attr(PREDICTED_TAGS).getField(attr).eq(0), then: 'Neutral' },
    ]),
  ];
};

export const Reviews = ReviewsUdd.join(reviews, {
  identifier: (t) => t.sql`"id"`,
})
  .select((t) => ({
    ...t.star(),
    ...Object.fromEntries(TAXONOMY.map((col) => mapTag(t, col))),
    SOURCE: Constant('Amazon'),
  }))
  .assertCacheable();

const SYSTEM_MESSAGE = `You are an AI designed to perform sentiment analysis for tags on customer feedback for e-commerce businesses.

The set of tags you will analyse sentiment for is:
${TAXONOMY.map((tag) => `  * ${tag}`).join('\n')}

You should use the determine_sentiment_for_tags function to accomplish this. All parameters are required, and for each one you will provide a -1 if the tag is mentioned with negative sentiment, 1 if it is mentioned with positive sentiment, and 0 if it is not mentioned at all.

If the tag appears in the feedback, you should never give 0. If it's not clear whether the tag is referenced or not, you should go with a 0.`;

export const ReviewsTagStream = EventStream({
  name: PREDICTED_TAGS,
  entityId: LEGACY_GLOBAL_ID,
  identifier: (t) => t.sql`"id"`,
  rel: Reviews,
  onChange: (t) => t.sql`"prompt"`,
  sinks: {
    tagReviews: {
      condition: (_t) => true,
      select: (t) => t.pick('id', 'prompt'),
      config: {
        t: 'llmToolUse' as const,
        config: {
          model: 'gpt-3.5-turbo-1106',
          messages: [
            {
              role: 'system',
              content: SYSTEM_MESSAGE,
            },
            {
              role: 'user',
              content:
                'The product was expensive but worth it. Delivery was on time.',
            },
            {
              role: 'assistant',
              content: null,
              tool_calls: [
                {
                  id: 'call_1',
                  type: 'function',
                  function: {
                    name: 'determine_sentiment_for_tags',
                    arguments: JSON.stringify({
                      QUALITY: 1,
                      PACKAGING: 0,
                      DELIVERY: 1,
                      PRODUCT_CHANGES: 0,
                      EASE_OF_USE: 0,
                      CUSTOMER_SERVICE: 0,
                      PRICE: -1,
                      VALUE_FOR_MONEY: 1,
                      SUSTAINABILITY: 0,
                      HEALTH_CONCERNS: 0,
                      DURABILITY: 0,
                      UNIQUENESS: 0,
                    }),
                  },
                },
              ],
            },
            {
              role: 'tool',
              tool_call_id: 'call_1',
              content: 'ok',
            },
            {
              role: 'user',
              content:
                'The product did a great job and was easy to use, but customer support were not helpful at all.',
            },
            {
              role: 'assistant',
              content: null,
              tool_calls: [
                {
                  id: 'call_2',
                  type: 'function',
                  function: {
                    name: 'determine_sentiment_for_tags',
                    arguments: JSON.stringify({
                      QUALITY: 1,
                      PACKAGING: 0,
                      DELIVERY: 1,
                      PRODUCT_CHANGES: 0,
                      EASE_OF_USE: 1,
                      CUSTOMER_SERVICE: -1,
                      PRICE: 0,
                      VALUE_FOR_MONEY: 0,
                      SUSTAINABILITY: 0,
                      HEALTH_CONCERNS: 0,
                      DURABILITY: 0,
                      UNIQUENESS: 0,
                    }),
                  },
                },
              ],
            },
            {
              role: 'tool',
              tool_call_id: 'call_2',
              content: 'ok',
            },
          ],
          expectedType: TAXONOMY_OUTPUT_TY,
          tools: [
            {
              type: 'function' as const,
              function: {
                name: 'determine_sentiment_for_tags',
                description: `Analyze feedback from a customer and determine which tags are being discussed and whether the sentiment is positive or negative, or whether the tag is not mentioned at all.

PAY CLOSE ATTENTION TO THIS BIT. All paramters are required, these are the cases for you to obey for each parameter:

- Positive mention of the tag: return 1
- Negative mention of the tag: return -1
- No mention of the tag: return 0

The ONLY values allowed for all paramters are 1, 0 and -1. Strings are forbidden, and you MUST NOT use strings like "nan" in place of a number 1, 0 or -1.`,
                parameters: {
                  type: 'object',
                  required: TAXONOMY as unknown as string[],
                  properties: {
                    ...Object.fromEntries(
                      TAXONOMY.map((category) => [
                        category,
                        {
                          type: 'number',
                          description: DESCRIPTIONS[category],
                          enum: [1, 0, -1],
                        },
                      ])
                    ),
                  },
                },
              },
            },
          ],
        },
      },
    },
  },
});
