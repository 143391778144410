import { Err, Ok, Result } from 'neverthrow';
import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from 'react';

import { useMemo } from 'react';
import { v4 } from 'uuid';
import { areResultsEqual } from '../utils';

export type WatchableViewModel<Props> = {
  subscribe(callback: () => void): () => void;
  notifySubscribers(): void;
  readonly subscribers: Record<string, () => void>;
  isEqual(other: any): boolean;
} & Props;

export abstract class Watchable implements WatchableViewModel<any> {
  readonly subscribers: Record<string, () => void> = {};

  subscribe(callback: () => void) {
    const id = v4();
    this.subscribers[id] = callback;

    return () => {
      delete this.subscribers[id];
    };
  }

  notifySubscribers() {
    Object.values(this.subscribers).forEach((callback) => callback());
  }

  isEqual(other: any): boolean {
    return this === other;
  }
}

export function useViewModel<T extends Watchable>(viewModel: T): T {
  const previousViewModel = useRef<T>(viewModel);

  return useMemo(() => {
    // If the current view model is not equal to the previous one, update the memoized value
    if (!previousViewModel.current.isEqual(viewModel)) {
      previousViewModel.current = viewModel;
    }

    return previousViewModel.current;
  }, [viewModel]);
}

// Context definition
const StateContext = createContext<any>(null);

export function ViewModelProvider<T extends Watchable>({
  children,
  model: initialState,
}: {
  children: ReactNode;
  model: T;
}) {
  const stateController = useViewModel(initialState); // Proxy the state

  return (
    <StateContext.Provider value={stateController}>
      {children}
    </StateContext.Provider>
  );
}

// Custom hook to access the context (unchanging reference)
export function useViewModelContext<T>() {
  const context = useContext<T>(StateContext);
  if (!context) {
    throw new Error('useStateContext must be used within a StateProvider');
  }
  return context;
}

function isResult<T, E>(value: any): value is Result<T, E> {
  return (
    (value instanceof Ok || value instanceof Err) &&
    typeof value.isOk === 'function' &&
    typeof value.isErr === 'function'
  );
}

export function useSubscribe<T extends WatchableViewModel<P>, P>(
  stateController: T,
  selector: (state: T) => P,
  equalityFn: (a: P, b: P) => boolean = (a, b) => {
    // Check if both values are arrays and have the same length
    if (Array.isArray(a) && Array.isArray(b)) {
      return (
        a.length === b.length && a.every((item, index) => item === b[index])
      );
    }
    if (isResult(a) && isResult(b)) {
      return areResultsEqual(a, b);
    }
    return a === b; // Fallback for non-array values or non-result values
  }
): P {
  const [, forceUpdate] = useState(0);
  const selectedValue = useRef<P>(selector(stateController)); // Store selected property value

  useEffect(() => {
    const checkForUpdates = () => {
      const newValue = selector(stateController);

      // Check if the current value has an isEqual method
      const hasCustomIsEqual =
        newValue && typeof (newValue as any).isEqual === 'function';

      // Use the custom isEqual method if it exists
      const valuesAreEqual = hasCustomIsEqual
        ? (newValue as any).isEqual(selectedValue.current)
        : equalityFn(newValue, selectedValue.current); // Fallback to lodash isEqual

      if (!valuesAreEqual) {
        selectedValue.current = newValue;
        forceUpdate((x) => x + 1);
      }
    };

    // Subscribe to state changes
    const removeSubscriber = stateController.subscribe(() => {
      checkForUpdates();
    });

    // Initial check on mount
    checkForUpdates();

    return () => {
      removeSubscriber();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateController, selector]);

  return selectedValue.current;
}

export const vm = {
  ViewModelProvider,
  useViewModel,
  useSubscribe,
  useViewModelContext,
};
