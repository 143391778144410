import { TenantedClient } from '@cotera/api';
import { StateSetter } from '@cotera/client/app/etc';
import { toast } from '@cotera/client/app/components/ui';

type Topic = {
  id: string;
  name: string;
  description: string | null;
  features: { featureId: string; content: string }[];
  action?: 'upsert' | 'delete';
};

type Feature = { featureId: string; content: string; ignored: boolean };

type State = {
  synced: 'synced' | 'unsynced' | 'syncing';
  topics: Topic[];
  features: Feature[];
};

function getChangedItems<T>(
  oldItems: T[],
  newItems: T[],
  findById: (item: T) => string,
  hasChanged: (oldItem: T, newItem: T) => boolean
): T[] {
  const changedItems: T[] = [];

  const findItemById = (id: string, items: T[]) =>
    items.find((item) => findById(item) === id);

  newItems.forEach((newItem) => {
    const oldItem = findItemById(findById(newItem), oldItems);

    // Check if the item is missing in oldItems or has changed.
    if (oldItem === undefined) {
      changedItems.push(newItem); // New item
    } else if (hasChanged(oldItem, newItem)) {
      changedItems.push(newItem); // Item has changed
    }
  });

  return changedItems;
}
const getChangedTopics = (oldTopics: Topic[], newTopics: Topic[]): Topic[] => {
  return getChangedItems<Topic>(
    oldTopics,
    newTopics,
    (topic) => topic.id,
    (oldTopic, newTopic) =>
      oldTopic.name !== newTopic.name ||
      JSON.stringify(oldTopic.features) !== JSON.stringify(newTopic.features)
  );
};

const getChangedFeatures = (
  oldFeatures: Feature[],
  newFeatures: Feature[]
): Feature[] => {
  return getChangedItems<Feature>(
    oldFeatures,
    newFeatures,
    (feature) => feature.featureId,
    (oldFeature, newFeature) =>
      oldFeature.content !== newFeature.content ||
      oldFeature.ignored !== newFeature.ignored
  );
};

export function withTopicsSync<T extends State>(
  client: TenantedClient,
  versionId: string,
  onComplete: () => void
) {
  return (set: StateSetter<T>, get: () => T): StateSetter<T> => {
    return (data: (s: T) => Partial<T>) => {
      const currentState = get();
      const nextState: T = {
        ...currentState,
        ...data(get()),
        synced: 'syncing',
      };
      const { topics: nextTopics, features: nextFeatures } = nextState;

      const topics = getChangedTopics(currentState.topics, nextTopics);
      const features = getChangedFeatures(currentState.features, nextFeatures);

      if (topics.length === 0) {
        set(() => nextState);
        return;
      }

      void client.topics
        .save({
          versionId,
          topics: topics.map((topic) => ({
            id: topic.id,
            name: topic.name,
            description: topic.description,
            action: topic.action ?? 'upsert',
            features: topic.features.map((feature) => feature.featureId),
          })),
          features: features.map((feature) => ({
            id: feature.featureId,
            ignored: feature.ignored,
          })),
        })
        .then(() => {
          set(
            () =>
              ({
                synced: 'synced',
              } as Partial<T>)
          );
        })
        .catch((e) => {
          toast.error(`Failed to persist changes: ${e.message}`);
          set(() => ({
            ...get(),
            synced: 'unsynced',
          }));
        })
        .finally(onComplete);

      set(() => nextState);
    };
  };
}
