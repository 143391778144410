import { Relation, AST } from '@cotera/era';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import {
  ChildrenProps,
  classNames,
  globalTheme,
} from '@cotera/client/app/components/utils';
import { z } from 'zod';
import { Card } from '@cotera/client/app/components/headless';
import { extractLetters } from '@cotera/client/app/etc';
import { Link } from 'react-router-dom';
import { Suspense } from 'react';
import { Loading } from '@cotera/client/app/components';

type ChartSection = AST.Chart._Chart;

const theme = globalTheme.theme('random');
const schema = z.object({
  value: z.string(),
  link: z
    .object({
      to: z.string(),
    })
    .nullable(),
});
type Schema = z.infer<typeof schema>;

export const RenderIndexList: React.FC<{
  section: ChartSection;
}> = ({ section }) => {
  return (
    <Suspense fallback={<LoadingView />}>
      <InnerRenderIndexList section={section} />
    </Suspense>
  );
};

const LoadingItem: React.FC = () => {
  return (
    <li>
      <Card.Container className="flex items-center w-full justify-between">
        <div className="flex flex-row h-full w-full">
          <div className="bg-muted-background w-[40px] text-sm rounded-l-md flex items-center justify-center px-2 text-alt-text border-r mr-4">
            <Loading.Shimmer className="w-4 h-4" />
          </div>
          <div className="min-h-[32px] flex flex-col items-center justify-center text-sm mr-4 py-4">
            <Loading.Shimmer className="w-20 h-4" />
          </div>
        </div>
      </Card.Container>
    </li>
  );
};

const LoadingView = () => {
  return (
    <ul>
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
    </ul>
  );
};

const InnerRenderIndexList: React.FC<{
  section: ChartSection;
}> = ({ section }) => {
  const { data } = useDuckDBQuery({ rel: Relation.wrap(section.rel) });

  const results = data.data.toArrayOf(schema);

  return (
    <ul className="grid grid-cols-2">
      {results.map((def, idx) => {
        return (
          <li
            key={idx}
            className={classNames('relative flex items-start space-x-3 mr-4')}
          >
            <Card.Container className="flex items-center w-full justify-between">
              <LinkWrapper {...def}>
                <div className="flex flex-row h-full w-full">
                  <div
                    className="bg-muted-background w-[40px] text-sm rounded-l-md flex items-center justify-center px-2 text-alt-text border-r mr-4"
                    style={{
                      color: theme.forLabel(def.value).color,
                    }}
                  >
                    {extractLetters(def.value)}
                  </div>
                  <div className="min-h-[32px] flex flex-col items-center justify-center text-sm mr-4 py-4">
                    {def.value}
                  </div>
                </div>
              </LinkWrapper>
            </Card.Container>
          </li>
        );
      })}
    </ul>
  );
};

const LinkWrapper: React.FC<Schema & ChildrenProps> = ({ link, children }) => {
  if (link) {
    return (
      <Link to={link.to} className="flex w-full items-center">
        {children}
      </Link>
    );
  }
  return <>{children}</>;
};
