import { TokenMeta } from './types';

export type { WithMeta, TokenMeta } from './types';
export { TokenStream } from './token-stream';
export { lex } from './lex';
export type { Token } from './tokens';
export * as T from './tokens';

export const combineMeta = (...tokenMeta: TokenMeta[]): TokenMeta =>
  tokenMeta.reduce((acc, curr) => ({
    range: [
      Math.min(acc.range[0], curr.range[0]),
      Math.max(acc.range[1], curr.range[1]),
    ],
  }));
