import { Ty } from '@cotera/era';
import { NamedAttr } from './types';
import { FFI_ACTIONS } from './ffi-actions/registry';
import { FFI } from '@cotera/sdk/core';

export const isLink = (attr: NamedAttr['ty']) => {
  const ty = Ty.shorthandToTy(attr);
  return ty.ty.k === 'struct' && '__link' in ty.ty.fields;
};

export const isAction = (attr: NamedAttr['ty']) => {
  return (
    Ty.isStructType(attr) &&
    Object.keys(FFI_ACTIONS).some((x) => Ty.hasTag(attr, x))
  );
};

export const getActionName = (attr: NamedAttr['ty']): FFI.FiiFuncName => {
  return Object.keys(FFI_ACTIONS).find((x) =>
    Ty.hasTag(attr, x)
  )! as FFI.FiiFuncName;
};
