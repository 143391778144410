import { AST, Ty, RelVar } from '@cotera/era';
import _ from 'lodash';

const SYSTEM_TABLE_SCOPE = '@@cotera-system';

const SYSTEM_TABLE_NAMES = [
  'stable_ids',
  'entity_events',
  'user_defined_dimensions',
] as const;
type SystemTable = (typeof SYSTEM_TABLE_NAMES)[number];

export const STABLE_IDS_ATTRIBUTES: Record<string, Ty.ExtendedAttributeType> = {
  identifier: Ty.ty('string'),
  definition_id: Ty.ty('string'),
  cotera_stable_id: Ty.ty('string'),
};

export const isSystemTableName = (name: string): name is SystemTable =>
  SYSTEM_TABLE_NAMES.includes(name as any);

export const SYSTEM_TABLES: Record<SystemTable, RelVar> = {
  stable_ids: RelVar.create({
    scope: SYSTEM_TABLE_SCOPE,
    name: 'stable_ids',
    ty: STABLE_IDS_ATTRIBUTES,
  }),
  entity_events: RelVar.create({
    scope: SYSTEM_TABLE_SCOPE,
    name: 'entity_events',
    ty: {
      cotera_stable_id: Ty.ty('string'),
      event_stream_id: Ty.ty('string'),
      entity_id: Ty.ty('string'),
      value: Ty.ty('string'),
      detected_at_time: Ty.ty('timestamp'),
    },
  }),
  user_defined_dimensions: RelVar.create({
    scope: SYSTEM_TABLE_SCOPE,
    name: 'user_defined_dimensions',
    ty: {
      entity_id: Ty.ty('string'),
      identifier: Ty.ty('string'),
      key: Ty.ty('string'),
      timestamp: Ty.ty('timestamp'),
      value: Ty.ty('super'),
      actor_id: Ty.ty('string'),
    },
  }),
} as const;

export const systemTablesForWriteSchema = (
  schema: string
): Record<SystemTable, AST._TableContract> =>
  _.mapValues(SYSTEM_TABLES, ({ relVar }): AST._TableContract => {
    const table: AST._TableContract = {
      t: 'table',
      name: relVar.name,
      schema,
      attributes: relVar.attributes,
    };
    return table;
  });

export type SpecTable = 'FOO_ENTITY' | 'SOME_INTS';

export const specTablesForWriteSchema = (
  schema: string
): Record<SpecTable, AST._TableContract> =>
  _.mapValues(SPEC_TABLES, (table) => ({ ...table, schema }));

const SPEC_TABLES: Record<SpecTable, Omit<AST._TableContract, 'schema'>> = {
  FOO_ENTITY: {
    t: 'table',
    name: 'spec_table_foo_entity',
    attributes: {
      id: Ty.ty('string'),
      bar: Ty.ty('string'),
    },
  },
  SOME_INTS: {
    t: 'table',
    name: 'spec_table_some_ints',
    attributes: {
      a: Ty.ty('int'),
      b: Ty.ty('int'),
    },
  },
};
