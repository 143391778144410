import { ChildrenProps } from '@cotera/client/app/components/utils';
import { AST, Expression, Macro, Relation, Ty } from '@cotera/era';
import { z } from 'zod';
import React, { createContext, useContext } from 'react';
import _, { sortBy } from 'lodash';
import {
  useDeepMemoValue,
  useDepsDeepEqMemo,
} from '@cotera/client/app/hooks/deep-memo';
import {
  NOT_FOUND_BUT_LOADED_EMPTY_SCOPE,
  resolveScopeDeps,
} from './resolve-scopes';

const EraScopeContext: React.Context<{
  [scope: string]: AST.MacroVarReplacements<AST.RelMacroChildren>;
}> = createContext({});

export const ProvideEraScope: React.FC<
  {
    scope: string;
    vals: AST.MacroVarReplacements<AST.RelMacroChildren>;
  } & ChildrenProps
> = ({ scope, vals, children }) => {
  const parentScopes = useContext(EraScopeContext);
  const memoed = useDeepMemoValue(vals);

  return (
    <EraScopeContext.Provider
      key={scope}
      value={{ ...parentScopes, [scope]: memoed }}
    >
      {children}
    </EraScopeContext.Provider>
  );
};

export const ProvideEraScopes: React.FC<
  {
    scopes: Record<string, AST.MacroVarReplacements<AST.RelMacroChildren>>;
  } & ChildrenProps
> = ({ scopes, children }) =>
  sortBy(Object.entries(scopes), ([name]) => name).reduce(
    (acc, [scope, vals]) => (
      <ProvideEraScope scope={scope} vals={vals}>
        {acc}
      </ProvideEraScope>
    ),
    children
  );

export function useEraRuntimeCurrentMuValue(params: {
  scope: string;
  name: string;
}): AST.Mu {
  const { scope, name } = params;
  const parentScopes = useContext(EraScopeContext);
  const scopeVals = parentScopes[scope] ?? NOT_FOUND_BUT_LOADED_EMPTY_SCOPE;

  const data: AST.Mu | undefined = scopeVals.sections[name];
  if (data === undefined) {
    const found = Object.keys(scopeVals)
      .map((key) => `"${key}"`)
      .join(', ');

    throw new Error(
      `Scope "${scope}" exists but is missing key "${name}" (found ${found})`
    );
  }

  return data;
}

export function useEraRuntimeCurrentRelValue(params: {
  scope: string;
  name: string;
}): AST.RelFR {
  const { scope, name } = params;
  const parentScopes = useContext(EraScopeContext);
  const scopeVals = parentScopes[scope] ?? NOT_FOUND_BUT_LOADED_EMPTY_SCOPE;

  const data: AST.RelFR | undefined = scopeVals.rels[name];

  if (data === undefined) {
    const found = Object.keys(scopeVals)
      .map((key) => `"${key}"`)
      .join(', ');

    throw new Error(
      `Scope "${scope}" exists but is missing key "${name}" (found ${found})`
    );
  }

  return data;
}

export function useEraRuntimeCurrentExprValue(params: {
  scope: string;
  name: string;
}): Ty.Scalar {
  const { scope, name } = params;
  const parentScopes = useContext(EraScopeContext);
  const scopeVals = parentScopes[scope] ?? NOT_FOUND_BUT_LOADED_EMPTY_SCOPE;

  const data: AST.ExprFR | undefined = scopeVals.exprs[name];

  if (data === undefined) {
    const found = Object.keys(scopeVals)
      .map((key) => `"${key}"`)
      .join(', ');

    throw new Error(
      `Scope "${scope}" exists but is missing key "${name}" (found ${found})`
    );
  }
  let appliedExpr: Expression = Expression.fromAst(data);
  const neededScopes = Object.keys(appliedExpr.freeVars);
  for (const scopeName of neededScopes) {
    const scopeVal =
      parentScopes[scopeName] ?? NOT_FOUND_BUT_LOADED_EMPTY_SCOPE;

    appliedExpr = Macro.callExprMacro(appliedExpr.ast, scopeVal, {
      scope: scopeName,
    });
  }

  return appliedExpr.evaluate();
}

function useMemoWithEraScopes<T>(
  scopes: string[],
  cb: (
    scopeVals: {
      scope: string;
      val: AST.MacroVarReplacements<AST.RelMacroChildren>;
    }[]
  ) => T,
  depList: React.DependencyList
): T {
  const parentScopeValues = useContext(EraScopeContext);
  const scopeVals = resolveScopeDeps(scopes, parentScopeValues);

  return useDepsDeepEqMemo(
    () => cb(scopeVals),
    // Basically we want to memoize based only the ref equality of the scopes
    // we depend on, so if an unrelated scope changes we don't have to re-evaluate
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...scopeVals, ...depList]
  );
}

export const useExpressionValue = (
  target: Expression | AST.ExprFR
): Ty.Scalar => {
  const expr =
    target instanceof Expression ? target : Expression.fromAst(target);

  return useMemoWithEraScopes(
    Object.keys(expr.freeVars),
    (scopes): Ty.Scalar => {
      let appliedExpr: Expression = expr;

      for (const { scope, val } of scopes) {
        appliedExpr = Macro.callExprMacro(appliedExpr.ast, val, { scope });
      }

      return appliedExpr.evaluate();
    },
    [expr.ast]
  );
};

export const useEraScopesAwareRelIR = (
  target: Relation | AST.RelFR
): AST.RelIR => {
  const rel = Relation.wrap(target);

  return useMemoWithEraScopes(
    Object.keys(rel.freeVars),
    (scopes) => {
      let appliedRel: Relation = rel;

      for (const { scope, val } of scopes) {
        appliedRel = Macro.callRelMacro(appliedRel.ast, val, { scope });
      }

      return appliedRel.ir();
    },
    [rel.ast]
  );
};

export function useTypedExpressionValue<T extends z.ZodSchema>(
  target: Expression | AST.ExprFR,
  schema: T
): z.infer<T> {
  return schema.parse(useExpressionValue(target))!;
}
