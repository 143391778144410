import React, { forwardRef, useRef, useState } from 'react';
import { FormComponentProps } from '../../types/form-component';
import { FormLabel, NameWrapper } from '../utils';
import { omit } from 'lodash';
import { Icon, IconName, Loading } from '@cotera/client/app/components/ui';
import { useForwardedRef } from '@cotera/client/app/hooks/use-forwarded-ref';
import {
  classNames,
  ColorScheme,
  Styles,
} from '@cotera/client/app/components/utils';
import { useEffectOnce } from 'react-use';

type InputType = 'text' | 'password' | 'email' | 'number';

export type InputProps = FormComponentProps &
  Pick<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'onKeyDown' | 'placeholder' | 'autoComplete' | 'onBlur' | 'id' | 'onFocus'
  > & {
    loading?: boolean;
    compact?: boolean;
    className?: string;
    disabled?: boolean;
    icon?: IconName;
    type?: InputType;
    autoFocus?: boolean;
    inline?: boolean;
    focusDelay?: number;
    value: string;
    spellcheck?: boolean;
    onChange: (
      value: string,
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
  };

export const TextArea = forwardRef<HTMLTextAreaElement, InputProps>(
  (
    {
      disabled = false,
      value,
      onChange,
      icon,
      label,
      compact = false,
      autoFocus,
      inline,
      theme = 'regular',
      focusDelay = 0,
      loading,
      spellcheck,
      ...props
    },
    forwardRef
  ) => {
    const [focused, setFocused] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useForwardedRef(forwardRef, textareaRef);

    const proxiedProps = {
      ...omit(
        props,
        'align',
        'onKeyDown',
        'placeholder',
        'autoComplete',
        'onBlur',
        'onFocus',
        'id'
      ),
      'data-focus': focused ? true : props['data-focus'],
    };

    useEffectOnce(() => {
      if (autoFocus) {
        setTimeout(() => {
          textareaRef.current?.focus();
        }, focusDelay);
      }
    });

    return (
      <NameWrapper name={label} compact={compact}>
        <div
          {...proxiedProps}
          className={classNames(
            Styles.focus[inline ? 'inline' : 'normal'],
            `focus:!text-zinc-400 
            data-[focus]:!text-zinc-800 
            bg-white  
            hover:bg-zinc-50 
            active:bg-zinc-100
            border-zinc-200
            border
            rounded
            px-2 
            py-1.5 `,
            Styles.compactableInput[compact ? 'compact' : 'normal'],
            'flex',
            ColorScheme.text[theme],
            disabled ? Styles.disabled : 'cursor-pointer',
            props.className
          )}
          onClick={() => {
            textareaRef.current?.focus();
          }}
        >
          {loading && <Loading.Spinner className="mr-2" variant="sm" />}
          {icon && !loading && (
            <Icon
              className={classNames(label ? 'mr-2' : '')}
              icon={icon}
              theme={theme}
            />
          )}
          <FormLabel label={label} compact={compact} disabled={disabled} />
          <textarea
            spellCheck={spellcheck}
            id={props.id}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
            autoFocus={autoFocus}
            autoComplete={props.autoComplete}
            onFocus={() => setFocused(true)}
            onBlur={(e) => {
              setFocused(false);
              props.onBlur?.(e);
            }}
            className={classNames(
              'flex-grow text-sm border-none bg-transparent focus:outline-none focus:ring-0',
              label ? 'py-1' : ''
            )}
            ref={textareaRef}
            value={value}
            onChange={(e) => onChange(e.target.value, e)}
          />
        </div>
      </NameWrapper>
    );
  }
);
