import { AST } from '../../ast';
import { Ty } from '../../ty';
import { sqlExprMacro, intersperse } from '../sql-ast';
import { assertConstantString, SqlDialect } from './dialect';
import { PostgresDialect } from './postgres';
import { Expression } from '../../builder';

// This dialect exists to support the fact that we havent figured out how to
// use the icu extension for duckdb in the browser. Here we switch the more
// correct "time zone aware" date operations to ones that operate on naive
// timestamps.
//
// Some articles
// 1. https://duckdb.org/2022/01/06/time-zones.html
// 2. https://coterahq.slack.com/archives/C03TWHCCK0U/p1685727866832659
// 3. https://github.com/duckdb/duckdb-wasm/issues/1202

const PrimitiveAttributeTypeToDuckDbWasmType: Record<
  Ty.PrimitiveAttributeType,
  string
> = {
  string: 'text',
  int: 'integer',
  float: 'float',
  day: 'timestamp',
  month: 'timestamp',
  year: 'timestamp',
  boolean: 'boolean',
  super: 'json',
  timestamp: 'timestamp',
};

const typeMapping = (ty: Ty.AttributeType): string => {
  if (ty.k === 'enum') {
    return PrimitiveAttributeTypeToDuckDbWasmType.string;
  }
  if (ty.k === 'range') {
    return PrimitiveAttributeTypeToDuckDbWasmType.int;
  }
  if (ty.k === 'record') {
    return PrimitiveAttributeTypeToDuckDbWasmType.super;
  }
  if (ty.k === 'array') {
    return `${typeMapping(ty.t.ty)}[]`;
  }
  if (ty.k === 'struct') {
    return `struct(${Object.entries(ty.fields)
      .map(([name, { ty }]) => `"${name}" ${typeMapping(ty)}`)
      .join(', ')})`;
  }
  return PrimitiveAttributeTypeToDuckDbWasmType[ty.t];
};

export const DuckDbWasmDialect: SqlDialect = {
  ...PostgresDialect,
  typeMapping,
  cast: (expr, targetTy) => {
    const { ty } = Expression.fromAst(expr);
    if (ty.ty.k === 'struct' || targetTy.k === 'struct') {
      if (targetTy.k === 'struct' || targetTy.t === 'super') {
        return sqlExprMacro`(${expr})::json`;
      } else if (targetTy.t === 'string') {
        return sqlExprMacro`(${expr})::text`;
      } else {
        throw new Error('unreachable');
      }
    }

    if (ty.ty.t === 'timestamp' && targetTy.t === 'string') {
      return sqlExprMacro`strftime((${expr})::timestamp, '%Y-%m-%dT%H:%M:%SZ')`;
    }

    if (targetTy.t === 'super') {
      return sqlExprMacro`to_json(${expr})`;
    }

    if (ty.ty.t === 'super' && targetTy.t === 'string') {
      // Postgres will wrap top level strings in an additional level of quotes
      // if you cast directly to a string, this seems like a reasonable work
      // around
      return sqlExprMacro`(json_object('value', ${expr}))->>'value'`;
    }

    return sqlExprMacro`cast(${expr} as ${typeMapping(targetTy)})`;
  },
  generateSeries: ({ start, stop }) => {
    return sqlExprMacro`select cast(generate_series as int) as n from generate_series(${start.toString()}, ${stop.toString()})`;
  },
  makeArray: ({ elements }) => {
    return sqlExprMacro`[${intersperse<AST.ExprIR | string>(elements, ', ')}]`;
  },
  makeStruct: (fields) => {
    return sqlExprMacro`{${intersperse(
      Object.entries(fields).map(
        ([name, expr]) => sqlExprMacro`"${name}": ${expr}`
      ),
      [', ']
    )}}`;
  },
  getPropertyFromStruct(expr, name, _wantedTy) {
    return sqlExprMacro`(${expr}).${this.attr(name)}`;
  },
  supportsFileSources: true,
  functionOverrides: {
    ...PostgresDialect.functionOverrides,
    array_agg: ([arg0]) =>
      sqlExprMacro`array_agg(${arg0!}) filter (where (${arg0!}) is not null)`,
    log_2: ([arg0]) => sqlExprMacro`log2(${arg0!})`,
    log_10: ([arg0]) => sqlExprMacro`log10(${arg0!})`,
    date_add: ([arg0, arg1, arg2]) => {
      const scalar = assertConstantString(arg2!, ['days']);
      const unit = { days: 'day' }[scalar];
      return sqlExprMacro`((${arg0!})::timestamp + ((${arg1!}) * '1 ${unit}'::interval))`;
    },
    date_trunc: ([arg0, arg1]) =>
      sqlExprMacro`date_trunc('${assertConstantString(
        arg1!,
        AST.DATE_TRUNC_UNITS
      )}', ((${arg0!})::timestamp))`,
    date_part: ([arg0, arg1]) => {
      const unit = assertConstantString(arg1!, AST.DATE_PART_UNITS);
      return sqlExprMacro`date_part('${unit}', ((${arg0!})::timestamp))::int`;
    },
    date_diff: ([arg0, arg1, arg2]) => {
      const unit = assertConstantString(arg2!, AST.DATE_DIFF_UNITS);
      switch (unit) {
        case 'days':
          return sqlExprMacro`(extract(day from ((${arg1!})::timestamp - (${arg0!})::timestamp)))::int`;
        case 'years':
          return sqlExprMacro`(extract(year from (${arg1!})::timestamp) - extract(year from (${arg0!})::timestamp))::int`;
        case 'seconds':
          return sqlExprMacro`(extract(EPOCH from ((${arg1!})::timestamp - (${arg0!})::timestamp)))::float`;
      }
    },
    cosine_distance: ([arg0, arg1]) => {
      return sqlExprMacro`(1 - ((${arg0!}) <=> (${arg1!})))`;
    },
  },
};
