import { DisplayError, SideModal } from '@cotera/client/app/components/app';
import React, { Suspense } from 'react';
import {
  Button,
  Center,
  Divider,
  Icon,
  Loading,
  Title,
  Text,
  Tooltip,
} from '@cotera/client/app/components/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { Accordion } from '@cotera/client/app/components/headless';
import {
  ChildrenProps,
  classNames,
  ColorScheme,
} from '@cotera/client/app/components/utils';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { useAccordion } from '@cotera/client/app/components/headless';
import { useSubscribe } from '@cotera/client/app/etc';
import { Assert } from '@cotera/utilities';
import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';
import { useEntity } from '@cotera/client/app/pages/entities/hooks';
import { ResponseShape } from './utils';
import { ConfigSection } from './components/section';
import { LLMModelConfigViewModel } from './view-model';
import { Preview } from './components/preview';
import { TemplatedExpressionEditor } from '../components/templated-expression-editor';
import { NewColumnAction } from '../column-action.type';

const Container: NewColumnAction['view'] = ({
  open,
  onClose,
  additionalProps,
  vm: relVm,
}) => {
  const entityName = additionalProps['entityName'] as string;
  const entity = useEntity(entityName);
  Assert.assert(entity !== undefined);

  const vm = useDeepMemo(
    () => new LLMModelConfigViewModel(entity, relVm),
    [entity]
  );

  return (
    <SideModal open={open} onClose={onClose}>
      <Body>
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <ErrorBoundary
            fallbackRender={({ error }) => <DisplayError error={error} />}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between w-full mb-2">
                <Title type="section">Configuration</Title>
              </div>
              <Divider className="mb-4" />
            </div>
            <Configuration vm={vm} />
            <Accordion.Root expanded={['model']} multiple={true}>
              <Accordion.Trigger
                id="model"
                as={'div'}
                className="w-full cursor-pointer"
              >
                <SectionHeader title={'Model'} id={'model'} />
              </Accordion.Trigger>
              <Accordion.Item id="model">
                <Model vm={vm} />
              </Accordion.Item>
              <Accordion.Trigger
                id="output"
                as={'div'}
                className="w-full cursor-pointer"
              >
                <SectionHeader title={'Define Output Shape'} id={'output'} />
              </Accordion.Trigger>
              <Accordion.Item id="output">
                <DefineOutput vm={vm} />
              </Accordion.Item>
              <Accordion.Trigger
                id="preview"
                as={'div'}
                className="w-full cursor-pointer"
              >
                <SectionHeader title={'Preview'} id={'preview'} />
              </Accordion.Trigger>
              <Accordion.Item id="preview">
                <Preview vm={vm} />
              </Accordion.Item>
              <Accordion.Trigger
                id="advanced"
                as={'div'}
                className="w-full cursor-pointer"
              >
                <SectionHeader title={'Advanced Settings'} id={'advanced'} />
              </Accordion.Trigger>
              <Accordion.Item id="advanced">
                <Advanced vm={vm} />
              </Accordion.Item>
              <Accordion.Trigger
                id="run-settings"
                as={'div'}
                className="w-full cursor-pointer"
              >
                <SectionHeader title={'Run Settings'} id={'run-settings'} />
              </Accordion.Trigger>
              <Accordion.Item id="run-settings">
                <RunSettings />
              </Accordion.Item>
            </Accordion.Root>
          </ErrorBoundary>
        </Suspense>
      </Body>
    </SideModal>
  );
};

const SectionHeader: React.FC<{
  title: string;
  id: string;
}> = ({ title, id }) => {
  const expanded = useAccordion((s) => s.expanded);
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between w-full mb-2">
        <Title type="section">{title}</Title>
        <Icon icon={expanded.includes(id) ? 'chevron-up' : 'chevron-down'} />
      </div>
      <Divider />
    </div>
  );
};

const Body: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <div className="p-4 w-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <Title type="title" subtitle="Run an LLM model on your data">
          LLM Model
        </Title>
        <Button
          theme="secondary"
          text="Apply"
          hint={{
            display: `Ctrl + a`,
            targetKey: (e) => e.ctrlKey && e.key === 'a',
          }}
          onClick={async () => {}}
        />
      </div>
      <Divider className="mb-4" />
      {children}
    </div>
  );
};

const DefineOutput: React.FC<{
  vm: LLMModelConfigViewModel;
}> = ({ vm }) => {
  const shape = useSubscribe(vm, (s) => s.outputShape);

  return (
    <ConfigSection className="h-[500px mt-4]">
      <Text.Caption className="mb-1">
        This will be the shape of the values in the resulting column.
      </Text.Caption>
      <TypeBuilder
        onChange={(v) => vm.setOutputShape(v)}
        name={'response'}
        shape={shape.value}
      />
    </ConfigSection>
  );
};

const makeBorderForDepth = (depth: number) => {
  const colors = ['border-emerald-300', 'border-indigo-300'];

  return colors[depth % colors.length];
};

const getNewType = (type: ResponseShape['t']): ResponseShape => {
  switch (type) {
    case 'object':
      return { t: 'object', items: [] };
    case 'array':
      return { t: 'array', items: { t: 'string' } };
    case 'enum':
      return { t: 'enum', values: [] };
    case 'string':
      return { t: 'string' };
    case 'float':
      return { t: 'float' };
    case 'int':
      return { t: 'int' };
    case 'boolean':
      return { t: 'boolean' };
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

const TypeBuilder: React.FC<{
  shape: ResponseShape;
  name: string;
  depth?: number;
  onChange: (updatedShape: ResponseShape) => void;
  onNameChange?: (newName: string) => void;
  onDelete?: () => void;
  parent?: ResponseShape['t'];
}> = ({ shape, name, depth = 0, onChange, onNameChange, onDelete, parent }) => {
  return (
    <div
      className={classNames(
        'flex flex-col border-l-4 pl-4 mt-2',
        makeBorderForDepth(depth)
      )}
    >
      <div className="flex items-center">
        {parent === 'object' ? (
          <Inputs.Text
            className="!rounded-r-none"
            compact
            value={name}
            onChange={(v) => {
              onNameChange?.(v);
            }}
          />
        ) : (
          <span
            className={classNames(
              'text-sm rounded-l px-4 h-10 border flex items-center',
              ColorScheme.text.regular
            )}
          >
            {name}
          </span>
        )}
        <ComboBox.Select
          compact
          className="w-full !rounded-l-none "
          options={[
            'string',
            'int',
            'float',
            'boolean',
            'enum',
            'object',
            'array',
          ].map((x) => ({
            display: x,
            value: x,
          }))}
          onChange={(value) => {
            onChange(getNewType(value!.value as ResponseShape['t']));
          }}
          value={{
            display: shape.t,
            value: shape.t,
          }}
        />
        {onDelete && (
          <Button
            icon="trash"
            inline
            iconOnly
            theme="error"
            onClick={onDelete}
            className="ml-2"
          />
        )}
      </div>
      {shape.t === 'object' && (
        <div>
          {shape.items.map((item, idx) => {
            return (
              <TypeBuilder
                depth={depth + 1}
                key={idx}
                shape={item.type}
                name={item.key}
                parent={shape.t}
                onNameChange={(newName) => {
                  const newState = structuredClone(shape);
                  newState.items[idx]!.key = newName;
                  onChange(newState);
                }}
                onChange={(newShape) => {
                  const newState = structuredClone(shape);
                  newState.items[idx]!.type = newShape;
                  onChange(newState);
                }}
                onDelete={() => {
                  const newState = structuredClone(shape);
                  newState.items.splice(idx, 1);
                  onChange(newState);
                }}
              />
            );
          })}
          <Button
            inline
            icon="add"
            text="Add Field"
            compact
            className="!ml-0 !pl-0 !pb-0"
            onClick={() => {
              const newState = structuredClone(shape);
              newState.items.push({
                key: `new_field_${newState.items.length}`,
                type: { t: 'string' },
              });
              onChange(newState);
            }}
          />
        </div>
      )}
      {shape.t === 'array' && (
        <div>
          <TypeBuilder
            depth={depth + 1}
            shape={shape.items}
            name={'items'}
            onChange={(newShape) => {
              const newState = structuredClone(shape);
              newState.items = newShape;
              onChange(newState);
            }}
          />
        </div>
      )}
      {shape.t === 'enum' && (
        <div>
          <ul className="flex flex-col mt-2 ml-2">
            {shape.values.map((value, idx) => {
              return (
                <li
                  key={idx}
                  className="flex items-center [&:not(:last-child)]:mb-2"
                >
                  <Inputs.Text
                    value={value}
                    onChange={(newValue) => {
                      const newState = structuredClone(shape);
                      newState.values[idx] = newValue;
                      onChange(newState);
                    }}
                  />
                  <Button
                    icon="trash"
                    iconOnly
                    inline
                    theme="error"
                    className="ml-2"
                    onClick={() => {
                      const newState = structuredClone(shape);
                      newState.values.splice(idx, 1);
                      onChange(newState);
                    }}
                  />
                </li>
              );
            })}
          </ul>
          <Button
            inline
            icon="add"
            text="Add Value"
            compact
            className="!ml-0 !pl-0 !pb-0"
            onClick={() => {
              const newState = structuredClone(shape);
              newState.values.push('');
              onChange(newState);
            }}
          />
        </div>
      )}
    </div>
  );
};

const MODEL_OPTIONS = [
  {
    display: 'GPT-4o',
    value: 'gpt-4o',
    context: 'Good for general purpose text generation',
  },
  {
    display: 'GPT-4o-mini',
    value: 'gpt-4o-mini',
    context: 'Good, fast option for most use cases',
  },
  {
    display: 'GPT-o1',
    value: 'gpt-o1',
    context: 'Slow, but good for long form text generation nad complex tasks',
  },
];

const Model: React.FC<{
  vm: LLMModelConfigViewModel;
}> = ({ vm }) => {
  const model = useSubscribe(vm, (s) => s.model);
  const systemMessage = useSubscribe(vm, (s) => s.systemMessage);

  return (
    <ConfigSection className="mt-4">
      <Inputs.TextArea
        label="System Message (optional)"
        placeholder={`
      You are an analyst and are looking at CX tickets. 

      Your job is to summarize them to extract the main issues and trends.
    `}
        value={systemMessage}
        onChange={(v) => vm.setSystemMessage(v)}
        className="mb-4 min-h-[150px]"
      />
      <ComboBox.Single
        nullable={false}
        label="Model"
        options={({ query }) => {
          return (
            <ComboBox.StaticOptions query={query} options={MODEL_OPTIONS} />
          );
        }}
        value={{
          display:
            MODEL_OPTIONS.find((x) => x.value === model)?.display ?? model,
          value: model,
        }}
        onChange={(m) => vm.setModel(m!.value)}
      />
    </ConfigSection>
  );
};

/**
 *
 * temperature, max tokens etc
 */
const Advanced: React.FC<{
  vm: LLMModelConfigViewModel;
}> = ({ vm }) => {
  const maxTokens = useSubscribe(vm, (s) => s.maxTokens);

  return (
    <ConfigSection className="mt-4">
      <Inputs.Number
        label="Maximum Output Length (in tokens)"
        value={maxTokens}
        onChange={(v) => vm.setMaxTokens(v)}
      />
    </ConfigSection>
  );
};

/**
 * where you configure the prompt and input columns
 */
const Configuration: React.FC<{
  vm: LLMModelConfigViewModel;
}> = ({ vm }) => {
  const prompt = useSubscribe(vm, (s) => s.prompt);

  return (
    <ConfigSection>
      <TemplatedExpressionEditor
        name="Prompt"
        entity={vm.entity}
        eraql={prompt}
        onChange={(v) => {
          vm.setPrompt(v);
        }}
      />
    </ConfigSection>
  );
};

/**
 *  determine whether or not to run backfills when the rpompt changes, or just run going forward.
 * maybe allow for conditional runs or backfill only a certain slice
 */
const RunSettings: React.FC = () => {
  return (
    <ConfigSection className="mt-4">
      <div className="flex items-center mb-1">
        <Title type="label" className="mr-2">
          Run Backfills
        </Title>
        <Tooltip
          side="top"
          text='Run over entire dataset when any settings change. If "no", then we just run for new items in the dataset.'
        >
          <div className="cursor-pointer">
            <Icon icon="information-circle" />
          </div>
        </Tooltip>
      </div>
      <Inputs.Toggle options={['Yes', 'No']} onChange={() => {}} />
    </ConfigSection>
  );
};

export const LlmColumn: NewColumnAction = {
  icon: 'sparkles',
  view: Container,
  label: 'LLM Model',
};
