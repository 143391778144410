import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { Overlay } from '../overlay';
import { classNames } from '@cotera/client/app/components/utils';
import { Button } from '../button';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';

const PRIORITY = {
  low: 'z-10',
  medium: 'z-20',
  high: 'z-30',
  highest: 'z-40',
};

export const Modal: React.FC<{
  open: boolean;
  onClose?: (val: boolean) => void;
  children: ReactNode;
  center?: boolean;
  className?: string;
  priority?: keyof typeof PRIORITY;
  padding?: boolean;
  contentClassName?: string;
  closeEnabled?: boolean;
}> = ({
  open,
  onClose = () => {},
  children,
  center = true,
  className,
  priority = 'medium',
  padding = true,
  contentClassName,
  closeEnabled = true,
}) => {
  useKeyPress('Escape', () => {
    onClose(false);
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('relative', PRIORITY[priority])}
        onClose={() => {}}
      >
        <Overlay z={PRIORITY[priority]} />

        <div
          onClick={() => onClose(false)}
          className={classNames(
            'fixed inset-0 overflow-y-auto',
            PRIORITY[priority]
          )}
        >
          <div
            className={classNames(
              `
            flex min-h-full text-center justify-center sm:p-0
          `,
              center ? 'items-center justify-center' : '',
              className,
              padding ? 'p-4' : ''
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'flex-grow-0 h-fit relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 relative',
                  padding ? 'sm:p-6' : '',
                  contentClassName ?? ''
                )}
              >
                {closeEnabled && (
                  <Button
                    icon="x-mark"
                    tooltip="left"
                    iconOnly
                    inline
                    text="Close"
                    className="absolute right-2 top-2"
                    onClick={() => onClose(false)}
                  />
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
