import {
  AST,
  Constant,
  Expression,
  Macro,
  Relation,
  Ty,
  Values,
  InformationSchema,
  From,
} from '@cotera/era';
import _ from 'lodash';
import type {
  RuntimeData,
  AsyncFnRuntimeDataProvider,
  RuntimeDataProvider,
} from './runtime-data';
import { Assert } from '@cotera/utilities';

import {
  ENTITY_DEFINITIONS,
  EVENT_CURSORS,
  MANIFEST_EVENT_STREAM_SINKS,
  MANIFEST_EVENT_STREAMS,
} from './vars';
import { systemTablesForWriteSchema } from './system-tables';
import { EntityResolver } from '../cookbook';

export const MANIFEST_INFORMATATION_SCHEMA_NAME = '@@cotera-manifest';

export const applyToExprAsync = async <T>(
  expr: Expression,
  provider: AsyncFnRuntimeDataProvider<T>,
  args: T
): Promise<Expression> => {
  const neededScopes = Object.keys(expr.freeVars);
  const scopes = await provideAsync(neededScopes, provider, args);

  return scopes.reduce(
    (e, { scope, vars }) => Macro.callExprMacro(e.ast, vars, { scope }),
    expr
  );
};

export const applyToRelAsync = async <T>(
  rel: Relation,
  provider: AsyncFnRuntimeDataProvider<T>,
  args: T
): Promise<Relation> => {
  const neededScopes = Object.keys(rel.freeVars);
  const scope = await provideAsync(neededScopes, provider, args);

  return scope.reduce(
    (r, { scope, vars }) => Macro.callRelMacro(r.ast, vars, { scope }),
    rel
  );
};

const RUNTIME_DATA_TO_SCOPE_VARS: {
  [Scope in keyof RuntimeData]: (
    data: RuntimeData[Scope]
  ) => Record<string, Expression | Relation>;
} = {
  '@@cotera-manifest-eager-defs': (defs) => defs,
  '@@cotera-entity-refs': (entities) => {
    return _.mapValues(entities, ({ id, cols }) => {
      const entity = EntityResolver.fromColumnInfo(id, cols);
      return entity.allColumns();
    });
  },
  '@@cotera-system': ({ WRITE_SCHEMA }) => {
    const systemTables = systemTablesForWriteSchema(WRITE_SCHEMA);
    return _.mapValues(systemTables, (table) => From(table));
  },
  '@@cotera-org': (data) => ({
    ORG_ID: Constant(data.ORG_ID),
    ORG_NAME: Constant(data.ORG_NAME),
  }),
  '@@cotera-entities': ({ DEFINITIONS, WRITE_SCHEMA }) => {
    const { stable_ids } = systemTablesForWriteSchema(WRITE_SCHEMA);

    const stableIdsForEntity = Object.fromEntries(
      DEFINITIONS.map(({ name }) => [
        `${name}-stable-ids`,
        From(stable_ids).where((t) => t.attr('definition_id').eq(name)),
      ])
    );

    return {
      DEFINITIONS: StrictValues(DEFINITIONS, ENTITY_DEFINITIONS.attributes),
      ...stableIdsForEntity,
    };
  },
  '@@cotera-events': (data) => ({
    CURSORS: StrictValues(data.CURSORS, EVENT_CURSORS.attributes),
  }),
  '@@cotera-manifest-event-streams': ({ SINKS, STREAMS }) => ({
    STREAMS: StrictValues(STREAMS, MANIFEST_EVENT_STREAMS.attributes),
    SINKS: StrictValues(SINKS, MANIFEST_EVENT_STREAM_SINKS.attributes),
  }),
  '@@cotera-manifest-def-information-schema': (data) => ({
    INFORMATION: StrictValues(
      data.INFORMATION.map((row) => ({
        ...row,
        table_schema: MANIFEST_INFORMATATION_SCHEMA_NAME,
        data_type: Ty.displayTy(row.ty),
        is_nullable: row.ty.nullable,
      })),
      InformationSchema({ type: 'columns', schemas: ['@@dummy'] }).attributes
    ),
  }),
  '@@cotera-decision-tree-outputs': (data) => {
    return _.mapValues(data, (data) => Values(data));
  },
};

const buildScopeVars = (
  vars: Record<string, Expression | Relation>
): AST.MacroVarReplacements<AST.RelMacroChildren> =>
  Object.entries(vars).reduce<AST.MacroVarReplacements<AST.RelMacroChildren>>(
    (scopeVars, [name, replacement]) => {
      if (replacement instanceof Expression) {
        return {
          ...scopeVars,
          exprs: { ...scopeVars.exprs, [name]: replacement.ast },
        };
      } else if (replacement instanceof Relation) {
        return {
          ...scopeVars,
          rels: { ...scopeVars.rels, [name]: replacement.ast },
        };
      } else {
        return Assert.unreachable(replacement);
      }
    },
    { exprs: {}, rels: {}, sections: {} }
  );

export const provide = (provider: RuntimeDataProvider) => {
  return _.mapValues(
    provider,
    (res, name): AST.MacroVarReplacements<AST.RelMacroChildren> =>
      buildScopeVars(
        RUNTIME_DATA_TO_SCOPE_VARS[name as keyof RuntimeDataProvider](
          res as any
        )
      )
  );
};

const provideAsync = async <T>(
  neededScopes: string[],
  provider: AsyncFnRuntimeDataProvider<T>,
  args: T
): Promise<
  { scope: string; vars: AST.MacroVarReplacements<AST.RelMacroChildren> }[]
> => {
  const dataPromises: (() => Promise<{
    scope: string;
    vars: AST.MacroVarReplacements<AST.RelMacroChildren>;
  }>)[] = [];

  const providedScopes = Object.keys(
    provider
  ) as (keyof AsyncFnRuntimeDataProvider<T>)[];

  for (const scope of providedScopes.filter((scope) =>
    neededScopes.includes(scope)
  )) {
    dataPromises.push(async () => {
      const data = await provider[scope](args);
      // This is safe, TS just doesn't know we're calling the same scope on both providers;
      // thus the `as any`
      const mapped = RUNTIME_DATA_TO_SCOPE_VARS[scope](data as any);
      return { scope, vars: buildScopeVars(mapped) };
    });
  }

  return Promise.all(dataPromises.map((f) => f()));
};

const StrictValues = (
  rows: readonly Ty.Row[],
  attributes: Record<string, Ty.Shorthand>
): Relation =>
  Values(
    rows.map((row) => _.pick(row, Object.keys(attributes))),
    attributes
  );
