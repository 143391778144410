import { z } from 'zod';
import { Contract, GET, PaginationParamsSchema, POST } from '@cotera/contracts';
import { Parser } from '@cotera/era';

export const ArtifactSchema = z.object({
  id: z.string().uuid(),
  status: z
    .enum(['ready', 'pending'])
    .or(z.object({ status: z.literal('failed'), message: z.string() })),
});

export const ArtifactFullfillmentSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  numberOfRows: z.number().gte(0),
  enforcedLimit: z.number().nullable(),
  signature: z.record(Parser.ExtendedAttributeTypeSchema),
  createdAt: z.coerce.date(),
});

export const ArtifactFailure = z.object({
  errorMessage: z.string(),
  createdAt: z.coerce.date(),
});

export const ArtifactRequestSchema = z.object({
  id: z.string().uuid(),
  sqlHash: z.string(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  requestedByUserId: z.string().uuid(),
  fullfillment: ArtifactFullfillmentSchema.nullable(),
  failure: ArtifactFailure.nullable(),
  invalidatedAt: z.coerce.date().nullable(),
  lastHeartBeatAt: z.coerce.date().nullable(),
});

export type Artifact = z.infer<typeof ArtifactSchema>;
export type ArtifactRequest = z.infer<typeof ArtifactRequestSchema>;
export type ArtifactFullfillment = z.infer<typeof ArtifactFullfillmentSchema>;
export type ArtifactFailure = z.infer<typeof ArtifactFailure>;

export const ArtifactsContract = Contract.new({
  invalidateArtifact: POST({
    params: z.object({ id: z.string() }),
    output: z.object({}),
    errors: z.never(),
  }),
  usableCache: GET({
    params: z.object({}),
    output: z.record(
      z.object({
        id: z.string().uuid(),
        createdAt: z.coerce.date(),
        status: z.discriminatedUnion('t', [
          z.object({ t: z.literal('pending') }),
          z.object({
            t: z.literal('ready'),
            signature: z.record(Parser.ExtendedAttributeTypeSchema),
            complete: z.boolean(),
          }),
        ]),
      })
    ),
    errors: z.never(),
  }),
  createByDefinitionId: POST({
    params: z.object({ defId: z.string() }),
    output: ArtifactSchema,
    errors: z.object({ errorType: z.enum(['DefinitionNotFound', 'NotFound']) }),
    mustBeAbleTo: [['write', 'warehouse']],
  }),
  create: POST({
    params: z.object({
      ast: Parser.RelSchema,
      acceptStaleWithinSeconds: z.number().optional(),
    }),
    output: ArtifactSchema,
    errors: z.never(),
  }),
  getRequestById: GET({
    output: ArtifactRequestSchema.nullable(),
    params: z.object({ id: z.string() }),
    errors: z.never(),
  }),
  listRequests: GET({
    params: z.object({
      sqlHash: z.string().optional(),
      pagination: PaginationParamsSchema,
    }),
    output: z.array(ArtifactRequestSchema),
    errors: z.never(),
  }),
});
