import React from 'react';
import { z } from 'zod';
import { InputProps, ObjectErrors } from './input.props';
import { ArrayInput } from './array';
import { EnumSelect } from './enum';
import { ObjectInput } from './object';
import { NumberInput } from './number';
import { StringInput } from './string';

export const ZodInput: React.FC<InputProps & { errors?: ObjectErrors }> = (
  props
) => {
  const { zodSchema, name } = props;
  const optional = zodSchema instanceof z.ZodOptional;
  const unwrappedSchema = optional ? zodSchema.unwrap() : zodSchema;
  const errorName = name.split('.').at(-1);
  const error = errorName && props.errors ? props.errors[errorName] : undefined;

  if (optional && props.hideOptionals) {
    return null;
  }

  if (unwrappedSchema instanceof z.ZodObject) {
    return (
      <ObjectInput
        errors={props.errors}
        className={props.className}
        wrapper={props.wrapper}
        optional={optional}
        name={name}
        zodSchema={unwrappedSchema}
        hideOptionals={props.hideOptionals}
      />
    );
  } else if (unwrappedSchema instanceof z.ZodArray) {
    return (
      <ArrayInput
        error={error}
        className={props.className}
        wrapper={props.wrapper}
        optional={optional}
        name={name}
        zodSchema={unwrappedSchema}
        hideOptionals={props.hideOptionals}
      />
    );
  }

  if (unwrappedSchema instanceof z.ZodEnum) {
    return (
      <EnumSelect
        error={error}
        className={props.className}
        wrapper={props.wrapper}
        optional={optional}
        name={name}
        zodSchema={unwrappedSchema}
      />
    );
  }
  if (unwrappedSchema instanceof z.ZodNumber) {
    return (
      <NumberInput
        error={error}
        className={props.className}
        wrapper={props.wrapper}
        optional={optional}
        name={name}
        zodSchema={unwrappedSchema}
      />
    );
  }

  return (
    <StringInput
      error={error}
      className={props.className}
      wrapper={props.wrapper}
      optional={optional}
      name={name}
      zodSchema={unwrappedSchema}
    />
  );
};
