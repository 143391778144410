import { filterGroupToExpression } from '@cotera/client/app/components/app';
import { FilterGroup } from '@cotera/client/app/components/app/filter-builder/types';
import {
  BaseWorkspaceNodeViewModel,
  NodeTransform,
  TransformableRelation,
} from '@cotera/client/app/components/app/workspace/nodes/base';
import { Values } from '@cotera/era';

export class DocumentsViewModel extends BaseWorkspaceNodeViewModel {
  override transforms: NodeTransform[] = [];
  t: 'feature';
  filters: FilterGroup | null = null;
  limit: number = 300;

  constructor(
    name: string,
    id: string,
    private _baseRel: TransformableRelation,
    private documents: {
      features: {
        id: string;
        content: string;
      }[];
      coteraStableId: string;
    }[]
  ) {
    super(name, id);
    const documentValues = Values(
      this.documentIds.map((coteraStableId) => ({
        coteraStableId,
      })),
      {
        coteraStableId: 'string',
      }
    );

    this.transforms = [
      {
        fn: (rel) =>
          rel
            .innerJoin(documentValues, (documents, ids) => {
              return {
                on: documents
                  .attr('id')
                  .cast('string')
                  .eq(ids.attr('coteraStableId').cast('string')),
                select: {
                  ...documents.star(),
                },
              };
            })
            .limit(this.limit),
        type: 'documents',
      },
    ];
  }

  get baseRel() {
    return this._baseRel;
  }

  get rel() {
    return this.baseRel.apply(this.transforms);
  }

  get documentIds() {
    return this.documents.map((d) => d.coteraStableId);
  }

  get stableIds() {
    return this.documentIds.map((coteraStableId) => ({
      coteraStableId,
    }));
  }

  featuresForDocument(coteraStableId: string) {
    return (
      this.documents.find((d) => d.coteraStableId === coteraStableId)
        ?.features ?? []
    );
  }

  featuresWithCounts() {
    return this.documents.flatMap((d) => d.features);
  }

  setFilters(filters: FilterGroup | null, limit?: number) {
    this.filters = filters;
    this.limit = limit ?? this.limit;

    this.transforms = [
      {
        fn: (rel) => {
          const maybeFiltered = filters
            ? rel.where((t) => filterGroupToExpression(t)(filters))
            : rel;

          return maybeFiltered
            .innerJoin(
              Values(this.stableIds, {
                coteraStableId: 'string',
              }),
              (documents, ids) => {
                return {
                  on: documents
                    .attr('id')
                    .cast('string')
                    .eq(ids.attr('coteraStableId').cast('string')),
                  select: {
                    ...documents.star(),
                  },
                };
              }
            )
            .limit(this.limit);
        },
      },
    ];
  }

  refreshSample() {
    //TODO sampling
    this.notifySubscribers();
  }
}
