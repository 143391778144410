import { Assert } from '@cotera/utilities';
import { useQuery } from '@tanstack/react-query';
import { useTenantedClient, useWhoami } from '../stores/org';
import { queryKeys } from './query-cache-keys';

export const useFivetranConnectors = () => {
  const client = useTenantedClient();
  const orgId = useWhoami((x) => x.org.id);

  return useQuery({
    queryKey: queryKeys.sources.fivetranConnectors.getDestination({ orgId }),
    queryFn: async () =>
      Assert.assertOk(await client.warehouse.etl.getDestination({ orgId })),
  });
};
