import { Card, Tabs } from '@cotera/client/app/components/headless';
import { AST } from '@cotera/era';
import { CompileMarkup } from './compiler';

export const RenderTabs: React.FC<{
  section: AST._Tabs;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  return (
    <Tabs.Container mode="controlled" default={String(0)}>
      <Tabs.Panel type={section.style}>
        {section.style === 'select' ? (
          <Tabs.TabSelector
            tabs={section.tabs.map((tab, i) => ({
              id: String(i),
              name: tab.title,
            }))}
          />
        ) : (
          section.tabs.map((tab, i) => (
            <Tabs.Tab key={i} id={String(i)} text={tab.title} />
          ))
        )}
      </Tabs.Panel>
      {section.tabs.map((tab, i) => (
        <Tabs.Content padding={false} id={String(i)} key={i}>
          <Card.Container bottomPadding={false} className="mt-2">
            <Card.Content>
              <CompileMarkup key={i} section={tab.section} inBlock={inBlock} />
            </Card.Content>
          </Card.Container>
        </Tabs.Content>
      ))}
    </Tabs.Container>
  );
};
