import round from 'lodash/round';
import { Text, Tooltip } from '@cotera/client/app/components';
import { classNames } from '@cotera/client/app/components/utils';
import { Ty } from '@cotera/era';
import { CATEGORICAL_TAGS } from '@cotera/sdk/core';
import { useMeasure } from 'react-use';

const COLOR_MAP = {
  category: 'bg-secondary-background text-alt-text hover:bg-primary-background',
  gray: 'bg-gray-200',
  active: 'bg-primary-background text-alt-text',
  warning:
    'text-black pattern-diagonal-lines pattern-yellow-400 pattern-size-2 pattern-bg-yellow-200',
};

const HistogramBar = ({
  height,
  category,
  rows,
  percent,
  onClick,
  active = false,
}: {
  rows: number;
  height: number;
  percent: number;
  category: string | null;
  onClick?: () => void;
  active?: boolean;
}) => {
  return (
    <Tooltip
      text={`${rows} rows (${round(percent, 2)}%) ${category} `}
      side="bottom"
    >
      <div
        onClick={onClick}
        className={classNames(
          'cursor-pointer h-full mr-[1px] flex items-center justify-center text-xs flex-1',
          COLOR_MAP[active ? 'active' : 'category']
        )}
        style={{ height: `${Math.max(height, 0.1)}%` }}
      />
    </Tooltip>
  );
};

export const Histogram: React.FC<{
  direction?: 'horizontal' | 'vertical';
  buckets: { count: number | null; start: number | null; end: number | null }[];
  onClick?: (min: number, max: number) => void;
  activeBucket?: { min: number; max: number };
}> = ({ buckets: _buckets, direction = 'vertical', onClick, activeBucket }) => {
  const buckets = _buckets.map((b) => ({
    count: Number(b.count),
    start: Number(b.start),
    end: Number(b.end),
  }));
  const max = Math.max(...buckets.map((b) => b.count));
  const maxBucketValue = round(Math.max(...buckets.map((b) => b.end)), 2);
  const minBucketValue = round(Math.min(...buckets.map((b) => b.start)), 2);
  const totalRows = buckets.reduce((acc, curr) => acc + curr.count, 0);

  return (
    <div
      className={classNames(
        'flex w-full h-full',
        direction === 'horizontal' ? 'flex-row items-center' : 'flex-col'
      )}
    >
      <div
        className={classNames(
          'flex flex-row h-full flex-grow rounded overflow-hidden items-end justify-between',
          direction === 'horizontal' ? '' : 'mb-1'
        )}
      >
        {buckets.map((bucket, i) => {
          const height = (bucket.count / max) * 100;
          const percent = (bucket.count / totalRows) * 100;
          return (
            <HistogramBar
              active={
                activeBucket?.min === bucket.start &&
                activeBucket.max === bucket.end
              }
              onClick={() =>
                onClick && onClick(round(bucket.start, 2), round(bucket.end, 2))
              }
              key={i}
              rows={bucket.count}
              height={height}
              percent={percent}
              category={`${round(bucket.start, 2)} - ${round(bucket.end, 2)}`}
            />
          );
        })}
      </div>
      <div className="flex flex-row justify-between">
        <Text.Caption
          className={classNames(
            'flex flew-row text-xs',
            direction === 'horizontal' ? 'ml-1' : 'mb-1'
          )}
        >
          {direction === 'vertical' && 'Min: '}
          {minBucketValue} - {direction === 'vertical' && 'Max: '}
          {maxBucketValue}
        </Text.Caption>
      </div>
    </div>
  );
};

type StatsProps = {
  type: Ty.AttributeType;
  categories: { count: number; value: string | null }[];
  buckets: { count: number; start: number; end: number }[];
  numDistinct: number;
  onClick?: (category: string) => void;
  active?: string[];
  horizontal?: boolean;
};

export const Categorical: React.FC<Omit<StatsProps, 'type' | 'buckets'>> = ({
  categories,
  numDistinct,
  onClick,
  active,
  horizontal = false,
}) => {
  const total =
    categories.reduce((acc, curr) => acc + curr.count, 0) + numDistinct;

  const unqiuePercent = (numDistinct / total) * 100;
  const categoryCountsTotal =
    categories.reduce((acc, curr) => acc + curr.count, 0) + numDistinct;

  const numCategories = categories.length + numDistinct;

  return (
    <div className="flex flex-col h-full w-full">
      <div
        className={classNames(
          'flex flex-row h-full rounded overflow-hidden',
          horizontal ? '' : 'mb-1'
        )}
      >
        {categories.map((category, i) => {
          const categoryPercent = (category.count / categoryCountsTotal) * 100;
          return (
            <Bar
              key={i}
              rows={category.count}
              color={
                category.value && active?.includes(category.value)
                  ? 'active'
                  : category.value
              }
              percent={categoryPercent}
              category={category.value}
              onClick={
                [CATEGORICAL_TAGS.discrete].includes(category.value ?? '')
                  ? undefined
                  : onClick
              }
            />
          );
        })}
        {numDistinct > 0 && (
          <Bar
            rows={numDistinct}
            color={'unique'}
            percent={unqiuePercent}
            category={'unique'}
          />
        )}
      </div>
      {!horizontal && (
        <Text.Caption
          className={classNames(
            'flex flew-row text-xs',
            horizontal ? '' : 'mb-1'
          )}
        >
          {numCategories} Categor{numCategories === 1 ? 'y' : 'ies'}
        </Text.Caption>
      )}
    </div>
  );
};

const Bar = ({
  percent,
  color,
  category,
  onClick,
  rows,
}: {
  rows: number;
  percent: number;
  color: string | null;
  category: string | null;
  onClick?: (category: string) => void;
}) => {
  const textCategory = category ?? 'null';
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  return (
    <Tooltip
      text={`${rows} rows (${round(percent, 2)}%) ${category} `}
      side="bottom"
    >
      <div
        onClick={() => onClick && onClick(textCategory)}
        ref={ref}
        className={classNames(
          'cursor-pointer h-full mr-[1px] flex items-center justify-center text-xs last:mr-0',
          valueToColor(color)
        )}
        style={{ width: `${Math.max(percent, 0.2)}%` }}
      >
        {width > textCategory.length * 6 && textCategory}
      </div>
    </Tooltip>
  );
};

const valueToColor = (value: string | null) => {
  switch (value) {
    case 'active':
      return COLOR_MAP['active'];
    case 'unique':
    case CATEGORICAL_TAGS.discrete:
      return COLOR_MAP['gray'];
    case null:
      return COLOR_MAP['warning'];
    default:
      return COLOR_MAP['category'];
  }
};
