import {
  Constant,
  Desc,
  FirstValueOver,
  Expression,
  Relation,
  Max,
  Min,
  RelationRef,
  Sum,
  SumOver,
  GroupedRelationRef,
} from '@cotera/era';

/**
 * These base tables are the foundation for the favorite products function
 * @param {Relation} Orders - Must include USER_ID (`customerIdColumn`), PRODUCT_ID, QUANTITY
 * @param {Relation} ActiveItems - PRODUCT_ID column only
 */
export type FavoriteProductsTables = {
  Orders: Relation;
  ActiveItems: Relation;
  ItemDetail: Relation;
};

/**
 * These parameters specify the customer ID, favorite product or product group, and quantity column in the FavoriteProducts Tables
 * @param customerIdColumn - Orders column name to identify customer IDs.
 * @param favoriteProductIdentifierColumn - ItemDetail column name to identify the favorite product or product group
 * @param favoriteProductNameColumn - ItemDetail column name to name the favorite product or product group
 * @param quantityMeasureColumn - Orders column name to measure the quantity of the favorite product or product group
 * @param dateColumn - Optional Orders column name with the date of the order (default `ORDER_DATE`)
 */
export type CustomerSpecifications = {
  customerIdColumn: (t: RelationRef) => Expression;
  favoriteProductIdentifierColumn: (t: RelationRef) => Expression;
  favoriteProductNameColumn: (t: RelationRef) => Expression;
  quantityMeasureColumn: (t: RelationRef) => Expression;
  dateColumn?: (t: RelationRef | GroupedRelationRef) => Expression;
};

export function userFavoriteProducts(params: {
  favoriteProductsTables: FavoriteProductsTables;
  customerSpecifications: CustomerSpecifications;
}): Relation {
  const {
    favoriteProductsTables: { Orders, ActiveItems, ItemDetail },
    customerSpecifications: {
      customerIdColumn,
      favoriteProductIdentifierColumn,
      favoriteProductNameColumn,
      quantityMeasureColumn,
      dateColumn = (t) => t.attr('ORDER_DATE'),
    },
  } = params;

  const FavoriteProducts = Orders.innerJoin(ActiveItems, (ord, item) => ({
    on: ord.attr('PRODUCT_ID').eq(item.attr('PRODUCT_ID')),
    select: {
      ...ord.star(),
      __COTERA_CUSTOMER_ID: customerIdColumn(ord),
    },
  }))
    .leftJoin(ItemDetail, (ord, item) => ({
      on: ord.attr('PRODUCT_ID').eq(item.attr('PRODUCT_ID')),
      select: {
        ...ord.star(),
        FAVORITE_ID: favoriteProductIdentifierColumn(item),
        FAVORITE_NAME: favoriteProductNameColumn(item),
        QUANTITY_MEASURE: quantityMeasureColumn(ord),
      },
    }))
    .groupBy((t) =>
      t.pick('__COTERA_CUSTOMER_ID', 'FAVORITE_ID', 'FAVORITE_NAME')
    )
    .select((t) => ({
      ...t.group(),
      MOST_RECENT_ORDER_DATE: Max(dateColumn(t)),
      QUANTITY: Sum(t.attr('QUANTITY_MEASURE')),
    }))
    .select((t) => {
      const orderBy = [
        Desc(t.attr('QUANTITY')),
        Desc(t.attr('MOST_RECENT_ORDER_DATE')),
      ];

      return {
        ...t.pick('__COTERA_CUSTOMER_ID'),
        FAVORITE_PRODUCT_QUANTITY: FirstValueOver(t.attr('QUANTITY'), {
          partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
          orderBy,
        }),
        FAVORITE_PRODUCT_ID: FirstValueOver(t.attr('FAVORITE_ID'), {
          partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
          orderBy,
        }),
        FAVORITE_PRODUCT_NAME: FirstValueOver(t.attr('FAVORITE_NAME'), {
          partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
          orderBy,
        }),
        PROPORTION: t.attr('QUANTITY').div(
          SumOver(t.attr('QUANTITY'), {
            partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
          })
        ),
      };
    })
    .groupBy((t) =>
      t.pick(
        '__COTERA_CUSTOMER_ID',
        'FAVORITE_PRODUCT_ID',
        'FAVORITE_PRODUCT_NAME',
        'FAVORITE_PRODUCT_QUANTITY'
      )
    )
    .select((t) => ({
      ...t.group(),
      P_MAX: Max(t.attr('PROPORTION')),
      P_MIN: Min(t.attr('PROPORTION')),
    }))
    .select((t) => ({
      ...t.pick(
        '__COTERA_CUSTOMER_ID',
        'FAVORITE_PRODUCT_ID',
        'FAVORITE_PRODUCT_NAME',
        'FAVORITE_PRODUCT_QUANTITY',
        'P_MAX',
        'P_MIN'
      ),
      CONSERVATIVE_SHOPPER: Constant(1)
        .sub(t.attr('P_MAX'))
        .mul(Constant(1).sub(t.attr('P_MIN')))
        .toThePowerOf(0.5)
        .lt(0.5),
    }));

  return FavoriteProducts;
}
