import hotToast, {
  Toaster as ReactHotToaster,
  resolveValue,
} from 'react-hot-toast';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

const doToast =
  (icon: JSX.Element) =>
  (header: string, body: string = '') => {
    hotToast(
      <>
        <p className="font-medium text-standard-text">{header}</p>
        <p className="mt-1 text-gray-500">{body}</p>
      </>,
      {
        icon,
      }
    );
  };

const ToastIcon: React.FC<{
  type: 'error' | 'warning' | 'success' | 'info';
}> = ({ type }) => {
  const props = (color: string = 'green') => ({
    className: `h-6 w-6 text-${color}-400`,
  });
  switch (type) {
    case 'error':
      return <XCircleIcon {...props('red')} />;
    case 'info':
      return <InformationCircleIcon {...props('grey')} />;
    case 'warning':
      return <ExclamationTriangleIcon {...props('yellow')} />;
    default:
      return <CheckCircleIcon {...props()} />;
  }
};

export const toast = {
  info: doToast(<ToastIcon type={'info'} />),
  error: doToast(<ToastIcon type={'error'} />),
  success: doToast(<ToastIcon type={'success'} />),
  warning: doToast(<ToastIcon type={'warning'} />),
};

export const Toaster: React.FC = () => (
  <ReactHotToaster
    position="bottom-right"
    toastOptions={{
      duration: 5000,
    }}
  >
    {(t) => (
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{t.icon}</div>

            <div className="ml-3 w-0 flex-1 pt-0.5 text-sm">
              {resolveValue(t.message, t)}
            </div>

            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded bg-white text-muted-text hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  hotToast.remove(t.id);
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </ReactHotToaster>
);
