import { M } from '..';
import { AST } from '../../ast';
import { Macro } from '../../macros';
import { Ty } from '../../ty';
import { TC, TyStackTrace } from '../../type-checker';
import { Expression } from '../expression';
import { Constant } from '../utilities';
import { MarkupShorthand } from './section';

export class DetailPage {
  private constructor(
    public readonly ty: Ty.ExtendedAttributeType,
    public readonly mu: AST.Mu
  ) {
    Object.freeze(this);
  }

  render(id: number | string): AST.Mu {
    return DetailPage.renderDetails(this.mu, id);
  }

  static renderDetails(mu: AST.Mu, id: string | number): AST.Mu {
    const tc = TC.checkMarkup(mu);

    if (tc instanceof TyStackTrace) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      throw tc.toError({ jsStackPointer: this.renderDetails });
    }

    const [targetScope, ...restOfScopes] = Object.entries(tc.vars);

    if (targetScope === undefined || restOfScopes.length > 0) {
      throw new Error(
        `Incorrect number of scopes found in macro, found ${[
          targetScope,
          ...restOfScopes,
        ]
          .map((s) => `"${s}"`)
          .join(', ')} but expected 1`
      );
    }

    const [scope, { exprs }] = targetScope;

    const idTy = exprs['id'];

    if (idTy === undefined) {
      throw new Error('Missing `id` key in markup scope');
    }

    const coerced = TC.implementsTy({ subject: idTy.type, req: 'float' })
      ? Number(id)
      : id;

    return Macro.callMuMacro(
      mu,
      { exprs: { id: Constant(coerced, { ty: idTy.type }).ast } },
      { scope }
    ).ast;
  }

  static create(
    ty: Ty.Shorthand,
    cb: (id: Expression) => MarkupShorthand
  ): DetailPage {
    const fullTy = Ty.shorthandToTy(ty);
    const macro = M.mu({ id: Constant(null, fullTy) }, ({ id }) => cb(id));
    return new this(fullTy, macro.body.ast);
  }
}

export const details = DetailPage.create.bind(DetailPage);
