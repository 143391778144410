import { UiComponent } from '../../types/ui-component';
import { ColorScheme, classNames } from '@cotera/client/app/components/utils';
import { Text } from '../text';
import { Theme } from '../../types/utils';
import React from 'react';

const Hr: React.FC<{
  className?: string;
  theme?: Theme;
}> = ({ className, theme = 'muted' }) => (
  <hr
    className={classNames(
      `h-[1px] w-full`,
      ColorScheme.background[theme],
      className
    )}
  />
);

type DividerProps = {
  theme?: Theme;
  caption?: string;
} & UiComponent;
export const Divider: React.FC<DividerProps> = ({
  theme = 'muted',
  className,
  caption,
}) => {
  if (caption) {
    return (
      <div className={classNames('flex flex-row items-center', className)}>
        <Hr theme={theme} />
        <Text.Caption className={classNames('mx-4 shrink-0')}>
          {caption}
        </Text.Caption>
        <Hr theme={theme} />
      </div>
    );
  }
  return <Hr className={className} theme={theme} />;
};
