import { Section } from '@cotera/client/app/components';
import { Text } from '@cotera/client/app/components/ui';
import {
  ChildrenProps,
  classNames,
  ColorScheme,
} from '@cotera/client/app/components/utils';
import { forwardRef } from 'react';

export const NodeCard = forwardRef<
  HTMLDivElement,
  ChildrenProps & { from?: string; name: string }
>(({ children, from, name }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(
        'flex flex-col border rounded mb-4 w-full',
        'relative'
      )}
    >
      <div className="flex items-center w-fit justify-between p-2 -mt-[14px] ml-4">
        {from && (
          <Text.Caption className="bg-white mr-2 px-1">
            <span className="text-xs">From:</span> {from}
          </Text.Caption>
        )}
        <Text.Span
          ref={ref}
          className={classNames(
            'bg-white text-xs w-fit outline-none rounded px-1',
            ColorScheme.text['muted']
          )}
        >
          {name}
        </Text.Span>
      </div>

      <Section className="w-[calc(100%-2em)]">{children}</Section>
    </div>
  );
});
