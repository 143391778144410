import _ from 'lodash';
import { AST } from '../ast';
import { SQL } from '../sql';
import { Ty } from '../ty';
import { TC, TyStackTrace } from '../type-checker';
import { CachePolicy, CacheStrategy } from './types';
import { ARTIFACT_SIZE, Relation } from '../builder';
import { Assert } from '@cotera/utilities';
import { splitFunctionalTransform } from './split-functional-transform';

export const cacheStrategy = (params: {
  readonly ir: AST.RelIR;
  readonly existingCache: {
    readonly [sqlHash: string]: { createdAt: Date; complete: boolean | null };
  };
  readonly policy: CachePolicy;
}): CacheStrategy => {
  const { source, next } = splitFunctionalTransform(params.ir);
  const strat = doCacheStrategy({ ...params, ir: source, topLevel: true });

  switch (strat.type) {
    case 'existing':
      return { ...strat, serve: (x: AST.RelIR) => next(strat.serve(x)) };
    case 'create':
      return { ...strat, serve: (x: AST.RelIR) => next(strat.serve(x)) };
    case 'functional':
      return { ...strat, relation: next(strat.relation) };
  }
};

const doCacheStrategy = (params: {
  ir: AST.RelIR;
  existingCache: {
    readonly [sqlHash: string]: { createdAt: Date; complete: boolean | null };
  };
  policy: CachePolicy;
  topLevel: boolean;
}): CacheStrategy => {
  const { ir, existingCache, policy } = params;
  const { t } = ir;
  const sqlHash = SQL.hashIR(ir);
  const cached = existingCache[sqlHash];

  if (cached && policy.isFresh({ artifactCreatedAt: cached.createdAt })) {
    if (params.topLevel || cached.complete === true) {
      return {
        type: 'existing',
        sqlHash,
        signature: Relation.wrap(ir).attributes,
        serve: (cached) =>
          sanityCheck(cached, Relation.wrap(ir).attributes, {
            replacingSqlHash: sqlHash,
          }),
      };
    }
  }

  switch (t) {
    case 'select':
    case 'aggregate': {
      const child = doCacheStrategy({
        ir: ir.sources.from,
        existingCache,
        policy,
        topLevel: false,
      });
      const { type } = child;
      switch (type) {
        case 'existing':
          return {
            ...child,
            serve: (source) => ({
              ...ir,
              sources: { from: child.serve(source) },
            }),
          };
        case 'functional':
          return {
            ...child,
            relation: { ...ir, sources: { from: child.relation } },
          };
        case 'create':
          return child.complete
            ? {
                ...child,
                serve: (source) => ({
                  ...ir,
                  sources: { from: child.serve(source) },
                }),
              }
            : createFromIR(ir, policy);
        default:
          return Assert.unreachable(type);
      }
    }
    case 'values':
    case 'file':
    case 'generate-series':
      return { type: 'functional', relation: ir };
    case 'join':
    case 'union':
    case 'table':
    case 'information-schema': {
      return createFromIR(ir, policy);
    }
    default:
      return Assert.unreachable(t);
  }
};

const sanityCheck = (
  ir: AST.RelIR,
  attrs: Record<string, Ty.ExtendedAttributeType>,
  opts: {
    replacingSqlHash: string;
  }
): AST.RelIR => {
  const reqs: { readonly [attr: string]: Ty.ExtendedAttributeType[] } =
    _.mapValues(attrs, (x) => [x]);

  const received = Relation.wrap(ir).attributes;
  const isSane = TC.implementsRel({ subject: received, reqs });

  if (isSane.isErr()) {
    const extendedInfo = `Wanted:\n${Object.entries(reqs).map(
      ([name, tys]) =>
        `  - "${name}" ${tys.map((ty) => Ty.displayTy(ty)).join(', ')}`
    )}\n\nGot:\n${Object.entries(received).map(
      ([name, ty]) => `  - "${name}" ${Ty.displayTy(ty)}`
    )}`;

    console.warn(
      `Invalid Cache Replacement: ${
        isSane.error.toStackTrace({}).headline
      }\n\nReplacing Hash: ${opts.replacingSqlHash}\n\n${extendedInfo}`
    );
  }

  return ir;
};

const createFromIR = (ir: AST.RelIR, policy: CachePolicy): CacheStrategy => {
  const maxRows = SQL.maxPossibleRows(ir);

  const tc = TC.checkRel(ir);

  Assert.assert(!(tc instanceof TyStackTrace));

  const complete = typeof maxRows === 'number' && maxRows < policy.artifactSize;

  return {
    type: 'create',
    relation: ir,
    serve: (cached) => {
      return sanityCheck(cached, Relation.wrap(ir).attributes, {
        replacingSqlHash: SQL.hashIR(ir),
      });
    },
    complete,
  };
};
