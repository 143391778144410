import { isNumber } from 'lodash';
import { Result, err, ok } from 'neverthrow';
import { StreamParser } from '@json2csv/plainjs';

export function classNames(...classes: (string | undefined)[]): string {
  return classes.filter(Boolean).join(' ');
}

export function getCurrencySymbol(
  currency: string
): Result<string, 'InvalidCurrency'> {
  if (!ISO_4217_CURRENCY_CODES.includes(currency.toUpperCase() as any)) {
    return err('InvalidCurrency');
  }

  const locale =
    navigator.languages !== undefined && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  try {
    return ok(
      (0)
        .toLocaleString(locale, {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()
    );
  } catch (e) {
    if (e instanceof Error && e.message.includes('Invalid currency code')) {
      return err('InvalidCurrency');
    }

    throw e;
  }
}

export const ISO_4217_CURRENCY_CODES = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BOV',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHE',
  'CHF',
  'CHW',
  'CLF',
  'CLP',
  'COP',
  'COU',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MXV',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'CNY',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'USN',
  'UYI',
  'UYU',
  'UYW',
  'UZS',
  'VED',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'XSU',
  'XTS',
  'XUA',
  'XXX',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];

export const jsonToCsv = async (json: any[]): Promise<string> => {
  return new Promise((resolve, reject) => {
    const opts = {};
    const asyncOpts = {};
    const parser = new StreamParser(opts, asyncOpts);

    if (!json.length) return resolve('');

    let csv = '';
    parser.onData = (chunk) => (csv += chunk.toString());
    parser.onEnd = () => resolve(csv);
    parser.onError = (err) => reject(err);

    json.forEach((row) => parser.pushLine(row));
    parser.end();
  });
};

export const downloadFile = (
  contents: string[],
  type: 'csv' | 'json',
  name?: string
) => {
  const file = new Blob(contents, { type: `text/${type}` });
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = `${name ?? Date.now()}.${type}`;
  document.body.appendChild(element);
  element.click();
};

const toUtc = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export class UTCDate extends Date {
  constructor(date: Date | string | number | null | undefined) {
    if (date === null || date === undefined) {
      super();
      return;
    }
    if (date instanceof Date) {
      super(toUtc(date));
      return;
    }
    if (typeof date === 'string') {
      if (!isNaN(Number(date))) {
        super(toUtc(new Date(Number(date))));
        return;
      }
      super(toUtc(new Date(Date.parse(date))));
      return;
    }
    if (isNumber(date)) {
      super(toUtc(new Date(Number(date))));
      return;
    }
  }
}

export const joinElements = (
  elements: React.ReactNode[],
  joiner: (i: number) => React.ReactNode
) => {
  return elements.reduce<React.ReactNode[]>((acc, el, i) => {
    if (i === 0) {
      return [el];
    }

    return [...acc, joiner(i), el];
  }, [] as React.ReactNode[]);
};
