import { Assert } from '@cotera/utilities';
import { Result, err, ok } from 'neverthrow';
import OpenAI from 'openai';
import { useEffect } from 'react';
import { Initializable } from './initializable';

type Serializeable = {
  toString(): string;
};

const FAILED_STATUES = [
  'failed',
  'expired',
  'cancelling',
  'cancelled',
  'requires_action',
];
const OTHER_STATUSES = ['queued', 'completed', 'in_progress'] as const;
const ALL_STATUSES = [...FAILED_STATUES, ...OTHER_STATUSES] as const;

const checkReadyOrWait = async (maxAttempts: number, cb: () => boolean) => {
  let attempts = 0;
  while (attempts < maxAttempts) {
    if (cb()) {
      return ok(true);
    }
    attempts++;
    await new Promise((res) => setTimeout(res, 2000));
  }

  return err(new Error('MaxAttempts'));
};

export class LLMClient<
  Response,
  Message extends string | Serializeable = string
> implements Initializable
{
  private thread: OpenAI.Beta.Threads.Thread | null = null;
  private assistant: OpenAI.Beta.Assistants.Assistant | null = null;
  private ticker: NodeJS.Timeout;
  private locked: boolean = false;
  private readonly sentMessages: string[] = [];
  private didInit: boolean = false;

  constructor(
    private readonly openai: OpenAI,
    private readonly assistantId: string,
    private readonly parse: (message: string) => Response = (message) =>
      message as unknown as Response
  ) {}

  async init(context: string) {
    if (this.didInit) {
      return;
    }

    const { thread } = await this.getOrCreateAssistant();
    if (context.length > 0 && !this.sentMessages.includes(context)) {
      await this.openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: context.toString(),
      });
      this.sentMessages.push(context);
    }
    this.didInit = true;
  }

  async send(message: Message): Promise<Result<Response, Error>> {
    const res = await checkReadyOrWait(10, () => this.canSend());

    if (res.isErr()) {
      return err(new Error('CantSend'));
    }

    this.locked = true;
    const { thread, assistant } = await this.getOrCreateAssistant();

    await this.openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: message.toString(),
    });

    const run = await this.openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });

    try {
      const result = await this.checkRun(run);
      this.sentMessages.push(message.toString());
      return ok(result);
    } catch (e) {
      return err(e as Error);
    } finally {
      this.locked = false;
    }
  }

  private async checkRun(run: OpenAI.Beta.Threads.Run) {
    return new Promise<Response>((resolve, reject) => {
      const check = () => {
        this.ticker = setTimeout(async () => {
          const result = await this.checkResponse(run.id);
          if (result.status === 'completed') {
            resolve(this.parse(result.messages.join(' ')));
          } else if (FAILED_STATUES.includes(result.status)) {
            reject(result.status);
          } else {
            check();
          }
        }, 200);
      };
      check();
    });
  }

  stop() {
    clearTimeout(this.ticker);
  }

  private canSend() {
    return !this.locked;
  }

  private async getOrCreateAssistant() {
    const [thread, assistant] = await Promise.all([
      !this.thread
        ? this.openai.beta.threads.create({})
        : Promise.resolve(this.thread),
      !this.assistant
        ? this.openai.beta.assistants.retrieve(this.assistantId)
        : Promise.resolve(this.assistant),
    ]);

    this.thread = thread;
    this.assistant = assistant;

    return { thread: this.thread, assistant: this.assistant };
  }

  private async checkResponse(
    runId: string
  ): Promise<{ status: (typeof ALL_STATUSES)[number]; messages: string[] }> {
    const { thread } = await this.getOrCreateAssistant();
    const failedStatuses = ['failed', 'expired', 'cancelling', 'cancelled'];

    const runStatus = await this.openai.beta.threads.runs.retrieve(
      thread.id,
      runId
    );

    if (failedStatuses.includes(runStatus.status)) {
      return {
        status: runStatus.status,
        messages: [],
      };
    }

    if (runStatus.status === 'completed') {
      const messages = await this.openai.beta.threads.messages.list(thread.id);

      return {
        status: runStatus.status,
        messages:
          messages.data.at(0)?.content.map((x) => {
            Assert.assert(x.type === 'text');

            return x.text.value;
          }) ?? [],
      };
    }

    return {
      status: runStatus.status,
      messages: [],
    };
  }
}

export const useLLMClient = <
  Response,
  Message extends string | Serializeable = string,
  T extends LLMClient<Response, Message> = LLMClient<Response, Message>
>(
  client: T
) => {
  useEffect(() => {
    return () => client.stop();
  }, [client]);

  return { client };
};
