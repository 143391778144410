import { Text, Divider, Animated } from '@cotera/client/app/components/ui';
import { AppStoreIllustration } from './app-store.image';
import { getRandomItems } from './utils';
import { H1, H4 } from '@cotera/client/app/components/ui/typography';
import { APPS } from '../../components/app/apps';
import { CopilotIcon } from '@cotera/client/app/components/app/icons/copilot';

export const AppsPreview = () => {
  return (
    <div className="md:fixed sm:relative top-0 right-0 flex flex-col md:w-1/3 sm:w-full border-l border-divider h-screen pt-[5%] items-center justify-between">
      <div className="flex flex-col w-full items-center">
        <H1 className="mb-2 text-center">A Radical Data Experience</H1>
        <div className="flex items-center justify-center mb-8">
          <Text.Caption className="text-xs mr-2">
            Powered by Cotera Copilot
          </Text.Caption>
          <CopilotIcon className="w-8 h-8 self-end" theme="dark" />
        </div>
      </div>
      <div className="flex flex-col items-center mx-20">
        <Divider
          className="w-full mb-8"
          theme="regular"
          caption="Suggested Applications"
        />
        <ul className="flex flex-col justify-start w-full overflow-x-hidden">
          {getRandomItems(APPS).map((app, i) => {
            return (
              <Animated
                animationIn="fadeInRight"
                animationOut="fadeOut"
                animationInDelay={i * 100}
                animationInDuration={600}
                animationOutDuration={600}
                isVisible={true}
                key={app.key}
              >
                <li key={app.key} className="mb-8">
                  <div className="flex items-center">
                    <app.icon className="w-8 h-8 mr-6 flex-shrink-0 text-standard-text" />
                    <div className="flex flex-col">
                      <H4 className="">{app.label}</H4>
                      <Text.P className="text-sm">{app.excerpt}</Text.P>
                    </div>
                  </div>
                </li>
              </Animated>
            );
          })}
        </ul>
        <Divider className="w-full mt-2 mb-8" />
      </div>
      <AppStoreIllustration className="w-[200px] h-[200px] stroke-slate-800" />
    </div>
  );
};
