import { useSnapshotEventStream } from '@cotera/client/app/hooks';
import { Button } from '@cotera/client/app/components/ui';

export const SnapshotSinkButton: React.FC<{
  streamId: string;
}> = ({ streamId }) => {
  const snapshot = useSnapshotEventStream();

  return (
    <Button
      icon="camera"
      text={`Snapshot`}
      theme="secondary"
      onClick={() => snapshot.mutateAsync({ eventStreamId: streamId })}
    />
  );
};
