import { err, Result } from 'neverthrow';
import { z } from 'zod';
import { parseZodToResult } from './utils.js';
import _ from 'lodash';
export * from './method.js';
import type { RouteDefinition, Method } from './method.js';

const { uniqBy } = _;

const mergeRequirements = (...scopes: readonly (readonly Scope[])[]): Scope[] =>
  uniqBy(scopes.flat(), (scope) => scope.join(':')).sort();

export type RouteDefinitions<SubRoutes extends RouteDefinitions = {}> = Record<
  string,
  RouteDefinition | Contract<SubRoutes>
>;

export type ApiError = {
  readonly errorType:
    | 'Unauthorized'
    | 'NotImplemented'
    | 'ServerError'
    | 'InvalidInput'
    | 'Forbidden';
  readonly message?: string;
};

export type RouteClient<
  RouteDef extends RouteDefinition,
  ClientSpecificErrors = never,
  Options = undefined
> = (
  input: z.infer<RouteDef['params']>,
  options?: Options
) => Promise<
  Result<
    z.infer<RouteDef['output']>,
    | { t: 'client'; err: ClientSpecificErrors }
    | { t: 'server'; err: ApiError }
    | { t: 'application'; err: z.infer<RouteDef['errors']> }
  >
>;

export type RouteProvider<RouteDef extends RouteDefinition, Context = {}> = (
  input: z.infer<RouteDef['params']>,
  context: Context
) => Promise<Result<z.infer<RouteDef['output']>, z.infer<RouteDef['errors']>>>;

export type ApiProvider<T, Context = {}> = T extends Contract<infer Routes>
  ? {
      [Route in keyof Routes]: Routes[Route] extends Contract<infer SubRoutes>
        ? ApiProvider<Contract<SubRoutes>, Context>
        : Routes[Route] extends RouteDefinition
        ? RouteProvider<Routes[Route], Context>
        : never;
    }
  : never;

type ApiImplmentation<Context> = Record<
  string,
  {
    method: Method;
    requirements: Scope[];
    implementation: (
      params: unknown,
      context: Context,
      can: Ability
    ) => Promise<
      Result<
        unknown,
        { t: 'server'; err: ApiError } | { t: 'application'; err: any }
      >
    >;
  }
>;

export type ApiClient<
  T extends Contract<any>,
  ClientSpecificErrors = never,
  Options = undefined
> = T extends Contract<infer Routes>
  ? {
      [Route in keyof Routes]: Routes[Route] extends Contract<infer Subroutes>
        ? ApiClient<Contract<Subroutes>, ClientSpecificErrors, Options>
        : Routes[Route] extends RouteDefinition
        ? RouteClient<Routes[Route], ClientSpecificErrors, Options>
        : never;
    }
  : never;

export type AltRouteClient<
  RouteDef extends RouteDefinition,
  Output,
  AdditionalArgs extends any[] = []
> = (input: z.infer<RouteDef['params']>, ...args: AdditionalArgs) => Output;

export type ApiClientCallback<
  AdditionalErrors = never,
  ConfigVal = undefined,
  Options = undefined
> = <P extends z.ZodTypeAny, O extends z.ZodTypeAny, E extends z.ZodTypeAny>(
  route: string[],
  method: Method,
  schemas: {
    params: P;
    output: O;
    errors: E;
  },
  config: ConfigVal
) => (
  params: z.infer<P>,
  opts: Options
) => Promise<
  Result<
    z.infer<O>,
    { t: 'client'; err: AdditionalErrors } | { t: 'server'; err: z.infer<E> }
  >
>;

export type AltApiClient<
  T extends Contract<any>,
  Output,
  AdditionalArgs extends any[] = []
> = T extends Contract<infer Routes>
  ? {
      [Route in keyof Routes]: Routes[Route] extends Contract<infer Subroutes>
        ? AltApiClient<Contract<Subroutes>, Output, AdditionalArgs>
        : Routes[Route] extends RouteDefinition
        ? AltRouteClient<Routes[Route], Output, AdditionalArgs>
        : never;
    }
  : never;

export type AltApiClientCallback<
  Output,
  ConfigVal = undefined,
  AdditionalArgs extends any[] = []
> = <P extends z.ZodTypeAny>(
  route: string[],
  method: Method,
  schemas: {
    params: P;
  },
  config: ConfigVal
) => (params: z.infer<P>, ...args: AdditionalArgs) => Output;

export type ContractConfig<
  T extends Contract<any>,
  Config
> = T extends Contract<infer Routes>
  ? {
      [Route in keyof Routes]: Routes[Route] extends Contract<infer Subroutes>
        ? ContractConfig<Contract<Subroutes>, Config>
        : Routes[Route] extends RouteDefinition
        ? Config
        : never;
    }
  : never;

export type Scope = [action: string, subject: string];

export interface Ability {
  can: (scope: Scope) => boolean;
}

export class Contract<Routes extends RouteDefinitions> {
  private constructor(
    readonly routes: Routes,
    readonly requirements: Scope[]
  ) {}

  static new<R extends RouteDefinitions>(
    routes: R,
    opts?: { mustBeAbleTo?: Scope[] }
  ): Contract<R> {
    return new Contract(routes, opts?.mustBeAbleTo ?? []);
  }

  public withImplAndContext<Ctx>(
    impl: ApiProvider<Contract<Routes>, Ctx>,
    ctx: Ctx,
    can: Ability
  ): ApiClient<Contract<Routes>> {
    const server = this.attachImplementation(impl);
    return this.makeApiClient((route, _method, _schemas): any => {
      return (params: any) => {
        return server[route.join('/')]!.implementation(params, ctx, can);
      };
    });
  }

  makeAltApiClient<
    Output,
    ConfigVal = undefined,
    AdditionalArgs extends any[] = []
  >(
    callback: AltApiClientCallback<Output, ConfigVal, AdditionalArgs>,
    options?: {
      prefix?: string[];
      config?: ContractConfig<Contract<Routes>, ConfigVal>;
    }
  ): AltApiClient<Contract<Routes>, Output, AdditionalArgs> {
    // This is actual the same impl as makeApiClient but type wise it's not.
    // makeApiClient is _also_ making guarantees that typescript cant check
    // (see lying 😆)... so this isn't any worse
    return this.makeApiClient(callback as any, options) as any;
  }

  makeApiClient<
    ClientSpecificErrors,
    ConfigVal = undefined,
    Options = undefined
  >(
    callback: ApiClientCallback<ClientSpecificErrors, ConfigVal, Options>,
    options?: {
      prefix?: string[];
      config?: ContractConfig<Contract<Routes>, ConfigVal>;
    }
  ): ApiClient<Contract<Routes>, ClientSpecificErrors, Options> {
    const client = Object.entries(this.routes).map(([route, def]) => {
      const path = [...(options?.prefix ?? []), route];
      const config = options?.config?.[route] as any;
      if (def instanceof Contract) {
        return [route, def.makeApiClient(callback, { prefix: path, config })];
      } else {
        const { method, params, output, errors } = def;
        const config = options?.config?.[route] as any;
        return [
          route,
          callback(path, method, { params, output, errors }, config),
        ];
      }
    });

    return Object.fromEntries(client) as ApiClient<
      Contract<Routes>,
      ClientSpecificErrors,
      Options
    >;
  }

  private rawServer<Context>(
    implementation: ApiProvider<Contract<Routes>, Context>
  ): ApiImplmentation<Context> {
    const server: [
      string,
      { method: Method; requirements: Scope[]; implementation: Function }
    ][] = Object.entries(this.routes).flatMap(([route, def]) => {
      if (def instanceof Contract) {
        const subServer = def.rawServer(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          implementation[route]! as any
        );

        return Object.entries(subServer).map(([suffix, impl]) => {
          const subRoute = `${route}/${suffix}`;
          const requirements = mergeRequirements(
            impl.requirements,
            def.requirements,
            this.requirements
          );
          return [subRoute, { ...impl, requirements }];
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const impl = implementation[route] as any;
        const wrappedImpl = (params: unknown, context: Context) => {
          const inputParamsResult = parseZodToResult(def.params, params);

          if (inputParamsResult.isErr()) {
            return err({
              t: 'server',
              err: {
                errorType: 'InvalidInput',
                info: inputParamsResult.error.format(),
              },
            });
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return impl(inputParamsResult.value as any, context).then(
            (res: any) => res.mapErr((err: any) => ({ t: 'application', err }))
          );
        };
        return [
          [
            route,
            {
              method: def.method,
              implementation: wrappedImpl,
              requirements: mergeRequirements(
                def.mustBeAbleTo ?? [],
                this.requirements
              ),
            },
          ],
        ] as any;
      }
    });

    return Object.fromEntries(server) as ApiImplmentation<Context>;
  }

  attachImplementation<Context>(
    implementation: ApiProvider<Contract<Routes>, Context>
  ): ApiImplmentation<Context> {
    const server = this.rawServer(implementation);
    const abilityCheckedSever = Object.entries(server).map(
      ([route, { implementation, requirements, ...rest }]) => {
        const abilityCheckedImpl = async (
          params: unknown,
          context: Context,
          ability: Ability
        ) => {
          const missingScopes = requirements.filter((req) => !ability.can(req));

          if (missingScopes.length > 0) {
            const error: ApiError = {
              errorType: 'Forbidden',
              message: `missing scopes ${missingScopes
                .map(([action, subject]) => `"${subject}:${action}"`)
                .join(',')}`,
            };
            return err({ t: 'server', err: error });
          }

          return implementation(params, context, ability);
        };

        return [
          route,
          { implementation: abilityCheckedImpl, requirements, ...rest },
        ];
      }
    );
    return Object.fromEntries(abilityCheckedSever) as ApiImplmentation<Context>;
  }
}
