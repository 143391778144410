import {
  useMutation,
  useQueryClient,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useAppData, useTenantedClient, useWhoami } from '../stores/org';
import { queryKeys } from './query-cache-keys';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { TC } from '@cotera/era';
import { EntityColumnInfo, ExpressionColumnSchema } from '@cotera/api';

export const useIdeConfig = () => {
  const orgId = useWhoami((s) => s.org.id);
  const client = useTenantedClient();
  return useSuspenseQuery({
    queryKey: queryKeys.ideConfig({ orgId }),
    queryFn: async () => Assert.assertOk(await client.config.config({})),
  });
};

export const useUpsertErqQLCol = (x: {
  entityId: string;
  onSuccess: () => void | Promise<void>;
}) => {
  const orgId = useWhoami((x) => x.org.id);
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: {
      data: ExpressionColumnSchema;
      columnName: string;
    }) =>
      client.warehouse.entityColumns.upsertEraQLColumn({
        ...params,
        entityId: x.entityId,
      }),
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: [orgId, 'entity', x.entityId],
      });
      await queryClient.invalidateQueries({
        queryKey: [orgId, 'entities'],
      });
      if (res.isOk()) {
        return x.onSuccess();
      }
    },
  });
};

export const useCreateEntity = ({
  onSuccess,
}: {
  onSuccess: (entity: { name: string; uuid: string }) => void | Promise<void>;
}) => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: client.config.createEntityDefinition,
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.ideConfig({ orgId }),
      });

      if (res.isOk()) {
        await onSuccess(res.value);
      }
    },
  });
};

export const useEntity = ({ entityName }: { entityName: string }) => {
  const {
    data: { entities },
  } = useIdeConfig();
  const definitions = useAppData((s) => s.skeleton.definitions);
  const matchedEntity = entities.find((x) => x.name === entityName)!;

  Assert.assert(matchedEntity !== undefined, `Entity ${entityName} not found`);

  return {
    ...matchedEntity,
    definitions: Object.fromEntries(
      Object.entries(definitions).filter(([_, def]) =>
        Object.values(def.attributes).some((attr) =>
          TC.implementsTy({ subject: attr, req: matchedEntity.idType })
        )
      )
    ),
  };
};

export const useEntityColumns = ({
  entityId,
}: {
  entityId: string;
}): UseSuspenseQueryResult<Record<string, EntityColumnInfo>> => {
  const orgId = useWhoami((x) => x.org.id);
  const client = useTenantedClient();

  return useSuspenseQuery<Record<string, EntityColumnInfo>>({
    queryFn: async (): Promise<Record<string, EntityColumnInfo>> =>
      Assert.assertOk(
        await client.warehouse.entityColumns.columnsForEntity({ entityId })
      ),
    queryKey: queryKeys.entity.columns({ orgId, entityId }),
  });
};

export const useLegacyUuds = ({ entityId }: { entityId: string }) => {
  const orgId = useWhoami((x) => x.org.id);
  const client = useTenantedClient();

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(await client.udds.get({ entityId })).udds,
    queryKey: [orgId, 'entries', entityId, 'udds'],
  });
};
