import { AST } from '@cotera/era';
import { RenderSliceBuilder } from './slice-builder';
import { RenderFilePicker } from './render-file-picker';
import { RenderSemanticSearch } from './semantic-search';

export const RenderRelControls: React.FC<{
  section: AST._RelVarControlsV2;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  switch (section.config.t) {
    case 'slice-builder':
      return <RenderSliceBuilder section={section} inBlock={inBlock} />;
    case 'file-picker':
      return <RenderFilePicker section={section} inBlock={inBlock} />;
    case 'defintion-picker':
    case 'semantic-search':
      return <RenderSemanticSearch section={section} inBlock={inBlock} />;
    case 'editable-table':
      throw new Error(`TODO: rel-controls ${section.config.t}`);
  }
};
