export const STRING_OPERATORS = [
  'one-of',
  'equals',
  'not equals',
  'contains',
  'does not contain',
  'starts with',
  'ends with',
] as const;
export const NUMERIC_OPERATORS = [
  'equals',
  'not equals',
  'greater than',
  'less than',
  'between',
] as const;
export const DATE_OPERATORS = [
  'between',
  'before',
  'after',
  'equals',
  'not equals',
] as const;
export const BOOLEAN_OPERATORS = ['equals', 'not equals'] as const;

export const OPERATORS = [
  ...STRING_OPERATORS,
  ...NUMERIC_OPERATORS,
  ...DATE_OPERATORS,
  ...BOOLEAN_OPERATORS,
] as const;

export type Operator = (typeof OPERATORS)[number];
