import { And, CountDistinct, Expression, Min, Relation } from '@cotera/era';

export function NextProductFlow(params: {
  Orders: Relation;
  productId: string | Expression;
  startDate: Date | Expression;
}): Relation {
  const { Orders, productId, startDate } = params;

  const ProductCustomers = Orders.where((t) =>
    t.attr('PRODUCT_ID').cast('string').eq(productId)
  )
    .groupBy((t) => t.pick('__COTERA_CUSTOMER_ID'))
    .select((t) => ({
      ...t.group(),
      FIRST_ORDER_DATE: Min(t.attr('ORDER_DATE')),
    }))
    .where((t) => t.attr('FIRST_ORDER_DATE').gte(startDate));

  const NextProductOrders = Orders.innerJoin(ProductCustomers, (ord, cust) => ({
    on: And(
      ord.attr('__COTERA_CUSTOMER_ID').eq(cust.attr('__COTERA_CUSTOMER_ID')),
      cust.attr('FIRST_ORDER_DATE').lt(ord.attr('ORDER_DATE'))
    ),
    select: {
      ...cust.star(),
      ...ord.star(),
    },
  }))
    .groupBy((t) => t.pick('PRODUCT_ID'))
    .select((t) => ({
      ...t.group(),
      KEY_PRODUCT_ID: productId,
      CUSTOMER_COUNT: CountDistinct(t.attr('__COTERA_CUSTOMER_ID')),
    }));

  return NextProductOrders;
}

export function PreviousProductFlow(params: {
  Orders: Relation;
  productId: string | Expression;
  startDate: Date | Expression;
}): Relation {
  const { Orders, productId, startDate } = params;

  const ProductCustomers = Orders.where((t) =>
    t.attr('PRODUCT_ID').cast('string').eq(productId)
  )
    .groupBy((t) => t.pick('__COTERA_CUSTOMER_ID'))
    .select((t) => ({
      ...t.group(),
      FIRST_ORDER_DATE: Min(t.attr('ORDER_DATE')),
    }))
    .where((t) => t.attr('FIRST_ORDER_DATE').gte(startDate));

  const PreviousProductOrders = Orders.innerJoin(
    ProductCustomers,
    (ord, cust) => ({
      on: And(
        ord.attr('__COTERA_CUSTOMER_ID').eq(cust.attr('__COTERA_CUSTOMER_ID')),
        cust.attr('FIRST_ORDER_DATE').gt(ord.attr('ORDER_DATE'))
      ),
      select: {
        ...cust.star(),
        ...ord.star(),
      },
    })
  )
    .groupBy((t) => t.pick('PRODUCT_ID'))
    .select((t) => ({
      ...t.group(),
      KEY_PRODUCT_ID: productId,
      CUSTOMER_COUNT: CountDistinct(t.attr('__COTERA_CUSTOMER_ID')),
    }));

  return PreviousProductOrders;
}
