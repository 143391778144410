import {
  Title,
  Divider,
  Loading,
  Center,
  Icon,
  Button,
  Text,
  toast,
  Tooltip,
} from '@cotera/client/app/components/ui';
import { ApplyFn } from '../new-column';
import { Inputs } from '@cotera/client/app/components/forms';
import { mapValues, startCase } from 'lodash';
import { AST, EraQL, Expression, Relation, Ty } from '@cotera/era';
import { Suspense, useRef, useState } from 'react';
import { useEntity } from '@cotera/client/app/pages/entities/hooks';
import { ErrorBoundary } from 'react-error-boundary';
import {
  DisplayError,
  Hint,
  SideModal,
  TextHints,
  useTextHinter,
} from '@cotera/client/app/components/app';
import { Assert } from '@cotera/utilities';
import { useUpsertErqQLCol } from '@cotera/client/app/hooks/entities';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import {
  useSubscribe,
  Watchable,
  WatchableViewModel,
} from '@cotera/client/app/etc';
import {
  Accordion,
  useAccordion,
} from '@cotera/client/app/components/headless';
import { useArtifactQuery } from '@cotera/client/app/etc/data/duckdb';
import { iconForTy } from '../../../app/era';
import { generateExamplesForCategories } from './examples';
import { Entity } from '@cotera/api';
import {
  getValidOperatorsForType,
  handleOptionClick,
  useHints,
} from '../hints';
import { parseExprResult } from '../utils';
import { DataPreview } from '../components/data-preview';
import { useCompletionMutation } from '@cotera/client/app/hooks/use-completion';
import { NewColumnAction } from '../column-action.type';
import { VALID_ENTITY_COLUMN_NAME_REGEX } from '@cotera/sdk/core';
import { ColumnName } from '../components/column-name';
import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';

class ViewModel extends Watchable {
  constructor(
    readonly entity: Entity,
    private _columnName: string = '',
    private relVm: WatchableViewModel<{
      rel: Relation;
    }>
  ) {
    super();
  }

  get columnName() {
    return this._columnName;
  }

  get errors() {
    if (this.columnName.length === 0) {
      return {
        columnName: 'Column name is required',
      };
    }
    if (!VALID_ENTITY_COLUMN_NAME_REGEX.test(this.columnName)) {
      return {
        columnName: `Column name must match ${VALID_ENTITY_COLUMN_NAME_REGEX}`,
      };
    }
    if (this.entity.columns[this.columnName]) {
      return {
        columnName: 'Column name already taken. Column names must be unique',
      };
    }

    return {
      columnName:
        this.columnName.length === 0 ? 'Column name is required' : undefined,
    };
  }

  get rel() {
    return this.relVm.rel;
  }

  hasErrors() {
    return Object.values(this.errors).some((v) => v !== undefined);
  }

  setColumnName(columnName: string) {
    this._columnName = columnName;
    this.notifySubscribers();
  }

  clear() {
    this._columnName = '';
    this.notifySubscribers();
  }
}

const Container: React.FC<{
  open: boolean;
  onClose: () => void;
  vm: WatchableViewModel<{
    rel: Relation;
  }>;
  additionalProps: {
    entityName: string;
  };
  onApply: ApplyFn;
}> = ({
  open,
  onClose,
  additionalProps: { entityName },
  vm: relVm,
  onApply,
}) => {
  const entity = useEntity(entityName);
  Assert.assert(entity !== undefined);

  const vm = useDeepMemo(() => new ViewModel(entity, '', relVm), []);
  return (
    <SideModal open={open} onClose={onClose}>
      <ErrorBoundary
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <View
            vm={vm}
            entityName={entityName}
            onApply={onApply}
            onClose={onClose}
          />
        </Suspense>
      </ErrorBoundary>
    </SideModal>
  );
};

const ApplyButton: React.FC<{
  vm: ViewModel;
  onApply: ApplyFn;
  onClose: () => void;
  eraql: string;
  parseRes: ReturnType<typeof parseExprResult>;
}> = ({ vm, onApply, onClose, parseRes, eraql }) => {
  const hasErrors = useSubscribe(vm, (s) => s.hasErrors());
  const errors = useSubscribe(
    vm,
    (s) => s.errors,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const columnName = useSubscribe(vm, (s) => s.columnName);

  const upsert = useUpsertErqQLCol({
    entityId: vm.entity.id,
    onSuccess: () => {},
  });

  return (
    <Tooltip
      text={
        hasErrors
          ? Object.values(errors)
              .filter((e) => e !== undefined)
              .join('\n')
          : 'Apply your case statement'
      }
      side="left"
    >
      <Button
        disabled={hasErrors}
        theme="secondary"
        text="Apply"
        hint={{
          display: `Ctrl + a`,
          targetKey: (e) => e.ctrlKey && e.key === 'a',
        }}
        onClick={async () => {
          if (parseRes.isOk() && columnName.length > 0) {
            onApply({
              t: 'eraql',
              value: Expression.fromAst(parseRes.value.ast),
              column: columnName,
            });
            onClose();
            void upsert
              .mutateAsync({
                data: {
                  eraql,
                  t: 'eraql',
                },
                columnName,
              })
              .then(() => {
                toast.success('Column configuration saved');
              })
              .catch(() => {
                toast.error('Failed to save column configuration');
              });
          }
        }}
      />
    </Tooltip>
  );
};

const View: React.FC<{
  entityName: string;
  vm: ViewModel;
  onApply: ApplyFn;
  onClose: () => void;
}> = ({ vm, onApply, onClose }) => {
  const [eraql, setEraql] = useState<string>('');

  const parseRes = parseExprResult(
    EraQL.parseExpr(eraql, {
      attributes: mapValues(vm.entity.columns, (x) => x.type),
    })
  );

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const hints = useHints(
    vm.entity,
    parseRes.isOk() ? getValidOperatorsForType(parseRes.value.tc.ty) : [],
    inputRef,
    setEraql
  );

  const handleChange = (
    currentValue: string,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEraql(currentValue);
    hints.handleChange(currentValue, e);
  };

  return (
    <div className="p-4 w-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <Title type="title" subtitle="Write an EraQL expression">
          Formula
        </Title>
        <ApplyButton
          vm={vm}
          onApply={onApply}
          onClose={onClose}
          eraql={eraql}
          parseRes={parseRes}
        />
      </div>
      <Divider className="mb-4" />
      <ColumnName vm={vm} className="mb-4" />
      <Divider caption="use Copilot to write your formula" className="" />
      <div className="mt-4 flex flex-col">
        <Suspense
          fallback={
            <div className="flex justify-center">
              <Loading.Shimmer className="h-[150px] w-full" />
            </div>
          }
        >
          <ErrorBoundary
            fallbackRender={({ error }) => <DisplayError error={error} />}
          >
            <CopilotExpression
              entity={vm.entity}
              vm={vm}
              onFormula={setEraql}
            />
          </ErrorBoundary>
        </Suspense>
      </div>
      <Divider caption="or write your formula here" className="" />
      <div className="mt-4 flex flex-col relative">
        <Inputs.TextArea
          ref={inputRef}
          className="min-h-[100px] mb-4 font-mono"
          label="Formula"
          placeholder="Enter an EraQL expression"
          value={eraql}
          onChange={handleChange}
        />
        <TypeHints />
        {eraql.length > 0 && parseRes.isErr() && (
          <div
            className={classNames(
              'p-2 rounded mt-4 text-sm border',
              ColorScheme.background.error,
              ColorScheme.text.error,
              ColorScheme.border.error
            )}
          >
            {startCase(parseRes.error.t)}: {parseRes.error.msg}
          </div>
        )}
        <TextHints
          hints={hints.columns.options}
          anchorRef={inputRef}
          show={hints.columns.showOptions}
          onSelect={(v) =>
            hints.handleOptionClick(
              {
                value: v,
                trigger: '"',
              },
              eraql
            )
          }
        />
        <TextHints
          hints={hints.operators.options}
          anchorRef={inputRef}
          show={hints.operators.showOptions}
          onSelect={(v) =>
            hints.handleOptionClick(
              {
                value: v,
                trigger: '.',
              },
              eraql
            )
          }
        />
        <TextHints
          hints={hints.functions.options}
          anchorRef={inputRef}
          show={hints.functions.showOptions}
          onSelect={(v) =>
            hints.handleOptionClick(
              {
                value: v,
                trigger: '|>',
              },
              eraql
            )
          }
        />
      </div>
      <Divider className="mb-4 mt-4" />
      <Title type="section" className="mb-4">
        Sample Output
      </Title>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <ErrorBoundary
          fallbackRender={({ error }) => <DisplayError error={error} />}
        >
          {parseRes.isOk() && (
            <SampleOutput
              vm={vm}
              expr={parseRes.value.ast}
              sourceColumns={parseRes.value.tc.attrReqs.from ?? {}}
            />
          )}
        </ErrorBoundary>
      </Suspense>
      <Divider className="mb-4 mt-4" />
      <Title type="section" className="mb-4">
        Example Formulas
      </Title>
      <ErrorBoundary
        resetKeys={[eraql]}
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <Examples vm={vm} entity={vm.entity} onSelect={(e) => setEraql(e)} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const useCompletion = (props: {
  examples: Record<
    string,
    {
      example: string;
      output: string;
    }[]
  >;
  prompt: string;
}) => {
  return useCompletionMutation({
    messages: [
      {
        role: 'system',
        content: `
            Your job is to write a formula in ERA-QL that will be used to calculate a new column in the table.

            Examples for how the formulas are constructed are as follows:

            ONLY return the formula.

            ${Object.entries(props.examples)
              .map(([title, examples]) => {
                return `
                ${title}:
                ${examples.map(({ example }) => `${example}`).join('\n')}
              `;
              })
              .join('\n')}
          `,
      },
      {
        role: 'user',
        content: props.prompt,
      },
    ],
  });
};

const TypeHints = () => (
  <Text.Caption>
    Type <Hint theme="primary">"</Hint> to select a column,{' '}
    <Hint theme="primary">.</Hint> to select an operator
  </Text.Caption>
);

const CopilotExpression: React.FC<{
  entity: Entity;
  vm: WatchableViewModel<{
    rel: Relation;
  }>;
  onFormula: (formula: string) => void;
}> = ({ vm, entity, onFormula }) => {
  const examples = useExamples(entity, vm);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);
  const completor = useCompletion({
    examples,
    prompt: value,
  });
  const [loading, setLoading] = useState(false);
  const {
    options,
    showOptions,
    handleChange: hintChange,
    close,
  } = useTextHinter(
    Object.entries(entity.columns).map(([attr, ty]) => ({
      value: attr,
      icon: iconForTy(ty.type) ?? undefined,
    })),
    '"'
  );

  const handleChange = (
    currentValue: string,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue(currentValue);
    const cursorPosition = e.target.selectionStart ?? 0;
    hintChange(currentValue, cursorPosition);
  };

  const run = async () => {
    setLoading(true);
    const result = await completor.mutateAsync();

    if (result.isOk()) {
      onFormula(result.value.completion);
    } else {
      setError(result.error.err.errorType);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col">
      <Inputs.TextArea
        ref={ref}
        className="min-h-[150px] mb-4"
        label="Describe your formula"
        placeholder="Enter a description for the column"
        value={value}
        onChange={handleChange}
      />
      <TypeHints />
      <TextHints
        hints={options}
        anchorRef={ref}
        show={showOptions}
        onSelect={(v) => {
          handleOptionClick(
            {
              value: v,
              trigger: '"',
            },
            value,
            ref,
            (v) => {
              setValue(v);
              close();
            }
          );
        }}
      />
      <div className="flex justify-between">
        <div className="flex items-center">
          {error && (
            <Text.Caption className={ColorScheme.text.error}>
              {error}
            </Text.Caption>
          )}
        </div>
        <Button
          icon="sparkles"
          loading={loading}
          theme="primary"
          text="Generate Formula"
          hint={{
            display: `Ctrl + ↵`,
            targetKey: (e) => e.ctrlKey && e.key === 'Enter',
          }}
          className="mb-4 w-fit"
          onClick={run}
        />
      </div>
    </div>
  );
};

const useExamples = (
  entity: Entity,
  vm: WatchableViewModel<{
    rel: Relation;
  }>
) => {
  const { data } = useArtifactQuery({
    baseRel: vm.rel,
    rel: (rel) => rel.select((t) => t.star()),
    limit: 1,
  });

  return generateExamplesForCategories(
    mapValues(entity.columns, ({ type }) => type),
    data.data.toArray()
  );
};

const Examples: React.FC<{
  vm: WatchableViewModel<{
    rel: Relation;
  }>;
  entity: Entity;
  onSelect: (example: string) => void;
}> = ({ vm, entity, onSelect }) => {
  const examples = useExamples(entity, vm);

  return (
    <Accordion.Root multiple={false}>
      {Object.entries(examples).map(([title, examples], idx) => {
        return (
          <div key={idx} className="w-full">
            <Accordion.Trigger
              id={String(idx)}
              as={'div'}
              className="w-full cursor-pointer"
            >
              <ExampleHeader title={title} id={String(idx)} />
            </Accordion.Trigger>
            <Accordion.Item id={String(idx)}>
              <ul className="mb-4">
                {examples.map((example, idx) => {
                  return (
                    <li
                      key={idx}
                      className="mb-4 text-sm flex items-center space-x-4"
                    >
                      <Hint className="w-[250px] shrink-0 text-ellipsis flex items-center justify-between">
                        {example.example}
                        <Button
                          icon="arrow-right"
                          iconOnly
                          inline
                          small
                          onClick={() => {
                            onSelect(example.example);
                          }}
                        />
                      </Hint>
                      <Text.Caption>=</Text.Caption>
                      <Hint className="text-ellipsis overflow-hidden text-nowrap">
                        {example.output}
                      </Hint>
                    </li>
                  );
                })}
              </ul>
            </Accordion.Item>
          </div>
        );
      })}
    </Accordion.Root>
  );
};

const ExampleHeader: React.FC<{
  title: string;
  id: string;
}> = ({ title, id }) => {
  const expanded = useAccordion((s) => s.expanded);
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between w-full">
        <Title type="label">{title}</Title>
        <Icon icon={expanded.includes(id) ? 'chevron-up' : 'chevron-down'} />
      </div>
      <Divider className="mb-4" />
    </div>
  );
};

const SampleOutput: React.FC<{
  vm: ViewModel;
  expr: AST.ExprIR;
  sourceColumns: Record<string, Ty.ExtendedAttributeType>;
}> = ({ vm, expr, sourceColumns }) => {
  const columnName = useSubscribe(vm, (vm) =>
    vm.columnName.length === 0 ? 'COLUMN_NAME' : vm.columnName
  );
  const rel = useSubscribe(vm, (vm) => vm.rel);
  const { data } = useArtifactQuery({
    baseRel: rel,
    rel: (rel) =>
      rel.select((t) => ({
        ...t.pick(...Object.keys(sourceColumns)),
        [columnName]: Expression.fromAst(expr),
      })),
    limit: 10,
  });

  const records = data.data.toArray();

  return (
    <div className="flex flex-col overflow-x-scroll">
      <DataPreview records={records} columnName={columnName} />
    </div>
  );
};

export const EreQLColumn: NewColumnAction = {
  icon: 'code-bracket',
  view: Container as NewColumnAction['view'],
  label: 'Formula',
};
