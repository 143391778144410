import { line, curveCatmullRom } from 'd3-shape';
import { BarLayer, ResponsiveBar } from '@nivo/bar';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { groupBy, mapValues, sortBy, uniqBy } from 'lodash';
import {
  Axis,
  PATTERN_DEFS,
  THEME,
  fillMatcher,
  getTickLegendSize,
  isDateAxis,
  makeTickValues,
} from '../utils';
import { Loading } from './loading';
import { ChartContext, makeChartContextHook } from '../context';
import { BaseDatum, ChartProps } from '../types';
import { EmptyChart } from '../empty';
import { ChartInfo, InfoMenuItem, infoHandler } from '../info';
import { useState } from 'react';
import { BAR_CHART_INFO } from './info';
import { scaleToFormat } from '../utils';
import { Tooltip } from '../tooltip';
import { Button, Divider, If } from '@cotera/client/app/components/ui';
import {
  GRAY_200,
  makeValueFormatter,
  UTCDate,
} from '@cotera/client/app/components/utils';

type Datum = {
  y: number;
  category: string;
  x: string;
  style: BaseDatum['style'];
};

type Config = {
  type?: 'grouped' | 'stacked';
  trendline?: boolean;
  direction?: 'horizontal' | 'vertical';
  axis: {
    x: Axis;
    y: Axis;
  };
};
export type Props = Config & { loading: boolean } & ChartProps<Datum>;

export const BarChart: React.FC<Props & ChartProps<Datum>> = ({
  data,
  type = 'stacked',
  axis,
  trendline,
  loading,
  format,
  menuItemHandlers = {},
  menuItems = [],
  direction = 'vertical',
  ...layoutProps
}) => {
  const [infoOpen, setInfoOpen] = useState(false);
  const [labelsVisible, setLabelsVisible] = useState(false);

  const xKey = direction === 'vertical' ? 'x' : 'y';
  const yKey = direction === 'vertical' ? 'y' : 'x';

  return (
    <ChartContext data={data} labelKeys={['category']}>
      <ChartLayout
        {...layoutProps}
        labels={{
          left: axis[yKey].label,
          bottom: axis[xKey].label,
        }}
        menuItems={[
          ...DEFAULT_MENU_ITEMS,
          InfoMenuItem,
          {
            id: 'labels',
            label: 'View Labels',
            icon: (props) =>
              labelsVisible ? (
                <Button inline icon="eye-slash" {...props} />
              ) : (
                <Button inline icon="eye" {...props} />
              ),
          },
          ...menuItems,
        ]}
        menuItemHandlers={{
          ...infoHandler(() => setInfoOpen(true)),
          ...{
            labels: () => setLabelsVisible(!labelsVisible),
          },
          ...menuItemHandlers,
        }}
      >
        <If condition={loading}>
          <Loading />
        </If>
        <If condition={data.length === 0 && !loading}>
          <EmptyChart />
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart
            labelsVisible={labelsVisible}
            axis={axis}
            trendline={trendline}
            type={type}
            format={format}
            direction={direction}
          />
        </If>
      </ChartLayout>
      <ChartInfo
        open={infoOpen}
        onChange={setInfoOpen}
        sections={BAR_CHART_INFO}
      />
    </ChartContext>
  );
};

const useTypedChartContext = makeChartContextHook<Datum>();

const Chart: React.FC<{
  axis?: Props['axis'];
  format?: Props['format'];
  trendline?: boolean;
  type?: 'grouped' | 'stacked';
  direction?: 'horizontal' | 'vertical';
  labelsVisible: boolean;
}> = ({ axis, trendline, type, format, direction, labelsVisible }) => {
  const layers = trendline ? [ScatterCircle, Line] : [];
  const data = useTypedChartContext((s) => s.data);
  const labels = useTypedChartContext((s) => s.activeLabels);
  const keys = labels.map((x) => x.label);
  const chartData = orderData(transform(data, keys), axis);
  const xKey = direction === 'vertical' ? 'x' : 'y';
  const yKey = direction === 'vertical' ? 'y' : 'x';
  const defaultYFormat = direction === 'vertical' ? 'linear' : undefined;

  const xFormatter = makeValueFormatter(
    format?.[xKey] ?? scaleToFormat(axis?.[xKey]?.scale)
  );

  const yFormatter = makeValueFormatter(
    format?.[yKey] ?? scaleToFormat(axis?.[yKey]?.scale ?? defaultYFormat)
  );

  const bottomMargin = getTickLegendSize(
    chartData.map((x) => xFormatter(x.x)).flat(),
    20
  );

  const leftMargin = getTickLegendSize(
    chartData
      .map((x) =>
        Object.entries(x)
          .filter(([key]) => key !== 'x')
          .map(([_, value]) => yFormatter(value))
      )
      .flat(),
    20
  );

  const directionProps =
    direction === 'horizontal'
      ? {
          layout: 'horizontal' as const,
          enableGridY: false,
          enableGridX: true,
        }
      : {
          layout: 'vertical' as const,
          enableGridY: true,
          enableGridX: false,
        };

  const ticks = () => {
    const value = makeTickValues(format?.[xKey] ?? axis?.[xKey]?.scale);
    if (value === 'every 1 month') {
      return uniqBy(
        chartData.map((datum) => datum.x),
        (x) => {
          const date = new UTCDate(x);
          return date.getFullYear() + '-' + date.getMonth();
        }
      );
    }
    return value;
  };

  return (
    <ResponsiveBar
      animate={false}
      theme={THEME}
      data={chartData}
      keys={keys}
      indexBy="x"
      margin={{
        top: 50,
        right: 50,
        bottom: direction === 'vertical' ? bottomMargin : leftMargin,
        left: direction === 'vertical' ? leftMargin : bottomMargin,
      }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      defs={PATTERN_DEFS}
      colors={(x) => labels.find((y) => y.label === x.id)?.color ?? GRAY_200}
      fill={labels.map(fillMatcher)}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: (v) => xFormatter(v, 20),
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 60,
        tickValues: ticks(),
      }}
      axisLeft={{
        format: yFormatter,
      }}
      label={(d) => yFormatter(d.value)}
      tooltip={({ id, value, color, data }) => (
        <Tooltip.Container>
          <strong>{xFormatter(data.x)}</strong>
          <Divider className="mb-2 mt-1" />
          <Tooltip.Item>
            <Tooltip.Dot color={color} />
            <strong>{id}</strong>: {yFormatter(value)}
          </Tooltip.Item>
        </Tooltip.Container>
      )}
      enableLabel={labelsVisible}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      layers={['grid', 'axes', 'bars', 'markers', ...layers]}
      groupMode={type}
      {...directionProps}
    />
  );
};

const ScatterCircle: BarLayer<{ x: string }> = ({ bars }) => {
  return (
    <>
      {bars.map((bar) => {
        // Render the circle SVG in chart using Bars co-ordinates.
        return (
          <circle
            key={`point-${bar.x}-${bar.y}`}
            // Scale x-cordinate of the circle to the center of bar
            cx={bar.x + bar.width / 2}
            // Scale y-cordinate of the circle to top of the bar
            cy={bar.y - 10}
            r={3}
            fill={bar.color}
            stroke={bar.color}
            style={{ pointerEvents: 'none' }}
          />
        );
      })}
    </>
  );
};

const Line: BarLayer<{ x: string }> = ({ bars }) => {
  const lines = Object.values(
    mapValues(
      groupBy(bars, (x) => x.data.id),
      (bars) => {
        const lineGenerator = line()
          .x((bar: any) => bar.x + bar.width / 2)
          .y((bar: any) => bar.y - 10)
          .curve(curveCatmullRom.alpha(0.5));

        const b = sortBy(bars, (x) => x.x);
        return (
          <path
            d={lineGenerator(b as any)!}
            fill="none"
            stroke={bars[0]?.color}
            style={{ pointerEvents: 'none', strokeWidth: '2' }}
          />
        );
      }
    )
  );

  return <>{lines}</>;
};

const orderData = (
  data: {
    x: any;
  }[],
  axis?: Props['axis']
): {
  x: string;
}[] => {
  if (isDateAxis(axis?.x)) {
    return sortBy(data, (x) => new UTCDate(x.x).getTime());
  }

  if (axis?.x?.scale === 'linear') {
    return sortBy(data, (x) => Number(x.x));
  }
  return data;
};

const transformData = (data: Datum[], keys: string[]): Record<string, any> => {
  return Object.fromEntries(
    keys.map((category) => {
      return [
        category,
        data
          .filter((x) => x.category === category)
          .reduce((acc, x) => acc + x.y, 0),
      ];
    })
  );
};

type T = (
  key: string,
  data: Datum[]
) => {
  x: string;
  [key: string]: unknown;
};

/**
 *
 * @returns data in the following format: [{ x: 'some x, category1: 1, category2: 2, ... }]
 * where category1, category2, ... are the categories in the bar chart
 */
const transform = (
  data: Datum[],
  keys: string[]
): {
  x: string;
  [key: string]: unknown;
}[] => {
  const mapper: T = (key, x) => {
    return {
      x: key,
      ...transformData(x, keys),
    };
  };

  return Object.entries(groupBy(data, (x) => x.x))
    .map(([key, x]) => {
      const mapped = mapper(key, x);

      return {
        ...mapped,
        x: key,
      };
    })
    .flat();
};
