import { StateSetter } from '../provider';
import { StoreMiddleware } from './type';

export function applyMiddleware<State>(
  middlewares: StoreMiddleware<State>[],
  set: StateSetter<State>,
  get: () => State
): StateSetter<State> {
  return middlewares.reduceRight(
    (next, middleware) => middleware(next, get),
    set
  );
}
