import { Relation, Ty } from '@cotera/era';
import { Table, Vector } from 'apache-arrow';
import { z } from 'zod';
import { mapArrowToEraTypes } from './map-arrow-to-era-types';

export class DuckDBQueryResult {
  constructor(
    protected readonly arrowTable: Table,
    readonly source?: Relation | z.ZodSchema
  ) {}

  toArrayOf<T extends z.ZodSchema>(schema: T): z.infer<z.ZodArray<T>> {
    return z.array(schema).parse(this.toArray());
  }

  toString() {
    return this.arrowTable.toString();
  }

  toArray(): Ty.Row[] {
    const arrowFields = this.arrowTable.schema.fields;
    return this.arrowTable
      .toArray()
      .map((r) => mapArrowToEraTypes(r, arrowFields));
  }

  get length() {
    return this.arrowTable.numRows;
  }

  slice(start: number, stop: number): DuckDBQueryResult {
    return new DuckDBQueryResult(
      this.arrowTable.slice(start, stop),
      this.source
    );
  }

  row(index: number): DuckDBQueryResult {
    return new DuckDBQueryResult(
      this.arrowTable.slice(index, index + 1),
      this.source
    );
  }

  concat(other: DuckDBQueryResult): DuckDBQueryResult {
    return new DuckDBQueryResult(
      this.arrowTable.concat(other.arrowTable),
      this.source
    );
  }

  forSchema(schema: z.ZodSchema): DuckDBQueryResult {
    return new DuckDBQueryResult(this.arrowTable, schema);
  }

  forRelation(rel: Relation): DuckDBQueryResult {
    return new DuckDBQueryResult(this.arrowTable, rel);
  }

  column<Schema extends z.Schema>(name: string): Column<Schema> {
    return new Column(
      name,
      this.arrowTable.getChild(name),
      (this.source instanceof z.Schema ? this.source : z.any()) as Schema
    );
  }

  get numRows(): number {
    return this.arrowTable.numRows;
  }

  get numCols(): number {
    return this.arrowTable.numCols;
  }

  isEqual(other: DuckDBQueryResult): boolean {
    if (this.source instanceof Relation && other?.source instanceof Relation) {
      return this.source.sqlHash() === other.source.sqlHash();
    }

    return this === other;
  }
}

class Column<Schema extends z.ZodSchema> {
  constructor(
    readonly name: string,
    private readonly column: Vector<any> | null,
    private readonly schema: Schema
  ) {}

  get length(): number {
    return this.column?.length ?? 0;
  }

  valueAt(index: number): z.infer<Schema> {
    return this.column?.get(index) as z.infer<Schema>;
  }

  toArray(): z.infer<z.ZodArray<Schema>> {
    return (this.column?.toArray() as z.infer<z.ZodArray<Schema>>) ?? [];
  }
}
