import { AST } from '../ast';
import { Interpreter } from '../interpreter';
import { Assert } from '@cotera/utilities';
import { TC, TyStackTrace } from '../type-checker';
import { Ty } from '../ty';

// 🤷 no idea really? I mean it's not super clear whether this function needs to be a strict upper bound or just like a "close enough"
const YEARS_CARDINALITY = 2030 - 2000;

// TODO: This can probably work on macros? It was originally built to support
// the split queries so it only needed to work on IR, but there's no reason it
// couldn't calculate the max of both macro branches?

export const maxPossibleRows = (ir: AST.RelIR): number | null => {
  const { t } = ir;

  switch (t) {
    case 'select': {
      const childLimit = maxPossibleRows(ir.sources.from);
      if (typeof ir.limit === 'number') {
        if (typeof childLimit === 'number') {
          return Math.min(ir.limit, childLimit);
        }

        return ir.limit;
      }
      if (typeof childLimit === 'number') {
        return childLimit;
      }
      return null;
    }
    case 'aggregate': {
      const tc = TC.checkRel(ir);

      if (tc instanceof TyStackTrace) {
        throw tc.toError({ jsStackPointer: maxPossibleRows });
      }

      const knownSize = ir.groupedAttributes
        .map((name) => {
          const ty = tc.attributes[name];

          let size: number | null = null;

          if (ty && Ty.hasTag(ty, Ty.CARD_LT_1000.tags)) {
            return 1000;
          }

          if (ty?.ty.k === 'primitive') {
            switch (ty.ty.t) {
              case 'boolean':
                size = 2;
                break;
              case 'year':
                size = YEARS_CARDINALITY;
                break;
              case 'day':
                size = YEARS_CARDINALITY * 365;
                break;
              case 'month':
                size = YEARS_CARDINALITY * 12;
                break;
            }
          }

          if (ty?.ty.k === 'enum') {
            size = ty.ty.t.length;
          }

          return size !== null ? size + (ty?.nullable ? 1 : 0) : null;
        })
        .reduce<number | null>(
          (acc, next) => (acc === null || next === null ? null : acc * next),
          1
        );

      return knownSize ?? maxPossibleRows(ir.sources.from);
    }
    case 'join': {
      const l = maxPossibleRows(ir.sources.left);
      if (typeof l === 'number') {
        const r = maxPossibleRows(ir.sources.right);
        if (typeof r === 'number') {
          return l * r;
        }
      }
      return null;
    }
    case 'union': {
      const l = maxPossibleRows(ir.sources.left);
      if (typeof l === 'number') {
        const r = maxPossibleRows(ir.sources.right);
        if (typeof r === 'number') {
          return l + r;
        }
      }
      return null;
    }

    case 'generate-series': {
      const stop = Interpreter.evalExprIR(ir.stop);
      const start = Interpreter.evalExprIR(ir.start);
      Assert.assert(typeof start === 'number' && typeof stop === 'number');
      return Math.max(stop - start, 0);
    }
    case 'values':
      return ir.values.length;
    case 'file':
    case 'table':
    case 'information-schema':
      return null;
    default:
      return Assert.unreachable(t);
  }
};
