import { LineChart } from '@cotera/client/app/components/data-vis';
import { useAppData } from '@cotera/client/app/stores/org';
import { useAsyncFn } from '@cotera/client/app/hooks/use-query-data';
import { countOverTime } from './queries';
import { useStore } from './store';

export const Chart: React.FC = () => {
  const initedDb = useAppData((s) => s.initedDb);
  const searches = useStore((s) => s.searchTerms);
  const cutoff = useStore((s) => s.cutoff);
  const rel = useStore((s) => s.rel);

  const res = useAsyncFn(async () => {
    return await countOverTime(initedDb, cutoff, searches, rel!);
  }, [cutoff, initedDb, searches, rel]);

  return (
    <div className="h-[400px] w-full">
      <LineChart
        loading={res.isLoadingData()}
        axis={{
          x: { label: 'timestamp', scale: 'month' },
          y: { label: 'cosine_similarity' },
        }}
        data={
          res.data?.toArray().map((datum) => ({
            x: datum['x'],
            y: datum['y'],
            category: 'items',
          })) ?? []
        }
      />
    </div>
  );
};
