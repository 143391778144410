import { Ty } from '../ty';
import { Expression } from './expression';
import { Constant, If } from './utilities';

export const Sum = (
  expr: Ty.Scalar | Expression,
  opts?: { jsStackPointer: Function }
): Expression =>
  Expression.wrap(expr).functionCall('sum', [], {
    jsStackPointer: opts?.jsStackPointer ?? Sum,
  });

export const Avg = (
  expr: Ty.Scalar | Expression,
  opts?: { jsStackPointer: Function }
): Expression =>
  Expression.wrap(expr).functionCall('avg', [], {
    jsStackPointer: opts?.jsStackPointer ?? Avg,
  });

export const Min = (
  expr: Ty.Scalar | Expression,
  opts?: { jsStackPointer: Function }
): Expression =>
  Expression.wrap(expr).functionCall('min', [], {
    jsStackPointer: opts?.jsStackPointer ?? Min,
  });

export const Max = (
  expr: Ty.Scalar | Expression,
  opts?: { jsStackPointer: Function }
): Expression =>
  Expression.wrap(expr).functionCall('max', [], {
    jsStackPointer: opts?.jsStackPointer ?? Max,
  });

export const Count = (opts?: { jsStackPointer: Function }): Expression =>
  Constant(1).functionCall('count', [], {
    jsStackPointer: opts?.jsStackPointer ?? Count,
  });

export const CountIf = (
  expr: boolean | Expression,
  opts?: { jsStackPointer?: Function }
): Expression =>
  Sum(If(expr, { then: 1, else: 0 }), {
    jsStackPointer: opts?.jsStackPointer ?? CountIf,
  }).coalesce(0);

export const CountDistinct = (
  expr: Ty.Scalar | Expression,
  opts?: { jsStackPointer: Function }
): Expression =>
  Expression.wrap(expr).functionCall('count_distinct', [], {
    jsStackPointer: opts?.jsStackPointer ?? CountDistinct,
  });

export const Corr = (
  left: number | Expression,
  right: number | Expression,
  opts?: { jsStackPointer?: Function }
): Expression =>
  Expression.wrap(left).functionCall('corr', [right], {
    jsStackPointer: opts?.jsStackPointer ?? Corr,
  });

export const Median = (
  expr: number | Expression,
  opts?: { jsStackPointer?: Function }
): Expression =>
  Expression.wrap(expr).functionCall('percentile_cont', [0.5], {
    jsStackPointer: opts?.jsStackPointer ?? PercentileCont,
  });

export const PercentileCont = (
  expr: number | Expression,
  percentile: number | Expression,
  opts?: { jsStackPointer?: Function }
): Expression =>
  Expression.wrap(expr).functionCall('percentile_cont', [percentile], {
    jsStackPointer: opts?.jsStackPointer ?? PercentileCont,
  });

export const PercentileDisc = (
  expr: Ty.Scalar | Expression,
  percentile: number | Expression,
  opts?: { jsStackPointer?: Function }
): Expression =>
  Expression.wrap(expr).functionCall('percentile_disc', [percentile], {
    jsStackPointer: opts?.jsStackPointer ?? PercentileDisc,
  });

export const StdDevPop = (
  expr: number | Expression,
  opts?: { jsStackPointer?: Function }
) =>
  Expression.wrap(expr).functionCall('stddev_pop', [], {
    jsStackPointer: opts?.jsStackPointer ?? StdDevPop,
  });

export const StdDevSamp = (
  expr: number | Expression,
  opts?: { jsStackPointer?: Function }
) =>
  Expression.wrap(expr).functionCall('stddev_samp', [], {
    jsStackPointer: opts?.jsStackPointer ?? StdDevSamp,
  });

export const StringAgg = (
  expr: string | Expression,
  delimiter: string | Expression
): Expression => Expression.wrap(expr).functionCall('string_agg', [delimiter]);

export const ArrayAgg = (expr: Ty.Scalar | Expression): Expression =>
  Expression.wrap(expr).functionCall('array_agg', []);
