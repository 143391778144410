import {
  ICON_MAPPING,
  IconName,
  Text,
  Title,
} from '@cotera/client/app/components/ui';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';

export const Empty: React.FC<{
  type: 'list' | 'chart';
  title: string;
  caption?: string;
  icon?: IconName;
  cta?: React.ReactNode;
}> = ({ type, title, caption, icon, cta }) => {
  const IconComponent =
    ICON_MAPPING[icon ?? (type === 'list' ? 'table-cells' : 'bar-chart')];

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="h-20 w-20 mb-4 rounded-full border border-zinc-300 p-5">
        <IconComponent
          className={classNames(ColorScheme.text['regular'], 'h-full w-full')}
        />
      </div>
      <Title type="section" className="mb-2">
        {title}
      </Title>
      {caption && <Text.Caption>{caption}</Text.Caption>}
      {cta !== undefined && <div className="mt-4">{cta}</div>}
    </div>
  );
};
