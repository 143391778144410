import { Icon, IconName } from '@cotera/client/app/components/ui';
import { createPortal } from 'react-dom';
import { List } from '@cotera/client/app/components/headless';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';

export const TextHints: React.FC<{
  hints: {
    value: string;
    icon?: IconName;
  }[];
  anchorRef: React.RefObject<HTMLElement>;
  show: boolean;
  onSelect: (option: string) => void;
}> = ({ hints, anchorRef, show, onSelect }) => {
  const rect = anchorRef.current?.getBoundingClientRect();

  if (hints.length === 0) {
    return null;
  }

  return (
    show &&
    createPortal(
      <div
        className="text-hints absolute bg-white border border-divider rounded w-[200px] max-h-[300px] overflow-y-auto z-50"
        style={{
          top: rect && rect.bottom + window.scrollY,
          left: rect && rect.left + window.scrollX,
        }}
      >
        <List.Ul>
          {hints.map((option, index) => (
            <List.Li
              key={index}
              onClick={() => {
                onSelect(option.value);
              }}
              as="li"
              className={classNames(
                'data-[focus]:bg-zinc-200 items-center flex transition-colors cursor-pointer px-2 py-2 flex border-b boder-divder pb-2 text-sm',
                ColorScheme.text['regular']
              )}
            >
              {option.icon && (
                <Icon size="small" icon={option.icon} className="mr-2" />
              )}
              {option.value}
            </List.Li>
          ))}
        </List.Ul>
      </div>,
      document.body // Render in the body to avoid parent clipping
    )
  );
};
