import { AST, Expression } from '@cotera/era';
import { Operator } from './constants';
import { Connective, FilterGroup, FilterItem } from './types';

// Helper to generate unique IDs for FilterItem
const generateId = (() => {
  let counter = 0;
  return () => `item-${counter++}`;
})();

// Helper to determine the operator type
const getOperator = (op: AST.FunctionIdentifier): Operator => {
  switch (op) {
    case 'eq':
      return 'equals';
    case 'neq':
      return 'not equals';
    case 'gt':
      return 'greater than';
    case 'lt':
      return 'less than';
    case 'like':
      return 'contains';
    default:
      throw new Error(`Unsupported operator: ${op}`);
  }
};

function parseArgs(args: AST.ExprFR[]): (string | null)[] {
  return args.map((arg) => (arg.t === 'scalar' ? String(arg.val) : null));
}

// Recursive function to parse the AST
function parseAst(ast: AST.ExprFR): FilterGroup {
  if (ast.t === 'function-call' && (ast.op === 'and' || ast.op === 'or')) {
    return {
      connective: ast.op as Connective,
      items: ast.args
        .map((arg) => {
          const parsedItem = parseAst(arg);
          return parsedItem.items.length === 1 && parsedItem.items[0]!.value
            ? parsedItem.items[0] // Single item with a value
            : ({ group: parsedItem, id: generateId() } as FilterItem); // Nested FilterGroup
        })
        .filter((x) => x !== undefined) as FilterItem[],
    };
  } else if (ast.t === 'function-call' && ast.op) {
    const [attr, value] = ast.args;
    if (attr!.t === 'attr' && value!.t === 'scalar') {
      return {
        connective: 'and', // Default connective for single item group
        items: [
          {
            id: generateId(),
            value: {
              key: attr.name,
              value: parseArgs([value]),
              operator: getOperator(ast.op),
            },
          },
        ],
      };
    }
  }
  throw new Error('Unsupported AST structure');
}

export function convertToFilterGroup(data: Expression): FilterGroup {
  return parseAst(data.ast) as FilterGroup;
}
