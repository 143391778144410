export function getRandomItems<T>(array: T[], itemCount: number = 5): T[] {
  // Ensure the array has enough items
  if (array.length < itemCount) {
    return array;
  }

  // Create a copy of the array and shuffle it
  const shuffled = [...array].sort(() => 0.5 - Math.random());

  // Return the first 'itemCount' elements
  return shuffled.slice(0, itemCount);
}
