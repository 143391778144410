import { classNames } from '@cotera/client/app/components/utils';

const NavItem: React.FC<{
  name: string;
  active: boolean;
  set: (value: string | null) => void;
}> = (props) => {
  return (
    <div
      onClick={() => props.set(props.name)}
      key={props.name}
      className={
        '[&:not(:last-child)]:border-r border-divider pr-4 mr-2 flex items-center cursor-pointer'
      }
    >
      <span
        className={classNames(
          props.active
            ? 'text-primary-text'
            : 'hover:text-standard-text text-standard-text',
          'ml-2 whitespace-nowrap text-sm'
        )}
      >
        {props.name}
      </span>
    </div>
  );
};

export const Nav: React.FC<{
  options: string[];
  active: string;
  set: (value: string | null) => void;
}> = ({ options, active, set }) => {
  return (
    <div className="overflow-y-scroll w-full flex items-center border border-divider rounded px-3 py-2">
      {options.map((name) => (
        <NavItem key={name} name={name} active={name === active} set={set} />
      ))}
    </div>
  );
};
