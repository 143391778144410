import { useState } from 'react';
import { useAsync } from 'react-use';
import { AsyncResult } from '@cotera/sdk/core';
import { ChildrenProps } from '@cotera/client/app/components/utils';

export function useAsyncFn<T>(
  fn: () => Promise<T>,
  deps?: React.DependencyList,
  opts: { savePrevious: boolean } = { savePrevious: false }
): AsyncResult<T> {
  const [prev, setPrev] = useState<T | undefined>();

  const res = useAsync(async () => {
    return fn().then((r) => {
      if (opts.savePrevious) {
        setPrev(r);
      }
      return r;
    });
  }, deps);

  if (res.value !== undefined && res.value instanceof AsyncResult) {
    return res.value;
  }

  if (res.error) {
    // eslint-disable-next-line no-console
    console.error(res.error);
  }

  return AsyncResult.from<T>({
    isLoading: res.loading,
    data: res.value ?? prev,
    error: res.error,
  });
}

const IsLoading: React.FC<{ res: AsyncResult<any> } & ChildrenProps> = ({
  res,
  children,
}) => (res.isLoadingData() ? <>{children}</> : null);

const IsError = ({
  res,
  children,
}: { res: AsyncResult<any> } & ChildrenProps) =>
  res.isError() ? <>{children}</> : null;

function HasData<T>({
  res,
  children,
}: {
  res: AsyncResult<T>;
  children: (res: T) => ChildrenProps['children'];
}) {
  return res.hasData() ? <>{children(res.data)}</> : null;
}

export const AsyncData = {
  IsLoading,
  IsError,
  HasData,
};
