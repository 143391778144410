import {
  XMarkIcon,
  CheckCircleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';
import { Assert } from '@cotera/utilities';
import {
  DevModeStatus,
  useDevMode,
  useDevModeStatus,
} from '@cotera/client/app/stores/dev-mode';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useWhoami } from '@cotera/client/app/stores/org';
import { useDevServer } from '@cotera/client/app/hooks/use-manifest';
import { Loading } from '@cotera/client/app/components/ui';
import { Inputs } from '@cotera/client/app/components/forms';

const Status: React.FC<{ status: DevModeStatus }> = ({ status }) => {
  if (status === DevModeStatus.Disabled) {
    return null;
  }

  if (status === DevModeStatus.Loading) {
    return <ArrowPathIcon className="text-green-400 animate-spin-slow" />;
  }

  if (status === DevModeStatus.Error) {
    return <XMarkIcon className="text-red-400" />;
  }

  if (status === DevModeStatus.On) {
    return <CheckCircleIcon className="text-green-400" />;
  }

  return Assert.unreachable(status);
};

export const DevMode: React.FC = () => {
  const devModeStatus = useDevModeStatus();
  const enabled = useDevMode((s) => s.enabled);
  const setDevMode = useDevMode((s) => s.setEnabled);

  return (
    <div className="flex items-center">
      <span className="mr-2 text-xs word-break-keep whitespace-nowrap">
        Dev Mode
      </span>
      {devModeStatus !== DevModeStatus.Disabled && (
        <div className="h-5 mr-2 flex justify-around">
          <div className="w-5 mx-1">
            <Status status={devModeStatus} />
          </div>
        </div>
      )}
      <Inputs.Toggle
        compact
        options={['On', 'Off']}
        value={enabled ? 'On' : 'Off'}
        onChange={(value) => setDevMode(value === 'On')}
      />
    </div>
  );
};
