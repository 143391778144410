import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { Button, Text } from '@cotera/client/app/components/ui';
import { useSearchParams } from 'react-router-dom';
import {
  createDataStore,
  makeStore,
  StateGetter,
  StateSetter,
  UrlStateAdapter,
} from '@cotera/client/app/etc';

type State = {
  totalRows?: number;
  page: number;
  rowsPerPage: number;
  id: string;
};

const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  next: () => {
    set((state) => ({
      page: state.page + 1,
    }));
  },
  previous: () => {
    set((state) => ({
      page: state.page - 1,
    }));
  },
  setPageSize: (pageSize: number) => {
    set(() => ({
      rowsPerPage: pageSize,
    }));
  },
  hasNextPage: () => {
    return get().page < Math.ceil((get().totalRows ?? 0) / get().rowsPerPage);
  },
  hasPreviousPage: () => {
    return get().page > 0;
  },
  setPage: (page: number) => {
    set(() => ({
      page,
    }));
  },
});

type Actions = ReturnType<typeof actions>;

class PaginationUrlStateAdapter extends UrlStateAdapter<State> {
  partialize(state: State): Partial<State> {
    return {
      page: state.page,
      rowsPerPage: state.rowsPerPage,
    };
  }
}

export const { hook: usePagination, provider: PaginationProvider } = makeStore<
  State,
  Actions
>((state, actions) =>
  createDataStore(state, actions, {
    persistance: PaginationUrlStateAdapter,
  })
);

const View: React.FC<ChildrenProps & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'border-t border-divider flex items-center flex-row pt-4 w-full justify-between',
        className
      )}
    >
      {children}
    </div>
  );
};

const Container: React.FC<
  ChildrenProps & { totalRows?: number; id?: string; rowsPerPage?: number }
> = ({ children, totalRows, id, rowsPerPage }) => {
  return (
    <PaginationProvider
      state={{
        totalRows,
        rowsPerPage: rowsPerPage ?? 50,
        page: 0,
        id: id ?? window.location.pathname,
      }}
      actions={actions}
    >
      {children}
    </PaginationProvider>
  );
};

const Total = () => {
  const totlaRows = usePagination((s) => s.totalRows);
  return (
    <Text.Caption className="text-sm text-black">
      Total: {totlaRows}
    </Text.Caption>
  );
};

const ROW_SIZES = [10, 20, 30, 40, 50] as const;

const PageControls = () => {
  const page = usePagination((s) => s.page);
  const rowsPerPage = usePagination((s) => s.rowsPerPage);
  const setPageSize = usePagination((s) => s.actions.setPageSize);
  const totalRows = usePagination((s) => s.totalRows);

  const startRowDisplay = page * rowsPerPage + 1;
  const actions = usePagination((s) => s.actions);
  return (
    <div className="flex items-center gap-2">
      <Text.Caption className="text-sm text-black">Rows per page:</Text.Caption>
      <select
        className="mr-4 border-0 focus:ring-0 focus:outline-none active:bg-gray-200 bg-gray-100 rounded px-4 pr-8 text-sm text-gray-500"
        value={rowsPerPage}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {ROW_SIZES.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <Text.Caption className="text-sm text-black mr-4">
        {startRowDisplay} -&nbsp;
        {Math.min(totalRows ?? rowsPerPage - 1, startRowDisplay + rowsPerPage - 1)} of {totalRows}
      </Text.Caption>

      <Button
        inline
        icon="chevron-left"
        className={classNames(
          !actions.hasPreviousPage() ? 'text-muted-text' : ''
        )}
        onClick={() => actions.previous()}
        disabled={!actions.hasPreviousPage()}
      />
      <Button
        inline
        onClick={() => actions.next()}
        disabled={!actions.hasNextPage()}
        icon="chevron-right"
        className={classNames(!actions.hasNextPage() ? 'text-muted-text' : '')}
      />
    </div>
  );
};

export const Pagination = {
  Container,
  View,
  Total,
  PageControls,
};

export function useSearchParamsState<T>(
  searchParamName: string,
  defaultValue: T
): readonly [
  searchParamsState: T,
  setSearchParamsState: (newState: T) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState: T = acquiredSearchParam
    ? JSON.parse(acquiredSearchParam)
    : defaultValue;

  const setSearchParamsState = (newState: T) => {
    const next = Object.assign(
      {},
      [...searchParams.entries()].reduce(
        (o, [key, value]) => ({ ...o, [key]: value }),
        {}
      ),
      { [searchParamName]: newState }
    );
    setSearchParams(JSON.stringify(next));
  };
  return [searchParamsState, setSearchParamsState];
}
