import { PersistanceAdapter } from '../store';
import * as URLParse from 'url-parse';

export abstract class UrlStateAdapter<State extends { id: string }>
  implements PersistanceAdapter<State>
{
  constructor(private state: State) {}

  id(): string {
    return this.state.id;
  }

  getItem<Item>(key: string): Item {
    const currentUrl = new URLParse.default(window.location.href, true);
    const searchParams = new URLSearchParams(
      currentUrl.query as Record<string, string>
    );
    const storedValue = searchParams.get(key);
    return JSON.parse(storedValue as string) as Item;
  }

  setItem<Item>(key: string, newValue: Item): PromiseLike<void> {
    const currentUrl = new URLParse.default(window.location.href, true);
    const searchParams = new URLSearchParams(
      currentUrl.query as Record<string, string>
    );
    searchParams.set(key, JSON.stringify(newValue));
    window.history.replaceState(
      null,
      '',
      `${currentUrl.pathname}?${searchParams.toString()}${
        currentUrl.hash ? `${currentUrl.hash}` : ''
      }`
    );
    return Promise.resolve();
  }

  removeItem(key: string): void {
    const currentUrl = new URLParse.default(window.location.href, true);
    const searchParams = new URLSearchParams(
      currentUrl.query as Record<string, string>
    );
    searchParams.delete(key);
    window.location.search = searchParams.toString();
  }

  abstract partialize(state: State): Partial<State>;
}
