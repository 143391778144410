import { AST } from '@cotera/era';
import { CompileMarkup } from '../compiler';
import { useEraRuntimeCurrentMuValue } from './scopes';

export const RenderMarkupVar: React.FC<{
  inBlock: boolean;
  section: AST._MarkupVar;
}> = ({ inBlock, section }) => {
  const mu = useEraRuntimeCurrentMuValue({
    name: section.identifier,
    scope: section.scope,
  });

  return <CompileMarkup inBlock={inBlock} section={mu} />;
};
