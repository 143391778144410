import {
  BaseWorkspaceNodeViewModel,
  TransformableRelation,
  NodeTransform,
} from './base';
import { Divider } from '@cotera/client/app/components/ui';
import { v4 } from 'uuid';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { Suspense, useEffect } from 'react';

const REL_MAP = new Map<string, TransformableRelation>();

export class DatasetViewModel extends BaseWorkspaceNodeViewModel {
  t = 'dataset';
  private _artifactRel?: TransformableRelation;
  type: 'sample' | 'full' = 'sample';

  override readonly transforms: NodeTransform[] = [];

  constructor(
    name: string,
    position: { x: number; y: number },
    private _rel: TransformableRelation
  ) {
    super(name, v4(), position);
  }

  get originalRel() {
    return this._rel;
  }

  get rel() {
    if (this.type === 'sample' && this._artifactRel !== undefined) {
      return this._artifactRel;
    }

    /**
     * this is a bit of a hack. Basically the view can only ever calll registerArtifact once
     * so we track it in the global var.
     * But we also dont want ot have to call sqlHash() every time we want to check if we have the artifact
     * so we store the artifact in a private variable and return that if it exists
     */
    if (REL_MAP.has(this.originalRel.sqlHash())) {
      this._artifactRel = REL_MAP.get(this.originalRel.sqlHash());
      return this._artifactRel!;
    }

    return this.originalRel;
  }

  registerArtifact(rel: TransformableRelation) {
    const hash = this.originalRel.sqlHash();
    if (!REL_MAP.has(hash)) {
      REL_MAP.set(hash, rel);

      this.notifySubscribers();
    }
  }

  updateType(type: 'sample' | 'full') {
    this.type = type;
  }
}

const View: React.FC<{ node: DatasetViewModel }> = ({ node }) => {
  return (
    <>
      <Divider caption={node.name} />
      <Suspense fallback={null}>
        <DataLoader node={node} />
      </Suspense>
    </>
  );
};

const DataLoader = ({ node }: { node: DatasetViewModel }) => {
  const { data } = useDuckDBQuery({
    rel: node.originalRel,
  });

  useEffect(() => {
    node.registerArtifact(
      new TransformableRelation(
        data.artifactRel.ast,
        data.artifactRel.typecheck
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.artifactRel.sqlHash()]);

  return null;
};

export const DatasetNode = {
  ViewModel: DatasetViewModel,
  View,
};
