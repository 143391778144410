import { z } from 'zod';
import { Ty } from '../ty';
import { Assert } from '../utils';
export * from './base-zod';

export const isPrimitive = (
  x: unknown
): x is string | boolean | number | Date | null => {
  const ty = typeof x;
  switch (ty) {
    case 'string':
    case 'number':
    case 'boolean':
      return true;
    case 'object': {
      if (x === null) {
        return true;
      }

      if (x instanceof Date) {
        return true;
      }

      return false;
    }
    case 'bigint':
    case 'symbol':
    case 'function':
    case 'undefined':
      return false;
    default:
      return Assert.unreachable(ty);
  }
};

export const isScalar = (x: unknown): x is Ty.Scalar => {
  if (isPrimitive(x)) {
    return true;
  }

  if (Array.isArray(x)) {
    return x.every((y) => isScalar(y));
  }

  if (typeof x === 'object') {
    Assert.assert(
      !(x instanceof Date) && x !== null,
      'Covered by `isPrimitive`'
    );

    return Object.values(x).every((y) => isScalar(y));
  }

  return false;
};

// These are hand rolled because the `z.lazy` version of these was really
// really slow
export const PrimitiveSchema = z.custom<Ty.Primitive>((x) => isPrimitive(x));
export const LiteralSchema = z.custom<Ty.Scalar>((x) => isScalar(x));
