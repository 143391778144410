import { NumberFormat, numberFormatter, isNumberFormat } from './number';
import {
  TimeSeriesFormat,
  timeSeries,
  isTimeSeriesFormat,
} from './time-series';
import { customUnit } from './custom-unit';

export type Format =
  | {
      unit?: TimeSeriesFormat | null;
      value?: Date | number | string | null;
    }
  | {
      unit?: 'none' | null;
      value?: string | null;
    }
  | {
      unit?: NumberFormat | null;
      value?: number | string | null;
    };

const isCustomFormat = (
  props: Format
): props is {
  unit: string;
  value: string;
} => {
  return (
    props.unit !== 'none' &&
    !isNumberFormat(props) &&
    !isTimeSeriesFormat(props)
  );
};

export const makeValueFormatter =
  (unit: Format['unit']) =>
  (value: Format['value'], maxLength?: number): string => {
    const props = { unit, value } as Format;

    const noUnit = unit === null || unit === undefined || unit === 'none';

    if ((value === null || value === undefined) && noUnit) {
      return 'NULL';
    }

    if (noUnit) {
      if (maxLength && String(value).length > maxLength) {
        return String(value).substring(0, maxLength) + '...';
      }
      return String(value);
    }

    if (isTimeSeriesFormat(props)) {
      return timeSeries(props.value, props.unit);
    }

    if (isNumberFormat(props)) {
      return numberFormatter(props.value, props.unit);
    }

    if (isCustomFormat(props)) {
      return customUnit(String(props.value), props.unit);
    }

    return String(value);
  };

export const Formatters = {
  dateTime: timeSeries,
  number: numberFormatter,
  custom: customUnit,
};
