import { classNames } from '@cotera/client/app/components/utils';
import { Colors } from './classes';

export const Title: React.FC<{
  className?: string;
  children: React.ReactNode;
  pre?: string;
  sub?: string;
  type?: 'extra-large' | 'large' | 'medium';
}> = ({ className = '', pre, sub, children, type = 'large' }) => {
  const sizeClass = {
    'extra-large': 'text-3xl',
    large: 'text-2xl',
    medium: 'text-xl',
  };
  return (
    <div className={classNames('flex flex-col font-sans', className)}>
      {pre && (
        <span className={classNames('text-sm mb-0.5', Colors.caption)}>
          {pre}
        </span>
      )}
      <span
        className={classNames(
          'tracking-tight font-medium',
          Colors.standard,
          sizeClass[type]
        )}
      >
        {children}
      </span>
      {sub && (
        <span className={classNames('text-base mt-1.5', Colors.subtitle)}>
          {sub}
        </span>
      )}
    </div>
  );
};
