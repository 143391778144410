import { createContext } from 'react';
import { makeStoreProvider, StoreFn } from './provider';
import { createDataStore, makeStoreContextHook } from './store';

export * from './provider';
export * from './store';
export * from './persistance';
export * from './middlewares';

export function makeStore<
  T,
  K extends Record<string, (...args: any) => void> = ReturnType<() => {}>
>(storeFactory: StoreFn<T, K> = createDataStore) {
  const context = createContext<T>(undefined as any);
  return {
    provider: makeStoreProvider<T, K>(context, storeFactory),
    hook: makeStoreContextHook<T, K>(context),
  };
}
