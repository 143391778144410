import { UI } from '@cotera/era';
import { Dataset } from './dataset';
import { IndexPage } from './index-page';

export const TaxonomyAppV2 = UI.App({
  icon: 'identification',
  title: 'Cotera Marketplace Risk AI Demo',
  pages: {
    '/': IndexPage,
    '/:dataset': Dataset,
  },
});
