import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useDocumentsForFeatures = (featureIds: string[]) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey(['documents', ...featureIds]);

  return useSuspenseQuery({
    queryFn: async () => {
      return Assert.assertOk(
        await client.topics.documentsForFeatures({ featureIds })
      );
    },
    queryKey,
  });
};
