import { ICON_MAPPING, IconName } from './mapping';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { Theme } from '../../types/utils';

const SIZES = {
  regular: 'w-4 h-4',
  large: 'w-6 h-6',
  small: 'w-3 h-3',
};

export const Icon: React.FC<{
  theme?: Theme;
  icon: IconName;
  className?: string;
  size?: 'regular' | 'large' | 'small';
}> = ({ theme, icon, className, size = 'regular' }) => {
  const IconComponent = ICON_MAPPING[icon];
  return (
    <IconComponent
      className={classNames(
        theme ? ColorScheme.text[theme] : '',
        SIZES[size],
        className
      )}
    />
  );
};
