import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputProps, SingleError } from './input.props';
import { z } from 'zod';
import { lowerCase, upperFirst } from 'lodash';
import { Inputs } from '@cotera/client/app/components/forms';

export const StringInput: React.FC<
  InputProps<
    z.ZodString,
    { type?: 'password' | 'text'; displayName?: string }
  > & { error?: SingleError }
> = ({
  name,
  wrapper: Wrapper = ({ children }) => children,
  zodSchema,
  optional,
  className,
}) => {
  const { control } = useFormContext();
  const label = zodSchema._def.meta?.displayName ?? upperFirst(lowerCase(name));
  const description = zodSchema.description;

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: !optional }}
      render={({ field: { onChange, onBlur, value } }) => (
        <Wrapper schema={zodSchema} name={name}>
          <>
            <Inputs.Text
              className={className}
              type={zodSchema._def.meta?.type || 'text'}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
            />
            <span className="font-medium text-gray-600 text-xs">
              {description}
            </span>
          </>
        </Wrapper>
      )}
    />
  );
};
