import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  ReactNode,
} from 'react';

interface FocusWithinProps {
  onBlur?: (event?: any) => void;
  onFocus?: (event?: any) => void;
  children: ((args: {
    focusProps: any;
    getFocusProps: (props?: any) => any;
    isFocused: boolean;
  }) => ReactNode) | ReactNode;
}

const useFocusWithin = (
  onFocus?: (event?: any) => void,
  onBlur?: (event?: any) => void
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isBlurring, setIsBlurring] = useState(false);
  const mouseDownWithinTarget = useRef<HTMLElement | null>(null);
  const lastBlurEvent = useRef<any>(null);
  const isUnmounted = useRef(false);

  const delay = useCallback((cb: (...args: any[]) => void, ...args: any[]) => {
    setTimeout(() => {
      if (!isUnmounted.current) {
        cb(...args);
      }
    });
  }, []);

  const setFocusState = useCallback(
    (focused: boolean, isFocusWithinEvent: boolean) => {
      const setStateCb = () => {
        if (isUnmounted.current) return;
        setIsFocused((prevFocused) => {
          if (prevFocused === focused) {
            return prevFocused;
          }
          if (!focused) {
            setIsBlurring(true);
            return focused;
          }
          return focused;
        });
      };
      if (isFocusWithinEvent) {
        setStateCb();
      } else {
        delay(setStateCb);
      }
    },
    [delay]
  );

  const onDocumentMouseUp = useCallback(
    (event: MouseEvent) => {
      const container = mouseDownWithinTarget.current;
      const mouseUpWithinTarget =
        container && container.contains(event.target as Node);
      const activeElementWithinTarget =
        container && container.contains(document.activeElement);
      if (!activeElementWithinTarget && !mouseUpWithinTarget) {
        setFocusState(
          false,
          lastBlurEvent.current
            ? lastBlurEvent.current.__isFocusWithinEvent
            : true
        );
      }
      mouseDownWithinTarget.current = null;
      lastBlurEvent.current = null;
    },
    [setFocusState]
  );

  const getFocusProps = useCallback(
    ({
      onFocus: propOnFocus,
      onBlur: propOnBlur,
      onMouseDown: propOnMouseDown,
      ...props
    }: {
      onFocus?: (event: React.FocusEvent) => boolean;
      onBlur?: (event: React.FocusEvent) => boolean;
      onMouseDown?: (event: React.MouseEvent) => boolean;
    } = {}) => ({
      ...props,
      onFocus: (event: React.FocusEvent) => {
        const propagationStopped = propOnFocus && propOnFocus(event) === false;
        if (
          propagationStopped ||
          (event && (event as any).focusWithinDefaultPrevented)
        ) {
          return;
        }
        setFocusState(true, event && (event as any).__isFocusWithinEvent);
      },
      onBlur: (event: React.FocusEvent) => {
        const propagationStopped = propOnBlur && propOnBlur(event) === false;
        if (
          propagationStopped ||
          (event && (event as any).focusWithinDefaultPrevented)
        ) {
          return;
        }
        if (mouseDownWithinTarget.current) {
          event.persist && event.persist();
          lastBlurEvent.current = event;
          return;
        }
        setFocusState(false, event && (event as any).__isFocusWithinEvent);
      },
      onMouseDown: (event: React.MouseEvent) => {
        const propagationStopped =
          propOnMouseDown && propOnMouseDown(event) === false;
        if (
          propagationStopped ||
          (event && (event as any).focusWithinDefaultPrevented)
        ) {
          return;
        }
        mouseDownWithinTarget.current = event.currentTarget as any;
      },
    }),
    [setFocusState]
  );

  useEffect(() => {
    document.addEventListener('mouseup', onDocumentMouseUp);
    return () => {
      isUnmounted.current = true;
      document.removeEventListener('mouseup', onDocumentMouseUp);
    };
  }, [onDocumentMouseUp]);

  useEffect(() => {
    if (isBlurring) {
      delay(() => {
        if (isUnmounted.current) {
          return;
        }
        setIsBlurring(false);
        if (!isFocused) {
          onBlur?.({ __isFocusWithinEvent: true });
        }
      });
    }
    if (!isBlurring && isFocused && onFocus) {
      delay(onFocus, { __isFocusWithinEvent: true });
    }
  }, [isBlurring, isFocused, delay, onBlur, onFocus]);

  return { getFocusProps, isFocused: isFocused || isBlurring };
};

export const FocusWithin: React.FC<FocusWithinProps> = ({
  onBlur = () => {},
  onFocus = () => {},
  children,
}) => {
  const { getFocusProps, isFocused } = useFocusWithin(onFocus, onBlur);
  return typeof children === 'function' ? children({
    focusProps: getFocusProps(),
    getFocusProps,
    isFocused,
  }) : children;
};

export function withFocusWithin<P>(Component: React.ComponentType<P> | string) {
  const WrappedComponent: React.FC<
    P & { onFocus?: () => void; onBlur?: () => void }
  > = ({ onFocus, onBlur, ...props }) => (
    <FocusWithin onFocus={onFocus} onBlur={onBlur}>
      {({ getFocusProps, isFocused }) =>
        typeof Component === 'string' ? (
          <Component {...getFocusProps(props)} />
        ) : (
          <Component {...getFocusProps(props)} isFocused={isFocused} />
        )
      }
    </FocusWithin>
  );
  WrappedComponent.displayName = `WithFocusWithin(${
    typeof Component === 'string'
      ? Component
      : Component.displayName || Component.name || 'Component'
  })`;
  return WrappedComponent;
}
