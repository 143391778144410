import { ResponsivePie } from '@nivo/pie';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { PATTERN_DEFS, THEME, fillMatcher } from '../utils';
import { ChartContext, makeChartContextHook } from '../context/context';
import { BaseDatum, ChartProps } from '../types';
import { Loading } from './loading';
import { EmptyChart } from '../empty';
import { Tooltip } from '../tooltip';
import {
  GRAY_200,
  makeValueFormatter,
} from '@cotera/client/app/components/utils';
import { If } from '@cotera/client/app/components/ui';

export type TransformedDatum = {
  id: string;
  label: string;
  value: number;
  color: string;
};

type PieDatum = {
  category: string;
  value: number;
  style?: BaseDatum['style'];
};

export type Props = {
  labels?: boolean;
} & ChartProps<PieDatum>;

const useTypedChartContext = makeChartContextHook<PieDatum>();

export const PieChart: React.FC<Props> = ({
  data,
  labels,
  loading = false,
  format,
  ...layoutProps
}) => {
  return (
    <ChartContext data={data} labelKeys={['category']}>
      <ChartLayout {...layoutProps} menuItems={DEFAULT_MENU_ITEMS}>
        <If condition={loading}>
          <Loading />
        </If>
        <If condition={data.length === 0 && !loading}>
          <EmptyChart />
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart labels={labels} format={format} />
        </If>
      </ChartLayout>
    </ChartContext>
  );
};

const Chart: React.FC<{
  labels?: boolean;
  format: ChartProps<PieDatum>['format'];
}> = ({ labels: showLabels = true, format }) => {
  const _data = useTypedChartContext((s) => s.data);
  const labels = useTypedChartContext((s) => s.activeLabels);

  const total = _data.reduce((acc, x) => acc + Number(x.value), 0);

  const data = _data.map((x) => ({
    id: x.category,
    label: x.category,
    value: Number(x.value) / total,
    rawValue: Number(x.value),
  }));

  const valueFormatter = makeValueFormatter(format?.['value'] ?? 'none');

  return (
    <ResponsivePie
      theme={THEME}
      data={data}
      margin={{
        top: labels !== undefined ? 40 : 25,
        bottom: 20,
        left: 10,
        right: 10,
      }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      valueFormat=".0%"
      colors={(x) => labels.find((y) => y.label === x.id)?.color ?? GRAY_200}
      enableArcLinkLabels={showLabels ?? false}
      defs={PATTERN_DEFS}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      fill={labels.map(fillMatcher)}
      tooltip={(props) => (
        <Tooltip.Container>
          <Tooltip.Item>
            <Tooltip.Dot color={props.datum.color} />
            <strong>{props.datum.id}</strong>:{' '}
            {valueFormatter(
              data.find((x) => x.id === props.datum.id)?.rawValue ?? 0
            )}
          </Tooltip.Item>
        </Tooltip.Container>
      )}
    />
  );
};
