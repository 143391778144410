import { LoadingDots } from './dots';
import { Spinner } from './spinner/spinner';
import { Shimmer } from '../loading/shimmer';
import { Form } from './form';

export const Loading = {
  Spinner,
  Dots: LoadingDots,
  Shimmer,
  Form,
};
