import { Constant, Relation, Ty, Histogram as HistogramRel } from '@cotera/era';
import { CategoricalAttributes } from '@cotera/sdk/core';
import React, { Suspense } from 'react';
import { z } from 'zod';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Loading, Text, Tooltip } from '@cotera/client/app/components/ui';
import { Categorical, Histogram } from '@cotera/client/app/components/data-vis';
import { useArtifactQuery } from '@cotera/client/app/etc/data/duckdb';
import { METADATA_CONFIG } from '@cotera/client/app/etc/duckdb/constants';
import { DateDisplay } from '../../ui/typography';
import { ErrorBoundary } from 'react-error-boundary';
import { useSubscribe, useViewModelContext } from '@cotera/client/app/etc';
import { DataGridViewModel } from '../types';
import { isEqual } from 'lodash';

type NamedAttr = {
  name: string;
  ty: Ty.ExtendedAttributeType;
};

type HeaderProps = {
  rel: Relation;
  attr: NamedAttr;
  filterValue?: string;
  summary?: {
    min: string | null;
    max: string | null;
    unique: number | null;
  };
  onClick?: (value: any) => void;
};

export const AsyncHeaderWrapper: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <ErrorBoundary fallbackRender={({ error }) => <ErrorStats error={error} />}>
      <Suspense fallback={<LoadingStats />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export const BaseHeader = ({
  children,
  attr,
}: {
  attr: {
    name: string;
    ty?: React.ReactNode;
  };
  children?: ChildrenProps['children'];
}) => {
  return (
    <div className="flex relative flex-col px-3 w-full pb-2 bg-white -mt-0.5">
      <div className="whitespace-nowrap font-semibold inline-block w-[calc(100%-12px)] overflow-hidden text-ellipsis">
        {attr.name}
      </div>
      {attr.ty !== undefined && (
        <Text.Caption className="mb-2 whitespace-nowrap text-ellipsis verflow-hidden">
          {attr.ty}
        </Text.Caption>
      )}
      {children}
    </div>
  );
};

export const ContinuousHeader: React.FC<HeaderProps> = ({ rel, attr }) => {
  const { data } = useArtifactQuery({
    baseRel: rel,
    limit: 50_000,
    rel: (rel) =>
      HistogramRel(
        rel,
        (r) => ({ target: r.attr(attr.name), group: Constant('all') }),
        { numBuckets: 10 }
      ),
  });
  const vm = useViewModelContext<DataGridViewModel>();
  const filters = useSubscribe(vm, (s) => s.filters, isEqual);
  const activeFilter = filters[attr.name];

  return (
    <div className="w-full h-[45px]">
      <Histogram
        activeBucket={
          activeFilter?.t === 'range'
            ? {
                min: Number(activeFilter.min),
                max: Number(activeFilter.max),
              }
            : undefined
        }
        onClick={(min, max) => {
          if (
            activeFilter?.t === 'range' &&
            activeFilter?.min === String(min) &&
            activeFilter?.max === String(max)
          ) {
            vm.filter(
              Object.fromEntries(
                Object.entries(filters).filter(([k]) => k !== attr.name)
              )
            );
          } else {
            vm.filter({
              ...filters,
              [attr.name]: {
                t: 'range' as const,
                min: String(min),
                max: String(max),
              },
            });
          }
        }}
        buckets={data.data.toArrayOf(
          z.object({
            count: z.number().default(0),
            start: z.number().nullable(),
            end: z.number().nullable(),
          })
        )}
      />
    </div>
  );
};

export const CategoricalHeader: React.FC<HeaderProps> = ({
  rel,
  attr,
  summary,
}) => {
  const result = useArtifactQuery({
    baseRel: rel,
    limit: 50_000,
    rel: (rel) =>
      CategoricalAttributes(rel, (r) => r.attr(attr.name), {
        discreteThreshold: METADATA_CONFIG.MAX_CATEGORIES,
      }),
  });
  const vm = useViewModelContext<DataGridViewModel>();
  const filters = useSubscribe(vm, (s) => s.filters, isEqual);
  const activeFilter = filters[attr.name];

  return (
    <div className="w-full h-[45px]">
      <Categorical
        categories={result.data.data.toArrayOf(
          z.object({ count: z.number(), value: z.string().nullable() })
        )}
        active={activeFilter?.t === 'one-of' ? activeFilter.values : []}
        numDistinct={summary?.unique ?? 0}
        onClick={(c) => {
          const existingValues =
            activeFilter?.t === 'one-of' ? activeFilter.values : [];
          const filterValues = [
            ...existingValues.filter((v) => v !== c),
          ].concat(existingValues.includes(c) ? [] : [c]);
          if (filterValues.length === 0) {
            vm.filter(
              Object.fromEntries(
                Object.entries(filters).filter(([k]) => k !== attr.name)
              )
            );
          } else {
            vm.filter({
              ...filters,
              [attr.name]: {
                t: 'one-of' as const,
                values: filterValues,
              },
            });
          }
        }}
      />
    </div>
  );
};

export const TimestampHeader = ({ summary }: HeaderProps) => {
  return (
    <div className="flex flex-col w-full">
      {summary?.min && (
        <Text.Caption className="text-xs mb-1">
          Min: <DateDisplay date={new Date(summary.min)} />
        </Text.Caption>
      )}
      {summary?.max && (
        <Text.Caption className="text-xs">
          Max: <DateDisplay date={new Date(summary.max)} />
        </Text.Caption>
      )}
    </div>
  );
};

const LoadingStats = () => (
  <div className="h-[45px] flex flex-col">
    <Loading.Shimmer className="h-[25px] w-full mb-2" />
    <Loading.Shimmer className="h-[8px] w-1/2 " />
  </div>
);

const ErrorStats: React.FC<{ error?: any }> = ({ error }) => {
  return (
    <Tooltip text={String(error)} side="bottom">
      <div className="h-[45px] flex flex-col">
        <div className="h-[25px] w-full text-red-500 font-semibold text-xs">
          Unable to load data
        </div>
      </div>
    </Tooltip>
  );
};
