import { AST } from '@cotera/era';
import { CompileMarkup } from './compiler';

const WIDTHS = {
  '1/2': 'w-full md:w-1/2',
  '1/3': 'w-full md:w-1/3',
  '2/3': 'w-full md:w-2/3',
} as const;

export const RenderWidth: React.FC<{
  section: AST._Width;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  return (
    <div className={WIDTHS[section.size]}>
      <CompileMarkup section={section.section} inBlock={inBlock} />
    </div>
  );
};
