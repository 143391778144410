import { z } from 'zod';

export const FEATURE_FLAGS = [
  '_Test1',
  '_Test2',
  '_Test3',
  'Connections',
  'SendCredentialsToVfs',
  'UseLocalDevManifest',
  'ExploreV2',
] as const;

export const FeatureFlagSchema = z.enum(FEATURE_FLAGS);
export const FeatureFlagsSchema = z.record(FeatureFlagSchema, z.boolean());
export type FeatureFlag = z.infer<typeof FeatureFlagSchema>;
export type FeatureFlags = z.infer<typeof FeatureFlagsSchema>;
