export const deepFreeze = (x: unknown) => {
  Object.freeze(x);

  if (typeof x === 'object') {
    for (const key in x) {
      deepFreeze((x as Record<string, unknown>)[key]);
    }
  }
};

/**
 * Kludge to make isArray work with readonly
 *
 * https://github.com/microsoft/TypeScript/issues/17002
 * https://stackoverflow.com/questions/56248618/how-to-check-if-an-object-is-a-readonly-array-in-typescript

 */
export const isReadonlyArray = (x: any): x is ReadonlyArray<any> =>
  Array.isArray(x);
