import _ from 'lodash';
import { ExprVar, Expression } from '../expression';
import { RelVar, Relation } from '../relation';
import { Markup } from '../markup/section';
import { Ty } from '../../ty';

export const GLOBAL_SCOPE = '@@global';

export const MarkupVar = (params: { name: string; scope: string }): Markup =>
  Markup.fromAst(
    { t: 'markup-var', identifier: params.name, scope: params.scope },
    { jsStackPointer: MarkupVar }
  );

export type MacroArgs = {
  readonly [name: string]: Expression | Relation;
};

export type MacroBody<Args extends MacroArgs, Output> = (parmas: {
  [Key in keyof Args]: Args[Key] extends Relation
    ? Relation
    : Expression | NonNullable<Ty.Scalar>;
}) => Output;

export const mkMacroVars = <Args extends MacroArgs>(
  scope: string,
  args: Args
): Args =>
  _.mapValues(args, (defaultVal, name): Expression | Relation => {
    if (defaultVal instanceof Relation) {
      return RelVar.create({
        name,
        ty: defaultVal.attributes,
        default: defaultVal,
        scope,
      });
    } else {
      return ExprVar.create({
        name,
        scope,
        ty: defaultVal.ty,
        default: defaultVal,
      });
    }
  }) as Args;
