import { Case, From, MakeStruct, RelationRef } from '@cotera/era';
import { OREO_REVIEWS } from '../../assumptions';
import { TAXONOMY } from './constants';

const mapName = (name: string) => {
  return name.toUpperCase().replace(' - ', '__');
};

const textToInt = (t: RelationRef, attr: string) => {
  const a = mapName(attr);
  return Case([
    { when: t.attr(a).eq('Positive'), then: 1 },
    { when: t.attr(a).eq('Negative'), then: -1 },
    { when: t.attr(a).eq('Neutral'), then: 0 },
  ]);
};

export const Sentiments = From(OREO_REVIEWS).select((t) => ({
  ...t.star(),
  PREDICTIONS: MakeStruct({
    ...Object.fromEntries(TAXONOMY.map((col) => [col, textToInt(t, col)])),
  }),
  SENTIMENT: TAXONOMY.map((col) => textToInt(t, col)).reduce((a, b) =>
    a.add(b)
  ),
}));
