import { BaseDatum, ChartAction, ChartProps } from './types';
import { Relation } from '@cotera/era';
import { useState } from 'react';
import { Button } from '@cotera/client/app/components/ui';
import { DataGridForRelation } from '@cotera/client/app/components/data-vis';

const DataTableMenuItem = (active: boolean): ChartAction => ({
  active,
  id: 'rel-table',
  label: 'Show data table',
  icon: (props) => <Button icon="table-cells" inline {...props} />,
});

const action = (onClick: () => void) => ({
  'rel-table': () => onClick(),
});

export function DataGridChartWrapper<
  K extends BaseDatum,
  T extends ChartProps<K>
>({
  chart: Chart,
  rel,
  fromArtifactId,
  ...rest
}: T & {
  chart: React.FC<T>;
  rel: Relation;
  fromArtifactId: string | null;
}) {
  const [showTable, setShowTable] = useState(false);

  return (
    <>
      <Chart
        {...(rest as any)}
        menuItems={[...(rest.menuItems ?? []), DataTableMenuItem(showTable)]}
        menuItemHandlers={{
          ...(rest.menuItemHandlers ?? {}),
          ...action(() => setShowTable(!showTable)),
        }}
      />
      {showTable && (
        <div className="w-full mt-4 pb-6">
          <DataGridForRelation
            defaultRowCount={10}
            rel={rel}
            fromArtifactId={fromArtifactId}
          />
        </div>
      )}
    </>
  );
}
