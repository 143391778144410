import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

export const Row: React.FC<
  ChildrenProps & {
    spaceEvenly?: boolean;
    center?: boolean;
    className?: string;
  }
> = ({ children, spaceEvenly, center = true, className }) => {
  return (
    <div
      className={classNames(
        'flex flex-row w-full',
        spaceEvenly ? 'justify-between' : '',
        center ? 'items-center' : '',
        className
      )}
    >
      {children}
    </div>
  );
};
