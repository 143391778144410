import { BaseDatum, ChartProps } from './types';
import { Relation } from '@cotera/era';
import { DataGridChartWrapper } from './data-table';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { DisplayError } from '@cotera/client/app/components/app';
import { useFilterContext } from '../../contexts/filters';

type JSXEl = JSX.Element | React.ReactNode;

type ChartForRelationProps<K extends BaseDatum, T extends ChartProps<K>> = {
  rel: Relation;
  chart: (props: T) => JSXEl;
} & Omit<T, 'data' | 'loading'>;

function ChartContainer<K extends BaseDatum, T extends ChartProps<K>>(
  props: ChartForRelationProps<K, T>
) {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <DisplayError error={error} />}
    >
      <Suspense
        fallback={
          <DataGridChartWrapper
            {...(props as any)}
            fromArtifactId={null}
            data={[]}
            loading={true}
          />
        }
      >
        <InnerChartForRelation {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

function InnerChartForRelation<K extends BaseDatum, T extends ChartProps<K>>({
  rel,
  chart: Chart,
  ...rest
}: ChartForRelationProps<K, T>) {
  const { data } = useDuckDBQuery({ rel });
  const updateFilter = useFilterContext((s) => s.actions.update);

  const chartData = data.data.toArray() ?? [];

  return (
    <DataGridChartWrapper
      {...(rest as any)}
      fromArtifactId={data.data ?? null}
      chart={Chart}
      rel={rel}
      data={chartData}
      loading={false}
      onLegendClick={(labels: { id: string; hidden: boolean }[]) => {
        if (rel.ast.t === 'select') {
          //TODO this is a bit of a hack - will only work for charts that have a "category" property
          const category = rel.ast.selection['category'];
          if (category?.t === 'attr') {
            updateFilter(category.name, {
              t: category.ty.ty,
              values: labels.filter((x) => !x.hidden).map((x) => x.id),
            });
          }
        }
      }}
    />
  );
}

const ChartForRelation = React.memo(ChartContainer, (prev, next) => {
  const { rel, ...props } = prev;
  const { rel: nextRel, ...nextProps } = next;

  return (
    rel.sqlHash() === nextRel.sqlHash() &&
    Object.keys(props).every((key) => {
      const k = key as keyof typeof props;
      return props[k] === nextProps[k];
    })
  );
});

export function makeChartRelComponent<
  K extends BaseDatum,
  T extends Omit<ChartProps<K>, 'data' | 'loading'>
>(chart: (props: T & { data: K[]; loading: boolean }) => JSXEl) {
  return function ChartRelComponent({
    rel,
    ...rest
  }: {
    rel: Relation;
  } & Omit<T, 'data' | 'loading'>) {
    return <ChartForRelation {...(rest as any)} chart={chart} rel={rel} />;
  };
}
