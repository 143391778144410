import {
  TimeSeriesFormat,
  isTimeSeriesFormat,
  Format,
} from '@cotera/client/app/components/utils';

export const PATTERN_DEFS = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    size: 4,
    padding: 1,
    stagger: true,
  },
  {
    id: 'lines',
    type: 'patternLines',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    rotation: -45,
    lineWidth: 6,
    spacing: 10,
  },
];

export const THEME = {
  axis: {
    legend: {
      text: {
        fill: '#64748b',
      },
    },
    ticks: {
      text: { fill: '#64748b' },
    },
  },
  crosshair: {
    line: {
      stroke: '#000000',
      strokeWidth: 1,
      strokeOpacity: 0.35,
    },
  },
};

export type Axis = {
  label?: string;
  scale?:
    | 'linear'
    | 'day'
    | 'point'
    | 'day'
    | 'month'
    | 'year'
    | 'millisecond'
    | 'second'
    | 'minute'
    | 'hour';
};

export const scaleToFormat = (scale: Axis['scale']): Format['unit'] => {
  if (scale === 'linear') {
    return 'number';
  }
  if (scale === 'point') {
    return 'none';
  }
  if (isTimeSeriesFormat({ unit: scale as TimeSeriesFormat })) {
    return scale;
  }
  return 'none';
};

export const isDateAxis = (axis?: Axis) => {
  return isTimeSeriesFormat({ unit: axis?.scale as TimeSeriesFormat });
};

export const fillMatcher = (x: { fill: string; label: string }) => {
  return {
    match: {
      id: x.label,
    },
    id: x.fill,
  };
};

export const getTickLegendSize = (legends: string[], padding: number = 10) => {
  return Math.min(
    Math.max(...legends.map((x) => String(x).length)) * 6 + padding,
    150
  );
};

export const makeTickValues = (scale: Format['unit']) => {
  switch (scale) {
    case 'month-only':
      return 'every 1 month';
    case 'month':
      return 'every 1 month';
    case 'year':
      return 'every 1 year';
    default:
      return undefined;
  }
};
