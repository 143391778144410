import { AST } from '../ast';
import type { Vars } from './base';
import _ from 'lodash';

type VarCacheEntry<Output extends WeakKey> = {
  next: WeakMap<
    Partial<AST.MacroVarReplacements<AST.IRChildren>>,
    VarCacheEntry<Output>
  >;
  at: WeakRef<Output> | null;
};

export type CacheEntry<T> =
  | { readonly t: 'existing'; readonly val: T }
  | { readonly t: 'missing'; readonly set: (val: T) => void };

export const buildCacheEntryFn = <
  Target extends WeakKey,
  Output extends WeakKey
>(x: {
  openScopes: (t: Target) => Set<string>;
}) => {
  const CACHE: WeakMap<Target, VarCacheEntry<Output>> = new WeakMap();

  return (target: Target, scopeVars: Vars): CacheEntry<Output> => {
    Object.freeze(target);
    Object.freeze(scopeVars);

    const openScopes = x.openScopes(target);

    let entry: VarCacheEntry<Output> | undefined = CACHE.get(target);

    if (!entry) {
      entry = { at: null, next: new WeakMap() };
      CACHE.set(target, entry);
    }

    const varEntries = _.sortBy(Object.entries(scopeVars), ([scope]) => scope);

    for (const [scope, vars] of varEntries) {
      if (openScopes.has(scope)) {
        Object.freeze(vars);
        let next: VarCacheEntry<Output> | undefined = entry!.next.get(vars);

        if (next === undefined) {
          next = { next: new WeakMap(), at: null };
          entry.next.set(vars, next);
        }

        entry = next;
      }
    }

    const val = entry.at?.deref();

    return val
      ? { t: 'existing', val }
      : {
          t: 'missing',
          set: (val) => {
            entry.at = new WeakRef(Object.freeze(val));
          },
        };
  };
};
