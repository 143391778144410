import { Expression } from '@cotera/era';

/**
 * This function is very specialized to calculate the weight of a customer's RFM duration in a given segment.
 * It is looking for the 1st of the month, 11th of the month, and 21st of the month to determine if the month should be split into 1, 2, or 3 parts.
 * @param date
 * @returns a number between 1 and 3 to represent fraction of the month
 */
export const rfmRounder = (date: Date | Expression): Expression => {
  const expr = Expression.wrap(date);
  return expr.datePart('day').div(10.5).floor().add(1);
};
