import { Contract, GET } from '@cotera/contracts';
import { Parser, AST, RelationRef, Expression, Ty } from '@cotera/era';
import { z } from 'zod';
import type { SinkConfig } from './sink-config';
import { SinkConfigSchema } from './sink-config';

export const ScheduleSchema = z
  .enum(['hourly', 'daily'])
  .or(z.object({ cron: z.string() }));

export type Schedule = 'hourly' | 'daily' | { readonly cron: string };

export const ManifestCompileErrorSchema = z.object({
  errorType: z.literal('CompileError'),
  stackTrace: z.string(),
});

export type _Sink = {
  readonly config: SinkConfig;
  readonly condition: AST.ExprFR;
  readonly manualVerification: boolean;
  readonly select: {
    readonly [name: string]: AST.ExprFR;
  };
};

export type Sink = {
  readonly config: SinkConfig;
  readonly manualVerification?: boolean;
  readonly condition: (t: RelationRef) => boolean | Expression;
  readonly select: (t: RelationRef) => {
    readonly [attrName: string]: Ty.Scalar | Expression;
  };
};

export type SinkType = SinkConfig['t'];

export const SinkSchema: z.ZodSchema<_Sink> = z.object({
  select: z.record(Parser.IdentifierSchema, Parser.ExprSchema),
  condition: Parser.ExprSchema,
  config: SinkConfigSchema,
  manualVerification: z.boolean(),
});

export const ManifestSkeletonSchema = z.object({
  detailPages: z.record(Parser.ExtendedAttributeTypeSchema),
  version: z.string(),
  materializations: z
    .object({
      schema: z.string(),
      viewName: z.string(),
      schedule: z.string().or(z.object({ cron: z.string() })),
    })
    .array(),
  udds: z
    .array(
      z.object({
        id: z
          .object({
            k: z.literal('id'),
            name: z.string(),
            t: z.enum(['string', 'int']),
          })
          .or(z.string()),
        attributes: z.record(Parser.ExtendedAttributeTypeSchema),
      })
    )
    .readonly(),
  definitions: z.record(
    z.object({
      id: z.string(),
      eager: Parser.RelSchema.nullable(),
      displayName: z.string(),
      hidden: z.boolean(),
      attributes: z.record(
        Parser.IdentifierSchema,
        Parser.ExtendedAttributeTypeSchema
      ),
    })
  ),
  apps: z.record(
    z.object({
      title: z.string(),
      icon: z.enum(AST.MARKUP_ICONS),
      hasHomePage: z.boolean(),
    })
  ),
  schemas: z.object({ read: z.string().array() }),
  eventStreams: z.array(
    z.object({
      entityId: Parser.IdTypeSchema,
      name: z.string(),
      sinks: z.record(SinkSchema),
      when: ScheduleSchema,
      identifier: Parser.ExprSchema,
      rel: Parser.RelSchema,
    })
  ),
});

export type ManifestSkeleton = z.infer<typeof ManifestSkeletonSchema>;
export type ManifestCompilerError = z.infer<typeof ManifestCompileErrorSchema>;

export const DevSeverSyncSchema = z.object({
  org: z.object({ id: z.string(), name: z.string() }),
  entities: z.record(
    z.object({
      idType: Parser.AttributeTypeSchema,
      udds: z.record(
        z.object({
          ty: Parser.ExtendedAttributeTypeSchema,
          displayName: z.string(),
        })
      ),
    })
  ),
});

export type DevSeverSync = z.infer<typeof DevSeverSyncSchema>;

export const ManifestContract = Contract.new({
  skeleton: GET({
    params: z.object({}),
    errors: ManifestCompileErrorSchema,
    output: ManifestSkeletonSchema,
  }),
  app: GET({
    params: z.object({ id: z.string(), version: z.string() }),
    errors: ManifestCompileErrorSchema,
    output: Parser.AppSchema.nullable(),
  }),
  detailsPage: GET({
    params: z.object({ page: z.string(), version: z.string() }),
    errors: ManifestCompileErrorSchema,
    output: Parser.MarkupSchema.nullable(),
  }),
  definition: GET({
    params: z.object({ id: z.string(), version: z.string() }),
    errors: ManifestCompileErrorSchema,
    output: Parser.RelSchema.nullable(),
  }),
});

export const DevServerContract = Contract.new({
  manifests: ManifestContract,
});
