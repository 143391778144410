import { Animated as Base, AnimatedProps } from 'react-animated-css';
import { ChildrenProps } from '@cotera/client/app/components/utils';

export * from './fade-in';
export * from './collapse';
export * from './rotate';

//annoying hack as react-animated-css doesn't export the type that allows children
export const Animated = Base as unknown as React.FC<
  ChildrenProps & AnimatedProps
>;
