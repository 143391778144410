import { z } from 'zod';

export const WarehouseTypeSchema = z.enum([
  'SNOWFLAKE',
  'BIGQUERY',
  'POSTGRES',
  'REDSHIFT',
  'DUCKDBNATIVE',
  'DUCKDBWASM',
]);

export const ConnectionPoolSchema = z.object({
  name: z.string(),
  size: z.number(),
});

const withMetadata = (schema, metadata) => {
  schema._def.meta = metadata;
  return schema;
};

export const SnowflakeCredentialsSchema = z.object({
  user: z.string(),
  password: withMetadata(z.string(), { type: 'password' }),
  account_id: z.string(),
  database: z.string(),
  read_schema: z.string(),
  write_schema: z.string(),
  warehouse: z.string(),
  role: z.string().optional(),
});

export const PostgresCredentialsSchema = z.object({
  user: z.string(),
  password: withMetadata(z.string(), { type: 'password' }),
  host: z.string(),
  port: z.string(),
  database: z.string(),
  options: z.string().optional(),
  read_schema: z.string(),
  write_schema: z.string(),
});

export const RedshiftCredentialsSchema = z.object({
  user: z.string(),
  password: withMetadata(z.string(), { type: 'password' }),
  host: z.string(),
  port: z.string(),
  database: z.string(),
  options: z.string().optional(),
  read_schema: z.string(),
  write_schema: z.string(),
});

export const DuckDbCredentialsSchema = z.object({
  write_schema: z.string().default('main'),
  read_schema: z.string().default('main'),
  location: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(':memory:'),
    }),
    z.object({
      type: z.literal('file'),
      path: z.string(),
    }),
  ]),
});

export const BigQueryCredentialsSchema = z.object({
  type: z.string(),
  project_id: z.string(),
  private_key_id: z.string(),
  private_key: withMetadata(z.string(), { type: 'password' }),
  client_email: z.string(),
  client_id: z.string(),
  auth_uri: z.string(),
  token_uri: z.string(),
  auth_provider_x509_cert_url: z.string(),
  client_x509_cert_url: z.string(),
  read_schema: z.string(),
  write_schema: z.string(),
});

export const WarehouseCredentialsSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('POSTGRES'),
    credentials: PostgresCredentialsSchema,
  }),
  z.object({
    type: z.literal('REDSHIFT'),
    credentials: RedshiftCredentialsSchema,
  }),
  z.object({
    type: z.literal('BIGQUERY'),
    credentials: BigQueryCredentialsSchema,
  }),
  z.object({
    type: z.literal('SNOWFLAKE'),
    credentials: SnowflakeCredentialsSchema,
  }),
  z.object({
    type: z.literal('DUCKDBNATIVE'),
    credentials: DuckDbCredentialsSchema,
  }),
  z.object({
    type: z.literal('DUCKDBWASM'),
    credentials: DuckDbCredentialsSchema,
  }),
]);
