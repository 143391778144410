import { z } from 'zod';
import { Ty } from '../ty';

export const IdentifierSchema = z.string().min(1).max(63);

export const IdTypeSchema = z.object({
  k: z.literal('id'),
  t: z.enum(['int', 'string']),
  name: z.string(),
});

export const PrimitiveAttributeTypeSchema = z.enum(
  Ty.PRIMITIVE_ATTRIBUTE_TYPES,
);

const PrimitiveTy = z.object({
  k: z.literal('primitive'),
  t: PrimitiveAttributeTypeSchema,
});

const EnumTy = z.object({
  k: z.literal('enum'),
  t: z.array(z.string()).readonly(),
});

export const AttributeTypeSchema = z.lazy(() =>
  z.discriminatedUnion('k', [
    PrimitiveTy,
    EnumTy,
    z.object({
      k: z.literal('struct'),
      fields: z.record(IdentifierSchema, ExtendedAttributeTypeSchema),
    }),
    z.object({
      k: z.enum(['array', 'record']),
      t: ExtendedAttributeTypeSchema,
    }),
    z.object({
      k: z.literal('id'),
      name: z.string(),
      t: z.enum(['string', 'int']),
    }),
    z.object({
      k: z.literal('range'),
      start: z.number().int(),
      end: z.number().int(),
      t: z.literal('int'),
    }),
  ]),
);

export const ExtendedAttributeTypeSchema = z.object({
  ty: AttributeTypeSchema,
  nullable: z.boolean(),
  tags: z.array(z.string()),
});
