import { AST, Constant, Ty, Relation } from '@cotera/era';
import React, { useContext } from 'react';
import { UiStateContext } from '../ui-state';
import { Assert } from '@cotera/utilities';
import { Column } from '../../components/column';
import { Text } from '@cotera/client/app/components';
import { startCase } from 'lodash';
import { TabSelector } from './tab-selector';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { z } from 'zod';
import { Nav } from './nav';
import {
  useEraRuntimeCurrentExprValue,
  useEraScopesAwareRelIR,
} from '../macro-expansion/scopes';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';

export const RenderExprControlsV2: React.FC<{
  section: AST._ExprControlsV2;
  inBlock: boolean;
}> = ({ section }) => {
  const sendUpdate = useContext(UiStateContext);
  const currentValue = useEraRuntimeCurrentExprValue(section.var);

  const set = (value: Ty.Scalar): void =>
    sendUpdate.expr({
      scope: section.var.scope,
      name: section.var.name,
      cb: () => Constant(value, { ty: section.var.ty }),
    });

  if (section.config === null) {
    const { ty } = section.var.ty;
    if (ty.k === 'primitive' || ty.k === 'id') {
      const { t } = ty;
      switch (t) {
        case 'boolean': {
          Assert.assert(typeof currentValue === 'boolean');
          return (
            <BoolToggle
              current={currentValue}
              name={section.var.name}
              set={set}
            />
          );
        }
        case 'float':
        case 'int': {
          Assert.assert(typeof currentValue === 'number');
          return (
            <NumberInput
              ty={t}
              name={section.var.name}
              set={set}
              current={currentValue}
            />
          );
        }
        case 'day':
        case 'month':
        case 'year':
        case 'timestamp': {
          Assert.assert(currentValue instanceof Date);
          return (
            <TimestampInput
              current={currentValue}
              set={set}
              name={section.var.name}
            />
          );
        }
        case 'string': {
          Assert.assert(typeof currentValue === 'string');
          return (
            <StringInput
              current={currentValue}
              set={set}
              name={section.var.name}
            />
          );
        }
        case 'super':
          throw new Error(
            `We dont support inputs of type ${Ty.displayTy(section.var.ty)}`
          );
        default:
          return Assert.unreachable(t);
      }
    } else if (ty.k === 'enum') {
      Assert.assert(typeof currentValue === 'string');
      return (
        <PickList
          active={currentValue}
          name={section.var.name}
          options={ty.t}
          set={set}
          display={ty.t.length > 5 ? 'picklist' : 'toggle'}
          label={section.label}
        />
      );
    } else if (ty.k === 'range') {
      Assert.assert(typeof currentValue === 'number');
      return (
        <NumberInput
          ty="int"
          name={section.var.name}
          set={set}
          current={currentValue}
        />
      );
    } else if (ty.k === 'array' || ty.k === 'struct' || ty.k === 'record') {
      throw new Error(
        `We dont support inputs of type ${Ty.displayTy(section.var.ty)}`
      );
    } else {
      return Assert.unreachable(ty);
    }
  }

  switch (section.config.t) {
    case 'picklist': {
      return (
        <PickList
          name={section.var.name}
          active={currentValue as string}
          set={set}
          options={section.config.options}
          display={section.config.display}
          label={section.label}
        />
      );
    }
    case 'pickfrom': {
      return (
        <PickFrom
          label={section.label}
          name={section.var.name}
          active={currentValue as string}
          set={set}
          rel={section.config.rel}
          display={section.config.display}
        />
      );
    }
    default:
      return Assert.unreachable(section.config);
  }
};

const PickFrom: React.FC<{
  name: string;
  active: string;
  rel: AST.RelFR;
  display: 'picklist' | 'tab-selector' | 'toggle' | 'nav';
  set: (x: Ty.Scalar) => void;
  label: boolean;
}> = (props) => {
  const rel = useEraScopesAwareRelIR(props.rel);
  const { data } = useDuckDBQuery({ rel: Relation.wrap(rel) });
  const opts = data.data
    .toArrayOf(z.object({ option: z.string() }))
    .map((x) => x.option)
    .sort();

  switch (props.display) {
    case 'nav':
      return <Nav options={opts} active={props.active} set={props.set} />;
    case 'picklist':
      return <PickList {...props} options={opts} display="picklist" />;
    case 'tab-selector':
      return <TabSelector {...props} options={opts} />;
    case 'toggle':
      return (
        <Inputs.Toggle
          {...props}
          label={props.name}
          options={opts}
          onChange={(x) => props.set(x!)}
        />
      );
    default:
      return Assert.unreachable(props.display);
  }
};

const BoolToggle: React.FC<{
  readonly current: boolean;
  readonly name: string;
  readonly set: (x: boolean) => void;
}> = ({ current, name, set }) => {
  return (
    <label>
      {name}
      <input type="checkbox" checked={current} onChange={() => set(!current)} />
    </label>
  );
};

const PickList: React.FC<{
  active: string;
  name: string;
  set: (x: string | null) => void;
  options: readonly string[];
  display: 'toggle' | 'picklist' | 'tab-selector' | 'nav';
  label: boolean;
}> = ({ set, options, active, name, display, label }) => {
  let inner: React.ReactNode = null;

  switch (display) {
    case 'picklist':
      inner = (
        <ComboBox.Select
          value={{ value: active, display: active ?? '(null)' }}
          options={options.map((x) => ({ display: x, value: x }))}
          onChange={(value) => set(value!.value)}
        />
      );
      break;
    case 'toggle':
      inner = (
        <Inputs.Toggle
          value={active ?? options[0]!}
          options={options}
          onChange={(value) => set(value!)}
        />
      );
      break;
    case 'tab-selector':
      inner = <TabSelector options={options} active={active} set={set} />;
      break;
    case 'nav':
      inner = <Nav options={[...options]} active={active} set={set} />;
      break;
    default:
      return Assert.unreachable(display);
  }
  return (
    <Column className="grow-0 w-full">
      {label && (
        <Text.Caption className="mb-2 text-sm">{startCase(name)}</Text.Caption>
      )}
      {inner}
    </Column>
  );
};

const NumberInput: React.FC<{
  ty: 'float' | 'int';
  current: number;
  set: (x: number) => void;
  name: string;
}> = ({ current, set, name, ty }) => {
  return (
    <label>
      {name}
      <input
        type="number"
        onChange={(x) => {
          if (x.target.value !== '') {
            const val =
              ty === 'int' ? parseInt(x.target.value) : Number(x.target.value);
            set(val);
          }
        }}
        value={current}
      />
    </label>
  );
};

const TimestampInput: React.FC<{
  current: Date;
  set: (x: Date) => void;
  name: string;
}> = ({ name, set, current }) => {
  return (
    <label>
      {name}
      <input
        type="date"
        onChange={(x) => {
          const d = new Date(x.target.value);
          set(d);
        }}
        value={current.toISOString()}
      />
    </label>
  );
};

const StringInput: React.FC<{
  current: string;
  set: (x: string) => void;
  name: string;
}> = ({ name, set, current }) => {
  return (
    <label>
      {name}
      <input
        onChange={(x) => {
          set(x.target.value);
        }}
        value={current}
      />
    </label>
  );
};
