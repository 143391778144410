import { AST } from '../../ast';
import { Assert } from '../../utils';
import _ from 'lodash';
import { FUNCTION_TYPING_RULES } from './function-call';

type GroupedByAttrReqs = Record<string, Set<string>>;

export const attrsThatMustBeGroupedOn = (
  expr: AST.ExprFR
): GroupedByAttrReqs => {
  const { t } = expr;

  switch (t) {
    case 'expr-var':
    case 'scalar': {
      return {};
    }
    case 'cast':
    case 'get-field': {
      return attrsThatMustBeGroupedOn(expr.expr);
    }
    case 'attr': {
      return { [expr.source]: new Set([expr.name]) };
    }
    case 'make-struct': {
      return mergeAttrs(
        ...Object.values(expr.fields).map((field) =>
          attrsThatMustBeGroupedOn(field)
        )
      );
    }
    case 'make-array': {
      return mergeAttrs(
        ...expr.elements.map((elem) => attrsThatMustBeGroupedOn(elem))
      );
    }
    case 'case': {
      return mergeAttrs(
        ...expr.cases.flatMap(({ when, then }) =>
          [when, then].map(attrsThatMustBeGroupedOn)
        ),
        ...(expr.else ? [attrsThatMustBeGroupedOn(expr.else)] : [])
      );
    }
    case 'invariants': {
      return mergeAttrs(
        attrsThatMustBeGroupedOn(expr.expr),
        ...Object.values(expr.invariants).map((expr) =>
          attrsThatMustBeGroupedOn(expr)
        )
      );
    }
    case 'window': {
      return mergeAttrs(
        ...expr.args.map((arg) => attrsThatMustBeGroupedOn(arg)),
        ...expr.over.orderBy.map(({ expr }) => attrsThatMustBeGroupedOn(expr))
      );
    }
    case 'function-call': {
      const { op } = expr;

      if (FUNCTION_TYPING_RULES[op].aggregate) {
        return {};
      }

      return mergeAttrs(
        ...expr.args.map((arg) => attrsThatMustBeGroupedOn(arg))
      );
    }
    case 'macro-expr-case':
      return mergeAttrs(
        attrsThatMustBeGroupedOn(expr.else),
        ...expr.cases
          .flatMap(({ when, then }) => [when, then])
          .map((x) => attrsThatMustBeGroupedOn(x))
      );
    case 'macro-apply-vars-to-expr':
      return attrsThatMustBeGroupedOn(expr.sources.from);
    default:
      return Assert.unreachable(t);
  }
};

const mergeAttrs = (...xs: GroupedByAttrReqs[]): GroupedByAttrReqs => {
  const merged: GroupedByAttrReqs = {};
  for (const x of xs) {
    for (const [source, attrs] of Object.entries(x)) {
      const s = merged[source as 'left' | 'right' | 'from'] ?? new Set();
      for (const attr of attrs) {
        s.add(attr);
      }
      merged[source] = s;
    }
  }

  return merged;
};
