import { Link } from 'react-router-dom';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

const BottomNavItem: React.FC<{
  text: string;
  isActive?: boolean;
  icon?:
    | ((props: { className?: string }) => JSX.Element | React.ReactNode)
    | null;
  type: 'link' | 'button';
  to?: string;
  onClick?: () => void;
}> = (props) => {
  const Icon = props.icon ?? (() => null);

  return (
    <div
      key={props.text}
      className={
        '[&:not(:last-child)]:border-r border-divider pr-4 mr-2 flex items-center'
      }
    >
      {
        <Icon
          className={classNames(
            'h-4 w-4',
            props.isActive ? 'text-primary-text' : 'text-standard-text'
          )}
        />
      }
      <span
        className={classNames(
          props.isActive
            ? 'text-primary-text'
            : 'hover:text-standard-text text-standard-text',
          'ml-2 whitespace-nowrap'
        )}
      >
        <Link to={props.to ?? ''}>{props.text}</Link>
      </span>
    </div>
  );
};

const Container: React.FC<ChildrenProps & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'fixed bottom-0 text-sm h-[40px] overflow-auto items-center flex w-full bg-white border-t botder-r border-b border-divider px-6 py-2',
        className
      )}
    >
      {children}
    </div>
  );
};

/**
 * @deprecated
 */
export const BottomNav = {
  Item: BottomNavItem,
  Container,
};
