import { Hint, TextHints } from '@cotera/client/app/components/app';
import { Inputs } from '@cotera/client/app/components/forms';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { Text } from '@cotera/client/app/components/ui';
import { mapValues, startCase } from 'lodash';
import { useRef } from 'react';
import { useHints } from '../hints';
import { Entity } from '@cotera/api';
import {
  buildEraQLFromExpressionString,
  extractExpressions,
  hasMatchingBraces,
} from '../llm/utils';
import { AST, EraQL, TC } from '@cotera/era';
import { parseExprResult } from '../utils';
import { Result } from 'neverthrow';

type InputType = typeof Inputs.TextArea | typeof Inputs.Text;

type Props = {
  name?: string;
  error?: {
    t: string;
    msg: string;
  };
  eraql: string;
  as?: InputType;
  entity: Entity;
  onChange: (
    value: string,
    eraql: Result<
      {
        tc: TC.ExprTypeCheck;
        ast: AST.ExprIR;
      },
      {
        t: string;
        msg: string;
      }
    >
  ) => void;
};

export const TemplatedExpressionEditor: React.FC<Props> = ({
  entity,
  name,
  onChange,
  error,
  eraql: prompt,
  as: Input = Inputs.TextArea,
}) => {
  const changeHandler = (value: string) => {
    const expressions = extractExpressions(value);
    const eraQl = buildEraQLFromExpressionString(expressions).trimEnd();

    const parseRes = parseExprResult(
      EraQL.parseExpr(eraQl, {
        attributes: mapValues(entity.columns, (x) => x.type),
      })
    );

    onChange(value, parseRes);
  };

  const promptRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const hints = useHints(
    entity,
    ['==', '!=', '>', '<', '>=', '<=', 'contains', 'not_contains'],
    promptRef,
    changeHandler
  );

  const handleChange = (
    currentValue: string,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const isGoingBackwards = currentValue.length < prompt.length;
    if (
      !hasMatchingBraces(currentValue) &&
      currentValue.at(-1) === '{' &&
      !isGoingBackwards
    ) {
      changeHandler(currentValue + '  }}');
    } else {
      changeHandler(currentValue);
    }
    hints.handleChange(currentValue, e);
  };

  return (
    <>
      <Input
        className={classNames(
          'mb-4',
          Input === Inputs.TextArea ? 'min-h-[100px]' : ''
        )}
        ref={promptRef as any}
        label={name}
        onChange={handleChange}
        value={prompt}
      />
      <TypeHints />
      {prompt.length > 0 && error !== undefined && (
        <div
          className={classNames(
            'p-2 rounded mt-4 text-sm border',
            ColorScheme.background.error,
            ColorScheme.text.error,
            ColorScheme.border.error
          )}
        >
          {startCase(error.t)}: {error.msg}
        </div>
      )}
      <TextHints
        hints={hints.columns.options}
        anchorRef={promptRef}
        show={hints.columns.showOptions}
        onSelect={(v) =>
          hints.handleOptionClick(
            {
              value: v,
              trigger: '"',
            },
            prompt
          )
        }
      />
      <TextHints
        hints={hints.operators.options}
        anchorRef={promptRef}
        show={hints.operators.showOptions}
        onSelect={(v) =>
          hints.handleOptionClick(
            {
              value: v,
              trigger: '.',
            },
            prompt
          )
        }
      />
      <TextHints
        hints={hints.functions.options}
        anchorRef={promptRef}
        show={hints.functions.showOptions}
        onSelect={(v) =>
          hints.handleOptionClick(
            {
              value: v,
              trigger: '|>',
            },
            prompt
          )
        }
      />
    </>
  );
};

const TypeHints = () => (
  <div className="flex items-center">
    <Text.Caption className="mr-2">
      Columns and functions must be written within <Hint>{'{{ }}'}</Hint>.
    </Text.Caption>
    <Text.Caption>
      Type <Hint theme="primary">"</Hint> to select a column,{' '}
      <Hint theme="primary">.</Hint> to select an operator
    </Text.Caption>
  </div>
);
