import { Relation } from '@cotera/era';
import { Column } from './column';
import { Row } from './row';
import { Text, Loading } from '@cotera/client/app/components/ui';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import {
  ChildrenProps,
  Format,
  makeValueFormatter,
} from '@cotera/client/app/components/utils';
import { classNames } from '@cotera/client/app/components/utils';
import { H2 } from '@cotera/client/app/components/ui/typography';

export const CalloutForRelations: React.FC<{
  rels: Relation[];
  caption?: string;
}> = ({ rels, caption }) => {
  return (
    <Column>
      <Row className="flex-wrap items-stretch mb-2">
        {rels.map((item, i) => (
          <ForRelation key={i} rel={item} />
        ))}
      </Row>
      {caption && (
        <Row className="w-full mt-4 px-4 pt-2">
          <Text.Caption className="w-full border-t border-divider pt-4">
            {caption}
          </Text.Caption>
        </Row>
      )}
    </Column>
  );
};

const Container: React.FC<ChildrenProps> = ({ children }) => (
  <div
    className={classNames(
      `
        border-r
        min-w-[150px] 
        grow-1 
        flex-1 
        mt-2 
        [&:not(:last-child)]:mr-4 
        last:border-r-0
        px-4
      `
    )}
  >
    {children}
  </div>
);

export const CalloutSkeleton = () => {
  return (
    <Container>
      <Column>
        <Loading.Shimmer className="w-[100px] h-[25px] mb-4" />
        <Loading.Shimmer className="w-[200px] h-[30px]" />
      </Column>
    </Container>
  );
};

const ForRelation: React.FC<{ rel: Relation }> = ({ rel }) => {
  const result = useDuckDBQuery({ rel });

  const statsData = result.data.data.toArray();

  return (
    <>
      {statsData.map((item, i: number) => {
        return <Item key={i} {...(item as CalloutValue)} />;
      })}
    </>
  );
};

type CalloutValue = {
  title: string;
  value: string;
  style: keyof typeof STYLE;
  unit?: Format['unit'];
};

const STYLE = {
  positive: 'text-green-500',
  negative: 'text-rose-500',
  warning: 'text-amber-500',
  neutral: 'text-primary-text',
};

const Item: React.FC<CalloutValue> = ({ title, value, style, unit }) => {
  const formatter = makeValueFormatter(unit);
  return (
    <Container>
      <Text.P className="text-xl mb-1">{title}</Text.P>
      <div className="flex flex-row w-full items-end justify-between flex-wrap  mt-2">
        <div className="flex flex-row items-end flex-wrap mr-2">
          <H2 textColor={STYLE[style]}>{formatter(value)}</H2>
        </div>
      </div>
    </Container>
  );
};
