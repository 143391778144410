import { makeStore, StateGetter, StateSetter } from '@cotera/client/app/etc';

export type Feature = {
  featureId: string;
  content: string;
  documentCount: number;
  ignored: boolean;
  topics: {
    id: string | null;
    name: string;
  }[];
};

export type TopicFeature = {
  featureId: string;
  content: string;
  documentCount: number;
};

type State = {
  synced: 'synced' | 'unsynced' | 'syncing';
  features: Feature[];
  history: State[];
  pop: () => void;
  topics: {
    id: string;
    name: string;
    description: string | null;
    features: TopicFeature[];
    action?: 'upsert' | 'delete';
  }[];
  focusedFeature: Feature | null;
};

export const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  setFocusedFeature: (feature: Feature | null) => {
    set((state) => ({ ...state, focusedFeature: feature }));
  },
  assign: (feature: Feature) => {
    const newFeatures = get().features.map((f) =>
      f.featureId === feature.featureId
        ? { ...f, topics: [...f.topics, get().topics[0]!] }
        : f
    );
    set(() => ({
      features: newFeatures,
      //this works because there is only ever one topic in this model
      topics: [
        ...get().topics.map((topic) => ({
          ...topic,
          features: topic.features.includes(feature)
            ? topic.features
            : [...topic.features, feature],
        })),
      ],
    }));
  },
  remove: (feature: Feature) => {
    const newFeatures = get().features.map((f) =>
      f.featureId === feature.featureId
        ? { ...f, topics: f.topics.filter((t) => t.id !== get().topics[0]!.id) }
        : f
    );
    set(() => ({
      features: newFeatures,
      //this works because there is only ever one topic in this model
      topics: [
        ...get().topics.map((topic) => ({
          ...topic,
          features: topic.features.filter(
            (f) => f.featureId !== feature.featureId
          ),
        })),
      ],
    }));
  },
});

type Actions = ReturnType<typeof actions>;

export const { hook: useDrafts, provider: DraftProvider } = makeStore<
  State,
  Actions
>();
