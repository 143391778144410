import { useUddWrite } from '@cotera/client/app/hooks/udds';
import { Ty } from '@cotera/era';
import { Button, Loading } from '@cotera/client/app/components/ui';

export const UddWriteButton: React.FC<{
  payload: {
    entity_name: string;
    key: string;
    identifier: string | number;
    options: { label: string; color?: string; value: Ty.Scalar }[];
  };
}> = ({ payload }) => {
  const { entity_name, key, identifier, options } = payload;
  const write = useUddWrite({ entityId: entity_name, key });

  if (write.isPending) {
    return (
      <div>
        <Loading.Spinner />
      </div>
    );
  }

  const buttons = options.map(({ label, value }) => (
    <Button
      key={label}
      text={label}
      onClick={() => write.mutate([{ identifier, value }])}
    />
  ));
  return <div className="flex flex-row">{buttons}</div>;
};
