import { Layout } from '@cotera/client/app/layout';
import { Link } from 'react-router-dom';
import { Text, RelationPicker } from '@cotera/client/app/components';
import { useAppData } from '@cotera/client/app/stores/org';
import { TC, Ty } from '@cotera/era';

export const ListEmbeddableRelations: React.FC = () => {
  const definitions = useAppData((x) => x.skeleton.definitions);

  const defs = Object.fromEntries(
    Object.entries(definitions).filter(([_, value]) => {
      return TC.implementsRel({
        subject: value.attributes,
        reqs: {
          id: {
            allowed: ['string'],
            optional: false,
          },
          content: {
            allowed: ['string'],
            optional: false,
          },
          timestamp: {
            allowed: ['timestamp'],
            optional: false,
          },
          embedding: {
            allowed: [Ty.EMBEDDING],
            optional: false,
          },
        },
      }).isOk();
    })
  );

  return (
    <Layout>
      <div className="min-h-full flex flex-col items-center w-full">
        <div className="mx-auto max-w-7xl p-8 w-full">
          <h2 className="text-lg font-semibold leading-6 text-standard-text">
            Select a data set
          </h2>
          <Text.Caption className="mt-1 text-sm mb-6">
            Get started by choosing a data set to search.
          </Text.Caption>
          <RelationPicker
            definitions={defs}
            wrapper={(props) => (
              <>
                <Link to={`/semantic-search/${props.id}`} className="flex-1">
                  {props.children}
                </Link>
              </>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};
