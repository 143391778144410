import { BarChart } from './bar';
import { BreakdownBar } from './summary-bar';
import { HeatmapChart } from './heatmap';
import { HistogramChart } from './histogram';
import { LineChart } from './line';
import { PieChart } from './pie';
import { RadarChart } from './radar';
import { makeChartRelComponent } from './relation';
import { SankeyChart } from './sankey';
import { ScatterPlotChart } from './scatter';

export { PieChart } from './pie';
export { SankeyChart } from './sankey';
export { BarChart } from './bar';
export { HistogramChart } from './histogram';
export { LineChart } from './line';
export { RadarChart } from './radar';
export { HeatmapChart } from './heatmap';
export { ScatterPlotChart } from './scatter';

export const RadarChartForRelation = makeChartRelComponent(RadarChart);
export const LineChartForRelation = makeChartRelComponent(LineChart);
export const PieChartForRelation = makeChartRelComponent(PieChart);
export const BarChartForRelation = makeChartRelComponent(BarChart);
export const HistogramChartForRelation = makeChartRelComponent(HistogramChart);
export const HeatmapChartForRelation = makeChartRelComponent(HeatmapChart);
export const SankeyChartForRelation = makeChartRelComponent(SankeyChart);
export const ScatterPlotChartForRelation =
  makeChartRelComponent(ScatterPlotChart);

export const SummaryChartForRelation = makeChartRelComponent(BreakdownBar);
