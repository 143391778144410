import _ from 'lodash';
import { AST } from '../ast';

export type SqlParam = { readonly t: 'param'; readonly val: string };

export type SqlRelAst = readonly (string | SqlParam | AST.RelIR | SqlRelAst)[];
export type SqlExprAst = readonly (string | AST.ExprIR | SqlExprAst)[];
export type ParameterizedSql = readonly (
  | string
  | SqlParam
  | ParameterizedSql
)[];

export function intersperse<T>(arr: readonly T[], sep: T): T[] {
  return arr.reduce((a: readonly T[], v: T) => [...a, v, sep], []).slice(0, -1);
}

export const pSqlMacro = (
  parts: readonly string[],
  ...keys: readonly (string | SqlParam | ParameterizedSql)[]
): ParameterizedSql => {
  return _.zip(parts, keys)
    .flatMap(([str = '', key = '']) => [str, key])
    .filter((x) => x !== '');
};

export const sqlExprMacro = (
  parts: readonly string[],
  ...keys: readonly (string | AST.ExprIR | SqlExprAst)[]
): SqlExprAst => {
  return _.zip(parts, keys)
    .flatMap(([str = '', key = '']) => [str, key])
    .filter((x) => x !== '');
};

export const sqlRelMacro = (
  parts: readonly string[],
  ...keys: readonly (string | AST.RelIR | SqlRelAst)[]
): SqlRelAst => {
  return _.zip(parts, keys)
    .flatMap(([str = '', key = '']) => [str, key])
    .filter((x) => x !== '');
};
