import { Card } from '../../headless';
import { useDevMode } from '@cotera/client/app/stores/dev-mode';
import React from 'react';
import { ManifestSkeleton } from '@cotera/api';
import { classNames, globalTheme } from '@cotera/client/app/components/utils';
import { extractLetters } from '@cotera/client/app/etc';

const theme = globalTheme.theme('random');

export const RelationPicker: React.FC<{
  wrapper: (definition: {
    displayName: string;
    hidden: boolean;
    id: string;
    children: React.ReactNode;
  }) => React.ReactNode;
  definitions: ManifestSkeleton['definitions'];
}> = ({ wrapper: Wrapper, definitions }) => {
  const devMode = useDevMode();

  const filteredDefinitions = Object.values(definitions).filter(
    ({ hidden }) => {
      if (!devMode.enabled && hidden) {
        return false;
      }
      return true;
    }
  );

  return (
    <ul className="grid grid-cols-2">
      {filteredDefinitions.map((def, idx) => {
        return (
          <li
            key={idx}
            className={classNames('relative flex items-start space-x-3 mr-4')}
          >
            <Card.Container className="flex items-center w-full justify-between">
              <Wrapper {...def}>
                <div className="flex flex-row h-full w-full">
                  <div
                    className="bg-muted-background w-[40px] text-sm rounded-l-md flex items-center justify-center px-2 text-alt-text border-r mr-4"
                    style={{
                      color: theme.forLabel(def.id).color,
                    }}
                  >
                    {extractLetters(def.displayName)}
                  </div>
                  <div className="min-h-[32px] flex flex-col items-center justify-center text-sm mr-4 py-4">
                    {def.displayName}
                  </div>
                </div>
              </Wrapper>
            </Card.Container>
          </li>
        );
      })}
    </ul>
  );
};
