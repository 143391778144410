import { z } from 'zod';
import { shorthandToTy, nn } from '../ty/ty';
import { LiteralSchema } from './base';
import _ from 'lodash';

// This is in it's own .js because it turns out to be decently expensive TS
// type checking. My theory is that doing this array check i

// We also never want to _infer_ to super, unless the caller specifies it
export const PRIMITIVE_TYPE_INFERENCE_RULES = [
  ['int', z.number().int()],
  ['float', z.number()],
  ['boolean', z.boolean()],
  ['timestamp', z.date()],
  ['string', z.string()],
];

const PRIMITIVE_TYPE_VALIDATORS = Object.fromEntries(
  PRIMITIVE_TYPE_INFERENCE_RULES
);

export const validatorForType = (shorthand) => {
  const { ty, nullable, tags } = shorthandToTy(shorthand);

  if (ty.k === 'primitive') {
    if (ty.t === 'super') {
      return LiteralSchema;
    }

    if (ty.t === 'timestamp') {
      return nullable ? z.coerce.date().nullable().optional() : z.coerce.date();
    }

    return nullable
      ? PRIMITIVE_TYPE_VALIDATORS[ty.t].nullable().optional()
      : PRIMITIVE_TYPE_VALIDATORS[ty.t];
  }

  if (ty.k === 'array') {
    return z.array(validatorForType(ty.t)).nullable();
  }

  if (ty.k === 'enum') {
    if (ty.t.length > 0) {
      return nullable ? z.enum(ty.t).nullable() : z.enum(ty.t);
    } else {
      return nullable ? z.null() : z.never();
    }
  }

  if (ty.k === 'id') {
    return validatorForType({
      ty: { k: 'primitive', t: ty.t },
      nullable,
      tags,
    });
  }

  if (ty.k === 'record') {
    const record = z.record(validatorForType(nn(ty.t)));
    return nullable ? record.nullable() : record;
  }

  if (ty.k === 'range') {
    const range = z.number().min(ty.start).max(ty.end);
    return nullable ? range.nullable() : range;
  }

  return z.object(_.mapValues(ty.fields, (ty) => validatorForType(ty)));
};
