import { StateGetter, StateSetter, makeStore } from '@cotera/client/app/etc';
import { ChildrenProps, TimeSeriesFormat } from '../utils';
import { Relation, TC } from '@cotera/era';

export type NumbericColumnState = {
  type: 'numeric';
  decimals: number;
};

export type DateColumnState = {
  type: 'timestamp';
  format: TimeSeriesFormat;
};

type OtherColumnState = {
  type: 'other';
};

type ColumnState = NumbericColumnState | DateColumnState | OtherColumnState;

type State = {
  columns: Record<string, ColumnState>;
};

const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  setColumnState: (column: string, state: Partial<ColumnState>) =>
    set(() => ({
      columns: {
        ...get().columns,
        [column]: {
          ...get().columns[column]!,
          ...state,
        } as ColumnState,
      },
    })),
});

type Actions = ReturnType<typeof actions>;

const { hook: useColumns, provider: Provider } = makeStore<State, Actions>();

export function useColumnState<T extends ColumnState>(
  column: string
): [
  T,
  {
    update: (state: Partial<ColumnState>) => void;
  }
] {
  const col = useColumns((state) => state.columns[column])!;
  const actions = useColumns((state) => state.actions);
  return [
    col as T,
    {
      update: (state: Partial<ColumnState>) =>
        actions.setColumnState(column, state),
    },
  ];
}

export const ColumnStateProvider: React.FC<
  ChildrenProps & {
    state: State;
  }
> = ({ children, state }) => {
  return (
    <Provider state={state} actions={actions}>
      {children}
    </Provider>
  );
};

export const defaultStateFromRel = (rel: Relation) => {
  const columns: Record<string, ColumnState> = {};
  for (const [col, ty] of Object.entries(rel.attributes)) {
    if (TC.isNumeric(ty)) {
      columns[col] = { type: 'numeric', decimals: 2 };
    } else {
      columns[col] = { type: 'other' };
    }
  }
  return { columns };
};
