import {
  StateSetter,
  makeStoreProvider,
  makeStoreContextHook,
  StateGetter,
} from '@cotera/client/app/etc';
import { createContext } from 'react';
import {
  ChildrenProps,
  ThemeInstance,
} from '@cotera/client/app/components/utils';
import { BaseDatum } from '../types';
import { remove, sortBy, uniqBy } from 'lodash';
import objectHash from 'object-hash';

export const chartTheme = new ThemeInstance();

type Props<T extends BaseDatum> = {
  labelKeys: (keyof T & string)[];
  data: T[];
};

type Label = {
  label: string;
  color: string;
  fill: string;
};

type State<T extends BaseDatum> = {
  labels: Label[];
  data: T[];
  theme: ThemeInstance;
  baseData: T[];
  hiddenItems: string[];
  activeLabels: Label[];
} & Props<T>;

type Actions = (set: (state: Partial<State<any>>) => void) => {
  toggle(id: string): string[];
  focus: (id: string) => void;
  clearFocus: () => void;
};

const Context = createContext<State<any>>(undefined as any);

const ChartProvider = makeStoreProvider<State<any>, ReturnType<Actions>>(
  Context
);

export const useChartContext = makeStoreContextHook<
  State<any>,
  ReturnType<Actions>
>(Context);

export function makeChartContextHook<T extends BaseDatum>() {
  return makeStoreContextHook<State<T>, ReturnType<Actions>>(Context);
}

const chartActions = (
  set: StateSetter<State<any>>,
  get: StateGetter<State<any>, ReturnType<Actions>>
): ReturnType<Actions> => ({
  toggle: (id) => {
    set((state) => {
      const hiddenItems = [...state.hiddenItems];
      const index = hiddenItems.indexOf(id);

      if (hiddenItems.length === state.labels.length - 1 && index === -1) {
        return {
          data: state.baseData,
          activeLabels: state.labels,
          hiddenItems: [],
        };
      } else if (index === -1) {
        hiddenItems.push(id);
      } else {
        remove(hiddenItems, (x) => x === id);
      }

      return {
        data: state.baseData.filter((d) =>
          state.labelKeys.some((key) => !hiddenItems.includes(d[key]))
        ),
        activeLabels: state.labels.filter(
          (l) => !hiddenItems.includes(l.label)
        ),
        hiddenItems,
      };
    });

    return get().hiddenItems;
  },
  clearFocus: () => {
    set((state) => ({
      data: state.baseData,
      activeLabels: state.labels,
      hiddenItems: [],
    }));
  },
  focus: (id) => {
    set((state) => {
      const hiddenItems = state.labels
        .filter((l) => l.label !== id)
        .map((l) => l.label);

      return {
        data: state.baseData.filter((d) =>
          state.labelKeys.some((key) => !hiddenItems.includes(d[key]))
        ),
        activeLabels: state.labels.filter((l) => l.label === id),
        hiddenItems,
      };
    });
  },
});

export function ChartContext<T extends BaseDatum>({
  children,
  labelKeys,
  data,
}: ChildrenProps & Props<T>) {
  const labels = sortBy(
    uniqBy(
      labelKeys
        .map((key) =>
          data.map((x) => {
            const label = String(x[key]);
            const styledTheme = chartTheme.theme(x.style ?? 'random');
            const { color, pattern: fill } = styledTheme.forLabel(label);
            return { label, color, fill };
          })
        )
        .flat(),
      'label'
    ),
    'label'
  );

  return (
    <ChartProvider
      key={objectHash({
        data: JSON.stringify(data, (_key, value) =>
          typeof value === 'bigint' ? value.toString() : value
        ),
      })}
      state={{
        theme: chartTheme,
        labelKeys,
        labels,
        activeLabels: labels,
        data,
        baseData: data,
        hiddenItems: [],
      }}
      actions={chartActions}
    >
      {children}
    </ChartProvider>
  );
}
