import { chunk } from 'lodash';
import { Row } from './row';
import { Column } from './column';

const WIDTHS: Record<number, string> = {
  1: 'w-full',
  2: 'md:w-1/2',
  3: 'md:w-1/3',
  4: 'md:w-1/4',
};

export const FlexGrid: React.FC<
  { children: React.ReactNode[] } & { cols: number }
> = ({ cols, children }) => {
  const rows = chunk(children, cols);
  const width = WIDTHS[cols] ?? 'w-full';
  return (
    <Column>
      {rows.map((row) => (
        <Row className="flex-wrap justify-between">
          {row.map((child) => (
            <Column className={`${width} [&:not(:last-child)]:pr-4`}>
              {child}
            </Column>
          ))}
        </Row>
      ))}
    </Column>
  );
};
