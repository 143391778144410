import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ZodInput } from './input';
import { ObjectErrors } from './input.props';
import { Button } from '@cotera/client/app/components/ui';
import { classNames } from '@cotera/client/app/components/utils';

export function ZodForm<T extends z.AnyZodObject>({
  schema,
  onSubmit,
  wrapper,
  className,
  submitButton: button,
}: {
  schema: T;
  onSubmit: (data: z.infer<T>) => Promise<void> | void;
  className?: string;
  submitButton?: JSX.Element;
  wrapper?: (props: {
    children: JSX.Element;
    name: string;
    schema: z.ZodTypeAny;
  }) => JSX.Element;
}) {
  const methods = useForm({
    resolver: zodResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form className="mb-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <ZodInput
          name=""
          wrapper={wrapper}
          zodSchema={schema}
          optional={false}
          className={classNames('mb-4', className)}
          errors={methods.formState.errors as ObjectErrors}
        />
        {button || <Button text="Submit" className="mt-4" type="submit" />}
      </form>
    </FormProvider>
  );
}
