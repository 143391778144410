import { useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedClient, useWhoami } from '../stores/org';
import { Assert } from '@cotera/utilities';

export const usePrompts = () => {
  const client = useTenantedClient();
  const orgId = useWhoami((s) => s.org.id);

  return useSuspenseQuery({
    queryFn: () => client.prompts.list({}).then(Assert.assertOk),
    queryKey: ['prompts', orgId],
  });
};
