import { useEntity } from '@cotera/client/app/hooks/entities';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { Suspense, useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDetectedFeatures, useQueryKey, useVersions } from '../hooks';
import { Assert } from '@cotera/utilities';
import { DndProvider } from 'react-dnd';
import { actions, DraftProvider, useDrafts } from './context';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useQueryClient } from '@tanstack/react-query';
import { FormState } from './types';
import { applyMiddleware, withHistory } from '@cotera/client/app/etc';
import { AddNewVersion, PromoteButton } from '../components';
import {
  Badge,
  Button,
  Divider,
  Icon,
  Loading,
  Modal,
  Section,
  Title,
  Tooltip,
} from '@cotera/client/app/components/ui';
import { RelationPicker } from '@cotera/client/app/components/app';
import { Topics } from './topics';
import { Inputs } from '@cotera/client/app/components/forms';
import { DetectedFeatures } from './detected-features';
import { CrtlZ } from '../components';
import { withTopicsSync } from '../utils';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';

const Form: React.FC<{
  value: FormState;
  onChange?: (value: FormState) => void;
}> = ({ onChange, value }) => {
  const searchLoading = useDrafts((s) => s.searchLoading);
  const [form, setForm] = useState(value);
  const setFormValue = useCallback(
    (newValue: Partial<FormState>) => {
      setForm?.({
        ...form,
        ...newValue,
      });
    },
    [setForm, form]
  );

  useKeyPress('Enter', () => {
    onChange?.(form);
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex w-full mb-4"
    >
      <Inputs.Text
        loading={searchLoading}
        className="flex-grow mr-2"
        icon="search"
        value={form.search}
        onChange={(v) => setFormValue({ search: v })}
      />
      <Inputs.Toggle
        nullable={false}
        className="mr-2 flex-shrink-0"
        options={['all', 'assigned', 'unassigned', 'ignored']}
        value={form.filter}
        onChange={(v) => {
          setFormValue({ filter: v });
          onChange?.({
            ...form,
            filter: v!,
          });
        }}
      />
    </form>
  );
};

export const View: React.FC = () => {
  const [showAuditSelector, setShowAuditSelector] = useState(false);
  const {
    entityName,
    uddId,
    version: versionName,
  } = useParams() as {
    entityName: string;
    uddId: string;
    version: string;
  };
  const entity = useEntity({ entityName });
  const client = useTenantedClient();
  const [form, setForm] = useState<FormState>({
    search: '',
    filter: 'unassigned',
  });
  const { data: topicVersions } = useVersions(uddId);
  const version = topicVersions.find((x) => x.version === versionName);

  Assert.assert(version !== undefined, 'Version not found');

  const { features, topics } = useDetectedFeatures({
    uddId: uddId,
    assigned: 'all',
    versionId: version.id,
  });

  const queryClient = useQueryClient();
  const queryKey = useQueryKey({ uddId, versionId: version.id });

  return (
    <DndProvider backend={HTML5Backend}>
      <DraftProvider
        key={version.id}
        state={{
          pop: () => {},
          history: [],
          synced: 'synced',
          topics,
          features,
          focusedFeature: null,
        }}
        actions={(set, get) => {
          const enhancedSet = applyMiddleware(
            [
              withHistory,
              withTopicsSync(client, version.id, () => {
                void queryClient.invalidateQueries({ queryKey });
              }),
            ],
            set,
            get
          );
          return actions(enhancedSet, get);
        }}
      >
        <CrtlZ />
        <Modal
          open={showAuditSelector}
          onClose={() => {
            setShowAuditSelector(false);
          }}
        >
          <Title title="Select definition" type="section" className="mb-4" />
          <Divider className="mb-6" />
          <RelationPicker
            definitions={entity.definitions}
            wrapper={({ children, id }) => (
              <Link to={`/explore/data/${id}?topicVersionId=${version.id}`}>
                {children}
              </Link>
            )}
          />
        </Modal>
        <Section direction="vertical" className="w-full" top={false}>
          <div className="flex w-full items-center justify-between  mb-4">
            <div className="flex w-full items-center justify-between">
              <Title
                title={`Manage Topics`}
                type="title"
                className="mr-2"
                subtitle={version.id}
              />
              <div className="flex items-center">
                <SyncedIndicator />
                <Button
                  text="Audit"
                  icon="clipboard-document-list"
                  theme="regular"
                  small
                  className="mr-2"
                  onClick={() => {
                    setShowAuditSelector(true);
                  }}
                />
                {version.published ? (
                  <Badge theme="primary" className="mr-2">
                    Published
                  </Badge>
                ) : (
                  <PromoteButton small versionId={version.id} uddId={uddId} />
                )}
                <AddNewVersion
                  numVersions={topicVersions.length}
                  entityName={entity.name}
                  uddId={uddId}
                  sourceVersionId={version.id}
                />
              </div>
            </div>
          </div>
          <Divider className="mb-4" />
          <div className="flex w-full h-[calc(100%-54px)]">
            <div className="flex flex-col w-2/3 h-full">
              <Form value={form} onChange={setForm} />
              <Suspense fallback={<Loading.Dots />}>
                <DetectedFeatures
                  query={form}
                  uddId={uddId}
                  versionId={version.id}
                />
              </Suspense>
            </div>
            <div className="flex flex-col w-1/2 ml-4">
              <Topics key={version.id} versionId={version.id} uddId={uddId} />
            </div>
          </div>
        </Section>
      </DraftProvider>
    </DndProvider>
  );
};

const SyncedMessage = {
  synced: 'Synced',
  unsynced: 'Error saving topics',
  syncing: 'Syncing',
};

export const SyncedIndicator: React.FC = () => {
  const synced = useDrafts((s) => s.synced);

  return (
    <Tooltip side="left" text={SyncedMessage[synced]}>
      <Badge
        theme="regular"
        className="mr-2 transition-all ease-in-out duration-150"
      >
        {synced === 'syncing' && <Loading.Spinner variant="sm" />}
        {synced === 'synced' && <Icon icon="check" theme="secondary" />}
        {synced === 'unsynced' && (
          <>
            <Icon icon="warning" theme="error" />
            <span className="ml-2">Unsynced</span>
          </>
        )}
      </Badge>
    </Tooltip>
  );
};
