import React from 'react';
import { classNames } from '@cotera/client/app/components/utils';

export const Center = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center w-full min-h-full',
        className
      )}
    >
      {children}
    </div>
  );
};
