import { Ty } from '../ty';
import { Assert } from '../utils';
import { implementsTy } from './implements';

export const isNumeric = (ty: Ty.Shorthand): boolean =>
  implementsTy({
    subject: ty,
    req: 'float',
  });

export const isStringLike = (ty: Ty.Shorthand): boolean =>
  implementsTy({ subject: ty, req: 'string' });

export const isBoolean = (ty: Ty.Shorthand): boolean =>
  implementsTy({ subject: ty, req: 'boolean' });

export const isTimestamp = (ty: Ty.Shorthand): boolean =>
  implementsTy({ subject: ty, req: 'timestamp' });

export const isPrimitive = (ty: Ty.Shorthand): boolean => {
  const { k } = Ty.ty(ty).ty;

  switch (k) {
    case 'id':
    case 'primitive':
    case 'enum':
    case 'range':
      return true;
    case 'array':
    case 'struct':
    case 'record':
      return false;
    default:
      return Assert.unreachable(k);
  }
};

export const isId = (ty: Ty.Shorthand): boolean => Ty.ty(ty).ty.k === 'id';
export const isEnum = (ty: Ty.Shorthand): boolean => Ty.ty(ty).ty.k === 'enum';

export const isStruct = (ty: Ty.Shorthand): boolean =>
  Ty.ty(ty).ty.k === 'struct';

export const isArray = (ty: Ty.Shorthand): boolean =>
  Ty.ty(ty).ty.k === 'array';

export const isComposite = (ty: Ty.Shorthand): boolean => !isPrimitive(ty);
