export const BAR_CHART_INFO = [
  {
    title: 'What it shows',
    content:
      'The bar chart uses bar length to represent quantitative values for a range of different categories. You might use a bar chart to show the number of films made by different movie stars, for example.',
  },
  {
    title: 'How to read it',
    content:
      'Judge the absolute length of each bar by cross-referencing against the scale along an axis or a value label. Then compare all the bars, looking out for the big ones through to the small, the normal ones and the exceptions.',
  },
];
