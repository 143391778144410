import { Ty } from '@cotera/era';
import { Field as ArrowField, Decimal, StructRowProxy } from 'apache-arrow';
import { Type as ArrowType } from 'apache-arrow';

export const mapArrowToEraTypes = (
  row: StructRowProxy<any>,
  fields: ArrowField[]
): { [key: string]: Ty.Scalar } => {
  return Object.fromEntries(
    fields.map((field) => {
      // Arrow returns a proxy object which is a pointer to the actual data.
      // We know this works provided the fields are correct for the row
      const val = row[field.name];

      // We can always return null, this avoids tricky bugs down the line like
      // `new Date(null)` being the same as `new Date(0)` 🤦
      if (val === null) {
        return [field.name, val];
      }

      switch (field.typeId) {
        case ArrowType.Bool:
        case ArrowType.Utf8:
        case ArrowType.Int:
        case ArrowType.Float:
        case ArrowType.Date: {
          return [field.name, typeof val === 'bigint' ? Number(val) : val];
        }
        case ArrowType.Struct: {
          return [field.name, mapArrowToEraTypes(val, field.type.children)];
        }
        case ArrowType.Timestamp: {
          // We need to convert the timestamp repr into a js date
          // https://github.com/apache/arrow/blob/main/js/src/enum.ts#L169
          return [field.name, new Date(val)];
        }
        case ArrowType.Decimal: {
          // Horrible, hopefully temporary hack
          return [field.name, parseInt(String(val)) / (field.type.scale * 10)];
        }
        case ArrowType.List: {
          const arr: Ty.Scalar[] = [];
          for (const item of val) {
            const mapped = mapArrowToEraTypes(
              item,
              val.type.children
                ? val.type.children
                : [
                    {
                      ...val.type,
                      type: val.type,
                      typeId:
                        val.type instanceof Decimal
                          ? ArrowType.Decimal
                          : ArrowType.Utf8,
                    },
                  ]
            );
            arr.push(mapped);
          }
          return [field.name, arr];
        }
        default: {
          const arrowType = ArrowType[field.typeId];
          throw new Error(`Unsupported Arrow Type ${arrowType}`);
        }
      }
    })
  );
};
