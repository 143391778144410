import { Dictionary, groupBy, sortBy } from 'lodash';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import {
  Axis,
  THEME,
  getTickLegendSize,
  isDateAxis,
  makeTickValues,
  scaleToFormat,
} from '../utils';
import { Loading } from './loading';
import { ChartContext, makeChartContextHook } from '../context';
import { ChartProps } from '../types';
import { EmptyChart } from '../empty';
import { Tooltip } from '../tooltip';
import { If } from '@cotera/client/app/components/ui';
import {
  GRAY_200,
  isTimeSeriesFormat,
  makeValueFormatter,
  UTCDate,
} from '@cotera/client/app/components/utils';

type Datum = {
  x: string | number | Date;
  y: number;
  category: string;
};

export type Props = {
  loading?: boolean;
  numTicks?: number;
  dynamicSizePoints?: boolean;
  axis: {
    x?: Axis;
    y?: Axis;
  };
} & ChartProps<Datum>;

const makeScale = (scale: Axis['scale']) => {
  if (!scale || scale === 'point') {
    return {
      type: 'point',
    } as const;
  }
  if (scale === 'linear') {
    return {
      type: 'linear',
      max: 'auto',
      min: 'auto',
    } as const;
  }
  if (isTimeSeriesFormat({ unit: scale })) {
    const tzOffset = new Date().getTimezoneOffset();
    return {
      type: 'time',
      format: tzOffset === 0 ? 'native' : '%y-%m-%d-%H-%M-%S',
      precision: scale,
      useUTC: tzOffset === 0,
    } as const;
  }

  return {
    type: 'point',
  } as const;
};

const useTypedChartContext = makeChartContextHook<Datum>();

export const ScatterPlotChart: React.FC<Props> = ({
  data,
  axis,
  loading = false,
  format,
  menuItems = [],
  menuItemHandlers = {},
  numTicks = 10,
  dynamicSizePoints = false,
  ...chartLayout
}) => {
  return (
    <ChartContext data={data} labelKeys={['category']}>
      <ChartLayout
        {...chartLayout}
        labels={{
          left: axis.y?.label,
          bottom: axis.x?.label,
        }}
        menuItems={[...DEFAULT_MENU_ITEMS, ...menuItems]}
        menuItemHandlers={{ ...menuItemHandlers }}
      >
        <If condition={loading}>
          <Loading />
        </If>
        <If condition={data.length === 0 && !loading}>
          <EmptyChart />
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart
            axis={axis}
            format={format}
            numTicks={numTicks}
            dynamicSizePoints={dynamicSizePoints}
          />
        </If>
      </ChartLayout>
    </ChartContext>
  );
};

const MAX_NODE_SIZE = 24;

const Chart: React.FC<{
  format?: Props['format'];
  axis: Props['axis'];
  type?: 'stacked' | 'normal' | 'area';
  numTicks: number;
  dynamicSizePoints: boolean;
}> = ({ axis, format, numTicks, dynamicSizePoints }) => {
  const xFormatter = makeValueFormatter(
    format?.['x'] ?? scaleToFormat(axis.x?.scale)
  );
  const yFormatter = makeValueFormatter(
    format?.['y'] ?? scaleToFormat(axis.y?.scale ?? 'linear')
  );
  const data = useTypedChartContext((s) => s.data);

  const labels = useTypedChartContext((s) => s.labels);

  const chartData = transform(
    groupBy(data, (x) => x.category),
    axis
  );

  const bottomMargin = getTickLegendSize(
    chartData.map((x) => x.data.map((y) => xFormatter(y.x))).flat(),
    20
  );
  const leftMargin = getTickLegendSize(
    chartData.map((x) => x.data.map((y) => yFormatter(y.y))).flat(),
    20
  );
  const maxPerCategory = chartData.map((x) => ({
    category: x.id,
    value: Math.max(...x.data.map((y) => y.y)),
  }));

  return (
    <ResponsiveScatterPlot
      gridXValues={numTicks}
      theme={THEME}
      animate
      data={chartData}
      colors={(x) =>
        labels.find((y) => y.label === x.serieId)?.color ?? GRAY_200
      }
      margin={{
        bottom: bottomMargin,
        left: leftMargin,
        right: 30,
        top: 20,
      }}
      tooltip={({ node: point }) => {
        return (
          <Tooltip.Container>
            <Tooltip.Item key={point.id}>
              <Tooltip.Dot color={point.color} />
              <strong>{point.serieId}</strong>: {yFormatter(point.data.y)}
            </Tooltip.Item>
          </Tooltip.Container>
        );
      }}
      xScale={makeScale(axis.x?.scale)}
      yScale={{
        type: 'linear',
        max: 'auto',
        min: 'auto',
      }}
      nodeSize={(node) => {
        const maxForCategory = maxPerCategory.find(
          (y) => y.category === node.serieId
        )?.value;

        if (maxForCategory && dynamicSizePoints) {
          return Math.max(MAX_NODE_SIZE * (node.data.y / maxForCategory), 6);
        }
        return 6;
      }}
      axisBottom={{
        format: (v) => xFormatter(v, 20),
        tickValues: makeTickValues(format?.['x'] ?? axis.x?.scale) ?? numTicks,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 60,
      }}
      axisLeft={{
        format: yFormatter,
      }}
    />
  );
};

type TransformedDatum = {
  id: string;
  data: {
    x: string | number | Date;
    y: number;
  }[];
};

const sortData = (data: Datum[], axis: Props['axis']) => {
  if (isDateAxis(axis.x)) {
    return sortBy(data, (x) => x.x);
  }

  return data;
};

const transformX = (x: Datum['x'], axis: Props['axis']): Datum['x'] => {
  if (isDateAxis(axis.x)) {
    return new UTCDate(x);
  }

  return x;
};

const transformDatum = (
  datum: Datum,
  axis: Props['axis']
): { x: Datum['x']; y: Datum['y'] } => {
  return {
    x: transformX(datum.x, axis),
    y: datum.y,
  };
};

const transform = (
  data: Dictionary<Datum[]>,
  axis: Props['axis']
): TransformedDatum[] => {
  return Object.entries(data).map(([key, value]) => ({
    id: key,
    data: sortData(value, axis).map((x) => transformDatum(x, axis)),
  }));
};
