import { err, ok, Result } from 'neverthrow';
import { Traverse } from '../traverse';
import { AST } from '../ast';
import _ from 'lodash';

type Origin = 'file' | 'db' | null;

const ORIGIN_FOR_TYPE: Record<AST.RelIR['t'], Origin> = {
  table: 'db',
  'information-schema': 'db',
  file: 'file',
  values: null,
  'generate-series': null,
  select: null,
  join: null,
  union: null,
  aggregate: null,
};

export const originForIR = (ir: AST.RelIR): Result<Origin, { msg: string }> => {
  const traversal = Traverse.relIRsInRelIR(ir, ({ t }) => ORIGIN_FOR_TYPE[t]);

  let curr: Origin = null;
  for (const next of traversal) {
    if (next === null || curr === next) {
      continue;
    }

    if (curr === null) {
      curr = next;
    } else {
      return err({ msg: `Cannot merge "${curr}" and "${next}" sources` });
    }
  }

  return ok(curr);
};
