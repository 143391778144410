import { useEffect } from 'react';

export const useNearBottom = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
  offset = 50
) => {
  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      const positionY = scrollHeight - scrollTop - clientHeight;

      if (positionY <= offset) {
        callback();
      }
    };

    const element = ref.current;
    element?.addEventListener('scroll', handleScroll);

    return () => element?.removeEventListener('scroll', handleScroll);
  }, [ref, callback, offset]);
};
