import { Suspense } from 'react';
import { Layout } from '@cotera/client/app/layout';
import { Protected } from '@cotera/client/app/components/app';
import {
  Badge,
  Center,
  Divider,
  Loading,
  Section,
  Text,
  Title,
} from '@cotera/client/app/components/ui';
import { useEntities } from './hooks';
import { classNames, globalTheme } from '@cotera/client/app/components/utils';
import { Card } from '@cotera/client/app/components/headless';
import { extractLetters } from '@cotera/client/app/etc/utils';
import { Link } from 'react-router-dom';
import React from 'react';
import { AddEntityContainer } from './components/setup-entity';

const theme = globalTheme.theme('random');

export const ListEntitiesPage: React.FC = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <View />
      </Suspense>
    </Layout>
  );
};

const View: React.FC = () => {
  const { data: entities } = useEntities();

  return (
    <Section direction="vertical" className="w-full items-center">
      <div className="w-[70%] flex flex-col">
        <div className="flex flex-row justify-between items-center w-full mb-4">
          <Title type="title">Entities</Title>
        </div>
        <Divider className="mb-4" />
        {entities.length > 0 && (
          <ul className="grid grid-cols-2">
            {entities.map((entity, idx) => {
              return (
                <li
                  key={idx}
                  className={classNames(
                    'relative flex items-start space-x-3 mr-4'
                  )}
                >
                  <Link to={`/entities/${entity.name}`} className="w-full">
                    <Card.Container className="flex items-center w-full justify-between">
                      <div className="flex flex-row h-full w-full">
                        <div
                          className="bg-muted-background w-[40px] text-sm rounded-l-md flex items-center justify-center px-2 text-alt-text border-r mr-4"
                          style={{
                            color: theme.forLabel(entity.id).color,
                          }}
                        >
                          {extractLetters(entity.name)}
                        </div>
                        <div className="min-h-[32px] w-full flex flex-row items-center justify-between text-sm mr-4 py-4">
                          <div className="flex flex-col">
                            {entity.name}
                            <Text.Caption>{entity.id}</Text.Caption>
                          </div>
                          <div className="flex flex-col">
                            <Badge theme="regular">
                              {Object.keys(entity.columns).length} columns
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </Card.Container>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
        <Protected scopes={[['manage', 'entities']]} fallback={<></>}>
          <AddEntityContainer entity={null} />
        </Protected>
      </div>
    </Section>
  );
};
