import React, { createContext } from 'react';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import {
  StateSetter,
  makeStoreContextHook,
  makeStoreProvider,
} from '@cotera/client/app/etc';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { Overlay } from '../overlay';

type PanelState = {
  collapsed: boolean;
};

type TabActions = (set: (state: Partial<PanelState>) => void) => {
  close: () => void;
  open: () => void;
};

const AppContext = createContext<PanelState>(undefined as any);

const PanelProvider = makeStoreProvider<PanelState, ReturnType<TabActions>>(
  AppContext
);

export const usePanel = makeStoreContextHook<
  PanelState,
  ReturnType<TabActions>
>(AppContext);

const actions = (set: StateSetter<PanelState>): ReturnType<TabActions> => ({
  close: () => set(() => ({ collapsed: true })),
  open: () => set(() => ({ collapsed: false })),
});

const InnerContainer: React.FC<
  ChildrenProps & {
    panel: ChildrenProps['children'];
    side?: 'left' | 'right';
  }
> = ({ children, panel, side = 'left' }) => {
  const collapsed = usePanel((s) => s.collapsed);

  const InnerPannel = (
    <Panel
      key={1}
      className={classNames(
        'w-full transition-[height] duration-200',
        side === 'left' ? 'md:mr-4 justify-self-end' : 'md:ml-4',
        collapsed ? 'md:w-[42px] flex-1 h-0 md:h-full' : 'md:w-1/4 h-[400px]'
      )}
    >
      {panel}
    </Panel>
  );
  const InnerContent = (
    <Content
      key={2}
      className={classNames(
        collapsed
          ? 'w-[calc(100%-20px)] md:w-[calc(100%-56px)] md:flex-shrink'
          : 'w-full md:w-3/4'
      )}
    >
      {children}
    </Content>
  );

  const elements =
    side === 'left' ? [InnerPannel, InnerContent] : [InnerContent, InnerPannel];

  return <>{elements.map((e) => e)}</>;
};

const PanelContainer: React.FC<
  ChildrenProps & {
    panel: ChildrenProps['children'];
    side?: 'left' | 'right';
    open?: boolean;
  }
> = ({ open = true, ...props }) => {
  return (
    <PanelProvider
      state={{
        collapsed: !open,
      }}
      actions={actions}
    >
      <InnerContainer {...props} />
    </PanelProvider>
  );
};

const Panel = React.forwardRef<
  HTMLDivElement,
  ChildrenProps & { className?: string }
>((props, ref) => {
  const open = usePanel((x) => x.actions.open);
  const close = usePanel((x) => x.actions.close);
  const isOpen = !usePanel((x) => x.collapsed);

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          '-ml-3 md:ml-0 fixed -bottom-4 md:sticky md:top-0 md:h-full pb-3 z-10 md:z-0',
          props.className
        )}
      >
        <div className="flex flex-col h-full bg-white border rounded border-200-gray md:border-b-none md:shadow-none shadow-sm ">
          {props.children}
        </div>
      </div>
      {isOpen && (
        <Overlay z={'z-[5]'} onClick={() => close()} className="md:hidden" />
      )}
      <div
        className={classNames(
          'fixed rounded-full bg-accent-background flex items-center justify-center bottom-4 right-4 md:hidden z-[5] p-2'
        )}
      >
        <ChevronUpIcon
          className="w-4 h-4 text-alt-text"
          onClick={() => open()}
        />
      </div>
    </>
  );
});

const Content: React.FC<ChildrenProps & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames('mx-3 mb-4 md:mx-0', className)}>{children}</div>
  );
};

const Header: React.FC<ChildrenProps> = ({ children }) => {
  const collapsed = usePanel((x) => x.collapsed);

  return (
    <div
      className={classNames(
        'w-full flex items-center border-b border-divider px-4 pb-3 pt-4 text-sm font-medium',
        collapsed ? 'justify-center' : 'justify-between'
      )}
    >
      {children}
    </div>
  );
};

export const ToolPanel = {
  Container: PanelContainer,
  Header,
};
