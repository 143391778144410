import {
  areArraysEqual,
  useSubscribe,
  WatchableViewModel,
} from '@cotera/client/app/etc';
import { ApplyFn } from '../new-column';
import {
  Case,
  Constant,
  Count,
  Expression,
  Relation,
  Sum,
  TC,
} from '@cotera/era';
import {
  AdvancedFilterBuilder,
  convertToFilterGroup,
  DisplayError,
  filterGroupToExpression,
  makeDefaultFilterGroup,
  SideModal,
  useOptions,
} from '@cotera/client/app/components/app';
import { Suspense } from 'react';
import {
  Badge,
  Button,
  Center,
  Divider,
  Icon,
  Loading,
  Title,
  Text,
  Tooltip,
  toast,
} from '@cotera/client/app/components/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { useState } from 'react';
import {
  Accordion,
  useAccordion,
} from '@cotera/client/app/components/headless';
import {
  ChildrenProps,
  classNames,
  ColorScheme,
  Formatters,
} from '@cotera/client/app/components/utils';
import { useFilterBuilder } from '../../../app/filter-builder/hooks';
import { Inputs } from '@cotera/client/app/components/forms';
import {
  useArtifactQuery,
  useDuckDBQuery,
} from '@cotera/client/app/etc/data/duckdb';
import { DataPreview } from '../components/data-preview';
import { useEntity } from '@cotera/client/app/pages/entities/hooks';
import { Assert } from '@cotera/utilities';
import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';
import { startCase } from 'lodash';
import { ExpressionEditor } from '../components/expression-editor';
import { useUpsertErqQLCol } from '@cotera/client/app/hooks/entities';
import {
  DecisionTreeViewModel,
  ExpressionInput,
  ExpressionItem,
} from './view-model';
import { NewColumnAction } from '../column-action.type';
import { ColumnName } from '../components/column-name';

export const DecisionTree: React.FC<{
  open: boolean;
  onClose: () => void;
  vm: WatchableViewModel<{
    rel: Relation;
    baseRel: Relation;
  }>;
  additionalProps: {
    entityName: string;
    columnName?: string;
    eraql?: string;
  };
  onApply: ApplyFn;
}> = ({
  open,
  onClose,
  additionalProps: { entityName, columnName, eraql },
  vm: relVm,
  onApply,
}) => {
  const entity = useEntity(entityName);
  Assert.assert(entity !== undefined);

  const vm = useDeepMemo(
    () => new DecisionTreeViewModel(entity, relVm, columnName, eraql),
    [entity]
  );

  return (
    <SideModal open={open} onClose={onClose}>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <ErrorBoundary
          fallbackRender={({ error }) => <DisplayError error={error} />}
        >
          <Main vm={vm} onApply={onApply} onClose={onClose}>
            <View vm={vm} />
          </Main>
        </ErrorBoundary>
      </Suspense>
    </SideModal>
  );
};

const Main: React.FC<
  ChildrenProps & {
    vm: DecisionTreeViewModel;
    onApply: ApplyFn;
    onClose: () => void;
  }
> = ({ children, vm, onApply, onClose }) => {
  const columnName = useSubscribe(vm, (s) => s.columnName);

  return (
    <div className="p-4 w-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <Title
          type="title"
          subtitle="Visualize and Analyze Data with Interactive Decision Trees"
        >
          Decision Tree
        </Title>
        <ApplyButton vm={vm} onApply={onApply} onClose={onClose} />
      </div>
      <Divider className="mb-4" />
      <div className="flex flex-col">
        <div className="flex flex-row items-center mb-2">
          <Title type="label" className="mr-4 shrink-0">
            {!vm.isNew ? columnName : 'Column Name'}
          </Title>
          <SparkPreviewContainer>
            <OverallSparkPreview vm={vm} />
          </SparkPreviewContainer>
        </div>
        {vm.isNew && <ColumnName vm={vm} className="mb-4" />}
      </div>
      <Divider className="mb-4" />
      {children}
    </div>
  );
};

const ApplyButton: React.FC<{
  vm: DecisionTreeViewModel;
  onApply: ApplyFn;
  onClose: () => void;
}> = ({ vm, onApply, onClose }) => {
  const hasErrors = useSubscribe(vm, (s) => s.hasErrors());
  const errors = useSubscribe(
    vm,
    (s) => s.errors,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const eraql = useSubscribe(vm, (s) => s.caseEraQl);
  const caseExpr = useSubscribe(
    vm,
    (s) => s.caseExpression,
    (a, b) => JSON.stringify(a.ir()) === JSON.stringify(b.ir())
  );
  const items = useSubscribe(
    vm,
    (s) => s.items,
    (a, b) =>
      areArraysEqual(
        a.map((x) => x.id),
        b.map((x) => x.id)
      )
  );
  const columnName = useSubscribe(vm, (s) => s.columnName);

  const upsert = useUpsertErqQLCol({
    entityId: vm.entity.id,
    onSuccess: () => {},
  });

  return (
    <Tooltip
      text={
        hasErrors
          ? Object.values(errors)
              .filter((e) => e !== undefined)
              .join('\n')
          : 'Apply your case statement'
      }
      side="left"
    >
      <Button
        disabled={hasErrors}
        theme="secondary"
        text="Apply"
        hint={{
          display: `Ctrl + a`,
          targetKey: (e) => e.ctrlKey && e.key === 'a',
        }}
        onClick={async () => {
          onApply({
            t: 'decision-tree',
            value: caseExpr,
            column: columnName,
          });
          onClose();
          void upsert
            .mutateAsync({
              data: {
                eraql,
                t: 'decision-tree',
                k: {
                  variants: items.map((x) => x.then.textValue),
                },
              },
              columnName,
            })
            .then(() => {
              toast.success('Column configuration saved');
              vm.clear();
            })
            .catch(() => {
              toast.error('Failed to save column configuration');
            });
        }}
      />
    </Tooltip>
  );
};

const View: React.FC<{
  vm: DecisionTreeViewModel;
}> = ({ vm }) => {
  const items = useSubscribe(
    vm,
    (s) => s.items,
    (a, b) =>
      areArraysEqual(
        a.map((x) => x.id),
        b.map((x) => x.id)
      )
  );
  const errors = useSubscribe(
    vm,
    (s) => s.errors,
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );

  return (
    <Accordion.Root expanded={[]} multiple={false}>
      {errors.empty && (
        <div className="p-2 rounded mt-2 text-sm bg-error">
          <Text.Caption>{errors.empty}</Text.Caption>
        </div>
      )}
      {items.map((item) => {
        return (
          <Item
            key={item.id}
            vm={vm}
            item={item}
            onRemove={() => {
              vm.removeItem(item.id);
            }}
          />
        );
      })}
      <div className="w-full flex justify-center mt-4">
        <Button
          iconOnly
          tooltip="right"
          className="w-fit !rounded-full"
          icon="add"
          onClick={() => {
            vm.addItem(`Item ${items.length + 1}`);
          }}
          text="Add Item"
        />
      </div>
    </Accordion.Root>
  );
};

const Item: React.FC<{
  onRemove: () => void;
  vm: DecisionTreeViewModel;
  item: ExpressionItem;
}> = ({ onRemove, vm, item }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const expanded = useAccordion((s) => s.expanded);
  const options = useOptions(rel.attributes);
  const filter = useSubscribe(item, (s) => s.filter);
  const then = useSubscribe(item, (s) => s.then);
  const name = useSubscribe(item, (s) => s.name);

  const [type, setType] = useState<'Filter' | 'Expression'>('Filter');
  const isDefault = item.filter.expr.expr.ast.t === 'scalar';

  const [state] = useFilterBuilder({
    filterGroup: isDefault
      ? makeDefaultFilterGroup()
      : convertToFilterGroup(item.filter.expr.expr),
    options,
    onChange: (state) => {
      const expr = filterGroupToExpression(
        rel.ref('from'),
        Constant(false)
      )(state);
      const exprTyRes = TC.checkExpr(expr.ast);

      if (exprTyRes instanceof TC.TyStackTrace) {
        item.setFilter(null, undefined, {
          t: 'Type Error',
          msg: exprTyRes.message.toString(),
        });
        return;
      }

      item.setFilter({
        tc: exprTyRes,
        expr,
      });
    },
  });

  const isExpanded = expanded.includes(item.id);

  return (
    <>
      <Accordion.Trigger id={item.id}>
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between w-full mb-2 mt-2">
            <div className="flex">
              <Button
                className="!pl-0 !mt-0 !pt-0"
                inline
                theme="error"
                iconOnly
                icon="trash"
                onClick={onRemove}
              />
              <div className="flex items-center flex-wrap ml-2 mt-1">
                {!isExpanded && (
                  <>
                    <Badge
                      size="small"
                      theme="primary"
                      className="mr-2 shrink-0 mb-2"
                    >
                      IF
                    </Badge>
                    <Text.P className="mr-2 break-words	mb-2">
                      {filter.textValue ?? 'false'}
                    </Text.P>
                    <Badge
                      size="small"
                      theme="primary"
                      className="mr-2 shrink-0 mb-2"
                    >
                      THEN
                    </Badge>
                    <Text.P className="mr-2 shrink-0 mb-2">{item.name}</Text.P>
                  </>
                )}
                {isExpanded && <Text.P className="mb-2">{item.name}</Text.P>}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center mr-2">
                <SparkPreviewContainer>
                  <SparkPreview vm={vm} filter={filter.expr} />
                </SparkPreviewContainer>
              </div>
              <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} />
            </div>
          </div>
          <Divider />
        </div>
      </Accordion.Trigger>
      <Accordion.Item id={item.id}>
        <div className="flex flex-col mt-4 h-[500px] overflow-auto border-b border-divider">
          <div className="w-full justify-between flex items-center mb-2">
            <Badge className="shrink-0" theme="primary">
              IF
            </Badge>
            <Inputs.Toggle
              compact
              value={type}
              options={['Filter', 'Expression']}
              onChange={(v) => setType(v!)}
            />
          </div>
          {type === 'Filter' && (
            <div className="flex flex-col">
              <AdvancedFilterBuilder
                rel={rel}
                fillRow
                filterGroup={state}
                options={options}
                showRun={false}
              />
              {item.filter.error !== undefined && (
                <div
                  className={classNames(
                    'p-2 rounded mt-2 text-sm border',
                    ColorScheme.background.error,
                    ColorScheme.text.error,
                    ColorScheme.border.error
                  )}
                >
                  {startCase(item.filter.error.t)}: {item.filter.error.msg}
                </div>
              )}
            </div>
          )}
          {type === 'Expression' && (
            <div className="mb-6">
              <ExpressionEditor
                ty={filter?.expr.expr.ty}
                entity={vm.entity}
                eraql={filter?.textValue ?? ''}
                error={filter?.error}
                onChange={(v, res) => {
                  if (res.isErr()) {
                    item.setFilter(null, v, res.error);
                  } else {
                    item.setFilter(
                      {
                        tc: res.value.tc,
                        expr: Expression.fromAst(res.value.ast),
                      },
                      v
                    );
                  }
                }}
              />
            </div>
          )}
          <div className="flex items-center justify-between mb-4">
            <Badge className="shrink-0 mr-2" theme="primary">
              THEN
            </Badge>
            <Inputs.Text
              className="flex-1"
              value={name}
              onChange={(v) => {
                const expr = Constant(v);

                const exprTyRes = TC.checkExpr(expr.ast);

                item.setThen(
                  {
                    tc: exprTyRes as TC.ExprTypeCheck,
                    expr,
                  },
                  v
                );
              }}
            />
          </div>
          <Suspense
            fallback={
              <Center>
                <Loading.Dots />
              </Center>
            }
          >
            <ErrorBoundary
              fallbackRender={({ error }) => <DisplayError error={error} />}
            >
              {filter !== null && (
                <Preview vm={vm} filter={filter['expr']} then={then.expr} />
              )}
            </ErrorBoundary>
          </Suspense>
        </div>
      </Accordion.Item>
    </>
  );
};

const Preview: React.FC<{
  vm: DecisionTreeViewModel;
  filter: ExpressionInput['expr'];
  then: ExpressionInput['expr'];
}> = ({ vm, filter, then }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const columnName = useSubscribe(vm, (s) =>
    s.columnName.length > 0 ? s.columnName : 'case_statement'
  );
  const sourceColumns = filter.tc.attrReqs.from ?? {};

  const transform = (rel: Relation) =>
    rel.select((t) => ({
      ...t.pick(...Object.keys(sourceColumns)),
      [columnName]: then.expr,
    }));

  const { data } = useArtifactQuery({
    baseRel: rel,
    rel: (rel) => transform(rel).where((_) => filter.expr),
    limit: 6,
  });
  const { data: falseData } = useArtifactQuery({
    baseRel: rel,
    rel: (rel) => transform(rel).where((_) => filter.expr.not()),
    limit: 6,
  });

  return (
    <div className="flex flex-col w-full mb-8">
      <Divider caption="Examples" className="mb-2" />
      <DataPreview
        records={[...data.data.toArray(), ...falseData.data.toArray()]}
        columnName={columnName}
      />
    </div>
  );
};

const SparkPreviewContainer: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Suspense fallback={null}>
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <Tooltip text={error.message} side="top">
            <div className="flex">
              <Icon icon="x-circle" theme="error" />
            </div>
          </Tooltip>
        )}
      >
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

const SparkPreview: React.FC<{
  vm: DecisionTreeViewModel;
  filter: ExpressionInput['expr'];
}> = ({ vm, filter }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);

  const { data: pieData } = useDuckDBQuery({
    rel: rel
      .groupBy((_) => ({
        category: Case(
          [
            {
              when: filter.expr,
              then: 'Match',
            },
          ],
          {
            else: 'No Match',
          }
        ),
      }))
      .select((t) => ({
        category: t.attr('category'),
        value: Count(),
      })),
  });

  const arrayData = pieData.data.toArray();
  const total = arrayData.reduce((acc, row) => acc + Number(row['value']), 0);
  const countMatch =
    arrayData.filter((row) => row['category'] === 'Match').at(0)?.['value'] ??
    0;
  const percentageMatching = (Number(countMatch) / total) * 100;

  return (
    <Badge theme={'regular'}>
      {Formatters.number(percentageMatching, '%')}
    </Badge>
  );
};

const OverallSparkPreview: React.FC<{
  vm: DecisionTreeViewModel;
}> = ({ vm }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const numItems = useSubscribe(vm, (s) => s.items.length);
  const caseExpr = useSubscribe(
    vm,
    (s) => s.caseExpression,
    (a, b) => JSON.stringify(a.ir()) === JSON.stringify(b.ir())
  );

  const { data: pieData } = useDuckDBQuery({
    rel: rel
      .groupBy((_) => ({
        category: caseExpr,
      }))
      .select((t) => ({
        category: t.attr('category'),
        value: Count(),
      }))
      .groupBy((t) => ({
        category: Case(
          [
            {
              when: t.attr('category').isNotNull(),
              then: 'Match',
            },
          ],
          {
            else: 'No Match',
          }
        ),
      }))
      .select((t) => ({
        category: t.attr('category'),
        value: Sum(t.attr('value')),
      })),
  });

  const arrayData = pieData.data.toArray();
  const total = arrayData.reduce((acc, row) => acc + Number(row['value']), 0);

  const countMatch =
    arrayData.filter((row) => row['category'] === 'Match').at(0)?.['value'] ??
    0;
  const percentageMatching = (Number(countMatch) / total) * 100;

  return (
    <Text.Caption>
      {Formatters.number(
        countMatch === 0 || numItems === 0 ? 0 : percentageMatching,
        '%'
      )}{' '}
      Matched
    </Text.Caption>
  );
};

export const DecisionTreeColumn: NewColumnAction = {
  icon: 'filter',
  view: DecisionTree as NewColumnAction['view'],
  label: 'Decision Tree',
};
