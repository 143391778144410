export const AppStoreIllustration: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1024 1024"
      enableBackground="new 0 0 1024 1024"
      {...props}
    >
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12.000000"
        d="
M528.500000,603.000000 
 C532.248230,598.828735 536.934021,597.411560 542.500305,597.438660 
 C570.166321,597.572998 597.834106,597.377380 625.499634,597.559570 
 C636.752014,597.633728 641.619019,592.751038 641.580994,581.499756 
 C641.534851,567.835754 641.147400,554.169128 641.891479,540.494080 
 C642.263000,533.666138 640.150940,526.710632 643.000000,520.000000 
 C639.527222,486.544373 642.473267,452.993042 641.504517,419.499878 
 C641.290833,412.111084 636.902283,407.909973 629.510437,406.921722 
 C616.996216,405.248596 604.501221,406.588745 592.000183,406.480652 
 C585.332520,406.422974 578.666077,406.004089 572.000122,406.028687 
 C545.833130,406.125275 519.666870,406.400482 493.499908,406.467743 
 C482.667084,406.495575 471.833801,406.078033 460.999878,406.021057 
 C445.333679,405.938690 429.665680,405.896118 414.000427,406.045105 
 C406.497925,406.116425 398.991272,406.536255 391.502533,407.037933 
 C384.503479,407.506836 379.748871,413.467499 379.533661,422.000854 
 C379.365631,428.663269 379.500000,435.333344 379.500000,442.000000 
 C379.500000,465.666656 379.500000,489.333344 379.500000,513.000000 
 C379.500000,535.000000 379.513367,557.000000 379.487640,579.000000 
 C379.483002,582.965210 379.648407,587.023315 381.586823,590.450928 
 C384.782166,596.101074 390.142731,598.044800 396.500061,598.029175 
 C424.166565,597.961182 451.833771,598.077698 479.499634,597.930664 
 C484.673584,597.903137 488.989746,599.193359 492.500000,603.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M456.500000,229.500000 
 C458.039124,218.695938 454.046326,209.474670 447.954681,201.032700 
 C442.067963,192.874710 434.848969,186.316940 425.012268,182.963974 
 C398.476746,173.919022 372.665833,184.488235 361.601562,210.543121 
 C359.816711,214.746140 357.660767,216.515213 353.002228,216.609589 
 C338.420990,216.904953 322.426544,228.000977 321.569458,247.503052 
 C320.881042,263.166901 333.635162,278.351593 349.501923,278.156708 
 C388.496033,277.677673 427.500000,277.993408 466.500000,278.007507 
 C472.025269,278.009491 477.125153,276.876526 482.075592,274.136536 
 C484.995331,272.520477 488.515472,270.915710 492.092499,273.888733 
 C493.160156,274.776093 495.332764,274.470428 497.000092,274.477600 
 C507.333252,274.522125 517.666809,274.466949 527.999939,274.511475 
 C535.047607,274.541840 539.951416,279.506012 540.002197,286.499969 
 C540.162964,308.666687 540.333374,330.833344 540.500061,353.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12.000000"
        d="
M791.500000,626.500000 
 C792.557007,634.147339 791.984558,641.836731 791.931885,649.499512 
 C791.882690,656.650879 794.382019,659.250122 801.502197,659.114868 
 C819.662292,658.770020 837.836487,658.812012 855.997925,659.120605 
 C861.097046,659.207275 864.437805,657.128784 864.469360,650.499878 
 C864.557495,632.000244 864.533508,613.499878 864.476868,595.000061 
 C864.462341,590.250610 861.717712,587.530029 856.999939,587.518799 
 C838.000061,587.473511 818.999512,587.576233 800.000366,587.448608 
 C794.985046,587.414917 791.837646,590.482178 791.951477,595.501099 
 C792.132812,603.497009 792.008179,611.500000 791.994751,619.500000 
 C791.991028,621.712097 792.265320,623.976685 791.000000,626.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M701.500000,364.500000 
 C699.184692,363.609375 696.977356,362.703125 694.943604,361.070282 
 C688.727539,356.079590 681.337036,354.474884 673.500000,354.493744 
 C663.000061,354.519012 652.457458,355.088654 642.012024,354.333984 
 C631.623901,353.583466 621.299683,355.599457 611.009583,354.416748 
 C600.965576,353.262329 590.978210,355.486389 581.005798,354.444519 
 C569.813904,353.275269 558.668762,354.642426 547.499634,354.606812 
 C480.166992,354.391968 412.833344,354.503662 345.500000,354.497375 
 C329.245056,354.495850 316.687775,364.979736 315.000702,382.000061 
 C314.472809,387.326050 314.239380,392.657379 314.750000,398.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M230.000000,594.500000 
 C231.134628,583.848450 230.260788,573.165771 230.464615,562.499329 
 C230.534836,558.824585 231.417313,555.539795 233.480606,552.486877 
 C236.622131,547.838623 241.033325,545.944031 246.500061,545.992310 
 C267.499908,546.177856 288.500366,546.299377 309.499725,546.525513 
 C314.335266,546.577576 314.995544,547.422668 314.996399,552.500000 
 C315.002899,591.166687 315.032471,629.833374 314.980835,668.500000 
 C314.964691,680.588257 322.542725,694.380066 336.514954,697.941284 
 C339.411743,698.679626 342.496826,698.915710 345.500580,698.954041 
 C355.165680,699.077393 364.835205,699.114502 374.499298,698.957275 
 C380.336426,698.862366 386.110504,699.826111 392.016235,699.139709 
 C397.607330,698.489685 403.355865,698.686584 408.993378,699.091919 
 C414.191101,699.465637 419.323029,699.320190 424.502899,699.057251 
 C429.656281,698.795532 434.887329,698.522827 439.988678,699.100159 
 C450.359894,700.273743 460.675385,698.097534 470.996246,699.041077 
 C476.017944,699.500183 480.981171,699.427307 486.005310,699.076111 
 C490.980988,698.728210 496.053680,698.531616 500.988861,699.097168 
 C511.358887,700.285522 521.690918,697.969971 531.990234,699.089600 
 C537.544983,699.693542 542.999939,699.023682 548.500000,699.004272 
 C553.999939,698.984802 559.517334,698.729126 564.994873,699.079651 
 C570.018921,699.401184 574.981201,699.427368 580.005310,699.076111 
 C584.981018,698.728271 590.053650,698.531616 594.988892,699.097168 
 C605.358887,700.285522 615.716919,697.804993 625.982727,699.133606 
 C633.072693,700.051208 639.996155,698.769653 647.001099,698.959717 
 C653.829407,699.145020 660.701904,698.569092 667.489868,699.123779 
 C673.996826,699.655579 680.266846,699.278381 686.500244,697.500793 
 C696.987732,694.510010 706.585144,683.312500 706.532410,669.999878 
 C706.424255,642.666992 706.774048,615.328430 706.285583,588.003845 
 C706.178528,582.016724 707.091125,576.050842 706.182678,569.972717 
 C705.329834,564.266846 705.951782,558.332642 706.036621,552.500549 
 C706.055847,551.176270 705.442017,549.721924 706.500000,548.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12.000000"
        d="
M186.500000,484.500000 
 C194.981354,483.382111 203.502243,484.290619 212.000320,484.009766 
 C213.998138,483.943726 216.000900,484.038727 217.999817,483.992126 
 C222.450668,483.888336 224.387238,482.038574 224.473984,477.499512 
 C224.582245,471.834930 224.500000,466.166656 224.500000,460.500000 
 C224.500000,448.166656 224.524994,435.833282 224.488220,423.500031 
 C224.472534,418.240906 221.759033,415.520752 216.499985,415.511841 
 C198.333359,415.481079 180.166672,415.491333 162.000000,415.504974 
 C156.057159,415.509430 154.021133,417.516357 154.010544,423.500031 
 C153.980469,440.499969 154.032333,457.500183 153.942032,474.499695 
 C153.934341,475.945892 155.297821,478.482239 152.000000,478.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M572.500000,701.000000 
 C572.500000,723.333313 572.466309,745.666748 572.522888,767.999939 
 C572.537659,773.833618 572.763306,779.669739 573.018433,785.499207 
 C573.311890,792.206238 577.599487,799.150085 589.000366,799.034912 
 C603.332214,798.890015 617.666687,799.000000 632.000000,799.000000 
 C671.333313,799.000000 710.666687,799.012573 750.000000,798.978210 
 C753.500122,798.975159 757.000000,798.666687 760.500000,798.500061 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M378.000000,520.500000 
 C369.346344,519.774353 360.529724,519.733215 351.995850,519.681335 
 C343.666809,519.630737 334.969238,520.512817 326.505066,520.298889 
 C317.815521,520.079407 309.166840,520.554871 300.499939,520.509888 
 C291.833527,520.464844 283.166656,520.500000 274.500000,520.500000 
 C266.000000,520.500000 257.500000,520.500000 249.000000,520.500000 
 C240.333328,520.500000 231.666672,520.500000 223.000000,520.500000 
 C214.500000,520.500000 205.999359,520.565002 197.500183,520.481873 
 C188.832703,520.397095 180.166672,520.166687 171.500000,520.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M795.500000,460.000000 
 C795.500000,475.166656 795.514954,490.333374 795.494141,505.500000 
 C795.482605,513.905701 789.463806,520.388306 780.999634,520.445801 
 C757.833984,520.603088 734.654236,520.912231 711.508606,520.214294 
 C705.617859,520.036621 699.856262,521.020691 693.993408,520.589600 
 C688.357178,520.175171 682.604004,521.037964 677.013245,520.386597 
 C665.824707,519.082947 654.641113,521.880493 643.500000,520.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M385.500000,701.000000 
 C385.666656,705.833313 385.994629,710.667419 385.972595,715.499878 
 C385.866943,738.666870 385.679840,761.833435 385.493256,784.999939 
 C385.422699,793.756348 378.322815,799.717651 369.494110,799.081970 
 C363.525055,798.652161 357.499786,799.031616 351.500061,798.989868 
 C346.833069,798.957336 342.166656,798.833374 337.500000,798.750061 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M539.500000,701.000000 
 C539.500000,712.666687 539.545227,724.333496 539.491150,735.999939 
 C539.350708,766.333374 539.173462,796.666748 538.979797,826.999878 
 C538.970276,828.500854 538.666626,830.000000 538.500000,831.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M704.000000,255.000000 
 C706.503723,253.645279 709.363342,253.369019 711.973816,252.427399 
 C720.035339,249.519577 727.247314,240.400833 726.322449,229.515091 
 C725.424194,218.942703 718.015259,211.774445 708.013062,209.929138 
 C696.876526,207.874542 684.766113,216.018860 682.423218,227.984970 
 C680.511780,237.747635 687.220276,249.477768 697.519226,252.942795 
 C699.605713,253.644775 701.634399,254.344055 703.500000,255.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M574.500000,474.500000 
 C575.663940,468.308624 575.268188,467.000580 569.982178,464.538300 
 C551.994629,456.159546 534.727722,446.380615 517.014465,437.471252 
 C512.750427,435.326599 509.274445,434.622253 504.388733,437.296783 
 C488.183228,446.167999 471.556183,454.280914 454.968872,462.436676 
 C449.321045,465.213684 446.487396,468.602814 446.807129,475.508942 
 C447.453827,489.479004 446.993042,503.500000 447.006378,517.500000 
 C447.008789,520.038208 446.563507,522.703125 448.028687,524.981567 
 C449.740875,527.644226 446.783966,528.945984 446.500000,531.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M448.000000,701.000000 
 C448.000000,726.000000 448.053650,751.000183 447.954712,775.999817 
 C447.934906,781.001038 447.949036,786.139282 446.856628,790.967590 
 C445.192841,798.321411 440.430969,803.908875 434.517975,808.523071 
 C432.570190,810.043030 430.833313,811.833313 428.999969,813.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M633.750000,237.500000 
 C633.833313,247.833328 633.976196,258.166595 633.988525,268.500000 
 C634.021973,296.499969 634.000000,324.500000 634.000000,352.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M822.500000,546.250000 
 C808.497314,545.881104 794.502930,546.177185 780.498291,546.379822 
 C759.838318,546.678650 739.166687,546.166199 718.500000,546.000427 
 C714.761475,545.970398 711.061279,546.146423 707.480591,547.446533 
 C706.953796,547.637817 706.365784,547.833374 705.469849,547.638550 
 C693.681824,545.075317 681.839722,545.015076 669.991699,547.459656 
 C668.552917,547.756470 667.000000,547.500000 665.500000,547.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M509.000000,701.000000 
 C509.000000,709.666687 508.954712,718.333618 509.008820,726.999939 
 C509.149231,749.500122 509.358826,771.999878 509.488190,794.500061 
 C509.544861,804.357788 505.454193,808.538940 495.499847,808.996460 
 C493.394165,809.093262 491.797638,811.190613 489.500000,810.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M703.500000,256.000000 
 C704.301270,257.603210 703.987732,259.325592 703.988525,261.000000 
 C704.004089,292.500000 704.011169,324.000031 703.965393,355.499939 
 C703.963257,356.988617 704.495850,358.527252 703.523743,360.015503 
 C701.159790,363.634491 702.360474,367.551270 703.634521,370.949554 
 C705.608948,376.216034 705.805481,381.526978 706.071838,386.996490 
 C706.778442,401.506805 705.893738,416.000336 706.019348,430.499847 
 C706.066956,436.000854 706.916077,441.559937 706.357910,446.985382 
 C705.597534,454.377350 706.773315,461.661499 706.547791,469.001465 
 C706.327881,476.161377 706.500000,483.333344 706.500000,490.500000 
 C706.500000,497.833344 706.534912,505.166931 706.477051,512.499817 
 C706.461304,514.501038 706.166687,516.500000 706.000000,518.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M416.500000,701.000000 
 C417.586243,734.162048 416.734161,767.333801 416.976196,800.500183 
 C416.992004,802.665527 416.666656,804.833313 416.500000,807.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M303.500000,309.500000 
 C294.514954,310.474365 285.499878,309.943878 276.499908,309.972900 
 C252.000168,310.051880 227.500000,309.993042 203.000015,310.014008 
 C201.161285,310.015594 199.271820,309.656647 197.500000,310.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M534.500000,247.750000 
 C544.666687,247.833328 554.833191,247.944016 565.000061,247.980072 
 C567.429749,247.988693 569.811584,248.021851 572.233826,249.401031 
 C573.265320,283.657654 572.973145,318.080353 573.000000,352.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M416.500000,807.500000 
 C412.470062,809.379822 407.729218,808.857727 403.935730,811.920410 
 C398.061523,816.663147 394.363708,822.319580 395.064209,829.994141 
 C395.795074,838.001953 400.267639,843.646362 407.512756,846.972229 
 C413.430176,849.688538 423.324402,847.658997 428.000031,843.000061 
 C433.261017,837.757874 435.209930,828.348572 433.079926,821.973328 
 C430.401154,813.955627 424.097595,810.386536 417.000000,807.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M603.250000,255.500000 
 C603.166687,272.500000 603.054138,289.499908 603.008667,306.500031 
 C602.967651,321.833252 603.000000,337.166656 603.000000,352.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M415.750000,515.500000 
 C417.192322,520.945801 416.382324,526.499084 416.462921,532.000549 
 C416.598450,541.251526 416.500000,550.505981 416.500000,560.098450 
 C433.443024,562.903687 449.992828,560.771790 466.500000,560.750000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M553.500000,662.000000 
 C554.362854,655.855591 550.676880,650.820251 548.706177,645.611145 
 C545.797729,637.923401 542.896729,630.198059 540.099121,622.464172 
 C537.969177,616.576172 535.080200,611.626343 529.516418,608.471008 
 C528.402222,607.839050 527.500000,606.833374 526.500000,606.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.000000"
        d="
M500.000000,605.750000 
 C498.500000,605.666687 497.000000,605.583374 495.500000,605.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M350.500000,455.000000 
 C309.000000,454.833313 267.500000,454.666656 226.000000,454.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M443.000000,664.500000 
 C446.961670,663.413452 451.006348,664.299438 454.999512,663.993591 
 C458.857910,663.698120 462.751953,664.650146 466.566895,662.626038 
 C468.734131,661.476135 470.944641,664.081055 473.500336,664.069092 
 C498.166443,663.953857 522.851013,664.540771 547.491333,663.734436 
 C557.377441,663.410889 567.166687,665.157715 577.000000,664.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M790.500000,626.000000 
 C778.667725,625.757019 766.838501,626.130798 754.994690,625.624207 
 C744.853699,625.190430 734.667175,625.915894 724.499878,625.979492 
 C711.167053,626.062988 697.833191,626.032471 684.500122,625.977173 
 C681.166138,625.963318 677.829041,625.725220 674.501221,625.482849 
 C671.464539,625.261597 670.014709,623.489746 669.438965,620.511780 
 C668.888184,617.662964 669.104736,614.836975 669.000000,612.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M478.500000,701.000000 
 C478.789520,728.832581 477.896881,756.672302 479.000000,784.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M539.000000,832.000000 
 C545.856445,835.253906 552.299316,839.257385 554.380066,847.032104 
 C556.406921,854.605225 555.316284,862.076782 548.938660,867.426880 
 C542.180786,873.095886 534.338928,875.049561 526.028870,870.941589 
 C519.155823,867.543884 515.600647,862.045105 514.828979,854.016418 
 C513.724426,842.525085 523.909973,832.414001 534.502625,832.947266 
 C535.649658,833.005005 536.833313,832.333374 538.000000,832.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M315.000000,548.000000 
 C316.402008,546.671692 318.234589,546.082825 320.000793,546.058533 
 C329.165741,545.932739 338.336121,545.871765 347.498474,546.070801 
 C350.626007,546.138672 352.566010,547.804077 352.550995,551.500183 
 C352.452209,575.833130 352.506897,600.166687 352.478760,624.500000 
 C352.477417,625.666870 352.166687,626.833313 352.000000,628.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M302.500000,751.250000 
 C278.500000,751.333374 254.500031,751.434998 230.499954,751.466614 
 C228.500290,751.469238 226.500000,751.000000 224.500000,750.750000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M634.500000,826.000000 
 C631.224182,828.185181 627.191956,829.263733 624.971497,832.982971 
 C622.543335,837.050293 622.137634,844.867798 626.000488,849.499634 
 C629.286377,853.439636 636.553772,855.375732 641.000061,853.500122 
 C645.763367,851.490662 650.388611,844.552551 650.000000,840.000000 
 C649.518677,834.362000 644.566772,828.503296 638.999023,827.003601 
 C637.672180,826.646179 636.333313,826.333313 635.000000,825.999939 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M488.500000,271.500000 
 C489.799286,265.969604 493.766846,261.674469 495.122101,256.029327 
 C499.125732,239.352585 490.195038,224.039627 472.980988,219.065781 
 C467.965546,217.616608 462.707428,217.402771 457.500000,218.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M603.000000,701.000000 
 C603.000000,713.000000 603.171570,725.003235 602.943481,736.998901 
 C602.773743,745.926880 609.842163,751.369629 617.000610,751.439453 
 C635.498840,751.620056 654.000000,751.502625 672.500000,751.489502 
 C673.166748,751.489014 673.833313,751.333374 674.500000,751.250061 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M282.500000,665.750000 
 C260.165405,666.008606 237.830322,666.473511 215.498871,665.526733 
 C214.507721,665.484741 213.500000,665.833313 212.500000,666.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M354.500000,710.500000 
 C354.500000,719.000000 354.500000,727.500000 354.500000,736.000000 
 C354.500000,745.313965 348.434570,751.480225 339.000092,751.458984 
 C334.002106,751.447754 328.984009,751.972778 323.991180,751.047546 
 C322.648926,750.798767 321.083588,750.667725 320.000000,752.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M774.000000,574.500000 
 C767.862122,573.267212 761.674683,574.464355 755.491516,574.192871 
 C741.518494,573.579407 727.499939,573.981750 713.500061,574.022156 
 C711.666260,574.027466 709.833313,574.333374 708.000000,574.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M196.500000,653.000000 
 C187.767380,653.090515 182.000000,659.056763 182.000000,668.000000 
 C182.000000,676.423584 188.040039,682.895020 196.000000,683.000000 
 C204.133179,683.107239 211.440720,675.571350 212.000000,666.500000 
 C212.362686,660.617371 204.140945,653.217773 197.000000,653.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M668.750000,436.500000 
 C669.628296,442.483978 668.193420,448.757080 671.000000,454.500000 
 C667.437134,459.546204 669.709290,465.176941 669.599365,470.502045 
 C669.307068,484.662659 669.518005,498.833405 669.477844,512.999939 
 C669.472656,514.833679 669.166687,516.666687 669.000000,518.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M352.000000,418.500000 
 C352.825134,427.988434 352.998688,437.540070 352.344421,446.989227 
 C351.579590,458.035309 352.992035,468.988739 352.586761,480.003204 
 C352.188721,490.821899 352.525024,501.666779 352.477478,512.499878 
 C352.468658,514.500610 352.166656,516.500000 352.000000,518.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M315.000000,455.500000 
 C313.599060,463.666656 315.262024,471.829407 315.034576,480.000977 
 C314.728516,490.997070 314.640106,501.999725 314.526154,513.000244 
 C314.507202,514.831665 314.833374,516.666687 315.000031,518.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M275.000000,810.000000 
 C284.501068,808.119751 288.824219,798.964844 283.500031,792.000000 
 C281.046173,788.790039 274.348358,786.986816 270.500000,788.500000 
 C266.432007,790.099548 263.808502,794.539368 264.000031,799.500000 
 C264.212189,804.996643 266.667145,808.074402 272.001892,809.492859 
 C272.821594,809.710815 273.666656,809.833374 274.500000,810.000061 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M847.000000,387.500000 
 C840.822876,388.020966 837.075134,392.557800 837.500061,399.000000 
 C837.821045,403.867493 842.220703,407.654419 847.499573,407.486572 
 C852.907715,407.314545 858.054138,404.525055 858.005737,396.999969 
 C857.968384,391.188232 854.139038,387.702484 847.500000,387.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M494.500000,606.000000 
 C483.640411,612.633606 481.311249,624.463623 477.094360,635.037598 
 C474.766846,640.873962 472.566589,646.759766 470.001465,652.500671 
 C468.648346,655.528931 467.728088,658.674072 467.500000,662.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M796.500000,415.000000 
 C796.770142,416.603485 795.882629,416.856537 794.504578,417.035248 
 C784.018677,418.395447 775.949158,426.193695 775.580200,436.003021 
 C775.190979,446.348633 779.860107,453.976379 791.011353,457.968353 
 C798.986572,460.823456 810.607605,454.823639 814.569214,447.035217 
 C819.325867,437.683807 816.375244,427.368500 809.571167,421.911255 
 C805.763123,418.856903 801.674561,417.179108 797.000000,416.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M230.500000,595.000000 
 C234.875412,598.070984 240.853287,598.818054 242.665527,605.454773 
 C244.344940,611.605164 243.338226,617.010376 238.000000,621.000000 
 C233.535599,624.336548 226.154968,624.275452 221.989441,621.013489 
 C214.645996,615.262695 214.700424,602.447815 224.998230,597.496338 
 C226.677399,596.688965 228.333328,595.833313 229.999985,595.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M855.000000,517.000000 
 C856.934021,523.398987 860.211670,526.145996 864.992065,526.586304 
 C870.822693,527.123291 874.887634,521.478455 875.505554,517.500854 
 C876.345947,512.090881 871.538025,507.257996 867.498169,506.509888 
 C861.957214,505.483856 856.838074,508.923157 855.499146,514.499817 
 C855.338745,515.167969 855.166687,515.833374 855.000061,516.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M467.500000,522.000000 
 C468.873840,524.502808 469.304230,527.204895 468.983093,529.998047 
 C468.467926,534.479065 470.064392,537.095642 474.433838,539.141296 
 C484.145935,543.688293 493.384186,549.239807 503.045410,553.905945 
 C508.438873,556.510864 514.213318,555.535095 519.479858,552.958862 
 C528.718628,548.439514 537.764160,543.522888 547.030945,539.064331 
 C551.276672,537.021545 552.306824,534.143433 552.719910,529.519653 
 C553.633789,519.289856 551.888550,509.178131 552.377869,498.994141 
 C552.563477,495.130219 552.963196,490.275665 547.000000,489.500000 
 C538.804993,478.878723 525.211365,476.905212 514.969666,469.542206 
 C512.000732,467.407776 508.704468,467.708984 505.492340,469.486145 
 C498.183258,473.530029 490.922363,477.677490 483.460541,481.421326 
 C479.991058,483.162109 477.639465,486.337189 474.780304,488.154327 
 C467.297791,492.909729 469.368439,499.621246 469.015259,506.000854 
 C468.727905,511.191193 469.827911,516.510254 467.500000,521.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M464.500000,328.500000 
 C451.011078,329.565033 437.498383,328.718018 424.000244,329.010895 
 C419.498749,329.108551 415.000000,329.333313 410.500000,329.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M831.000000,310.000000 
 C813.500000,310.000000 796.000000,310.000000 778.500000,310.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M573.000000,474.500000 
 C575.393005,474.349091 574.996643,476.070221 574.997131,477.500000 
 C575.001831,491.833344 575.887207,506.248627 574.574463,520.460693 
 C574.029968,526.354736 576.195496,530.578552 577.500000,535.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M634.000000,701.000000 
 C634.000000,729.500000 633.952454,758.000122 634.022827,786.499939 
 C634.052063,798.333618 634.320862,810.166870 634.519714,821.999695 
 C634.539307,823.168823 634.833313,824.333313 635.000000,825.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M570.499939,204.500000 
 C570.666626,201.833328 570.953857,199.167709 570.976990,196.499802 
 C571.042603,188.951630 567.003296,183.241669 560.500000,181.500000 
 C554.578369,179.914078 547.640991,182.751938 544.552673,188.528168 
 C540.892822,195.373306 541.752380,200.679108 547.104370,206.402390 
 C551.860657,211.488647 558.068420,212.731476 565.466187,207.947723 
 C567.111267,206.883972 568.257874,205.040939 570.500000,205.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M669.500000,548.000000 
 C669.500000,561.333313 669.514160,574.666687 669.488647,588.000000 
 C669.483215,590.833435 669.333313,593.666687 669.249939,596.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M571.000000,205.000000 
 C577.122986,210.724869 584.705078,214.434113 591.115845,219.863190 
 C599.248047,226.750031 604.074890,234.675705 603.500000,245.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8.000000"
        d="
M430.500000,309.250000 
 C415.166656,309.250000 399.833344,309.250000 384.500000,309.250000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.000000"
        d="
M511.000000,510.000000 
 C511.000000,526.666687 511.000000,543.333313 511.000000,560.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8.000000"
        d="
M753.500000,286.500000 
 C751.518005,279.341400 746.089966,276.953003 741.000000,281.000000 
 C738.053589,283.342651 737.598999,287.888306 740.000000,291.000000 
 C742.325195,294.013489 747.028687,294.776306 749.981018,292.475677 
 C751.753418,291.094543 752.970398,289.238770 753.500000,287.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M485.500000,877.499939 
 C470.833344,877.666626 456.166656,877.833313 441.500000,877.999939 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M515.500000,192.000000 
 C502.500000,192.000000 489.500000,192.000000 476.500000,192.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M475.000000,489.500000 
 C482.113922,495.252289 490.583191,498.645294 498.499512,503.000885 
 C500.002899,503.828064 501.478241,504.709045 503.003632,505.492950 
 C511.362213,509.788361 511.495239,509.996796 519.964355,504.940277 
 C528.753540,499.692596 538.552734,496.181580 546.500000,489.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8.000000"
        d="
M354.500000,838.000000 
 C343.000000,837.166870 331.500000,837.166870 320.000000,838.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12.000000"
        d="
M154.000000,479.000000 
 C155.036621,483.610077 158.600357,484.481995 162.499817,484.462982 
 C169.167145,484.430511 175.832932,484.093109 182.500305,484.057098 
 C183.973953,484.049133 186.421692,482.823273 186.500000,486.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M671.500000,454.500000 
 C682.485657,455.613037 693.501953,454.704926 704.500000,455.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M446.500000,442.750000 
 C435.500000,443.165710 424.500000,443.165710 413.500000,442.750000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M844.000000,609.500000 
 C833.181458,608.372559 822.331116,609.309692 811.500000,609.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M314.749969,423.000000 
 C314.666656,433.500000 314.583313,444.000000 314.499969,454.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M604.500000,446.000000 
 C604.833191,456.330658 603.834534,466.682770 605.000000,477.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8.000000"
        d="
M846.500000,627.500000 
 C836.162231,627.964050 825.841675,626.301147 815.500000,627.250000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M204.500000,440.500000 
 C196.500000,440.333344 188.500336,440.145813 180.499725,440.016907 
 C178.827225,439.989929 177.103210,439.698730 175.500000,440.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M364.000000,310.000000 
 C354.500946,309.779785 344.985199,310.510468 335.500000,309.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M670.000000,302.000000 
 C674.035645,302.138641 676.845947,304.222595 679.014160,307.490601 
 C682.394714,312.585968 678.690125,320.623688 672.500000,321.500000 
 C665.974609,322.423767 660.538513,318.869385 659.500000,313.000000 
 C658.744202,308.728058 663.116699,303.665100 669.000000,302.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M738.500000,750.500000 
 C729.511780,751.854065 720.512939,752.063660 711.500000,750.750000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8.000000"
        d="
M480.500000,838.500000 
 C471.500000,838.500000 462.500000,838.500000 453.500000,838.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M174.500000,456.750000 
 C183.475067,458.262787 192.494553,457.377258 201.500000,457.250000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M420.500000,476.000000 
 C419.000000,476.000000 417.500000,476.000000 415.424561,476.000000 
 C415.722504,483.000000 415.035278,490.000000 416.000000,497.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.000000"
        d="
M837.500000,519.750000 
 C829.500000,520.918823 821.500000,520.918823 813.500000,519.750000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M604.750000,491.500000 
 C605.972168,498.653412 603.354858,505.851501 605.000000,513.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M867.500000,455.000000 
 C860.833313,454.916687 854.166687,454.833344 847.500000,454.750000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8.000000"
        d="
M168.999985,597.499939 
 C167.833313,597.999939 166.491928,598.280640 165.534607,599.043396 
 C163.186966,600.913940 160.984299,602.928467 162.477631,606.509338 
 C163.987671,610.130188 166.840302,612.263245 170.481277,611.839294 
 C173.948563,611.435486 176.222275,608.614868 176.439148,604.996338 
 C176.720444,600.303528 172.857712,599.043884 169.500000,597.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M581.500000,561.250000 
 C575.158020,560.677307 568.827881,561.001282 562.500000,561.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9.000000"
        d="
M265.500000,407.750000 
 C259.166656,408.000000 252.833328,408.250000 246.500000,408.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.000000"
        d="
M398.500000,660.500000 
 C403.770966,652.585144 411.202515,652.171448 415.073669,659.460876 
 C415.751190,660.736694 415.126709,661.500000 415.000000,662.500000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.000000"
        d="
M604.000000,660.000000 
 C610.186707,652.664856 616.083008,652.664856 621.000000,660.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.000000"
        d="
M775.000000,709.500000 
 C775.185059,710.831055 774.426208,712.280029 775.577515,713.182922 
 C776.822754,713.124878 778.161377,713.062439 779.500000,713.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.000000"
        d="
M511.000000,155.500000 
 C511.083344,159.000000 511.166656,162.500000 511.250000,166.000000 
"
      />
      <path
        fill="none"
        opacity="1.000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.000000"
        d="
M517.500000,162.500000 
 C515.500000,162.500000 513.500000,162.500000 511.500000,162.500000 
"
      />
    </svg>
  );
};
