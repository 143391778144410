export const LogoComponent: React.FC<{ color?: string }> = ({
  color = '#4438c9',
}) => {
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 96.42 155.48"
    >
      <defs>
        <style>.cls-2{`{filter:url(#drop-shadow-1);}`}</style>
        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
          <feOffset dx="0" dy="0" />
          <feGaussianBlur result="blur" stdDeviation="2" />
          <feFlood floodColor={color} floodOpacity=".6" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Layer_1-2">
        <g className="cls-2">
          <path
            fill={color}
            d="M12.85,117.87c9.3-11.23,21.79-17.41,35.18-17.41s25.88,6.18,35.18,17.41c.06,.07,.11,.13,.17,.2,.54,.66,1.53,.72,2.14,.11l10.49-10.49c.42-.42,.54-1.07,.29-1.62C96.29,106.08,64.78,0,48.21,0,31.64,0,.13,106.08,.13,106.08c-.25,.55-.13,1.19,.29,1.62l10.29,10.29c.6,.6,1.59,.55,2.13-.1h.01Z"
          />
          <path
            fill={color}
            d="M48.03,116.45c-.1,0-.2,0-.3,0-14.2,.16-21.16,17.4-11.12,27.44l11.6,11.6,11.33-11.33c10.14-10.14,3.11-27.54-11.22-27.7-.1,0-.2,0-.3,0Z"
          />
        </g>
      </g>
    </svg>
  );
};
