import { ForwardedRef, RefObject, useImperativeHandle } from 'react';

export const useForwardedRef = <T extends HTMLElement>(
  forwardRef: ForwardedRef<T>,
  targetRef: RefObject<T>
) => {
  useImperativeHandle(
    forwardRef,
    () => {
      return targetRef.current!;
    },
    [targetRef]
  );
};
