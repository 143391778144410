import { err, ok } from 'neverthrow';
import {
  ArityMismatch,
  InvalidFunctionCall,
  TypeCantBeDynamicallyAccessed,
} from '../type-check-error';
import type { FunctionTypingRule } from './function-call';
import { implementsTy, narrowestSuperTypeOf } from '../implements';
import { Ty } from '../../ty';

export const getFromRecordTypingRule: FunctionTypingRule = (name, args) => {
  const [recordish, field, ...rest] = args;
  if (!recordish || !field || rest.length > 0) {
    return err(
      new ArityMismatch({ name, expected: 2, attempted: args.length })
    );
  }

  const invalidCallErr = new InvalidFunctionCall({
    op: name,
    recieved: args,
    // TODO: something better for what can be done or why its invalid
    allowed: [],
  });

  if (!implementsTy({ subject: field, req: 'string' })) {
    return err(invalidCallErr);
  }

  if (recordish.ty.k === 'struct') {
    const outputType = narrowestSuperTypeOf(Object.values(recordish.ty.fields));
    if (outputType.isOk()) {
      return ok(Ty.makeNullable(outputType.value));
    } else {
      return err(new TypeCantBeDynamicallyAccessed({ ty: recordish }));
    }
  }

  if (recordish.ty.k === 'record') {
    return ok(Ty.makeNullable(recordish.ty.t));
  }

  return err(invalidCallErr);
};
