import * as Sentry from '@sentry/react';
import { isProduction } from '../utils';
import { createContext, useMemo } from 'react';
import { CaptureConsole } from '@sentry/integrations';
import { config } from '@cotera/client/config/config';
import { Primitive } from 'zod';
import { makeStoreContextHook } from '../store/store';
import { makeStoreProvider } from '../store/provider';
import { ChildrenProps } from '@cotera/client/app/components/utils';

type Metadata = Record<string, Primitive>;

export class Instrumentation {
  constructor(readonly metadata: Metadata = {}) {}

  count(event: string, tags: Metadata = {}, value: number = 1) {
    if (isProduction()) {
      Sentry.metrics.increment(event, value, {
        tags: {
          ...this.metadata,
          ...tags,
        },
      });
    }
  }

  static init() {
    if (isProduction() && config.sentryDsn) {
      Sentry.init({
        dsn: config.sentryDsn,
        integrations: [
          Sentry.metrics.metricsAggregatorIntegration(),
          new Sentry.BrowserProfilingIntegration(),
          new Sentry.BrowserTracing({
            tracePropagationTargets: ['https://app.cotera.co'],
          }),
          new CaptureConsole({ levels: ['error'] }) as any,
        ],
        release: import.meta.env.RELEASE_TAG,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        beforeSend: (event) => {
          if (window.location.hostname === 'localhost') {
            return null;
          }
          return event;
        },
      });
    }
  }
}

type State = { client: Instrumentation };

const InstrumentationContext = createContext<State>({
  client: new Instrumentation(),
});

const InternalInstrumentationProvider = makeStoreProvider<State>(
  InstrumentationContext
);

const useHook = makeStoreContextHook<State>(InstrumentationContext);

export const useInstrumentation = () => useHook((s) => s.client);

export const InstrumentationProvider: React.FC<
  ChildrenProps & { metadata?: Metadata }
> = ({ children, metadata }) => {
  const memoed = useMemo(() => new Instrumentation(metadata), [metadata]);
  return (
    <InternalInstrumentationProvider
      state={{ client: memoed }}
      actions={() => ({})}
    >
      {children}
    </InternalInstrumentationProvider>
  );
};
