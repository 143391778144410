/* eslint-disable @typescript-eslint/unbound-method */
import _ from 'lodash';
import { Axis, ChartFormat, HeatmapColors } from '../../ast/chart';
import { TC, TyStackTrace } from '../../type-checker';
import { Expression } from '../expression';
import { Grouping } from '../fluent-grouping';
import { Relation } from '../relation';
import type { MarkupShorthand } from '../markup/section';
import { mkMuMacro } from '../macro/mu-macro';
import { RelationRef, GroupedRelationRef } from '../relation-ref';
import { Constant } from '../utilities';
import { AST } from '../../ast';
import { Markup } from './section';
import { Ty } from '../../ty';

export type ChartSize = 'lg' | 'md' | 'sm';

export type LineChartProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly axis?: Partial<{
    readonly x: Partial<Axis>;
    readonly y: Partial<Axis>;
  }>;
  readonly format?: ChartFormat;
  readonly type?: 'stacked' | 'normal' | 'area';
};

export type BarChartProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly axis?: Partial<{
    readonly x: Partial<Axis>;
    readonly y: Partial<Axis>;
  }>;
  readonly trendline?: boolean;
  readonly type?: 'stacked' | 'grouped';
  readonly format?: ChartFormat;
  readonly direction?: 'vertical' | 'horizontal';
};

export type PieChartProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly labels?: boolean;
  readonly format?: ChartFormat;
};

export type SankeyChartProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly format?: ChartFormat;
};

export type RadarChartProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly format?: ChartFormat;
};

export type HeatMapChartProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly axis?: Partial<{
    readonly x: Partial<Axis>;
    readonly y: Partial<Axis>;
  }>;
  readonly format?: ChartFormat;
  readonly colors?: HeatmapColors[];
};

export type ScatterPlotProps = {
  readonly title?: string | Expression;
  readonly size?: ChartSize;
  readonly format?: ChartFormat;
  readonly numTicks?: number;
  readonly dynamicSizePoints?: boolean;
  readonly axis?: Partial<{
    readonly x: Partial<Axis>;
    readonly y: Partial<Axis>;
  }>;
};

export class Stat {
  static create(
    rel: Relation,
    config: AST._Stat['config'],
    opts?: { jsStackPointer?: Function }
  ): Stat {
    const stat = { rel: rel.ast, config };
    const checked = TC.MU.checkStat(stat);

    if (checked instanceof TyStackTrace) {
      throw checked.toError({
        jsStackPointer: opts?.jsStackPointer ?? this.create,
      });
    }

    return new this(stat);
  }

  private constructor(readonly stat: AST._Stat) {}

  where(condition: (t: RelationRef) => boolean | Expression): Stat {
    const { ast: rel } = Relation.fromAst(this.stat.rel).where(condition, {
      jsStackPointer: this.where,
    });

    return new Stat({ rel, config: this.stat.config });
  }
}

export class Chart {
  protected constructor(private readonly rel: Relation | Grouping) {}

  static fromRel(rel: Relation | Grouping): Chart {
    return new this(rel);
  }

  DataGrid(opts?: {
    title?: string;
    defaultRowCount?: 10 | 20 | 30 | 40 | 50;
  }): Markup {
    const rel =
      this.rel instanceof Relation
        ? this.rel
        : this.rel.select((t) => ({ ...t.group(), ...t.count() }));

    const dg: AST._Chart = {
      t: 'chart',
      rel: rel.ast,
      size: 'lg',
      format: {},
      title: opts?.title ? Constant(opts.title).ast : null,
      config: {
        t: 'data-grid',
        defaultRowCount: opts?.defaultRowCount ?? 10,
      },
    };

    return Markup.fromAst(dg, { jsStackPointer: this.DataGrid });
  }

  Stat(
    data: (t: RelationRef | GroupedRelationRef) => {
      value: number | Expression;
      title?: string | Expression;
      from?: number | Expression;
      unit?: string | Expression;
      style?: string | Expression;
      caption?: string | Expression;
    },
    opts?: {
      unit?: string | Expression;
      style?: string | Expression;
      title?: string | Expression;
      info?: string | Expression;
      caption?: string | Expression;
    }
  ): Stat {
    return Stat.create(
      this.rel.select(data),
      {
        unit: null,
        style: null,
        title: null,
        info: null,
        caption: null,
        ..._.mapValues(opts, (x) =>
          x === undefined ? null : Expression.wrap(x).ast
        ),
      },
      {
        jsStackPointer: this.Stat,
      }
    );
  }

  LineChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      x: string | Date | Expression;
      y: number | Expression;
      category?: string | Expression;
      style?: string | Expression;
    },
    opts?: LineChartProps
  ): Markup {
    const { title, size, format, axis, type } = opts ?? {};

    const bc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => ({
        category: Constant(null, { ty: 'string' }),
        style: Constant(null, { ty: 'string' }),
        ...data(t),
      })).ast,
      title: Expression.wrap(title ?? Constant(null, { ty: 'string' })).ast,
      size: size ?? 'md',
      format: format ?? {},
      config: {
        t: 'line-chart',
        axis: {
          x: {
            label: axis?.x?.label ?? null,
            scale: axis?.x?.scale ?? null,
          },
          y: {
            label: axis?.y?.label ?? null,
            scale: axis?.y?.scale ?? null,
          },
        },
        type: type ?? 'normal',
      },
    };

    return Markup.fromAst(bc, { jsStackPointer: this.LineChart });
  }

  BarChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      x: string | number | Date | Expression;
      y: number | Expression;
      category: string | Expression;
    },
    props: BarChartProps = {}
  ): Markup {
    const bc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select(data).ast,
      title: Expression.wrap(props.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: props.size ?? 'md',
      format: props.format ?? {},
      config: {
        t: 'bar-chart',
        axis: {
          x: {
            label: props.axis?.x?.label ?? null,
            scale: props.axis?.x?.scale ?? null,
          },
          y: {
            label: props.axis?.y?.label ?? null,
            scale: props.axis?.y?.scale ?? null,
          },
        },
        trendline: props.trendline ?? false,
        type: props.type ?? 'stacked',
        direction: props.direction ?? 'vertical',
      },
    };

    return Markup.fromAst(bc, { jsStackPointer: this.BarChart });
  }

  SemanticSearchResults(
    data: (t: RelationRef | GroupedRelationRef) => {
      value: string | Expression;
      timestamp: Date | Expression;
      id: string | Expression;
      similarity?: number | Expression;
    },
    props: BarChartProps = {}
  ): Markup {
    const bc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select(data).ast,
      title: Expression.wrap(props.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: props.size ?? 'md',
      format: props.format ?? {},
      config: {
        t: 'semantic-search-results',
      },
    };

    return Markup.fromAst(bc, { jsStackPointer: this.BarChart });
  }

  Insights(
    data: (t: RelationRef | GroupedRelationRef) => {
      title: string | Expression;
      style?: string | Expression;
    },
    config?: {
      title?: string;
      wrap?: boolean;
    }
  ): Markup {
    const ins: AST._Insights = {
      t: 'insights',
      rel: this.rel.select(data).ast,
      config: {
        title: config?.title ?? null,
        wrap: config?.wrap ?? false,
      },
    };

    return Markup.fromAst(ins, { jsStackPointer: this.Insights });
  }

  PieChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      value: number | Expression;
      category: string | Expression;
      style?: string | Expression;
    },
    opts: PieChartProps = {}
  ): Markup {
    const pc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => ({
        style: Constant(null, { ty: 'string' }),
        ...data(t),
      })).ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: opts.size ?? 'md',
      format: opts.format ?? {},
      config: {
        t: 'pie-chart',
        labels: opts.labels ?? true,
      },
    };

    return Markup.fromAst(pc, { jsStackPointer: this.PieChart });
  }

  SummaryChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      value: number | Expression;
      category: string | Expression;
      style?: string | Expression;
    },
    opts: PieChartProps = {}
  ): Markup {
    const pc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => ({
        style: Constant(null, { ty: 'string' }),
        ...data(t),
      })).ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: opts.size ?? 'md',
      format: opts.format ?? {},
      config: {
        t: 'summary-chart',
      },
    };

    return Markup.fromAst(pc, {
      jsStackPointer: this.SummaryChart,
    });
  }

  LLMSummary(opts: PieChartProps & { prompt: string; model?: string }): Markup {
    if (this.rel instanceof Grouping) {
      throw new Error('Summarizing doesnt work on groupings');
    }
    const pc: AST._Chart = {
      t: 'chart',
      rel: this.rel.ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: opts.size ?? 'md',
      format: opts.format ?? {},
      config: {
        t: 'llm-summary',
        prompt: opts.prompt,
        model: opts.model ?? null,
      },
    };

    return Markup.fromAst(pc, {
      jsStackPointer: this.SummaryChart,
    });
  }

  SankeyChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      to: string | Expression;
      from: string | Expression;
      value: number | Expression;
    },
    opts: SankeyChartProps = {}
  ): Markup {
    const sc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => data(t)).ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: opts.size ?? 'md',
      format: opts.format ?? {},
      config: {
        t: 'sankey-chart',
      },
    };
    return Markup.fromAst(sc, { jsStackPointer: this.SankeyChart });
  }

  RadarChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      value: number | Expression;
      category: string | Expression;
      group: string | Expression;
    },
    opts: RadarChartProps = {}
  ): Markup {
    const pc: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => data(t)).ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: opts.size ?? 'md',
      format: opts.format ?? {},
      config: {
        t: 'radar-chart',
      },
    };

    return Markup.fromAst(pc, { jsStackPointer: this.RadarChart });
  }

  HeatMapChart(
    data: (t: RelationRef | GroupedRelationRef) => {
      x: string | Expression;
      value: number | Expression;
      y: string | Expression;
    },
    opts: HeatMapChartProps = {}
  ): Markup {
    const hm: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => data(t)).ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      size: opts.size ?? 'md',
      format: opts.format ?? {},
      config: {
        t: 'heatmap-chart',
        axis: {
          x: {
            label: opts.axis?.x?.label ?? null,
            scale: opts.axis?.x?.scale ?? null,
          },
          y: {
            label: opts.axis?.y?.label ?? null,
            scale: opts.axis?.y?.scale ?? null,
          },
        },
        colors: opts.colors ?? [],
      },
    };

    return Markup.fromAst(hm, {
      jsStackPointer: this.HeatMapChart,
    });
  }

  ScatterPlot(
    data: (t: RelationRef | GroupedRelationRef) => {
      x: number | string | Date | Expression;
      y: number | string | Expression;
      label?: string | Expression;
      category?: string | Expression;
    },
    opts: ScatterPlotProps = {}
  ): Markup {
    const sp: AST._Chart = {
      t: 'chart',
      rel: this.rel.select((t) => ({
        category: Constant(null, { ty: 'string' }),
        label: Constant(null, { ty: 'string' }),
        ...data(t),
      })).ast,
      title: Expression.wrap(opts.title ?? Constant(null, { ty: 'string' }))
        .ast,
      config: {
        t: 'scatter-plot',
        axis: {
          x: {
            label: opts.axis?.x?.label ?? null,
            scale: opts.axis?.x?.scale ?? null,
          },
          y: {
            label: opts.axis?.y?.label ?? null,
            scale: opts.axis?.y?.scale ?? null,
          },
        },
        numTicks: opts.numTicks ?? 10,
        dynamicSizePoints: opts.dynamicSizePoints ?? false,
      },
      size: opts.size ?? 'md',
      format: opts.format ?? {},
    };

    return Markup.fromAst(sp, { jsStackPointer: this.ScatterPlot });
  }

  RelationInfo(): Markup {
    const rel =
      this.rel instanceof Relation
        ? this.rel.ast
        : this.rel.select((t) => t.group()).ast;

    const ri: AST._Chart = {
      config: { t: 'relation-info' },
      rel,
      title: null,
      size: 'lg',
      format: {},
      t: 'chart',
    };

    return Markup.fromAst(ri, {
      jsStackPointer: this.RelationInfo,
    });
  }

  ForEachRow(each: (row: Expression) => MarkupShorthand): Markup {
    const rel =
      this.rel instanceof Relation
        ? this.rel
        : this.rel.select((t) => t.group());

    const m = mkMuMacro(
      { data: Constant(null, Ty.s(rel.attributes)) },
      (args) => each(args.data),
      { displayName: 'ForEach' }
    );

    const data: AST._ForEach = {
      t: 'for-each',
      rel: rel.ast,
      body: { scope: m.scope, macro: m.body.ast },
    };

    return Markup.fromAst(data, {
      jsStackPointer: this.ForEachRow,
    });
  }

  IndexList() {
    const ast = this.rel.select((t) => ({
      value: t.attr('value'),
      link: t.attr('link'),
    })).ast;
    const ri: AST._Chart = {
      config: { t: 'index-list' },
      rel: ast,
      title: null,
      size: 'lg',
      t: 'chart',
      format: {},
    };

    return Markup.fromAst(ri, {
      jsStackPointer: this.IndexList,
    });
  }
}
