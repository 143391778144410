import { Relation } from '@cotera/era';
import { EventStream } from './event-stream';
import type { Schedule } from '@cotera/api';
import { LEGACY_GLOBAL_ID } from '../cookbook/legacy-global-id';

/**
 * @param props relation with attributes { key: string, type: 'info' | 'warning' | 'error', title: string, description: string, extras: Record<string, string> }
 * the key property is used to dedupe alerts - an alert with the same key will not be sent more than once
 */
export const Alert = (props: {
  id: string;
  rel: Relation;
  when?: Schedule;
  config: {
    channel: string;
  };
}) => {
  return EventStream({
    name: `${props.id}-alert`,
    entityId: LEGACY_GLOBAL_ID,
    rel: props.rel,
    identifier: (t) => t.attr('key').cast('string'),
    onChange: (t) => t.attr('key').cast('string'),
    when: props.when,
    sinks: {
      slack: {
        condition: () => true,
        config: {
          t: 'coteraSlack',
          config: {
            channel: props.config.channel,
          },
        },
        select: (t) => ({
          title: t.attr('title').cast('string'),
          description: t.attr('description').cast('string'),
          subtitle: t.attr('subtitle').cast('string'),
          type: t.attr('type').cast('string'),
        }),
      },
    },
  });
};
