import { AST } from '../ast';
import { sha1 } from 'object-hash';
import { toSql } from './sql';
import { DuckDbWasm } from './dialects/dialects';

const HASH_CACHE: WeakMap<AST.RelIR, string> = new WeakMap();

export const hashIR = (ir: AST.RelIR): string => {
  const existing = HASH_CACHE.get(ir);

  if (existing !== undefined) {
    return existing;
  }

  const res = sha1(toSql(ir, { dialect: DuckDbWasm, sqlGen: {} }));
  HASH_CACHE.set(ir, res);
  return res;
};
