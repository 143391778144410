import { z } from 'zod';

const ConfigSchema = z.object({
  apiUrl: z.string().default('https://app.cotera.co'),
  posthogKey: z.string().optional(),
  demo: z.boolean().default(false),
  demoSiteUrl: z.string().default('https://demo.cotera.co'),
  sentryDsn: z.string().optional(),
  gtmId: z.string().optional(),
});

export const config = ConfigSchema.parse((window as any).COTERA_CONFIG ?? {});
