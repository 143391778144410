import { ScheduledJob, CronExpression } from '@cotera/sdk/core';
import { RelationRef } from '@cotera/era';

export const CUSTOMER_ID = '__COTERA_CUSTOMER_ID';

export const mapCustomerId = (t: RelationRef, customerId: string) => ({
  [CUSTOMER_ID]: t.attr(customerId),
});

export const WriteToLog = ({
  msg,
  id,
  schedule,
}: {
  msg: string;
  id: string;
  schedule: string;
}): ScheduledJob => ({
  id,
  schedule,
  parameters: { t: 'write-to-log', params: { msg } },
});

export const UpdateWarehouse = ({
  orgId,
  models,
  schedule,
  id,
}: {
  id: string;
  orgId: string;
  models?: string[];
  schedule: string;
}): ScheduledJob => ({
  id,
  schedule,
  parameters: { t: 'update-warehouse', params: { orgId, models } },
});

export const RecommendationModel = ({
  id,
  orgId,
  schedule,
  minInteractions = 5,
  shouldRunInputDbt = true,
  shouldRunOutputDbt = true,
  nCollabFilteringRecs = 15,
  nProductSimilarityRecs = 15,
}: {
  id: string;
  orgId: string;
  schedule: string;
  minInteractions?: number;
  nCollabFilteringRecs?: number;
  shouldRunInputDbt?: boolean;
  shouldRunOutputDbt?: boolean;
  nProductSimilarityRecs?: number;
}): ScheduledJob => ({
  id,
  schedule,
  parameters: {
    t: 'recommendation-model',
    params: {
      orgId,
      minInteractions,
      shouldRunInputDbt,
      shouldRunOutputDbt,
      nCollabFilteringRecs,
      nProductSimilarityRecs,
    },
  },
});

export const FpGrowthModel = ({
  id,
  orgId,
  schedule = CronExpression.EVERY_DAY_AT_MIDNIGHT,
  outputTableName = 'FP_GROWTH_RECOMMENDATIONS',
  transactionsDefinition = 'FpGrowthBase',
  userItemFrequenciesDefinition = 'CustomerOrderItems',
  constrainTransactionSizeTo = 4,
  useNMostCommonItems = 512,
  useNTransactions = 1000000,
  nRecommendations = 10,
}: {
  id: string;
  orgId: string;
  schedule?: string;
  outputTableName?: string;
  transactionsDefinition?: string;
  userItemFrequenciesDefinition?: string;
  constrainTransactionSizeTo?: number;
  useNMostCommonItems?: number;
  useNTransactions?: number;
  nRecommendations?: number;
}): ScheduledJob => ({
  id,
  schedule,
  parameters: {
    t: 'fp-growth-model',
    params: {
      orgId,
      outputTableName,
      transactionsDefinition,
      userItemFrequenciesDefinition,
      constrainTransactionSizeTo,
      useNMostCommonItems,
      useNTransactions,
      nRecommendations,
    },
  },
});
