import { Entity } from '@cotera/api';
import { Watchable, WatchableViewModel } from '@cotera/client/app/etc';
import { EraQL, Relation } from '@cotera/era';
import { buildEraQLFromExpressionString, extractExpressions } from './utils';
import { parseExprResult } from '../utils';
import { mapValues } from 'lodash';

export type ResponseShape =
  | {
      t: 'object';
      items: {
        key: string;
        type: ResponseShape;
      }[];
    }
  | {
      t: 'array';
      items: ResponseShape;
    }
  | {
      t: 'string';
    }
  | {
      t: 'float';
    }
  | {
      t: 'boolean';
    }
  | {
      t: 'int';
    }
  | {
      t: 'enum';
      values: string[];
    };

export class OutputShape {
  constructor(private _shape: ResponseShape) {}

  isEqual(other: OutputShape) {
    return JSON.stringify(this._shape) === JSON.stringify(other._shape);
  }

  get value() {
    return this._shape;
  }
}

export class LLMModelConfigViewModel extends Watchable {
  constructor(
    readonly entity: Entity,
    readonly relVm: WatchableViewModel<{
      rel: Relation;
    }>,
    private _outputShape: OutputShape = new OutputShape({
      t: 'string',
    }),
    private _model: string = 'gpt-4o-mini',
    private _systemMessage: string = '',
    private _prompt = '',
    private _maxTokens = 4096,
    private _columnName: string = ''
  ) {
    super();
  }

  get outputShape() {
    return this._outputShape;
  }

  get model() {
    return this._model;
  }

  get systemMessage() {
    return this._systemMessage;
  }

  get prompt() {
    return this._prompt;
  }

  get maxTokens() {
    return this._maxTokens;
  }

  get rel() {
    return this.relVm.rel;
  }

  get promptEraQl() {
    const expressions = extractExpressions(this.prompt);
    const eraQl = buildEraQLFromExpressionString(expressions).trimEnd();

    const parseRes = parseExprResult(
      EraQL.parseExpr(eraQl, {
        attributes: mapValues(this.entity.columns, (x) => x.type),
      })
    );

    return parseRes;
  }

  get columnName() {
    return this._columnName;
  }

  setColumnName(columnName: string) {
    this._columnName = columnName;
    this.notifySubscribers();
  }

  setMaxTokens(maxTokens: number) {
    this._maxTokens = maxTokens;
    this.notifySubscribers();
  }

  setPrompt(prompt: string) {
    this._prompt = prompt;
    this.notifySubscribers();
  }

  setModel(model: string) {
    this._model = model;
    this.notifySubscribers();
  }

  setSystemMessage(message: string) {
    this._systemMessage = message;
    this.notifySubscribers();
  }

  setOutputShape(shape: ResponseShape) {
    this._outputShape = new OutputShape(shape);
    this.notifySubscribers();
  }
}
