import {
  ChildrenProps,
  classNames,
  Formatters,
} from '@cotera/client/app/components/utils';
import { Chip, Text } from '@cotera/client/app/components/ui';
import { H2 } from '@cotera/client/app/components/ui/typography';
import { EnhancedPercentage, Style, TEXT_STYLE } from '../../app';

type Statistic = {
  value: number | null;
  title: string | null;
  from: number | null;
  style: 'positive' | 'negative' | 'warning' | 'neutral' | null;
  unit: string | null;
};

export const BigNumber: React.FC<Statistic> = ({
  title,
  value,
  from,
  unit,
  style = 'neutral',
}) => {
  return (
    <Container>
      <Text.P className="text-xl mb-1">{title}</Text.P>
      <div className="flex flex-row w-full items-end justify-between flex-wrap  mt-2">
        <div className="flex flex-row items-end flex-wrap mr-2">
          <Value value={value} unit={unit} from={from} style={style} />
        </div>
        {from !== null &&
          from !== undefined &&
          value !== null &&
          value !== undefined && (
            <Change value={Number(value)} from={Number(from)} style={style} />
          )}
      </div>
    </Container>
  );
};

const Container: React.FC<ChildrenProps> = ({ children }) => (
  <div
    className={classNames(
      `
        border-r
        min-w-[150px]
        grow-1
        flex-1
        mt-2
        [&:not(:last-child)]:mr-4
        last:border-r-0
        px-4
      `
    )}
  >
    {children}
  </div>
);

const Change = ({
  value,
  from,
  style,
}: {
  value: number;
  from: number;
  style?: string | null;
}) => {
  const change = ((value - from) / from) * 100;

  let chipType: Style;

  if (
    style === 'positive' ||
    style === 'negative' ||
    style === 'warning' ||
    style === 'neutral'
  ) {
    chipType = style;
  } else {
    const isBad = change < 0;
    chipType = isBad ? 'negative' : 'positive';
  }

  return (
    <Chip className="mt-2" type={chipType}>
      <EnhancedPercentage
        value={change}
        className="mx-1 font-semibold"
        style={chipType}
      />
    </Chip>
  );
};

const Value: React.FC<Omit<Statistic, 'title'>> = ({
  unit,
  value,
  style: color,
  from,
}) => {
  return (
    <>
      <H2 className={'mr-2'} textColor={TEXT_STYLE[color ?? 'neutral']}>
        {Formatters.number(value, unit ?? 'number')}
      </H2>

      {from !== null && (
        <Text.Caption className="text-sm mb-1">
          from {Formatters.number(from, unit)}
        </Text.Caption>
      )}
    </>
  );
};
