import { classNames } from '@cotera/client/app/components/utils';
import { Tooltip } from '@cotera/client/app/components/ui';
const CopilotIconRaw: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1024 1024"
      enableBackground="new 0 0 1024 1024"
      {...props}
    >
      <path
        opacity="1.000000"
        stroke="none"
        d="
M480.411743,192.327942 
	C509.545593,171.352356 545.360596,183.421509 559.562439,209.374023 
	C574.177795,236.082230 563.805847,269.820038 536.215942,282.833374 
	C530.878906,285.350677 528.790527,288.151855 529.201416,294.006165 
	C529.736877,301.635254 529.431763,309.331604 529.298035,316.995667 
	C529.238525,320.402740 530.488770,321.844452 533.978943,321.707611 
	C538.638245,321.524933 543.311035,321.613251 547.976562,321.681061 
	C555.135010,321.785126 559.030334,325.617218 559.221008,332.830261 
	C559.344238,337.493683 559.293762,342.163025 559.255554,346.829163 
	C559.190247,354.799377 555.602844,358.327057 547.576111,358.330261 
	C505.077240,358.347260 462.569305,358.897980 420.082336,358.191956 
	C386.426971,357.632721 361.127258,372.455139 341.327850,398.085815 
	C324.771362,419.518402 316.756073,444.179291 320.627289,471.147430 
	C325.449097,504.737946 343.130035,530.514954 372.770325,547.560791 
	C385.441437,554.847839 399.145538,557.873718 413.889252,557.815491 
	C479.219910,557.557312 544.559509,557.115906 609.880737,557.942017 
	C643.340759,558.365173 667.659424,543.315552 686.499268,517.686462 
	C699.763306,499.642517 706.689575,478.871063 705.470642,456.324921 
	C703.562927,421.038818 686.912048,393.730530 658.106934,373.634766 
	C648.458679,366.903748 638.027100,361.891998 626.492737,359.188721 
	C618.686218,357.359100 616.680908,354.528625 616.670166,346.556000 
	C616.663818,341.889465 616.654480,337.222809 616.677307,332.556396 
	C616.715454,324.760284 620.503784,321.384857 628.357605,322.251526 
	C639.963745,323.532318 650.627991,327.846588 660.920837,333.002075 
	C687.953125,346.542023 708.774719,366.575989 722.703613,393.497742 
	C724.355774,396.690948 726.133179,397.297791 729.350708,396.673248 
	C766.826233,389.399048 798.400513,419.792694 802.564453,451.801941 
	C807.198608,487.425903 784.663696,520.097412 749.699768,526.687866 
	C742.447998,528.054749 735.080811,527.846680 727.902527,525.918884 
	C725.341858,525.231262 723.439148,525.182556 721.984558,527.783020 
	C710.057800,549.103699 694.201233,566.767578 672.957275,579.574646 
	C675.263611,582.125793 678.168274,581.590698 680.596802,582.238586 
	C704.701111,588.669434 720.350769,610.861145 720.567139,630.992676 
	C720.742371,647.303040 722.354492,663.693787 719.218262,679.809875 
	C714.089233,706.166748 689.026733,722.585754 662.747559,717.139832 
	C653.388062,715.200256 646.106934,709.765991 640.950439,701.433044 
	C640.950439,719.784485 641.073120,737.857056 640.923767,755.927368 
	C640.651367,788.893677 619.065063,820.959839 589.631897,832.400085 
	C562.863831,842.804443 532.733948,834.168884 518.138611,811.823242 
	C516.153564,808.784119 514.578674,805.477173 512.851135,802.361328 
	C510.989594,802.391846 511.126740,803.936218 510.675476,804.837769 
	C499.859406,826.445862 482.045258,836.695007 458.245667,836.406128 
	C425.352997,836.006897 405.285400,817.118103 392.024017,789.099976 
	C386.605560,777.652100 384.368835,765.506042 384.594666,752.772156 
	C384.895844,735.787598 384.669098,718.793640 384.669098,701.938782 
	C383.479736,701.258301 383.288727,702.158569 382.953583,702.624817 
	C369.134186,721.851013 344.020599,722.044922 326.818207,709.980957 
	C312.263214,699.773499 305.122467,685.541748 304.860291,667.866821 
	C304.694672,656.702759 305.111755,645.523376 304.712677,634.370728 
	C303.773926,608.135559 324.434113,586.298645 348.531158,581.714294 
	C349.824127,581.468323 351.234253,581.648865 352.123535,580.292542 
	C352.414154,578.528076 350.845093,578.140930 349.810486,577.493347 
	C331.014465,565.729431 316.831421,549.573242 305.652771,530.679871 
	C303.178772,526.498413 301.209290,525.042542 295.730133,526.039185 
	C264.107330,531.791260 235.639389,513.560791 225.613800,481.906342 
	C214.563858,447.017548 233.280792,409.338074 267.068024,398.351990 
	C276.125519,395.406891 285.347076,394.598145 294.630310,396.562347 
	C299.055542,397.498657 301.367401,396.507385 303.615601,392.358978 
	C317.770416,366.240143 338.284668,346.562683 364.972168,333.462463 
	C380.564087,325.808807 396.958588,321.493988 414.500427,321.634552 
	C439.498138,321.834839 464.499817,321.553894 489.497162,321.776978 
	C494.621246,321.822693 496.232758,320.089691 496.040771,315.139801 
	C495.718018,306.818176 495.817383,298.475861 495.920105,290.144470 
	C495.955536,287.271393 495.028992,285.682343 492.248352,284.514526 
	C458.646606,270.402710 443.126373,222.490784 480.411743,192.327942 
M545.500000,593.148193 
	C520.834595,593.150879 496.168427,593.278992 471.504028,593.121460 
	C446.189362,592.959778 420.862885,593.760132 395.562500,592.478577 
	C388.103180,592.100769 387.997467,591.973389 387.953247,599.435547 
	C387.813019,623.094666 388.301910,646.754517 387.544434,670.415466 
	C387.324860,677.274353 387.231110,684.231323 388.231537,690.908325 
	C390.157684,691.635437 391.169342,690.572876 392.289978,689.940430 
	C405.155884,682.679993 419.107544,690.673645 419.274750,705.633606 
	C419.474091,723.464294 419.244080,741.299377 419.376801,759.131226 
	C419.506042,776.496094 431.551056,794.394775 446.931061,800.455139 
	C464.132965,807.233459 478.396698,799.015930 480.864075,780.905884 
	C482.338531,770.083740 487.125183,765.130615 498.004211,764.392578 
	C508.800781,763.660095 519.660889,763.361145 530.454590,764.525330 
	C538.801331,765.425598 542.969238,770.387268 543.444031,778.801025 
	C543.772034,784.612793 544.232971,790.362122 548.667725,795.018188 
	C556.765625,803.520142 570.483887,805.155273 581.765076,798.256104 
	C597.197388,788.818420 604.963806,774.571411 605.564758,756.772400 
	C606.126892,740.124573 605.535217,723.441162 605.802002,706.778992 
	C606.062866,690.485962 618.220276,682.982239 632.996643,689.683350 
	C633.897278,690.091797 634.751282,690.708374 636.172180,689.955139 
	C637.169434,689.054260 636.914978,687.496521 636.974487,686.038147 
	C638.156250,657.056030 637.366272,628.058411 637.706055,599.070312 
	C637.765381,594.009888 635.468750,592.986328 630.992554,593.011230 
	C602.828979,593.168213 574.664307,593.125305 545.500000,593.148193 
M261.255127,444.813843 
	C260.403778,446.431702 259.454834,448.006317 258.716187,449.674103 
	C251.331192,466.348785 261.330231,486.765564 279.023743,491.195526 
	C285.953278,492.930481 288.364532,490.596619 287.219910,483.789490 
	C285.565002,473.947235 284.553711,464.040619 285.464935,454.022095 
	C285.991760,448.229706 286.442474,442.424896 286.701477,436.615570 
	C286.895355,432.268036 285.074890,430.774841 280.877960,431.734558 
	C272.976196,433.541351 266.346497,437.404785 261.255127,444.813843 
M506.902740,215.243240 
	C497.990021,218.428772 492.317474,224.465851 491.426514,234.057480 
	C490.601868,242.935013 496.301819,251.324478 504.836548,254.762543 
	C512.947266,258.029816 522.808899,255.314240 529.148132,248.067932 
	C534.554138,241.888382 535.410645,232.105438 531.186157,224.790909 
	C526.496216,216.670486 517.468628,212.906021 506.902740,215.243240 
M762.923889,479.404755 
	C774.654724,465.051208 769.601196,440.889404 749.344055,433.468109 
	C741.639038,430.645294 738.570251,432.853424 739.403687,441.123138 
	C740.878357,455.757019 740.799561,470.293854 738.397156,484.833710 
	C737.476807,490.403992 740.213562,492.567444 745.677246,491.059540 
	C752.474060,489.183655 758.029724,485.400604 762.923889,479.404755 
M353.654053,664.497681 
	C353.653015,650.690674 353.686523,636.883606 353.632721,623.076782 
	C353.613098,618.042358 351.588867,616.894653 347.123535,619.346497 
	C341.978455,622.171448 339.528778,626.796936 339.453247,632.477722 
	C339.289612,644.785278 339.206665,657.100464 339.473755,669.404236 
	C339.596924,675.079468 342.444458,679.627197 347.750671,682.104004 
	C351.480957,683.845276 353.502502,682.524109 353.615112,678.470825 
	C353.735199,674.148987 353.650299,669.821411 353.654053,664.497681 
M672.036682,665.496826 
	C672.008728,669.823608 671.820129,674.157104 671.999268,678.475281 
	C672.167725,682.537109 673.878357,683.449219 677.550537,681.642456 
	C682.666382,679.125366 685.397705,674.626648 685.548035,669.248352 
	C685.906128,656.442322 685.862366,643.616394 685.612793,630.805664 
	C685.477722,623.870483 679.544983,618.483032 672.031494,618.306458 
	C672.031494,633.706177 672.031494,649.102051 672.036682,665.496826 
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M439.131836,420.656097 
	C453.433228,429.386810 460.009399,442.189056 458.751678,458.185852 
	C457.326202,476.316498 446.758972,488.049652 429.295746,492.368713 
	C402.152283,499.081848 379.135406,475.198883 382.447540,451.503052 
	C385.241852,431.511627 397.204376,418.727661 417.102173,415.978058 
	C424.731384,414.923828 431.918762,417.265228 439.131836,420.656097 
M425.143829,465.855042 
	C430.251526,463.636627 432.925049,459.783661 432.757904,454.175049 
	C432.601318,448.921753 429.503876,445.674042 425.056671,443.454742 
	C420.581329,441.221405 416.620941,442.615112 412.997223,445.388245 
	C408.692322,448.682678 407.430725,454.251251 409.673859,459.192902 
	C412.369385,465.131195 417.112152,467.366638 425.143829,465.855042 
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M638.493652,473.293640 
	C628.682373,489.504730 611.489075,496.749664 594.343445,492.164307 
	C578.630737,487.962128 565.833557,472.561310 565.929810,456.211975 
	C566.039917,437.502258 577.725464,419.114929 599.653625,415.890594 
	C617.199768,413.310608 636.638550,426.551544 641.186462,444.741211 
	C643.577698,454.304871 643.228699,463.885742 638.493652,473.293640 
M594.751160,447.356049 
	C591.652344,451.904144 591.118408,456.661163 594.342041,461.256165 
	C597.083984,465.164520 601.080383,467.397247 605.929932,466.455658 
	C610.884460,465.493683 614.770752,462.891846 616.164734,457.508118 
	C617.387695,452.784851 614.880249,447.184601 609.890015,444.095032 
	C604.850464,440.974945 600.562317,441.760773 594.751160,447.356049 
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M693.318359,281.796844 
	C692.387146,294.144897 684.441467,300.131165 674.752563,296.296600 
	C669.854309,294.358002 667.737915,290.452118 666.884888,285.588745 
	C666.770447,284.936157 666.812561,284.256927 666.709961,283.601318 
	C666.037903,279.308563 668.929138,273.418549 665.016174,271.053284 
	C661.683350,269.038635 656.237793,269.926178 651.823425,270.410126 
	C640.693726,271.630249 634.596680,258.456635 638.751770,250.785278 
	C641.387817,245.918304 645.535583,244.140030 650.609680,243.873169 
	C654.430054,243.672241 658.272522,243.656403 662.095337,243.807632 
	C665.600525,243.946320 666.863953,242.411102 666.703003,239.028946 
	C666.536926,235.540451 666.568970,232.032837 666.709167,228.541412 
	C667.031494,220.511154 671.877197,215.307434 679.189148,214.975098 
	C686.692688,214.634033 692.379639,219.668686 693.132080,227.674240 
	C693.489258,231.473709 693.454163,235.335770 693.266663,239.153244 
	C693.092468,242.700760 694.578735,243.915497 697.975952,243.728775 
	C701.295654,243.546295 704.638306,243.600479 707.963501,243.735001 
	C716.066345,244.062790 721.467773,249.908142 721.334106,258.071991 
	C721.205994,265.896545 716.252930,270.309753 707.588806,270.317383 
	C706.589722,270.318237 705.590515,270.298981 704.591553,270.309326 
	C691.664368,270.443085 693.501770,268.741638 693.318359,281.796844 
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M379.365906,245.164413 
	C386.073029,249.876419 388.250580,255.310379 386.226501,261.574310 
	C384.293213,267.557281 379.884796,270.194641 372.164948,270.341370 
	C367.874390,270.422913 362.599365,268.507477 359.547150,271.193970 
	C356.355804,274.002930 358.579895,279.390625 358.356201,283.634705 
	C357.949249,291.355988 353.078735,296.846588 345.854736,297.296112 
	C338.324432,297.764679 332.763885,292.849243 331.759949,284.796814 
	C331.636841,283.809540 331.682343,282.799896 331.669891,281.800354 
	C331.526611,270.305023 331.527649,270.304993 319.835907,270.297302 
	C309.216705,270.290314 304.086334,266.246704 303.679474,257.563202 
	C303.317719,249.841721 308.388947,244.169434 316.258850,243.734497 
	C320.081024,243.523239 323.923737,243.613205 327.755585,243.670059 
	C330.485565,243.710556 331.712189,242.513199 331.659973,239.762344 
	C331.587219,235.930893 331.556274,232.092926 331.692993,228.264389 
	C331.977997,220.281906 337.389923,214.984467 345.027527,215.063538 
	C352.546783,215.141403 358.081543,220.685120 358.287384,228.446671 
	C358.384521,232.110733 358.460236,235.785843 358.285492,239.443512 
	C358.129913,242.700531 359.449524,243.841904 362.665680,243.853165 
	C368.125732,243.872284 373.681793,242.933502 379.365906,245.164413 
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M539.252319,516.396851 
	C521.110901,525.138184 503.298889,525.307251 485.605560,516.348999 
	C476.570160,511.774353 472.993164,504.794983 475.296967,497.086151 
	C477.884094,488.429108 486.550934,484.121674 494.688934,488.156921 
	C506.305206,493.916840 517.652039,493.634033 529.310913,488.554596 
	C537.897400,484.813690 544.560608,486.922333 548.514221,494.192566 
	C552.471375,501.469269 550.375732,509.119904 543.010803,514.171204 
	C541.914612,514.923035 540.731873,515.548645 539.252319,516.396851 
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M577.912720,674.427490 
	C577.334045,682.387634 573.132751,686.571472 567.750427,684.884094 
	C563.631653,683.592773 561.355347,680.735107 561.727966,676.291992 
	C562.092224,671.948975 564.025696,668.600098 568.653198,667.741455 
	C573.687866,666.807312 576.463928,669.453796 577.912720,674.427490 
z"
      />
    </svg>
  );
};

const STYLES = {
  light: {
    loader: 'border-white',
    icon: 'fill-white',
  },
  primary: {
    loader: 'border-indigo-500',
    icon: 'fill-indigo-500',
  },
  dark: {
    loader: 'border-slate-800',
    icon: 'fill-slate-800',
  },
};

export const CopilotIcon: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<SVGSVGElement>,
    SVGSVGElement
  > & {
    loading?: boolean;
    height?: string;
    width?: string;
    theme?: keyof typeof STYLES;
    tooltip?: string;
  }
> = ({ loading, ...props }) => {
  const style = STYLES[props.theme ?? 'light'];

  const children = (
    <div
      className="relative flex items-center justify-center"
      style={{
        height: props.height ?? '28px',
        width: props.width ?? '28px',
      }}
    >
      <div
        className={classNames(
          loading
            ? 'animate-spin absolute border-t rounded-full w-full h-full'
            : '',
          style.loader
        )}
      />
      <CopilotIconRaw
        {...props}
        className={classNames(props.className, style.icon)}
      />
    </div>
  );

  return props.tooltip ? (
    <Tooltip side="top" text={props.tooltip}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};
