import { Disposable } from './disposable.type';

export async function using<T extends Disposable, K>(
  resource: T,
  func: (resource: T) => PromiseLike<K>
) {
  try {
    return await func(resource);
  } finally {
    await resource.dispose();
  }
}
