import React from 'react';
import { H1 } from '../../components/ui/typography';
import { Layout } from '../../layout';
import { useAppData, useTenantedClient } from '../../stores/org';
import { useMutation } from '@tanstack/react-query';
import { Assert } from '@cotera/utilities';
import { Button } from '../../components/ui/button';
import { toast } from '../../components';

export const MaterializationIde: React.FC = () => {
  const materializations = useAppData((x) => x.skeleton.materializations);
  return (
    <Layout>
      <div className="p-8 flex flex-col space-y-8">
        <H1>Materializations</H1>
        <div className="flex flex-col space-y-4">
          {materializations.map((mat) => (
            <Mat {...mat} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

const Mat: React.FC<{
  viewName: string;
  schema: string;
  schedule: string | { cron: string };
}> = ({ viewName, schema, schedule }) => {
  const client = useTenantedClient();
  const run = useMutation({
    mutationFn: async () =>
      Assert.assertOk(
        await client.warehouse.runMaterialization({ viewName, schema })
      ),
    onSuccess: () => {
      toast.success(`Materialization "${viewName}" started`);
    },
  });

  return (
    <div className="flex flex-row gap-x-8 items-center p-4">
      <span className="font-bold">{viewName}:</span> ({JSON.stringify(schedule)}
      )
      <Button onClick={() => run.mutate()} text="Run" />
    </div>
  );
};
