import { z } from 'zod';
import { Contract, GET, PaginationParamsSchema, POST } from '@cotera/contracts';
import {
  AttentiveCredentialsSchema,
  BrazeCredentialsSchema,
  HubspotCredentialsSchema,
  IterableCredentialsSchema,
  KlaviyoCredentialsSchema,
  KustomerCredentialsSchema,
  OneSignalCredentialsSchema,
  PostscriptCredentialsSchema,
  SendGridCredentialsSchema,
  ShopifyCredentialsSchema,
  ZendeskCredentialsSchema,
} from '@cotera/utilities';

const makeConnectionContract = (credentialsSchema: z.ZodType) =>
  POST({
    params: z.object({
      credentials: credentialsSchema,
      displayName: z.string(),
    }),
    output: z.object({
      id: z.string().uuid(),
    }),
    errors: z.never(),
  });

export const IterableContract = Contract.new({
  create: makeConnectionContract(IterableCredentialsSchema),
});

export const HubspotContract = Contract.new({
  create: makeConnectionContract(HubspotCredentialsSchema),
});

export const BrazeContract = Contract.new({
  create: makeConnectionContract(BrazeCredentialsSchema),
});

export const KlaviyoContract = Contract.new({
  create: makeConnectionContract(KlaviyoCredentialsSchema),
});

export const SendGridContract = Contract.new({
  create: makeConnectionContract(SendGridCredentialsSchema),
});

export const OneSignalContract = Contract.new({
  create: makeConnectionContract(OneSignalCredentialsSchema),
});

export const ShopifyContract = Contract.new({
  create: makeConnectionContract(ShopifyCredentialsSchema),
});

export const PostscriptContract = Contract.new({
  create: makeConnectionContract(PostscriptCredentialsSchema),
});

export const ZendeskContract = Contract.new({
  create: makeConnectionContract(ZendeskCredentialsSchema),
});

export const KustomerContract = Contract.new({
  create: makeConnectionContract(KustomerCredentialsSchema),
});

export const AttentiveContract = Contract.new({
  create: makeConnectionContract(AttentiveCredentialsSchema),
});

export const INTEGRATION_TYPES = [
  'braze',
  'iterable',
  'klaviyo',
  'hubspot',
  'sendGrid',
  'oneSignal',
  'shopify',
  'postscript',
  'zendesk',
  'kustomer',
  'attentive',
] as const;

export const ConnectionsContract = Contract.new(
  {
    iterable: IterableContract,
    braze: BrazeContract,
    klaviyo: KlaviyoContract,
    hubspot: HubspotContract,
    sendGrid: SendGridContract,
    oneSignal: OneSignalContract,
    shopify: ShopifyContract,
    postscript: PostscriptContract,
    zendesk: ZendeskContract,
    kustomer: KustomerContract,
    attentive: AttentiveContract,
    all: GET({
      params: z.object({ pagination: PaginationParamsSchema }),
      output: z
        .object({
          enabled: z.boolean(),
          type: z.enum(INTEGRATION_TYPES),
          displayName: z.string(),
          id: z.string(),
        })
        .array(),
      errors: z.never(),
    }),
  },
  {
    mustBeAbleTo: [['write', 'integrations']],
  }
);
