import { Ty } from '../../ty';
import type { FunctionTypingRule } from './function-call';
import { satisfiesOneOf } from './satisfies-signature';

export const dateTruncTypingRule: FunctionTypingRule = satisfiesOneOf([
  [['timestamp', Ty.e(['year'])], 'year'],
  [['timestamp', Ty.e(['year', 'month'])], 'month'],
  [['timestamp', Ty.e(['year', 'month', 'day'])], 'day'],
  [['timestamp', 'string'], 'timestamp'],
]);
