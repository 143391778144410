import { isProduction } from '../utils';
import { config } from '@cotera/client/config/config';
import { Org, Whoami } from '@cotera/api';
import posthog from 'posthog-js';
import { useMemo } from 'react';

export class AnalyticsClient {
  static init() {
    if (isProduction() && config.posthogKey) {
      posthog.init(config.posthogKey, {
        autocapture: {
          url_allowlist: ['cotera.co/.*'],
        },
      });
    }
  }

  identify(org: Org, whoami: Whoami) {
    if (config.posthogKey) {
      posthog.identify(whoami.id, {
        email: whoami.email,
        name: whoami.name,
      });
      posthog.group('organization', org.id, {
        name: org.name,
      });
    }
  }

  track(event: string, context?: Record<string, string>) {
    if (config.posthogKey) {
      posthog.capture(event, context);
    }
  }
}

export const useAnalytics = (): AnalyticsClient => {
  return useMemo(() => new AnalyticsClient(), []);
};
