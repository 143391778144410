export const PRODUCTS = [
  'Gluten Free Oreos',
  'Birthday Cake Oreos',
  'Double Stuf Oreos',
  'Golden Oreos',
];

export const CATEGORIES = [
  'color',
  'price',
  'taste',
  'texture',
  'filling',
  'packaging',
];

export const TAXONOMY = [
  'ordering',
  'product - color',
  'product - price',
  'customer_service',
  'product - taste',
  'product - texture',
  'product - filling',
  'product - packaging',
];
