import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { ChildrenProps } from '../utils';
import { HorizontalPadding } from './components/horizontal-padding';
import { kebabCase, throttle } from 'lodash';
import {
  flexRender,
  getCoreRowModel,
  Table as ReactTable,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer, Virtualizer } from './hooks/use-virtualizers';
import { useSubscribe, ViewModelProvider } from '@cotera/client/app/etc';
import { classNames, jsonToCsv } from '@cotera/client/app/components/utils';
import { useDuckDBQuery } from '../../etc/data/duckdb';
import { DataGridViewModel, Registry } from './types';
import { defaultRegistry } from './registry';
import { ColumnStateProvider, defaultStateFromRel } from './column-state';
import { useNearBottom } from './hooks/use-near-bottom';
import {
  Badge,
  Button,
  Center,
  Icon,
  Loading,
  ProgressBar,
  Text,
  toast,
} from '@cotera/client/app/components/ui';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '../headless';
import { ErrorBoundary } from 'react-error-boundary';
import { DisplayError } from '../app';
import { DataGridActions, SlideOver } from './components/data-grid-actions';
import { ColumnMenu } from './components/column-actions';
import { useOnPropChange } from './hooks/use-on-props-change';
import { useSelectRow } from './hooks/use-select-row';

const ROW_HEIGHT = 33;

type TableBodyProps = {
  rowVirtualizer: Virtualizer;
  dataLength: number;
  columns: { key: string }[];
  cellRenderer: (props: {
    columnIndex: number;
    rowIndex: number;
    key: string;
  }) => React.ReactNode;
  extraColumns?: ((props: { index: number }) => React.ReactNode)[];
  tableBodyRef: React.RefObject<HTMLTableSectionElement>;
  viewHeight?: number;
  vm: DataGridViewModel;
};

export const TableBody: React.FC<TableBodyProps> = ({
  vm,
  cellRenderer: Cell,
  viewHeight = 400,
  dataLength,
  columns,
  rowVirtualizer,
  extraColumns = [],
  tableBodyRef,
}) => {
  const height = Math.min(
    rowVirtualizer.controller.getTotalSize(),
    dataLength * ROW_HEIGHT
  );

  const virtualRows = rowVirtualizer.controller.getVirtualItems();
  const selectedRows = useSubscribe(vm, (s) => s.selectedRows);
  const selectRow = useSelectRow(vm);

  return (
    <div
      ref={tableBodyRef}
      className="flex w-full overflow-scroll"
      style={{
        height: `${viewHeight}px`,
        position: 'relative',
      }}
    >
      <div
        className="w-full"
        style={{
          display: 'grid',
          height: `${height}px`, //tells scrollbar how big the table is
          position: 'relative', //needed for absolute positioning of rows
        }}
      >
        {virtualRows.map((virtualRow) => {
          return (
            <div
              key={virtualRow.index}
              onClick={() => {
                selectRow(virtualRow.index);
              }}
              data-index={virtualRow.index}
              ref={(node) => rowVirtualizer.controller.measureElement(node)}
              className={classNames(
                'flex-row',
                selectedRows.includes(virtualRow.index) ? 'bg-indigo-50' : ''
              )}
              style={{
                display: 'flex',
                position: 'absolute',
                transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                width: '100%',
              }}
            >
              <HorizontalPadding.Cell position="start" />
              {columns.map((column, index) => {
                return flexRender(
                  <Cell
                    key={column.key}
                    columnIndex={index}
                    rowIndex={virtualRow.index}
                  />,
                  {}
                );
              })}
              {extraColumns.map((c) => c({ index: virtualRow.index }))}
              <HorizontalPadding.Cell position="end" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

type HeaderProps = {
  vm: DataGridViewModel;
  columns: { key: string; getResizeHandler: () => (event: unknown) => void }[];
  headerRenderer: (props: { index: number; id: string }) => React.ReactNode;
  extraColumns: React.ReactNode[];
  registry: Registry;
};

export const TableHeader: React.FC<HeaderProps> = ({
  extraColumns,
  columns,
  headerRenderer: Header,
  vm,
  registry,
}) => {
  return (
    <div className="sticky top-0 bg-white z-[1]">
      <div className="flex flex-row w-full relative">
        <HorizontalPadding.Heading position="start" />

        {columns.map((header, index) => {
          return (
            <div
              key={index}
              style={{
                width: `calc(var(--header-${kebabCase(
                  header.key
                )}-size) * 1px)`,
              }}
              className="relative flex flex-row border-r border-divider border-b shadow-sm font-normal text-left"
            >
              {flexRender(<Header index={index} id={header.key} />, {})}

              <>
                <div className="absolute top-0.5 right-0">
                  <ColumnMenu registry={registry} vm={vm} column={header.key} />
                </div>
                <div
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className="w-[4px] h-full hover:bg-secondary-background rounded -mr-[2px] cursor-ew-resize"
                />
              </>
            </div>
          );
        })}
        {extraColumns.map((c, index) => (
          <div
            key={index}
            style={{
              width: `calc(var(--header-cotera-extra-column-size) * 1px)`,
            }}
            className="relative flex flex-row border-r border-divider border-b shadow-sm font-normal text-left"
          >
            {c}
          </div>
        ))}
        <HorizontalPadding.Heading position="end" />
      </div>
    </div>
  );
};

type TableProps = {
  className?: string;
  containerRef: React.RefObject<HTMLDivElement>;
  columnCssVars: Record<string, string | number>;
  rowCssVars: Record<string, string | number>;
} & ChildrenProps;

export const Table: React.FC<TableProps> = ({
  children,
  columnCssVars,
  rowCssVars,
  containerRef,
}) => {
  return (
    <div className="flex flex-col w-full relative">
      <div
        className="h-full w-full flex flex-col overflow-auto relative"
        ref={containerRef}
        style={{
          ...columnCssVars,
          ...rowCssVars,
          display: 'grid',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const ManagedDataGrid: React.FC<{
  registry?: Registry;
  vm: DataGridViewModel;
  extraColumns?: {
    header: React.ReactNode;
    cell: (props: { index: number }) => React.ReactNode;
  }[];
}> = ({ vm, registry = defaultRegistry, extraColumns }) => {
  const rel = useSubscribe(vm, (s) => s.baseRel);
  const columns = useSubscribe(vm, (s) => s.visibleColumns);

  const table = useReactTable({
    initialState: {
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    data: [], //we manage the data ourselves
    columns: columns.map((name) => ({
      accessorKey: name,
    })),
    getCoreRowModel: getCoreRowModel(),
  });

  const headerRenderers = useMemo(() => {
    const renderers = columns.reduce((acc, col) => {
      acc[col] = registry.getHeader(rel, col, rel.attributes[col]!);
      return acc;
    }, {} as Record<string, React.ReactNode>);

    return (props: { id: string }) => {
      return renderers[props.id]!;
    };
  }, [columns, registry, rel]);

  return (
    <ViewModelProvider model={vm}>
      <ColumnStateProvider state={defaultStateFromRel(rel)}>
        <div className="flex flex-col w-full">
          <BaseDataGrid
            extraColumns={extraColumns?.map((c) => c.header)}
            registry={registry}
            vm={vm}
            table={table}
            columns={columns}
            headerRenderer={headerRenderers}
          >
            <Suspense
              fallback={
                <div className="h-[400px]">
                  <Center>
                    <Loading.Dots />
                  </Center>
                </div>
              }
            >
              <ErrorBoundary
                fallbackRender={({ error }) => <DisplayError error={error} />}
              >
                <DataView
                  table={table}
                  vm={vm}
                  registry={registry}
                  extraColumns={extraColumns?.map((x) => x.cell)}
                />
              </ErrorBoundary>
            </Suspense>
          </BaseDataGrid>
          <Footer vm={vm} />
          <SlideOver vm={vm} />
        </div>
      </ColumnStateProvider>
    </ViewModelProvider>
  );
};

const DataView: React.FC<{
  table: ReactTable<never>;
  vm: DataGridViewModel;
  registry: Registry;
  extraColumns?: ((props: { index: number }) => React.ReactNode)[];
}> = ({ table, vm, registry, extraColumns }) => {
  return table.getState().columnSizingInfo.isResizingColumn !== false ? (
    <MemoedDataGrid vm={vm} registry={registry} extraColumns={extraColumns} />
  ) : (
    <DataGridData vm={vm} registry={registry} extraColumns={extraColumns} />
  );
};

const BaseDataGrid: React.FC<
  {
    table: ReactTable<never>;
    columns: string[];
    headerRenderer: (props: { index: number; id: string }) => React.ReactNode;
    vm: DataGridViewModel;
    registry: Registry;
    extraColumns?: React.ReactNode[];
  } & ChildrenProps
> = ({
  table,
  vm,
  headerRenderer,
  children,
  columns,
  registry,
  extraColumns,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const headers = table.getFlatHeaders();
  const columnCssVars = useColumnCssVars(tableContainerRef, headers, table);

  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  const rowCssVars = {
    '--row-padding-size-start': virtualPaddingLeft ?? 0,
    '--row-padding-size-end': virtualPaddingRight ?? 0,
  };

  const managedHeaders = table
    .getHeaderGroups()
    .map((g) => g.headers)
    .flat()
    .filter((header) => columns.includes(header.id))
    .map((header) => ({
      key: header.id,
      getResizeHandler: header.getResizeHandler,
    }));

  return (
    <Table
      containerRef={tableContainerRef}
      columnCssVars={columnCssVars}
      rowCssVars={rowCssVars}
    >
      <TableHeader
        registry={registry}
        vm={vm}
        columns={managedHeaders}
        headerRenderer={headerRenderer}
        extraColumns={extraColumns ?? []}
      />
      {children}
    </Table>
  );
};

const DataGridData: React.FC<{
  vm: DataGridViewModel;
  registry: Registry;
  extraColumns?: ((props: { index: number }) => React.ReactNode)[];
}> = ({ vm, registry, extraColumns }) => {
  const rel = useSubscribe(vm, (s) => s.rel);
  const columns = useSubscribe(vm, (s) => s.visibleColumns);
  const {
    data: { data },
  } = useDuckDBQuery({
    rel,
  });

  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const rowVirtualizer = useVirtualizer({
    containerRef: tableBodyRef,
    estimatedSize: () => ROW_HEIGHT,
    defaultCount: Math.min(data.length, 50),
    horizontal: false,
    overscan: 5,
  });

  useEffect(() => {
    vm.setData(data);
    rowVirtualizer.setCount(Math.min(data.length, 50));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm, data]);

  const cellRenderer = useMemo(() => {
    const cellRenderers = registry.getCellRenderers(rel);
    return (props: { columnIndex: number; rowIndex: number }) => {
      const column = columns[props.columnIndex]!;
      const Cell = cellRenderers[column]!;
      const value = data.column(column).valueAt(props.rowIndex);

      return (
        <Cell column={column} value={value} ty={rel.attributes[column]!} />
      );
    };
  }, [rel, registry, columns, data]);

  useOnPropChange(rowVirtualizer.controller.getVirtualItems(), (items) => {
    vm.setInViewRows(items.map((x) => x.index));
  });

  useOnPropChange(rowVirtualizer.count, (count) => {
    vm.setLoadedRows(count);
  });

  useNearBottom(
    tableBodyRef,
    () => {
      if (data.length > rowVirtualizer.count) {
        const rowCount = rowVirtualizer.count + 50;
        rowVirtualizer.setCount(rowCount);
      }
    },
    100
  );

  return (
    <TableBody
      vm={vm}
      tableBodyRef={tableBodyRef}
      rowVirtualizer={rowVirtualizer}
      dataLength={Math.min(data.length, rowVirtualizer.count)}
      columns={columns.map((key) => ({ key }))}
      cellRenderer={cellRenderer}
      extraColumns={extraColumns}
    />
  );
};
const MemoedDataGrid = React.memo(DataGridData, (prev, next) => {
  return prev.vm.rel.sqlHash() === next.vm.rel.sqlHash();
});

const useColumnCssVars = (
  tableContainerRef: React.RefObject<HTMLDivElement>,
  headers: {
    id: string;
    getSize: () => number;
    column: { id: string; getSize: () => number };
  }[],
  table: ReactTable<never>
) => {
  const [tableWidth, setTableWidth] = useState(0);

  useEffect(() => {
    const updateTableWidth = throttle(() => {
      setTableWidth(tableContainerRef.current?.offsetWidth ?? 0);
    }, 100); // Throttle interval in milliseconds

    const resizeObserver = new ResizeObserver(updateTableWidth);
    if (tableContainerRef.current) {
      resizeObserver.observe(tableContainerRef.current);
    }

    // Initial call to set the width on first render
    updateTableWidth();

    return () => {
      resizeObserver.disconnect();
    };
  }, [tableContainerRef]);

  const columnCssVars = useMemo(() => {
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${kebabCase(header.id)}-size`] = Math.max(
        header.getSize(),
        tableWidth / headers.length
      );
      colSizes[`--col-${kebabCase(header.column.id)}-size`] = Math.max(
        header.column.getSize(),
        tableWidth / headers.length
      );
    }
    colSizes[`--header-cotera-extra-column-size`] = 120;
    colSizes[`--col-cotera-extra-column-size`] = 120;

    return colSizes;
  }, [
    headers,
    tableWidth,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    table.getState().columnSizingInfo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    table.getState().columnSizing,
  ]);

  return columnCssVars;
};

export const Footer: React.FC<{
  vm: DataGridViewModel;
}> = ({ vm }) => {
  const inViewRows = useSubscribe(vm, (s) => s.inViewRows);
  const totalRowCount = useSubscribe(vm, (s) => s.totalRowCount);
  const loadedRowCount = useSubscribe(vm, (s) => s.loadedRows);
  const maxInViewIndex =
    inViewRows.length === 0
      ? Math.min(50, totalRowCount)
      : Math.max(...inViewRows);
  const selectedRows = useSubscribe(vm, (s) => s.selectedRows);

  return (
    <div className="flex flex-col w-[calc(100%-1em)] border-t sticky left-0 bottom-0">
      {totalRowCount > 0 && (
        <ProgressBar
          theme="inverse"
          percent={
            loadedRowCount === 0
              ? 0
              : (Math.min(maxInViewIndex, totalRowCount) / totalRowCount) * 100
          }
          className="w-full h-1"
        />
      )}

      <div className="w-full flex bg-white pt-2 h-[35px] left-0 items-center justify-between">
        <div className="flex items-center">
          {totalRowCount > 0 && (
            <Text.Caption className="text-center">
              Loaded {Math.min(loadedRowCount, totalRowCount)} of{' '}
              {totalRowCount} rows
            </Text.Caption>
          )}
        </div>
        <div className="flex items-center">
          {selectedRows.length > 0 && (
            <>
              <Badge theme="secondary">
                {selectedRows.length} rows selected
              </Badge>
              <FooterMenu vm={vm} />
            </>
          )}
          <DataGridActions vm={vm} />
        </div>
      </div>
    </div>
  );
};

const FooterMenu: React.FC<{
  vm: DataGridViewModel;
}> = ({ vm }) => {
  const data = useSubscribe(vm, (s) => s.data);
  const selectedItems = useSubscribe(vm, (s) => s.selectedRows);

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button inline icon="ellipsis-vertical" />
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          icon={<Icon icon="clipboard-document" />}
          active={false}
          onClick={async () => {
            const json = JSON.stringify(
              selectedItems.map((x) => data?.row(x).toArray()).flat()
            );

            await navigator.clipboard.writeText(json);

            toast.success('Copied as JSON');
          }}
        >
          Copy as JSON
        </DropdownItem>
        <DropdownItem
          icon={<Icon icon="clipboard-document-list" />}
          active={false}
          onClick={async () => {
            const csv = await jsonToCsv(
              selectedItems.map((x) => data?.row(x).toArray()).flat()
            );

            await navigator.clipboard.writeText(csv);

            toast.success('Copied as CSV');
          }}
        >
          Copy as CSV
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
