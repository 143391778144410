import {
  BrazeCredentialsSchema,
  HubspotCredentialsSchema,
  IterableCredentialsSchema,
  KlaviyoCredentialsSchema,
  KustomerCredentialsSchema,
  OneSignalCredentialsSchema,
  PostscriptCredentialsSchema,
  SendGridCredentialsSchema,
  ShopifyCredentialsSchema,
  ZendeskCredentialsSchema,
} from '@cotera/utilities';

import {
  IterableLogo,
  RedshiftLogo,
  HubspotLogo,
  BigQueryLogo,
  SnowflakeLogo,
  PostgresLogo,
  KlaviyoLogo,
  BrazeLogo,
  SendGridLogo,
  OneSignalLogo,
  ShopifyLogo,
  ZendeskLogo,
  AttentiveLogo,
  KustomerLogo,
  PostscriptLogo,
} from '@cotera/client/app/components/app/icons';
import { INTEGRATION_TYPES } from '@cotera/api';
import { Credentials } from '@cotera/era';

export const WAREHOUSE_TYPES = [
  'snowflake',
  'bigquery',
  'redshift',
  'postgres',
] as const;
export const credentialTypes = [
  ...INTEGRATION_TYPES,
  ...WAREHOUSE_TYPES,
] as const;

export const CredentialsMap = {
  oneSignal: {
    icon: OneSignalLogo,
    iconSmall: '',
    displayName: 'OneSignal',
    schema: OneSignalCredentialsSchema,
  },
  sendGrid: {
    icon: SendGridLogo,
    displayName: 'SendGrid',
    schema: SendGridCredentialsSchema,
  },
  hubspot: {
    icon: HubspotLogo,
    displayName: 'HubSpot',
    schema: HubspotCredentialsSchema,
  },
  iterable: {
    icon: IterableLogo,
    displayName: 'Iterable',
    schema: IterableCredentialsSchema,
  },
  braze: {
    icon: BrazeLogo,
    displayName: 'Braze',
    schema: BrazeCredentialsSchema,
  },
  klaviyo: {
    icon: KlaviyoLogo,
    displayName: 'Klaviyo',
    schema: KlaviyoCredentialsSchema,
  },
  shopify: {
    icon: ShopifyLogo,
    displayName: 'Shopify',
    schema: ShopifyCredentialsSchema,
  },
  postscript: {
    icon: PostscriptLogo,
    displayName: 'Postscript',
    schema: PostscriptCredentialsSchema,
  },
  zendesk: {
    icon: ZendeskLogo,
    displayName: 'Zendesk',
    schema: ZendeskCredentialsSchema,
  },
  kustomer: {
    icon: KustomerLogo,
    displayName: 'Kustomer',
    schema: KustomerCredentialsSchema,
  },
  attentive: {
    icon: AttentiveLogo,
    displayName: 'Attentive',
    schema: KustomerCredentialsSchema,
  },
  snowflake: {
    icon: SnowflakeLogo,
    displayName: 'Snowflake',
    schema: Credentials.SnowflakeCredentialsSchema,
    warehouseType: 'SNOWFLAKE',
  },
  bigquery: {
    icon: BigQueryLogo,
    displayName: 'BigQuery',
    schema: Credentials.BigQueryCredentialsSchema,
    warehouseType: 'BIGQUERY',
  },
  redshift: {
    icon: RedshiftLogo,
    displayName: 'Redshift',
    schema: Credentials.RedshiftCredentialsSchema,
    warehouseType: 'REDSHIFT',
  },
  postgres: {
    icon: PostgresLogo,
    displayName: 'Postgres',
    schema: Credentials.PostgresCredentialsSchema,
    warehouseType: 'POSTGRES',
  },
} as const;
