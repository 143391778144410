import { Axis } from '../utils';
import { BarChart } from '../bar';
import { merge } from 'lodash';
import { ChartProps } from '../types';
import { sortBy } from 'lodash';
import { Formatters } from '@cotera/client/app/components/utils';

type Datum = {
  count: number;
  start: number;
  end: number;
  category: string;
};

export type Props = {
  trendline?: boolean;
  loading?: boolean;
  axis: ChartAxis;
  type?: 'stacked' | 'grouped';
  direction?: 'vertical' | 'horizontal';
} & ChartProps<Datum>;

type ChartAxis = {
  x?: Axis;
  y?: Axis;
};

export const HistogramChart: React.FC<Props> = ({
  data: _data,
  loading = false,
  axis,
  format: _format,
  type = 'stacked',
  direction = 'vertical',
  ...layoutProps
}) => {
  const data = sortBy(
    _data.map((datum) => ({
      x: `${Formatters.number(datum.start)} - ${Formatters.number(datum.end)}`,
      y: datum.count,
      category: datum.category,
      style: 'random' as const,
      rawStart: datum.start,
    })),
    (x) => x.rawStart
  );

  return (
    <BarChart
      type={type}
      data={data}
      axis={merge(
        {
          y: {},
          x: {},
        },
        axis
      )}
      loading={loading}
      direction={direction}
      {...layoutProps}
    />
  );
};
