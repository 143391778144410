import { CoteraClient, DevServerClient } from '@cotera/api';
import { createContext, useContext } from 'react';
import { useDevMode } from '@cotera/client/app/stores/dev-mode';
import { ChildrenProps } from '@cotera/client/app/components/utils';

type State = CoteraClient;

const Context = createContext<State>(undefined as any);

export const ApiProvider: React.FC<
  ChildrenProps & {
    api: CoteraClient;
    localDevServer: DevServerClient;
  }
> = ({ children, api, localDevServer }) => {
  const source = useDevMode((s) => (s.enabled ? 'dev' : 'commited'));

  return (
    <Context.Provider
      value={{
        ...api,
        t: {
          ...api.t,
          devServer: source === 'dev' ? localDevServer : api.t.devServer,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useApi = () => useContext(Context);
