export const Loading = () => (
  <svg
    className="h-full w-full animate-pulse"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97.28 435.2h322.56v455.68H97.28zM97.28 117.76h322.56v235.52H97.28z"
      fill="#D1D1D1"
    />
    <path
      d="M481.28 609.28h445.44v281.6H481.28zM481.28 117.76h445.44v419.84H481.28z"
      fill="#9C9C9C"
    />
  </svg>
);
