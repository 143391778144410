import { z, ZodSchema } from 'zod';

const enumParamSchema = (t: ZodSchema): ZodSchema => {
  return z.array(t).optional();
};

const stringParameterSchema = z.object({
  type: z.literal('string'),
  description: z.string(),
  enum: enumParamSchema(z.string()),
});

const numberParameterSchema = z.object({
  type: z.literal('number'),
  description: z.string(),
  enum: enumParamSchema(z.number()),
});

const booleanParameterSchema = z.object({
  type: z.literal('boolean'),
  description: z.string(),
  enum: enumParamSchema(z.boolean()),
});

const functionParameterSchema = z.discriminatedUnion('type', [
  stringParameterSchema,
  numberParameterSchema,
  booleanParameterSchema,
]);

export type FunctionParameter = z.infer<typeof functionParameterSchema>;

const functionDeclarationSchema = z.object({
  name: z.string(),
  description: z.string(),
  parameters: z.object({
    type: z.literal('object').or(z.literal('string')),
    required: z.array(z.string()),
    properties: z.record(functionParameterSchema),
  }),
});

export type FunctionDeclaration = z.infer<typeof functionDeclarationSchema>;

const functionSchema = z.object({
  type: z.literal('function'),
  function: functionDeclarationSchema,
});

export type Function = z.infer<typeof functionSchema>;

export const toolsSchema = functionSchema.array();

export type Tools = z.infer<typeof toolsSchema>;

export const toolCallSchema = z.object({
  id: z.string(),
  type: z.enum(['function']),
  function: z.object({
    name: z.string(),
    arguments: z.string(),
  }),
});

export type ToolCall = z.infer<typeof toolCallSchema>;
