export const Rotate: React.FC<{
  children: React.ReactNode;
  in: boolean;
  degrees: number;
  delay?: number;
  className?: string;
}> = ({ children, in: inProp, degrees, delay, className }) => {
  const duration = 300;

  const style = {
    transform: inProp ? `rotate(${degrees}deg)` : '',
    transition: `transform ${duration}ms ease ${delay}ms`,
  };

  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};
