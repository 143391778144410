export const ZendeskLogo = ({ height = 24 }: { height?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 629.5 119.6"
      width={height * (100 / 19)}
      height={height}
    >
      <style>.st0{'{fill:#03363d}'}</style>
      <title>golion-z-sourcefile-algae</title>
      <path
        className="st0"
        d="M415.3 103.3c8.3.2 16.3-2.9 22.3-8.7l11 11.9c-7.3 7.5-17.4 13.1-33.1 13.1-27 0-44.4-17.9-44.4-42.1 0-24 18.3-42.1 41.9-42.1 26.8 0 41.9 20.4 40.6 48.6h-63.2c2.2 11.8 10.5 19.3 24.9 19.3m19.2-32.7c-1.7-10.9-8.2-19-21.3-19-12.2 0-20.6 6.9-22.8 19h44.1zM0 102.4l48.7-49.5H1.1V37.4h70.1v15.9l-48.6 49.4h49.3v15.5H0zM126.5 103.3c8.3.2 16.3-2.9 22.3-8.7l11 11.9c-7.3 7.5-17.4 13.1-33.1 13.1-26.9 0-44.4-17.9-44.4-42.1 0-24 18.3-42.1 41.9-42.1 26.8 0 41.9 20.4 40.6 48.6h-63.2c2.3 11.8 10.5 19.3 24.9 19.3m19.3-32.7c-1.7-10.9-8.2-19-21.3-19-12.2 0-20.6 6.9-22.8 19h44.1zM270 77.6c0-25.9 19.3-42 40.6-42 10.7 0 20.9 4.7 26.9 12.1V0h17.2v118h-17.2v-11.2c-6.2 7.9-16.4 12.8-27.1 12.8-20.6 0-40.4-16.3-40.4-42m68.5-.2c0-14.4-10.9-26.2-25.6-26.2-14.4 0-25.6 11.5-25.6 26.2s11.2 26.3 25.6 26.3c14.7.1 25.6-11.7 25.6-26.3M464.8 100.8l15.6-8.1c4.2 7.5 12.2 12 20.8 11.8 9.7 0 14.8-5 14.8-10.7 0-6.5-9.4-7.9-19.6-10-13.8-2.9-28.1-7.4-28.1-24.1 0-12.8 12.2-24.6 31.3-24.4 15.1 0 26.3 6 32.6 15.7l-14.5 8c-3.7-5.7-10.1-9.2-18.2-9.2-9.3 0-14 4.5-14 9.7 0 5.8 7.5 7.4 19.2 10 13.3 2.9 28.4 7.3 28.4 24.1 0 11.2-9.7 26.2-32.8 26-16.8 0-28.7-6.8-35.5-18.8M579.8 82.1L566.2 97v21H549V0h17.2v77.2l36.5-40h20.9L592 71.8l32.5 46.2H605zM218 35.5c-20.5 0-37.6 13.3-37.6 35.2V118H198V73c0-13.3 7.6-21.2 20.6-21.2s19.5 7.9 19.5 21.2v45h17.5V70.8c0-22-17.1-35.3-37.6-35.3"
      />
      <path
        className="st0"
        d="M618.8 26.7c2.4-2.4 6.4-2.4 8.8 0 2.4 2.4 2.4 6.4 0 8.8-2.4 2.4-6.4 2.4-8.8 0-2.5-2.4-2.5-6.3 0-8.8zm7.7 7.8c1.8-1.9 1.7-4.9-.1-6.7-1.9-1.8-4.9-1.7-6.7.1-1.8 1.9-1.8 4.8.1 6.6 1.9 1.9 4.9 1.8 6.7 0zm-2.8-7.4l1.9 1.9c1.2 1.2 1.2 2.6.4 3.4-.6.7-1.6.9-2.4.5l-.6 2.4-1.3-1.3.9-3.3.5.5c.3.4 1 .5 1.4.2l.1-.1c.4-.4.3-1.1-.1-1.4l-.1-.1-.7-.7-3.5 3.5-1.1-1.1 4.6-4.4z"
      />
    </svg>
  );
};
