import { FFI } from '@cotera/sdk/core';
import { Icon, IconName } from '@cotera/client/app/components/ui';
import { TC, Ty } from '@cotera/era';

export const iconForTy = (ty: Ty.ExtendedAttributeType): IconName | null => {
  if (FFI.isFFI(ty)) {
    return 'sparkles';
  }

  if (TC.isId(ty)) {
    return 'key';
  }

  if (TC.isNumeric(ty)) {
    return 'variable';
  }

  if (TC.isTimestamp(ty)) {
    return 'calendar';
  }

  if (TC.isStringLike(ty)) {
    return 'text';
  }

  if (TC.isBoolean(ty)) {
    return 'check-circle';
  }

  return null;
};

export const AttrIcon: React.FC<{ ty: Ty.ExtendedAttributeType }> = ({
  ty,
}) => {
  const iconName = iconForTy(ty);

  if (iconName !== null) {
    return <Icon icon={iconName} />;
  }

  return <span className="w-4 h-4">&nbsp;</span>;
};
