import { DisplayError } from '@cotera/client/app/components';
import { AST, Constant } from '@cotera/era';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { CompileMarkup } from './compiler';
import { Dashboard } from '../components/dashboard';
import { InstrumentationProvider } from '@cotera/client/app/etc';
import {
  ProvideEraScope,
  useTypedExpressionValue,
} from './macro-expansion/scopes';

export const Page: React.FC<{
  section: AST._Page;
}> = ({ section: page }) => {
  const params = useParams();

  for (const param of page.params) {
    if (!(param in params)) {
      return (
        <DisplayError
          error={{
            name: 'Unable to show page',
            message: `Missing url parameter: ${param}`,
            errorType: 'MissingParam',
          }}
        />
      );
    }
  }

  const vals: AST.MacroVarReplacements<AST.RelMacroChildren> = {
    exprs: Object.fromEntries(
      page.params.map((param) => [
        param,
        Constant(z.string().parse(params[param]), { ty: 'string' }).ast,
      ])
    ),
    rels: {},
    sections: {},
  };

  return (
    <InstrumentationProvider
      metadata={{
        pageParams: JSON.stringify(params),
        location: window.location.pathname,
      }}
    >
      <Dashboard.Inner key={JSON.stringify(params)}>
        <ProvideEraScope scope={page.body.scope} vals={vals}>
          <CompileMarkup section={page.body.macro} inBlock={false} />
        </ProvideEraScope>
      </Dashboard.Inner>
    </InstrumentationProvider>
  );
};

export const PageHeader: React.FC<{ section: AST._Header }> = ({ section }) => {
  const title = useTypedExpressionValue(section.title, z.string().nullable());
  const caption = useTypedExpressionValue(
    section.caption,
    z.string().nullable()
  );

  return <Dashboard.Header title={title ?? ''} caption={caption ?? ''} />;
};
