import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../layout';
import { useManifestDetailPage } from '../../hooks/use-manifest';
import { Center, Loading, NotFound } from '../../components';
import { useAppData } from '../../stores/org';
import { assertOk } from '@cotera/utilities';
import { UI } from '@cotera/era';
import { CompileMarkup } from '../apps/compiler';
import { TopLevelMarkupWrapper } from '../top-level-markup-wrapper';

export const RenderDetails: React.FC = () => {
  const { page, id } = useParams();

  if (page === undefined || id === undefined) {
    throw new Error('Incorrect parameters');
  }

  const detailTy = useAppData((x) => x.skeleton.detailPages[page]);
  const detailPage = useManifestDetailPage({ page });

  if (detailPage.isLoading) {
    return (
      <Layout>
        <Center>
          <Loading.Dots />
        </Center>
      </Layout>
    );
  }

  if (!detailTy) {
    return (
      <Layout>
        <NotFound resource={`Detail Page: "${page}"`} />
      </Layout>
    );
  }

  const mu = assertOk(detailPage.data!);
  const section = UI.DetailPage.renderDetails(mu, id);

  return (
    <Layout>
      <TopLevelMarkupWrapper>
        <CompileMarkup section={section} inBlock={false} />
      </TopLevelMarkupWrapper>
    </Layout>
  );
};
