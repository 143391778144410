import { useState } from 'react';
import { ChildrenProps } from '../../utils';
import { classNames } from '@cotera/client/app/components/utils';
import { Button, toast } from '@cotera/client/app/components/ui';

type Offsets = {
  top?: string;
  left?: string;
  right?: string;
};
type CopyableProps = ChildrenProps & {
  position?: 'left' | 'right';
  offset?: Offsets;
  value?: string;
};

const CopyButton: React.FC<{
  value: string;
  position?: 'left' | 'right';
  offsets: Offsets;
}> = ({ value, position = 'right', offsets }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className={classNames(
        'absolute h-10 w-10',
        offsets?.top ?? '-top-2.5',
        position === 'right'
          ? offsets?.right ?? '-right-4'
          : offsets?.left ?? '-left-2'
      )}
      onMouseOver={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <Button
        inline
        icon="clipboard"
        onClick={async (e) => {
          e.stopPropagation();
          await navigator.clipboard.writeText(value);
          toast.success('Copied to clipboard');
        }}
        className={classNames(hovering ? 'block' : 'hidden')}
      />
    </div>
  );
};

export const Copyable: React.FC<CopyableProps> = ({
  children,
  position,
  offset,
  value,
}) => {
  return (
    <>
      {children}
      <CopyButton
        value={value ?? (children as string)}
        position={position}
        offsets={offset ?? {}}
      />
    </>
  );
};
