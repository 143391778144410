import { Ty } from '../ty';
import _ from 'lodash';
import { err, ok, Result } from 'neverthrow';
import { Assert } from '@cotera/utilities';
import {
  RelInterfaceShorthand,
  relShorthandInterfaceToFull,
} from './rel-interface';
import { InvalidTypeForAttribute, TypeCheckError } from './type-check-error';

export const implementsTy = (params: {
  subject: Ty.Shorthand;
  req: Ty.Shorthand;
}): boolean => {
  const extendedSubject = Ty.shorthandToTy(params.subject);
  const extendedReqs = Ty.shorthandToTy(params.req);

  if (!extendedReqs.nullable && extendedSubject.nullable) {
    return false;
  }

  if (!extendedReqs.tags.every((tag) => extendedSubject.tags.includes(tag))) {
    return false;
  }

  if (extendedReqs.ty.k === 'primitive') {
    if (extendedSubject.ty.k === 'primitive') {
      if (extendedReqs.ty.t === 'float' && extendedSubject.ty.t === 'int') {
        return true;
      }

      if (isTimeLike(extendedReqs.ty.t) && isTimeLike(extendedSubject.ty.t)) {
        return implsTimeLike({
          subject: extendedSubject.ty.t,
          reqs: extendedReqs.ty.t,
        });
      }

      return extendedSubject.ty.t === extendedReqs.ty.t;
    }

    if (extendedSubject.ty.k === 'id') {
      return implementsTy({
        subject: {
          ty: { k: 'primitive', t: extendedSubject.ty.t },
          nullable: extendedSubject.nullable,
          tags: extendedSubject.tags,
        },
        req: extendedReqs,
      });
    }

    if (extendedSubject.ty.k === 'range') {
      return implementsTy({
        subject: {
          ty: { k: 'primitive', t: 'int' },
          nullable: extendedSubject.nullable,
          tags: extendedSubject.tags,
        },
        req: extendedReqs,
      });
    }
  }

  if (extendedSubject.ty.k === 'array' && extendedReqs.ty.k === 'array') {
    return implementsTy({
      subject: extendedSubject.ty.t,
      req: extendedReqs.ty.t,
    });
  }

  if (extendedSubject.ty.k === 'struct' && extendedReqs.ty.k === 'struct') {
    const subjectFields = _.sortBy(Object.entries(extendedSubject.ty.fields), [
      (f) => f[0],
    ]);

    const reqFields = _.sortBy(Object.entries(extendedReqs.ty.fields), [
      (f) => f[0],
    ]);

    return _.zip(subjectFields, reqFields)
      .map(([sub, req]) => {
        if (sub === undefined || req === undefined) {
          return false;
        }
        const [subName, subTy] = sub;
        const [reqName, reqTy] = req;
        return (
          subName === reqName && implementsTy({ subject: subTy, req: reqTy })
        );
      })
      .every((x) => x);
  }

  if (extendedReqs.ty.k === 'range') {
    if (extendedSubject.ty.k === 'range') {
      return (
        extendedReqs.ty.start <= extendedSubject.ty.start &&
        extendedReqs.ty.end >= extendedSubject.ty.end
      );
    }
  }

  if (extendedReqs.ty.k === 'id') {
    if (extendedSubject.ty.k === 'id') {
      const sameName = extendedReqs.ty.name === extendedSubject.ty.name;
      const sameTy = extendedReqs.ty.t === extendedSubject.ty.t;
      return sameName && sameTy;
    }
  }

  if (extendedSubject.ty.k === 'enum') {
    if (extendedReqs.ty.k === 'primitive' && extendedReqs.ty.t === 'string') {
      return true;
    }

    if (extendedReqs.ty.k === 'enum') {
      const allowedVariants = extendedReqs.ty.t;
      return extendedSubject.ty.t.every((variant) =>
        allowedVariants.includes(variant)
      );
    }
  }

  if (extendedReqs.ty.k === 'record') {
    if (extendedSubject.ty.k === 'record') {
      return implementsTy({
        subject: extendedSubject.ty.t,
        req: extendedReqs.ty.t,
      });
    }
    if (extendedSubject.ty.k === 'struct') {
      const req = extendedReqs.ty.t;
      return Object.values(extendedSubject.ty.fields).every((field) =>
        implementsTy({
          subject: field,
          req,
        })
      );
    }
  }

  return false;
};

export const narrowestSuperTypeOf = (
  tys: readonly Ty.Shorthand[]
): Result<
  Ty.ExtendedAttributeType,
  {
    t: 'incompatible-types';
    lhs: Ty.ExtendedAttributeType;
    rhs: Ty.ExtendedAttributeType;
  }
> => {
  const [first, ...rest] = tys.map((ty) => Ty.shorthandToTy(ty));

  Assert.assert(
    first !== undefined,
    '"narrowestSuperTypeOf" doesnt support empty lists'
  );

  let superType: Ty.ExtendedAttributeType = first;

  outer: for (const ty of rest) {
    for (const [l, r] of [
      [ty, superType],
      [superType, ty],
    ] as const) {
      if (implementsTy({ subject: l, req: r })) {
        superType = r;
        continue outer;
      }

      if (implementsTy({ subject: l, req: Ty.makeNullable(r) })) {
        superType = Ty.makeNullable(r);
        continue outer;
      }

      if (l.ty.k === 'enum' && r.ty.k === 'enum') {
        const variants: Set<string> = new Set([...l.ty.t, ...r.ty.t]);

        superType = {
          ty: { k: 'enum', t: Object.freeze([...variants].sort()) },
          nullable: l.nullable || r.nullable,
          tags: _.intersection(l.tags, r.tags),
        };
        continue outer;
      }

      if (l.ty.k === 'range' && r.ty.k === 'range') {
        superType = {
          ty: {
            k: 'range',
            t: 'int',
            start: Math.min(l.ty.start, r.ty.start),
            end: Math.max(l.ty.end, r.ty.end),
          },
          nullable: l.nullable || r.nullable,
          tags: _.intersection(l.tags, r.tags),
        };
        continue outer;
      }

      for (const req of ['int', 'string', 'float'] as const) {
        if ([l, r].every((x) => implementsTy({ subject: x, req }))) {
          superType = {
            ty: { k: 'primitive', t: req },
            nullable: l.nullable || r.nullable,
            tags: _.intersection(l.tags, r.tags),
          };
          continue outer;
        }
      }

      if (l.ty.k === 'struct' && r.ty.k === 'struct') {
        const unified: Record<string, Ty.ExtendedAttributeType> = {};

        const uniqueFieldNames = _.uniq(
          [l.ty.fields, r.ty.fields].flatMap((x) => Object.keys(x))
        );

        for (const name of uniqueFieldNames) {
          const leftField = l.ty.fields[name];
          const rightField = r.ty.fields[name];
          const sup = narrowestSuperTypeOf(_.compact([leftField, rightField]));
          if (sup.isOk()) {
            const anyMissing =
              leftField === undefined || rightField === undefined;
            unified[name] = anyMissing ? Ty.makeNullable(sup.value) : sup.value;
          } else {
            return err({
              t: 'incompatible-types',
              lhs: ty,
              rhs: superType,
            });
          }
        }

        superType = {
          ty: { k: 'struct', fields: unified },
          nullable: l.nullable || r.nullable,
          tags: _.intersection(l.tags, r.tags),
        };
        continue outer;
      }
    }

    return err({
      t: 'incompatible-types',
      lhs: ty,
      rhs: superType,
    });
  }

  return ok(Object.freeze(superType));
};

export const implementsRel = (params: {
  subject: { readonly [attr: string]: Ty.Shorthand };
  reqs: RelInterfaceShorthand;
}): Result<true, TypeCheckError> => {
  for (const [attrName, { allowed, optional }] of Object.entries(
    relShorthandInterfaceToFull(params.reqs)
  )) {
    const subAttr = params.subject[attrName];

    if (subAttr === undefined) {
      if (optional) {
        continue;
      }

      return err(
        new InvalidTypeForAttribute({
          name: attrName,
          wanted: allowed,
          actual: null,
        })
      );
    }

    if (
      allowed !== 'any' &&
      !allowed.some((req) => implementsTy({ subject: subAttr, req }))
    ) {
      return err(
        new InvalidTypeForAttribute({
          name: attrName,
          actual: Ty.shorthandToTy(subAttr),
          wanted: allowed,
        })
      );
    }
  }

  return ok(true);
};

const TIMESTAMP_HEIRARCHY = ['year', 'month', 'day', 'timestamp'] as const;
const implsTimeLike = (p: {
  subject: Ty.PrimitiveTimeType;
  reqs: Ty.PrimitiveTimeType;
}): boolean =>
  TIMESTAMP_HEIRARCHY.indexOf(p.subject) <= TIMESTAMP_HEIRARCHY.indexOf(p.reqs);
const isTimeLike = (x: Ty.PrimitiveAttributeType): x is Ty.PrimitiveTimeType =>
  (TIMESTAMP_HEIRARCHY as readonly string[]).includes(x);
