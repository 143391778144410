import {
  UI,
  AST,
  f,
  MakeStruct,
  Page,
  Relation,
  From,
  Case,
  UnionAll,
} from '@cotera/era';
import {
  GLASSDOOR_REVIEWS,
  SCRAPER_PRODUCT_REVIEWS,
  SCRAPER_REVIEW_TAGS,
  SIMILARWEB_COMPETITORS,
} from '../../assumptions';

export const AllCompanyReviews = From(SCRAPER_PRODUCT_REVIEWS)
  .leftJoin(From(SCRAPER_REVIEW_TAGS), (a, t) => ({
    on: a.attr('ID').eq(t.attr('REVIEW_ID')),
    select: {
      ...a.star(),
      ...t.star(),
      SOURCE: Case([
        {
          when: a.attr('SOURCE').eq('reddit'),
          then: 'forum',
        },
        {
          when: a.attr('SOURCE').eq('trustpilot'),
          then: 'review site',
        },
        {
          when: a.attr('SOURCE').eq('google product reviews'),
          then: 'product specific reviews',
        },
        {
          when: a.attr('SOURCE').eq('app_store'),
          then: 'app reviews',
        },
      ]),
      COMPANY_NAME: a.attr('DOMAIN').replace('.com', ''),
    },
  }))
  .where((t) => t.attr('REVIEW_ID').isNotNull());

export const CompanyCompetitors = UnionAll([
  From(SIMILARWEB_COMPETITORS).select((t) => ({
    ...t.pick('DOMAIN'),
    COMPETITOR: t.attr('COMPETITOR_1'),
  })),
  From(SIMILARWEB_COMPETITORS).select((t) => ({
    ...t.pick('DOMAIN'),
    COMPETITOR: t.attr('COMPETITOR_2'),
  })),
]);

export const CompanyList = UnionAll([
  AllCompanyReviews.select((t) => t.pick('COMPANY_NAME', 'DOMAIN'), {
    distinct: true,
  }),
  From(GLASSDOOR_REVIEWS).select((t) => ({
    COMPANY_NAME: t.attr('DOMAIN').replace('.com', ''),
    ...t.pick('DOMAIN'),
  })),
]).select((t) => t.star(), { distinct: true });

export const GlassDoorReviews = From(GLASSDOOR_REVIEWS);

export const CompanyReviewOverview = ({
  CompanyReviews,
}: {
  CompanyReviews: Relation;
}): AST._App => {
  return UI.App({
    title: 'Company Overview',
    icon: 'bar-chart',
    pages: {
      '/': Page([], () => {
        return [
          UI.Header({
            title: 'Company List',
          }),
          UI.Block([
            CompanyReviews.select(
              (t) => ({
                ...t.pick('COMPANY_NAME'),
                COMPANY_DETAIL_PAGE: MakeStruct({
                  __link: f`/apps/company-detail/${t.attr('COMPANY_NAME')}/`,
                  text: f`${t.attr('COMPANY_NAME')} details`,
                  action: 'tab',
                }),
                COMPANY_URL: MakeStruct({
                  __link: f`https://${t.attr('DOMAIN')}`,
                  text: f`https://${t.attr('DOMAIN')}`,
                  action: 'tab',
                }),
              }),
              { distinct: true }
            ),
          ]),
        ];
      }),
    },
    menu: [],
  });
};
