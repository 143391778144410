import { round } from 'lodash';

export const format = (value: number | string): number => {
  const v = String(value);
  const [whole, decimal] = v.split('.');

  if (whole === undefined) {
    return 0;
  }
  if (whole.length >= 3 || !decimal) {
    const d = Number(`0.${decimal ?? 0}`);
    return round(Number(whole) + d, 0);
  }
  if (whole.length === 2) {
    const d = String(round(Number(`0.${decimal}`), 1));

    return Number(`${whole}.${d.split('.')[1] ?? 0}`);
  }
  const d = String(round(Number(`0.${decimal}`), 2));
  return Number(`${whole}.${d.split('.')[1]}`);
};
