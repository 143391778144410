import React, { useRef } from 'react';
import { StoreApi } from 'zustand';
import { createDataStore } from './store';

type SetterWithState<T> = (fn: (state: T) => Partial<T>) => void;

export type StateSetter<T> = SetterWithState<T>;
export type StateGetter<T, K = any> = () => T & { actions: K };

export type StoreFn<T, K> = (
  state: T,
  actions: (set: StateSetter<T>, get: StateGetter<T, K>) => K
) => StoreApi<
  T & {
    actions: K;
  }
>;

export function makeStoreProvider<
  T,
  K extends Record<string, (...args: any) => void> = ReturnType<() => {}>
>(ctx: React.Context<T>, storeFactory: StoreFn<T, K> = createDataStore) {
  return function StoreProvider(props: {
    state: T;
    actions: (set: StateSetter<T>, get: StateGetter<T, K>) => K;
    children: React.ReactNode | React.ReactNode[];
  }) {
    const store = useRef(storeFactory(props.state, props.actions));

    return (
      <ctx.Provider value={store.current as any}>{props.children}</ctx.Provider>
    );
  };
}
