import { classNames } from '@cotera/client/app/components/utils';
import { Button } from '../button';
import { useState } from 'react';
import { Inputs } from '@cotera/client/app/components/forms';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';

type Props = {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  children: (value: string) => React.ReactNode;
};

export const Editable: React.FC<Props> = ({
  className,
  value,
  onChange,
  children,
}) => {
  const [editValue, setEditValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  useKeyPress('Escape', () => {
    setIsEditing(false);
    onChange('');
  });

  useKeyPress('Enter', () => {
    if (hasFocus) {
      onChange(editValue);
      setIsEditing(false);
      setHasFocus(false);
    }
  });

  return (
    <div
      className={classNames('flex items-center', className)}
      onClick={() => {
        setIsEditing(true);
        setHasFocus(true);
      }}
    >
      {!isEditing && children(value)}
      {isEditing && (
        <>
          <Inputs.Text
            onBlur={() => {
              setHasFocus(false);
              setIsEditing(false);
            }}
            icon="edit"
            autoFocus
            className="w-full"
            placeholder="Search"
            value={editValue}
            onChange={(v) => {
              setHasFocus(true);
              setEditValue(v);
            }}
          />
          <Button
            icon="close"
            iconOnly
            text="Cancel"
            theme="error"
            inline
            onClick={() => {
              setIsEditing(false);
              onChange('');
            }}
          />
        </>
      )}
    </div>
  );
};
