import React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { InputProps, SingleError } from './input.props';
import { ZodInput } from './input';
import { Label } from '@cotera/client/app/components/ui';

export const ArrayInput: React.FC<
  InputProps<z.ZodArray<any>> & { error?: SingleError }
> = ({ zodSchema, name, wrapper, className, hideOptionals }) => {
  const { setValue } = useFormContext();
  const [elements, setElements] = useState<any[]>([]);

  const addRow = (element: React.ReactNode) => {
    setElements([...elements, element]);
  };

  const removeRow = (index: number) => {
    const updatedElements = elements.filter((_, i) => i !== index);
    setElements(updatedElements);
    setValue(name, updatedElements);
  };

  const optional = zodSchema instanceof z.ZodOptional;
  const unwrapped = optional ? zodSchema.unwrap() : zodSchema;

  return (
    <div className="col-span-6 flex flex-col">
      <Label />
      {elements.map((element, index) => {
        return (
          <div key={index} className="flex flex-row">
            {element}
            <div className="flex flex-col">
              <div className="flex-grow" />
              <button
                type="button"
                className="my-auto flex-shrink rounded bg-red-600 p-2 text-alt-text shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => removeRow(index)}
              >
                <MinusIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        );
      })}
      <div className="flex flex-row mt-2">
        <button
          type="button"
          className="flex-shrink rounded bg-primary-background p-2 text-alt-text shadow-sm hover:bg-primary-background focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() =>
            addRow(
              <ZodInput
                className={className}
                wrapper={wrapper as any}
                name={`${name}.${elements.length}`}
                zodSchema={unwrapped}
                optional={false}
                hideOptionals={hideOptionals}
              />
            )
          }
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};
