import { z } from 'zod';
import { Contract, POST } from '@cotera/contracts';

export const MagicLinkCreateInputSchema = z.object({
  email: z.string().email(),
});

export type MagicLinkCreateInput = z.infer<typeof MagicLinkCreateInputSchema>;

export const SuperAdminEmailsContract = Contract.new({
  signIn: POST({
    params: MagicLinkCreateInputSchema,
    output: z.object({}),
    errors: z.object({
      errorType: z.enum(['EmailNotFound']),
    }),
  }),
  sendWelcomeEmail: POST({
    params: MagicLinkCreateInputSchema,
    output: z.object({}),
    errors: z.object({
      errorType: z.enum([
        'UserAlreadyExists',
        'InvalidEmail',
        'UserWouldNotBePartOfAnOrg',
      ]),
    }),
  }),
  sendMagicLink: POST({
    params: MagicLinkCreateInputSchema,
    output: z.object({}),
    errors: z.object({ errorType: z.literal('EmailNotFound') }),
  }),
});
