import { ArtifactRequest } from '@cotera/api';
import { Text } from '@cotera/client/app/components';
import { DataGrid } from '@cotera/client/app/components/data-vis';
import { Button, Loading } from '@cotera/client/app/components/ui';
import {
  useArtifactRequests,
  useInvalidateArtifactById,
} from '@cotera/client/app/hooks/artifacts';
import { Ty } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { createColumn } from '@cotera/client/app/components/data-vis/data-grid/header';
import { Card } from '@cotera/client/app/components/headless';
import { Layout } from '@cotera/client/app/layout';

export const Artifacts = () => {
  // TODO: Figure out how to use `page` with <DataGrid />
  const reqs = useArtifactRequests({ page: 1 });

  return (
    <Layout>
      <div className="w-full p-4 flex flex-col">
        <Card.Container>
          <Card.Header>
            <Card.Title>Artifact Cache </Card.Title>
            <Text.Caption className="text-sm mt-2 space-y-4">
              <div>
                Recently run warehouse queries that the system can use to serve
                related queries without returning to the warehouse
              </div>
              {reqs.isFetching ? (
                <Loading.Spinner />
              ) : (
                <Button text="Refresh" onClick={() => reqs.refetch()} />
              )}
            </Text.Caption>
          </Card.Header>
          <Card.Content>
            {reqs.data ? <ListArtifacts reqs={reqs.data} /> : <Loading.Dots />}
          </Card.Content>
        </Card.Container>
      </div>
    </Layout>
  );
};
const ListArtifacts: React.FC<{ reqs: ArtifactRequest[] }> = ({ reqs }) => {
  const mapped = sortBy(
    reqs.map((req) => {
      const status =
        req.fullfillment !== null
          ? 'Fullfilled'
          : req.failure !== null
          ? 'Failed'
          : 'Pending';

      return {
        id: req.id,
        Hash: req.sqlHash,
        'Requested At': new Date(req.createdAt),
        Status: status,
        'Invalidated At': req.invalidatedAt
          ? new Date(req.invalidatedAt)
          : req.invalidatedAt,
      };
    }),
    (x) => x['Requested At']
  ).reverse();

  return (
    <DataGrid
      totalRows={mapped.length}
      data={mapped}
      columnTypes={{}}
      columns={[
        createColumn({
          data: { name: 'id', ty: Ty.ty('string'), detailPages: {} },
          cell: ({ cell }) => {
            const id = cell.getValue();
            Assert.assert(typeof id === 'string');
            return (
              <Link to={id} className="text-indigo-700 hover:text-indigo-900">
                {id}
              </Link>
            );
          },
        }),
        createColumn({
          data: { name: 'Hash', ty: Ty.ty('string'), detailPages: {} },
        }),
        createColumn({
          data: {
            name: 'Requested At',
            ty: Ty.ty('timestamp'),
            detailPages: {},
          },
        }),
        createColumn({
          data: { name: 'Status', ty: Ty.ty('string'), detailPages: {} },
        }),
        createColumn({
          data: {
            name: 'Invalidated At',
            ty: Ty.ty('timestamp'),
            detailPages: {},
          },
          cell: ({ cell, row }) => {
            const val = cell.getValue();
            if (val instanceof Date) {
              return <div>{val.toISOString()}</div>;
            }
            const id = row.getValue('id');
            Assert.assert(typeof id === 'string');
            return <InvalidateCell reqId={id} />;
          },
        }),
      ]}
    />
  );
};

const InvalidateCell: React.FC<{ reqId: string }> = ({ reqId }) => {
  const invalidate = useInvalidateArtifactById({ id: reqId });
  if (invalidate.isPending) {
    return <Loading.Spinner />;
  }
  return (
    <Button
      text={'Invalidate'}
      theme="error"
      onClick={() => invalidate.mutate()}
    />
  );
};
