import {
  ExprVar,
  If,
  M,
  Markup,
  Relation,
  RowNumberOver,
  Sum,
} from '@cotera/era';

export const DeprecatedDetailTool = (
  slice: Relation,
  measure: ExprVar,
  barChartMeasures: string[],
  monthCountChartMeasures: string[],
  monthSumChartMeasures: string[],
  pieChartMeasures: string[]
): Markup => {
  return M.muCase([
    ...pieChartMeasures.map((m) => {
      return {
        when: measure.eq(m),
        then: slice
          .countBy((t) => ({
            [m]: t.attr(m).cast('string'),
          }))
          .chart.PieChart((t) => ({
            value: t.attr('COUNT'),
            category: t.attr(m),
          })),
      };
    }),
    ...barChartMeasures.map((m) => {
      return {
        when: measure.eq(m),
        then: slice
          .countBy((t) => ({
            [m]: t.attr(m).cast('string'),
          }))
          .select((t) => ({
            ...t.star(),
            RANK: RowNumberOver({ orderBy: t.attr('COUNT').desc() }),
          }))
          .select((t) => ({
            ...t.star(),
            [m]: If(t.attr('RANK').lt(10), {
              then: t.attr(m),
              else: 'All other categories',
            }),
          }))
          .groupBy((t) => t.pick(m))
          .select((t) => ({
            ...t.group(),
            COUNT: Sum(t.attr('COUNT')),
          }))
          .chart.BarChart((t) => ({
            y: t.attr('COUNT'),
            x: t.attr(m),
            category: t.attr(m),
          })),
      };
    }),
    ...monthCountChartMeasures.map((m) => {
      return {
        when: measure.eq(m),
        then: slice
          .countBy((t) => ({
            [m]: t.attr(m).cast('string'),
            DETAIL_MONTH: t.attr('created_at').dateTrunc('month'),
          }))
          .leftJoin(
            slice
              .countBy((t) => ({
                [m]: t.attr(m).cast('string'),
              }))
              .select((t) => ({
                ...t.star(),
                RANK: RowNumberOver({ orderBy: t.attr('COUNT').desc() }),
              })),
            (month, total) => ({
              on: month.attr(m).eq(total.attr(m)),
              select: {
                ...month.star(),
                [m]: If(total.attr('RANK').lt(10), {
                  then: month.attr(m),
                  else: 'All other categories',
                }),
              },
            })
          )
          .groupBy((t) => t.pick(m, 'DETAIL_MONTH'))
          .select((t) => ({
            ...t.group(),
            COUNT: Sum(t.attr('COUNT')),
          }))
          .chart.LineChart(
            (t) => ({
              y: t.attr('COUNT'),
              x: t.attr('DETAIL_MONTH'),
              category: t.attr(m),
            }),
            {
              axis: {
                x: {
                  scale: 'month',
                },
              },
            }
          ),
      };
    }),
    ...monthSumChartMeasures.map((m) => {
      return {
        when: measure.eq(m),
        then: slice
          .groupBy((t) => ({
            DETAIL_MONTH: t.attr('created_at').dateTrunc('month'),
          }))
          .select((t) => ({
            ...t.group(),
            TOTAL: Sum(t.attr(m)),
          }))
          .chart.BarChart(
            (t) => ({
              x: t.attr('DETAIL_MONTH'),
              y: t.attr('TOTAL'),
              category: measure,
            }),
            {
              axis: {
                x: {
                  scale: 'month',
                },
              },
            }
          ),
      };
    }),
  ]);
};
