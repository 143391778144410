import { chunk, groupBy, sortBy } from 'lodash';
import { CSSProperties, useState } from 'react';

import { ChartContext, makeChartContextHook } from '../context';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { Loading } from './loading';
import { EmptyChart } from '../empty';
import { If, Text } from '@cotera/client/app/components/ui';
import {
  classNames,
  COLORS,
  makeValueFormatter,
  UTCDate,
  YELLOW_200,
} from '@cotera/client/app/components/utils';
import { Axis, isDateAxis } from '../utils';
import { ChartProps } from '../types';

type Datum = {
  x: string;
  value: number;
  y: string;
};

const Color: keyof (typeof COLORS)[200] = 'Indigo';

const DEFAULT_COLORS = [
  COLORS[200][Color],
  COLORS[300][Color],
  COLORS[400][Color],
  COLORS[500][Color],
  COLORS[600][Color],
];

const useTypedChartContext = makeChartContextHook<Datum>();

type Config = {
  axis: {
    x?: Axis;
    y?: Axis;
  };
  colors?: {
    color: keyof (typeof COLORS)[200];
    shade: keyof typeof COLORS;
  }[];
};

export type Props = {
  loading?: boolean;
} & Config &
  ChartProps<Datum>;

export const HeatmapChart: React.FC<Props> = ({
  data,
  axis,
  format,
  loading,
  colors = [],
  chartHeight,
  ...layoutProps
}) => {
  return (
    <ChartContext data={data} labelKeys={['y']}>
      <ChartLayout {...layoutProps} menuItems={DEFAULT_MENU_ITEMS} hideLabels>
        <If condition={loading ?? false}>
          <div
            style={{ height: chartHeight }}
            className="flex flex-col w-full justify-center items-center"
          >
            <Loading />
          </div>
        </If>
        <If condition={data.length === 0 && !loading}>
          <EmptyChart />
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart
            axis={axis}
            format={format}
            colors={colors}
            chartHeight={chartHeight}
          />
        </If>
      </ChartLayout>
    </ChartContext>
  );
};

const Chart: React.FC<Omit<Props, 'data'>> = ({
  axis,
  format,
  colors,
  chartHeight,
}) => {
  const data = useTypedChartContext((s) => s.data);
  const [hovering, setHovering] = useState<Datum | null>(null);
  const NUM_SEGMENTS = 5;
  const coloredData = chunk(
    sortBy(data, (x) => x.value),
    Math.ceil(data.length / NUM_SEGMENTS)
  ).map((chunk, i) => {
    const color =
      colors !== undefined && colors[i] !== undefined
        ? COLORS[colors[i]!.shade][colors[i]!.color]
        : DEFAULT_COLORS[i];
    return chunk.map((datum) => {
      return {
        ...datum,
        color,
      };
    });
  });
  const xValues = sortBy([...new Set(data.map((d) => d.x))]);

  const grouped = groupBy(coloredData.flat(), (d) => d.y);
  const yValues = Object.keys(grouped);
  yValues.forEach((y) => {
    const data: {
      color: string | undefined;
      x: string;
      value: number | null;
      y: string;
    }[] = grouped[y]!;
    const missingXValues = xValues.filter((x) => !data.find((d) => d.x === x));
    missingXValues.forEach((x) => {
      data.push({
        x,
        value: null,
        y,
        color: YELLOW_200,
      });
    });
  });
  const CHAR_SIZE = 10;
  const LABEL_PADDING_RIGHT = 10;
  const maxCategoryLength = Math.min(
    Math.max(...yValues.map((c) => c.length)),
    60
  );
  const yLabelWidth = maxCategoryLength * CHAR_SIZE + LABEL_PADDING_RIGHT;
  const valueFormatter = makeValueFormatter(format?.['value'] ?? 'number');
  const yFormatter = makeValueFormatter(format?.['y']);
  const ySort = makeYSortingFn(axis?.y);
  const xSort = makeXSortingFn(axis?.x);

  return (
    <div
      style={{
        minHeight: chartHeight,
      }}
      onMouseLeave={() => setHovering(null)}
      className="flex flex-row w-full h-full text-xs text-standard-text mt-5 mr-6 mb-4"
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-center w-full">
          <Text.Caption className="font-semibold mb-3">
            {axis.x?.label}
          </Text.Caption>
        </div>
        <div className="flex flex-row">
          <div
            style={{
              width: yLabelWidth,
            }}
            className="rounded text-center py-3 mr-2"
          >
            <Text.Caption className="font-semibold">
              {axis.y?.label}
            </Text.Caption>
          </div>
          {xSort(xValues, (d) => d).map((xValue) => {
            return (
              <div
                className={
                  'px-4 py-3 mr-1 text-center flex-grow flex-1 basis-0 shrink-0 min-w-0 text-ellipsis -rotate-12'
                }
              >
                {String(xValue)}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col w-full h-full">
          {ySort(yValues).map((y) => {
            return (
              <div
                className={'flex flex-row w-full flex-grow flex-1'}
                style={{
                  minHeight: 20,
                }}
              >
                <div
                  className="flex items-center justify-end rounded px-4 py-3 mr-2 mb-1 text-left text-ellipsis"
                  style={{ width: yLabelWidth }}
                >
                  {yFormatter(y)}
                </div>
                {xSort(grouped[String(y)]!, (d) => d.x).map((datum) => {
                  return (
                    <Cell
                      className={classNames(
                        hovering !== null &&
                          hovering.y !== y &&
                          hovering.x !== datum.x
                          ? 'transition ease-in-out opacity-40'
                          : '',
                        'flex-grow flex-1 basis-0 shrink-0 min-w-0'
                      )}
                      onHover={(hovering) => setHovering(hovering)}
                      style={{ background: datum.color }}
                      datum={datum}
                      formatter={valueFormatter}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="flex flex-row justify-end mt-3 mb-6">
          {coloredData.map((data) => {
            return (
              <div className="flex flex-col  mr-1">
                <div
                  style={{
                    background: data.at(0)?.color,
                    maxWidth: 120,
                  }}
                  className="rounded h-3"
                ></div>
                <div className="flex flex-row justify-center items-center mt-1">
                  <span className="mr-2">
                    {valueFormatter(Math.min(...data.map((d) => d.value)))}
                  </span>{' '}
                  -{' '}
                  <span className="ml-2">
                    {valueFormatter(Math.max(...data.map((d) => d.value)))}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Cell = ({
  datum,
  style,
  formatter,
  onHover,
  className,
}: {
  datum: Datum;
  className?: string;
  style: CSSProperties;
  formatter: (value: any) => string;
  onHover: (hovering: Datum | null) => void;
}) => {
  const background = datum.value === null ? undefined : style.background;
  const displayValue = datum.value === null ? '' : formatter(datum.value);

  return (
    <div
      onMouseEnter={() => onHover(datum)}
      style={{ ...style, background }}
      className={classNames(
        'text-ellipsis cursor-pointer text-gray-100 rounded px-4 py-3 bg-secondary-background mr-1 mb-1 flex items-center justify-center',
        datum.value === null
          ? 'pattern-diagonal-lines pattern-indigo-300 pattern-size-2 pattern-bg-tertiary-background text-standard-text'
          : '',
        className
      )}
    >
      {displayValue}
    </div>
  );
};

function makeYSortingFn<T extends string | number | Date>(axis?: Axis) {
  if (isDateAxis(axis)) {
    return (data: T[]) => sortBy(data, (datum) => new UTCDate(datum).getTime());
  }

  if (axis?.scale === 'linear') {
    return (data: T[]) => sortBy(data, (datum) => Number(datum));
  }

  return (data: T[]) => data;
}

function makeXSortingFn(axis?: Axis) {
  if (isDateAxis(axis)) {
    return function <T>(
      data: T[],
      projection: (datum: T) => string = (datum) => String(datum)
    ) {
      return sortBy(data, (datum) => new UTCDate(projection(datum)).getTime());
    };
  }

  if (axis?.scale === 'linear') {
    return function <T>(
      data: T[],
      projection: (datum: T) => string = (datum) => String(datum)
    ) {
      return sortBy(data, (datum) => Number(projection(datum)));
    };
  }

  return function <T>(
    data: T[],
    projection: (datum: T) => string = (datum) => String(datum)
  ) {
    return sortBy(data, (datum) => String(projection(datum)));
  };
}
