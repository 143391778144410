import { Alert, Button } from '@cotera/client/app/components';
import React from 'react';
import { NodeCard } from './components/node';
import { sortBy } from 'lodash';
import { useSubscribe, Watchable } from '@cotera/client/app/etc';
import { BaseWorkspaceNodeViewModel } from './nodes/base';
import { DatasetNode, DatasetViewModel } from './nodes/dataset';
import { GroupByNode, GroupByViewModel } from './nodes/group';
import { FilterNode, FilterNodeViewModel } from './nodes/filter';
import { SampleNode, SampleViewModel } from './nodes/sample';
import { ChartNode, ChartViewModel } from './nodes/chart';
import { classNames } from '@cotera/client/app/components/utils';

type Overrides = Record<
  string,
  (props: { node: BaseWorkspaceNodeViewModel }) => React.ReactNode
>;

export const WorkspaceNodes: React.FC<{
  vm: WorkspaceViewModel;
  overrides?: Overrides;
}> = ({ vm, overrides }) => {
  const nodes = useSubscribe(
    vm,
    (s) => s.nodes,
    (a, b) => {
      return Object.keys(a).length === Object.keys(b).length;
    }
  );

  return (
    <div className="relative w-full flex flex-col h-full pb-4">
      {sortBy(Object.values(nodes), (x) => x.position.y).map((node) => (
        <NodeWrapper
          key={node.id}
          workspace={vm}
          node={node}
          overrides={overrides}
        />
      ))}
    </div>
  );
};

const NodeWrapper: React.FC<{
  node: BaseWorkspaceNodeViewModel;
  workspace: WorkspaceViewModel;
  overrides?: Overrides;
}> = ({ node, workspace, overrides }) => {
  const [showNodeSelector, setShowNodeSelector] = React.useState(false);

  return (
    <>
      {node.t !== 'dataset' ? (
        <NodeCard key={node.id} name={node.name}>
          <Node key={node.id} node={node} overrides={overrides} />
        </NodeCard>
      ) : (
        <Node key={node.id} node={node} />
      )}
      <div
        className={classNames(
          'flex w-full items-center relative justify-center pb-1 -mt-2 transition-opactity duration-300 ease-in-out',
          showNodeSelector ? 'opacity-100' : 'hover:opacity-100 opacity-0'
        )}
      >
        {
          <Button
            className={classNames(
              'absolute left-[50%]',
              showNodeSelector ? 'hidden' : ''
            )}
            icon="add"
            onClick={() => {
              setShowNodeSelector(true);
            }}
            tooltip="top"
            iconOnly
            text="Add Node"
            small
            inline
          />
        }
        {
          <div
            className={classNames(
              showNodeSelector ? 'h-[45px] mt-2' : 'h-[20px]',
              'flex space-x-2 justify-center ease-in-out overflow-hidden transition-height duration-300'
            )}
          >
            {showNodeSelector && (
              <>
                <Button
                  icon="filter"
                  text="Filter"
                  compact
                  onClick={() => {
                    workspace.addNode(
                      new FilterNodeViewModel(
                        'Filter',
                        { x: 0, y: node.position.y + 1 },
                        { connective: 'and', items: [] },
                        node,
                        {
                          useAutoFilters: false,
                        }
                      )
                    );
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="link"
                  text="Group By"
                  compact
                  onClick={() => {
                    workspace.addNode(
                      new GroupByViewModel(
                        'Group By',
                        { x: 0, y: node.position.y + 1 },
                        { groupBy: [], select: {} },
                        node
                      )
                    );
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="chart-bar"
                  text="Chart"
                  compact
                  onClick={() => {
                    workspace.addNode(
                      new ChartViewModel(
                        'Chart',
                        { x: 0, y: node.position.y + 1 },
                        node
                      )
                    );
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="table-cells"
                  text="Table"
                  compact
                  onClick={() => {
                    workspace.addNode(
                      new SampleViewModel(
                        'Sample',
                        { x: 0, y: node.position.y + 1 },
                        node,
                        {
                          syncFilters: true,
                        }
                      )
                    );
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="close"
                  iconOnly
                  text="Close"
                  compact
                  inline
                  tooltip="right"
                  onClick={() => {
                    setShowNodeSelector(false);
                  }}
                />
              </>
            )}
          </div>
        }
      </div>
    </>
  );
};

const Node: React.FC<{
  node: BaseWorkspaceNodeViewModel;
  overrides?: Overrides;
}> = ({ node, overrides = {} }) => {
  if (overrides[node.t] !== undefined) {
    return overrides[node.t]!({ node });
  }

  switch (node.t) {
    case 'filter':
      return (
        <FilterNode.View node={node as FilterNodeViewModel} key={node.id} />
      );
    case 'sample':
      return <SampleNode.View vm={node as SampleViewModel} key={node.id} />;
    case 'group-by':
      return <GroupByNode.View node={node as GroupByViewModel} />;
    case 'dataset':
      return <DatasetNode.View node={node as DatasetViewModel} />;
    case 'chart':
      return <ChartNode.View node={node as ChartViewModel} />;
    default:
      return (
        <Alert
          variant="warn"
          //@ts-ignore
          detail={`Unimplemented for type ${node.t}`}
          message={''}
        />
      );
  }
};

export class WorkspaceViewModel extends Watchable {
  nodes: Record<string, BaseWorkspaceNodeViewModel> = {};

  constructor(nodes: BaseWorkspaceNodeViewModel[] = []) {
    super();
    this.nodes = nodes.reduce((acc, node) => {
      acc[node.id] = node;
      return acc;
    }, {} as Record<string, BaseWorkspaceNodeViewModel>);
  }

  addNode(node: BaseWorkspaceNodeViewModel) {
    this.nodes = {
      ...this.nodes,
      [node.id]: node,
    };

    this.notifySubscribers();
  }
}
