import { Layout } from '../../layout';
import { AvailableDefinitions } from './available-definitions';

export const ExploreSelector = () => {
  return (
    <Layout>
      <AvailableDefinitions />
    </Layout>
  );
};
