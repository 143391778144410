import { IconName } from '@cotera/client/app/components/ui';
import { useState } from 'react';

export const useTextHinter = (
  options: (string | { value: string; icon?: IconName })[],
  trigger: string = '/'
) => {
  const displayOptions = options.map((option) => {
    if (typeof option === 'string') {
      return { value: option };
    }
    return option;
  });
  const [filteredOptions, setFilteredOptions] = useState(displayOptions);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const handleChange = (currentValue: string, cursorPosition: number) => {
    const beforeCursor = currentValue.slice(0, cursorPosition);

    //get word at current cursor position
    const word = beforeCursor.split(' ').pop();

    if (word?.startsWith(trigger)) {
      const target = word.slice(trigger.length);
      setFilteredOptions(
        displayOptions.filter((option) =>
          option.value.toLowerCase().includes(target.toLowerCase())
        )
      );
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  return {
    handleChange,
    options: filteredOptions,
    showOptions,
    close: () => setShowOptions(false),
  };
};
