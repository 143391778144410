import {
  Constant,
  Count,
  ExprVar,
  From,
  M,
  Page,
  Relation,
  SumOver,
  Ty,
  UI,
} from '@cotera/era';
import { CategoricalAttributes } from '@cotera/sdk/core';
import { DateTime } from 'luxon';

const Details = (slice: Relation) => {
  return [
    UI.Divider(),
    UI.Header({ title: 'Data' }),
    UI.Row([
      UI.Third([
        slice
          .groupBy((t) => t.pick('category'))
          .select((t) => ({
            category: t.attr('category'),
            count: Count(),
          }))
          .chart.PieChart(
            (t) => ({
              category: t.attr('category'),
              value: t.attr('count'),
            }),
            {
              title: 'Overall category distribution',
              labels: false,
            }
          ),
      ]),
      UI.TwoThirds([
        slice
          .groupBy((t) => t.pick('category', 'sub_category'))
          .select((t) => ({
            category: t.attr('category'),
            sub_category: t.attr('sub_category'),
            count: Count(),
          }))
          .chart.BarChart(
            (t) => ({
              category: t.attr('category'),
              y: t.attr('count'),
              x: t.attr('sub_category'),
            }),
            {
              title: 'Distribution by sub_category',
            }
          ),
      ]),
    ]),
    UI.Divider(),
    slice.select((t) => t.except('embedding')),
  ];
};

const CountView = (slice: Relation) => {
  const groupBy = 'sub_category';
  return [
    UI.Row([
      UI.TwoThirds(
        slice
          .groupBy((x) => ({
            month: x.attr('timestamp').dateTrunc('month'),
            [groupBy]: x.attr(groupBy),
          }))
          .select((t) => ({
            month: t.attr('month'),
            [groupBy]: t.attr(groupBy),
            count: Count(),
          }))
          .chart.BarChart(
            (t) => ({
              x: t.attr('month'),
              y: t.attr('count'),
              category: t.attr(groupBy),
            }),
            {
              title: `Monthly Breakdown by ${groupBy}`,
              axis: {
                x: {
                  scale: 'month',
                },
              },
            }
          )
      ),
      SideStats(slice, groupBy),
    ]),
    Details(slice),
  ];
};

const SideStats = (slice: Relation, groupBy: string) => {
  return UI.Third([
    CategoricalAttributes(slice, (t) => t.attr(groupBy), {
      discreteThreshold: 15,
    }).chart.SummaryChart(
      (t) => ({
        value: t.attr('count'),
        category: t.attr('value'),
      }),
      { title: `Breakdown by ${groupBy} for all time` }
    ),
  ]);
};

const PercentView = (slice: Relation) => {
  const groupBy = 'sub_category';
  return [
    UI.Row([
      UI.TwoThirds(
        slice
          .groupBy((x) => ({
            month: x.attr('timestamp').dateTrunc('month'),
            [groupBy]: x.attr(groupBy),
          }))
          .select((t) => ({
            month: t.attr('month'),
            [groupBy]: t.attr(groupBy),
            count: Count(),
          }))
          .select((t) => ({
            ...t.star(),
            percent: t
              .attr('count')
              .safeDiv(
                SumOver(t.attr('count'), { partitionBy: t.attr('month') })
              ),
          }))
          .chart.BarChart(
            (t) => ({
              x: t.attr('month'),
              y: t.attr('percent'),
              category: t.attr(groupBy),
            }),
            {
              title: `Monthly Breakdown by ${groupBy} (%)`,
              axis: {
                x: {
                  scale: 'month',
                },
              },
            }
          )
      ),
      SideStats(slice, groupBy),
    ]),
    Details(slice),
  ];
};

const SearchResults = (slice: Relation) => {
  return [
    slice.chart.SemanticSearchResults(
      (t) => ({
        id: t.attr('id'),
        value: t.attr('value'),
        timestamp: t.attr('timestamp'),
        similarity: t.attr('similarity'),
      }),
      {
        title: 'Search Results',
      }
    ),
  ];
};

const showBreakdownBy = (slice: Relation, tab: ExprVar) => {
  return [
    UI.TabsV2(tab, [
      UI.Stats([
        slice
          .summary((_t) => ({
            value: Count(),
          }))
          .chart.Stat((t) => t.pick('value'), {
            title: 'Total Tickets',
          }),
      ]),
      M.muCase([
        {
          when: tab.eq('Count'),
          then: CountView(slice),
        },
        {
          when: tab.eq('Percent'),
          then: PercentView(slice),
        },
        {
          when: tab.eq('Search Results'),
          then: SearchResults(slice),
        },
      ]),
    ]),
  ];
};

const Chipotle = From({
  uri: 'https://storage.googleapis.com/cotera-static-public-assets/9545ee15-10a8-4f85-a033-adb2c70f3ad1.parquet',
  attributes: {
    ID: 'string',
    SUMMARY: 'string',
    TIMESTAMP: 'timestamp',
    EMBEDDING: Ty.EMBEDDING,
    CATEGORY: Ty.CARD_LT_1000,
    SUB_CATEGORY: Ty.CARD_LT_1000,
  },
})
  .select((t) => ({
    ...t.star(),
    VALUE: t.attr('SUMMARY'),
    SIMILARITY: Constant(0, { ty: Ty.ty('float', { nullable: true }) }),
  }))
  .renameAttributes((n) => n.toLowerCase());

const Starbucks = From({
  uri: 'https://storage.googleapis.com/cotera-static-public-assets/3c4e3f32-b85f-4ad8-bb78-21d913de9014.parquet',
  attributes: {
    ID: 'string',
    SUMMARY: 'string',
    TIMESTAMP: 'timestamp',
    EMBEDDING: Ty.EMBEDDING,
    CATEGORY: Ty.CARD_LT_1000,
    SUB_CATEGORY: Ty.CARD_LT_1000,
  },
})
  .select((t) => ({
    ...t.star(),
    VALUE: t.attr('SUMMARY'),
    SIMILARITY: Constant(0, { ty: Ty.ty('float', { nullable: true }) }),
  }))
  .renameAttributes((n) => n.toLowerCase());

export const Dataset = Page(['dataset'], ({ dataset }) => {
  const rel = M.relCase(
    [
      {
        when: dataset!.eq('chipotle'),
        then: Chipotle,
      },
    ],
    {
      else: Starbucks,
    }
  );

  return [
    UI.State(
      {
        tab: Constant('Count', {
          ty: Ty.e(['Count', 'Percent', 'Search Results']),
        }),
        rel,
        semanticRel: rel,
      },
      ({ rel: sliceRel, semanticRel, tab }) => {
        const rel = Relation.wrap(sliceRel).innerJoin(
          Relation.wrap(semanticRel),
          (left, right) => ({
            on: left.attr('id').eq(right.attr('id')),
            select: {
              ...left.star(),
              similarity: right.attr('similarity'),
            },
          })
        );
        return [
          UI.Header({
            title: 'Cotera Marketplace Risk AI Demo',
            caption: 'A breakdown of the provided text data by category.',
          }),
          M.muCase(
            [
              {
                when: dataset!.eq('chipotle'),
                then: sliceRel.SliceBuilder([
                  {
                    left: 'timestamp',
                    operator: 'between',
                    right: [
                      DateTime.now()
                        .minus({ year: 12 })
                        .startOf('year')
                        .toJSDate(),
                      DateTime.now()
                        .minus({ year: 6 })
                        .startOf('day')
                        .toJSDate(),
                    ],
                    t: 'timestamp',
                  },
                  {
                    left: 'category',
                    operator: 'one-of',
                    right: undefined,
                    t: 'string',
                  },
                  {
                    left: 'sub_category',
                    operator: 'one-of',
                    right: undefined,
                    t: 'string',
                  },
                ]),
              },
            ],
            {
              else: sliceRel.SliceBuilder([
                {
                  left: 'timestamp',
                  operator: 'between',
                  right: [
                    DateTime.now()
                      .minus({ year: 5 })
                      .startOf('year')
                      .toJSDate(),
                    DateTime.now().startOf('day').toJSDate(),
                  ],
                  t: 'timestamp',
                },
                {
                  left: 'category',
                  operator: 'one-of',
                  right: undefined,
                  t: 'string',
                },
                {
                  left: 'sub_category',
                  operator: 'one-of',
                  right: undefined,
                  t: 'string',
                },
              ]),
            }
          ),
          semanticRel.SemanticSearch(),
          UI.Divider(),
          showBreakdownBy(rel, tab),
        ];
      }
    ),
  ];
});
