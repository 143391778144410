/* eslint-disable @typescript-eslint/unbound-method */
import { Expression } from './expression';
import { Relation } from './relation';
import _ from 'lodash';
import { Avg, Count, Max, Min, PercentileDisc } from './aggregate';
import { TyStackTrace } from '../type-checker/ty-stack-trace';
import { NoSuchAttribute } from '../type-checker/type-check-error';
import type { RelTypeCheck } from '../type-checker/check-rel';
import { EraQL } from '../eraql';
import { Ty } from '../ty';

export const selectingStar = Symbol();

abstract class Ref {
  abstract source: 'left' | 'right' | 'from';

  /*
   * The following code is wrong
   * ```
   * From(tab).select(t => ({ ...t }));
   * ```
   * and should be
   * ```
   * From(tab).select(t => ({ ...t.star() }));
   *
   * This makes the former a type error
   * ```
   */
  _selectingStar = selectingStar;

  // get(attr: string, ...[next, ...rest]: string[]): Expression {
  //   const expr = this.fetchAttribute(attr, { jsStackPointer: this.get });
  //   return next ? expr.getField(next, ...rest) : expr;
  // }

  attr(name: string): Expression {
    return this.fetchAttribute(name, {
      jsStackPointer: this.attr,
    });
  }
  /**
   * `.pick` is a method that takes column names and returns an object
   * containing the attribute references of the specified columns
   * @param names Names of columns to select from referenced relation.
   * @returns The existing column(s) by the same name.
   * @example
   * ```ts
   * rel.select((t) => t.pick('name', 'age'))
   * ```
   */
  pick<Attr extends string = string>(
    ...names: readonly Attr[]
  ): { readonly [Name in Attr]: Expression } {
    return Object.fromEntries(
      names.map((name) => [
        name,
        this.fetchAttribute(name, { jsStackPointer: this.pick }),
      ])
    ) as { [name in Attr]: Expression };
  }

  abstract fetchAttribute(
    name: string,
    opts: { jsStackPointer: Function }
  ): Expression;
}

export class GroupedRelationRef extends Ref {
  public source = 'from' as const;

  constructor(
    private readonly groupedAttributes: readonly string[],
    private readonly relation: Relation
  ) {
    super();
  }

  group(): { [name: string]: Expression } {
    return Object.fromEntries(
      this.groupedAttributes.map((name) => [
        name,
        this.fetchAttribute(name, { jsStackPointer: this.group }),
      ])
    );
  }

  count(): { COUNT: Expression } {
    return { COUNT: Count() };
  }

  minMaxAvg(...names: string[]): { [name: string]: Expression } {
    const opts = { jsStackPointer: this.minMaxAvg };
    return Object.fromEntries(
      names.flatMap((name) => [
        [`${name}_MIN`, Min(this.fetchAttribute(name, opts))],
        [`${name}_MAX`, Max(this.fetchAttribute(name, opts))],
        [`${name}_AVG`, Avg(this.fetchAttribute(name, opts))],
      ])
    );
  }

  percentiles(...names: string[]): { [name: string]: Expression } {
    return Object.fromEntries(
      [10, 25, 50, 75, 90, 99].flatMap((n) =>
        names.map((name) => [
          `${name}_PERCENTILE_${n}`,
          PercentileDisc(
            this.fetchAttribute(name, { jsStackPointer: this.percentiles }),
            n / 100
          ),
        ])
      )
    );
  }

  sql(
    rawCode: readonly string[],
    ...keys: readonly (Ty.Scalar | Expression)[]
  ): Expression {
    const compiler = EraQL.makeQlExpressionCompiler(
      {
        attributes: _.mapValues(this.relation.attributes, (_ty, name) =>
          this.fetchAttribute(name, { jsStackPointer: this.sql })
        ),
      },
      { jsStackPointer: this.sql }
    );

    return compiler(rawCode, ...keys);
  }

  fetchAttribute(name: string, opts: { jsStackPointer: Function }): Expression {
    const ty = this.relation.attributes[name];

    if (ty === undefined) {
      throw TyStackTrace.fromErr(
        { frame: this.relation.ast, location: ['attribute', name] },
        new NoSuchAttribute({
          attributeName: name,
          relation: this.relation,
        })
      ).toError(opts);
    }

    return Expression.fromAst(
      { t: 'attr', name, source: this.source, ty },
      { jsStackPointer: opts.jsStackPointer }
    );
  }
}

export class RelationRef extends Ref {
  constructor(
    private readonly relation: Pick<RelTypeCheck, 'attributes'>,
    readonly source: 'left' | 'right' | 'from'
  ) {
    super();
  }
  /**
   * `.star` is a method used to select all columns from the referenced
   * relation.
   * @returns All columns from the referenced relation.
   * @example
   * ```ts
   * rel.select((t) => ({...t.star(), new_column: t.attr('column').add(1)}))
   * ```
   * @remarks
   * {@link https://github.com/coterahq/learn-nasty-by-example/blob/main/src/01_the-basics.test.ts#L29 | Learn by example}
   */
  star(): { readonly [name: string]: Expression } {
    return Object.fromEntries(
      Object.keys(this.relation.attributes).map((name) => [
        name,
        this.fetchAttribute(name, { jsStackPointer: this.star }),
      ])
    );
  }

  renameWith(
    mapper: (name: string) => string,
    names?: string[]
  ): { readonly [name: string]: Expression } {
    const attrs: Record<string, Expression> = {};

    for (const name of names ?? Object.keys(this.relation.attributes)) {
      attrs[mapper(name)] = this.fetchAttribute(name, {
        jsStackPointer: this.renameWith,
      });
    }

    return attrs;
  }

  unnest(name: string): { readonly [name: string]: Expression } {
    const attr = this.fetchAttribute(name, { jsStackPointer: this.unnest });

    const { ty } = attr.ty;

    if (ty.k !== 'struct') {
      throw new Error('Foo');
    }

    return _.mapValues(ty.fields, (_, name) => attr.getField(name));
  }

  mapValues(mapper: (expr: Expression, name: string) => Expression): {
    readonly [name: string]: Expression;
  } {
    return _.mapValues(this.star(), mapper);
  }

  mapNames(mapper: (name: string, expr: Expression) => string): {
    readonly [name: string]: Expression;
  } {
    return _.mapKeys(this.star(), (expr, name) => mapper(name, expr));
  }

  except(...names: string[]): { readonly [name: string]: Expression } {
    for (const name of names) {
      if (!(name in this.relation.attributes)) {
        throw TyStackTrace.fromErr(
          { frame: null, location: ['attribute', name] },
          new NoSuchAttribute({
            attributeName: name,
            relation: this.relation,
          })
        ).toError({ jsStackPointer: this.except });
      }
    }
    return Object.fromEntries(
      Object.keys(this.relation.attributes)
        .filter((name) => !names.includes(name))
        .map((name) => [name, this.attr(name)])
    );
  }

  sql(
    rawCode: readonly string[],
    ...keys: readonly (Ty.Scalar | Expression)[]
  ): Expression {
    const compiler = EraQL.makeQlExpressionCompiler(
      { attributes: this.star() },
      { jsStackPointer: this.sql }
    );

    return compiler(rawCode, ...keys);
  }

  fetchAttribute(name: string, opts: { jsStackPointer: Function }): Expression {
    const ty = this.relation.attributes[name];

    if (ty === undefined) {
      throw TyStackTrace.fromErr(
        { frame: null, location: ['attribute', name] },
        new NoSuchAttribute({
          attributeName: name,
          relation: this.relation,
        })
      ).toError(opts);
    }
    return Expression.fromAst(
      { t: 'attr', name, source: this.source, ty },
      { jsStackPointer: opts.jsStackPointer }
    );
  }
}
