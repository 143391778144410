import { Ty } from '../ty';
import _ from 'lodash';

export type RelInterface = {
  readonly [attr: string]: {
    readonly allowed: 'any' | Ty.ExtendedAttributeType[];
    readonly optional: boolean;
  };
};

export type RelInterfaceShorthand = {
  readonly [attr: string]:
    | 'any'
    | Ty.Shorthand[]
    | { allowed: readonly Ty.Shorthand[] | 'any'; optional: boolean };
};

export const relShorthandInterfaceToFull = (
  shorthand: RelInterfaceShorthand
): RelInterface =>
  _.mapValues(
    shorthand,
    (
      ty
    ): { allowed: Ty.ExtendedAttributeType[] | 'any'; optional: boolean } => {
      if (ty === 'any') {
        return { allowed: 'any' as const, optional: false };
      }

      if (Array.isArray(ty)) {
        return { allowed: ty.map((x) => Ty.shorthandToTy(x)), optional: false };
      }

      return {
        allowed:
          ty.allowed === 'any'
            ? 'any'
            : ty.allowed.map((x) => Ty.shorthandToTy(x)),
        optional: ty.optional,
      };
    }
  );
