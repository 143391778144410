import { Relation } from '@cotera/era';
import * as duckdb from '@duckdb/duckdb-wasm';
import { LogLevel } from '@duckdb/duckdb-wasm';
import { CoteraDuckDB, DuckDBPool } from '../db';
import { DuckDBQueryResult } from './duckdb-query-result';
export { CoteraDuckDB } from '../db';
export { DuckDBQueryResult } from './duckdb-query-result';

const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles();

export const duckDbSetup = async (): Promise<CoteraDuckDB> => {
  const bundle: duckdb.DuckDBBundle = await duckdb.selectBundle(
    JSDELIVR_BUNDLES
  );
  const crossOriginWorkerUrl = getWorkerURL(bundle.mainWorker!);

  const worker = new Worker(crossOriginWorkerUrl);
  const logger = new duckdb.ConsoleLogger(LogLevel.WARNING);
  const db = new duckdb.AsyncDuckDB(logger, worker);

  await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
  return new CoteraDuckDB(
    db,
    new DuckDBPool(db, {
      min: 1,
      max: 6,
      testOnBorrow: true,
    })
  );
};

export type QueryData = {
  data: DuckDBQueryResult;
  artifactRel: Relation;
  fromArtifactId: string | null;
};

// Returns a blob:// URL which points
// to a javascript file which will call
// importScripts with the given URL
function getWorkerURL(url: string) {
  const content = `importScripts( "${url}" );`;
  return URL.createObjectURL(new Blob([content], { type: 'text/javascript' }));
}
