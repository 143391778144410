import { PauseSinkButton } from './pause-sink';
import { FFI } from '@cotera/sdk/core';
import { SetSinkCursorForm } from './set-sink-cursor';
import { SnapshotSinkButton } from './snapshot-sink';
import { Assert } from '@cotera/utilities';
import { z } from 'zod';
import { Link } from 'react-router-dom';
import { UddWriteButton } from './udd-write';
import { Parser } from '@cotera/era';

export const Action: React.FC<{
  func: string;
  params: Record<string, any>;
}> = ({ func, params }) => {
  if (FFI.isFiiFuncName(func)) {
    switch (func) {
      case '@@cotera-open-link': {
        const { label, to, newTab } = z
          .object({
            to: z.string(),
            label: z.string(),
            newTab: z.boolean().optional().default(true),
          })
          .parse(params);
        return (
          <Link
            to={to}
            className="text-indigo-700 hover:text-indigo-900"
            target={newTab ? '_blank' : undefined}
          >
            {label}
          </Link>
        );
      }
      case '@@cotera-pause-sink': {
        const parsed = z
          .object({
            entityId: z.string(),
            streamId: z.string(),
            sinkId: z.string(),
          })
          .parse(params);

        return <PauseSinkButton {...parsed} />;
      }
      case '@@cotera-snapshot': {
        const parsed = z.object({ streamId: z.string() }).parse(params);
        return <SnapshotSinkButton {...parsed} />;
      }
      case '@@cotera-set-sink-cursor': {
        const parsed = z
          .object({
            entityId: z.string(),
            streamId: z.string(),
            sinkId: z.string(),
            detectedAt: z.date().nullable(),
            coteraStableId: z.string().nullable(),
          })
          .parse(params);
        return <SetSinkCursorForm {...parsed} />;
      }
      case '@@cotera-topic-matching': {
        const parsed = z
          .object({ coteraStableId: z.string(), content: z.string() })
          .parse(params);
        return parsed.content;
      }
      case '@@cotera-udd-write': {
        const parsed = z
          .object({
            entity_name: z.string(),
            key: z.string(),
            identifier: z.union([z.number(), z.string()]),
            options: z.array(
              z.object({
                label: z.string(),
                color: z.string().optional(),
                value: Parser.LiteralSchema,
              })
            ),
          })
          .parse(params);

        return <UddWriteButton payload={parsed} />;
      }
      default:
        return Assert.unreachable(func);
    }
  } else {
    return <Unimplemented func={func} />;
  }
};

const Unimplemented: React.FC<{ func: string }> = ({ func }) => (
  <span>{func} is not implemented</span>
);
