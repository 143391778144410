import { z } from 'zod';
import { FeatureFlagsSchema } from './feature-flags';
import { TenantedRoleSchema, UntenantedRoleSchema } from '../ability';

export const UserSchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  email: z.string(),
  untenantedRoles: UntenantedRoleSchema.array(),
});

export const OrgSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  featureFlags: FeatureFlagsSchema,
});

export const OrgRoleSchema = z.object({
  role: TenantedRoleSchema,
  userId: z.string().uuid(),
  orgId: z.string().uuid(),
});

export const WhoamiSchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  email: z.string(),
  impersonator: UserSchema.omit({ untenantedRoles: true }).nullable(),
  orgs: OrgSchema.array(),
  tenantedRoles: OrgRoleSchema.pick({ orgId: true, role: true }).array(),
  untenantedRoles: UntenantedRoleSchema.array(),
});

export type Whoami = z.infer<typeof WhoamiSchema>;
export type Org = z.infer<typeof OrgSchema>;
export type OrgRole = z.infer<typeof OrgRoleSchema>;
