import { Suspense, useState } from 'react';
import {
  Badge,
  Button,
  Center,
  Divider,
  Loading,
  Text,
  Title,
} from '@cotera/client/app/components/ui';
import { useEntities, useSaveEntityAssumptions } from '../hooks';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { Accordion, Card } from '@cotera/client/app/components/headless';
import {
  ArrayAgg,
  InformationSchema,
  MakeStruct,
  Ty,
  UnionAll,
} from '@cotera/era';
import {
  useWarehouseConnection,
  useWarehouseDialect,
} from '@cotera/client/app/hooks';
import { Runtime } from '@cotera/sdk/core';
import { compact } from 'lodash';
import { z } from 'zod';
import React from 'react';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { useAppData } from '@cotera/client/app/stores/org';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { Entity } from '@cotera/api';

export const AddEntityContainer: React.FC<{
  entity: Entity | null;
}> = ({ entity }) => {
  return (
    <Card.Container className="w-full">
      <Suspense
        fallback={
          <Center>
            <Loading.Form />
          </Center>
        }
      >
        <AddEntity entity={entity} />
      </Suspense>
    </Card.Container>
  );
};

const infoSchema = z.object({
  table_name: z.string(),
  table_schema: z.string(),
  columns: z
    .object({
      column_name: z.string(),
      data_type: z.string(),
      is_nullable: z.boolean(),
    })
    .array(),
});

type InfoSchema = z.infer<typeof infoSchema>;
type TableInfo = InfoSchema & { id: string };

const STEPS = [
  {
    id: 'set-name',
    title: 'Set Entity Name',
  },
  {
    id: 'select-base',
    title: 'Select Table(s)',
  },
  {
    id: 'select-columns',
    title: 'Set Entity ID Column',
  },
];

const AddEntity: React.FC<{
  entity: Entity | null;
}> = ({ entity }) => {
  const { data: entities } = useEntities();
  const { read } = useAppData((x) => x.skeleton.schemas);
  const wc = useWarehouseConnection();

  const info = UnionAll([
    Runtime.MANIFEST_DEF_INFORMATION_SCHEMA.select((t) => ({
      ...t.star(),
      is_nullable: t.attr('is_nullable').cast('string'),
    })),
    InformationSchema({
      schemas: compact([...read, wc.data?.readSchema, wc.data?.writeSchema]),
      type: 'columns',
    }).select((t) => ({
      ...t.star(),
      is_nullable: t.attr('is_nullable').cast('string'),
    })),
  ])
    .groupBy((t) => t.pick('table_name', 'table_schema'))
    .select((t) => ({
      ...t.group(),
      columns: ArrayAgg(
        MakeStruct(t.pick('column_name', 'data_type', 'is_nullable'))
      ),
    }));

  const { data } = useDuckDBQuery({ rel: info });

  const tables = (data.data.toArray() as InfoSchema[]).map((x) => ({
    ...x,
    id: `${x.table_name}-${x.table_schema}`,
  }));

  return (
    <Form
      entity={entity}
      tables={tables}
      entities={entities}
      steps={entity !== null ? STEPS.slice(1) : STEPS}
    />
  );
};

const resolveColumnName = (
  tables: TableInfo[],
  table: TableInfo,
  col: string
): string => {
  const allOtherColumns = tables
    .filter((t) => t.id !== table.id)
    .flatMap((x) => x.columns.map((c) => c.column_name));

  const tableNamesFromOtherSchemas = tables
    .filter((t) => t.table_schema !== table.table_schema)
    .map((x) => x.table_name);

  if (!allOtherColumns.includes(col)) {
    return col;
  }

  if (!tableNamesFromOtherSchemas.includes(table.table_name)) {
    return `${table.table_name}_${col}`;
  }

  return `${table.table_schema}_${table.table_name}_${col}`;
};

const Form: React.FC<{
  tables: TableInfo[];
  entities: {
    name: string;
  }[];
  entity: Entity | null;
  steps: typeof STEPS;
}> = ({ tables, entities, steps, entity }) => {
  const [step, setStep] = React.useState<string>(steps[0]!.id);
  const [entityName, setEntityName] = React.useState<string>('');
  const [idType, setIdType] = React.useState<'int' | 'string'>('string');
  const [selectedColumns, setSelectedColumns] = useState<
    Record<string, string[]>
  >({});
  const [joinColumns, setJoinColumns] = useState<Record<string, string>>({});
  const save = useSaveEntityAssumptions();
  const selectedTableIds = Object.keys(selectedColumns);
  const dialect = useWarehouseDialect();

  const savehandler = async (joinColumns: Record<string, string>) => {
    await save.mutateAsync({
      id: entity?.id,
      name: entityName,
      idType,
      assumptions: Object.entries(selectedColumns)
        .map(([tableId, cols]) => {
          const tableInfo = tables.find((x) => x.id === tableId)!;

          return cols.map((col) => {
            const type = tableInfo.columns.find((x) => x.column_name === col)!;
            const eraType = dialect.tryWarehouseTypeToEraType(type.data_type);
            return {
              ref: {
                tableSchema: tableInfo.table_schema,
                tableName: tableInfo.table_name,
                tableColName: col,
                primaryKeyColName: joinColumns[tableId]!,
                warehouseTypeName: type.data_type,
              },
              ty: eraType ? Ty.ty(eraType) : Ty.ty('string'),
              partial: false,
              columnName: resolveColumnName(
                tables.filter((x) => selectedTableIds.includes(x.id)),
                tableInfo,
                col
              ),
            };
          });
        })
        .flat(),
    });
    setStep(steps.at(0)!.id);
    setEntityName('');
    setIdType('int');
    setSelectedColumns({});
    setJoinColumns({});
  };

  return (
    <div className="px-4 py-4">
      <div className="flex flex-row justify-between items-center w-full mb-4">
        <Title type="section">Create Entity</Title>
        <div className="flex items-center space-x-10 relative">
          <hr
            className={classNames(
              `h-[1px] absolute bottom-2 left-[25%] w-[70%] z-[1]`,
              ColorScheme.background['regular']
            )}
          />
          {steps.map((s, idx) => {
            const canClick = idx < steps.findIndex((x) => x.id === step);
            return (
              <div
                key={idx}
                className={classNames(
                  'flex flex-col justify-center items-center z-[2]',
                  canClick ? 'cursor-pointer' : ''
                )}
                onClick={() => {
                  if (canClick) {
                    setStep(s.id);
                  }
                }}
              >
                <Text.Caption className="text-center mb-2">
                  {s.title}
                </Text.Caption>
                <div
                  className={classNames(
                    'rounded-full w-4 h-4',
                    step === s.id
                      ? ColorScheme.background['primary']
                      : 'bg-gray-200'
                  )}
                />
              </div>
            );
          })}
        </div>
        <div></div>
      </div>
      <Divider className="mb-4" />
      {step === 'set-name' && (
        <SetEntityName
          name={entityName}
          idType={idType}
          onSave={({ name, idType }) => {
            setEntityName(name);
            setIdType(idType);
            setStep('select-base');
          }}
          entities={entities}
        />
      )}
      {step === 'select-base' && (
        <SelectTables
          selectedColumns={selectedColumns}
          tables={tables}
          onSelect={(columns) => {
            setSelectedColumns(columns);
            setStep('select-columns');
          }}
        />
      )}
      {step === 'select-columns' && (
        <SelectJoinColumns
          selectedColumns={selectedColumns}
          tables={Object.keys(selectedColumns).map(
            (k) => tables.find((x) => x.id === k)!
          )}
          joinColumns={joinColumns}
          onSelect={(cols) => {
            setJoinColumns(cols);
            void savehandler(cols);
          }}
        />
      )}
    </div>
  );
};

const SelectTables: React.FC<{
  tables: TableInfo[];
  onSelect: (selectedColumns: Record<string, string[]>) => void;
  selectedColumns: Record<string, string[]>;
}> = ({ tables, onSelect, selectedColumns: inputSelectedColumns }) => {
  const search = useFuzzySearch(
    tables,
    ['table_name', 'table_schema', 'columns.column_name'],
    {
      threshold: 0.0,
    }
  );
  const [searchText, setSearchText] = React.useState('');
  const [selectedColumns, setSelectedColumns] =
    useState<Record<string, string[]>>(inputSelectedColumns);
  const activeTables = Object.entries(selectedColumns)
    .filter(([_, v]) => v.length > 0)
    .map(([k]) => k);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center w-full mb-4">
        <Inputs.Text
          autoFocus
          icon="search"
          value={searchText}
          placeholder="Search for table, schema or column"
          onChange={setSearchText}
          className="flex-1 mr-4"
        />
        {activeTables.length > 0 && (
          <Button
            icon="arrow-right"
            iconPosition="right"
            theme="primary"
            text={`Next`}
            onClick={() => onSelect(selectedColumns)}
          />
        )}
      </div>
      <Divider className="mb-4" />
      <Accordion.Root multiple={false} expanded={activeTables}>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Table Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Schema
                </th>
                <th scope="col" className="px-6 py-3">
                  Num Columns
                </th>
              </tr>
            </thead>
            <tbody>
              {search(searchText).map((table, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Accordion.Trigger
                      as={'tr'}
                      id={table.id}
                      className={classNames(
                        'border-b w-full cursor-pointer transition-colors ease-in-out',
                        activeTables.includes(table.id)
                          ? 'bg-indigo-100'
                          : 'hover:bg-gray-100 bg-white'
                      )}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        {table.table_name}
                      </th>
                      <td className="px-6 py-4">{table.table_schema}</td>
                      <td className="px-6 py-4">{table.columns.length}</td>
                    </Accordion.Trigger>
                    <Accordion.Item id={table.id} className="overflow-y-hidden">
                      <tr>
                        <td colSpan={4}>
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                              <tr>
                                <th scope="col" className="px-6 py-3 w-8">
                                  <Inputs.Checkbox
                                    onChange={(checked) => {
                                      if (checked) {
                                        setSelectedColumns({
                                          ...selectedColumns,
                                          [table.id]: table.columns.map(
                                            (x) => x.column_name
                                          ),
                                        });
                                      } else {
                                        setSelectedColumns({
                                          ...selectedColumns,
                                          [table.id]: [],
                                        });
                                      }
                                    }}
                                  />
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Column Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Data Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Nullable
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {table.columns.map((col, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="px-6 py-2">
                                      <Inputs.Checkbox
                                        value={selectedColumns[
                                          table.id
                                        ]?.includes(col.column_name)}
                                        onChange={(checked) => {
                                          if (checked) {
                                            setSelectedColumns({
                                              ...selectedColumns,
                                              [table.id]: [
                                                ...(selectedColumns[table.id] ??
                                                  []),
                                                col.column_name,
                                              ],
                                            });
                                          } else {
                                            setSelectedColumns({
                                              ...selectedColumns,
                                              [table.id]:
                                                selectedColumns[
                                                  table.id
                                                ]?.filter(
                                                  (x) => x !== col.column_name
                                                ) ?? [],
                                            });
                                          }
                                        }}
                                      />
                                    </td>
                                    <td className="px-6 py-2">
                                      {col.column_name}
                                    </td>
                                    <td className="px-6 py-2">
                                      {col.data_type}
                                    </td>
                                    <td className="px-6 py-2">
                                      {col.is_nullable ? 'Yes' : 'No'}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </Accordion.Item>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </Accordion.Root>
    </div>
  );
};

const SelectJoinColumns: React.FC<{
  tables: TableInfo[];
  selectedColumns: Record<string, string[]>;
  onSelect: (joinColumns: Record<string, string>) => void;
  joinColumns: Record<string, string>;
}> = ({ tables, joinColumns, onSelect, selectedColumns }) => {
  const [selected, setSelected] = useState<Record<string, string>>(joinColumns);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-end items-center w-full mb-4">
        <Button
          icon="arrow-right"
          theme="primary"
          iconPosition="right"
          text="Save"
          disabled={
            Object.entries(selected).filter(([_, v]) => v.length > 0).length <
            tables.length
          }
          onClick={() => {
            onSelect(selected);
          }}
        />
      </div>
      <Accordion.Root multiple={false} expanded={tables.map((x) => x.id)}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Table Name
              </th>
              <th scope="col" className="px-6 py-3">
                Schema
              </th>
              <th scope="col" className="px-6 py-3">
                Num Columns
              </th>
              <th scope="col" className="px-6 py-3">
                Join Column
              </th>
            </tr>
          </thead>
          <tbody>
            {tables.map((table, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Accordion.Trigger
                    as={'tr'}
                    id={table.id}
                    className={classNames(
                      'border-b w-full cursor-pointer transition-colors ease-in-out'
                    )}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {table.table_name}
                    </th>
                    <td className="px-6 py-4">{table.table_schema}</td>
                    <td className="px-6 py-4">{table.columns.length}</td>
                    <td className="px-6 py-4">
                      {selected[table.id] && (
                        <Badge theme="secondary">{selected[table.id]}</Badge>
                      )}
                    </td>
                  </Accordion.Trigger>
                  <Accordion.Item id={table.id} className="overflow-y-hidden">
                    <tr>
                      <td colSpan={4}>
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                            <tr>
                              <th scope="col" className="px-6 py-3 w-8"></th>
                              <th scope="col" className="px-6 py-3">
                                Column Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Data Type
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Nullable
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedColumns[table.id]!.map((colName, idx) => {
                              const col = table.columns.find(
                                (x) => x.column_name === colName
                              )!;
                              return (
                                <tr key={idx}>
                                  <td className="px-6 py-2">
                                    <Inputs.Checkbox
                                      value={
                                        selected[table.id] === col.column_name
                                      }
                                      onChange={(checked) => {
                                        if (checked) {
                                          setSelected({
                                            ...selected,
                                            [table.id]: col.column_name,
                                          });
                                        } else {
                                          setSelected(
                                            Object.fromEntries(
                                              Object.entries(selected).filter(
                                                ([k]) => k !== table.id
                                              )
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="px-6 py-2">
                                    {col.column_name}
                                  </td>
                                  <td className="px-6 py-2">{col.data_type}</td>
                                  <td className="px-6 py-2">
                                    {col.is_nullable ? 'Yes' : 'No'}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </Accordion.Item>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </Accordion.Root>
    </div>
  );
};

const SetEntityName: React.FC<{
  name: string;
  idType: 'int' | 'string';
  onSave: (props: { name: string; idType: 'int' | 'string' }) => void;
  entities: {
    name: string;
  }[];
}> = ({ name: inputName, onSave, entities, idType: inputIdType }) => {
  const [name, setName] = React.useState<string>(inputName);
  const [idType, setIdType] = React.useState<'int' | 'string'>(inputIdType);
  const isValidName = entities.every((x) => x.name !== name);

  return (
    <div className="w-full flex justify-center items-center min-h-[200px]">
      <form
        className="min-w-[200px] w-[50%]"
        onSubmit={(e) => {
          e.preventDefault();
          if (!isValidName) {
            return;
          }
          onSave({
            name,
            idType,
          });
        }}
      >
        <div className="flex flex-row justify-between items-center w-full mb-2">
          <Inputs.Text
            label="Entity Name"
            theme={isValidName ? 'regular' : 'error'}
            icon={
              !isValidName
                ? 'x-circle'
                : name.length === 0
                ? undefined
                : 'check'
            }
            autoFocus
            value={name}
            onChange={setName}
            className="flex-1 mr-4"
          />
          <ComboBox.Select
            className="w-[200px]"
            label="Id Type"
            nullable={false}
            options={[
              {
                value: 'int' as const,
                display: 'Int',
              },
              {
                value: 'string' as const,
                display: 'String',
              },
            ]}
            onChange={(value) => {
              setIdType(value!.value as 'int' | 'string');
            }}
          />
        </div>
        <Button
          type="submit"
          icon="arrow-right"
          iconPosition="right"
          theme="primary"
          text="Next"
          disabled={!isValidName || name.length === 0}
        />
        {!isValidName && (
          <span className={classNames(ColorScheme.text['error'], 'text-sm')}>
            Entity name must be unique
          </span>
        )}
      </form>
    </div>
  );
};
