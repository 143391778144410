import { useState } from 'react';
import { useApi } from '@cotera/client/app/etc/data/api';
import { toast } from '@cotera/client/app/components/ui/toaster';
import {
  Button,
  Divider,
  Modal,
  Title,
} from '@cotera/client/app/components/ui';
import { Inputs } from '@cotera/client/app/components/forms';

export const SetSinkCursorForm: React.FC<{
  detectedAt: Date | null;
  coteraStableId: string | null;
  entityId: string;
  streamId: string;
  sinkId: string;
}> = ({
  entityId,
  streamId,
  sinkId,
  detectedAt: inputDetectedAt,
  coteraStableId,
}) => {
  const client = useApi();

  const [stableId, setStableId] = useState<string | null>(coteraStableId);
  const [detectedAt, setDetectedAt] = useState<Date | null>(inputDetectedAt);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        text={`Set sink cursor`}
        icon="calendar"
        theme="primary"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Modal open={isOpen} onClose={setOpen} center={false} priority="low">
        <div className="min-w-[300px] w-[60%]">
          <form className="space-y-4">
            <Title type="section" title="Set sink cursor" />
            <Divider />
            <Inputs.Text
              label="Cotera stable id"
              value={stableId ?? ''}
              onChange={setStableId}
            />
            <Inputs.Date
              label="Detected at"
              rangeEnabled={false}
              value={[detectedAt, detectedAt]}
              onChange={([start]) => setDetectedAt(start)}
            />
            <div className="flex w-full justify-end">
              <Button
                theme="primary"
                disabled={loading}
                text={loading ? 'Loading...' : 'Set cursor'}
                icon="check"
                onClick={async () => {
                  if (detectedAt) {
                    setLoading(true);
                    await client.t.warehouse.setSinkCursorPosition({
                      id: {
                        entityId,
                        streamId,
                        sinkId,
                      },
                      position: {
                        coteraStableId: stableId ?? undefined,
                        detectedAt,
                      },
                    });
                    setLoading(false);
                    toast.success('Cursor set');
                  } else {
                    toast.warning('Detected at is required');
                  }
                }}
              />
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
