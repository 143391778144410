import { AST, Relation } from '@cotera/era';
import { useContext } from 'react';
import { UiStateContext } from './ui-state';
import Sticky from 'react-sticky-el';
import { Assert } from '@cotera/utilities';
import {
  useEraRuntimeCurrentRelValue,
  useEraScopesAwareRelIR,
} from './macro-expansion/scopes';
import { FilterBuilderForRelation } from '@cotera/client/app/components/app/filter-builder/for-relation';
import { v4 } from 'uuid';
import { useDeepCompareEffect } from 'react-use';
import { filterGroupToExpression } from '@cotera/client/app/components/app/filter-builder';

export const RenderSliceBuilder: React.FC<{
  section: AST._RelVarControlsV2;
  inBlock: boolean;
}> = ({ section }) => {
  const sendUpdate = useContext(UiStateContext);
  const currentValue = useEraRuntimeCurrentRelValue(section.var);
  const chartRelIR = useEraScopesAwareRelIR(currentValue);
  Assert.assert(section.config.t === 'slice-builder');
  const slices = section.config.quickFilters.map((qf) => ({
    id: v4(),
    value: {
      t: qf.t,
      operator: qf.operator,
      key: qf.left,
      value: Array.isArray(qf.right) ? qf.right : [qf.right],
      compact: true,
    },
  }));

  const rel = Relation.wrap(chartRelIR);

  useDeepCompareEffect(() => {
    setTimeout(() => {
      sendUpdate.rel({
        scope: section.var.scope,
        name: section.var.name,
        cb: () =>
          rel.where((t) =>
            filterGroupToExpression(t)({
              connective: 'and',
              items: slices,
            })
          ),
      });
    }, 10);
  }, [section.config.quickFilters]);

  return (
    <Sticky
      mode="top"
      wrapperClassName="z-10 relative"
      scrollElement={'.main'}
      stickyClassName="bg-white z-10 border-b-2 border-divider"
    >
      <div className="pt-2 w-full">
        <FilterBuilderForRelation
          filterGroup={{
            connective: 'and',
            items: slices,
          }}
          rel={rel}
          onRun={(rel) => {
            sendUpdate.rel({
              scope: section.var.scope,
              name: section.var.name,
              cb: () => rel,
            });
          }}
        />
      </div>
    </Sticky>
  );
};
