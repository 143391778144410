import { z } from 'zod';
import { AST } from '../../ast';
import { evalExprIR } from '../../interpreter/eval-expr-ir';
import { Ty } from '../../ty';
import { SqlExprAst, ParameterizedSql } from '../sql-ast';
import { IRChildren } from '../../ast/base';

type FunctionOverride = (
  args: readonly AST.ExprIR[],
  wantedTy: Ty.AttributeType
) => SqlExprAst;

export const assertConstantString = <T extends string>(
  expr: AST.ExprIR,
  options: readonly [T, ...T[]]
): T => {
  const res = evalExprIR(expr);
  return z.enum(options).parse(res);
};

export type SqlDialect = {
  tryWarehouseTypeToEraType: (warehouseType: string) => Ty.AttributeType | null;
  nullLiteral: (ty: Ty.AttributeType) => SqlExprAst;
  supportsFileSources: boolean;
  informationSchema: {
    // These are paramertized over the schema because bigquery doesnt support
    // cross schema information schema reads
    columns: (schemas: readonly string[]) => AST.RelIR;
    tables: (schemas: readonly string[]) => AST.RelIR;
  };
  relation: (ref: AST.TableIdentifier | string) => string;
  generateSeries: (params: { start: number; stop: number }) => SqlExprAst;
  quotingCharacter: string;
  getPropertyFromStruct: (
    expr: AST.ExprIR,
    name: string,
    wantedTy: Ty.AttributeType
  ) => SqlExprAst;
  makeStruct: (fields: Record<string, AST.ExprIR>) => SqlExprAst;
  makeRecord: (values: Record<string, AST.ExprIR>) => SqlExprAst;
  makeArray: (arr: AST._MakeArray<IRChildren>) => SqlExprAst;
  cast: (expr: AST.ExprIR, targetTy: Ty.AttributeType) => SqlExprAst;
  placeholder: (params: { oneIndexedArgNum: number }) => string;
  attr: (ident: string) => string;
  typeMapping: (ty: Ty.AttributeType) => string;
  functionOverrides: Partial<
    Record<AST.FunctionIdentifier, FunctionOverride | Error>
  >;
  values: (values: AST._ValuesContract) => SqlExprAst;
  scalarLiteralOverrides?: {
    string?: (literal: string) => ParameterizedSql;
  };
};
