import { From } from '@cotera/era';
import { ORDER_LINE_ITEM_DEMO } from './assumptions';

export const DemoUsers = From(ORDER_LINE_ITEM_DEMO).select(
  (t) => t.pick('USER_ID'),
  { distinct: true }
);

export const DemoOrders = From(ORDER_LINE_ITEM_DEMO).select((t) => ({
  ...t.pick(
    'ORDER_ID',
    'USER_ID',
    'PRODUCT_ID',
    'QUANTITY',
    'PRICE',
    'ORDER_DATE'
  ),
  __COTERA_CUSTOMER_ID: t.attr('USER_ID'),
}));

export const DemoItemDetail = From(ORDER_LINE_ITEM_DEMO).select(
  (t) => ({
    ...t.pick('PRODUCT_ID', 'PRODUCT_NAME', 'PRICE'),
    ITEM_NAME: t.attr('PRODUCT_NAME'),
    ITEM_PRODUCT_COST: t.attr('PRICE'),
    ITEM_PRODUCT_URL: '',
    ITEM_PRODUCT_IMAGE_URL: '',
  }),
  { distinct: true }
);
