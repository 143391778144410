import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import * as Popover from '@radix-ui/react-popover';

type Action = {
  icon: React.ReactNode;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ActionMenu: React.FC<{ actions: Action[] }> = ({ actions }) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button aria-label="Show actions" className="ml-2">
          <EllipsisVerticalIcon
            className="cursor-pointer text-gray-500 hover:bg-gray-200 rounded-full w-[22px] h-[22px] p-1"
            height={15}
          />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="z-50 pl-4 pr-6 pt-2 rounded bg-white border border-200-gray"
          sideOffset={5}
        >
          <ul>
            {actions.map((action) => (
              <li key={action.label} className="w-full pb-2">
                <button
                  key={action.label}
                  className="flex flex-row items-center text-standard-text h-8 text-sm text-left focus:outline-none cursor-pointer hover:text-primary-text"
                  onClick={action.onClick}
                >
                  <span className="text-standard-text mr-4">{action.icon}</span>
                  {action.label}
                </button>
              </li>
            ))}
          </ul>
          <Popover.Arrow className="fill-gray-400" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
