export const compileLikePattern = (pattern: string): RegExp => {
  const patternChars = [...pattern];
  const escapedPattern = [
    '^',
    ...patternChars.map((char) => {
      if ('/.*+?|(){}'.includes(char)) {
        return `\\${char}`;
      }

      if (char === '_') {
        return '.';
      }

      if (char === '%') {
        return '.*';
      }

      return char;
    }),
    '$',
  ].join('');

  return new RegExp(escapedPattern);
};
