import { classNames } from '@cotera/client/app/components/utils';
import { Colors } from './classes';

export const Label: React.FC<{
  className?: string;
  children: React.ReactNode;
  pre?: string;
  sub?: string;
}> = ({ className = '', pre, sub, children }) => {
  return (
    <div className={classNames('flex flex-col font-sans', className)}>
      {pre && (
        <span className={classNames('text-xs mb-0.5', Colors.caption)}>
          {pre}
        </span>
      )}
      <label
        className={classNames(
          'text-base tracking-tight font-medium',
          Colors.standard
        )}
      >
        {children}
      </label>
      {sub && (
        <span className={classNames('text-sm mt-1.5', Colors.subtitle)}>
          {sub}
        </span>
      )}
    </div>
  );
};
