import { DateTime } from 'luxon';
import { UTCDate } from '../utils';
import { Format } from '.';

export const TIME_SERIES_FORMAT = [
  'day',
  'month',
  'month-only',
  'year',
  'millisecond',
  'second',
  'minute',
  'hour',
] as const;

export type TimeSeriesFormat = (typeof TIME_SERIES_FORMAT)[number];

export const isTimeSeriesFormat = (
  props: Format
): props is {
  unit: TimeSeriesFormat;
  value: Date | number | string;
} => {
  return TIME_SERIES_FORMAT.includes(props.unit as TimeSeriesFormat);
};

const formatMap: Record<TimeSeriesFormat, string> = {
  month: 'MMM yyyy',
  day: 'yyyy-MM-dd',
  year: 'yyyy',
  hour: 'h a',
  second: 's',
  millisecond: 'SSS',
  minute: 'mm',
  'month-only': 'MMMM',
};

export function timeSeries(
  date?: Date | number | string | null,
  unit?: TimeSeriesFormat
): string {
  return DateTime.fromJSDate(new UTCDate(date ?? Date.now())).toFormat(
    unit ? formatMap[unit] : 'yyyy-MM-dd'
  );
}
