import { AST } from '../ast';
import { checkMarkup } from './check-mu';
import { Frame, TyStackTrace } from './ty-stack-trace';
import { checkExpr, checkRel, Errs, implementsTy } from './type-checker';
import type { RelTypeCheck } from './check-rel';
import type { ExprTypeCheck } from './expr/check-expr';
import type { MarkupTypeCheck } from './check-mu';
import { RelMacroChildren } from '../ast/base';

type VarCheck = {
  readonly rels: Record<string, RelTypeCheck>;
  readonly exprs: Record<string, ExprTypeCheck>;
  readonly sections: Record<string, MarkupTypeCheck>;
};

export const checkVars: (
  scope: AST.MacroArgsType,
  vars: Partial<AST.MacroVarReplacements<RelMacroChildren>>
) => VarCheck | ((frame: Frame['frame']) => TyStackTrace) = (scope, vars) => {
  const checkedRelVars: Record<string, RelTypeCheck> = {};

  if (vars.rels) {
    for (const [relName, relVarDef] of Object.entries(scope.rels)) {
      const replacement = vars.rels[relName];

      if (!replacement) {
        return (frame) =>
          TyStackTrace.fromErr(
            { frame, location: ['var', relName] as const },
            new Errs.UnreplacedVariable({ namespace: 'rel', varName: relName })
          );
      }

      const checkedReplacement = checkRel(replacement);

      if (checkedReplacement instanceof TyStackTrace) {
        return (frame) =>
          checkedReplacement.withFrame({
            frame,
            location: ['var', relName],
          });
      }

      for (const [varAttrName, varAttrTy] of Object.entries(relVarDef.type)) {
        const replacementTy = checkedReplacement.attributes[varAttrName];

        if (!replacementTy) {
          return (frame) =>
            TyStackTrace.fromErr(
              {
                frame,
                location: ['var', relName, 'attribute', varAttrName],
              },
              new Errs.ReplacementMissingAttribute({
                attrName: varAttrName,
                relName,
                ty: varAttrTy,
              })
            );
        }

        if (!implementsTy({ req: varAttrTy, subject: replacementTy })) {
          return (frame) =>
            TyStackTrace.fromErr(
              { frame },
              new Errs.InvalidTypeForAttribute({
                name: `${relName}"."${varAttrName}`,
                actual: replacementTy,
                wanted: [varAttrTy],
              })
            );
        }
      }

      checkedRelVars[relName] = checkedReplacement;
    }
  }

  const checkedExprVars: Record<string, ExprTypeCheck> = {};

  if (vars.exprs) {
    for (const [varName, varDef] of Object.entries(scope.exprs)) {
      const replacement = vars.exprs[varName];

      if (!replacement) {
        if (!varDef.defaulted) {
          return (frame) =>
            TyStackTrace.fromErr(
              { frame, location: ['var', varName] as const },
              new Errs.UnreplacedVariable({ namespace: 'expr', varName })
            );
        } else {
          continue;
        }
      }

      const checkedReplacement = checkExpr(replacement);

      if (checkedReplacement instanceof TyStackTrace) {
        return (frame) =>
          checkedReplacement.withFrame({ frame, location: ['var', varName] });
      }

      const usedAttrs = Object.values(checkedReplacement.attrReqs).flatMap(
        (attrs) => Object.keys(attrs)
      );
      const usedAttr = usedAttrs[0];

      if (usedAttr) {
        return (frame) =>
          TyStackTrace.fromErr(
            { frame },
            new Errs.CantUseAttributsInExprVarReplacements({
              varName,
              attributeName: usedAttr,
            })
          );
      }

      if (!implementsTy({ subject: checkedReplacement.ty, req: varDef.type })) {
        return (frame) =>
          TyStackTrace.fromErr(
            { frame, location: ['var', varName] },
            new Errs.InvalidTypeForAttribute({
              name: varName,
              actual: checkedReplacement.ty,
              wanted: [varDef.type],
            })
          );
      }

      checkedExprVars[varName] = checkedReplacement;
    }
  }

  const checkedSectionsVars: Record<string, MarkupTypeCheck> = {};

  if (vars.sections) {
    for (const [varName, _sectionDef] of Object.entries(scope.sections)) {
      const replacement = vars.sections[varName];

      if (!replacement) {
        return (frame) =>
          TyStackTrace.fromErr(
            { frame, location: ['var', varName] as const },
            new Errs.UnreplacedVariable({ namespace: 'section', varName })
          );
      }

      const checkedReplacement = checkMarkup(replacement);
      if (checkedReplacement instanceof TyStackTrace) {
        return (frame) =>
          checkedReplacement.withFrame({ frame, location: ['var', varName] });
      }

      checkedSectionsVars[varName] = checkedReplacement;
    }
  }

  const res: VarCheck = {
    exprs: checkedExprVars,
    sections: checkedSectionsVars,
    rels: checkedRelVars,
  };

  return res;
};
