import { AST } from '../../ast';
import { WINDOW_FUNCTION_FRAME_RULES } from './function-call';
import {
  TypeCheckError,
  WindowFunctionCanNotHaveAFrame,
  WindowFunctionFrameBoundsCanNotBeNegative,
  WindowFunctionRequiresAFrameClause,
  WindowFunctionsWithAFrameRequireAnOrderBy,
} from '../type-check-error';

export const checkWindowFrame = (params: {
  op: AST.WindowOp;
  frame: AST.WindowFrame | null;
  containsOrderByClause: boolean;
}): TypeCheckError | AST.WindowFrame | null => {
  const { op, frame, containsOrderByClause } = params;

  if (frame !== null && !containsOrderByClause) {
    return new WindowFunctionsWithAFrameRequireAnOrderBy({ op });
  }

  if (containsOrderByClause) {
    if (WINDOW_FUNCTION_FRAME_RULES[op] === 'required') {
      if (frame === null) {
        return new WindowFunctionRequiresAFrameClause({
          op,
        });
      } else {
        if (
          (typeof frame.following === 'number' && frame.following < 0) ||
          (typeof frame.preceding === 'number' && frame.preceding < 0)
        ) {
          return new WindowFunctionFrameBoundsCanNotBeNegative({
            op,
            frame,
          });
        }
      }
    } else {
      if (frame !== null) {
        return new WindowFunctionCanNotHaveAFrame({
          op,
          frame,
        });
      }
    }
  }

  return frame;
};
