import { useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { Assert } from '@cotera/utilities';
import { useTenantedQueryKey } from './use-tenanted-query-key';

export const useDecisionTreeRDAData = () => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey(['decision-tree-rda']);

  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const response = await client.runtimeData.decisionTreeOutputs({});
      return Assert.assertOk(response);
    },
  });
};
