import { AST, Expression, Relation } from '@cotera/era';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CompileMarkup } from './compiler';
import { ProvideEraScope } from './macro-expansion/scopes';

type SetFunc<T> = (ctx: {
  scope: string;
  name: string;
  cb: (params: { original: T }) => T;
}) => void;

type SetUiState = {
  rel: SetFunc<Relation>;
  expr: SetFunc<Expression>;
};

const defaultSetUiState: SetFunc<any> = ({ name, scope }) => {
  throw new Error(`Tried to set "${scope}"."${name}" to but it does not exist`);
};

export const UiStateContext: React.Context<SetUiState> = createContext({
  rel: defaultSetUiState,
  expr: defaultSetUiState,
});

export const RenderUiState: React.FC<{
  section: AST._UiState;
  inBlock: boolean;
}> = ({ inBlock, section }) => {
  const [exprState, setExprState] = useState<Record<string, AST.ExprFR>>(
    section.defaults.exprs
  );

  const [relState, setRelState] = useState<Record<string, AST.RelFR>>(
    section.defaults.rels
  );

  useEffect(() => {
    setRelState(section.defaults.rels);
  }, [section.defaults.rels]);

  useEffect(() => {
    setExprState(section.defaults.exprs);
  }, [section.defaults.exprs]);

  const parent = useContext(UiStateContext);

  const setExpr = useCallback<SetFunc<Expression>>(
    ({ scope, name, cb }) => {
      if (scope === section.body.scope) {
        const original = section.defaults.exprs[name];
        if (!original) {
          throw new Error('TODO');
        }
        setExprState((s) => ({
          ...s,
          [name]: cb({ original: Expression.fromAst(original) }).ast,
        }));
      } else {
        parent.expr({ scope, name, cb });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parent, section.body.scope, section.defaults]
  );

  const setRel = useCallback<SetFunc<Relation>>(
    ({ scope, name, cb }) => {
      if (scope === section.body.scope) {
        const original = section.defaults.rels[name];
        if (!original) {
          throw new Error('The relation does not exist in the defaults.');
        }
        const newRel = cb({ original: Relation.fromAst(original) });
        setRelState((s) => ({ ...s, [name]: newRel.ast }));
      } else {
        parent.rel({ scope, name, cb });
      }
    },
    [parent, section.body.scope, section.defaults]
  );

  const data: AST.MacroVarReplacements<AST.RelMacroChildren> = {
    rels: relState,
    exprs: exprState,
    sections: {},
  };

  return (
    <UiStateContext.Provider value={{ expr: setExpr, rel: setRel }}>
      <ProvideEraScope scope={section.body.scope} vals={data}>
        <CompileMarkup section={section.body.macro} inBlock={inBlock} />
      </ProvideEraScope>
    </UiStateContext.Provider>
  );
};
