import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useTenantedClient } from '../../stores/org';
import { Assert } from '@cotera/utilities';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { Ty } from '@cotera/era';
import toast from 'react-hot-toast';
import { Entity } from '@cotera/api';

export const useEntities = () => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey(['entities']);

  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      return Assert.assertOk(await client.warehouse.entityColumns.entities({}))
        .entities;
    },
  });
};

export const useEntity = (entityName: string): Entity | undefined => {
  const entities = useEntities();

  return entities.data?.find((x) => x.name === entityName);
};

type Assumption = {
  ref: {
    tableSchema: string;
    tableName: string;
    tableColName: string;
    primaryKeyColName: string;
    warehouseTypeName: string;
  };
  ty: Ty.ExtendedAttributeType;
  partial: boolean;
  columnName?: string | undefined;
};

export const useSaveEntityAssumptions = () => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();

  const queryKey = useTenantedQueryKey(['entities']);
  return useMutation({
    mutationFn: async (props: {
      id?: string;
      name: string;
      idType: 'int' | 'string';
      assumptions: Assumption[];
    }) => {
      await client.warehouse.entityColumns.upsertEntity({
        entityId: props.id,
        name: props.name,
        idType: props.idType,
        assumptions: props.assumptions,
      });
    },
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey });
      toast.success('Entity created');
    },
  });
};
