import { Assert, Freeze, ISO8601_REGEX } from '../../utils';
import { AST } from '../../ast';
import { Ty } from '../../ty';
import { TyStackTrace } from '../ty-stack-trace';
import {
  ALLOWED_PRIMITIVE_CASTS,
  FUNCTION_TYPING_RULES,
  WINDOW_FUNC_SIGNATURES,
} from './function-call';
import * as Errs from '../type-check-error';
import { mergeMacroVars } from '../merge-marco-vars';
import _ from 'lodash';
import { implementsTy, narrowestSuperTypeOf } from '../implements';
import { checkWindowFrame } from './check-window-frame';
import { mergeAttrReqs } from './merge-attr-reqs';
import { isExprInterpretable } from './purity-analysis';
import { checkVars } from '../check-vars';
import { FUNCTION_ARITY } from './func-arity';
import { ExprMacroChildren } from '../../ast/base';

export type ExprAttributeRequirements = Readonly<
  Partial<
    Record<
      'left' | 'right' | 'from',
      {
        readonly [name: string]: Ty.ExtendedAttributeType;
      }
    >
  >
>;

export type ExprTypeCheck = {
  readonly ty: Ty.ExtendedAttributeType;
  readonly aggregated: boolean;
  readonly windowed: boolean;
  readonly attrReqs: ExprAttributeRequirements;
  readonly vars: { readonly [scope: string]: AST.MacroArgsType };
};

type ExprCheck<T extends AST.ExprFR> = (
  expr: T
) => ExprTypeCheck | TyStackTrace;

const EXPR_TYPE_CHECK_CACHE: WeakMap<AST.ExprFR, ExprTypeCheck | TyStackTrace> =
  new WeakMap();

export const checkExpr = (
  expr: AST.ExprFR,
  opts: {
    readonly sources?: { readonly [source: string]: AST.Source };
    readonly implements?: readonly Ty.Shorthand[];
  } = {}
): ExprTypeCheck | TyStackTrace => {
  const existing = EXPR_TYPE_CHECK_CACHE.get(expr);

  let check: ExprTypeCheck | TyStackTrace | undefined = existing;

  if (check === undefined) {
    const { t } = expr;

    switch (t) {
      case 'scalar':
        check = checkScalar(expr);
        break;
      case 'cast':
        check = checkCast(expr);
        break;
      case 'function-call':
        check = checkFunctionCall(expr);
        break;
      case 'attr':
        check = checkAttr(expr);
        break;
      case 'window':
        check = checkWindow(expr);
        break;
      case 'case':
        check = checkCase(expr);
        break;
      case 'make-struct':
        check = checkMakeStruct(expr);
        break;
      case 'make-array':
        check = checkMakeArray(expr);
        break;
      case 'get-field':
        check = checkGetField(expr);
        break;
      case 'invariants':
        check = checkInvariants(expr);
        break;
      case 'expr-var':
        check = checkExprVar(expr);
        break;
      case 'macro-expr-case':
        check = checkMacroExprCase(expr);
        break;
      case 'macro-apply-vars-to-expr':
        check = checkMacroApplyVarsToExpr(expr);
        break;
      default:
        return Assert.unreachable(expr);
    }
  }

  if (existing === undefined) {
    EXPR_TYPE_CHECK_CACHE.set(expr, check);
  }

  if (check instanceof TyStackTrace) {
    return check;
  }

  if (opts.implements !== undefined) {
    // This version of TS can't figure out the type of check inside a closure, so we help it out;
    const { ty }: ExprTypeCheck = check;
    if (!opts.implements.some((req) => implementsTy({ subject: ty, req }))) {
      return TyStackTrace.fromErr(
        {},
        new Errs.TypeDoesNotMatchExpectation({
          found: check.ty,
          expected: opts.implements,
        })
      );
    }
  }

  if (opts.sources !== undefined) {
    for (const [source, requirements] of Object.entries(check.attrReqs)) {
      for (const [attributeName, { ty }] of Object.entries(requirements)) {
        const relation = opts.sources[source];
        const exisiting = relation?.attributes[attributeName];
        if (exisiting === undefined) {
          return TyStackTrace.fromErr(
            { frame: expr, location: source as 'left' | 'right' | 'from' },
            new Errs.NoSuchAttribute({ attributeName, relation })
          );
        }
        if (!implementsTy({ subject: exisiting, req: ty })) {
          return TyStackTrace.fromErr(
            { frame: expr, location: 'from' },
            new Errs.InvalidTypeForAttribute({
              name: attributeName,
              wanted: [ty],
              actual: exisiting ?? null,
            })
          );
        }
      }
    }
  }

  return check;
};

const checkMacroApplyVarsToExpr: ExprCheck<
  AST._MacroApplyVarsToExpr<ExprMacroChildren>
> = (apply) => {
  const fromD = checkExpr(apply.sources.from);

  if (fromD instanceof TyStackTrace) {
    return fromD.withFrame({ frame: apply, location: 'from' });
  }

  const scope = fromD.vars[apply.scope] ?? {
    exprs: {},
    rels: {},
    sections: {},
  };

  const checkedVars = checkVars(scope, apply.vars);

  if (typeof checkedVars === 'function') {
    return checkedVars(apply);
  }

  const varRes = mergeMacroVars(
    fromD.vars,
    ...Object.values(checkedVars.rels).map((rel) => rel.vars),
    ...Object.values(checkedVars.sections).map((section) => section.vars),
    ...Object.values(checkedVars.exprs).map((expr) => expr.vars)
  );

  if (varRes instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: apply }, varRes);
  }

  const { [apply.scope]: _, ...newVars } = varRes;

  return { ...fromD, vars: newVars };
};

const checkMacroExprCase: ExprCheck<AST._MacroExprCase<ExprMacroChildren>> = (
  macroCase
) => {
  const checkedWhens: ExprTypeCheck[] = [];
  const checkedThens: ExprTypeCheck[] = [];

  for (const { when, then } of macroCase.cases) {
    const whenD = checkExpr(when);

    if (whenD instanceof TyStackTrace) {
      return whenD.withFrame({ frame: macroCase });
    }

    if (!implementsTy({ subject: whenD.ty, req: 'boolean' })) {
      return TyStackTrace.fromErr(
        { frame: macroCase, location: 'condition' },
        new Errs.TypeDoesNotMatchExpectation({
          expected: 'boolean',
          found: whenD.ty,
        })
      );
    }

    if (!isExprInterpretable(when, { allow: { undefaultedVars: true } })) {
      return TyStackTrace.fromErr(
        { frame: macroCase, location: 'condition' },
        new Errs.ConstExprRequired({ name: 'when' })
      );
    }

    checkedWhens.push(whenD);

    const thenD = checkExpr(then);

    if (thenD instanceof TyStackTrace) {
      return thenD.withFrame({ frame: macroCase });
    }

    checkedThens.push(thenD);
  }

  const checkedElse = checkExpr(macroCase.else);

  if (checkedElse instanceof TyStackTrace) {
    return checkedElse.withFrame({ frame: macroCase });
  }

  const outputType = narrowestSuperTypeOf([
    checkedElse.ty,
    ...checkedThens.map((branch) => branch.ty),
  ]);

  if (outputType.isErr()) {
    return TyStackTrace.fromErr(
      { frame: macroCase, location: 'then' },
      new Errs.MismatchedCaseOutputTypes({
        received: [outputType.error.lhs, outputType.error.rhs],
      })
    );
  }

  const aggregated =
    checkedElse.aggregated || checkedThens.some((x) => x.aggregated);
  const windowed = checkedElse.windowed || checkedThens.some((x) => x.windowed);

  const attrReqs = mergeAttrReqs(
    checkedElse.attrReqs,
    ...checkedThens.map((x) => x.attrReqs)
  );

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: macroCase }, attrReqs);
  }

  const vars = mergeMacroVars(
    ...checkedWhens.map((x) => x.vars),
    ...checkedThens.map((x) => x.vars),
    checkedElse.vars
  );

  if (vars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: macroCase }, vars);
  }

  const res: ExprTypeCheck = {
    ty: outputType.value,
    aggregated,
    windowed,
    attrReqs,
    vars,
  };

  return res;
};

const checkInvariants: ExprCheck<AST._Invariants<ExprMacroChildren>> = (
  invariants
) => {
  const checkedExpr = checkExpr(invariants.expr);

  if (checkedExpr instanceof TyStackTrace) {
    return checkedExpr.withFrame({ frame: invariants });
  }

  const checkedInvariants: Record<string, ExprTypeCheck> = {};

  for (const [name, invariant] of Object.entries(invariants.invariants)) {
    const frame = { frame: invariants, location: ['invariant', name] } as const;
    const checkedInvariant = checkExpr(invariant);

    if (checkedInvariant instanceof TyStackTrace) {
      return checkedInvariant.withFrame(frame);
    }

    if (!implementsTy({ subject: checkedInvariant.ty.ty, req: 'boolean' })) {
      return TyStackTrace.fromErr(
        frame,
        new Errs.InvariantsMustBeOfTypeBoolean({
          name,
          actualType: checkedInvariant.ty,
        })
      );
    }

    checkedInvariants[name] = checkedInvariant;
  }

  const marcoVars = mergeMacroVars(
    checkedExpr.vars,
    ...Object.values(checkedInvariants).map(({ vars }) => vars)
  );

  if (marcoVars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: invariants }, marcoVars);
  }

  const attrReqs = mergeAttrReqs(
    checkedExpr.attrReqs,
    ...Object.values(checkedInvariants).map(({ attrReqs }) => attrReqs)
  );

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: invariants }, attrReqs);
  }

  const res: ExprTypeCheck = {
    ty: checkedExpr.ty,
    attrReqs,
    vars: marcoVars,
    aggregated:
      checkedExpr.aggregated ||
      Object.values(checkedInvariants).some((expr) => expr.aggregated),
    windowed:
      checkedExpr.windowed ||
      Object.values(checkedInvariants).some((expr) => expr.windowed),
  };

  return res;
};

const checkExprVar: ExprCheck<AST._ExprVar<ExprMacroChildren>> = (expr) => {
  let defaultD: ExprTypeCheck | TyStackTrace | null = null;
  if (expr.default !== null) {
    defaultD = checkExpr(expr.default);
    if (defaultD instanceof TyStackTrace) {
      return defaultD.withFrame({ frame: expr, location: 'default' });
    }

    if (!implementsTy({ subject: defaultD.ty, req: expr.ty })) {
      return TyStackTrace.fromErr(
        { frame: expr },
        new Errs.TypeDoesNotMatchExpectation({
          found: defaultD.ty,
          expected: expr.ty,
        })
      );
    }

    if (defaultD.windowed || defaultD.aggregated) {
      return TyStackTrace.fromErr(
        { frame: expr },
        new Errs.ExprVarDefaultsCantBeWindowedOrAggregated({
          name: expr.name,
          scope: expr.scope,
          windowed: defaultD.windowed,
          aggregated: defaultD.aggregated,
        })
      );
    }

    const foundAttrs = Object.entries(defaultD.attrReqs).flatMap(
      ([source, attrs]) =>
        Object.keys(attrs).map((name) => [source, name] as const)
    );

    if (foundAttrs.length > 0) {
      return TyStackTrace.fromErr(
        { frame: expr },
        new Errs.ExprVarsDefaultsCantContainAttributes({
          name: expr.name,
          scope: expr.scope,
          foundAttrs,
        })
      );
    }
  }

  const vars = mergeMacroVars(
    {
      [expr.scope]: {
        exprs: {
          [expr.name]: { type: expr.ty, defaulted: expr.default !== null },
        },
        rels: {},
        sections: {},
      },
    },
    ...(defaultD ? [defaultD.vars] : [])
  );

  if (vars instanceof Errs.TypeCheckError) {
    return vars.toStackTrace({ frame: expr });
  }

  const res: ExprTypeCheck = {
    aggregated: false,
    windowed: false,
    ty: expr.ty,
    attrReqs: {},
    vars,
  };

  return res;
};

const checkMakeArray: ExprCheck<AST._MakeArray<ExprMacroChildren>> = (
  array
) => {
  if (array.elements.length === 0) {
    return TyStackTrace.fromErr(
      { frame: array },
      new Errs.ArrayCreationMustHaveALeastOneElement()
    );
  }

  const elementsD: ExprTypeCheck[] = [];

  for (const elem of array.elements) {
    const checkD = checkExpr(elem);
    if (checkD instanceof TyStackTrace) {
      return checkD.withFrame({ frame: array });
    }

    elementsD.push(checkD);
  }

  if (elementsD.some(({ ty }) => ty.nullable)) {
    return TyStackTrace.fromErr(
      { frame: array },
      new Errs.NoNullInsideArrayLiterals()
    );
  }

  const superType = narrowestSuperTypeOf(elementsD.map((elem) => elem.ty));

  if (superType.isErr()) {
    return TyStackTrace.fromErr(
      { frame: array },

      new Errs.ArraysMustBeTheSameType({
        incompatible: [superType.error.lhs, superType.error.rhs],
      })
    );
  }

  const attrReqs = mergeAttrReqs(...elementsD.map((x) => x.attrReqs));

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: array }, attrReqs);
  }

  const vars = mergeMacroVars(...elementsD.map((x) => x.vars));

  if (vars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: array }, vars);
  }

  const res: ExprTypeCheck = {
    vars,
    attrReqs,
    aggregated: elementsD.some((elem) => elem.aggregated),
    windowed: elementsD.some((elem) => elem.windowed),
    ty: Ty.nn(Ty.a(superType.value)),
  };

  return res;
};

const checkMakeStruct: ExprCheck<AST._MakeStruct<ExprMacroChildren>> = (
  struct
) => {
  const checkedFields: Record<string, ExprTypeCheck> = {};

  for (const [name, field] of Object.entries(struct.fields)) {
    const checkedExpr = checkExpr(field);

    if (checkedExpr instanceof TyStackTrace) {
      return checkedExpr.withFrame({
        frame: struct,
        location: ['field', name],
      });
    }

    checkedFields[name] = checkedExpr;
  }

  const macroVars = mergeMacroVars(
    ...Object.values(checkedFields).map((field) => field.vars)
  );

  if (macroVars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: struct }, macroVars);
  }

  const attrReqs = mergeAttrReqs(
    ...Object.values(checkedFields).map((field) => field.attrReqs)
  );

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: struct }, attrReqs);
  }

  const res: ExprTypeCheck = {
    windowed: Object.values(checkedFields).some((field) => field.windowed),
    aggregated: Object.values(checkedFields).some((field) => field.windowed),
    vars: macroVars,
    attrReqs,
    ty: {
      ty: {
        k: 'struct',
        fields: _.mapValues(checkedFields, (field) => field.ty),
      },
      nullable: false,
      tags: [],
    },
  };

  return res;
};

const checkGetField: ExprCheck<AST._GetField<ExprMacroChildren>> = (
  getField
) => {
  const exprD = checkExpr(getField.expr);

  if (exprD instanceof TyStackTrace) {
    return exprD.withFrame({ frame: getField, location: 'source' });
  }

  const { ty } = exprD.ty;

  if (ty.k !== 'struct') {
    return TyStackTrace.fromErr(
      { frame: getField, location: 'source' },
      new Errs.CantGetFieldOfANonStruct({
        fieldName: getField.name,
        attempted: ty,
      })
    );
  }

  const propertyTy = ty.fields[getField.name];

  if (!propertyTy) {
    return TyStackTrace.fromErr(
      { frame: getField, location: 'source' },
      new Errs.NoSuchAttribute({
        attributeName: getField.name,
        relation: { attributes: ty.fields },
        struct: true,
      })
    );
  }

  const res: ExprTypeCheck = {
    ty: propertyTy,
    aggregated: exprD.aggregated,
    windowed: exprD.windowed,
    vars: exprD.vars,
    attrReqs: exprD.attrReqs,
  };

  return res;
};

const checkScalar: ExprCheck<AST._Scalar> = (expr) => {
  const { ty, val } = expr;

  const err = TyStackTrace.fromErr(
    { frame: expr, location: 'target' },
    new Errs.IllegalScalar({ ty, val })
  );

  if (val !== null) {
    if (ty.ty.k === 'primitive') {
      switch (ty.ty.t) {
        case 'boolean':
          if (typeof val !== 'boolean') {
            return err;
          }
          break;
        case 'string':
          if (typeof val !== 'string') {
            return err;
          }
          break;
        case 'float':
        case 'int':
          if (typeof val !== 'number') {
            return err;
          }
          if (ty.ty.t === 'int' && !Number.isInteger(val)) {
            return err;
          }
          break;
        case 'day':
        case 'month':
        case 'year':
        case 'timestamp':
          if (
            !(
              val instanceof Date ||
              (typeof val === 'string' && ISO8601_REGEX.test(val))
            )
          ) {
            return err;
          }
          break;
        case 'super':
          break;
        default:
          return Assert.unreachable(ty.ty);
      }
    } else if (ty.ty.k === 'array') {
      if (!Freeze.isReadonlyArray(val)) {
        return err;
      }

      for (const v of val) {
        if (v === null) {
          return TyStackTrace.fromErr(
            { frame: expr },
            new Errs.NoNullInsideArrayLiterals()
          );
        }
        const vCheck = checkScalar({ t: 'scalar', ty: ty.ty.t, val: v });
        if (vCheck instanceof TyStackTrace) {
          return vCheck.withFrame({ frame: expr });
        }
      }
    } else if (ty.ty.k === 'record') {
      if (
        !(typeof val === 'object') ||
        val instanceof Date ||
        Freeze.isReadonlyArray(val)
      ) {
        return err;
      }
      for (const innerVal of Object.values(val)) {
        const vCheck = checkScalar({
          t: 'scalar',
          ty: Ty.nn(ty.ty.t),
          val: innerVal,
        });
        if (vCheck instanceof TyStackTrace) {
          return vCheck.withFrame({ frame: expr });
        }
      }
    } else if (ty.ty.k === 'struct') {
      if (
        !(typeof val === 'object') ||
        val instanceof Date ||
        Freeze.isReadonlyArray(val)
      ) {
        return err;
      }

      for (const [name, fieldTy] of Object.entries(ty.ty.fields)) {
        const fieldVal = val[name] ?? null;
        const fieldCheck = checkScalar({
          t: 'scalar',
          val: fieldVal,
          ty: fieldTy,
        });
        if (fieldCheck instanceof TyStackTrace) {
          return fieldCheck.withFrame({ frame: expr });
        }
      }
    } else if (ty.ty.k === 'enum') {
      if (!(typeof val === 'string')) {
        return err;
      }

      if (!ty.ty.t.includes(val)) {
        return err;
      }
    } else if (ty.ty.k === 'id') {
      const innerCheck = checkScalar({
        t: 'scalar',
        val,
        ty: {
          ty: { k: 'primitive', t: ty.ty.t },
          nullable: ty.nullable,
          tags: ty.tags,
        },
      });

      if (innerCheck instanceof TyStackTrace) {
        return innerCheck.withFrame({ frame: expr });
      }
    } else if (ty.ty.k === 'range') {
      if (typeof val !== 'number') {
        return err;
      }
      if (!Number.isInteger(val)) {
        return err;
      }

      if (ty.ty.start > val || ty.ty.end < val) {
        return err;
      }
    } else {
      return Assert.unreachable(ty.ty);
    }
  }

  if (val === null && ty.nullable !== true) {
    return TyStackTrace.fromErr(
      { frame: expr },
      new Errs.CantUseNullAsANonNullableScalar()
    );
  }

  const checked: ExprTypeCheck = {
    ty: ty,
    windowed: false,
    aggregated: false,
    attrReqs: {},
    vars: {},
  };
  return checked;
};

const checkCase: ExprCheck<AST._Case<ExprMacroChildren>> = (caseExpr) => {
  const outputTypes: Ty.ExtendedAttributeType[] = [];
  const checkedWhens: { when: ExprTypeCheck; then: ExprTypeCheck }[] = [];

  for (const { when, then } of caseExpr.cases) {
    const checkedWhen = checkExpr(when);

    if (checkedWhen instanceof TyStackTrace) {
      return checkedWhen.withFrame({ frame: caseExpr, location: 'when' });
    }

    if (!implementsTy({ subject: checkedWhen.ty, req: 'boolean' })) {
      return TyStackTrace.fromErr(
        { frame: caseExpr, location: 'when' },
        new Errs.NonBooleanCaseStatementCondition({
          received: checkedWhen.ty.ty,
        })
      );
    }

    const checkedThen = checkExpr(then);

    if (checkedThen instanceof TyStackTrace) {
      return checkedThen.withFrame({ frame: caseExpr, location: 'when' });
    }

    outputTypes.push(checkedThen.ty);
    checkedWhens.push({ when: checkedWhen, then: checkedThen });
  }

  const checkedElse = caseExpr.else && checkExpr(caseExpr.else);

  if (checkedElse instanceof TyStackTrace) {
    return checkedElse.withFrame({ frame: caseExpr, location: 'else' });
  }

  if (checkedElse) {
    outputTypes.push(checkedElse.ty);
  }

  const primaryType = narrowestSuperTypeOf(outputTypes);

  if (primaryType.isErr()) {
    return TyStackTrace.fromErr(
      { frame: caseExpr, location: 'when' },
      new Errs.MismatchedCaseOutputTypes({
        received: [primaryType.error.lhs, primaryType.error.rhs],
      })
    );
  }

  const components: ExprTypeCheck[] = _.compact([
    ...checkedWhens.flatMap(({ when, then }) => [when, then]),
    checkedElse,
  ]);

  const aggregated = components.some((arg) => arg.aggregated);
  const windowed = components.some((arg) => arg.windowed);

  const macroVars = mergeMacroVars(
    ...checkedWhens.flatMap(({ when, then }) => [when.vars, then.vars]),
    checkedElse?.vars ?? {}
  );

  if (macroVars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: caseExpr }, macroVars);
  }

  const attrReqs = mergeAttrReqs(
    ...checkedWhens.flatMap(({ when, then }) => [when.attrReqs, then.attrReqs]),
    checkedElse?.attrReqs ?? {}
  );

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: caseExpr }, attrReqs);
  }

  const checkedCase: ExprTypeCheck = {
    aggregated,
    windowed,
    attrReqs,
    ty: {
      ...primaryType.value,
      nullable: primaryType.value.nullable || checkedElse === undefined,
    },
    vars: macroVars,
  };

  return checkedCase;
};

const checkWindow: ExprCheck<AST._Window<ExprMacroChildren>> = (windowFunc) => {
  const frame = checkWindowFrame({
    op: windowFunc.op,
    frame: windowFunc.frame,
    containsOrderByClause: windowFunc.over.orderBy.length > 0,
  });

  if (frame instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr(
      { frame: windowFunc, location: 'frame' },
      frame
    );
  }
  const { partitionBy, orderBy } = windowFunc.over;

  const checkedPartitionBys: ExprTypeCheck[] = [];

  for (const [expr, i] of partitionBy.map((expr, i) => [expr, i] as const)) {
    const checked = checkExpr(expr);

    const frame = {
      frame: windowFunc,
      location: ['over', 'partition by', 'position', i],
    } as const;

    if (checked instanceof TyStackTrace) {
      return checked.withFrame(frame);
    }

    if (checked.aggregated) {
      return TyStackTrace.fromErr(
        frame,
        new Errs.CantUseAggregateInWindowSpecification()
      );
    }

    checkedPartitionBys.push(checked);
  }

  const checkedOrderBys: ExprTypeCheck[] = [];

  for (const [{ expr }, i] of orderBy.map((expr, i) => [expr, i] as const)) {
    const checked = checkExpr(expr);

    const frame = {
      frame: windowFunc,
      location: ['over', 'order by', 'position', i],
    } as const;

    if (checked instanceof TyStackTrace) {
      return checked.withFrame(frame);
    }

    if (checked.aggregated) {
      return TyStackTrace.fromErr(
        frame,
        new Errs.CantUseAggregateInWindowSpecification()
      );
    }

    const isArrayOrStruct =
      checked.ty.ty.k === 'array' || checked.ty.ty.k === 'struct';
    const isSuper =
      checked.ty.ty.k === 'primitive' && checked.ty.ty.t === 'super';

    if (isArrayOrStruct || isSuper) {
      return TyStackTrace.fromErr(
        { frame: windowFunc },
        new Errs.CantOrderByStructArrayOrSuper({ attempted: checked.ty })
      );
    }

    checkedOrderBys.push(checked);
  }

  const numberedArgs = windowFunc.args.map((arg, i) => [i, arg] as const);
  const checkedArgs: ExprTypeCheck[] = [];

  for (const [index, arg] of numberedArgs) {
    const checked = checkExpr(arg);

    if (checked instanceof TyStackTrace) {
      return checked.withFrame({
        frame: windowFunc,
        location: ['position', index],
      });
    }

    checkedArgs.push(checked);
  }

  const requestedSignature = checkedArgs.map((arg) => arg.ty.ty);

  const signature = WINDOW_FUNC_SIGNATURES[windowFunc.op].find(([sig, _ret]) =>
    sig.every((sigTy, i) => {
      const reqTy = requestedSignature[i];
      if (reqTy === undefined) {
        return false;
      }

      return implementsTy({ subject: reqTy, req: sigTy });
    })
  );

  if (signature === undefined) {
    return TyStackTrace.fromErr(
      {
        frame: windowFunc,
        location: 'source',
      },
      new Errs.InvalidFunctionCall({
        op: windowFunc.op,
        recieved: requestedSignature,
        allowed: WINDOW_FUNC_SIGNATURES[windowFunc.op],
      })
    );
  }

  const [_params, outputTy] = signature;

  const typecheck: Ty.ExtendedAttributeType = {
    ty: outputTy.ty,
    nullable: true,
    tags: outputTy.tags,
  };

  const macroVars = mergeMacroVars(
    ...checkedArgs.map(({ vars }) => vars),
    ...checkedOrderBys.map(({ vars }) => vars),
    ...checkedPartitionBys.map(({ vars }) => vars)
  );

  if (macroVars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: windowFunc }, macroVars);
  }

  const attrReqs = mergeAttrReqs(
    ...checkedArgs.map(({ attrReqs }) => attrReqs),
    ...checkedOrderBys.map(({ attrReqs }) => attrReqs),
    ...checkedPartitionBys.map(({ attrReqs }) => attrReqs)
  );

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: windowFunc }, attrReqs);
  }

  const checked: ExprTypeCheck = {
    attrReqs,
    aggregated: false,
    windowed: true,
    ty: typecheck,
    vars: macroVars,
  };

  return checked;
};

const checkFunctionCall: ExprCheck<AST._FunctionCall<ExprMacroChildren>> = (
  funcCall
) => {
  const numberedArgs = funcCall.args.map((arg, i) => [i, arg] as const);
  const checkedArgs: ExprTypeCheck[] = [];

  for (const [index, arg] of numberedArgs) {
    const checked = checkExpr(arg);

    if (checked instanceof TyStackTrace) {
      return checked.withFrame({
        frame: funcCall,
        location: ['position', index],
      });
    }

    checkedArgs.push(checked);
  }

  const expectedArity = FUNCTION_ARITY[funcCall.op];

  const arityErr = TyStackTrace.fromErr(
    { frame: funcCall },
    new Errs.ArityMismatch({
      name: funcCall.op,
      expected: expectedArity,
      attempted: checkedArgs.length,
    })
  );

  if (typeof expectedArity === 'number') {
    if (checkedArgs.length !== expectedArity) {
      return arityErr;
    }
  } else if (checkedArgs.length < expectedArity.gte) {
    return arityErr;
  }

  const { rule, aggregate = false } = FUNCTION_TYPING_RULES[funcCall.op];

  // If this is an aggregate function, make sure no children have already been
  // aggregated because you can't nest aggregates
  if (aggregate) {
    for (const [i, arg] of checkedArgs.map((arg, i) => [i, arg] as const)) {
      if (arg.aggregated) {
        return TyStackTrace.fromErr(
          { frame: funcCall, location: ['position', i] },
          new Errs.NestedAggregateFunctions({
            function: funcCall.op,
            argPosition: i,
          })
        );
      }

      if (arg.windowed) {
        return TyStackTrace.fromErr(
          { frame: funcCall, location: ['position', i] },
          new Errs.AggregeateFunctionsCantContainWindowedFunctions({
            argPosition: i,
          })
        );
      }
    }
  }

  const aggregated = aggregate || checkedArgs.some((arg) => arg.aggregated);

  const typecheck = rule(
    funcCall.op,
    checkedArgs.map((arg) => arg.ty)
  );

  if (typecheck.isErr()) {
    return TyStackTrace.fromErr(
      { frame: funcCall, location: 'source' },
      typecheck.error
    );
  }

  const macroVars = mergeMacroVars(...checkedArgs.map(({ vars }) => vars));

  if (macroVars instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: funcCall }, macroVars);
  }

  const attrReqs = mergeAttrReqs(...checkedArgs.map((arg) => arg.attrReqs));

  if (attrReqs instanceof Errs.TypeCheckError) {
    return TyStackTrace.fromErr({ frame: funcCall }, attrReqs);
  }

  const checked: ExprTypeCheck = {
    aggregated,
    attrReqs,
    ty: typecheck.value,
    windowed: checkedArgs.some((arg) => arg.windowed),
    vars: macroVars,
  };

  return checked;
};

const checkAttr: ExprCheck<AST._Attribute> = (attribute) => {
  const res: ExprTypeCheck = {
    vars: {},
    ty: attribute.ty,
    aggregated: false,
    windowed: false,
    attrReqs: {
      [attribute.source]: {
        [attribute.name]: attribute.ty,
      },
    },
  };

  return res;
};

const checkCast: ExprCheck<AST._Cast<ExprMacroChildren>> = (cast) => {
  const expr = checkExpr(cast.expr);

  if (expr instanceof TyStackTrace) {
    return expr.withFrame({ frame: cast, location: 'source' });
  }

  const implementsTargetType = implementsTy({
    subject: expr.ty,
    req: cast.targetTy,
  });

  const isCastFromSuper =
    expr.ty.ty.k === 'primitive' && expr.ty.ty.t === 'super';

  const isCastToSuper =
    cast.targetTy.k === 'primitive' && cast.targetTy.t === 'super';

  const isCastFromWeirdTypeToString =
    (expr.ty.ty.k === 'struct' || expr.ty.ty.k === 'range') &&
    cast.targetTy.k === 'primitive' &&
    cast.targetTy.t === 'string';

  const isValidPrimitiveToPrimitiveCast =
    cast.targetTy.k === 'primitive' &&
    ((expr.ty.ty.k === 'primitive' &&
      ALLOWED_PRIMITIVE_CASTS[expr.ty.ty.t].includes(cast.targetTy.t)) ||
      (expr.ty.ty.k === 'enum' &&
        ALLOWED_PRIMITIVE_CASTS.string.includes(cast.targetTy.t)));

  let isAllowedIdCast = false;

  if (expr.ty.ty.k === 'id') {
    if (cast.targetTy.k === 'primitive') {
      isAllowedIdCast = ALLOWED_PRIMITIVE_CASTS[expr.ty.ty.t].includes(
        cast.targetTy.t
      );
    }
    if (cast.targetTy.k === 'id') {
      isAllowedIdCast =
        expr.ty.ty.name === cast.targetTy.name &&
        expr.ty.ty.t === cast.targetTy.t;
    }
  } else if (cast.targetTy.k === 'id') {
    if (expr.ty.ty.k === 'primitive') {
      isAllowedIdCast = ALLOWED_PRIMITIVE_CASTS[expr.ty.ty.t].includes(
        cast.targetTy.t
      );
    }
    if (expr.ty.ty.k === 'enum') {
      isAllowedIdCast = ALLOWED_PRIMITIVE_CASTS.string.includes(
        cast.targetTy.t
      );
    }
    if (expr.ty.ty.k === 'range') {
      isAllowedIdCast = ALLOWED_PRIMITIVE_CASTS.int.includes(cast.targetTy.t);
    }
  }

  const isAllowedEnumToEnumCast =
    expr.ty.ty.k === 'enum' &&
    cast.targetTy.k === 'enum' &&
    implementsTy({ subject: expr.ty.ty, req: cast.targetTy });

  let isAllowedRangeCast = false;

  if (expr.ty.ty.k === 'range') {
    if (
      cast.targetTy.k === 'range' &&
      cast.targetTy.start <= expr.ty.ty.start &&
      cast.targetTy.end >= expr.ty.ty.end
    ) {
      isAllowedRangeCast = true;
    }

    if (cast.targetTy.k === 'primitive' && cast.targetTy.t === 'boolean') {
      isAllowedRangeCast = true;
    }
  }

  const isAllowedCast =
    implementsTargetType ||
    isCastToSuper ||
    isCastFromSuper ||
    isValidPrimitiveToPrimitiveCast ||
    isCastFromWeirdTypeToString ||
    isAllowedIdCast ||
    isAllowedEnumToEnumCast ||
    isAllowedRangeCast;

  if (!isAllowedCast) {
    return TyStackTrace.fromErr(
      { frame: cast, location: 'source' },
      new Errs.IllegalCast({ from: expr.ty, to: cast.targetTy })
    );
  }

  const { aggregated, windowed } = expr;

  const checked: ExprTypeCheck = {
    aggregated,
    windowed,
    vars: expr.vars,
    attrReqs: expr.attrReqs,
    ty: {
      ty: cast.targetTy,
      nullable: expr.ty.nullable,
      tags: Object.freeze([]),
    },
  };

  return checked;
};
