import type { EntityColumnInfo } from '@cotera/api';
import { Relation, Ty, From, Eq, Values } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { err, ok, Result } from 'neverthrow';
import _ from 'lodash';
import type { ColumnError } from './types';
import { UserDefinedDimensions } from '../user-defined-dimensions';
import { Tags } from '../tags';
import { MANIFEST_INFORMATATION_SCHEMA_NAME } from '../../runtime/provide';
import { MANIFEST_EAGER_DEF } from '../../runtime/vars';

const STANDARD_PRIMARY_KEY_NAME = '__ID';
export const VALID_ENTITY_COLUMN_NAME_REGEX = /^[_a-zA-Z][_a-zA-Z0-9]*$/;

export const ERA_QL_TAG_MAP = {
  eraql: Tags.EXPR_COLUMN,
  'decision-tree': Tags.DECISON_TREE,
};

export const UDD_TAG_MAP = {
  topics: Tags.TOPICS,
};

const makeEntityColumnTaggedType = (
  col: Pick<EntityColumnInfo, 'meta' | 'type'>
): Ty.ExtendedAttributeType => {
  if (col.meta.t === 'eraql' || col.meta.t === 'decision-tree') {
    return Ty.tag(col.type, [ERA_QL_TAG_MAP[col.meta.t]]);
  }
  if (col.meta.t === 'udd' && col.meta.k?.t !== undefined) {
    return Ty.tag(col.type, [UDD_TAG_MAP[col.meta.k.t]]);
  }

  return col.type;
};

export class EntityResolver {
  private constructor(
    private readonly id: Ty.IdType,
    private readonly colInfo: { readonly [colName: string]: EntityColumnInfo }
  ) {}

  static fromColumnInfo(
    id: Ty.IdType,
    colInfo: {
      readonly [colName: string]: EntityColumnInfo;
    }
  ): EntityResolver {
    return new this(id, colInfo);
  }

  get attributes(): Record<string, Ty.ExtendedAttributeType> {
    return _.mapValues(this.colInfo, (x) => {
      if (x.meta.t === 'assumption' && x.meta.partial) {
        return Ty.makeNullable(x.type);
      }
      return x.type;
    });
  }

  tryForColumns(colNames: readonly string[]): Result<Relation, ColumnError> {
    const deps = resolveDeps(this.colInfo, colNames);

    if (deps.isErr()) {
      return err(deps.error);
    }

    const assumptionTables: {
      [schema: string]: {
        [table: string]: {
          primaryKeyColName: string;
          attributes: {
            [name: string]: {
              ty: Ty.ExtendedAttributeType;
              targetName: string;
            };
          };
          partial: boolean;
        };
      };
    } = {};

    const assumptions = deps.value
      .map(([colName, { meta, type }]) =>
        meta.t === 'assumption' ? { colName, meta, type } : null
      )
      .filter((x) => x !== null);

    for (const { meta, type, colName } of assumptions) {
      Assert.assert(meta.t === 'assumption');
      const { tableName, tableSchema, primaryKeyColName, tableColName } =
        meta.ref;
      const schema = assumptionTables[tableSchema] ?? {};
      const table = schema[tableName];

      schema[tableName] = {
        attributes: {
          ...table?.attributes,
          [tableColName]: {
            ty: type,
            targetName: colName,
          },
        },
        primaryKeyColName: table?.primaryKeyColName ?? primaryKeyColName,
        partial: table?.partial ?? meta.partial,
      };
      assumptionTables[tableSchema] = schema;
    }

    const builtAssumptions = Object.entries(assumptionTables).flatMap(
      ([schema, tables]) =>
        Object.entries(tables).map(([name, def]) => {
          const { partial, primaryKeyColName } = def;
          const attributes = {
            ..._.mapValues(def.attributes, (x) => x.ty),
            [primaryKeyColName]: Ty.ty(this.id),
          };

          const source: Relation =
            schema === MANIFEST_INFORMATATION_SCHEMA_NAME
              ? MANIFEST_EAGER_DEF({ name, attributes })
              : From({ name, schema, attributes });

          return {
            source: From(source)
              .select(
                (t) => ({
                  ...(primaryKeyColName in def.attributes
                    ? t.star()
                    : t.except(primaryKeyColName)),
                  [STANDARD_PRIMARY_KEY_NAME]: t.attr(primaryKeyColName),
                }),
                { condition: (t) => t.attr(primaryKeyColName).isNotNull() }
              )
              .select((t) => ({
                //exclude the aliased columns as the will be included by the following line under their aliased name
                ...t.except(...Object.keys(def.attributes)),
                ...Object.fromEntries(
                  Object.entries(def.attributes).map(
                    ([name, { targetName }]) => [targetName, t.attr(name)]
                  )
                ),
              })),
            partial,
          };
        })
    );

    let rel: Relation;

    if (builtAssumptions.length > 0) {
      const { source } = _.sortBy(builtAssumptions, (x) => !x.partial).reduce(
        (curr, next) => {
          const combined = curr.source.leftJoin(next.source, (l, r) => ({
            on: Eq(
              l.attr(STANDARD_PRIMARY_KEY_NAME),
              r.attr(STANDARD_PRIMARY_KEY_NAME)
            ),
            select: {
              ...l.star(),
              ...r.star(),
              [STANDARD_PRIMARY_KEY_NAME]: l.attr(STANDARD_PRIMARY_KEY_NAME),
            },
          }));

          return { source: combined, partial: false };
        }
      );

      rel = source;
    } else {
      rel = Values([], { [STANDARD_PRIMARY_KEY_NAME]: this.id });
    }

    const udds = deps.value
      .map(([name, { meta, type }]) =>
        meta.t === 'udd' ? { name, type, k: meta.k } : null
      )
      .filter((t) => t !== null);

    if (udds.length > 0) {
      const uddConfig = new UserDefinedDimensions({
        id: this.id,
        attributes: Object.fromEntries(
          udds.map(({ name, type, k }) => {
            const taggedType = makeEntityColumnTaggedType({
              type,
              meta: { t: 'udd', k },
            });

            return [name, taggedType];
          })
        ),
      });
      rel = uddConfig
        .join(rel, {
          identifier: (t) => t.attr(STANDARD_PRIMARY_KEY_NAME),
        })
        .select((t) => t.except(STANDARD_PRIMARY_KEY_NAME));
    } else {
      rel = rel.select((t) => t.except(STANDARD_PRIMARY_KEY_NAME));
    }

    const eraqlAttributes = deps.value
      .map(([colName, { meta, dependsOn }]) =>
        meta.t === 'eraql' || meta.t === 'decision-tree'
          ? { colName, eraql: meta.eraql, dependsOn, t: meta.t }
          : null
      )
      .filter((t) => t !== null);

    let pending = eraqlAttributes;
    while (pending.length > 0) {
      const [level, remaining] = _.partition(pending, (x) =>
        x.dependsOn.every((name) => name in rel.attributes)
      );

      if (level.length === 0 && remaining.length !== 0) {
        throw new Error('Unresolvable??');
      }

      rel = rel.select((t) => {
        const newAttrs = level.map(({ colName, eraql, t: colType }) => [
          colName,
          t.sql([eraql]).tag(ERA_QL_TAG_MAP[colType]),
        ]);

        return {
          ...t.star(),
          ...Object.fromEntries(newAttrs),
        };
      });

      pending = remaining;
    }

    return ok(rel.select((t) => t.pick(...colNames)));
  }

  forColumns(columnNames: readonly string[]): Relation {
    const res = this.tryForColumns(columnNames);

    if (res.isOk()) {
      return res.value;
    } else {
      throw new Error(JSON.stringify(res.error));
    }
  }

  allColumns(): Relation {
    return this.forColumns(Object.keys(this.colInfo));
  }
}

const resolveDeps = (
  colInfo: Record<string, EntityColumnInfo>,
  colNames: readonly string[]
): Result<[string, EntityColumnInfo][], ColumnError> => {
  const unprocessed = [...colNames];
  const seen: Set<string> = new Set();
  const infos: [string, EntityColumnInfo][] = [];

  while (unprocessed.length > 0) {
    const colName = unprocessed.pop()!;

    if (seen.has(colName)) {
      continue;
    }

    const info = colInfo[colName];
    seen.add(colName);

    if (!info) {
      return err({ t: 'column-not-found', colName });
    }

    unprocessed.push(...info.dependsOn);
    infos.push([colName, info]);
  }

  return ok(infos);
};
