import { Layout } from '@cotera/client/app/layout';
import { Link, useParams } from 'react-router-dom';
import { EntityResolver, Tags, ERA_QL_TAG_MAP } from '@cotera/sdk/core';
import { useViewModel } from '@cotera/client/app/etc';
import {
  Copyable,
  DatasetNode,
  DisplayError,
  FilterNode,
  NotFound,
  Protected,
  SampleNode,
  SampleViewModel,
  TransformableRelation,
  WorkspaceNodes,
  WorkspaceViewModel,
} from '@cotera/client/app/components/app';
import { useEntity } from './hooks';
import { useEraScopesAwareRelIR } from '@cotera/client/app/pages/apps/compiler/macro-expansion/scopes';
import { Relation, Ty } from '@cotera/era';
import { Entity } from '@cotera/api';
import { AddEntityContainer } from './components/setup-entity';
import {
  Badge,
  Center,
  Divider,
  Loading,
  Text,
  Title,
} from '@cotera/client/app/components/ui';
import { ChildrenProps } from '../../components/utils';
import React, { startTransition, Suspense, useCallback, useMemo } from 'react';
import { useLegacyUuds } from '../../hooks/entities';
import { ErrorBoundary } from 'react-error-boundary';
import { classNames } from '@cotera/client/app/components/utils';
import { Card } from '@cotera/client/app/components/headless';
import { ApplyFn, NewColumn } from '../../components/data-grid-2';
import {
  DecisionTreeColumn,
  EditDecisionTree,
  EreQLColumn,
  LlmColumn,
} from '../../components/data-grid-2/columns';
import {
  defaultRegistry,
  StringHeader,
} from '../../components/data-grid-2/registry';
import { Assert } from '@cotera/utilities';
import { Cell } from '../../components/data-grid-2/components/cell';

export const EntityPage: React.FC = () => {
  const { entityName } = useParams() as { entityName: string };
  const entity = useEntity(entityName);

  if (!entity) {
    return (
      <Layout>
        <NotFound resource="Entity" />
      </Layout>
    );
  }

  let child: React.ReactNode;
  if (Object.keys(entity.columns).length === 0) {
    child = <SetupView entity={entity} />;
  } else {
    child = <HasColumnsView entity={entity} />;
  }

  return (
    <Layout>
      <ViewContainer entity={entity}>{child}</ViewContainer>
    </Layout>
  );
};

const ViewContainer: React.FC<{ entity: Entity } & ChildrenProps> = ({
  entity,
  children,
}) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <DisplayError error={error} />}
    >
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <ViewDataWithUdds entity={entity}>{children}</ViewDataWithUdds>
      </Suspense>
    </ErrorBoundary>
  );
};

const ViewDataWithUdds: React.FC<{ entity: Entity } & ChildrenProps> = ({
  entity,
  children,
}) => {
  const { data: udds } = useLegacyUuds({ entityId: entity.id });

  return (
    <div className="flex flex-col w-full">
      <div className="w-full">{children}</div>
      <div className="w-full">
        <Title type="title" className="mb-4">
          Legacy UDD Columns
        </Title>
        <Divider className="mb-4" />
        <ul className="flex flex-col">
          {udds.map((column, idx) => {
            return (
              <li
                key={idx}
                className={classNames('relative flex items-start space-x-3')}
              >
                <Link
                  to={`/entities/${entity.name}/columns/${column.id}`}
                  className="w-full"
                >
                  <Card.Container className="flex items-center w-full justify-between">
                    <div className="flex flex-row h-full w-full">
                      <div className="min-h-[32px] w-full flex flex-row items-center justify-between text-sm mx-4 py-4">
                        <div className="flex flex-col">
                          {column.key}
                          <Text.Caption>{column.id}</Text.Caption>
                        </div>
                        <div className="flex flex-col">
                          <Badge theme="regular">
                            {Ty.displayTy(column.ty)}
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </Card.Container>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const SetupView: React.FC<{ entity: Entity }> = ({ entity }) => {
  return (
    <Protected scopes={[['manage', 'entities']]} fallback={<></>}>
      <AddEntityContainer entity={entity} />
    </Protected>
  );
};

const HasColumnsView: React.FC<{ entity: Entity }> = ({ entity }) => {
  const resolver = EntityResolver.fromColumnInfo(entity.idType, entity.columns);
  const rel = resolver.allColumns();

  const relIR = Relation.wrap(useEraScopesAwareRelIR(rel));

  const dataset = new DatasetNode.ViewModel(
    entity.name,
    { x: 0, y: 0 },
    TransformableRelation.fromRel(relIR)
  );

  const filter = new FilterNode.ViewModel(
    'Filter',
    { x: 0, y: 0 },
    { connective: 'and', items: [] },
    dataset,
    {
      useAutoFilters: false,
    }
  );

  const sample = new SampleNode.ViewModel('Sample 1', { x: 0, y: 0 }, filter);

  const workspace = new WorkspaceViewModel([dataset, filter, sample]);

  const vm = useViewModel(workspace);

  return (
    <WorkspaceNodes
      vm={vm}
      overrides={{
        sample: ({ node }) => (
          <SampleView vm={node as SampleViewModel} entity={entity} />
        ),
      }}
    />
  );
};

const useRegistry = (vm: SampleViewModel, entity: Entity, applyFn: ApplyFn) => {
  return useMemo(() => {
    return defaultRegistry
      .unshift((_, ty) => ty.tags.includes(Tags.EXPR_COLUMN), {
        header: (rel, column, ty) => (
          <StringHeader
            rel={rel}
            column={column}
            ty={ty}
            displayTy={'expression'}
          />
        ),
        headerActions: [
          //TODO
        ],
        cell: (column, ty, value) => {
          return (
            <Cell column={column}>
              <Copyable>{String(value)}</Copyable>
            </Cell>
          );
        }
      })
      .unshift((_, ty) => ty.tags.includes(Tags.DECISON_TREE), {
        header: (rel, column, ty) => (
          <StringHeader
            rel={rel}
            column={column}
            ty={ty}
            displayTy={'decision-tree'}
          />
        ),
        headerActions: [
          (_, column) => {
            return {
              label: 'Edit',
              icon: 'edit',
              view: (props) => {
                const columnInfo = entity.columns[column];
                Assert.assert(columnInfo?.meta.t === 'decision-tree');

                return (
                  <EditDecisionTree
                    {...props}
                    vm={vm}
                    applyFn={applyFn}
                    entity={entity}
                    columnName={column}
                    columnInfo={columnInfo.meta}
                  />
                );
              },
            };
          },
        ],
      });
  }, [vm, entity, applyFn]);
};

const SampleView: React.FC<{ vm: SampleViewModel; entity: Entity }> = ({
  vm,
  entity,
}) => {
  const applyHandler = useCallback<ApplyFn>(
    ({ t: colType, value, column }) => {
      if (colType === 'eraql' || colType === 'decision-tree') {
        //non blocking
        startTransition(() => {
          vm.parent.setTransform(
            (rel) =>
              rel.select((t) => ({
                ...t.star(),
                [column]: value.tag(ERA_QL_TAG_MAP[colType]),
              })),
            column
          );
        });
      }
    },
    [vm]
  );

  const columnRegistry = useRegistry(vm, entity, applyHandler);

  return (
    <SampleNode.View
      vm={vm}
      registry={columnRegistry}
      extraColumns={[
        {
          header: (
            <NewColumn.Header
              vm={vm}
              actions={[EreQLColumn, LlmColumn, DecisionTreeColumn]}
              additionalProps={{
                entityName: entity.name,
              }}
              onApply={applyHandler}
            />
          ),
          cell: ({ index }) => <NewColumn.Body key={index} />,
        },
      ]}
    />
  );
};
