import React, { createContext, useCallback } from 'react';
import { useDeepCompareEffect, useMeasure } from 'react-use';
import {
  FocusableComponentProps,
  FormComponentProps,
} from '../../types/form-component';
import { uniqBy } from 'lodash';
import { Button } from '../../ui/button/button';
import { List } from '../../headless/list/list';
import { TextInput } from '../input';
import { Divider } from '../../ui/divider';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { makePopover } from '@cotera/client/app/components/headless';
import {
  makeStoreContextHook,
  makeStoreProvider,
  StateGetter,
  StateSetter,
} from '@cotera/client/app/etc';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { Icon, Loading, Text } from '@cotera/client/app/components/ui';

type Value = { value: string; display: string; context?: string };

type Props = {
  nullable?: boolean;
  className?: string;
  options: ({ query }: { query: string | null }) => ChildrenProps['children'];
  value: Value[];
  onChange: (value: Value[]) => void;
} & FormComponentProps;

type State = {
  selectedItems: Value[];
  query: string | null;
};

const Context = createContext<State>(undefined as any);

const baseActions =
  (onChange: (value: Value[]) => void) =>
  (set: StateSetter<State>, get: StateGetter<State, any>) => ({
    select: (value: Value) => {
      const selectedItems = uniqBy([...get().selectedItems, value], 'value');
      set(() => ({ selectedItems }));
      onChange(selectedItems);
    },
    deselect: (value: Value) => {
      const selectedItems = get().selectedItems.filter(
        (x) => x?.value !== value?.value
      );
      set(() => ({ selectedItems }));
      onChange(selectedItems);
    },
    clear: () => {
      set(() => ({ selectedItems: [] }));
      onChange([]);
    },
    setQuery: (query: string | null) => {
      set(() => ({ query }));
    },
  });

type ActionsCtor = ReturnType<typeof baseActions>;
type Actions = ReturnType<ActionsCtor>;

const singleActions =
  (onChange: (value: Value[]) => void) => (set: StateSetter<State>) => ({
    select: (value: Value) => {
      const selectedItems = [value];
      set(() => ({ selectedItems }));
      onChange(selectedItems);
    },
  });

const notNullableActions =
  (onChange: (value: Value[]) => void) =>
  (set: StateSetter<State>, get: StateGetter<State, any>) => ({
    deselect: (value: Value) => {
      if (get().selectedItems.length === 1) {
        return;
      }
      const selectedItems = get().selectedItems.filter(
        (x) => x?.value !== value?.value
      );
      set(() => ({ selectedItems }));
      onChange(selectedItems);
    },
  });

const Provider = makeStoreProvider<State, Actions>(Context);

export const useComboBox = makeStoreContextHook<State, Actions>(Context);

function ComboBoxInput({
  value,
  multi = true,
  searchEnabled = true,
  nullable = false,
  ...props
}: Props & { multi?: boolean; searchEnabled?: boolean; nullable?: boolean }) {
  const actions: ActionsCtor = (set, get) => ({
    ...baseActions(props.onChange)(set, get),
    ...(!multi ? singleActions(props.onChange)(set) : {}),
    ...(!nullable ? notNullableActions(props.onChange)(set, get) : {}),
  });
  return (
    <Provider
      key={value.map((x) => x.value).join(',')}
      state={{
        selectedItems: value,
        query: '',
      }}
      actions={actions}
    >
      <InternalComboBoxInput
        {...props}
        searchEnabled={searchEnabled}
        nullable={nullable}
      />
    </Provider>
  );
}

const Popover = makePopover(Button);

const InternalComboBoxInput: React.FC<
  Omit<Props, 'value'> & { searchEnabled: boolean }
> = ({
  options,
  label,
  compact,
  icon,
  disabled,
  className,
  searchEnabled,
  nullable,
  theme = 'regular',
}) => {
  const [ref, { width }] = useMeasure<HTMLButtonElement>();
  const selectedItems = useComboBox((store) => store.selectedItems);
  const clear = useComboBox((store) => store.actions.clear);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const query = useComboBox((store) => store.query);
  const setQuery = useComboBox((store) => store.actions.setQuery);

  const items = uniqBy(selectedItems, 'value');

  useDeepCompareEffect(() => {
    inputRef.current?.focus();
  }, [items]);

  return (
    <Popover
      ref={ref}
      as={Button}
      theme={theme}
      disabled={disabled}
      className={className}
      priority="highest"
      label={label}
      compact={compact}
      icon={icon}
      text={items.map((x) => x?.display ?? x?.value ?? 'NULL').join(', ')}
      anchor="bottom start"
      panel={{
        style: { minWidth: `${width + 12.5}px` },
        view: (
          <>
            {searchEnabled && (
              <>
                <TextInput
                  ref={inputRef}
                  inline
                  autoFocus
                  focusDelay={300}
                  icon="magnifying-glass"
                  value={query ?? 'NULL'}
                  onChange={setQuery}
                />
                <Divider className="mb-2" />
              </>
            )}
            <div className="max-h-[200px] overflow-scroll">
              {options({ query })}
            </div>
            {nullable && (
              <>
                <Divider />
                <div className="flex justify-end mr-2">
                  <Button
                    onClick={() => clear()}
                    text="Clear"
                    icon="x-circle"
                    inline
                  />
                </div>
              </>
            )}
          </>
        ),
      }}
    />
  );
};

const StaticOptions: React.FC<{
  options: Value[];
  query: string | null;
  dynamicEnabled?: boolean;
}> = ({ options, query, dynamicEnabled = true }) => {
  const selectedItems = useComboBox((store) => store.selectedItems);
  const allOptions = uniqBy(
    [
      ...options,
      ...selectedItems,
      ...(query && dynamicEnabled ? [{ value: query, display: query }] : []),
    ],
    'value'
  ).sort((a, b) => a.display?.localeCompare(b.display));
  const search = useFuzzySearch(allOptions, ['value', 'display', 'context']);

  const results = search(query);

  if (results.length === 0 && (query?.length ?? 0) === 0) {
    return (
      <Text.Caption className="px-1.5 py-1.5 mb-2 text-center">
        No results
      </Text.Caption>
    );
  }

  return (
    <List.Ul
      selectedIndex={0}
      className="px-1.5 py-1.5"
      childType={ComboBoxOption}
    >
      {results.map((option) => (
        <ComboBoxOption key={option.value} value={option} />
      ))}
    </List.Ul>
  );
};

const Options: React.FC<ChildrenProps> = ({ children }) => {
  return <List.Ul childType={ComboBoxOption}>{children}</List.Ul>;
};

const ComboBoxOption: React.FC<
  {
    value: Value;
    className?: string;
  } & FocusableComponentProps
> = ({ value, className, ...props }) => {
  const select = useComboBox((store) => store.actions.select);
  const deselect = useComboBox((store) => store.actions.deselect);
  const selectedItems = useComboBox((store) => store.selectedItems);

  const handleSelect = useCallback(() => {
    if (selectedItems.some((x) => x?.value === value.value)) {
      deselect(value);
    } else {
      select(value);
    }
  }, [deselect, select, value, selectedItems]);

  return (
    <List.Li
      {...props}
      value={value}
      onClick={handleSelect}
      className={classNames(
        className,
        'flex items-center justify-between rounded hover:bg-zinc-100 px-2 py-2 active:bg-zinc-100 cursor-pointer data-[focus]:bg-zinc-100 transition duration-200 ease-out'
      )}
    >
      <div className="flex items-center">
        {selectedItems.some((x) => x?.value === value.value) ? (
          <Icon theme="regular" icon="check" className="mr-2" />
        ) : (
          <div className="w-4 h-4 mr-2" />
        )}
        <Text.Span className="text-sm">{value.display}</Text.Span>
      </div>
      {value.context && <Text.Caption>{value.context}</Text.Caption>}
    </List.Li>
  );
};

const OptionsLoading: React.FC = () => {
  return (
    <ul className="flex flex-grow flex-col px-2 space-y-2 mb-2">
      <Loading.Shimmer className="w-full h-8" as="li" />
      <Loading.Shimmer className="w-full h-8" as="li" />
      <Loading.Shimmer className="w-full h-8" as="li" />
    </ul>
  );
};

type SingleProps = Omit<Props, 'value' | 'onChange'> & {
  value?: Value;
  onChange: (v?: Value) => void;
};
const Single: React.FC<SingleProps> = (props) => {
  return (
    <ComboBoxInput
      {...props}
      multi={false}
      onChange={(v) => {
        props.onChange(v.at(-1)!);
      }}
      value={props.value ? [props.value] : []}
    />
  );
};

const Select: React.FC<
  Omit<SingleProps, 'options' | 'value'> & { options: Value[]; value?: Value }
> = (props) => {
  const value = props.value ?? props.options[0];
  return (
    <ComboBoxInput
      {...props}
      multi={false}
      onChange={(v) => {
        props.onChange(v.at(-1)!);
      }}
      value={value ? [value] : []}
      searchEnabled={false}
      options={({ query }) => (
        <StaticOptions options={props.options} query={query} />
      )}
    />
  );
};

export const ComboBox = {
  Multi: ComboBoxInput,
  Single,
  Select,
  StaticOptions,
  Options,
  Option: ComboBoxOption,
  LoadingOptions: OptionsLoading,
};
