import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useEffect, useState } from 'react';

export type DevModeError = 'NoLocalSever' | 'OrgNotFound';

export enum DevModeStatus {
  Loading,
  Disabled,
  On,
  Error,
}

type DevMode = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
};

export const useDevMode = create(
  persist<DevMode, [], [], { enabled: boolean }>(
    (set) => ({
      enabled: false,
      setEnabled: (enabled: boolean) => set(() => ({ enabled })),
    }),
    {
      name: 'COTERA_DEV_MODE',
      partialize: (state) => ({ enabled: state.enabled }),
    }
  )
);

export const useDevModeStatus = (): DevModeStatus => {
  const devModeEnabled = useDevMode((s) => s.enabled);

  const value = () => {
    return devModeEnabled ? DevModeStatus.On : DevModeStatus.Disabled;
  };

  return useDebounce(value(), 100);
};

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
