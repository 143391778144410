import { useRef } from 'react';
import { Transition } from 'react-transition-group';

export const FadeIn: React.FC<{
  children: React.ReactNode;
  in: boolean;
  delay?: number;
  className?: string;
  duration?: number;
}> = ({ children, in: inProp, delay = 0, className, duration = 200 }) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out ${delay}ms`,
    opacity: 0,
  };

  const nodeRef = useRef<HTMLDivElement>(null);

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={className}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
