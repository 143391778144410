import { Layout } from '../../../layout';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, Center } from '@cotera/client/app/components/ui';
import { View } from './view';

export const TopicsInbox: React.FC = () => {
  const { entityName } = useParams() as { entityName: string };

  return (
    <Layout>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <View key={entityName} />
      </Suspense>
    </Layout>
  );
};
