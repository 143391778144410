import { Entity } from '@cotera/api';
import { WatchableViewModel } from '@cotera/client/app/etc';
import { Relation } from '@cotera/era';
import { ApplyFn } from '../new-column';
import { DecisionTreeColumn } from './builder';

export const EditDecisionTree: React.FC<{
  vm: WatchableViewModel<{
    rel: Relation;
  }>;
  open: boolean;
  onClose: () => void;
  applyFn: ApplyFn;
  entity: Entity;
  columnName: string;
  columnInfo: {
    eraql: string;
  };
}> = ({ open, onClose, vm, applyFn, entity, columnName, columnInfo }) => {
  const View = DecisionTreeColumn.view;

  return (
    <View
      open={open}
      vm={vm}
      onApply={applyFn}
      onClose={onClose}
      additionalProps={{
        columnName,
        entityName: entity.name,
        eraql: columnInfo.eraql,
      }}
    />
  );
};
