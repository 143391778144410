import React from 'react';
import { z } from 'zod';
import { InputProps, SingleError } from './input.props';
import { useFormContext } from 'react-hook-form';
import { classNames } from '@cotera/client/app/components/utils';
import { lowerCase, upperFirst } from 'lodash';

const SELECT_STYLES =
  'block w-full rounded border-0 py-1.5 text-standard-text ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6';

export const EnumSelect: React.FC<
  InputProps<z.ZodEnum<any>> & { error?: SingleError }
> = ({
  zodSchema,
  name,
  optional,
  wrapper: Wrapper = ({ children }) => children,
  ...props
}) => {
  const opts = Array.from([
    ...(optional ? [null] : []),
    ...Object.values(zodSchema.Values),
  ]);
  const { register } = useFormContext();
  const className = classNames(
    SELECT_STYLES,
    props.className,
    props.error ? 'bg-red-100 border-red-500' : 'text-gray-800 bg-white'
  );
  const labelText =
    zodSchema._def.meta?.displayName ?? upperFirst(lowerCase(name));
  const description = zodSchema.description;

  return (
    <Wrapper schema={zodSchema} name={name}>
      <>
        {zodSchema._def.meta?.displayName && (
          <label
            htmlFor={name}
            className={classNames(
              'block text-sm font-medium text-gray-800',
              props.error ? 'text-red-600' : 'text-gray-800'
            )}
          >
            {labelText}
          </label>
        )}
        <select
          {...register(name, { required: !optional })}
          id={name}
          name={name}
          className={className}
        >
          {opts.map((opt: string) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </select>
        <span className="font-medium text-gray-600 text-xs">{description}</span>
      </>
    </Wrapper>
  );
};
