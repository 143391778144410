import { UI, AST, Relation } from '@cotera/era';
import { Overview } from './overview';

export const ItemDetailPerformance = ({
  Orders,
  ActiveItems,
  ItemDetail,
  TodaysCustomerRfm,
}: {
  Orders: Relation;
  ActiveItems: Relation;
  ItemDetail: Relation;
  TodaysCustomerRfm?: Relation;
}): AST._App => {
  return UI.App({
    title: 'Item Performance',
    icon: 'bar-chart',
    pages: {
      '/:productId': Overview({
        Orders,
        ActiveItems,
        ItemDetail,
        TodaysCustomerRfm,
      }),
    },
    menu: [],
  });
};
