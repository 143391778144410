import { FFI } from '@cotera/sdk/core';
import React from 'react';
import { Column, Table as ITable } from '@tanstack/react-table';
import { Ty } from '@cotera/era';
import { RunTopicMatchingButton } from './run-topic-matching';

export const ColumnAction: React.FC<{
  func: FFI.FiiFuncName;
  table: ITable<any>;
  column: Column<any, any>;
  t: Ty.ExtendedAttributeType;
  onClick?: (func: FFI.FiiFuncName) => void;
}> = ({ func, column, table, onClick }) => {
  if (FFI.isFiiFuncName(func)) {
    switch (func) {
      case '@@cotera-topic-matching': {
        return (
          <RunTopicMatchingButton
            column={column}
            table={table}
            onClick={() => onClick?.(func)}
          />
        );
      }
      default:
        return <Unimplemented func={func} />;
    }
  } else {
    return <Unimplemented func={func} />;
  }
};

const Unimplemented: React.FC<{ func: string }> = ({ func }) => (
  <span>{func} is not implemented</span>
);
