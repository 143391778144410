import { AST, From, Relation } from '@cotera/era';
import React, { useContext } from 'react';
import { UiStateContext } from './ui-state';
import { classNames } from '@cotera/client/app/components/utils';
import { useAppData } from '@cotera/client/app/stores/org';
import {
  useEraRuntimeCurrentRelValue,
  useEraScopesAwareRelIR,
} from './macro-expansion/scopes';

export const RenderFilePicker: React.FC<{
  section: AST._RelVarControlsV2;
  inBlock: boolean;
}> = ({ section }) => {
  const [loading, setLoading] = React.useState(false);
  const db = useAppData((x) => x.initedDb);

  const sendUpdate = useContext(UiStateContext);
  const currentValue = useEraRuntimeCurrentRelValue(section.var);
  const chartRelIR = useEraScopesAwareRelIR(currentValue);

  return (
    <div
      className={classNames('flex flex-col', loading ? 'animate-pulse' : '')}
    >
      <DropZone
        accept={['application/json', 'text/csv', 'application/octet-stream']}
        onChange={async (files) => {
          const file = files.item(0);
          if (file) {
            setLoading(true);
            const fileName = await (await db).loadFile(file);
            const currentRel = Relation.fromAst(chartRelIR);

            sendUpdate.rel({
              scope: section.var.scope,
              name: section.var.name,
              cb: () =>
                From({ uri: fileName, attributes: currentRel.attributes }),
            });
            setLoading(false);
          }
        }}
      />
    </div>
  );
};

const DropZone: React.FC<{
  onChange: (files: FileList) => void;
  accept: string[];
}> = ({ onChange, accept = ['*'] }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = React.useState(false);

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div
      className={classNames(
        'flex w-full border border-dashed rounded px-4 py-2 cursor-pointer transition-all',
        isDragging
          ? 'bg-gray-100 border-gray-400'
          : 'bg-gray-50 border-gray-300'
      )}
    >
      <Banner
        onClick={handleClick}
        onDrop={(files) => {
          onChange(files);
        }}
        onDrag={(isDragging) => {
          setIsDragging(isDragging);
        }}
      />
      <input
        type="file"
        aria-label="add files"
        className={'hidden'}
        ref={inputRef}
        onChange={(ev) => {
          if (ev.target.files) {
            onChange(ev.target.files);
          }
        }}
        accept={accept.join(',')}
      />
    </div>
  );
};

const Banner: React.FC<{
  onClick: () => void;
  onDrop: (files: FileList) => void;
  onDrag: (isDragging: boolean) => void;
}> = ({ onClick, onDrop, onDrag }) => {
  const handleDragOver: React.DragEventHandler<HTMLDivElement> = (ev) => {
    onDrag(true);
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDrop(ev.dataTransfer.files);
    onDrag(false);
  };

  return (
    <div
      className={classNames('flex justify-center w-full text-standard-text')}
      onClick={onClick}
      onDragOver={handleDragOver}
      onDragLeave={() => {
        onDrag(false);
      }}
      onDrop={handleDrop}
    >
      <span className="text-sm font-medium text-gray-600">
        Click or Drag to Add files
      </span>
    </div>
  );
};
