export const Loading = () => (
  <svg
    version="1.1"
    viewBox="0 0 1200 1200"
    fill="#d1d1d1"
    className="w-full h-full animate-pulse"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m883.7 817.98c-31.898 0-58.625 22.949-64.449 53.227l-490.45-0.003906v-490.43c30.273-5.8516 53.227-32.5 53.227-64.477 0-36.25-29.5-65.727-65.727-65.727-36.227 0-65.727 29.477-65.727 65.727 0 31.949 22.949 58.602 53.227 64.477v502.9c0 6.8984 5.625 12.5 12.5 12.5l502.95 0.003906c5.8242 30.273 32.551 53.227 64.449 53.227 36.199 0 65.727-29.5 65.727-65.727 0-36.227-29.527-65.699-65.727-65.699zm-608.12-501.65c0-22.449 18.25-40.727 40.727-40.727 22.477 0 40.727 18.25 40.727 40.727 0 22.426-18.25 40.727-40.727 40.727-22.477 0-40.727-18.277-40.727-40.727zm608.12 608.15c-22.426 0-40.727-18.273-40.727-40.727 0-22.449 18.227-40.75 40.727-40.75s40.727 18.273 40.727 40.75c0 22.426-18.301 40.727-40.727 40.727z" />
      <path d="m484.62 543.88c23.449 0 42.449-19.051 42.449-42.449 0-23.449-19.051-42.477-42.449-42.477s-42.477 19.051-42.477 42.477c0 23.398 19.102 42.449 42.477 42.449zm0-59.977c9.6758 0 17.449 7.8242 17.449 17.477 0 9.6484-7.8242 17.449-17.449 17.449s-17.477-7.8242-17.477-17.449c0-9.6484 7.8281-17.477 17.477-17.477z" />
      <path d="m675.95 528.18c23.426 0 42.477-19.074 42.477-42.5s-19.102-42.449-42.477-42.449c-23.449 0-42.523 19.051-42.523 42.449 0 23.426 19.125 42.5 42.523 42.5zm0-59.949c9.6484 0 17.477 7.8242 17.477 17.449 0 9.6758-7.8242 17.477-17.477 17.477-9.6758 0-17.523-7.8242-17.523-17.5 0-9.6289 7.8984-17.426 17.523-17.426z" />
      <path d="m594.6 676.57c23.398 0 42.449-19.074 42.449-42.477 0-23.449-19.102-42.477-42.449-42.477-23.449 0-42.477 19.051-42.477 42.477 0 23.402 19.023 42.477 42.477 42.477zm0-59.973c9.625 0 17.449 7.8242 17.449 17.477 0 9.6484-7.8242 17.477-17.449 17.477-9.6992 0-17.477-7.8242-17.477-17.477s7.7734-17.477 17.477-17.477z" />
      <path d="m827.3 633.77c23.449 0 42.449-19.074 42.449-42.477 0-23.449-19.051-42.477-42.449-42.477-23.398 0-42.477 19.051-42.477 42.477 0 23.402 19.051 42.477 42.477 42.477zm0-59.973c9.6758 0 17.449 7.8242 17.449 17.477 0 9.6484-7.8242 17.477-17.449 17.477s-17.477-7.8242-17.477-17.477 7.8242-17.477 17.477-17.477z" />
      <path d="m865.88 461.12c23.426 0 42.449-19.051 42.449-42.477 0-23.426-19.102-42.477-42.449-42.477-23.449 0-42.523 19.074-42.523 42.477 0 23.453 19.098 42.477 42.523 42.477zm0-59.977c9.625 0 17.449 7.8242 17.449 17.477 0 9.6484-7.8242 17.477-17.449 17.477-9.6992 0-17.523-7.8242-17.523-17.477 0-9.6484 7.8242-17.477 17.523-17.477z" />
    </g>
  </svg>
);
