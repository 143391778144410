import { From } from '@cotera/era';
import { LINKEDIN_POSTS_GROWTH, LINKEDIN_POSTS_AI } from './assumptions';

export const LinkedinGrowthHacks = From(LINKEDIN_POSTS_GROWTH).innerJoin(
  From(LINKEDIN_POSTS_AI).where((t) => t.attr('RESPOND').eq('yes')),
  (post, ai) => ({
    on: post.attr('URN').eq(ai.attr('URN')),
    select: {
      ...post.star(),
    },
  })
);
