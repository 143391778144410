import { useLayoutEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

export const Collapse: React.FC<{
  children: React.ReactNode;
  in: boolean;
  minValue?: number;
  className?: string;
  delay?: number;
  duration?: number;
  property?: 'height' | 'width';
  hideOnExit?: boolean;
  closedOnStart?: boolean;
}> = ({
  children,
  in: inProp,
  minValue = 0,
  className,
  delay = 0,
  duration: _duration = 300,
  property: key = 'height',
  hideOnExit = true,
  closedOnStart = false,
}) => {
  const [duration, setDuration] = useState(closedOnStart ? 0 : _duration);
  const defaultStyle = {
    transition: `opacity ${
      duration / 2
    }ms ease-in-out, ${key} ${duration}ms ease-in-out ${delay}ms`,
    opacity: 1,
  };

  const nodeRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(0);

  const elementKey = key === 'height' ? 'clientHeight' : 'clientWidth';

  useLayoutEffect(() => {
    setValue(nodeRef.current?.[elementKey] ?? 0);
  }, [elementKey]);

  const exitOpacity = minValue === 0 ? 0 : 1;

  const makeTransitionStyles = (value: number) => ({
    entering: { opacity: 1, [key]: value },
    entered: { opacity: 1, [key]: value },
    exiting: { opacity: exitOpacity, [key]: minValue },
    exited: {
      opacity: exitOpacity,
      [key]: minValue,
      display: hideOnExit && minValue === 0 ? 'none' : undefined,
    },
    unmounted: { opacity: 0 },
  });

  const transitionStyles =
    value !== 0
      ? makeTransitionStyles(value)
      : { entering: {}, entered: {}, exiting: {}, exited: {}, unmounted: {} };

  return (
    <Transition
      nodeRef={nodeRef}
      in={inProp}
      timeout={duration}
      onEnter={() => setDuration(_duration)}
    >
      {(state) => (
        <div
          className={className}
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
