import { Result } from 'neverthrow';
import type { SetRequired } from 'type-fest';
import type { StringLiteral } from './type-magic.js';

export const matchesType = <T>(_x: T) => {};

export type IsAny<T> = unknown extends T ? (T extends {} ? T : never) : never;

export type NotAny<T> = T extends IsAny<T> ? never : T;

export function isNotAny<T>(_x: NotAny<T>) {}

// I'm pretty sure this _requires_ an explict `any` because the `any` is in the
// `extends` clause (i.e. it's not possible to have `T extends Record<string,
// unknown>`)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function assertKeys<T extends Record<string, any>, Key extends keyof T>(
  obj: T,
  keys: Key[],
  msg?: string
): SetRequired<T, Key> {
  for (const key of keys) {
    if (obj[key] === undefined) {
      const error = new Error(msg ?? 'Assertion Error');
      if ((Error as any).captureStackTrace) {
        (Error as any).captureStackTrace(error, assertKeys);
      }
      throw error;
    }
  }

  return obj as unknown as SetRequired<T, Key>;
}

export function assert(cond: boolean, msg?: string): asserts cond {
  if (!cond) {
    const error = new Error(msg ?? 'Assertion Error');
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assert);
    }
    throw error;
  }
}

export function assertErr<E>(result: Result<unknown, E>): E {
  if (result.isErr()) {
    return result.error;
  } else {
    const error = new Error(
      `Expected 'err', but got ok(${JSON.stringify(result.value)})'`
    );
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertErr);
    }
    throw error;
  }
}
export function assertErrorType<ErrorType extends string>(
  result: Result<unknown, { errorType: ErrorType }>,
  errorType: ErrorType
): void {
  let error: Error | null = null;
  if (!result.isErr()) {
    error = new Error(
      `Expected 'err', but got ok(${JSON.stringify(result.value)})'`
    );
  } else {
    if (result.error.errorType !== errorType) {
      error = new Error(
        `Expected errorType '${errorType}', but got '${result.error.errorType}'`
      );
    }
  }

  if (error !== null) {
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertErrorType);
    }

    throw error;
  }
}

export function assertOkAndPresent<T>(
  result: Result<T | null | undefined, unknown>
): T {
  let err: Error;
  if (result.isOk()) {
    if (result.value === undefined || result.value === null) {
      err = new Error(`Expected "present" but got ${result.value}`);
    } else {
      return result.value;
    }
  } else {
    err = new Error(
      `Expected 'ok', but got err(${JSON.stringify(result.error)})`
    );
  }

  if ((Error as any).captureStackTrace) {
    (Error as any).captureStackTrace(err, assertOkAndPresent);
  }
  throw err;
}

export function assertOk<T>(result: Result<T, unknown>): T {
  if (result.isOk()) {
    return result.value;
  } else {
    const error = new Error(
      `Expected 'ok', but got err(${JSON.stringify(result.error)})`
    );
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertOk);
    }
    throw error;
  }
}

export function assertExists<T>(result: T | null): T {
  if (result !== null) {
    return result;
  } else {
    const error = new Error(`Expected value, but got null'`);
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertOk);
    }
    throw error;
  }
}

export const unreachable = (_x: never): never => {
  const error = new Error('Unreachable');
  if ((Error as any).captureStackTrace) {
    (Error as any).captureStackTrace(error, unreachable);
  }
  throw error;
};

export function assertInArray<T>(
  x: any,
  arr: readonly T[],
  msg?: string
): asserts x is T {
  if (!arr.includes(x)) {
    const error = new Error(msg ?? 'Assertion Error');
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertInArray);
    }
    throw error;
  }
}

type ContractErrorOf<ErrorType extends string> = {
  errorType: ErrorType;
  message?: string;
};

export function assertNotErrorType<
  Res extends Result<any, ContractErrorOf<string>>,
  ErrType extends string
>(
  res: Res,
  type: StringLiteral<ErrType>,
  msg?: string
): Res extends Result<infer T, ContractErrorOf<infer Errs>>
  ? Result<T, ContractErrorOf<Exclude<Errs, ErrType>>>
  : never {
  if (res.isErr() && res.error.errorType === type) {
    const error = new Error(msg ?? 'Assertion Error');
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertNotErrorType);
    }
    throw error;
  }
  return res as any;
}
