import React from 'react';
import { classNames } from '@cotera/client/app/components/utils';
import { Link } from 'react-router-dom';
import { Text, Logo } from '@cotera/client/app/components';
import { config } from '@cotera/client/config/config';
import { AppsPreview } from './apps-preview';

export const WaitlistPage: React.FC = () => {
  return (
    <main className={classNames('h-screen md:flex sm:flex-col')}>
      <div className="flex flex-col md:w-2/3 h-full py-6 px-10 sm:w-full">
        <div className="flex justify-between items-center text-sm font-semibold">
          <Logo />
          <div className="flex">
            <p className="text-standard-text disabled px-4 border-divider border-r ">
              Signup
            </p>
            <Link
              to="/login"
              className="text-standard-text px-4 hover:text-primary-text"
            >
              Login
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center h-full justify-center">
          <div className="flex flex-col w-[70%]">
            <Text.H1 className="mb-8">
              <span className="text-5xl">You're on the list!</span>
            </Text.H1>
            <Text.Caption className="mb-2">Thanks for signing up.</Text.Caption>
            <Text.Caption className="mb-6">
              We'll be in touch shortly to get you onboarded.
            </Text.Caption>
            <Text.H2 className="mb-2">
              <span className="text-standard-text">
                In the meantime, check out our
              </span>{' '}
              <Link
                className="text-primary-text font-semibold"
                to={config.demoSiteUrl}
              >
                {' '}
                demo app.
              </Link>
            </Text.H2>
            <Text.Caption className="mb-8">
              Or{' '}
              <Link
                className="text-primary-text font-semibold"
                to={'https://calendly.com/d/dqq-4hv-jcz/30-minute-conversation'}
              >
                Book a walkthrough demo.
              </Link>
            </Text.Caption>
          </div>
        </div>
      </div>
      <AppsPreview />
    </main>
  );
};
