import { Contract, POST } from '@cotera/contracts';
import { z } from 'zod';

export const SuperAdminDbtContract = Contract.new({
  runDbtForOrg: POST({
    params: z.object({
      orgId: z.string().uuid(),
    }),
    output: z.object({}),
    errors: z.object({ errorType: z.literal('OrgNotFound') }),
  }),
});
