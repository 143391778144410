import { isEqual } from 'lodash';
import { Result } from 'neverthrow';

const isLocalhost = () => window.location.hostname === 'localhost';

export const isProduction = () => !isLocalhost();

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count > 1 ? suffix : ''}`;

export const filter = <T>(
  items: T[],
  filters: (((items: T[]) => T[]) | undefined | boolean)[]
) => {
  return filters
    .filter((x) => typeof x === 'function')
    .reduce((acc, filter) => filter(acc), items);
};

export function extractLetters(input: string): string {
  // Split the input string into words
  const words = input.split(' ');

  if (words.length >= 2) {
    // If there are at least two words, take the first letter of the first two words
    return words[0]![0]! + words[1]![0];
  } else {
    // If there's only one word, take the first two letters of that word
    // Check if the word has at least two letters
    return words[0]!.length > 1 ? words[0]!.substring(0, 2) : words[0]!;
  }
}

export function areResultsEqual<T, E>(
  result1: Result<T, E>,
  result2: Result<T, E>,
  isEqualFn: (a: T, b: T) => boolean = isEqual,
  isErrEqualFn: (a: E, b: E) => boolean = isEqual
): boolean {
  if (result1.isOk() && result2.isOk()) {
    return isEqualFn(result1.value, result2.value);
  }

  if (result1.isErr() && result2.isErr()) {
    return isErrEqualFn(result1.error, result2.error);
  }

  return false; // One is Ok and the other is Err
}

export const areArraysEqual = <T>(
  arr1: T[],
  arr2: T[],
  isEqualFn: (a?: T, b?: T) => boolean = isEqual
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((item, index) => isEqualFn(item, arr2[index]));
};
