import {
  Constant,
  Count,
  ExprVar,
  M,
  RelVar,
  SumOver,
  Ty,
  UI,
  UnionAll,
  Values,
} from '@cotera/era';
import { CategoricalAttributes } from '@cotera/sdk/core';
import {
  barChartMeasures,
  monthCountChartMeasures,
  monthSumChartMeasures,
  pieChartMeasures,
} from './constants';
import { DeprecatedDetailTool } from '@cotera/customers/apps/detail-tool/deprecated-detail-tool';

const showBreakdownBy = (
  slice: RelVar,
  tab: ExprVar,
  measure_dropdown: ExprVar,
  groupBy: 'category' | 'sub_category'
) => {
  return [
    UI.Block([slice.SliceBuilder()], {
      border: false,
    }),
    UI.Row([
      UI.TwoThirds(
        UI.TabsV2(tab, [
          M.muIf(tab.eq('Count'), {
            then: slice
              .groupBy((x) => ({
                month: x.attr('timestamp').dateTrunc('month'),
                [groupBy]: x.attr(groupBy),
              }))
              .select((t) => ({
                month: t.attr('month'),
                [groupBy]: t.attr(groupBy),
                count: Count(),
              }))
              .chart.BarChart(
                (t) => ({
                  x: t.attr('month'),
                  y: t.attr('count'),
                  category: t.attr(groupBy),
                }),
                {
                  title: `Monthly Breakdown by ${groupBy}`,
                  axis: {
                    x: {
                      scale: 'month',
                    },
                  },
                }
              ),
            else: slice
              .groupBy((x) => ({
                month: x.attr('timestamp').dateTrunc('month'),
                [groupBy]: x.attr(groupBy),
              }))
              .select((t) => ({
                month: t.attr('month'),
                [groupBy]: t.attr(groupBy),
                count: Count(),
              }))
              .select((t) => ({
                ...t.star(),
                percent: t
                  .attr('count')
                  .safeDiv(
                    SumOver(t.attr('count'), { partitionBy: t.attr('month') })
                  ),
              }))
              .chart.BarChart(
                (t) => ({
                  x: t.attr('month'),
                  y: t.attr('percent'),
                  category: t.attr(groupBy),
                }),
                {
                  title: `Monthly Breakdown by ${groupBy} (%)`,
                  axis: {
                    x: {
                      scale: 'month',
                    },
                  },
                }
              ),
          }),
        ])
      ),
      UI.Third([
        UI.Stats([
          slice
            .summary((_t) => ({
              value: Count(),
            }))
            .chart.Stat((t) => t.pick('value'), {
              title: 'Total Tickets',
            }),
        ]),

        CategoricalAttributes(slice, (t) => t.attr(groupBy), {
          discreteThreshold: 15,
        }).chart.SummaryChart(
          (t) => ({
            value: t.attr('count'),
            category: t.attr('value'),
          }),
          { title: `Breakdown by ${groupBy} for all time` }
        ),
      ]),
    ]),
    UI.Divider(),
    UI.Block(
      [
        [measure_dropdown.TabSelector()],
        UI.Divider(),
        DeprecatedDetailTool(
          slice.select((t) => ({
            ...t.star(),
            created_at: t.attr('timestamp'),
          })),
          measure_dropdown,
          barChartMeasures,
          monthCountChartMeasures,
          monthSumChartMeasures,
          pieChartMeasures
        ),
      ],
      { title: 'Detail Tool' }
    ),
    UI.Divider(),
    UI.Header({ title: 'Data' }),
    slice,
  ];
};

export const TaxonomyApp = [
  UI.State(
    {
      file: Values([], {
        id: 'string',
        timestamp: 'timestamp',
        summary: 'string',
        category: 'string',
        sub_category: 'string',
        sentiment: 'string',
        cost: 'float',
      }),
    },
    ({ file }) => [
      UI.Header({
        title: 'Taxonomy Breakdown',
        caption: 'A breakdown of the provided text data by category.',
      }),
      file.FilePicker(),
      UI.State(
        {
          category: Constant(null, { ty: 'string' }),
          measure_dropdown: Constant(null, {
            ty: Ty.e([
              ...barChartMeasures,
              ...monthSumChartMeasures,
              ...pieChartMeasures,
            ]),
          }),
        },
        ({ category, measure_dropdown }) => [
          UI.Block(
            [
              category.PickFrom(
                UnionAll([
                  file.select((t) => ({
                    // This fixes the duckdb inference issue
                    ...t.except('cost'),
                    cost: t.attr('cost').cast('float').coalesce(0),
                  })),
                  Values([
                    {
                      id: 'All',
                      timestamp: new Date(),
                      summary: 'All',
                      category: 'All',
                      sub_category: 'All',
                      sentiment: 'All',
                      cost: 100,
                    },
                  ]),
                ])
                  .select((t) => ({
                    ...t.star(),
                    cost: t.attr('cost').cast('float'),
                  }))
                  .orderBy((t) => t.attr('category')),
                (t) => t.sql`"category"`,
                {
                  display: 'tab-selector',
                }
              ),
            ],
            {
              border: false,
            }
          ),
          M.muIf(category.eq('All'), {
            then: [
              UI.State(
                {
                  slice: file.where((t) => t.attr('category').neq('All')),
                  tab: Constant('Count', { ty: Ty.e(['Count', 'Percent']) }),
                },
                ({ slice, tab }) => [
                  showBreakdownBy(slice, tab, measure_dropdown, 'category'),
                ]
              ),
            ],
            else: M.muIf(category.isNotNull(), {
              then: [
                UI.State(
                  {
                    slice: file.where((t) => t.attr('category').eq(category)),
                    tab: Constant('Count', { ty: Ty.e(['Count', 'Percent']) }),
                  },
                  ({ slice, tab }) => [
                    showBreakdownBy(
                      slice,
                      tab,
                      measure_dropdown,
                      'sub_category'
                    ),
                  ]
                ),
              ],
              else: UI.Block(['# Select a file and a category to begin.']),
            }),
          }),
        ]
      ),
    ]
  ),
];
