import { AST } from '../../ast';
import { MarkupTypeCheck } from '../../type-checker/check-mu';
import { TC, TyStackTrace } from '../../type-checker';
import { Expression, ExprVar } from '../expression';
import { Constant } from '../utilities';
import { Relation } from '../relation';
import { Grouping } from '../fluent-grouping';
import { Chart } from './chart';
import _ from 'lodash';

export type MarkupShorthand =
  | null
  | string
  | AST.Mu
  | ExprVar
  | Expression
  | Relation
  | Grouping
  | Markup
  | Chart
  | MarkupShorthand[];

const Noop: AST.Mu = { t: 'noop' };

export class Markup {
  constructor(
    public readonly ast: AST.Mu,
    private readonly typecheck: MarkupTypeCheck
  ) {}

  static fromShorthand(
    shorthand: MarkupShorthand,
    opts?: { jsStackPointer: Function }
  ): Markup {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const jsStackPointer = opts?.jsStackPointer ?? this.fromShorthand;

    if (shorthand === null) {
      return Markup.fromAst(Noop, { jsStackPointer });
    }

    if (typeof shorthand === 'string') {
      return Markup.fromAst(
        { t: 'text', text: Constant(shorthand).ast, modifier: 'none' },
        { jsStackPointer }
      );
    }

    if (shorthand instanceof Expression) {
      return Markup.fromAst(
        { t: 'text', text: shorthand.cast('string').ast, modifier: 'none' },
        { jsStackPointer }
      );
    }

    if (shorthand instanceof Relation || shorthand instanceof Grouping) {
      return this.fromShorthand(shorthand.chart, { jsStackPointer });
    }

    if (shorthand instanceof Chart) {
      return shorthand.DataGrid();
    }

    if (shorthand instanceof Markup) {
      return shorthand;
    }

    if (Array.isArray(shorthand)) {
      return Markup.fromAst(
        {
          t: 'sections',
          sections: shorthand.map((x) => this.fromShorthand(x).ast),
          config: { t: 'sections' },
        },
        { jsStackPointer }
      );
    }

    return Markup.fromAst(shorthand, {
      jsStackPointer,
    });
  }

  static wrap(x: AST.Mu | Markup, opts?: { jsStackPointer: Function }): Markup {
    if (x instanceof Markup) {
      return x;
    }

    return Markup.fromAst(x, {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      jsStackPointer: opts?.jsStackPointer ?? this.wrap,
    });
  }

  static fromAst(ast: AST.Mu, opts?: { jsStackPointer: Function }): Markup {
    const check = TC.MU.checkMarkup(ast);

    if (check instanceof TyStackTrace) {
      throw check.toError({
        // eslint-disable-next-line @typescript-eslint/unbound-method
        jsStackPointer: opts?.jsStackPointer ?? this.fromAst,
      });
    }

    return new this(ast, check);
  }

  get freeVars(): { readonly [scope: string]: AST.MacroArgsType } {
    return this.typecheck.vars;
  }

  get url(): AST._UiState['url'] {
    return this.typecheck.url;
  }

  // This isn't duplicate, it's useful to be able to get the relation info of a
  // chart
  RelationInfo(): Markup {
    if (!('rel' in this.ast)) {
      throw new Error(
        `Markup section "${this.ast.t}" doesnt contain a relation`
      );
    }

    const rel = this.ast.rel;

    const ri: AST._Chart = {
      t: 'chart',
      rel,
      config: { t: 'relation-info' },
      size: 'lg',
      title: null,
      format: {},
    };

    return Markup.fromAst(ri, {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      jsStackPointer: this.RelationInfo,
    });
  }
}
