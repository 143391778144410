import { z } from 'zod';
import { Contract, GET, POST } from '@cotera/contracts';

export const fivetranShopifySourceSchema = z.object({
  id: z.string().uuid(),
  shopName: z.string(),
  connectorId: z.string(),
});

export type FivetranShopifySource = z.infer<typeof fivetranShopifySourceSchema>;

export const FivetranShopifyContract = Contract.new({
  getSourceForOrg: GET({
    params: z.object({}),
    output: fivetranShopifySourceSchema.nullable(),
    errors: z.object({
      errorType: z.literal('NotFound'),
      message: z.string().optional(),
    }),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  createConnectCard: POST({
    params: z.object({
      id: z.string().uuid(),
    }),
    output: z
      .object({
        url: z.string().url(),
      })
      .nullable(),
    errors: z.object({
      errorType: z.enum(['FivetranError']),
      message: z.string().optional(),
    }),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
});

export const SOURCES = ['shopify'] as const;

export const SourcesContract = Contract.new({
  fivetranShopify: FivetranShopifyContract,
});
