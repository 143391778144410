import { Expression, Now } from '@cotera/era';

export const valueToDateRange = (
  value: Expression,
  opts?: { jsStackPointer?: Function }
): { start: Expression; end: Expression } => {
  const numDays = valueToNumDays(value, {
    jsStackPointer: opts?.jsStackPointer ?? valueToDateRange,
  });

  return {
    start: Now().dateSub('days', numDays).dateTrunc('month'),
    end: Now(),
  };
};

export const valueToNumDays = (
  value: Expression,
  opts?: { jsStackPointer?: Function }
) =>
  value.match(
    {
      '1w': 7,
      '1m': 30,
      '3m': 90,
      '4m': 120,
      '5m': 150,
      '6m': 180,
      '1y': 365,
      '2y': 365 * 2,
      max: 365 * 5,
    },
    { jsStackPointer: opts?.jsStackPointer ?? valueToNumDays }
  );
