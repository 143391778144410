import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useTenantedQueryKey } from './use-tenanted-query-key';
import { queryKeys } from './query-cache-keys';

export const useTopicVersions = (uddId: string) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.versions({ uddId, orgId })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.versions({
          uddId,
        })
      ),
    queryKey,
  });
};

export const useTopics = (uddId: string, versionId: string) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.topics({ uddId, versionId, orgId })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.topics({
          versionId,
        })
      ),
    queryKey,
  });
};
