import { AST } from '@cotera/era';
import { Route, Routes } from 'react-router-dom';
import { CompileMarkup } from './compiler';
import { Dashboard } from '../components/dashboard';
import { Column } from '../components/column';
import { Row } from '../components/row';

export const App: React.FC<{
  section: AST._App;
}> = ({ section }) => {
  return (
    <Row center={false} className="mb-6">
      <Dashboard.Wrapper>
        <Column className="flex-1 justify-center items-center w-full">
          <Routes>
            {Object.entries(section.pages).map(([key, page]) => {
              return (
                <Route
                  key={key}
                  path={key}
                  element={<CompileMarkup section={page} inBlock={false} />}
                />
              );
            })}
          </Routes>
        </Column>
      </Dashboard.Wrapper>
    </Row>
  );
};
