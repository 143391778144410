import { Writeable } from 'zod';
import _ from 'lodash';
import { AST } from '../ast';
import { Ty } from '../ty';
import { TypeCheckError } from './type-check-error';
import { implementsTy } from './implements';
import { Assert } from '../utils';

export class IncompatibleMacroExprVarTypes extends TypeCheckError {
  constructor(
    readonly params: {
      readonly scope: string;
      readonly name: string;
      readonly lhs: { type: Ty.Shorthand; defaulted: boolean };
      readonly rhs: { type: Ty.Shorthand; defaulted: boolean };
    }
  ) {
    super();
  }

  protected message(): string {
    return TypeCheckError.msg`Variable "${this.params.scope}"."${
      this.params.name
    }" has incompatible types in sub branches, found ["${Ty.displayTy(
      this.params.lhs.type
    )}${this.params.lhs.defaulted ? ' (defaulted)' : ''}", "${Ty.displayTy(
      this.params.rhs.type
    )}${this.params.rhs.defaulted ? ' (defaulted)' : ''}"]`;
  }
}

export class IncompatibleMacroRelVarDefaultSetting extends TypeCheckError {
  constructor(readonly params: { scope: string; relName: string }) {
    super();
  }

  protected override message(): string {
    return TypeCheckError.msg`Rel var "${this.params.scope}"."${this.params.relName}" is defaulted in one branch, but not another`;
  }
}

export class IncompatibleMacroRelVarTypes extends TypeCheckError {
  constructor(
    readonly params: {
      scope: string;
      varName: string;
      relName: string;
      lhs: Ty.Shorthand;
      rhs: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message(): string {
    return TypeCheckError.msg`Expr var "${this.params.varName}" inside of relation var "${this.params.scope}"."${this.params.relName}" has incompatible types in different branches ["${this.params.lhs}", "${this.params.rhs}"]"`;
  }
}

export const mergeMacroVars = (
  ...vars: { readonly [scope: string]: AST.MacroArgsType }[]
): { readonly [scope: string]: AST.MacroArgsType } | TypeCheckError => {
  const scopes = _.uniq(vars.flatMap((x) => Object.keys(x)));

  const mergedScopes: { [scope: string]: AST.MacroArgsType } = {};

  for (const scope of scopes) {
    const rels = mergeMacroRelVars(
      scope,
      _.compact(vars.map((vars) => vars[scope]?.rels))
    );

    if (rels instanceof TypeCheckError) {
      return rels;
    }

    const exprs = mergeMacroExprVars(
      scope,
      _.compact(vars.map((vars) => vars[scope]?.exprs))
    );

    const sections = vars
      .map((vars) => vars[scope]?.sections ?? {})
      .reduce((l, r) => ({ ...l, ...r }), {});

    if (exprs instanceof TypeCheckError) {
      return exprs;
    }

    mergedScopes[scope] = { rels, exprs, sections };
  }

  return mergedScopes;
};

export const mergeMacroRelVars = (
  scope: string,
  vars: AST.MacroArgsType['rels'][]
): AST.MacroArgsType['rels'] | TypeCheckError => {
  const merged: Writeable<AST.MacroArgsType['rels']> = {};

  for (const varSet of vars) {
    for (const [relName, varType] of Object.entries(varSet)) {
      const existing = merged[relName];

      if (!existing) {
        merged[relName] = varType;
      } else {
        const mergedVars: Record<string, Ty.ExtendedAttributeType> = {};

        if (existing.defaulted !== varType.defaulted) {
          return new IncompatibleMacroRelVarDefaultSetting({
            scope,
            relName,
          });
        }

        const uniqVarNames = new Set([
          ...Object.keys(existing.type),
          ...Object.keys(varType.type),
        ]);

        for (const varName of uniqVarNames) {
          const lhs = existing.type[varName];
          const rhs = varType.type[varName];

          if (!lhs) {
            Assert.assert(rhs !== undefined);
            mergedVars[varName] = rhs;
            continue;
          }

          if (!rhs) {
            Assert.assert(lhs !== undefined);
            mergedVars[varName] = lhs;
            continue;
          }

          if (!implementsTy({ subject: lhs, req: rhs })) {
            return new IncompatibleMacroRelVarTypes({
              scope,
              relName,
              varName,
              lhs,
              rhs,
            });
          }

          mergedVars[varName] = lhs;
        }

        merged[relName] = { type: mergedVars, defaulted: varType.defaulted };
      }
    }
  }

  return merged;
};

export const mergeMacroExprVars = (
  scope: string,
  varSets: AST.MacroArgsType['exprs'][]
): AST.MacroArgsType['exprs'] | TypeCheckError => {
  const merged: Writeable<AST.MacroArgsType['exprs']> = {};

  for (const vars of varSets) {
    for (const [key, ty] of Object.entries(vars)) {
      const existing = merged[key];

      if (!existing) {
        merged[key] = ty;
      } else {
        if (
          !implementsTy({ subject: existing.type, req: ty.type }) ||
          existing.defaulted !== ty.defaulted
        ) {
          return new IncompatibleMacroExprVarTypes({
            scope,
            name: key,
            lhs: existing,
            rhs: ty,
          });
        }
      }
    }
  }

  return merged;
};
