import { AST, From, Ty } from '@cotera/era';

export const SHOPIFY_CUSTOMER_ID = Ty.intId('SHOPIFY_CUSTOMER_ID');
export const SHOPIFY_ORDER_ID = Ty.intId('SHOPIFY_ORDER_ID');
export const SHOPIFY_PRODUCT_ID = Ty.intId('SHOPIFY_PRODUCT_ID');
export const SHOPIFY_VARIANT_ID = Ty.intId('SHOPIFY_VARIANT_ID');
export const SHOPIFY_IMAGE_ID = Ty.intId('SHOPIFY_IMAGE_ID');
export const SHOPIFY_FULLFILLMENT_ID = Ty.intId('SHOPIFY_FULLFILLMENT_ID');
export const SHOPIFY_COLLECTION_ID = Ty.intId('SHOPIFY_COLLECTION_ID');

export const SHOPIFY_SCHEMAS = ['SHOPIFY', 'SHOPIFY_SHOPIFY'];

export const ORDER = From({
  schema: 'SHOPIFY',
  name: 'ORDER',
  attributes: {
    ID: SHOPIFY_ORDER_ID,
    CUSTOMER_ID: SHOPIFY_CUSTOMER_ID,
    EMAIL: 'string',
    SUBTOTAL_PRICE: 'float',
    CREATED_AT: 'timestamp',
    PROCESSED_AT: 'timestamp',
    CANCELLED_AT: 'timestamp',
    FINANCIAL_STATUS: 'string',
    TOTAL_DISCOUNTS: 'float',
    TOTAL_PRICE: 'float',
    TOTAL_WEIGHT: 'float',
    SHIPPING_ADDRESS_PHONE: 'string',
    SHIPPING_ADDRESS_PROVINCE: 'string',
    SOURCE_NAME: 'string',
  },
});

export const ORDER_LINE = (schema: string = 'SHOPIFY_SHOPIFY') =>
  From({
    schema,
    name: 'SHOPIFY__ORDER_LINES',
    attributes: {
      ORDER_ID: SHOPIFY_ORDER_ID,
      PRODUCT_ID: SHOPIFY_PRODUCT_ID,
      QUANTITY: 'float',
      PRICE: 'float',
      GRAMS: 'float',
      VARIANT_ID: SHOPIFY_VARIANT_ID,
    },
  });

export const ORDER_LINE_SHOPIFY = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'ORDER_LINE',
    attributes: {
      GRAMS: 'float',
      ORDER_ID: SHOPIFY_ORDER_ID,
      PRICE: 'float',
      PRODUCT_ID: SHOPIFY_PRODUCT_ID,
      QUANTITY: 'float',
      VARIANT_ID: SHOPIFY_VARIANT_ID,
    },
  });

export const PRODUCT_IMAGE = From({
  schema: 'SHOPIFY',
  name: 'PRODUCT_IMAGE',
  attributes: {
    PRODUCT_ID: SHOPIFY_PRODUCT_ID,
    ID: SHOPIFY_IMAGE_ID,
    SRC: 'string',
    POSITION: 'int',
  },
});

export const PRODUCT = (schema: string = 'SHOPIFY_SHOPIFY') =>
  From({
    schema,
    name: 'SHOPIFY__PRODUCTS',
    attributes: {
      PRODUCT_ID: SHOPIFY_PRODUCT_ID,
      HANDLE: 'string',
      TITLE: 'string',
      STATUS: 'string',
      PRODUCT_TYPE: 'string',
      VENDOR: 'string',
      HAS_PRODUCT_IMAGE: 'boolean',
      COUNT_VARIANTS: Ty.nn('int'),
      SUBTOTAL_SOLD: 'float',
      AVG_QUANTITY_PER_ORDER_LINE: 'float',
      TOTAL_QUANTITY_SOLD: 'int',
      PRODUCT_TOTAL_DISCOUNT: 'float',
      CREATED_TIMESTAMP: 'timestamp',
    },
  });

export const PRODUCT_SHOPIFY = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'PRODUCT',
    attributes: {
      ID: SHOPIFY_PRODUCT_ID,
      TITLE: 'string',
      HANDLE: 'string',
      PRODUCT_TYPE: 'string',
      STATUS: 'string',
    },
  }).select((t) => ({ ...t.star(), PRODUCT_ID: t.attr('ID') }));

export const PRODUCT_VARIANT = From({
  schema: 'SHOPIFY',
  name: 'PRODUCT_VARIANT',
  attributes: {
    PRODUCT_ID: SHOPIFY_PRODUCT_ID,
    PRICE: 'float',
    POSITION: 'int',
    ID: SHOPIFY_VARIANT_ID,
    INVENTORY_QUANTITY: 'int',
  },
});

export const SHOPIFY__CUSTOMERS = (schema: string = 'SHOPIFY_SHOPIFY') =>
  From({
    schema,
    name: 'SHOPIFY__CUSTOMERS',
    attributes: {
      CUSTOMER_ID: SHOPIFY_CUSTOMER_ID,
      EMAIL: 'string',
      FIRST_ORDER_TIMESTAMP: 'timestamp',
      LIFETIME_ABANDONED_CHECKOUTS: 'int',
    },
  });

export const CUSTOMER_SHOPIFY = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'CUSTOMER',
    attributes: {
      ID: SHOPIFY_CUSTOMER_ID,
      EMAIL: 'string',
    },
  }).select((t) => ({ ...t.star(), CUSTOMER_ID: t.attr('ID') }));

export const DISCOUNTS = (
  schema: string = 'SHOPIFY',
  additionalColumns?: AST.TableDescription['attributes']
) =>
  From({
    schema,
    name: 'DISCOUNT_APPLICATION',
    attributes: {
      ORDER_ID: SHOPIFY_ORDER_ID,
      CODE: 'string',
      TYPE: 'string',
      INDEX: 'int',
      ...additionalColumns,
    },
  });

export const SHIPMENTS = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'ORDER_SHIPPING_LINE',
    attributes: {
      ORDER_ID: SHOPIFY_ORDER_ID,
      PRICE: 'float',
      DISCOUNTED_PRICE: 'float',
    },
  });

export const FULFILLMENTS = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'FULFILLMENT',
    attributes: {
      ORDER_ID: SHOPIFY_ORDER_ID,
      ID: SHOPIFY_FULLFILLMENT_ID,
      TRACKING_COMPANY: 'string',
    },
  });

export const UTMS = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'ORDER_URL_TAG',
    attributes: {
      ORDER_ID: SHOPIFY_ORDER_ID,
      KEY: 'string',
      VALUE: 'string',
    },
  });

export const FULFILLMENT_EVENT = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'FULFILLMENT_EVENT',
    attributes: {
      ORDER_ID: SHOPIFY_ORDER_ID,
      STATUS: 'string',
    },
  });

export const SHOPIFY__CUSTOMERS__ANALYSIS = (
  schema: string = 'SHOPIFY_SHOPIFY'
) =>
  From({
    schema,
    name: 'SHOPIFY__CUSTOMERS',
    attributes: {
      CUSTOMER_ID: SHOPIFY_CUSTOMER_ID,
      MARKETING_CONSENT_STATE: 'string',
      FIRST_ORDER_TIMESTAMP: 'timestamp',
      MOST_RECENT_ORDER_TIMESTAMP: 'timestamp',
      AVG_ORDER_VALUE: 'float',
      LIFETIME_TOTAL_SPENT: 'float',
      LIFETIME_COUNT_ORDERS: 'int',
      LIFETIME_TOTAL_REFUNDED: 'int',
      AVG_QUANTITY_PER_ORDER: 'float',
      AVG_DISCOUNT_PER_ORDER: 'float',
    },
  });

export const ABANDONED_CHECKOUT = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'ABANDONED_CHECKOUT',
    attributes: {
      ID: 'int',
      CUSTOMER_ID: SHOPIFY_CUSTOMER_ID,
      CREATED_AT: 'timestamp',
    },
  });

export const ABANDONED_CHECKOUT_LINE = (schema: string = 'SHOPIFY') =>
  From({
    schema,
    name: 'ABANDONED_CHECKOUT_LINE',
    attributes: {
      CHECKOUT_ID: 'int',
      QUANTITY: 'float',
      PRODUCT_ID: SHOPIFY_PRODUCT_ID,
      PRICE: 'float',
    },
  });
