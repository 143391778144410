import React from 'react';
import { ReactConstructor } from '../types/utils';
import { ChildrenProps } from '@cotera/client/app/components/utils';

export const focusableChildren =
  (children: ChildrenProps['children']) =>
  ({ focus }: { focus: boolean }) =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement<any>(child, {
          'data-focus': focus === true ? true : undefined,
        });
      }
      return child;
    });

function recursiveMap(
  children: ChildrenProps['children'],
  fn: (child: React.ReactNode) => React.ReactNode,
  stop?: (child: React.ReactNode) => boolean
): ChildrenProps['children'] {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children && !stop?.(child)) {
      child = React.cloneElement<any>(child, {
        children: recursiveMap(child.props.children, fn),
      });
    }

    return fn(child);
  });
}

const isOfType = (type: ReactConstructor) => (child: React.ReactNode) =>
  React.isValidElement(child) && child.type === type;

const ofType = (type: ReactConstructor) => (children: React.ReactNode) => {
  const traverse = (children: ChildrenProps['children']) => {
    const items: React.ReactElement[] = [];

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      if (child.type !== type) {
        if (child.props.children) {
          items.push(...traverse(child.props.children));
        }
      } else {
        items.push(child);
      }
    });

    return items;
  };

  return traverse(children);
};

export const Children = {
  recursiveMap,
  isOfType,
  ofType,
};
