import { useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedClient, useWhoami } from '../../stores/org';
import { Assert } from '@cotera/utilities';

export const useReasons = () => {
  const client = useTenantedClient();
  const orgId = useWhoami((s) => s.org.id);

  return useSuspenseQuery({
    queryFn: async () => Assert.assertOk(await client.reasons.reasons({})),
    queryKey: ['reasons', orgId],
  });
};
