import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

export const Section: React.FC<
  ChildrenProps & {
    className?: string;
    direction?: 'horizontal' | 'vertical';
    tight?: boolean;
    top?: boolean;
  }
> = ({ children, direction = 'horizontal', className, tight, top = true }) => {
  return (
    <section
      className={classNames(
        'flex',
        tight ? 'mx-2 mb-2' : 'mx-4 mb-4',
        top ? (tight ? 'mt-2' : 'mt-4') : '',
        direction === 'vertical' ? 'flex-col' : 'flex-row',
        className
      )}
    >
      {children}
    </section>
  );
};
