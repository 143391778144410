import React, { HTMLProps } from 'react';
import { classNames } from '../../utils';

export function Checkbox({
  indeterminate,
  className = '',
  onChange,
  value,
  ...rest
}: {
  indeterminate?: boolean;
  onChange?: (value: boolean) => void;
  value?: boolean;
} & Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'value'>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      checked={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.checked);
        }
      }}
      className={classNames(
        className,
        'cursor-pointer focus:outline-0 outline-0 rounded focus:ring-0'
      )}
      {...rest}
    />
  );
}
