import { classNames } from '@cotera/client/app/components/utils';
export const Main: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <main
      className={classNames(
        'main min-h-screen p-8 flex flex-row justify-center items-center',
        className
      )}
    >
      {children}
    </main>
  );
};
