import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import React from 'react';

export const Column: React.FC<ChildrenProps & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames('flex flex-col', className)}>{children}</div>
  );
};
