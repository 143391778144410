import isEqual from 'fast-deep-equal';
import { useMemo, useRef } from 'react';

export const useDeepMemoValue = <T>(val: T): T => {
  const ref = useRef<T>(val);
  if (!isEqual(val, ref.current)) {
    ref.current = val;
  }
  return ref.current;
};

export const useDeepMemo = <T>(fn: () => T, deps: unknown[]) => {
  const ref = useRef<T>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(fn, deps);
  if (ref.current === undefined || !isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export const useDepsDeepEqMemo = <T>(fn: () => T, deps: unknown[]): T => {
  const depsRef = useRef(deps);
  const valRef = useRef<{ val: T }>();

  if (valRef.current === undefined || !isEqual(depsRef.current, deps)) {
    depsRef.current = deps;
    valRef.current = { val: fn() };
  }

  return valRef.current.val;
};
