import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { Layout } from '../../layout';
import React, { Suspense, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useTenantedClient } from '../../stores/org';
import { Assert } from '@cotera/utilities';
import {
  Section,
  Alert,
  Title,
  Badge,
  Button,
  Loading,
  Divider,
  toast,
  Text,
} from '@cotera/client/app/components/ui';
import { round } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { useReasons } from './hooks';
import { Relation } from '@cotera/era';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { ResultBoundary } from '@cotera/client/app/components/boundaries';
import { z } from 'zod';
import {
  Card,
  FocusWithin,
  List,
} from '@cotera/client/app/components/headless';
import { DisplayError } from '@cotera/client/app/components/app';
import { usePrompts } from '../../hooks/prompts';
import { classNames, Styles } from '@cotera/client/app/components/utils';
import { useDefinition } from '@cotera/client/app/etc/data/manifest';
import { useKeyPress } from '../../hooks/use-key-press';
import { useEntity, useIdeConfig } from '../../hooks/entities';

const useExamples = (id: string) => {
  const client = useTenantedClient();

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(await client.reasons.examples({ reasonId: id })),
    queryKey: ['reasons-examples', 'examples', id],
  });
};

const useAddExample = ({ reasonId }: { reasonId: string }) => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ topicId }: { topicId: string }) =>
      Assert.assertOk(await client.reasons.addExample({ reasonId, topicId })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['reasons-examples', 'examples', reasonId],
      });
    },
  });
};

const useDeleteExample = ({ reasonId }: { reasonId: string }) => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ topicId }: { topicId: string }) =>
      Assert.assertOk(await client.reasons.deleteExample({ topicId })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['reasons-examples', 'examples', reasonId],
      });
    },
  });
};

const useSearch = (query: string) => {
  const client = useTenantedClient();

  return useSuspenseQuery({
    queryFn: () => client.reasons.search({ query }),
    queryKey: ['reasons', 'search', query],
  });
};

const useDryRun = () => {
  const client = useTenantedClient();

  return useMutation({
    mutationFn: async ({
      entityName,
      topicExtractionPromptId,
      summaryPromptId,
      messages,
    }: {
      entityName: string;
      topicExtractionPromptId: string;
      summaryPromptId: string;
      messages: { content: string; coteraStableId: string }[];
    }) =>
      Assert.assertOk(
        await client.reasons.dryRun({
          entityName,
          topicExtractionPromptId,
          summaryPromptId,
          messages,
        })
      ),
  });
};

export const ManageReason: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<Loading.Dots />}>
        <ManageReasonView />
      </Suspense>
    </Layout>
  );
};

type Match = {
  keywordName: string;
  keywordId: string;
  sentiment: string;
  messageStableId: string;
};

const ManageReasonView: React.FC = () => {
  const { id } = useParams() as { id: string };
  const [form, setForm] = useState<string>();
  const { data: reasons } = useReasons();
  const reason = reasons.find((x) => x.id === id);

  Assert.assert(reason !== undefined);
  const {
    data: { entities },
  } = useIdeConfig();
  const [entityName] = Object.entries(entities).find(
    ([_, x]) => x.uuid === reason.entityId
  )!;
  const [selectedDefinition, setSelectedDefinition] = useState<string | null>(
    null
  );
  const [selectedMessages, setSelectedMessages] = useState<
    { id: string; content: string }[]
  >([]);
  const [topicExtractionPrompt, setTopicExtractionPrompt] = useState<string>();
  const [summaryPrompt, setSummaryPrompt] = useState<string>();
  const dryRun = useDryRun();
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(false);

  const toggleSelectedId = useCallback(
    ({ id, content }: { id: string; content: string }) => {
      setSelectedMessages((messages) => {
        if (messages.some((x) => x.id === id)) {
          return messages.filter((x) => x.id !== id);
        }
        return [
          ...messages,
          {
            id,
            content,
          },
        ];
      });
    },
    []
  );

  return (
    <Section direction="vertical" className="w-full">
      <div className="flex w-full items-center mb-2">
        <Title
          title={`Manage Reason - ${reason.name}`}
          type="title"
          className="mr-2"
        />
        <Badge theme="primary" className="ml-2">
          {entityName}
        </Badge>
      </div>
      <Divider className="mb-4" />
      <div className="flex w-full h-full">
        <div className="flex flex-col w-1/2 h-full">
          <Form onSubmit={setForm} />
          <Suspense fallback={<Loading.Dots />}>
            {form && <Search query={form} id={id} />}
          </Suspense>
        </div>
        <div className="flex flex-col w-1/2 ml-4">
          <Card.Container className="h-1/2 overflow-scroll">
            <Card.Content>
              <Title title="Examples" type="section" className="mb-2" />
              <Divider className="mb-4" />
              <Suspense fallback={<Loading.Dots />}>
                <ErrorBoundary
                  fallbackRender={({ error }) => <DisplayError error={error} />}
                >
                  <Examples id={id} />
                </ErrorBoundary>
              </Suspense>
            </Card.Content>
          </Card.Container>
          <Card.Container className="h-1/2">
            <Card.Header>
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <Title title="Test Run" type="section" className="mr-2" />
                  {loading && <Loading.Spinner />}
                </div>
                {selectedDefinition && (
                  <div className=" flex items-center">
                    <Badge theme="primary">{selectedDefinition}</Badge>
                    <Button
                      theme="error"
                      inline
                      iconOnly
                      icon="x-mark"
                      text="Change Dataset"
                      onClick={() => {
                        setSelectedDefinition(null);
                        setSelectedMessages([]);
                      }}
                      className="ml-2"
                    />
                  </div>
                )}
                {selectedDefinition && (
                  <Button
                    disabled={
                      !topicExtractionPrompt ||
                      !summaryPrompt ||
                      selectedMessages.length === 0
                    }
                    compact
                    iconOnly
                    theme="secondary"
                    icon="play"
                    onClick={async () => {
                      setLoading(true);
                      try {
                        const results = await dryRun.mutateAsync({
                          entityName,
                          topicExtractionPromptId: topicExtractionPrompt!,
                          summaryPromptId: summaryPrompt!,
                          messages: selectedMessages.map((x) => ({
                            content: x.content,
                            coteraStableId: x.id,
                          })),
                        });
                        if (results.length === 0) {
                          toast.warning('No matches found');
                        } else {
                          setMatches(
                            results.map((x) => ({
                              keywordName: reasons.find(
                                (y) => y.id === x.keywordId
                              )!.name,
                              keywordId: x.keywordId,
                              sentiment: x.sentiment,
                              messageStableId: x.messageStableId,
                            }))
                          );
                          toast.success(`Matched ${results.length} items`);
                        }
                      } catch (e) {
                        toast.error((e as Error).message);
                      } finally {
                        setLoading(false);
                      }
                    }}
                  />
                )}
              </div>
            </Card.Header>
            <Card.Content className="h-full overflow-scroll">
              <Suspense fallback={<Loading.Dots />}>
                <ErrorBoundary
                  fallbackRender={({ error }) => <DisplayError error={error} />}
                >
                  <TestRun
                    entityName={entityName}
                    selectedDefinition={selectedDefinition ?? undefined}
                    onChange={setSelectedDefinition}
                    onToggle={toggleSelectedId}
                    selectedMessages={selectedMessages}
                    summaryPrompt={summaryPrompt}
                    topicExtractionPrompt={topicExtractionPrompt}
                    onChangeSummaryPrompt={setSummaryPrompt}
                    onChangeTopicExtractPrompt={setTopicExtractionPrompt}
                    matches={matches}
                  />
                </ErrorBoundary>
              </Suspense>
            </Card.Content>
          </Card.Container>
        </div>
      </div>
    </Section>
  );
};

const TestRun: React.FC<
  {
    matches: Match[];
    topicExtractionPrompt?: string;
    summaryPrompt?: string;
    entityName: string;
    selectedDefinition?: string;
    onChange: (val: string) => void;
    onChangeTopicExtractPrompt: (val: string) => void;
    onChangeSummaryPrompt: (val: string) => void;
  } & SelectedMessageProps
> = ({
  entityName,
  selectedDefinition,
  onChange,
  topicExtractionPrompt,
  summaryPrompt,
  onChangeSummaryPrompt,
  onChangeTopicExtractPrompt,
  ...props
}) => {
  const definitions = useEntity({ entityName }).definitions;
  const {
    data: { prompts },
  } = usePrompts();

  //todo filter definitions that have a content column

  if (Object.keys(definitions).length === 0) {
    return (
      <Alert variant="warn" message="No definitions found for this entity" />
    );
  }

  if (!selectedDefinition) {
    return (
      <ul>
        {Object.entries(definitions).map(([name, _], i) => {
          return (
            <li
              className={classNames(
                'mb-2 cursor-pointer',
                Styles.focus['inline']
              )}
              key={i}
              onClick={() => {
                onChange(name);
              }}
            >
              <div className="flex items-center justify-between   w-full">
                {name}
                <Button
                  inline
                  iconOnly
                  icon="chevron-right"
                  text="Use Dataset"
                  onClick={() => {}}
                />
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
  return (
    <div className="flex flex-col w-full">
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex items-center mb-4"
      >
        <ComboBox.Single
          className="mr-2"
          label="Topic Extraction Prompt"
          compact
          value={
            topicExtractionPrompt
              ? {
                  value: topicExtractionPrompt,
                  display: prompts.find((x) => x.id === topicExtractionPrompt)!
                    .name,
                }
              : undefined
          }
          onChange={(v) => v && onChangeTopicExtractPrompt(v.value)}
          options={({ query }) => (
            <ComboBox.StaticOptions
              options={prompts.map((x) => ({
                value: x.id,
                display: x.name,
              }))}
              query={query}
            />
          )}
        />
        <ComboBox.Single
          label="Summary Prompt"
          compact
          value={
            summaryPrompt
              ? {
                  value: summaryPrompt,
                  display: prompts.find((x) => x.id === summaryPrompt)!.name,
                }
              : undefined
          }
          onChange={(v) => v && onChangeSummaryPrompt(v.value)}
          options={({ query }) => (
            <ComboBox.StaticOptions
              options={prompts.map((x) => ({
                value: x.id,
                display: x.name,
              }))}
              query={query}
            />
          )}
        />
      </form>
      <Dataset definition={selectedDefinition} {...props} />
    </div>
  );
};

type SelectedMessageProps = {
  selectedMessages: { id: string; content: string }[];
  onToggle: (msg: { id: string; content: string }) => void;
};

const Dataset: React.FC<
  { definition: string; matches: Match[] } & SelectedMessageProps
> = ({ definition, ...props }) => {
  const { data: def } = useDefinition({ id: definition });

  return (
    <ResultBoundary
      result={def}
      fallback={() => (
        <Alert variant="error" message={`Definition "${definition}"`} />
      )}
    >
      {(def) => <WithDef def={Relation.wrap(def)} {...props} />}
    </ResultBoundary>
  );
};

const WithDef: React.FC<
  {
    def: Relation;
    matches: Match[];
  } & SelectedMessageProps
> = ({ def, selectedMessages, onToggle, matches }) => {
  const sample = useDuckDBQuery({ rel: def.limit(50) });
  const selectedIds = selectedMessages.map((x) => x.id);
  const data = sample.data.data.toArrayOf(
    z.object({
      content: z.string(),
      cotera_stable_id: z.string(),
    })
  );

  return (
    <ul className="overflow-scroll">
      {data.map((item, i) => {
        const match = matches.find(
          (x) => x.messageStableId === item.cotera_stable_id
        );
        return (
          <li className="mb-4 border-b border-divider pb-2" key={i}>
            <div className="flex flex-col w-full">
              <div className="flex w-full items-center mb-1 justify-between">
                <div className="flex items-center">
                  <Inputs.Checkbox
                    checked={selectedIds.includes(item.cotera_stable_id)}
                    className="mr-2"
                    onChange={() => {
                      onToggle({
                        id: item.cotera_stable_id,
                        content: item.content,
                      });
                    }}
                  />
                  <Text.Caption>{item.cotera_stable_id}</Text.Caption>
                </div>
                {match && (
                  <div className="flex items-center">
                    <Badge className="mr-2" theme="primary">
                      {match.keywordName}
                    </Badge>
                    <Badge theme="primary">{match.sentiment}</Badge>
                  </div>
                )}
              </div>
              <Text.P className="mt-1 line-clamp-2 leading-6 overflow-ellipsis">
                {item.content}
              </Text.P>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const Form: React.FC<{
  onSubmit: (value: string) => void;
}> = ({ onSubmit }) => {
  const [value, setValue] = useState('');
  const [hasFocus, setHasFocus] = useState(false);

  useKeyPress('Enter', () => {
    if (hasFocus) {
      onSubmit(value);
    }
  });

  return (
    <FocusWithin
      onBlur={() => setHasFocus(false)}
      onFocus={() => setHasFocus(true)}
    >
      {() => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex w-full mb-4"
        >
          <Inputs.Text
            className="flex-grow mr-2"
            icon="search"
            value={value}
            onChange={(v) => {
              setValue(v);
            }}
          />
          <Button
            theme="secondary"
            text="Search"
            type="submit"
            onClick={() => onSubmit(value)}
          />
        </form>
      )}
    </FocusWithin>
  );
};

const Search: React.FC<{ query: string; id: string }> = ({ query, id }) => {
  const { data } = useSearch(query);

  const results = Assert.assertOk(data);
  const addExample = useAddExample({ reasonId: id });

  const onAddHandler = useCallback(
    (item: { topicId: string }) => {
      addExample.mutate({ topicId: item.topicId });
    },
    [addExample]
  );

  return (
    <>
      <Card.Container className="h-full overflow-scroll">
        <List.Ul className="pl-2 pr-4 py-2" hasFocus={true}>
          {results.map((item, i) => {
            return (
              <List.Li
                as="li"
                key={i}
                onClick={() => onAddHandler(item)}
                className={classNames(
                  'data-[focus]:bg-zinc-200 transition-colors rounded cursor-pointer px-2 py-2 flex border-b boder-divder mb-2'
                )}
              >
                <div className="flex flex-col w-full">
                  <div className="flex items-center mb-1 justify-between">
                    <div className="flex items-center">
                      <span className="mr-2 p-1.5 bg-indigo-50 text-primary-text rounded border border-indigo-200 text-xs font-semibold text-center">
                        {round(item.distance ?? 0, 2)}
                      </span>
                      <Text.Caption>{item.topicId}</Text.Caption>
                    </div>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddHandler(item);
                      }}
                      theme="secondary"
                      icon="plus"
                      iconOnly
                      tooltip="left"
                      text="Add Example"
                    />
                  </div>
                  <p className="mt-1 line-clamp-2 text-sm leading-6 text-standard-text overflow-ellipsis">
                    {item.content}
                  </p>
                </div>
              </List.Li>
            );
          })}
        </List.Ul>
      </Card.Container>
    </>
  );
};

const Examples: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data } = useExamples(id);
  const deleteExample = useDeleteExample({ reasonId: id });

  return (
    <ul>
      {data.map((x, i) => {
        return (
          <li className="mb-2" key={i}>
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between   w-full">
                <div className="flex items-center">
                  <div className="w-[70px]">
                    <Badge theme="primary">{x.type}</Badge>
                  </div>
                  <Text.Span>{x.content}</Text.Span>
                </div>
                <Button
                  theme="error"
                  iconOnly
                  icon="trash"
                  tooltip="left"
                  text="Delete Example"
                  inline
                  onClick={() => {
                    deleteExample.mutate({ topicId: x.topicId });
                  }}
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
