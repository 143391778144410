import { z } from 'zod';
import { Result, ok, err } from 'neverthrow';

export const parseZodToResult = <T extends z.ZodTypeAny>(
  schema: T,
  data: unknown
): Result<z.infer<T>, z.ZodError> => {
  const result = schema.safeParse(data);

  switch (result.success) {
    case true:
      return ok(result.data);
    case false:
      return err(result.error);
  }
};
