import { AST, Relation } from '@cotera/era';
import { DocumentListForRelation } from '@cotera/client/app/components';

type ChartSection = AST.Chart._Chart;

export const RenderSemanticSearchResults: React.FC<{
  section: ChartSection;
}> = ({ section }) => {
  return <DocumentListForRelation rel={Relation.wrap(section.rel)} />;
};
