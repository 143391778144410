export const LoadingDots = () => {
  return (
    <div className="py-2 px-5 flex items-center flex-col">
      <div className="loader-dots block relative w-20 h-5 mt-2">
        <div className="absolute top-0 mt-1 w-3 h-3 rounded-full border border-indigo-400 bg-white"></div>
        <div className="absolute top-0 mt-1 w-3 h-3 rounded-full border border-indigo-400 bg-white"></div>
        <div className="absolute top-0 mt-1 w-3 h-3 rounded-full border border-indigo-400 bg-white"></div>
        <div className="absolute top-0 mt-1 w-3 h-3 rounded-full border border-indigo-400 bg-white"></div>
      </div>
    </div>
  );
};
