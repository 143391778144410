import { ArtifactFullfillment, ArtifactRequest } from '@cotera/api';
import { useInvalidateArtifactById } from '@cotera/client/app/hooks/artifacts';
import { Layout } from '@cotera/client/app/layout';
import { Assert } from '@cotera/utilities';
import { useParams } from 'react-router-dom';
import { Relation, From } from '@cotera/era';
import { config } from '@cotera/client/config/config';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { DataGridForRelation } from '@cotera/client/app/components/data-vis';
import {
  useIrForSqlHash,
  useSqlInWarehouseDialect,
  useWarehouseConnection,
} from '@cotera/client/app/hooks';
import React from 'react';
import { Loading, Button } from '@cotera/client/app/components/ui';
import { useSuspenseQuery } from '@tanstack/react-query';
import { NotFound } from '@cotera/client/app/components/app';

const ArtifactRequestDetails: React.FC<{
  artifact: ArtifactRequest;
}> = ({ artifact }) => {
  const wc = useWarehouseConnection();
  const ir = useIrForSqlHash({ sqlHash: artifact.sqlHash });
  const invalidate = useInvalidateArtifactById({ id: artifact.id });

  let invalidationIcon: React.ReactNode;

  if (artifact.invalidatedAt) {
    invalidationIcon = (
      <div>Invalidated At: {artifact.invalidatedAt.toString()}</div>
    );
  } else if (invalidate.isPending) {
    invalidationIcon = <Loading.Spinner />;
  } else {
    invalidationIcon = (
      <Button
        text="Invalidate"
        theme="error"
        onClick={() => invalidate.mutate()}
      />
    );
  }

  return (
    <div>
      <div className="p-4 space-y-4">
        {invalidationIcon}
        <details>
          <summary>SQL</summary>
          {wc.data !== undefined && ir.data ? (
            ir.data === null ? (
              <NotFound />
            ) : (
              <RenderSQL rel={Relation.wrap(ir.data)} />
            )
          ) : (
            <Loading.Shimmer />
          )}
        </details>
        <details>
          <summary>Advanced</summary>
          <div className="max-w-7xl">
            <pre className="max-w-7xl p-4 rounded  border border-divider font-mono overflow-auto">
              {JSON.stringify(artifact, null, 2)}
            </pre>
          </div>
        </details>
      </div>
      {artifact.fullfillment && (
        <Fullfillment
          fullfillment={artifact.fullfillment}
          fromArtifactId={artifact.id}
        />
      )}
    </div>
  );
};

export const RenderSQL: React.FC<{ rel: Relation }> = ({ rel }) => {
  const sql = useSqlInWarehouseDialect({ rel });

  if (sql.data === undefined) {
    return <Loading.Shimmer />;
  }

  return (
    <div>
      <pre className="p-4 rounded bg-white border border-divider font-mono overflow-auto">
        {sql.data.sql}
      </pre>
      <details className="p-4">
        <summary>Params</summary>
        <pre className="">{JSON.stringify(sql.data.params, null, 2)}</pre>
      </details>
    </div>
  );
};

export const ViewArtifact: React.FC<{}> = () => {
  const { artifactId } = useParams();
  Assert.assert(artifactId !== undefined);
  const api = useTenantedClient();

  const { data: req } = useSuspenseQuery({
    queryKey: ['artifact', { id: artifactId }],
    queryFn: async () => {
      return api.artifacts.getRequestById({ id: artifactId });
    },
  });

  return (
    <Layout>
      <div className="w-full p-4">
        {req.isOk() && req.value !== null ? (
          <ArtifactRequestDetails artifact={req.value} />
        ) : (
          <NotFound resource="artifact" />
        )}
      </div>
    </Layout>
  );
};

const Fullfillment: React.FC<{
  fullfillment: ArtifactFullfillment;
  fromArtifactId: string;
}> = ({ fullfillment, fromArtifactId }) => {
  const orgId = useWhoami((x) => x.org.id);

  const Rel = From({
    uri: `${config.apiUrl}/assets/artifacts/${orgId}/${fullfillment.id}.parquet`,
    attributes: fullfillment.signature,
  });

  return (
    <div className="p-2 drop-shadow-md">
      <DataGridForRelation rel={Rel} fromArtifactId={fromArtifactId} />
    </div>
  );
};
