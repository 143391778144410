import { Layout } from '../../layout';
import { FIVETRAN_CONNECTORS } from '@cotera/api';
import { useFivetranConnectors } from '../../hooks/use-fivetran-connections';
import { Connector } from './connector';
import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { Loading, Text } from '@cotera/client/app/components/ui';
import { H1 } from '@cotera/client/app/components/ui/typography';
import { Inputs } from '@cotera/client/app/components/forms';

type FivetranDestinationAndConnectors = {
  groupId: string;
  connectors: {
    id: string;
    connector: string;
    config?: any;
  }[];
};

export const NewSourcePage: React.FC = () => {
  const { data, error, isLoading, isError } = useFivetranConnectors();

  if (isLoading || data === undefined) {
    return (
      <Layout>
        <Loading.Dots />
      </Layout>
    );
  }

  if (isError && error) {
    return <Layout>err</Layout>;
  }

  return (
    <Layout className="flex flex-col">
      <ConnectorsWrapper fivetranConnectors={data} />
    </Layout>
  );
};

const ConnectorsWrapper: React.FC<{
  fivetranConnectors: FivetranDestinationAndConnectors | null;
}> = ({ fivetranConnectors }) => {
  const establishedConnections = useMemo(() => {
    return (fivetranConnectors ?? { connectors: [] }).connectors.map(
      (c) => c.connector
    );
  }, [fivetranConnectors]);

  const availableConnectors = useMemo(() => {
    return FIVETRAN_CONNECTORS.filter(
      (c) => !establishedConnections.includes(c)
    );
  }, [establishedConnections]);

  const fuse = useMemo(
    () =>
      new Fuse(availableConnectors, {
        shouldSort: true,
        keys: ['name', 'description'],
      }),
    [availableConnectors]
  );
  const [search, setSearch] = useState('');
  const [results, setResults] = useState(availableConnectors);

  useEffect(() => {
    if (search === '') {
      setResults(availableConnectors);
      return;
    }

    const results = fuse.search(search, { limit: 10 });
    setResults(results.map((result) => result.item));
  }, [search, availableConnectors, fuse]);

  return (
    <div>
      <H1 className="mb-4">Sources</H1>
      <Text.P className="mb-4">Connect a new data source to Cotera.</Text.P>

      <Inputs.Text
        type="text"
        id="search"
        value={search}
        onChange={setSearch}
        icon="magnifying-glass"
        className="mb-8"
      />
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {results.map((c) => (
          <Connector key={c} connector={c} alreadyConnected={false} />
        ))}
      </ul>
    </div>
  );
};
