import { Relation, SQL } from '@cotera/era';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { queryKeys } from './query-cache-keys';
import { useTenantedClient, useWhoami } from '../stores/org';
import { assertOk } from '@cotera/utilities';
import { useEraScopesAwareRelIR } from '../pages/apps/compiler/macro-expansion/scopes';

export const useWarehouseConnection = () => {
  const org = useWhoami((s) => s.org);
  const client = useTenantedClient();
  const key = queryKeys.warehouse.getPrimary({ orgId: org.id });

  return useSuspenseQuery({
    queryKey: key,
    queryFn: async () => {
      return assertOk(await client.warehouse.getPrimary({}));
    },
  });
};

const DIALECTS = {
  POSTGRES: SQL.Dialects.Postgres,
  REDSHIFT: SQL.Dialects.Redshift,
  BIGQUERY: SQL.Dialects.BigQuery,
  SNOWFLAKE: SQL.Dialects.Snowflake,
  DUCKDBWASM: SQL.Dialects.DuckDbWasm,
  DUCKDBNATIVE: SQL.Dialects.DuckDbNative,
} as const;

export const useWarehouseDialect = (): SQL.SqlDialect => {
  const wc = useWarehouseConnection();
  return DIALECTS[wc.data?.type ?? 'DUCKDBWASM'];
};

export const useSqlInWarehouseDialect = ({ rel }: { rel: Relation }) => {
  const orgId = useWhoami((x) => x.org.id);
  const wc = useWarehouseConnection().data;
  const ir = useEraScopesAwareRelIR(rel);

  return useSuspenseQuery({
    queryKey: queryKeys.warehouse.sqlInWarehouseDialect({ orgId, rel }),
    queryFn: () => {
      const dialect = wc?.type ?? 'DUCKDBWASM';
      return {
        ...Relation.wrap(ir).sqlForDialect(dialect),
        dialect,
      };
    },
  });
};

export const useIrForSqlHash = ({ sqlHash }: { sqlHash: string }) => {
  const orgId = useWhoami((s) => s.org.id);
  const client = useTenantedClient();

  return useQuery({
    queryKey: queryKeys.warehouse.irForSqlHash({ orgId, sqlHash }),
    queryFn: async () => {
      const response = assertOk(await client.warehouse.irForHash({ sqlHash }));
      return response;
    },
  });
};
