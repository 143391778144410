const Heading: React.FC<{
  position: 'start' | 'end';
}> = ({ position }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: `calc(var(--row-padding-size-${position}) * 1px)`,
      }}
    />
  );
};

const Cell: React.FC<{
  position: 'start' | 'end';
}> = ({ position }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: `calc(var(--row-padding-size-${position}) * 1px)`,
      }}
    />
  );
};

export const HorizontalPadding = {
  Heading,
  Cell,
};
