import { FFI } from '../ffi/index';
import { f, If, Relation } from '@cotera/era';

export const SinkTooling = (cursors: Relation) =>
  cursors.select((t) => ({
    ...t.star(),
    set_cursor: If(t.attr('cotera_stable_id').isNotNull(), {
      then: FFI.setCursor({
        entityId: t.attr('entity_id'),
        detectedAt: t.attr('detected_at_time'),
        sinkId: t.attr('sink_id'),
        streamId: t.attr('event_stream_id'),
        coteraStableId: t.attr('cotera_stable_id'),
      }),
    }),
    pause: FFI.pause({
      entityId: t.attr('entity_id'),
      sinkId: t.attr('sink_id'),
      streamId: t.attr('event_stream_id'),
    }),
    snapshot: FFI.snapshot({
      entityId: t.attr('entity_id'),
      streamId: t.attr('event_stream_id'),
    }),
    dry_run: FFI.openLink({
      to: f`/events/${t.attr('event_stream_id')}/${t.attr('sink_id')}/dry-run`,
      label: 'Dry Run',
      newTab: true,
    }),
  }));
