import { And, Case, Relation } from '@cotera/era';

export const RfmAssignment = (x: Relation): Relation =>
  x.select((t) => {
    const topCustomers = And(
      t.attr('CUSTOMER_AGE_IN_DAYS').gte(90),
      t.attr('DAYS_SINCE_LAST_ORDER').lt(90),
      t.attr('TOTAL_SPEND_LAST_90_DAYS').gt(1000)
    );

    const repeatCustomers = And(
      t.attr('CUSTOMER_AGE_IN_DAYS').gte(90),
      t.attr('DAYS_SINCE_LAST_ORDER').lt(90),
      t.attr('TOTAL_SPEND_LAST_90_DAYS').gt(200),
      t.attr('TOTAL_SPEND_LAST_90_DAYS').lte(1000)
    );

    const atRiskCustomers = And(
      t.attr('DAYS_SINCE_LAST_ORDER').lt(90),
      t.attr('CUSTOMER_AGE_IN_DAYS').gte(90)
    );

    const topNewCustomers = And(
      t.attr('CUSTOMER_AGE_IN_DAYS').lt(90),
      t.attr('TOTAL_SPEND_LAST_90_DAYS').gt(300)
    );

    const newCustomers = And(t.attr('CUSTOMER_AGE_IN_DAYS').lt(90));

    const lapsedCustomers = And(
      t.attr('CUSTOMER_AGE_IN_DAYS').gte(90),
      t.attr('DAYS_SINCE_LAST_ORDER').gte(90),
      t.attr('DAYS_SINCE_LAST_ORDER').lt(180)
    );

    const hibernatingCustomers = And(
      t.attr('CUSTOMER_AGE_IN_DAYS').gte(90),
      t.attr('DAYS_SINCE_LAST_ORDER').gte(180),
      t.attr('DAYS_SINCE_LAST_ORDER').lt(365)
    );

    const lostCustomers = And(
      t.attr('CUSTOMER_AGE_IN_DAYS').gte(90),
      t.attr('DAYS_SINCE_LAST_ORDER').gte(365)
    );

    return {
      ...t.star(),
      RFM_CATEGORY: Case([
        { when: lostCustomers, then: 'Lost Customers' },
        { when: topCustomers, then: 'Top Customers' },
        { when: repeatCustomers, then: 'Repeat Customers' },
        { when: atRiskCustomers, then: 'At Risk Customers' },
        { when: topNewCustomers, then: 'Top New Customers' },
        { when: newCustomers, then: 'New Customers' },
        { when: lapsedCustomers, then: 'Lapsed Customers' },
        { when: hibernatingCustomers, then: 'Hibernating Customers' },
      ]),
      RFM_NUM: Case([
        { when: lostCustomers, then: 8 },
        { when: topCustomers, then: 1 },
        { when: repeatCustomers, then: 2 },
        { when: atRiskCustomers, then: 5 },
        { when: topNewCustomers, then: 3 },
        { when: newCustomers, then: 4 },
        { when: lapsedCustomers, then: 6 },
        { when: hibernatingCustomers, then: 7 },
      ]),
    };
  });
