import { useMutation } from '@tanstack/react-query';
import { useTenantedClient } from '../stores/org';
import { Ty } from '@cotera/era';

export const useUddWrite = (params: { entityId: string; key: string }) => {
  const { udds } = useTenantedClient();

  const mutationFn = async (
    data: { identifier: string | number; value: Ty.Scalar }[]
  ) => {
    return udds.write({ ...params, data });
  };

  return useMutation({ mutationFn });
};
