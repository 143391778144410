import { useAppData } from '@cotera/client/app/stores/org';
import { classNames, ChildrenProps } from '@cotera/client/app/components/utils';
import { Accordion, Card } from '@cotera/client/app/components/headless';
import { Badge, Text, Divider } from '@cotera/client/app/components';
import React, { useEffect, useMemo, useState } from 'react';
import { InformationSchema } from '@cotera/era';
import { Layout } from '../../layout';
import { groupBy } from 'lodash';
import Fuse from 'fuse.js';
import { z } from 'zod';
import { Inputs } from '@cotera/client/app/components/forms';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { H4 } from '@cotera/client/app/components/ui/typography';

const ColumnInfoSchema = z.object({
  column_name: z.string(),
  table_name: z.string(),
  table_schema: z.string(),
  data_type: z.string(),
  is_nullable: z.coerce.string(),
});

type ColumnInfo = z.infer<typeof ColumnInfoSchema>;

export const WarehouseSearchPage: React.FC<{}> = () => {
  const schemas = useAppData((x) => x.skeleton.schemas).read;
  const columnInfo = useDuckDBQuery({
    rel: InformationSchema({ schemas, type: 'columns' }),
  });

  return (
    <Layout>
      <PageLayout schemas={schemas}>
        <Results
          results={ColumnInfoSchema.array().parse(
            columnInfo.data?.data.toArray() ?? []
          )}
        />
      </PageLayout>
    </Layout>
  );
};

const PageLayout: React.FC<ChildrenProps & { schemas: string[] }> = ({
  children,
  schemas,
}) => {
  return (
    <div className="min-h-full flex flex-col items-center w-full">
      <div className="mx-auto max-w-7xl p-8 w-full">
        <h2 className="text-lg font-semibold leading-6 text-standard-text">
          Warehouse explore
        </h2>
        <Text.Caption className="mt-1 text-sm mb-6">
          Search for columns or tables in the{' '}
          <strong>{schemas.join(', ')}</strong> schema
          {schemas.length > 1 ? 's' : ''}.
        </Text.Caption>
        {children}
      </div>
    </div>
  );
};

type TableDef = {
  name: string;
  schema: string;
  columns: {
    name: string;
    type: string;
    nullable: string;
  }[];
};

const Results: React.FC<{ results: ColumnInfo[] }> = ({ results: data }) => {
  const fuse = useMemo(
    () =>
      new Fuse(data, {
        shouldSort: true,
        keys: ['table_name', 'column_name'],
      }),
    [data]
  );
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([] as ColumnInfo[]);

  useEffect(() => {
    if (search === '') {
      setResults(data);
      return;
    }

    const results = fuse.search(search, { limit: 10 });
    setResults(results.map((result) => result.item));
  }, [search, data, fuse]);

  const tableDefs: TableDef[] = Object.values(
    groupBy(results, (x) => `${x.table_schema}.${x.table_name}`)
  ).map((columns) => {
    const { table_schema, table_name } = columns[0]!;

    return {
      schema: table_schema,
      name: table_name,
      columns: columns.map((c) => ({
        name: c.column_name,
        type: c.data_type,
        nullable: c.is_nullable,
      })),
    };
  });

  if (tableDefs.length === 0) {
    return <div className="text-standard-text">No results found</div>;
  }

  return (
    <>
      <Inputs.Text
        type="text"
        id="search"
        value={search}
        onChange={setSearch}
        icon={'magnifying-glass'}
        className="mb-8"
      />
      <Accordion.Root expanded={[]}>
        <ul className="flex flex-col">
          {tableDefs.map((table, idx) => {
            return (
              <li key={idx} className={classNames('relative flex items-start')}>
                <Card.Container className="w-full">
                  <Card.Content>
                    <Accordion.Trigger
                      id={table.name}
                      className="flex items-center w-full justify-between"
                    >
                      <div className="mr-4 text-left">
                        <H4 className="mb-2">{table.name}</H4>
                        <Text.Caption>
                          {table.columns.length} columns
                        </Text.Caption>
                      </div>
                      <Badge theme="primary">{table.schema}</Badge>
                    </Accordion.Trigger>
                    <Accordion.Item id={table.name}>
                      <Divider className="mt-4" />
                      <table className="min-w-full divide-y divide-divider">
                        <thead>
                          <tr className="divide-x divide-divider">
                            <th className="py-4 pl-2 pr-2 text-left text-sm font-semibold">
                              Column
                            </th>
                            <th className="py-4 pl-2 pr-2 text-left text-sm font-semibold">
                              Type
                            </th>
                            <th className="py-4 pl-2 pr-2 text-left text-sm font-semibold">
                              Nullable
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-divider">
                          {table.columns.map((column, idx) => {
                            return (
                              <tr key={idx} className="divide-x divide-v">
                                <td className="whitespace-nowrap p-2 text-sm">
                                  {column.name}
                                </td>
                                <td className="whitespace-nowrap p-2 text-sm">
                                  {column.type}
                                </td>
                                <td className="whitespace-nowrap p-2 text-sm">
                                  {column.nullable ? 'Yes' : 'No'}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Accordion.Item>
                  </Card.Content>
                </Card.Container>
              </li>
            );
          })}
        </ul>
      </Accordion.Root>
    </>
  );
};
