import { From, And, f, MaxOver, Now } from '@cotera/era';
import {
  ORDER,
  ORDER_LINE,
  PRODUCT,
  PRODUCT_IMAGE,
  PRODUCT_VARIANT,
  SHOPIFY__CUSTOMERS,
  DISCOUNTS,
} from '../apps/shopify-assumptions';
import { mapCustomerId } from '../sdk';

export const CustomerEmails = From(SHOPIFY__CUSTOMERS()).select(
  (t) => ({
    ...mapCustomerId(t, 'CUSTOMER_ID'),
    ...t.pick('CUSTOMER_ID', 'EMAIL'),
  }),
  { distinct: true }
);

// Order table with item id and quantity
export const Orders = From(ORDER)
  .where((t) =>
    And(
      t.attr('CANCELLED_AT').isNull(),
      t.attr('FINANCIAL_STATUS').eq('paid'),
      t.attr('EMAIL').isNotNull(),
      t.attr('ID').isNotNull()
    )
  )
  .leftJoin(From(ORDER_LINE()), (ord, line) => ({
    on: ord.attr('ID').eq(line.attr('ORDER_ID')),
    select: {
      ...ord.pick('CUSTOMER_ID', 'PROCESSED_AT', 'TOTAL_DISCOUNTS'),
      ...line.pick('ORDER_ID', 'PRODUCT_ID', 'QUANTITY', 'PRICE'),
    },
  }))
  .select((t) => ({
    ...mapCustomerId(t, 'CUSTOMER_ID'),
    ...t.pick('ORDER_ID', 'PRODUCT_ID', 'QUANTITY', 'TOTAL_DISCOUNTS', 'PRICE'),
    ORDER_DATE: t.attr('PROCESSED_AT'),
  }));

export const OrdersAdjusted = Orders.select((t) => ({
  ...t.star(),
  ORDER_DATE: t
    .attr('ORDER_DATE')
    .dateAdd('days', MaxOver(t.attr('ORDER_DATE'), {}).dateDiff(Now(), 'days')),
}));

// Recommendation Prep Tables

// Recommendations Active Items List
// Active Items Table
export const ActiveItems = From(PRODUCT())
  .where((t) => And(t.attr('STATUS').eq('active')))
  .select((t) => ({
    ...t.pick('PRODUCT_ID'),
  }));

// Item Details table with Sink columns
export const ItemDetail = From(PRODUCT_IMAGE)
  .where((t) => t.attr('POSITION').eq(1))
  .innerJoin(From(PRODUCT()), (img, prd) => ({
    on: img.attr('PRODUCT_ID').eq(prd.attr('PRODUCT_ID')),
    select: {
      PRODUCT_ID: img.attr('PRODUCT_ID'),
      ITEM_PRODUCT_IMAGE_URL: img.attr('SRC'),
      ITEM_PRODUCT_URL: f`www.cotera.co/products/${prd.attr('HANDLE')}`,
      ITEM_NAME: prd.attr('TITLE'),
    },
  }))
  .innerJoin(
    From(PRODUCT_VARIANT).where((t) =>
      t.attr('POSITION').eq(1).and(t.attr('INVENTORY_QUANTITY').gt(0))
    ),
    (rec, varnt) => ({
      on: rec.attr('PRODUCT_ID').eq(varnt.attr('PRODUCT_ID')),
      select: {
        ...rec.star(),
        ITEM_PRODUCT_COST: varnt.attr('PRICE'),
      },
    })
  );

export const ActiveItemDetails = ActiveItems.innerJoin(
  ItemDetail,
  (item, detail) => ({
    on: item.attr('PRODUCT_ID').eq(detail.attr('PRODUCT_ID')),
    select: {
      ...detail.star(),
    },
  })
);

export const DiscountStream = From(DISCOUNTS()).select((t) => ({
  ORDER_ID: t.attr('ORDER_ID'),
  CODE: t.attr('CODE'),
  VALUE: 10,
}));
