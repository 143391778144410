import { Expression } from '../builder';
import { ParseCtx, ParseRes } from './eraql-ast/ast';
import { parseExpr } from './parse';
import { Ty } from '../ty';
import _ from 'lodash';
import { exprToEraQL } from './to-eraql/expr-to-eraql';

type RawQlExpressionCompiler = (
  rawCode: readonly string[],
  keys: readonly (Ty.Scalar | Expression)[],
  ctx: ParseCtx
) => ParseRes<Expression>;

export type QlExpressionCompiler = (
  rawCode: readonly string[],
  ...keys: readonly (Ty.Scalar | Expression)[]
) => Expression;

const rawQlExpressionCompiler: RawQlExpressionCompiler = (
  rawCode,
  keys,
  opts
) => {
  const parts: string[] = [];

  for (const [code = '', interpolated] of _.zip(rawCode, keys)) {
    parts.push(code);

    if (interpolated !== undefined) {
      const expr = Expression.wrap(interpolated);
      parts.push(exprToEraQL(expr.ir()));
    }
  }

  const eraCode = parts.join('');
  return parseExpr(eraCode, opts).andThen(
    (ir): ParseRes<Expression> =>
      Expression.tryfromAst(ir).mapErr((trace) => [
        { t: 'type-check-error', trace },
        { range: [0, eraCode.length] },
      ])
  );
};

export const makeQlExpressionCompiler = (
  ctx: ParseCtx,
  opts: {
    jsStackPointer?: Function;
  }
): QlExpressionCompiler => {
  const inner: QlExpressionCompiler = (rawCode, ...keys) => {
    const { jsStackPointer = inner } = opts;
    const res = rawQlExpressionCompiler(rawCode, keys, ctx);

    if (res.isErr()) {
      const [e, _meta] = res.error;

      if (e.t === 'type-check-error') {
        throw e.trace.toError({ jsStackPointer });
      }

      const error = new Error(`${e.t}`);

      if ((Error as any).captureStackTrace && jsStackPointer !== undefined) {
        (Error as any).captureStackTrace(error, jsStackPointer);
      }

      throw error;
    }

    return res.value;
  };

  return inner;
};
