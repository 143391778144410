import { useEffect, useMemo } from 'react';

class ComponentAbortController implements AbortController {
  private _base = new AbortController();

  get signal() {
    return this._base.signal;
  }

  abort(reason?: unknown): void {
    this._base.abort(reason);
  }

  reset() {
    this._base = new AbortController();
  }
}

export const useAbortController = (props: { abortOnUnmount: boolean }) => {
  const controller = useMemo(() => new ComponentAbortController(), []);

  useEffect(() => {
    controller.reset();

    return () => {
      if (props.abortOnUnmount) {
        controller.abort();
      }
    };
  }, [controller, props.abortOnUnmount]);

  return controller;
};
