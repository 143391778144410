import { AST } from '@cotera/era';
import { ProvideEraScope } from './scopes';
import { CompileMarkup } from '../compiler';

export const RenderMacroApplyVarsToMarkup: React.FC<{
  inBlock: boolean;
  section: AST._MacroApplyVarsToMarkup;
}> = ({ inBlock, section }) => (
  <ProvideEraScope scope={section.scope} vals={section.vars}>
    <CompileMarkup inBlock={inBlock} section={section.section} />
  </ProvideEraScope>
);
