export const PostscriptLogo: React.FC<{ height: number }> = ({
  height = 35,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 189 59"
      fill="none"
    >
      <path
        d="M0 54.2858C0 28.6951 0 15.8999 7.94999 7.94999C15.8999 0 28.6951 0 54.2858 0H158.554C174.825 0 188.014 13.1897 188.014 29.46C188.014 45.7303 174.825 58.92 158.554 58.92H0V54.2858Z"
        fill="#5724E9"
      />
      <path
        d="M16.33 18.088H25.3224C30.6627 18.088 33.8713 20.5971 33.8713 25.6505C33.8713 30.2847 31.2652 33.0233 25.7991 33.0233H21.5423V38.9969H16.33V18.088ZM25.5784 29.5278C27.7697 29.5278 28.9768 28.3516 28.9768 25.9043C28.9768 23.521 27.6528 22.186 25.1635 22.186H21.5423V29.5278H25.5784Z"
        fill="white"
      />
      <path
        d="M34.4432 31.1802C34.4432 26.0628 37.8129 23.044 42.9922 23.044C48.1714 23.044 51.5389 26.0628 51.5389 31.1802C51.5389 36.2557 48.1714 39.2503 42.9922 39.2503C37.8129 39.2503 34.4432 36.2624 34.4432 31.1802ZM46.7105 31.1802C46.7105 28.3821 45.3423 26.7314 42.9922 26.7314C40.642 26.7314 39.2738 28.3821 39.2738 31.1802C39.2738 33.9431 40.6398 35.565 42.9922 35.565C45.3445 35.565 46.7105 33.9431 46.7105 31.1802Z"
        fill="white"
      />
      <path
        d="M52.7145 33.7235H57.0375C57.0375 35.1844 57.7679 36.1377 60.5308 36.1377C63.042 36.1377 63.9313 35.4072 63.9313 34.2619C63.9313 33.6904 63.6467 33.2468 62.828 33.0548C61.6204 32.8309 60.3998 32.6835 59.1736 32.6135C56.9183 32.4215 55.1706 32.0729 54.2173 31.4042C53.7422 31.0436 53.3592 30.5757 53.0995 30.0388C52.8399 29.5018 52.7109 28.9111 52.7233 28.3148C52.7233 24.9142 56.2188 23.0385 60.5727 23.0385C65.721 23.0385 68.0756 25.0091 68.0756 28.6634H63.8056C63.7725 27.0106 63.0111 26.1522 60.6278 26.1522C58.4652 26.1522 57.5119 26.8848 57.5119 27.9639C57.5119 28.6259 57.7679 29.1401 58.6572 29.363C59.4517 29.5528 60.8485 29.6807 62.5345 29.8396C64.472 30.0294 65.8446 30.2214 67.0142 30.8878C67.5534 31.2074 67.999 31.6635 68.3058 32.2102C68.6126 32.7568 68.77 33.3747 68.7619 34.0016C68.7619 37.3117 65.9329 39.2448 60.5639 39.2448C55.0338 39.2514 52.7145 36.9078 52.7145 33.7235Z"
        fill="white"
      />
      <path
        d="M71.5844 33.5974V27.05H69.2585V23.2985H71.1431C71.8735 23.2985 72.1295 22.8859 72.2244 21.9326L72.4451 19.7413H76.4172V23.2985H81.4376V27.05H76.4084V33.3414C76.4084 34.9612 77.1697 35.4709 79.0763 35.4709C79.8128 35.4663 80.5472 35.3924 81.2698 35.2503V38.8936C80.2667 39.1112 79.2443 39.2281 78.2179 39.2423C73.3256 39.2511 71.5844 37.0907 71.5844 33.5974Z"
        fill="white"
      />
      <path
        d="M82.6781 33.7235H86.9989C86.9989 35.1844 87.7293 36.1377 90.4944 36.1377C93.0034 36.1377 93.895 35.4072 93.895 34.2619C93.895 33.6904 93.6081 33.2468 92.7916 33.0548C91.5839 32.8312 90.3634 32.6838 89.1372 32.6135C86.8797 32.4215 85.132 32.0729 84.1787 31.4042C83.7039 31.0435 83.3212 30.5755 83.0619 30.0386C82.8026 29.5016 82.6741 28.9109 82.6869 28.3148C82.6869 24.9142 86.1802 23.0385 90.5341 23.0385C95.6824 23.0385 98.037 25.0091 98.037 28.6634H93.778C93.7471 27.0106 92.9836 26.1522 90.6003 26.1522C88.4399 26.1522 87.4866 26.8848 87.4866 27.9639C87.4866 28.6259 87.7404 29.1401 88.6297 29.363C89.4241 29.5528 90.8232 29.6807 92.5069 29.8396C94.4444 30.0294 95.817 30.2214 96.9866 30.8878C97.5257 31.2088 97.9705 31.6663 98.2762 32.2142C98.5819 32.762 98.7376 33.3809 98.7277 34.0082C98.7277 37.3183 95.9009 39.2514 90.5297 39.2514C84.9974 39.2514 82.6781 36.9078 82.6781 33.7235Z"
        fill="white"
      />
      <path
        d="M99.5178 31.1802C99.5178 26.0628 102.854 23.044 107.683 23.044C112.29 23.044 115.309 25.6192 115.309 29.9709H111.05C111.019 27.9385 109.812 26.7314 107.842 26.7314C105.586 26.7314 104.346 28.2872 104.346 31.1449C104.346 33.91 105.617 35.5584 107.747 35.5584C109.812 35.5584 111.057 34.3822 111.083 32.3807H115.34C115.34 36.8913 112.132 39.2437 107.493 39.2437C102.793 39.2503 99.5178 36.2624 99.5178 31.1802Z"
        fill="white"
      />
      <path
        d="M117.233 23.2977H122.063L121.874 27.7796H121.938C122.6 24.7917 124.288 23.044 127.371 23.044C130.579 23.044 132.446 25.1095 132.446 29.1765C132.446 30.0592 132.351 31.4649 132.287 32.2902H127.911C127.973 31.5289 128.006 30.7345 128.006 30.2578C128.006 27.9694 127.084 27.0492 125.559 27.0492C123.493 27.0492 122.063 28.733 122.063 32.2284V38.9965H117.233V23.2977Z"
        fill="white"
      />
      <path
        d="M139.073 23.3008H134.243V38.9973H139.073V23.3008Z"
        fill="white"
      />
      <path
        d="M141.538 23.2977H146.369L146.274 26.5086H146.305C146.909 24.3482 148.911 23.044 151.612 23.044C155.997 23.044 158.856 26.1577 158.856 30.7985C158.856 35.874 155.988 39.2503 151.574 39.2503C148.705 39.2503 146.94 38.0741 146.234 36.1608H146.203C146.267 37.2113 146.362 38.767 146.362 39.3386V45.2923H141.538V23.2977ZM154.059 30.9882C154.059 28.4461 152.596 26.7314 150.405 26.7314C147.977 26.7314 146.369 28.4792 146.369 31.1162V31.8158C146.369 34.0711 147.988 35.5672 150.436 35.5672C152.594 35.565 154.057 33.7224 154.057 30.9882H154.059Z"
        fill="white"
      />
      <path
        d="M161.813 33.5974V27.05H159.494V23.2985H161.368C162.098 23.2985 162.354 22.8859 162.449 21.9326L162.67 19.7413H166.642V23.2985H171.662V27.05H166.642V33.3414C166.642 34.9612 167.403 35.4709 169.31 35.4709C170.046 35.4663 170.781 35.3924 171.503 35.2503V38.8936C170.5 39.1112 169.478 39.2281 168.451 39.2423C163.561 39.2511 161.813 37.0907 161.813 33.5974Z"
        fill="white"
      />
      <path
        d="M139.553 19.6768C139.553 20.1071 139.444 21.6805 136.904 21.6805H134.256V19.6702C134.256 18.4653 135.23 17.6466 136.904 17.6466C138.368 17.6532 139.553 18.2512 139.553 19.6768Z"
        fill="white"
      />
    </svg>
  );
};
