import { PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Scope } from '@cotera/contracts';
import { useWhoami } from '@cotera/client/app/stores/org';

export const Protected: React.FC<
  PropsWithChildren<{
    scopes: Scope[];
    fallback?: JSX.Element;
  }>
> = ({ children, scopes, fallback = null }) => {
  const abilities = useWhoami((x) => x.abilities);

  if (scopes.every((s) => abilities.can(s))) {
    return <>{children}</>;
  }
  return fallback;
};

export const ProtectedRoute: React.FC<{
  scopes: [action: string, subject: string][];
}> = ({ scopes }) => {
  const abilities = useWhoami((x) => x.abilities);
  return scopes.every((s) => abilities.can(s)) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorised" />
  );
};
