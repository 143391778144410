import { ResponsiveRadar } from '@nivo/radar';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { ChartContext, makeChartContextHook } from '../context';
import { THEME, fillMatcher } from '../utils';
import { ChartProps } from '../types';
import { Loading } from './loading';
import { uniq } from 'lodash';
import { EmptyChart } from '../empty';
import { Tooltip } from '../tooltip';
import { If, Text } from '@cotera/client/app/components/ui';
import {
  GRAY_200,
  makeValueFormatter,
} from '@cotera/client/app/components/utils';

type CategoricalDatum = {
  category: string;
  value: number;
  group: string;
  style?: 'positive' | 'negative' | 'neutral';
};

type Props = ChartProps<CategoricalDatum>;

const useTypedChartContext = makeChartContextHook<CategoricalDatum>();

export const RadarChart: React.FC<Props> = ({
  data,
  loading = false,
  format,
  ...layoutProps
}) => {
  return (
    <ChartContext data={data} labelKeys={['group']}>
      <ChartLayout {...layoutProps} menuItems={DEFAULT_MENU_ITEMS}>
        <If condition={loading}>
          <Loading />
        </If>
        <If condition={data.length === 0 && !loading}>
          <EmptyChart />
        </If>
        <If condition={!loading && data.length > 0}>
          <Chart format={format} />
        </If>
      </ChartLayout>
    </ChartContext>
  );
};

const Chart = ({
  format,
}: {
  format: ChartProps<CategoricalDatum>['format'];
}) => {
  const labels = useTypedChartContext((s) => s.activeLabels);
  const groups = labels.map((x) => x.label);
  const data = useTypedChartContext((s) => s.data);
  const categories = uniq(data.map((x) => x.category));
  const chartData = categories.map((k) => {
    return {
      label: k,
      ...Object.fromEntries(
        groups.map((c) => {
          return [
            c,
            data
              .filter((x) => x.group === c && x.category === k)
              .reduce((acc, x) => acc + x.value, 0),
          ];
        })
      ),
    };
  });

  const valueFormatter = makeValueFormatter(format?.['value'] ?? 'none');

  return (
    <ResponsiveRadar
      theme={THEME}
      keys={groups}
      indexBy="label"
      data={chartData}
      margin={{ top: 45, bottom: 20, left: 10, right: 10 }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      colors={(x) => labels.find((y) => y.label === x.key)?.color ?? GRAY_200}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={labels.map(fillMatcher)}
      sliceTooltip={(props) => {
        return (
          <Tooltip.Container>
            <Text.Caption className="text-sm mb-2">{props.index}</Text.Caption>
            {props.data.map((point) => {
              return (
                <Tooltip.Item key={point.id}>
                  <Tooltip.Dot color={point.color} />
                  <strong>{point.id}</strong>: {valueFormatter(point.value)}
                </Tooltip.Item>
              );
            })}
          </Tooltip.Container>
        );
      }}
    />
  );
};
