import { Layout } from '@cotera/client/app/layout';
import { Text, CoteraLink } from '@cotera/client/app/components/ui';
import { LogoComponent } from '@cotera/client/app/components/app';

export const UnauthorizedPage = () => {
  return (
    <Layout>
      <div className="h-full flex-1 flex flex-col justify-center items-center mr-8">
        <div className="h-[20px] w-[20px] mb-16">
          <LogoComponent />
        </div>
        <Text.Caption className="text-primary-text font-bold text-2xl mb-3">
          401
        </Text.Caption>
        <Text.H1 className="mb-3 capitalize">Unauthorized</Text.H1>
        <Text.Caption className="mb-6">
          It appears you dont have permission to access the resource you were
          looking for.
        </Text.Caption>

        <CoteraLink to="/">
          Got back home <span aria-hidden="true"> &rarr;</span>
        </CoteraLink>
      </div>
    </Layout>
  );
};
