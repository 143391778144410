import { sortBy } from 'lodash';
import React from 'react';
import { classNames } from '@cotera/client/app/components/utils';
import { Text } from '@cotera/client/app/components';

export const Legend: React.FC<{
  labels: { label: string; color: string; hidden: boolean }[];
  sort?: boolean;
  mode?: 'horizontal' | 'vertical';
  onDoubleClick?: (label: string) => void;
  onClick?: (label: string) => void;
}> = ({
  sort = true,
  mode = 'horizontal',
  labels,
  onClick = () => {},
  onDoubleClick = () => {},
}) => {
  if (sort) {
    labels = sortBy(labels, 'label');
  }
  return (
    <ul
      className={classNames(
        'mt-6 flex',
        mode === 'vertical' ? 'flex-col' : 'flex-row flex-wrap'
      )}
    >
      {labels.map((d) => (
        <LegendItem
          key={d.label}
          label={d.label}
          color={d.color}
          hidden={d.hidden}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          as="li"
        />
      ))}
    </ul>
  );
};

export const LegendItem: React.FC<{
  label: string;
  color: string;
  hidden: boolean;
  onClick: (label: string) => void;
  onDoubleClick: (label: string) => void;
  as?: React.ElementType;
}> = ({ label, color, hidden, onClick, onDoubleClick, as: As = 'li' }) => {
  return (
    <As
      className={classNames(
        'mr-4 flex items-center cursor-pointer',
        hidden ? 'opacity-50 line-through' : ''
      )}
      onClick={(e: { detail: number }) => {
        switch (e.detail) {
          case 2:
            onDoubleClick(label);
            break;
          default:
            onClick(label);
        }
      }}
    >
      <Text.Caption className="cursor-pointer flex flex-row items-center text-sm transition-colors hover:text-primary-text">
        <span
          className={classNames('rounded	mr-3 min-w-3 h-3 inline-block')}
          style={{ background: color }}
        ></span>
        {label}
      </Text.Caption>
    </As>
  );
};
