import { MD5 } from 'object-hash';
import hash from 'object-hash';
import { Assert } from '@cotera/utilities';

export const hashLiteral = (
  x: hash.NotUndefined,
  opts: { algorithm: 'passthrough' | 'md5' }
): string => {
  // normalize `{ foo: undefined }` to `{}`
  const target = JSON.parse(JSON.stringify(x));
  switch (opts.algorithm) {
    case 'passthrough': {
      return hash(target, { algorithm: 'passthrough' });
    }
    case 'md5': {
      return MD5(target);
    }
    default:
      return Assert.unreachable(opts.algorithm);
  }
};
