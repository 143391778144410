import * as BaseTooltip from '@radix-ui/react-tooltip';
import { ChildrenProps } from '@cotera/client/app/components/utils';

export type TooltipSide = 'right' | 'top' | 'bottom' | 'left';

export const Tooltip: React.FC<
  {
    text: string;
    offset?: number;
    side: TooltipSide;
  } & ChildrenProps
> = ({ side, children, text, offset }) => {
  return (
    <BaseTooltip.Provider delayDuration={0}>
      <BaseTooltip.Root>
        <BaseTooltip.Trigger asChild>{children}</BaseTooltip.Trigger>
        <BaseTooltip.Portal>
          <BaseTooltip.Content
            side={side}
            className="z-50 transition-all text-alt-text text-sm bg-accent-background rounded px-3 py-2"
            sideOffset={offset}
          >
            {text}
            <BaseTooltip.Arrow />
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
};
