import { Inputs } from '@cotera/client/app/components/forms';
import { useCallback } from 'react';

export type AssignedFilter = 'all others' | 'unassigned' | 'assigned';

export type FormState = {
  search: string;
  filter: AssignedFilter;
};

export const Filters: React.FC<{
  value: FormState;
  onChange?: (value: FormState) => void;
}> = ({ onChange, value }) => {
  const setFormValue = useCallback(
    (newValue: Partial<FormState>) => {
      onChange?.({
        ...value,
        ...newValue,
      });
    },
    [onChange, value]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex w-full mb-4"
    >
      <Inputs.Text
        className="flex-grow mr-2"
        icon="search"
        value={value.search}
        onChange={(v) => setFormValue({ search: v })}
      />
      <Inputs.Toggle
        nullable={false}
        className="mr-2 flex-shrink-0"
        options={['all others', 'unassigned', 'assigned']}
        value={value.filter}
        onChange={(v) => setFormValue({ filter: v })}
      />
    </form>
  );
};
