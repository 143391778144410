import { Contract, GET } from '@cotera/contracts';
import z from 'zod';

export const PromptsContract = Contract.new({
  list: GET({
    params: z.object({}),
    output: z.object({
      prompts: z
        .object({
          id: z.string(),
          name: z.string(),
          prompt: z.string(),
        })
        .array(),
    }),
    errors: z.never(),
  }),
});
