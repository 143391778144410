import { keys, pickBy } from 'lodash';

export const COLOR_ORDER = [
  'Indigo',
  'Purple',
  'Emerald',
  'Rose',
  'Orange',
  'Blue',
  'Yellow',
  'Red',
  'Green',
  'Pink',
  'Sky',
  'AltRed',
  'Lavender',
  'Teal',
  'Peach',
] as const;

const COLORS_200 = {
  Indigo: '#c7d2fe',
  Green: '#bbf7d0',
  Yellow: '#fef08a',
  Teal: '#99f6e4',
  Purple: '#d8b4fe',
  Orange: '#fed7aa',
  Red: '#fecaca',
  Pink: '#fbcfe8',
  Blue: '#bfdbfe',
  Emerald: '#a7f3d0',
  Sky: '#bae6fd',
  Rose: '#fecdd3',
  AltRed: '#F3A78B',
  Lavender: '#c9d5e8',
  Peach: '#e8c9db',
};

const COLORS_300 = {
  Indigo: '#a5b4fc',
  Yellow: '#FCEBB5',
  Green: '#86efac',
  Orange: '#fdba74',
  Sky: '#7dd3fc',
  Pink: '#f9a8d4',
  Purple: '#d8b4fe',
  Teal: '#5eead4',
  Blue: '#93c5fd',
  Red: '#fca5a5',
  Emerald: '#6ee7b7',
  Rose: '#fda4af',
  AltRed: '#f47560',
  Lavender: '#b2c6e5',
  Peach: '#e5b2d1',
};

const COLORS_400 = {
  Red: '#f87171',
  Pink: '#f472b6',
  Purple: '#c084fc',
  Indigo: '#818cf8',
  Blue: '#60a5fa',
  Sky: '#38bdf8',
  Teal: '#2dd4bf',
  Green: '#4ade80',
  Emerald: '#34d399',
  Yellow: '#fde047',
  Orange: '#fb923c',
  Rose: '#fb7185',
  AltRed: '#E64F17',
  Lavender: '#98b7e5',
  Peach: '#e598c6',
};

const COLORS_500 = {
  Red: '#ef4444',
  Pink: '#ec4899',
  Purple: '#a855f7',
  Indigo: '#6366f1',
  Blue: '#3b82f6',
  Sky: '#0ea5e9',
  Cyan: '#06b6d4',
  Teal: '#14b8a6',
  Green: '#22c55e',
  Emerald: '#10b981',
  Yellow: '#facc15',
  Orange: '#f97316',
  Rose: '#f43f5e',
  AltRed: '#DA0000',
  Lavender: '#7ca7e8',
  Peach: '#e87cbd',
};

const COLORS_600 = {
  Red: '#e53935',
  Pink: '#d81b60',
  Purple: '#8e24aa',
  Indigo: '#3949ab',
  Blue: '#1e88e5',
  Sky: '#039be5',
  Cyan: '#00acc1',
  Teal: '#00897b',
  Green: '#43a047',
  Emerald: '#7cb342',
  Lime: '#c0ca33',
  Yellow: '#fdd835',
  Amber: '#ffb300',
  Orange: '#fb8c00',
  Brown: '#6d4c41',
  BlueGrey: '#546e7a',
  Rose: '#e11d48',
  AltRed: '#CD0000',
  Aqua: '#49D0B9',
  Lavender: '#6b6899',
  Peach: '#e87060',
};

export const COLORS = {
  200: COLORS_200,
  300: COLORS_300,
  400: COLORS_400,
  500: COLORS_500,
  600: COLORS_600,
};

export const GRAY_200 = '#eeeeee';
export const YELLOW_200 = '#fff59d';

export const COLOR_SCHEMES = [
  'positive',
  'negative',
  'neutral',
  'random',
  'warning',
] as const;

export type COLOR_SCHEME = (typeof COLOR_SCHEMES)[number];

export class ThemeInstance {
  private readonly themes: Record<COLOR_SCHEME, Theme> = {
    positive: new Theme('positive'),
    negative: new Theme('negative'),
    neutral: new Theme('neutral'),
    random: new Theme('random'),
    warning: new Theme('warning'),
  };

  theme(style: COLOR_SCHEME): Theme {
    return this.themes[style];
  }
}

const PATTERNS = ['none', 'dots', 'lines'] as const;

type Label = 'other' | 'null' | string;

export class Theme {
  private index: number = 0;
  private readonly inputColors: string[] = [];
  private readonly colors: Record<string, string> = {};
  private readonly patterns: Record<string, (typeof PATTERNS)[number]> = {};

  constructor(style: COLOR_SCHEME) {
    const colorArray = Object.values(COLORS);

    switch (style) {
      case 'positive':
        this.inputColors = [
          colorArray.map((shade) => shade.Green),
          colorArray.map((shade) => shade.Emerald),
        ].flat();
        break;
      case 'negative':
        this.inputColors = [
          colorArray.map((shade) => shade.Rose),
          colorArray.map((shade) => shade.Red),
          colorArray.map((shade) => shade.AltRed),
        ].flat();
        break;
      case 'neutral':
        this.inputColors = [
          colorArray.map((shade) => shade.Blue),
          colorArray.map((shade) => shade.Purple),
          colorArray.map((shade) => shade.Indigo),
          colorArray.map((shade) => shade.Lavender),
        ].flat();
        break;
      case 'warning':
        this.inputColors = [
          colorArray.map((shade) => shade.Yellow),
          colorArray.map((shade) => shade.Orange),
        ].flat();
        break;
      case 'random':
        this.inputColors = COLOR_ORDER.map((x) => COLORS_400[x]);
        break;
    }
  }

  nextColor(): string {
    return this.inputColors[this.index++ % this.inputColors.length]!;
  }

  colorForLabel(label: Label): string {
    if (label === 'null') {
      return YELLOW_200;
    }
    if (label.toLowerCase() === 'other') {
      return GRAY_200;
    }

    if (!this.colors[label]) {
      this.colors[label] = this.nextColor();
    }
    return this.colors[label]!;
  }

  private patternForLabel(
    label: Label,
    color: string
  ): (typeof PATTERNS)[number] {
    if (label === 'null') {
      return 'lines';
    }
    if (label === 'other') {
      return 'none';
    }
    if (this.patterns[label]) {
      return this.patterns[label]!;
    }

    const colors = Object.values(this.colors);

    const indexesOfColor = (color?: string) =>
      keys(pickBy(Object.values(colors), (x) => x === color)).map(Number);

    const indexes = indexesOfColor(color);

    const indexOfIndex = indexes.indexOf(this.index - 1);

    if (indexOfIndex === -1) {
      return PATTERNS[0];
    }

    return PATTERNS[indexOfIndex % PATTERNS.length]!;
  }

  forLabel(label: string): { color: string; pattern: string } {
    const color = this.colorForLabel(label);
    return {
      color,
      pattern: this.patternForLabel(label, color),
    };
  }
}

export const globalTheme = new ThemeInstance();
