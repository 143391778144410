import { z } from 'zod';

export const withMetadata = (schema, metadata) => {
  schema._def.meta = metadata;
  return schema;
};

export const IterableCredentialsSchema = z.object({
  apiKey: withMetadata(z.string(), {
    displayName: 'Api Key',
    type: 'password',
  }),
});

export const HubspotCredentialsSchema = z.object({
  accessToken: withMetadata(z.string(), {
    displayName: 'Access Token',
    type: 'password',
  }),
});

export const SendGridCredentialsSchema = z.object({
  apiKey: withMetadata(z.string(), {
    displayName: 'Api Key',
    type: 'password',
  }),
});

export const OneSignalCredentialsSchema = z.object({
  appId: withMetadata(z.string(), { displayName: 'App Id' }),
  apiKey: withMetadata(z.string(), {
    displayName: 'api Key',
    type: 'password',
  }),
});

export const KlaviyoCredentialsSchema = z.object({
  privateKey: withMetadata(z.string(), {
    displayName: 'Private Key',
    type: 'password',
  }),
});

export const BrazeCredentialsSchema = z.object({
  apiKey: withMetadata(z.string(), { displayName: 'Api Key' }),
});

export const ShopifyCredentialsSchema = z.object({
  accessToken: withMetadata(z.string(), {
    displayName: 'Access Token',
    type: 'password',
  }),
});

export const PostscriptCredentialsSchema = z.object({
  apiKey: withMetadata(z.string(), {
    displayName: 'Api Key',
    type: 'password',
  }),
});

export const ZendeskCredentialsSchema = z.object({
  apiToken: withMetadata(z.string(), {
    displayName: 'Api Token',
    type: 'password',
  }),
  email: z.string().email(),
});

export const KustomerCredentialsSchema = z.object({
  apiKey: withMetadata(z.string(), {
    displayName: 'Api Key',
    type: 'password',
  }),
});

export const AttentiveCredentialsSchema = z.object({
  apiKey: withMetadata(z.string(), {
    displayName: 'Api Key',
    type: 'password',
  }),
});
