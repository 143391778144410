import './styles.css';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/app';
import { Toaster } from './app/components/ui/toaster';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from './app/error-boundary';
import { duckDbSetup } from './app/etc/duckdb';
import {
  AnalyticsClient,
  Instrumentation,
  InstrumentationProvider,
} from './app/etc';
import { FilterProvider } from './app/components/contexts/filters';

Instrumentation.init();
AnalyticsClient.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});
const initedDb = duckDbSetup();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <InstrumentationProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <FilterProvider>
              <App initedDb={initedDb} />
            </FilterProvider>
          </ErrorBoundary>
          <Toaster />
        </QueryClientProvider>
      </InstrumentationProvider>
    </BrowserRouter>
  </StrictMode>
);
