import { LLMMessage } from '@cotera/api';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedQueryKey } from './use-tenanted-query-key';
import { Ty } from '@cotera/era';

export const useCompletionMutation = ({
  messages,
  model = 'gpt-4o-mini',
}: {
  messages: LLMMessage[];
  model?: string;
}) => {
  const client = useTenantedClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: async () => {
      return await client.llm.completion({
        model,
        messages,
        orgId,
      });
    },
  });
};

export const useCompletion = ({
  messages,
  model = 'gpt-4o-mini',
  expectedType,
}: {
  messages: LLMMessage[];
  model?: string;
  expectedType?: Ty.ExtendedAttributeType;
}) => {
  const client = useTenantedClient();
  const orgId = useWhoami((s) => s.org.id);
  const queryKey = useTenantedQueryKey([
    'completion',
    model,
    JSON.stringify(messages),
  ]);

  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      return await client.llm.completion({
        model,
        messages,
        orgId,
        expectedType,
      });
    },
  });
};
