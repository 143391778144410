import { useWhoami } from '../stores/org';

export const useTenantedQueryKey = (
  key: string[] | ((orgId: string) => readonly string[])
) => {
  const orgId = useWhoami((s) => s.org.id);

  if (typeof key === 'function') {
    return key(orgId);
  }

  return [orgId, ...key];
};
