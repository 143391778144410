import { Contract, GET, POST } from '@cotera/contracts';
import { z } from 'zod';
import { Parser } from '@cotera/era';

export const ConfigContract = Contract.new({
  createEntityDefinition: POST({
    params: z.object({
      name: z.string(),
      idType: z.enum(['string', 'int']),
    }),
    errors: z.object({ msg: z.string() }),
    output: z.object({
      idType: Parser.AttributeTypeSchema,
      uuid: z.string(),
      name: z.string(),
    }),
  }),
  config: GET({
    params: z.object({}),
    output: z.object({
      entities: z.array(
        z.object({
          name: z.string(),
          idType: Parser.IdTypeSchema,
          uuid: z.string(),
        })
      ),
    }),
    errors: z.never(),
  }),
});
