import { AST } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { FlexGrid } from '../components/grid';
import { Row } from '../components/row';
import { CompileMarkup } from './compiler';

export const RenderSections: React.FC<{
  section: AST._Sections;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  const sections = section.sections.map((section, i) => (
    <CompileMarkup key={i} section={section} inBlock={inBlock} />
  ));

  switch (section.config.t) {
    case 'sections':
      if (isExprControlsV2Panel(section.sections)) {
        return <Row className="mt-2 w-full space-x-4">{sections}</Row>;
      }
      return <>{sections}</>;
    case 'row':
      return (
        <Row className="md:space-x-4 flex-wrap md:flex-nowrap" center={false}>
          {sections}
        </Row>
      );
    case 'grid':
      return <FlexGrid cols={section.config.columns}>{sections}</FlexGrid>;
    default:
      return Assert.unreachable(section.config);
  }
};

const isExprControlsV2Panel = (
  x: readonly AST.Mu[]
): x is readonly AST._ExprControlsV2[] =>
  x.every((sec) => sec.t === 'expr-controls-v2');
