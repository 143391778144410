import {
  Divider,
  Title,
  Text,
  Badge,
  Button,
} from '@cotera/client/app/components/ui';
import React, { useState } from 'react';
import { Assert } from '@cotera/utilities';
import { useDrafts } from './context';
import {
  DocumentListForRelation,
  FilterBuilder,
  filterGroupToExpression,
  useOptions,
} from '@cotera/client/app/components/app';
import { Card } from '@cotera/client/app/components/headless';
import { Relation, Ty, Values } from '@cotera/era';
import { useEntity } from '@cotera/client/app/hooks/entities';
import { useDefinition } from '@cotera/client/app/etc/data/manifest';
import { FFI } from '@cotera/sdk/core';
import { uniqBy } from 'lodash';
import { FilterGroup } from '@cotera/client/app/components/app/filter-builder/types';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useSearchParam } from 'react-use';

const useDocumentsForFeatures = (featureIds: string[]) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey(['documents', ...featureIds]);

  return useSuspenseQuery({
    queryFn: async () => {
      return Assert.assertOk(
        await client.topics.documentsForFeatures({ featureIds })
      );
    },
    queryKey,
  });
};

export const Documents: React.FC<{
  entityName: string;
  forceRender: number;
  features: { id: string; content: string }[];
  onRefreshClick: () => void;
}> = React.memo(
  ({ entityName, features, onRefreshClick }) => {
    const columnName = useSearchParam('column');
    const entity = useEntity({ entityName });

    const getTopicsColumnName = (
      attrs: Record<string, Ty.ExtendedAttributeType>
    ) => {
      if (columnName !== null) {
        return columnName;
      }
      return Object.entries(attrs).find(([_, ty]) =>
        FFI.isFFI(ty, FFI.TOPICS_TAG)
      )?.[0];
    };

    const definitionWithTargetColumn = Object.entries(entity.definitions).find(
      ([_, { attributes }]) => {
        return getTopicsColumnName(attributes) !== undefined;
      }
    )?.[0];

    Assert.assert(
      definitionWithTargetColumn !== undefined,
      'No definitons match the column'
    );

    const def = useDefinition({
      id: definitionWithTargetColumn,
    });

    Assert.assert(def.data.isOk());

    const baseRel = Relation.wrap(def.data.value);
    const topicsColumn = getTopicsColumnName(baseRel.attributes)!;

    const { data: featuresWithDocuments } = useDocumentsForFeatures(
      features.map((f) => f.id)
    );

    const documentIds = featuresWithDocuments.documents.map((x) => ({
      coteraStableId: x.coteraStableId,
    }));

    const [state, setState] = useState<{
      filterGroup: FilterGroup | null;
      limit: number;
    }>({ filterGroup: null, limit: 300 });

    const documentValues = Values(documentIds, {
      coteraStableId: 'string',
    });

    const options = useOptions(baseRel.attributes);

    const rel = (
      state.filterGroup !== null
        ? baseRel.where((t) => filterGroupToExpression(t)(state.filterGroup!))
        : baseRel
    )
      .select((t) => ({
        ...t.star(),
        value: t.attr(topicsColumn).getField('params').get('content'),
        id: t.attr(topicsColumn).getField('params').get('coteraStableId'),
      }))
      .innerJoin(documentValues, (documents, ids) => {
        return {
          on: documents.attr('id').eq(ids.attr('coteraStableId')),
          select: {
            ...documents.star(),
          },
        };
      })
      .limit(state.limit);

    return (
      <>
        <FilterBuilder
          runEnabled
          options={options}
          rel={baseRel}
          onRun={({ filterGroup, limit }) => {
            setState({ ...state, filterGroup, limit: limit ?? 300 });
          }}
        />
        <Card.Container className="h-[calc(100%-60px)] mt-2">
          <div className="flex items-center">
            <Title
              type="section"
              title="Documents"
              subtitle="Sample of documents in this topics training set"
              className="w-fit px-4 pt-4 pb-2"
            />
            <Button
              icon="arrow-path"
              tooltip="left"
              text={'refresh sample'}
              inline
              theme="primary"
              className="ml-auto mr-4"
              onClick={() => {
                onRefreshClick();
              }}
            />
          </div>
          <Divider className="mb-2" />
          <DocumentListForRelation
            className="h-[calc(100%-46px)]"
            rel={rel}
            renderer={(props) => (
              <Document
                {...props}
                features={
                  featuresWithDocuments.documents.find(
                    (d) => d.coteraStableId === props.item.id
                  )?.features ?? []
                }
              />
            )}
          />
        </Card.Container>
      </>
    );
  },
  (prev, next) => prev.forceRender === next.forceRender
);

const Document: React.FC<{
  pos: number;
  item: {
    value: string;
    id: string;
  };
  features: {
    id: string;
    content: string;
  }[];
}> = ({ item, features: featuresForDocument }) => {
  const featuresIdsForTopic = useDrafts((s) => s.topics)
    .at(0)!
    .features.map((f) => f.featureId);

  return (
    <li className="flex flex-col w-full overflow-x-scroll pb-2">
      <Title type="label" className="w-fit mb-2">
        {item.id}
      </Title>
      <Text.P className="max-h-[100px] overflow-y-scroll mb-3 text-wrap break-all">
        {item.value}
      </Text.P>
      <div className="flex items-center mb-2 flex-wrap">
        {uniqBy(featuresForDocument, 'id').map((f, i) => (
          <Badge
            key={i}
            className="mr-2 mb-2"
            theme={featuresIdsForTopic.includes(f.id) ? 'primary' : 'regular'}
          >
            {f.content}
          </Badge>
        ))}
      </div>
      <Divider />
    </li>
  );
};
