import { Org, SuperAdminContract, UntenantedClient } from '@cotera/api';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useState } from 'react';
import { Result } from 'neverthrow';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Assert } from '@cotera/utilities';
import { classNames } from '@cotera/client/app/components/utils';
import { Button } from '@cotera/client/app/components/ui';

export const ContractForm: React.FC<{
  superAdmin: UntenantedClient['superAdmin'];
  path: string[];
  orgs: Org[];
}> = ({ superAdmin, path, orgs }) => {
  const handler = handlerFromPath(superAdmin, path);

  const { zodSchema, prepareResult } = prepareSchema(path);

  const methods = useForm({
    resolver: zodResolver(zodSchema),
  });

  const [result, setResult] = useState<Result<unknown, unknown> | undefined>(
    undefined
  );

  const onSubmit = async (data: any) => {
    const res = await handler(prepareResult(data));
    setResult(res);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="w-full flex flex-col px-8"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Grid>
          {Object.entries(zodSchema.shape).map(([paramName, zodSchema]) => (
            <ZodInput
              key={paramName}
              paramName={paramName}
              zodSchema={zodSchema as z.ZodAny}
              orgs={orgs}
            />
          ))}
        </Grid>
        <div className="mt-6 pt-6 flex justify-end border-t border-gray-900/10">
          <div className="flex space-x-4">
            <Button
              theme="muted"
              onClick={() => setResult(undefined)}
              text="Clear"
            />
            <Button text="Submit" theme="primary" type="submit" />
          </div>
        </div>
        {result && (
          <div
            className={classNames(
              'mt-6 overflow-auto font-mono text-sm p-4 rounded shadow',
              result.isOk()
                ? 'bg-white text-standard-text'
                : 'bg-red-50 text-red-900'
            )}
          >
            <pre>
              {JSON.stringify(
                result.isOk() ? result.value : result.error,
                null,
                2
              )}
            </pre>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

type InputProps<Z = z.ZodAny> = {
  zodSchema: Z;
  orgs: Org[];
  paramName: string;
  width?: number;
};

const ZodInput: React.FC<InputProps> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { zodSchema, paramName } = props;
  const optional = zodSchema instanceof z.ZodOptional;
  const unwrappedSchema = optional ? zodSchema.unwrap() : zodSchema;

  if (unwrappedSchema instanceof z.ZodObject) {
    return <ObjectInput {...props} zodSchema={unwrappedSchema} />;
  } else if (unwrappedSchema instanceof z.ZodArray) {
    return <ArrayInput {...props} />;
  }

  let children: React.ReactNode;

  if (props.paramName.split('.').slice(-1)?.at(0) === 'orgId') {
    children = <OrgSelect {...props} />;
  } else if (unwrappedSchema instanceof z.ZodEnum) {
    children = (
      <EnumSelect
        {...props}
        opts={Array.from(Object.values(unwrappedSchema.Values))}
      />
    );
  } else if (unwrappedSchema instanceof z.ZodNumber) {
    children = <NumberInput {...props} />;
  } else {
    children = <StringInput {...props} />;
  }

  const error = errors[paramName] as any;
  const colSpan = widthToColSpan(2);
  return (
    <div className={colSpan}>
      <Label paramName={paramName} optional={optional} />
      <div className="mt-2">
        <div className="flex rounded shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 bg-white">
          {children}
        </div>
        {error && <Error error={error} />}
      </div>
    </div>
  );
};

const ObjectInput: React.FC<InputProps<z.ZodObject<any>>> = ({
  zodSchema,
  paramName,
  orgs,
}) => {
  const optional = zodSchema instanceof z.ZodOptional;
  const unwrappedSchema = optional ? zodSchema.unwrap() : zodSchema;
  return (
    <div className="col-span-6">
      <Grid>
        {Object.entries(unwrappedSchema.shape).map(
          ([nestedParamName, schema]) => (
            <ZodInput
              key={nestedParamName}
              paramName={`${paramName}.${nestedParamName}`}
              zodSchema={schema as z.ZodAny}
              orgs={orgs}
            />
          )
        )}
      </Grid>
    </div>
  );
};

const ArrayInput: React.FC<InputProps> = ({ zodSchema, paramName, orgs }) => {
  const { setValue } = useFormContext();
  const [elements, setElements] = useState<any[]>([]);

  const addRow = (element: React.ReactNode) => {
    setElements([...elements, element]);
  };

  const removeRow = (index: number) => {
    const updatedElements = elements.filter((_, i) => i !== index);
    setElements(updatedElements);
    setValue(paramName, updatedElements);
  };

  const optional = zodSchema instanceof z.ZodOptional;
  const unwrapped = optional ? zodSchema.unwrap() : zodSchema;
  Assert.assert(unwrapped instanceof z.ZodArray);

  return (
    <div className="col-span-6 flex flex-col">
      <Label paramName={paramName} optional={optional} />
      {elements.map((element, index) => {
        return (
          <div key={index} className="flex flex-row">
            {element}
            <div className="flex flex-col">
              <div className="flex-grow" />
              <button
                type="button"
                className="my-auto flex-shrink rounded bg-red-600 p-2 text-alt-text shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => removeRow(index)}
              >
                <MinusIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        );
      })}
      <div className="flex flex-row mt-2">
        <button
          type="button"
          className="flex-shrink rounded bg-primary-background p-2 text-alt-text shadow-sm hover:bg-primary-background focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() =>
            addRow(
              <ZodInput
                paramName={`${paramName}.${elements.length}`}
                zodSchema={unwrapped.element}
                orgs={orgs}
              />
            )
          }
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const StringInput: React.FC<InputProps> = ({ paramName }) => {
  const { register } = useFormContext();
  return (
    <input
      {...register(paramName, { required: true })}
      type="text"
      name={paramName}
      id={paramName}
      className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-standard-text placeholder:text-muted-text focus:ring-0 sm:text-sm sm:leading-6"
      placeholder="string"
    />
  );
};

const NumberInput: React.FC<InputProps> = ({ paramName }) => {
  const { register } = useFormContext();
  return (
    <input
      {...register(paramName, { required: true })}
      type="number"
      name={paramName}
      id={paramName}
      className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-standard-text placeholder:text-muted-text focus:ring-0 sm:text-sm sm:leading-6"
      placeholder="number"
    />
  );
};

const SELECT_STYLES =
  'block w-full rounded border-0 py-1.5 text-standard-text ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6';

const OrgSelect: React.FC<
  InputProps & {
    orgs: Org[];
  }
> = ({ orgs, paramName }) => {
  const { register } = useFormContext();
  return (
    <select
      {...register(paramName, { required: true })}
      id={paramName}
      name={paramName}
      className={SELECT_STYLES}
    >
      {orgs.map((org) => (
        <option key={org.name} value={org.id}>
          {org.name}
        </option>
      ))}
    </select>
  );
};

const EnumSelect: React.FC<
  InputProps & {
    opts: string[];
  }
> = ({ opts, paramName }) => {
  const { register } = useFormContext();
  return (
    <select
      {...register(paramName, { required: true })}
      id={paramName}
      name={paramName}
      className={SELECT_STYLES}
    >
      {opts.map((opt: string) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </select>
  );
};

const Error: React.FC<{ error: any }> = ({ error }) => (
  <div className="bg-red-100 text-red-900 text-sm px-3 py-2 mt-1 rounded">
    {error.message}
  </div>
);

const Label: React.FC<{ paramName: string; optional: boolean }> = ({
  paramName,
  optional,
}) => {
  return (
    <div className="flex justify-between">
      <label
        htmlFor={paramName}
        className="block text-sm font-medium leading-6 text-standard-text"
      >
        {paramName}
      </label>
      {optional && (
        <span className="text-sm leading-6 text-gray-500" id="email-optional">
          Optional
        </span>
      )}
    </div>
  );
};

const Grid: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="grid grid-cols-6 gap-x-6 gap-y-8 w-full">{children}</div>
);

const widthToColSpan = (width: number): string => {
  const styles = [
    'col-span-1',
    'col-span-2',
    'col-span-3',
    'col-span-4',
    'col-span-5',
    'col-span-6',
  ];
  Assert.assert(width <= styles.length);
  return styles[width - 1]!;
};

const handlerFromPath = (
  superAdmin: UntenantedClient['superAdmin'],
  path: string[]
): any => {
  let fn = superAdmin;
  for (const p of path) {
    fn = (fn as any)[p];
  }
  return fn;
};

const prepareSchema = (
  path: string[]
): { zodSchema: z.ZodObject<any>; prepareResult: (o: any) => any } => {
  const { params } = schemaFromPath(path);

  if (params instanceof z.ZodObject) {
    return { zodSchema: params, prepareResult: (o: any) => o };
  }

  const paramName = paramNameFromSchema(params);
  // The form resolver only really works with objects, so we make a zod
  // object to wrap positional arguments and give them a nice name for the
  // form.
  const schema = z.object({ [paramName]: params });

  return {
    zodSchema: schema,
    prepareResult: (o: any) => o[paramName],
  };
};

const paramNameFromSchema = (schema: z.ZodAny): string => {
  if (schema instanceof z.ZodString) {
    return schema.isEmail ? 'email' : schema.isUUID ? 'id' : 'string';
  }
  if (schema instanceof z.ZodNumber) {
    return 'number';
  }
  return 'param';
};

const schemaFromPath = (path: string[]): any => {
  let contract = SuperAdminContract;
  for (const p of path) {
    contract = (contract.routes as any)[p];
  }
  return contract;
};
