import { AST, Expression, Relation, Markup } from '@cotera/era';
import _ from 'lodash';

export const NOT_FOUND_BUT_LOADED_EMPTY_SCOPE: AST.MacroVarReplacements<AST.RelMacroChildren> =
  Object.freeze({ exprs: {}, rels: {}, sections: {} });

type Val = AST.MacroVarReplacements<AST.RelMacroChildren>;

export const resolveScopeDeps = (
  scopes: string[],
  supplied: Record<string, Val>
): { scope: string; val: Val }[] => {
  const directChildren: Record<string, string[]> = _.mapValues(
    supplied,
    ({ exprs, rels, sections }) => {
      const exprScopeReqs = Object.values(exprs).flatMap((expr) =>
        Object.keys(Expression.fromAst(expr).freeVars)
      );

      const relScopeReqs = Object.values(rels).flatMap((rel) =>
        Object.keys(Relation.wrap(rel).freeVars)
      );

      const muScopeReqs = Object.values(sections).flatMap((mu) =>
        Object.keys(Markup.fromAst(mu).freeVars)
      );

      return _.uniq([...exprScopeReqs, ...relScopeReqs, ...muScopeReqs]);
    }
  );

  const resolved: { scope: string; val: Val }[] = [];

  const resolve = (scope: string) => {
    if (resolved.some((r) => r.scope === scope)) {
      return;
    }

    resolved.push({
      scope,
      val: supplied[scope] ?? NOT_FOUND_BUT_LOADED_EMPTY_SCOPE,
    });

    const requires = directChildren[scope] ?? [];

    for (const child of requires) {
      resolve(child);
    }
  };

  scopes.forEach(resolve);
  return resolved.reverse();
};
