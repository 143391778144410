import { err, ok, Result } from 'neverthrow';
import { lex } from './lex';
import type { WithMeta, TokenMeta } from './types';
import { Token } from './tokens';

export class TokenStream {
  private buffer: WithMeta<Token>[];
  private stream: Generator<WithMeta<Token>>;

  constructor(
    private readonly str: string,
    opts?: { readonly offset?: number }
  ) {
    this.stream = lex(str, opts);
    this.buffer = [];
  }

  originalForMeta({ range }: TokenMeta): string {
    return this.str.slice(range[0], range[1]);
  }

  currentOffset(): number {
    const next = this.peak();
    return next?.[1].range[0] ?? this.str.length;
  }

  debug(): { next: WithMeta<Token> | null; split: readonly [string, string] } {
    const next = this.peak();
    const info = {
      next,
      split: [
        this.originalForMeta({
          range: [0, this.currentOffset()],
        }),
        this.originalForMeta({
          range: [this.currentOffset(), this.str.length],
        }),
      ] as const,
    };
    return info;
  }

  peak(offset: number = 0): WithMeta<Token> | null {
    const buffRequired = Math.max(offset + 1 - this.buffer.length, 0);

    for (let i = 0; i <= buffRequired; i++) {
      const next = this.stream.next();

      if (next.value) {
        this.buffer.push(next.value);
      } else {
        break;
      }
    }

    return this.buffer.at(offset) ?? null;
  }

  skipWhile(cb: (t: Token) => boolean): TokenStream {
    // eslint-disable-next-line
    while (true) {
      const res = this.consumeIf(cb);
      if (res.isErr()) {
        break;
      }
    }

    return this;
  }

  skipWhiteSpace(): TokenStream {
    return this.skipWhile(({ t }) => t === 'white-space');
  }

  consumeIf(cb: (x: Token) => boolean): Result<WithMeta<Token>, null | false> {
    const next = this.peak();

    if (next === null) {
      return err(null);
    }

    return cb(next[0]) ? ok(this.consume()!) : err(false);
  }

  consume(): WithMeta<Token> | null {
    if (this.buffer.length > 0) {
      return this.buffer.shift()!;
    }

    const next = this.stream.next();

    if (next.value) {
      return next.value;
    }

    return null;
  }

  isFinished(): boolean {
    return this.peak() === null;
  }
}
