export type AssignedFilter = 'assigned' | 'unassigned' | 'all' | 'ignored';

export type FormState = {
  search: string;
  filter: AssignedFilter;
};

export const ItemTypes = {
  ITEM: 'item',
  TOPIC: 'topic',
};
