import { PlusIcon } from '@heroicons/react/24/solid';
import { Loading } from '@cotera/client/app/components/ui';
import { useStore } from './store';
import { useEffect } from 'react';
import { toast } from '@cotera/client/app/components/ui/toaster';
import { preview, registerFile } from './queries';
import { useFilePicker } from 'use-file-picker';
import { useAppData } from '@cotera/client/app/stores/org';
import { From, Ty } from '@cotera/era';
const FILE_NAME = `embeddings_${Date.now()}.csv`;

export const EmptyState: React.FC = () => {
  const initedDb = useAppData((s) => s.initedDb);
  const loading = useStore((s) => s.loading);
  const setLoading = useStore((s) => s.setLoading);
  const setData = useStore((s) => s.setData);
  const setRel = useStore((s) => s.setRel);

  const { openFilePicker, plainFiles } = useFilePicker({
    accept: '.csv',
  });

  useEffect(() => {
    const load = async () => {
      toast.info('Loading file into database');
      setLoading(true);
      try {
        await registerFile(initedDb, plainFiles[0]!, FILE_NAME);
        const rel = From({
          uri: FILE_NAME,
          attributes: {
            id: Ty.sid('id'),
            embedding: Ty.EMBEDDING,
            content: 'string',
            timestamp: Ty.ty('timestamp'),
          },
        });
        const res = await preview(initedDb, rel);
        setData(res);
        setRel(rel);

        toast.success('File loaded into database');
      } catch (e) {
        toast.error('Failed to load file into database');
      } finally {
        setLoading(false);
      }
    };

    if (plainFiles.length > 0) {
      void load();
    }
  }, [plainFiles, initedDb, setLoading, setData, setRel]);

  return (
    <div className="text-center pt-40 w-full">
      <svg
        className="mx-auto h-12 w-12 text-muted-text"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-standard-text">No file</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by uploading a file to explore
      </p>
      <div className="mt-6">
        {!loading && (
          <button
            type="button"
            className="inline-flex items-center rounded bg-primary-background px-3 py-2 text-sm font-semibold text-alt-text shadow-sm hover:bg-primary-background focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={openFilePicker}
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Upload File
          </button>
        )}
        {loading && <Loading.Dots />}
      </div>
    </div>
  );
};
