import { forwardRef } from 'react';
import { UiComponent } from '../../types/ui-component';
import { ColorScheme } from '../../utils/colors/color-scheme';
import { ThemeableProps } from '../../types/themeable';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

type SpanProps = UiComponent &
  ChildrenProps &
  ThemeableProps &
  React.HTMLAttributes<HTMLSpanElement>;

export const Span = forwardRef<HTMLSpanElement, SpanProps>(
  ({ className, theme = 'regular', ...props }, ref) => {
    return (
      <span
        ref={ref}
        {...props}
        className={classNames(className, 'text-sm', ColorScheme.text[theme])}
      />
    );
  }
);
