import { z } from 'zod';
import { Result, ok, err } from 'neverthrow';

export type IsAny<T> = unknown extends T ? (T extends {} ? T : never) : never;

export type NotAny<T> = T extends IsAny<T> ? never : T;

export const matchesType = <T>(_x: T) => {};

export function isNotAny<T>(_x: NotAny<T>) {}

export function assertOk<T>(result: Result<T, unknown>): T {
  if (result.isOk()) {
    return result.value;
  } else {
    const error = new Error(
      `Expected 'ok', but got err(${JSON.stringify(result.error)})`
    );
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertOk);
    }
    throw error;
  }
}

export function assertErr<E>(result: Result<unknown, E>): E {
  if (result.isErr()) {
    return result.error;
  } else {
    const error = new Error(
      `Expected 'err', but got ok(${JSON.stringify(result.value)})'`
    );
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assertErr);
    }
    throw error;
  }
}

export function assert(cond: boolean, msg?: string): asserts cond {
  if (!cond) {
    const error = new Error(msg ?? 'Assertion Error');
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, assert);
    }
    throw error;
  }
}

export const parseZodToResult = <T extends z.ZodTypeAny>(
  schema: T,
  data: unknown
): Result<z.infer<T>, z.ZodError> => {
  const result = schema.safeParse(data);

  switch (result.success) {
    case true:
      return ok(result.data);
    case false:
      return err(result.error);
  }
};

export const Assert = {
  matchesType,
  assertErr,
  assertOk,
  isNotAny,
};
