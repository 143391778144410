import {
  And,
  Constant,
  Expression,
  Histogram,
  Not,
  Or,
  Relation,
  RelationRef,
  TC,
  Ty,
  Values,
} from '@cotera/era';
import { FilterGroup, FilterItem } from './types';
import { Assert } from '@cotera/utilities';
import {
  DATE_OPERATORS,
  NUMERIC_OPERATORS,
  STRING_OPERATORS,
} from './constants';
import { CategoricalAttributes } from '@cotera/sdk/core';

const filterItemToExpression =
  (ref: RelationRef) =>
  (item: FilterItem): Expression => {
    if (item.value) {
      Assert.assert(item.value !== undefined, 'Expected value');

      const attr = ref.attr(item.value.key);

      if (TC.isNumeric(attr.ty)) {
        const values = item.value.value.map((v) =>
          v !== null && v !== undefined ? Number(v) : null
        );

        Assert.assert(values.length === 1 || values.length === 2);
        Assert.assert(values[0] !== null && values[0] !== undefined);

        switch (item.value.operator as (typeof NUMERIC_OPERATORS)[number]) {
          case 'between': {
            return attr.between(values[0], values[1]!);
          }
          case 'greater than':
            return attr.gt(values[0]);
          case 'less than':
            return attr.lt(values[0]);
          case 'equals':
            return attr.eq(values[0]);
          case 'not equals':
            return attr.neq(values[0]);
        }
      }

      if (TC.isStringLike(attr.ty)) {
        const value = item.value.value[0];

        Assert.assert(value !== null && value !== undefined);

        switch (item.value.operator as (typeof STRING_OPERATORS)[number]) {
          case 'contains':
            return attr.like(`%${value}%`);
          case 'equals':
            return attr.eq(value);
          case 'not equals':
            return attr.neq(value);
          case 'ends with':
            return attr.like(`%${value}`);
          case 'starts with':
            return attr.like(`${value}%`);
          case 'does not contain':
            return Not(attr.like(`%${value}%`));
          case 'one-of':
            return attr.oneOf(item.value.value.filter((v) => v !== null));
        }
      }

      if (
        TC.implementsTy({
          req: 'boolean',
          subject: attr.ty,
        })
      ) {
        Assert.assert(item.value.value.length === 1);
        Assert.assert(
          item.value.value[0] !== null && item.value.value[0] !== undefined
        );

        return attr.eq(item.value.value[0] === 'true');
      }

      if (
        TC.implementsTy({
          req: 'timestamp',
          subject: attr.ty,
        })
      ) {
        const values = item.value.value.map((v) =>
          v !== null && v !== undefined ? new Date(v) : null
        );

        Assert.assert(values.length === 1 || values.length === 2);
        Assert.assert(values[0] !== null && values[0] !== undefined);

        switch (item.value.operator as (typeof DATE_OPERATORS)[number]) {
          case 'between':
            return attr.cast('timestamp').between(values[0], values[1]!);
          case 'before':
            return attr.cast('timestamp').lt(values[0]);
          case 'after':
            return attr.cast('timestamp').gt(values[0]);
          case 'equals':
            return attr.cast('timestamp').eq(values[0]);
          case 'not equals':
            return attr.cast('timestamp').neq(values[0]);
        }
      }

      throw new Error(`Invalid filter conversion for ${JSON.stringify(item)}`);
    } else if (item.group) {
      return filterGroupToExpression(ref)(item.group);
    }

    throw new Error(`Invalid filter item ${JSON.stringify(item)}`);
  };

const removeNulls = (item: FilterItem): FilterItem => {
  if (item.value) {
    return {
      ...item,
      value: {
        ...item.value,
        value: item.value.value.filter((v) => v !== null && v !== undefined),
      },
    };
  }

  if (item.group) {
    return {
      ...item,
      group: {
        ...item.group,
        items: item.group.items.map(removeNulls),
      },
    };
  }

  throw new Error('Invalid filter item');
};

export const filterGroupToExpression =
  (ref: RelationRef, defaultExpr: Expression = Constant(true)) =>
  (group: FilterGroup): Expression => {
    const expressions = group.items
      .map(removeNulls)
      .filter((item) => (item.value?.value.length ?? 0) > 0 || item.group)
      .map(filterItemToExpression(ref));

    if (expressions.length === 0) {
      return defaultExpr;
    }

    if (group.connective === 'and') {
      return And(...expressions);
    }

    if (group.connective === 'or') {
      return Or(...expressions);
    }

    throw new Error(`Invalid connective ${group.connective}`);
  };

export const makeStatsQuery = ({
  type,
  col,
  rel,
}: {
  type: Ty.ExtendedAttributeType;
  col: string;
  rel: Relation;
}) => {
  if (TC.isNumeric(type)) {
    return Histogram(
      rel,
      (t) => ({ target: t.attr(col), group: Constant('all') }),
      {
        numBuckets: 10,
      }
    );
  }

  if (TC.isStringLike(type)) {
    return CategoricalAttributes(rel, (t) => t.attr(col), {
      discreteThreshold: 10,
    });
  }

  return Values([]);
};
