import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { Button } from '../button';
import { useState } from 'react';
import { Inputs } from '@cotera/client/app/components/forms';

type SearchableProps = {
  className?: string;
  value: string;
  onChange: (value: string) => void;
} & ChildrenProps;
export const Searchable: React.FC<SearchableProps> = ({
  children,
  className,
  value,
  onChange,
}) => {
  const [isSearching, setIsSearching] = useState(false);

  return (
    <div className={classNames('flex items-center', className)}>
      {!isSearching && (
        <>
          <Button
            icon="search"
            inline
            onClick={() => {
              setIsSearching(true);
            }}
          />
          {children}
        </>
      )}
      {isSearching && (
        <>
          <Inputs.Text
            icon="search"
            autoFocus
            className="w-full"
            placeholder="Search"
            value={value}
            onChange={(v) => {
              onChange(v);
            }}
          />
          <Button
            icon="close"
            inline
            onClick={() => {
              setIsSearching(false);
              onChange('');
            }}
          />
        </>
      )}
    </div>
  );
};
