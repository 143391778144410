import { chunk } from 'lodash';
import { DateTime } from 'luxon';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import React, { forwardRef, useState } from 'react';
import { generateCalendarArray } from './utils';
import { useKeyDownState } from '@cotera/client/app/hooks';
import { Text } from '../../ui/text';
import { makePopover } from '../../headless/popover';
import { Button } from '../../ui/button';
import { FormComponentProps } from '../../types/form-component';
import { List } from '../../headless/list';
import { withAutoFocus } from '../utils';

export type Range = [Date | null, Date | null];

const Popover = makePopover(Button);

type Props = {
  value: Range;
  onChange: (value: Range) => void;
  className?: string;
  nullable?: boolean;
  rangeEnabled?: boolean;
  label?: string;
} & FormComponentProps;

export const DatePicker: React.FC<Props> = ({
  value,
  onChange,
  className,
  rangeEnabled = true,
  label,
  compact,
  icon,
  disabled,
  theme = 'regular',
}) => {
  const calendarRef = React.useRef<HTMLDivElement>(null);
  return (
    <Popover
      as={Button}
      theme={theme}
      disabled={disabled}
      className={className}
      label={label}
      compact={compact}
      icon={icon}
      text={
        (value[0]?.getTime() === value[1]?.getTime()
          ? value[0]?.toDateString()
          : `${value[0]?.toDateString()} - ${value[1]?.toDateString()}`) ?? ''
      }
      anchor="bottom start"
      panel={{
        view: (
          <AutoFocusedCalendar
            ref={calendarRef}
            value={value}
            onChange={onChange}
            rangeEnabled={rangeEnabled}
          />
        ),
      }}
    />
  );
};

const Calendar = forwardRef<
  HTMLDivElement,
  { value: Range; onChange: (value: Range) => void; rangeEnabled?: boolean }
>(({ value, onChange, rangeEnabled }, ref) => {
  const [startDate, endDate] = value;
  const [dateInView, setDateInView] = useState(endDate ?? new Date());

  const rows = chunk(generateCalendarArray(dateInView), 7);
  const isShiftPressed = useKeyDownState('Shift');
  const usingRage = isShiftPressed && rangeEnabled;
  const today = DateTime.fromJSDate(new Date()).startOf('day');

  return (
    <div
      className="w-full shadow-sm focus:ring-0 focus:outline-none"
      tabIndex={0}
      ref={ref}
    >
      <div className="flex px-6 py-4 bg-white rounded-t">
        <div className="flex flex-col pr-6">
          <div className="flex items-center justify-between">
            <span className="focus:outline-none text-base font-semibold text-accent-text">
              {DateTime.fromJSDate(dateInView).toFormat('MMM yyyy')}
            </span>
            <div className="flex items-center">
              <button
                aria-label="calendar backward"
                className="hover:text-accent-text text-standard-text"
                onClick={() => {
                  setDateInView(
                    DateTime.fromJSDate(dateInView)
                      .minus({ months: 1 })
                      .toJSDate()
                  );
                }}
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </button>
              <button
                aria-label="calendar forward"
                className="hover:text-gray-400 ml-3 text-gray-800"
                onClick={() => {
                  setDateInView(
                    DateTime.fromJSDate(dateInView)
                      .plus({ months: 1 })
                      .toJSDate()
                  );
                }}
              >
                <ChevronRightIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between pt-12 overflow-x-auto mb-2">
            <table className="w-full">
              <thead>
                <tr>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        Mo
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        Tu
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        We
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        Th
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        Fr
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        Sa
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-gray-800">
                        Su
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <tr key={index}>
                      {row.map((day, index) => {
                        const isInRange =
                          startDate !== null &&
                          endDate !== null &&
                          day !== '' &&
                          DateTime.fromJSDate(startDate).startOf('day') <=
                            DateTime.fromJSDate(dateInView)
                              .set({ day: Number(day) })
                              .startOf('day') &&
                          DateTime.fromJSDate(dateInView)
                            .set({ day: Number(day) })
                            .startOf('day') <=
                            DateTime.fromJSDate(endDate).startOf('day');
                        const dateOnCal = DateTime.fromJSDate(dateInView).set({
                          day: Number(day),
                        });
                        const isEndDate =
                          endDate !== null &&
                          day !== '' &&
                          DateTime.fromJSDate(endDate)
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            })
                            .toISODate() === dateOnCal.toISODate();
                        const isStartDate =
                          startDate !== null &&
                          day !== '' &&
                          DateTime.fromJSDate(startDate)
                            .set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            })
                            .toISODate() ===
                            dateOnCal.set({ day: Number(day) }).toISODate();

                        return (
                          <td key={index}>
                            <Button
                              onClick={() => {
                                if (
                                  startDate === null ||
                                  endDate === null ||
                                  !isShiftPressed
                                ) {
                                  onChange([
                                    dateOnCal.toJSDate(),
                                    dateOnCal.toJSDate(),
                                  ]);
                                } else if (usingRage) {
                                  document.getSelection()?.removeAllRanges();
                                  if (
                                    dateOnCal.startOf('day').toJSDate() <
                                    startDate
                                  ) {
                                    onChange([dateOnCal.toJSDate(), endDate]);
                                  } else {
                                    onChange([startDate, dateOnCal.toJSDate()]);
                                  }
                                }
                              }}
                              inline
                              text={day}
                              theme={
                                isStartDate
                                  ? 'primary'
                                  : isEndDate
                                  ? 'primary'
                                  : 'regular'
                              }
                              active={isInRange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {rangeEnabled && (
            <Text.Caption className="mt-2 flex items-center">
              <InformationCircleIcon className="h-4 w-4 mr-2" /> Hold shift to
              select range
            </Text.Caption>
          )}
        </div>
        <div className="flex flex-col pl-6 border-l border-divider">
          <List.Ul selectedIndex={0}>
            <List.Li
              as={Button}
              inline
              text={'Today'}
              onClick={() => {
                onChange([today.toJSDate(), today.toJSDate()]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Yesterday'}
              onClick={() => {
                onChange([
                  today.minus({ days: 1 }).toJSDate(),
                  today.minus({ days: 1 }).toJSDate(),
                ]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Last 7 Days'}
              onClick={() => {
                onChange([
                  today.minus({ days: 7 }).toJSDate(),
                  today.toJSDate(),
                ]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Last 30 Days'}
              onClick={() => {
                onChange([
                  today.minus({ days: 30 }).toJSDate(),
                  today.toJSDate(),
                ]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Month to Date'}
              onClick={() => {
                onChange([today.set({ day: 1 }).toJSDate(), today.toJSDate()]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Last 3 Months'}
              onClick={() => {
                onChange([
                  today.minus({ month: 3 }).toJSDate(),
                  today.toJSDate(),
                ]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Last 6 Months'}
              onClick={() => {
                onChange([
                  today.minus({ month: 6 }).toJSDate(),
                  today.toJSDate(),
                ]);
              }}
            />
            <List.Li
              as={Button}
              inline
              text={'Year to Date'}
              onClick={() => {
                onChange([today.startOf('year').toJSDate(), today.toJSDate()]);
              }}
            />
          </List.Ul>
        </div>
      </div>
    </div>
  );
});

const AutoFocusedCalendar = withAutoFocus(Calendar);
