export const IterableLogo = ({
  height = 35,
  width,
}: {
  height?: number;
  width?: number;
}) => (
  <div
    className="flex items-center"
    style={{
      width,
    }}
  >
    <svg
      className="mr-2"
      data-test="color-horizontal-logo-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height={height}
      width={height}
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="#6A266D"
          d="M2.46327722 19.063383L19.90195533 1.62470487l8.784898 8.784898L11.24817522 27.848281z"
          opacity="0.15"
        ></path>
        <path
          fill="#34C3F2"
          d="M19.75854796 10.82599175l8.784898-8.784898 17.43867812 17.43867812-8.784898 8.784898z"
          opacity="0.25"
        ></path>
        <path
          fill="#59C1A7"
          d="M19.86557152 37.05314013L37.30424963 19.614462l8.784898 8.784898-17.43867811 17.43867812z"
          opacity="0.25"
        ></path>
        <path
          fill="#EF3D55"
          d="M2.04960266 28.41855201l8.784898-8.784898 17.43867812 17.43867812-8.784898 8.784898z"
          opacity="0.15"
        ></path>
        <g>
          <circle
            cx="24.0364238"
            cy="6.30463576"
            r="6.25827815"
            fill="#6A266D"
          ></circle>
          <circle
            cx="41.6986755"
            cy="24.0364238"
            r="6.25827815"
            fill="#34C3F2"
          ></circle>
          <circle
            cx="24.0364238"
            cy="41.6986755"
            r="6.25827815"
            fill="#59C1A7"
          ></circle>
          <circle
            cx="6.37417219"
            cy="23.9668874"
            r="6.25827815"
            fill="#EF3D55"
          ></circle>
        </g>
      </g>
    </svg>
    <svg
      className="itbl-b-brand-wordmark"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 462.8 60.1"
      height={height * 0.5}
    >
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M154.2,0.1h-32.9c-0.6,0-1.1,0.5-1.1,1.1v57.9c0,0.6,0.5,1.1,1.1,1.1h32.9c0.6,0,1.1-0.5,1.1-1.1v-9.2
        c0-0.6-0.5-1.1-1.1-1.1l0,0h-20.1c-1.2,0-2.1-0.9-2.1-2.1v-9.5c0-1.2,0.9-2.1,2.1-2.1h18.4c0.6,0,1.1-0.5,1.1-1.1v-8.3
        c0-0.6-0.5-1.1-1.1-1.1h-18.4c-1.2,0-2.1-0.9-2.1-2.1v-8.8c0-1.2,0.9-2.1,2.1-2.1h20.1c0.6,0,1.1-0.5,1.1-1.1V1.3
        C155.3,0.6,154.8,0.1,154.2,0.1L154.2,0.1z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M290.8,60.2c0.4,0,0.7-0.2,0.9-0.5s0.2-0.7,0.1-1l-23.2-58c-0.2-0.4-0.6-0.7-1-0.7h-5.9c-0.4,0-0.8,0.3-1,0.7
        l-23.2,57.9c-0.1,0.3-0.1,0.7,0.1,1c0.2,0.3,0.6,0.5,0.9,0.5h10.6c0.5,0,0.8-0.3,1-0.7l2.9-8c0.3-0.8,1.1-1.4,2-1.4h19.5
        c0.9,0,1.7,0.6,2,1.4l2.8,8c0.2,0.4,0.5,0.7,1,0.7L290.8,60.2z M271,38.9c-0.4,0.6-1,0.9-1.7,0.9h-9.1c-0.7,0-1.3-0.3-1.7-0.9
        s-0.5-1.3-0.3-1.9l4.5-12.7c0.4-1.1,1.6-1.7,2.7-1.3c0.6,0.2,1.1,0.7,1.3,1.3l4.5,12.7C271.5,37.6,271.4,38.3,271,38.9z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M405.6,60.2c0.6,0,1.1-0.5,1.1-1.1v-9.2c0-0.6-0.5-1.1-1.1-1.1l0,0h-17.1c-1.2,0-2.1-0.9-2.1-2.1V1.2
        c0-0.6-0.5-1.1-1.1-1.1h-9.6c-0.6,0-1.1,0.5-1.1,1.1v57.9c0,0.6,0.5,1.1,1.1,1.1L405.6,60.2L405.6,60.2z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M312.2,60.2h22.7c3.5,0.1,7-0.7,10.1-2.4c2.7-1.5,4.9-3.7,6.4-6.3c1.4-2.5,2.2-5.4,2.2-8.3
        c0.2-6.3-3.4-12.1-9.1-14.9c-0.2-0.1-0.3-0.3-0.3-0.5v-0.3l0.2-0.3c3.4-2.8,5.3-7,5.2-11.4c0-2.8-0.7-5.5-2-7.9
        c-1.4-2.5-3.5-4.5-6-5.7c-3.1-1.5-6.5-2.3-10-2.2h-19.3c-0.7,0-1.2,0.5-1.2,1.2V59C311,59.7,311.6,60.2,312.2,60.2z M322.9,13.3
        c0-1,0.8-1.8,1.8-1.8h5.2c2-0.1,4,0.5,5.5,1.8c1.1,1,1.8,2.3,2,3.8c0.3,2.1-0.5,4.1-2,5.6c-1.5,1.4-3.6,2.1-5.6,2h-5.2
        c-1,0-1.8-0.8-1.8-1.8L322.9,13.3z M322.9,36.3c0-1,0.8-1.8,1.8-1.8h8c2.1-0.1,4.2,0.6,5.8,2.1c1.5,1.3,2.3,3.1,2.3,5.1
        s-0.8,3.9-2.2,5.2c-1.6,1.5-3.7,2.2-5.9,2.1h-7.9c-1,0-1.9-0.9-1.9-1.9l0,0L322.9,36.3z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M209.5,39.4c-0.6-1-0.2-2.2,0.7-2.8c2.6-1.6,4.8-3.7,6.3-6.4c1.8-3.1,2.8-6.7,2.7-10.4c0-3.4-0.9-6.7-2.5-9.7
        c-1.7-3-4.2-5.6-7.2-7.3c-3.3-1.9-7-2.8-10.8-2.8h-18.3c-0.6,0-1.1,0.5-1.1,1.1V59c0,0.6,0.5,1.1,1.1,1.1h9.6c0.6,0,1.1-0.5,1.1-1.1
        V41.5c0-1.2,0.9-2.1,2.1-2.1h2.9c0.8,0,1.5,0.4,1.9,1.1l9.9,19c0.2,0.4,0.6,0.6,1,0.6h10.9c0.6,0,1.1-0.4,1.2-1c0-0.2,0-0.4-0.2-0.6
        L209.5,39.4z M204.6,25.9c-1.8,1.6-4.2,2.4-6.6,2.3h-4.8c-1.2,0-2.1-0.9-2.1-2.1V13.6c0-1.2,0.9-2.1,2.1-2.1h4.8
        c2.4-0.1,4.8,0.8,6.6,2.4c3.3,3.2,3.4,8.5,0.2,11.8C204.7,25.7,204.6,25.8,204.6,25.9L204.6,25.9z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M98.2,0.1H60.3c-0.6,0-1.1,0.5-1.1,1.1l0,0v9.2c0,0.6,0.5,1.1,1.1,1.1l0,0h11.1c1.2,0,2.1,0.9,2.1,2.1l0,0v45.6
        c0,0.6,0.5,1.1,1.1,1.1h9.7c0.6,0,1.1-0.5,1.1-1.1l0,0V13.5c0-1.2,0.9-2.1,2.1-2.1h10.7c0.6,0,1.1-0.5,1.1-1.1V1.1
        C99.3,0.6,98.8,0.1,98.2,0.1L98.2,0.1z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M461.6,48.8h-20.1c-1.2,0-2.1-0.9-2.1-2.1v-9.5c0-1.2,0.9-2.1,2.1-2.1h18.4c0.6,0,1.1-0.5,1.1-1.1v-8.3
        c0-0.6-0.5-1.1-1.1-1.1h-18.4c-1.2,0-2.1-0.9-2.1-2.1v-8.8c0-1.2,0.9-2.1,2.1-2.1h20.1c0.6,0,1.1-0.5,1.1-1.1V1.3
        c0-0.6-0.5-1.1-1.1-1.1h-32.9c-0.6,0-1.1,0.5-1.1,1.1v57.9c0,0.6,0.5,1.1,1.1,1.1h33c0.6,0,1.1-0.5,1.1-1.1V50
        C462.7,49.3,462.2,48.8,461.6,48.8z"
      ></path>
      <path
        className="itbl-b-brand-wordmark__letter"
        d="M36.8,48.7h-9.5c-1.2,0-2.1-0.9-2.1-2.1l0,0V13.5c0-1.2,0.9-2.1,2.1-2.1h9.6c0.6,0,1.1-0.5,1.1-1.1l0,0V1.1
        C38,0.5,37.5,0,36.9,0l0,0H1.1C0.5,0,0,0.5,0,1.1l0,0v9.3c0,0.6,0.5,1.1,1.1,1.1l0,0h10.1c1.2,0,2.1,0.9,2.1,2.1v33.2
        c0,1.1-1,2-2.1,2H1.1c-0.6,0-1.1,0.5-1.1,1.1l0,0v9.2c0,0.6,0.5,1.1,1.1,1.1l0,0h13c0.1,0,0.2,0.1,0.3,0.1h9.7c0.1,0,0.2,0,0.3-0.1
        h12.5c0.6,0,1.1-0.5,1.1-1.1l0,0v-9.2C37.9,49.2,37.4,48.7,36.8,48.7L36.8,48.7z"
      ></path>
    </svg>
  </div>
);
