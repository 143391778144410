import _ from 'lodash';

export class AsyncResult<T> {
  private constructor(
    public readonly loading: boolean,
    public readonly data?: T,
    public readonly error?: unknown
  ) {
    Object.freeze(this);
  }

  static loading<T = never>() {
    return new AsyncResult<T>(true);
  }

  static ready<T>(data: T) {
    return new AsyncResult<T>(false, data);
  }

  static errored<T = never>(error: any) {
    return new AsyncResult<T>(false, undefined, error);
  }

  static all<T>(results: AsyncResult<T>[]): AsyncResult<T[]> {
    const output: T[] = [];

    for (const res of results) {
      if (res.isError()) {
        return AsyncResult.errored(res.error);
      }
    }

    for (const res of results) {
      if (res.isLoadingData()) {
        return AsyncResult.loading();
      } else {
        output.push(res.unwrap());
      }
    }

    return AsyncResult.ready(output);
  }

  static combine<T extends { [name: string]: AsyncResult<unknown> }>(
    results: T
  ): AsyncResult<{
    [Key in keyof T]: T[Key] extends AsyncResult<infer X> ? X : never;
  }> {
    const data: Record<string, unknown> = {};
    for (const [name, res] of _.sortBy(
      Object.entries(results),
      ([name]) => name
    )) {
      if (res.isError()) {
        return res as unknown as AsyncResult<never>;
      }

      if (res.isLoadingData()) {
        return res as unknown as AsyncResult<never>;
      }

      data[name] = res.data;
    }

    return AsyncResult.ready(data as any);
  }

  static from<T>(res: {
    isLoading: boolean;
    data?: T;
    error?: any;
    isIdle?: boolean;
  }) {
    return new AsyncResult(
      res.isLoading || (res.isIdle ?? false),
      res.data,
      res.error
    );
  }

  isLoadingData(): this is {
    loading: true;
    data: undefined;
    error: undefined;
  } {
    return this.loading && !this.error && this.data === undefined;
  }

  hasData(): this is { data: T; loading: false; error: undefined } {
    return this.data !== undefined;
  }

  isError(): this is { data: undefined; loading: false; error: any } {
    return !this.loading && !!this.error && this.data === undefined;
  }

  getOrDefault<U>(defaultVal: U): T | U {
    if (this.isError()) {
      throw this.error;
    }
    return this.hasData() ? this.data : defaultVal;
  }

  unwrap(): T {
    if (this.error) {
      throw this.error;
    }

    if (!this.hasData()) {
      const e = new Error('No value!');

      if ((Error as any).captureStackTrace) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        (Error as any).captureStackTrace(e, this.unwrap);
      }

      throw e;
    }

    return this.data;
  }

  map<K>(fn: (data: T) => K) {
    if (this.hasData()) {
      return AsyncResult.ready<K>(fn(this.data));
    }

    return this as unknown as AsyncResult<K>;
  }

  async mapAsync<K>(fn: (data: T) => Promise<K>): Promise<AsyncResult<K>> {
    if (this.hasData()) {
      try {
        const res = await fn(this.data);
        return AsyncResult.ready(res);
      } catch (e) {
        return AsyncResult.errored(e);
      }
    } else {
      return this as unknown as AsyncResult<never>;
    }
  }

  flatMap<K>(fn: (data: T) => AsyncResult<K>): AsyncResult<K> {
    if (this.hasData()) {
      return fn(this.data);
    }
    return AsyncResult.from<K>({
      isLoading: this.loading,
      error: this.error,
    });
  }
}
