import { AST, Constant, Relation, Ty } from '@cotera/era';
import { CompileMarkup } from './compiler';
import { ProvideEraScope } from './macro-expansion/scopes';
import React from 'react';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';

export const ForEach: React.FC<{
  section: AST._ForEach;
  inBlock: boolean;
}> = ({ section, inBlock }) => {
  const rel = Relation.wrap(section.rel);
  const { data } = useDuckDBQuery({ rel });

  const result = data.data.toArray().map(
    (row): AST.MacroVarReplacements<AST.RelMacroChildren> => ({
      exprs: { data: Constant(row, { ty: Ty.s(rel.attributes) }).ast },
      rels: {},
      sections: {},
    })
  );

  const rows = result.map((vals, i) => {
    return (
      <ProvideEraScope scope={section.body.scope} vals={vals}>
        <CompileMarkup key={i} inBlock={inBlock} section={section.body.macro} />
      </ProvideEraScope>
    );
  });

  return <> {rows} </>;
};
