import { Values } from '@cotera/era';

export const UserLifecycleMetrics = Values([
  {
    __COTERA_CUSTOMER_ID: '00001',
    RFM_CATEGORY: 'Top Users',
    CHURN_RISK: 'Very Low Churn Risk',
    CUSTOMER_AGE_IN_DAYS: 541,
    ACTIVITY_TREND_30_DAYS: 'Consistent or Increasing Activity',
    TOTAL_LIFETIME_MINUTES: 7240,
    DAYS_SINCE_LAST_ACTIVITY: 1,
    AVG_DAYS_BETWEEN_ACTIVITY: 189,
    TOTAL_MINUTES_LAST_30_DAYS: 410,
    TOTAL_MINUTES_LAST_30_60_DAYS: 395,
  },
  {
    __COTERA_CUSTOMER_ID: '00002',
    RFM_CATEGORY: 'Top Users',
    CHURN_RISK: 'Very Likely to Churn',
    CUSTOMER_AGE_IN_DAYS: 294,
    ACTIVITY_TREND_30_DAYS: 'Declining Activity',
    TOTAL_LIFETIME_MINUTES: 3255,
    DAYS_SINCE_LAST_ACTIVITY: 12,
    AVG_DAYS_BETWEEN_ACTIVITY: 236,
    TOTAL_MINUTES_LAST_30_DAYS: 120,
    TOTAL_MINUTES_LAST_30_60_DAYS: 580,
  },
  {
    __COTERA_CUSTOMER_ID: '00003',
    RFM_CATEGORY: 'Top Users',
    CHURN_RISK: 'Somewhat Likely to Churn',
    CUSTOMER_AGE_IN_DAYS: 128,
    ACTIVITY_TREND_30_DAYS: 'Declining Activity',
    TOTAL_LIFETIME_MINUTES: 1855,
    DAYS_SINCE_LAST_ACTIVITY: 6,
    AVG_DAYS_BETWEEN_ACTIVITY: 261,
    TOTAL_MINUTES_LAST_30_DAYS: 665,
    TOTAL_MINUTES_LAST_30_60_DAYS: 840,
  },
  {
    __COTERA_CUSTOMER_ID: '00004',
    RFM_CATEGORY: 'Repeat Users',
    CHURN_RISK: 'Very Low Churn Risk',
    CUSTOMER_AGE_IN_DAYS: 203,
    ACTIVITY_TREND_30_DAYS: 'Consistent or Increasing Activity',
    TOTAL_LIFETIME_MINUTES: 1230,
    DAYS_SINCE_LAST_ACTIVITY: 6,
    AVG_DAYS_BETWEEN_ACTIVITY: 430,
    TOTAL_MINUTES_LAST_30_DAYS: 215,
    TOTAL_MINUTES_LAST_30_60_DAYS: 205,
  },
  {
    __COTERA_CUSTOMER_ID: '00005',
    RFM_CATEGORY: 'Repeat Users',
    CHURN_RISK: 'Very Likely to Churn',
    CUSTOMER_AGE_IN_DAYS: 95,
    ACTIVITY_TREND_30_DAYS: 'Declining Activity',
    TOTAL_LIFETIME_MINUTES: 1360,
    DAYS_SINCE_LAST_ACTIVITY: 15,
    AVG_DAYS_BETWEEN_ACTIVITY: 526,
    TOTAL_MINUTES_LAST_30_DAYS: 135,
    TOTAL_MINUTES_LAST_30_60_DAYS: 265,
  },
  {
    __COTERA_CUSTOMER_ID: '00006',
    RFM_CATEGORY: 'Repeat Users',
    CHURN_RISK: 'Somewhat Likely to Churn',
    CUSTOMER_AGE_IN_DAYS: 465,
    ACTIVITY_TREND_30_DAYS: 'Declining Activity',
    TOTAL_LIFETIME_MINUTES: 3825,
    DAYS_SINCE_LAST_ACTIVITY: 8,
    AVG_DAYS_BETWEEN_ACTIVITY: 624,
    TOTAL_MINUTES_LAST_30_DAYS: 80,
    TOTAL_MINUTES_LAST_30_60_DAYS: 265,
  },

  {
    __COTERA_CUSTOMER_ID: '00007',
    RFM_CATEGORY: 'At Risk Users',
    CHURN_RISK: 'Very Low Churn Risk',
    CUSTOMER_AGE_IN_DAYS: 421,
    ACTIVITY_TREND_30_DAYS: 'Consistent or Increasing Activity',
    TOTAL_LIFETIME_MINUTES: 1690,
    DAYS_SINCE_LAST_ACTIVITY: 12,
    AVG_DAYS_BETWEEN_ACTIVITY: 1186,
    TOTAL_MINUTES_LAST_30_DAYS: 45,
    TOTAL_MINUTES_LAST_30_60_DAYS: 55,
  },
  {
    __COTERA_CUSTOMER_ID: '00008',
    RFM_CATEGORY: 'At Risk Users',
    CHURN_RISK: 'Somewhat Likely to Churn',
    CUSTOMER_AGE_IN_DAYS: 95,
    ACTIVITY_TREND_30_DAYS: 'Consistent or Increasing Activity',
    TOTAL_LIFETIME_MINUTES: 1360,
    DAYS_SINCE_LAST_ACTIVITY: 25,
    AVG_DAYS_BETWEEN_ACTIVITY: 1326,
    TOTAL_MINUTES_LAST_30_DAYS: 75,
    TOTAL_MINUTES_LAST_30_60_DAYS: 60,
  },
  {
    __COTERA_CUSTOMER_ID: '00009',
    RFM_CATEGORY: 'At Risk Users',
    CHURN_RISK: 'Low Churn Risk',
    CUSTOMER_AGE_IN_DAYS: 168,
    ACTIVITY_TREND_30_DAYS: 'Consistent or Increasing Activity',
    TOTAL_LIFETIME_MINUTES: 985,
    DAYS_SINCE_LAST_ACTIVITY: 8,
    AVG_DAYS_BETWEEN_ACTIVITY: 621,
    TOTAL_MINUTES_LAST_30_DAYS: 60,
    TOTAL_MINUTES_LAST_30_60_DAYS: 65,
  },
]).select((t) => ({
  ...t.star(),
  AVG_DAYS_BETWEEN_ACTIVITY: t.attr('AVG_DAYS_BETWEEN_ACTIVITY').div(100),
}));
