import { ReactNode } from 'react';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

export type HeaderProps = ChildrenProps;

const Container: React.FC<HeaderProps> = ({ children }) => {
  return (
    <header
      className={classNames(
        'z-10 justify-between',
        'flex-shrink-0 flex flex-row',
        'text-sm bg-white px-6 border-b border-divider'
      )}
      style={{ height: 'var(--header-height)' }}
    >
      {children}
    </header>
  );
};

const Item: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="flex items-center px-4 [&:not(:last-child)]:border-r border-divider text-sm text-standard-text">
      {children}
    </div>
  );
};

export const Header = {
  Container,
  Item,
};
