import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';
import { TC, Ty } from '@cotera/era';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';

export const TIMESTAMP_FIELD_NAME_HUERISTICS = [
  'created_at',
  'timestamp',
  'detected_at',
];

export const DEFAULT_TIME_RANGE: [Date, Date] = [
  DateTime.now().minus({ year: 1 }).toJSDate(),
  DateTime.now().toJSDate(),
];

const useDetectedTimestampFilters = (attributes: {
  [name: string]: Ty.ExtendedAttributeType;
}): {
  value: [Date, Date];
  attr: string;
  t: Ty.ExtendedAttributeType;
  operator: 'between';
}[] => {
  const timestampFields = Object.entries(attributes).filter(([_, ty]) =>
    TC.implementsTy({ req: 'timestamp', subject: ty })
  );

  const candidates = timestampFields.filter(([attr]) =>
    TIMESTAMP_FIELD_NAME_HUERISTICS.some(
      (x) => x.toLocaleLowerCase() === attr.toLocaleLowerCase()
    )
  );

  return candidates.map(([attr, t]) => {
    return {
      value: DEFAULT_TIME_RANGE,
      attr,
      operator: 'between',
      t,
    };
  });
};

export const useDetectedQuickFilters = (attributes: {
  [name: string]: Ty.ExtendedAttributeType;
}) => {
  const timestampFilters = useDetectedTimestampFilters(attributes);

  return useDeepMemo(() => {
    return [...timestampFilters].map((x) => ({
      id: v4(),
      value: {
        ...x,
        key: x.attr,
        value: x.value.map(String),
        compact: true,
      },
    }));
  }, [attributes]);
};
