import { Relation } from '@cotera/era';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { DuckDBQueryResult } from '../duckdb';
import { CancelablePromise } from '@cotera/client/app/etc';
import { useAppData, useWhoami } from '@cotera/client/app/stores/org';
import { DataProvider } from './fn.type';
import { useGetOrCreateArtifact } from './artifacts';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Assert } from '@cotera/utilities';
import { useEraScopesAwareRelIR } from '../../pages/apps/compiler/macro-expansion/scopes';
import { useAbortController } from './hook';

export type QueryData = {
  data: DuckDBQueryResult;
  artifactRel: Relation;
  fromArtifactId: string | null;
};

export const useDuckDBQuery: DataProvider<
  {
    rel: Relation;
    onArtifact?: (props: {
      id: string | null;
      sourceHash: string;
      rel: Relation;
    }) => void;
  },
  QueryData
> = (props, opts) => {
  const abort = useAbortController({
    abortOnUnmount: true,
  });
  const ir = useEraScopesAwareRelIR(props.rel);
  const { data: artifact } = useGetOrCreateArtifact(
    { rel: Relation.wrap(ir), onArtifact: props.onArtifact },
    {
      abort: opts?.abort ?? abort,
    }
  );
  const initedDb = useAppData((s) => s.initedDb);

  const sqlHash = Relation.wrap(ir).sqlHash();
  return useSuspenseQuery({
    queryKey: queryKeys.artifacts.data({
      orgId: useWhoami((x) => x.org.id),
      sqlHash,
    }),
    queryFn: async () => {
      const db = await initedDb;
      if (artifact.isErr()) {
        throw new Error(artifact.error.message);
      }

      const artifactRel = Relation.fromAst(artifact.value.ir);
      const data = await new CancelablePromise(db.query(artifactRel), {
        controller: opts?.abort ?? abort,
      });

      const result = new DuckDBQueryResult(data, artifactRel);

      return {
        data: result,
        artifactRel,
        fromArtifactId: artifact.value.meta?.artifactId ?? null,
      };
    },
  });
};

export const useArtifactQuery: DataProvider<
  {
    baseRel: Relation;
    rel: (rel: Relation) => Relation;
    limit: number;
  },
  QueryData
> = (props, opts) => {
  const { data: artifact } = useGetOrCreateArtifact({
    rel: props.baseRel.limit(props.limit),
  });
  const artifactRel = Relation.wrap(Assert.assertOk(artifact).ir);
  return useDuckDBQuery({ rel: props.rel(artifactRel) }, opts);
};
