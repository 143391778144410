import {
  Asc,
  AST,
  CountDistinct,
  DenseRankOver,
  Desc,
  f,
  FirstValueOver,
  If,
  MakeStruct,
  Min,
  Page,
  Relation,
  RowNumberOver,
  Sum,
  UI,
  Today,
  Constant,
  Ty,
} from '@cotera/era';
import { valueToNumDays } from '../utils';

export const OrdersOverview = ({
  Orders,
  ItemDetail,
}: {
  Orders: Relation;
  ItemDetail: Relation;
}): AST._App => {
  return UI.App({
    title: 'Orders Overview',
    icon: 'calculator',
    pages: {
      '/': Page([], () => {
        return [
          UI.Header({
            title: 'Orders Overview',
          }),
          UI.State(
            {
              timePeriodValue: Constant('1y', {
                ty: Ty.e(['1m', '3m', '6m', '1y', '2y']),
              }),
              tab: Constant('Order Count', {
                ty: Ty.e(['Order Count', 'Quantity', 'Revenue']),
              }),
            },
            ({ timePeriodValue, tab }) => {
              const timePeriod = valueToNumDays(timePeriodValue);
              const OrdersTimePeriod = Orders.where((t) =>
                t
                  .attr('ORDER_DATE')
                  .gte(Today().dateSub('days', timePeriod).dateTrunc('month'))
              ).leftJoin(ItemDetail, (ord, item) => ({
                on: ord.attr('PRODUCT_ID').eq(item.attr('PRODUCT_ID')),
                select: {
                  ...ord.star(),
                  ...item.pick('ITEM_NAME'),
                },
              }));

              const OrdersCompare = Orders.where((t) =>
                t
                  .attr('ORDER_DATE')
                  .gte(
                    Today()
                      .dateSub('days', timePeriod.mul(2))
                      .dateTrunc('month')
                  )
              )
                .leftJoin(ItemDetail, (ord, item) => ({
                  on: ord.attr('PRODUCT_ID').eq(item.attr('PRODUCT_ID')),
                  select: {
                    ...ord.star(),
                    ...item.pick('ITEM_NAME'),
                  },
                }))
                .select((t) => ({
                  ...t.star(),
                  TIME_PERIOD: If(
                    t
                      .attr('ORDER_DATE')
                      .gte(Today().dateSub('days', timePeriod)),
                    { then: 0, else: 1 }
                  ),
                }));

              const OrderMonthSummary = OrdersTimePeriod.groupBy((t) => ({
                MONTH: t.attr('ORDER_DATE').dateTrunc('month'),
              })).select((t) => ({
                ...t.group(),
                ORDER_COUNT: CountDistinct(t.attr('ORDER_ID')),
                QUANTITY: Sum(t.attr('QUANTITY')),
                REVENUE: Sum(t.attr('PRICE')),
              }));

              const ItemSummary = OrdersTimePeriod.groupBy((t) =>
                t.pick('ITEM_NAME')
              )
                .select((t) => ({
                  ...t.group(),
                  ORDER_COUNT: CountDistinct(t.attr('ORDER_ID')),
                  QUANTITY: Sum(t.attr('QUANTITY')),
                  REVENUE: Sum(t.attr('PRICE')),
                }))
                .where((t) => t.attr('ITEM_NAME').isNotNull());
              const FirstOrdersTimePeriod = Orders.select((t) => ({
                ...t.star(),
                ORDER_RANK: DenseRankOver({
                  partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
                  orderBy: Asc(t.attr('ORDER_DATE')),
                }),
              }))
                .select((t) => ({
                  ...t.star(),
                  FIRST_ORDER: t.attr('ORDER_RANK').eq(1),
                }))
                .where((t) =>
                  t
                    .attr('ORDER_DATE')
                    .gte(Today().dateSub('days', timePeriod).dateTrunc('month'))
                );

              const FirstOrdersSummary = FirstOrdersTimePeriod.groupBy((t) =>
                t.pick('FIRST_ORDER')
              ).select((t) => ({
                ...t.group(),
                TOTAL_ORDERS: CountDistinct(t.attr('ORDER_ID')),
                TOTAL_CUSTOMERS: CountDistinct(t.attr('__COTERA_CUSTOMER_ID')),
                TOTAL_REVENUE: Sum(t.attr('PRICE')),
              }));

              const OrdersCompareStats = OrdersCompare.groupBy((t) =>
                t.pick('TIME_PERIOD')
              ).select((t) => ({
                ...t.group(),
                TOTAL_ORDERS: CountDistinct(t.attr('ORDER_ID')),
                TOTAL_CUSTOMERS: CountDistinct(t.attr('__COTERA_CUSTOMER_ID')),
                TOTAL_REVENUE: Sum(t.attr('PRICE')),
                TOTAL_QUANTITY: Sum(t.attr('QUANTITY')),
              }));

              return [
                UI.Row([timePeriodValue.Toggle()]),
                // Can I add a conditional option here to select day or month
                // for the date trunc if the original toggle chooses 3 months?
                UI.Block([
                  UI.Stats([
                    OrdersCompareStats.select(
                      (t) => ({
                        ...t.pick('TIME_PERIOD', 'TOTAL_ORDERS'),
                        COMPARE_VAL: FirstValueOver(t.attr('TOTAL_ORDERS'), {
                          orderBy: Desc(t.attr('TIME_PERIOD')),
                        }),
                      }),
                      { distinct: true }
                    )
                      .where((t) => t.attr('TIME_PERIOD').eq(0))
                      .chart.Stat(
                        (t) => ({
                          value: t.attr('TOTAL_ORDERS'),
                          from: t.attr('COMPARE_VAL'),
                          style: If(
                            t.attr('TOTAL_ORDERS').gte(t.attr('COMPARE_VAL')),
                            {
                              then: 'positive',
                              else: 'negative',
                            }
                          ),
                        }),
                        { title: 'Total Orders' }
                      ),
                    OrdersCompareStats.select(
                      (t) => ({
                        ...t.pick('TIME_PERIOD', 'TOTAL_CUSTOMERS'),
                        COMPARE_VAL: FirstValueOver(t.attr('TOTAL_CUSTOMERS'), {
                          orderBy: Desc(t.attr('TIME_PERIOD')),
                        }),
                      }),
                      { distinct: true }
                    )
                      .where((t) => t.attr('TIME_PERIOD').eq(0))
                      .chart.Stat(
                        (t) => ({
                          value: t.attr('TOTAL_CUSTOMERS'),
                          from: t.attr('COMPARE_VAL'),
                          style: If(
                            t
                              .attr('TOTAL_CUSTOMERS')
                              .gte(t.attr('COMPARE_VAL')),
                            {
                              then: 'positive',
                              else: 'negative',
                            }
                          ),
                        }),
                        { title: 'Total Customers' }
                      ),
                    OrdersCompareStats.select(
                      (t) => ({
                        ...t.pick('TIME_PERIOD', 'TOTAL_REVENUE'),
                        COMPARE_VAL: FirstValueOver(t.attr('TOTAL_REVENUE'), {
                          orderBy: Desc(t.attr('TIME_PERIOD')),
                        }),
                      }),
                      { distinct: true }
                    )
                      .where((t) => t.attr('TIME_PERIOD').eq(0))
                      .chart.Stat(
                        (t) => ({
                          value: t.attr('TOTAL_REVENUE'),
                          from: t.attr('COMPARE_VAL'),
                          style: If(
                            t.attr('TOTAL_REVENUE').gte(t.attr('COMPARE_VAL')),
                            {
                              then: 'positive',
                              else: 'negative',
                            }
                          ),
                        }),
                        { title: 'Total Revenue', unit: 'usd' }
                      ),
                    OrdersCompareStats.select(
                      (t) => ({
                        ...t.pick('TIME_PERIOD', 'TOTAL_QUANTITY'),
                        COMPARE_VAL: FirstValueOver(t.attr('TOTAL_QUANTITY'), {
                          orderBy: Desc(t.attr('TIME_PERIOD')),
                        }),
                      }),
                      { distinct: true }
                    )
                      .where((t) => t.attr('TIME_PERIOD').eq(0))
                      .chart.Stat(
                        (t) => ({
                          value: t.attr('TOTAL_QUANTITY'),
                          from: t.attr('COMPARE_VAL'),
                          style: If(
                            t.attr('TOTAL_QUANTITY').gte(t.attr('COMPARE_VAL')),
                            {
                              then: 'positive',
                              else: 'negative',
                            }
                          ),
                        }),
                        { title: 'Total Quantity Sold' }
                      ),
                  ]),
                ]),
                UI.Block(
                  [
                    UI.TabsV2(tab, [
                      tab.muMatch({
                        'Order Count': OrderMonthSummary.chart.BarChart(
                          (t) => ({
                            x: t.attr('MONTH'),
                            y: t.attr('ORDER_COUNT'),
                            category: 'Order Month',
                          }),
                          {
                            axis: { x: { scale: 'month' } },
                            format: { x: 'month' },
                          }
                        ),
                        Quantity: OrderMonthSummary.chart.BarChart(
                          (t) => ({
                            x: t.attr('MONTH'),
                            y: t.attr('QUANTITY'),
                            category: 'Order Month',
                          }),
                          {
                            axis: { x: { scale: 'month' } },
                            format: { x: 'month' },
                          }
                        ),
                        Revenue: OrderMonthSummary.chart.BarChart(
                          (t) => ({
                            x: t.attr('MONTH'),
                            y: t.attr('REVENUE'),
                            category: 'Order Month',
                          }),
                          {
                            axis: { x: { scale: 'month' } },
                            format: { x: 'month' },
                          }
                        ),
                      }),
                    ]),
                  ],
                  { border: false }
                ),
                UI.Block(
                  [
                    UI.Row([
                      UI.Third(
                        FirstOrdersSummary.chart.PieChart(
                          (t) => ({
                            value: t.attr('TOTAL_CUSTOMERS'),
                            category: If(t.attr('FIRST_ORDER'), {
                              then: 'First Time Customers',
                              else: 'Repeat Customers',
                            }),
                          }),
                          { title: 'Customer Count', labels: false }
                        )
                      ),
                      UI.Third(
                        FirstOrdersSummary.chart.PieChart(
                          (t) => ({
                            value: t.attr('TOTAL_ORDERS'),
                            category: If(t.attr('FIRST_ORDER'), {
                              then: 'First Time Customers',
                              else: 'Repeat Customers',
                            }),
                          }),
                          { title: 'Order Count', labels: false }
                        )
                      ),
                      UI.Third(
                        FirstOrdersSummary.chart.PieChart(
                          (t) => ({
                            value: t.attr('TOTAL_REVENUE'),
                            category: If(t.attr('FIRST_ORDER'), {
                              then: 'First Time Customers',
                              else: 'Repeat Customers',
                            }),
                          }),
                          { title: 'Total Revenue', labels: false }
                        )
                      ),
                    ]),
                  ],
                  { title: 'First Time vs. Repeat Customers' }
                ),
                UI.Block(
                  [
                    UI.Row([
                      UI.Third(
                        ItemSummary.select((t) => ({
                          ...t.star(),
                          RANK: RowNumberOver({
                            orderBy: Desc(t.attr('ORDER_COUNT')),
                          }),
                        }))
                          .select((t) => ({
                            ...t.star(),
                            ITEM_NAME: If(t.attr('RANK').lte(5), {
                              then: t.attr('ITEM_NAME'),
                              else: 'Other',
                            }),
                          }))
                          .groupBy((t) => t.pick('ITEM_NAME'))
                          .select((t) => ({
                            ...t.group(),
                            ORDER_COUNT: Sum(t.attr('ORDER_COUNT')),
                            RANK: Min(t.attr('RANK')),
                          }))
                          .chart.SummaryChart(
                            (t) => ({
                              value: t.attr('ORDER_COUNT'),
                              category: t.attr('ITEM_NAME'),
                            }),
                            { title: 'Order Count' }
                          )
                      ),
                      UI.Third(
                        ItemSummary.select((t) => ({
                          ...t.star(),
                          RANK: RowNumberOver({
                            orderBy: Desc(t.attr('QUANTITY')),
                          }),
                        }))
                          .select((t) => ({
                            ...t.star(),
                            ITEM_NAME: If(t.attr('RANK').lte(5), {
                              then: t.attr('ITEM_NAME'),
                              else: 'Other',
                            }),
                          }))
                          .groupBy((t) => t.pick('ITEM_NAME'))
                          .select((t) => ({
                            ...t.group(),
                            QUANITTY: Sum(t.attr('QUANTITY')),
                            RANK: Min(t.attr('RANK')),
                          }))
                          .chart.SummaryChart(
                            (t) => ({
                              value: t.attr('QUANITTY'),
                              category: t.attr('ITEM_NAME'),
                            }),
                            { title: 'Quantity' }
                          )
                      ),
                      UI.Third(
                        ItemSummary.select((t) => ({
                          ...t.star(),
                          RANK: RowNumberOver({
                            orderBy: Desc(t.attr('REVENUE')),
                          }),
                        }))
                          .select((t) => ({
                            ...t.star(),
                            ITEM_NAME: If(t.attr('RANK').lte(5), {
                              then: t.attr('ITEM_NAME'),
                              else: 'Other',
                            }),
                          }))
                          .groupBy((t) => t.pick('ITEM_NAME'))
                          .select((t) => ({
                            ...t.group(),
                            REVENUE: Sum(t.attr('REVENUE')),
                            RANK: Min(t.attr('RANK')),
                          }))
                          .chart.SummaryChart(
                            (t) => ({
                              value: t.attr('REVENUE'),
                              category: t.attr('ITEM_NAME'),
                            }),
                            { title: 'Revenue' }
                          )
                      ),
                    ]),
                  ],
                  { title: 'Top 5 Items by Sales Metrics' }
                ),
                UI.Block(
                  [
                    OrdersTimePeriod.select((t) => ({
                      ...t.pick(
                        'ORDER_ID',
                        'ORDER_DATE',
                        '__COTERA_CUSTOMER_ID',
                        'PRODUCT_ID'
                      ),
                      PRODUCT_NAME: MakeStruct({
                        __link: f`https://app.cotera.co/apps/item-overview/${t.attr(
                          'PRODUCT_ID'
                        )}/`,
                        text: t.attr('ITEM_NAME'),
                        action: 'tab',
                      }),
                      ...t.pick('QUANTITY', 'PRICE'),
                    })),
                  ],
                  { title: 'Orders sample data' }
                ),
              ];
            }
          ),
        ];
      }),
    },
    menu: [],
  });
};
