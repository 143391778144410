export const Loading = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-full animate-pulse"
  >
    <path
      d="M64.55296 608.95232l209.97632-209.97632 39.82336 39.82336-209.97632 209.97632z"
      fill="#D1D1D1"
    />
    <path
      d="M62.976 386.56l196.58752-196.5824 39.82336 39.82336-196.58752 196.5824z"
      fill="#9C9C9C"
    />
    <path
      d="M710.02624 562.75456l174.13632-174.13632 39.82336 39.82336-174.13632 174.14144z"
      fill="#D1D1D1"
    />
    <path
      d="M694.3744 353.87904l209.98144-209.98144 39.82336 39.82336-209.98144 209.98144z"
      fill="#9C9C9C"
    />
    <path
      d="M257.46944 451.1744l16.52736-53.8368 492.9024 151.3216-16.53248 53.84192z"
      fill="#D1D1D1"
    />
    <path
      d="M242.80064 244.39296l16.52736-53.84192 492.9024 151.32672-16.5376 53.84192z"
      fill="#9C9C9C"
    />
    <path d="M73.216 828.416h870.4v58.88h-870.4z" fill="#D1D1D1" />
  </svg>
);
