import { UI, Values } from '@cotera/era';

export const IndexPage = [
  UI.Header({ title: 'Cotera Marketplace Risk AI Demo - Datasets' }),
  UI.Block(
    [
      Values([
        {
          value: 'Chipotle',
          link: {
            to: './chipotle',
          },
        },
        {
          value: 'Starbucks',
          link: {
            to: './starbucks',
          },
        },
      ]).chart.IndexList(),
    ],
    {
      border: false,
    }
  ),
];
